import { DataDrivenForm } from '@app/view/components/DataDrivenForm/components/DataDrivenForm';
import {
    PageLayout,
    usePageLayout,
    Text,
    ColumnLayout,
    StackLayout,
    FormLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useState } from 'react';
import {
    addressPages,
    addressQuestions,
    lawVerticalPages,
    lawVerticalQuestions,
    naicsPages,
    naicsQuestions,
    signupPages,
    signupQuestions,
    techVerticalPages,
    techVerticalQuestions,
} from './SignupOnboardingWrapperQuestionnaireData';
import { SignUpBanner } from './SignUpBanner.view';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishUserOrgUserClickEvent } from '@app/userOrg/useCases/PublishUserOrgUserClickEvent';
import { typeformLink } from '@app/view/components/BrokerBanner.view';
import { Link } from '@app/view/components/Link/Link';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { Organization } from '@app/userOrg/entities/Organization';
import { UnauthorizedOnboardingSignUp } from '@app/userOrg/useCases/UnauthorizedOnboardingSignUp';
import { CoverageType } from '@app/shopping/types/CoverageType';
import { HeaderView } from '@app/view/components';
import { UnauthenticatedHeaderCtaView } from '../UnauthenticatedHeaderCta.view';
import { AppContext } from '@app/view/AppContext';
import {
    FormPageDefinition,
    FormQuestionDefinition,
} from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { TechVerticalProductSelectionPage } from '@app/shopping/view/components/techVerticalProductSelection/TechVerticalProductSelectionPage';
import { StartGenericBundleRequest } from '@app/shopping/view/hooks/useStartApplication';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { Headquarters } from '@app/shopping/types/Headquarters';
import { SignUpTermsAndConditions } from './SignUpTermsAndConditions.view';
import { EmailInputPage } from './EmailInputPage.view';
import { ErrorCode } from '../../../errors';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { LawVerticalProductSelection } from './LawVerticalProductSelection';
import { calculateOnboardingRedirectURLForUnauthenticatedSignup } from '@app/userOrg/useCases/GetOnboardingRedirect';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';

type QuestionnaireSection = {
    type: 'product-selection' | 'data-driven-form' | 'email-page';
    name: string;
    component: any;
    questions?: FormQuestionDefinition[];
    pages?: FormPageDefinition[];
    showBanner?: boolean;
    showBrokerLink?: boolean;
};

const signupSection: QuestionnaireSection = {
    type: 'data-driven-form',
    name: 'signup',
    component: DataDrivenForm,
    questions: signupQuestions,
    pages: signupPages,
    showBanner: true,
    showBrokerLink: true,
};

const addressSection: QuestionnaireSection = {
    type: 'data-driven-form',
    name: 'address',
    component: DataDrivenForm,
    questions: addressQuestions,
    pages: addressPages,
};

const naicsSection: QuestionnaireSection = {
    type: 'data-driven-form',
    name: 'naics',
    component: DataDrivenForm,
    questions: naicsQuestions,
    pages: naicsPages,
};

const techVerticalSection: QuestionnaireSection = {
    type: 'data-driven-form',
    name: 'techvertical',
    component: DataDrivenForm,
    questions: techVerticalQuestions,
    pages: techVerticalPages,
};

const lawVerticalSection: QuestionnaireSection = {
    type: 'data-driven-form',
    name: 'lawvertical',
    component: DataDrivenForm,
    questions: lawVerticalQuestions,
    pages: lawVerticalPages,
};

const productSelection: QuestionnaireSection = {
    type: 'product-selection',
    name: 'questionnaire',
    component: ProductSelectionPage,
};

const otherEmailSection: QuestionnaireSection = {
    type: 'email-page',
    name: 'email',
    component: EmailInputPage,
};

const getSteps = (naicsCode: string, redirectUrl: string | undefined, questionnaireData: any) => {
    const lawBundleFlowWithoutProductSelection = [
        signupSection,
        addressSection,
        naicsSection,
        lawVerticalSection,
        otherEmailSection,
    ];

    const fullLawBundleFlow = [
        signupSection,
        addressSection,
        naicsSection,
        lawVerticalSection,
        productSelection,
        otherEmailSection,
    ];

    const techVerticalFlowWithoutProductSelection = [
        signupSection,
        addressSection,
        naicsSection,
        techVerticalSection,
        otherEmailSection,
    ];

    const fullTechVerticalFlow = [
        signupSection,
        addressSection,
        naicsSection,
        techVerticalSection,
        productSelection,
        otherEmailSection,
    ];

    const basicFlow = [signupSection, addressSection, naicsSection, otherEmailSection];

    if (NAICS_CODE_TO_VERTICAL[naicsCode] === 'LawFirm') {
        const calculatedOnboardingRedirectUrl =
            calculateOnboardingRedirectURLForUnauthenticatedSignup({
                naicsCode,
                redirectUrl,
                questionnaireData,
                vertical: 'LawFirm',
            });
        if (calculatedOnboardingRedirectUrl) {
            return lawBundleFlowWithoutProductSelection;
        }
        return fullLawBundleFlow;
    } else if (NAICS_CODE_TO_VERTICAL[naicsCode] === 'TechCompanies') {
        const calculatedOnboardingRedirectUrl =
            calculateOnboardingRedirectURLForUnauthenticatedSignup({
                naicsCode,
                redirectUrl,
                questionnaireData,
                vertical: 'TechCompanies',
            });
        if (calculatedOnboardingRedirectUrl) {
            return techVerticalFlowWithoutProductSelection;
        }
        return fullTechVerticalFlow;
    }
    return basicFlow;
};

export type QuestionnaireData = {
    company_name: string;
    zip_code: ZipCode;
    has_website: boolean;
    headquarters: Headquarters;
    naics_code: string;
    tech_area_of_focus: string;
    onb_raised_funding: boolean;
    total_revenue: Revenue;
    working_space_type: string;
    total_number_employees: number | string;
    has_employees: boolean;
    has_automobiles: boolean;
    email: EmailAddress;
    [key: string]: any;
};

function formatSubmitErrors(errors: any[]) {
    const emailAlreadyInUseMessage = (
        <React.Fragment>
            This email is already connected to an Embroker account.{' '}
            <Link href="/login">Sign in</Link>.
        </React.Fragment>
    );
    for (const error of errors) {
        const errorCode = error.code;
        switch (errorCode) {
            case ErrorCode.EmailAlreadyInUse:
                return [emailAlreadyInUseMessage];
            case ErrorCode.InvalidEmail:
                return ['Please enter a valid email'];
            case ErrorCode.MaxNumberOfSignUpAttemptsExceeded:
                return [
                    'You have tried to use invalid email address 5 times. You cannot create an account for the next 1 hour.',
                ];
        }
    }

    container.get<Logger>(Log).warn(`User onboarding - Signup page formatSubmitErrors: ${errors}`);

    return ['Sorry, something went wrong. Please try again later.'];
}

type Props = {
    redirectUrl?: string;
};

export function SignupOnboardingWrapper({ redirectUrl }: Props) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [questionnaireData, setQuestionnaire] = useState<QuestionnaireData>(
        {} as QuestionnaireData,
    );
    const steps = getSteps(questionnaireData.naics_code, redirectUrl, questionnaireData);
    const currentStep = steps[currentStepIndex];
    const {
        component: Component,
        questions,
        pages,
        name,
        showBanner,
        showBrokerLink,
        type,
    } = currentStep;
    const pageLayout = usePageLayout({});

    const questionsWithValues =
        questions?.map((question: FormQuestionDefinition) => ({
            ...question,
            initialValue: questionnaireData[question.key],
        })) ?? [];

    const { setHeader } = useContext(AppContext);
    useEffect(() => {
        setHeader(
            <HeaderView logoHref="https://www.embroker.com">
                <UnauthenticatedHeaderCtaView
                    href="/login"
                    content="Sign in now"
                    dataE2e="customer-public-header-login-link"
                    question="Already have an account?"
                />
            </HeaderView>,
        );
    }, [setHeader]);

    const hasPrevious = currentStepIndex > 0;

    const previousStep = () => {
        if (hasPrevious) {
            setCurrentStepIndex((currentStepIndex) => currentStepIndex - 1);
        }
    };

    const hasNext = currentStepIndex < steps.length - 1;

    const [emailErrors, setEmailErrors] = useState<any[]>([]);

    const nextStep = async (updatedQuestionnaireData: QuestionnaireData) => {
        if (hasNext) {
            setCurrentStepIndex((currentStepIndex) => currentStepIndex + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (currentStepIndex === steps.length - 1) {
            const unauthorizedOnboardingSignUpResponse = await execute(
                UnauthorizedOnboardingSignUp,
                {
                    questionnaireData: updatedQuestionnaireData,
                    redirectUrl,
                },
            );

            if (isErr(unauthorizedOnboardingSignUpResponse)) {
                const errors = unauthorizedOnboardingSignUpResponse.errors;
                const formattedErrors = formatSubmitErrors(errors as any[]);
                setEmailErrors(formattedErrors);
            }
        }
    };

    const handleFormSubmit = (formData: QuestionnaireData) => {
        const updatedQuestionnaireData = { ...questionnaireData, ...formData };
        setQuestionnaire(updatedQuestionnaireData);
        nextStep(updatedQuestionnaireData);
    };

    const handlePageComplete = (pageData: QuestionnaireData) => {
        setQuestionnaire({ ...questionnaireData, ...pageData });
    };

    const onProductSelectionContinueClicked = (data: {
        applicationType: string;
        selectedCoverages:
            | ('techEO' | 'directorsAndOfficers' | 'employmentPractices' | 'fiduciary')[]
            | CoverageType[];
    }) => {
        const updatedQuestionnaireData = { ...questionnaireData, ...data };
        setQuestionnaire(updatedQuestionnaireData);
        nextStep(updatedQuestionnaireData);
    };

    return (
        <PageLayout {...pageLayout}>
            <StackLayout>
                {showBanner && <SignUpBanner />}
                <StackLayout gap="12">
                    {type === 'product-selection' && (
                        <ProductSelectionPage
                            organization={
                                {
                                    companyLegalName: questionnaireData.company_name,
                                    naics: questionnaireData.naics_code,
                                    raisedFunding: questionnaireData.onb_raised_funding,
                                } as Organization
                            }
                            onBackClicked={previousStep}
                            onContinueClicked={onProductSelectionContinueClicked}
                        />
                    )}
                    {type === 'data-driven-form' && (
                        <Component
                            key={name}
                            formQuestions={questionsWithValues}
                            formPages={pages}
                            onFormSubmit={handleFormSubmit}
                            onPageComplete={handlePageComplete}
                            onFirstPageBackButtonClicked={hasPrevious ? previousStep : undefined}
                            submitText={hasNext ? 'Next' : 'Submit'}
                        />
                    )}
                    {type === 'email-page' && (
                        <EmailInputPage
                            key={name}
                            email={questionnaireData.email}
                            onEmailChange={(e: { target: { value: any } }) => {
                                setQuestionnaire({ ...questionnaireData, email: e.target.value });
                            }}
                            onContinueClicked={() => {
                                handleFormSubmit(questionnaireData);
                            }}
                            onBackClicked={previousStep}
                            errors={emailErrors}
                        />
                    )}
                    {showBrokerLink && (
                        <FormLayout>
                            <BrokerLink />
                            <SignUpTermsAndConditions />
                        </FormLayout>
                    )}
                </StackLayout>
            </StackLayout>
        </PageLayout>
    );
}

function BrokerLink() {
    const handleBannerClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        execute(PublishUserOrgUserClickEvent, {
            clickEventName: 'Signup Broker Banner Clicked',
        });
        window.open(typeformLink);
    };
    return (
        <ColumnLayout split="-1">
            <Text>
                Are you a broker? Access the broker portal{' '}
                <Link onClick={handleBannerClick} href={''}>
                    here
                </Link>
            </Text>
        </ColumnLayout>
    );
}

export interface ProductSelectionPageProps {
    organization: Organization;
    onBackClicked: () => void;
    onContinueClicked: (props: {
        applicationType: string;
        selectedCoverages:
            | ('techEO' | 'directorsAndOfficers' | 'employmentPractices' | 'fiduciary')[]
            | CoverageType[];
    }) => void;
}

function ProductSelectionPage({
    organization,
    onBackClicked,
    onContinueClicked,
}: ProductSelectionPageProps) {
    if (NAICS_CODE_TO_VERTICAL[organization.naics as string] === 'LawFirm') {
        return (
            <LawVerticalProductSelection
                organization={organization}
                onBackClicked={onBackClicked}
                onContinueClicked={onContinueClicked}
            />
        );
    } else if (NAICS_CODE_TO_VERTICAL[organization.naics as string] === 'TechCompanies') {
        const handleContinue = (selectedCoverages: StartGenericBundleRequest) => {
            onContinueClicked({
                applicationType: 'AppTypeCodeListGenericBundle',
                selectedCoverages,
            });
        };

        return (
            <TechVerticalProductSelectionPage
                organization={organization}
                onContinueClicked={handleContinue}
                onBackClicked={onBackClicked}
            />
        );
    }
    return null;
}
