import React from 'react';
import { Text } from '@embroker/ui-toolkit/v2';
import { Link } from '@app/view/components';

export function RiskProfileLegalDisclosure() {
    return (
        <Text style="microcopy">
            The information contained herein is subject to Embroker's&nbsp;
            <Link href="https://www.embroker.com/terms" target="_blank">
                Terms
            </Link>
            &nbsp;and &nbsp;
            <Link href="https://www.embroker.com/privacy" target="_blank">
                Privacy Policy
            </Link>
            , is based upon Embroker's experience as an insurance broker, available information,
            current insurance information and marketplace, or may be of a general nature. Nothing in
            the content provided should be construed as tax, accounting, legal or actuarial advise.
            While we provide comment and recommendations related to the types and terms of insurance
            coverage, the decision to act or not act is ultimately the insurance purchaser's alone.
        </Text>
    );
}
