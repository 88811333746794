import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { BundleQuoteRepository } from '../../bundle/repositories';
import { TasksRepository } from '../../tasks/repositories';
import {
    Application,
    ApplicationCreated,
    ApplicationNotEligible,
    ApplicationQuoteCreated,
    ApplicationReferred,
} from '../entities/Application';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { Bundle, DeclinedByCarrier, NotEligible } from '../types/enums';

interface GetTaskStatusRequest {
    id: UUID;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds: number;
    abortSignal: AbortSignal;
}

export interface GetTaskStatusResponse {
    application: EntityProps<Application>;
    redirectApplicationList: Nullable<Array<EntityProps<Application>>>;
    isPCOMLEnabled: boolean;
    isLPLEnabled: boolean;
    isEmbrokerCrimeEnabled: boolean;
    isEmbrokerCyberEnabled: boolean;
    isEmbrokerExcessEnabled: boolean;
}

export interface GetTaskStatus extends UseCase {
    execute(
        request: GetTaskStatusRequest,
    ): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class GetTaskStatusUseCase extends UseCase implements GetTaskStatus {
    public static type = Symbol('Shopping/GetTaskStatus');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute({
        id,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds,
        abortSignal,
    }: GetTaskStatusRequest): AsyncResult<
        GetTaskStatusResponse,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            id,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }
        if (!UUID.check(pollForTaskStatusResult.value)) {
            return Failure(OperationFailed({ message: 'Task data is not in correct format' }));
        }

        const getApplicationResponse =
            await this.applicationRepository.getApplicationWithQuoteDetails(
                pollForTaskStatusResult.value,
            );

        if (isErr(getApplicationResponse)) {
            return handleOperationFailure(getApplicationResponse);
        }
        const { application, totalPremium } = getApplicationResponse.value;

        // for bundle apps we need to check for redirects for underlying applications
        const underlyingAppRedirectIds: Immutable<UUID>[] = [];
        for (const underlyingAppDetail of (application.bundleDetails?.appDetails ?? []).filter(
            (item) =>
                item.creationType == 'InsuranceApplicationCreationTypeCodeListUnderlying' &&
                item.appStatus !== 'InsuranceApplicationStatusCodeListDeleted',
        )) {
            const getAppResult = await this.applicationRepository.getApplication(
                underlyingAppDetail.appId,
            );
            if (isErr(getAppResult)) {
                return handleOperationFailure(getAppResult);
            }

            if (getAppResult.value.redirectToApplicationList) {
                underlyingAppRedirectIds.push(...getAppResult.value.redirectToApplicationList);
            }
        }

        const getRedirectApplicationListResponse = await this.getRedirectApplicationList([
            ...underlyingAppRedirectIds,
            ...(application.redirectToApplicationList ?? []),
        ]);
        if (isErr(getRedirectApplicationListResponse)) {
            return handleOperationFailure(getRedirectApplicationListResponse);
        }
        const redirectApplicationList = getRedirectApplicationListResponse.value;

        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }
        const {
            isPCOMLEnabled,
            isLPLEnabled,
            isEmbrokerCrimeEnabled,
            isEmbrokerCyberEnabled,
            isEmbrokerExcessEnabled,
        } = configResponse.value;

        if ((application.ineligibilityReasons?.referralReasons?.length ?? 0) > 0) {
            const referralReasons = application.ineligibilityReasons?.referralReasons;
            const clientReservationReferralReason = 'Client reservation';
            if (referralReasons?.some((reason) => reason == clientReservationReferralReason)) {
                application.onSubmitClearanceFailed();
            }
        }

        if (application.isOFACRejected) {
            application.onOFACRejected();
        } else if ([NotEligible, DeclinedByCarrier].includes(application.status)) {
            const skuResult = await this.calculateSKU.execute({
                event: 'quote',
                applicationId: application.id,
            });
            const event: ApplicationNotEligible = {
                origin: 'Application',
                name: 'NotEligible',
                createdAt: new Date(Date.now()),
                applicationId: application.id,
                id: UUID.create(),
                sku: skuResult.value,
                isRenewal: application.isRenewal(),
            };
            await this.eventBus.publish(event);
        } else {
            if (totalPremium === null) {
                this.logger.warn(`Unable to retrieve total premium from application: ${name}.`);
            }

            if (application.creationType === Bundle) {
                await this.sendBundleApplicationSubmittedEvent(
                    application.id,
                    application.isRenewal(),
                );
            } else {
                const skuResult = await this.calculateSKU.execute({
                    event: 'quote',
                    applicationId: application.id,
                });
                application.onQuotePremium({
                    totalPremium,
                    applicationId: application.id,
                    isRenewal: application.isRenewal(),
                    sku: skuResult.value,
                    isPartiallyQuoted:
                        (redirectApplicationList && redirectApplicationList?.length > 0) ?? false,
                });
            }
        }

        if (redirectApplicationList && redirectApplicationList?.length > 0) {
            for (const redirectedApplication of redirectApplicationList) {
                await this.publishApplicationCreatedEvent(redirectedApplication);
            }
        }

        this.eventBus.publishEntityEvents(application);

        return Success({
            isPCOMLEnabled,
            isLPLEnabled: isLPLEnabled,
            isEmbrokerCrimeEnabled,
            isEmbrokerCyberEnabled,
            isEmbrokerExcessEnabled,
            application: application,
            redirectApplicationList: redirectApplicationList,
        });
    }

    private async publishApplicationCreatedEvent(application: Immutable<Application>) {
        const skuResult = await this.calculateSKU.execute({
            appType: application.appType,
            event: 'questionnaire_changed',
            questionnaireData: application.questionnaireData ?? undefined,
            shoppingCoverageList: application.shoppingCoverageList,
            applicationId: application.id,
        });

        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: application.id,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        await this.eventBus.publish(eventData);
    }

    private async getRedirectApplicationList(
        redirectApplicationIdList: Immutable<Nullable<Array<UUID>>>,
    ): AsyncResult<Nullable<Array<Application>>, InvalidArgument | OperationFailed> {
        if (!redirectApplicationIdList || redirectApplicationIdList.length === 0) {
            return Success(null);
        }

        const result: Array<Application> = [];
        for (const applicationId of redirectApplicationIdList) {
            const getApplicationResponse = await this.applicationRepository.getApplication(
                applicationId,
            );
            if (isErr(getApplicationResponse)) {
                return handleOperationFailure(getApplicationResponse);
            }
            result.push(getApplicationResponse.value as Application);
        }
        return Success(result);
    }

    private async sendBundleApplicationSubmittedEvent(applicationId: UUID, isRenewal: boolean) {
        const getLastQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
            applicationId,
        );

        if (isErr(getLastQuoteResult)) {
            return handleOperationFailure(getLastQuoteResult);
        }

        const bundleQuote = getLastQuoteResult.value;
        const skuResult = await this.calculateSKU.execute({
            event: 'quote',
            applicationId,
        });

        if (bundleQuote.isAnyCoverageReferred()) {
            const event: ApplicationReferred = {
                origin: 'Application',
                name: 'Referred',
                createdAt: new Date(Date.now()),
                applicationId: applicationId,
                totalPremium: bundleQuote.getTotalBundlePremium(),
                id: UUID.create(),
                sku: skuResult.value,
                isRenewal,
            };
            await this.eventBus.publish(event);
        } else if (bundleQuote.isBundleQuoteBindable()) {
            const event: ApplicationQuoteCreated = {
                origin: 'Application',
                name: 'QuoteCreated',
                createdAt: new Date(Date.now()),
                applicationId,
                totalPremium: bundleQuote.getTotalBundlePremium(),
                id: UUID.create(),
                sku: skuResult.value,
                isRenewal,
            };
            await this.eventBus.publish(event);
        } else {
            const event: ApplicationNotEligible = {
                origin: 'Application',
                name: 'NotEligible',
                createdAt: new Date(Date.now()),
                applicationId,
                id: UUID.create(),
                sku: skuResult.value,
                isRenewal,
            };
            await this.eventBus.publish(event);
        }
    }
}

export const GetTaskStatus: UseCaseClass<GetTaskStatus> = GetTaskStatusUseCase;
