import React, { Fragment, useContext, useMemo, useState } from 'react';
import {
    Modal,
    StackLayout,
    StatusMessage,
    Text,
    useModal,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import {
    DirectorsAndOfficersCard,
    EPLCard,
    FiduciaryCard,
    TechEOCard,
    TechVerticalProductSelectionCardProps,
} from './TechVerticalProductSelectionCardTemplates';
import { AppContext } from '@app/view/AppContext';
import { ModalLayout } from '@app/view/components/ModalLayout.view';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { execute } from '@embroker/shotwell/core/UseCase';
import { StartGenericBundleRequest, useStartApplication } from '../../hooks/useStartApplication';
import {
    AppTypeCode,
    AppTypeCodeListTechEO,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualFiduciary,
} from '@app/shopping/types/enums';
import { ESPFiduciaryWarningModalLayout } from '@app/quote/esp/view/components/Modals/ESPFiduciaryWarningModalLayout';
import { Organization } from '@app/userOrg/entities/Organization';
import { CoverageRecommendationLayout } from '../lawBundle/CoverageRecommendationLayout.view';
import { TechVerticalProductSelectionPageBanner } from './TechVerticalProductSelectionPageBanner.view';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import { CoverageInformationSlideout } from '../lawBundle/CoverageInformationSlideout.view';

export enum TechVerticalCoverageType {
    techEO = 'techEO',
    directorsAndOfficers = 'directorsAndOfficers',
    employmentPractices = 'employmentPractices',
    fiduciary = 'fiduciary',
}

export type TechCoverageTypes = keyof typeof TechVerticalCoverageType;

export const TECH_VERTICAL_COVERAGES: TechVerticalCoverageType[] = [
    TechVerticalCoverageType.techEO,
    TechVerticalCoverageType.directorsAndOfficers,
    TechVerticalCoverageType.employmentPractices,
    TechVerticalCoverageType.fiduciary,
];

export const coverageToCardMap: {
    [key in TechVerticalCoverageType]: React.FC<TechVerticalProductSelectionCardProps>;
} = {
    techEO: TechEOCard,
    directorsAndOfficers: DirectorsAndOfficersCard,
    employmentPractices: EPLCard,
    fiduciary: FiduciaryCard,
} as const;
export interface TechVerticalProductSelectionPageProps {
    organization?: Organization;
    onBackClicked: () => void;
    onContinueClicked: (startGenericBundleRequest: StartGenericBundleRequest) => void;
}

export function TechVerticalProductSelectionPage({
    organization,
    onContinueClicked,
}: TechVerticalProductSelectionPageProps) {
    const { setSlideout } = useContext(AppContext);
    const eplModal = useModal({ visible: false });
    const fiduciaryModal = useModal({ visible: false });
    const { startApplication } = useStartApplication();
    const [showWarningMessage, setShowWarningMessage] = useState(false);

    const organizationState = organization?.headquarters?.state;
    const organizationNaics = organization?.naics;
    const organizationIsFunded = Boolean(organization?.raisedFunding);

    const availableCoverages = !organizationIsFunded
        ? TECH_VERTICAL_COVERAGES.filter((coverage) => coverage == TechVerticalCoverageType.techEO)
        : TECH_VERTICAL_COVERAGES;

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });

    const cardClassName = isMobile ? 'u-1' : 'u-1/4';
    const canSelectMultipleCoverages = useMemo(
        () => availableCoverages.length > 1,
        [availableCoverages],
    );

    const shouldDeselectAllApps = useMemo(
        () =>
            canSelectMultipleCoverages &&
            // We only want to evaluate the feature flag if the user can select multiple coverages
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('tech-vertical-product-page-unselect', false),
        [canSelectMultipleCoverages],
    );

    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );

    const [selectedCoverages, setSelectedCoverages] = useState<TechCoverageTypes[]>(
        shouldDeselectAllApps ? [] : [...availableCoverages],
    );

    const toggleCard = (appType: TechCoverageTypes) => {
        setSelectedCoverages((currentArr) => {
            if (currentArr.includes(appType)) {
                return currentArr.filter((item) => item !== appType);
            }
            return currentArr.concat(appType);
        });
        setShowWarningMessage(false);
    };

    const handleTooltipClick = (type: TechCoverageTypes) => {
        let appTypeCode: null | AppTypeCode = null;

        switch (type) {
            case TechVerticalCoverageType.techEO:
                appTypeCode = AppTypeCodeListTechEO;
                break;
            case TechVerticalCoverageType.directorsAndOfficers:
                appTypeCode = AppTypeCodeListManualDirectorsAndOfficers;
                break;
            case TechVerticalCoverageType.employmentPractices:
                appTypeCode = AppTypeCodeListManualEmploymentPractices;
                break;
            case TechVerticalCoverageType.fiduciary:
                appTypeCode = AppTypeCodeListManualFiduciary;
                break;
        }

        if (appTypeCode) {
            setSlideout(
                <CoverageInformationSlideout
                    appTypeCode={appTypeCode}
                    onClose={() => setSlideout(null)}
                    isSelected={selectedCoverages.includes(type)}
                    onCoverageSelect={
                        canSelectMultipleCoverages
                            ? () => {
                                  toggleCard(type);
                                  setSlideout(null);
                              }
                            : undefined
                    }
                />,
            );
        }
    };

    const hasOnlyEPLCoverageSelected = useMemo(
        () =>
            selectedCoverages.length === 1 &&
            selectedCoverages.includes(TechVerticalCoverageType.employmentPractices),
        [selectedCoverages],
    );

    const hasOnlyFiduciarySelected = useMemo(
        () =>
            selectedCoverages.length === 1 &&
            selectedCoverages.includes(TechVerticalCoverageType.fiduciary),
        [selectedCoverages],
    );

    const isAllProductsNotSelected = useMemo(
        () => selectedCoverages.length === 0,
        [selectedCoverages],
    );

    const handleSubmit = () => {
        if (hasOnlyFiduciarySelected) {
            showFiduciaryModal();
        } else if (hasOnlyEPLCoverageSelected && organizationState === 'CA') {
            showEPLModal();
        } else {
            const startGenericBundleRequest: StartGenericBundleRequest =
                TECH_VERTICAL_COVERAGES.filter(
                    (coverage) =>
                        availableCoverages.includes(coverage) &&
                        selectedCoverages.includes(coverage),
                );
            onContinueClicked(startGenericBundleRequest);
        }
    };

    const showEPLModal = () => {
        eplModal.show();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up Displayed',
        });
    };
    const onEPLModalDismiss = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up closed',
        });
    };
    const onEPLModalClose = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up closed',
        });
        setShowWarningMessage(true);
    };
    const showFiduciaryModal = () => {
        fiduciaryModal.show();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation Fiduciary Pop up Displayed',
        });
    };
    const onFiduciaryModalDismiss = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation Fiduciary Pop up closed',
        });
    };
    const onFiduciaryModalClose = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation Fiduciary Pop up closed',
        });
        setShowWarningMessage(true);
    };
    const handleEPLModalPrimaryCTAClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up _ add more coverage clicked',
        });
        setShowWarningMessage(true);
        eplModal.hide();
    };

    const handleFiduciaryModalPrimaryCTAClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation Fiduciary Pop up _ add more coverage clicked',
        });
        setShowWarningMessage(true);
        fiduciaryModal.hide();
    };
    const handleEPLModalSecondaryCTAClick = () => {
        startApplication({ selectedAppTypes: [AppTypeCodeListManualEmploymentPractices] });
        eplModal.hide();
    };

    return (
        <CoverageRecommendationLayout
            isAllProductsNotSelected={isAllProductsNotSelected}
            handleGetStarted={handleSubmit}
            banner={
                <StackLayout gap="32">
                    <TechVerticalProductSelectionPageBanner
                        hideSecurityScorecardText={experimentationService.getFeatureValue(
                            'security-scorecard-credit-tech-vertical',
                            false,
                        )}
                    />
                    {showWarningMessage && hasOnlyEPLCoverageSelected && (
                        <StatusMessage status="warning">
                            EPLI coverage can only be purchased with another coverage. Please select
                            one more coverage, or continue with a non-digital quote.
                        </StatusMessage>
                    )}
                </StackLayout>
            }
            footer={
                experimentationService.getFeatureValue(
                    'security-scorecard-credit-tech-vertical',
                    false,
                ) ? null : (
                    <Text style="microcopy" color="brand-400">
                        *Discount subject to receiving an A rating from SecurityScorecard. <br />
                        <strong>Please note:</strong> Changes to your cybersecurity rating won't
                        lower your current premium, but will be credited at policy renewal.
                    </Text>
                )
            }
        >
            <Fragment>
                {availableCoverages.map((coverage) => {
                    const Card = coverageToCardMap[coverage];
                    return (
                        <Card
                            key={coverage}
                            canSelect={canSelectMultipleCoverages}
                            className={
                                canSelectMultipleCoverages || isMobile ? cardClassName : 'u-2/5'
                            }
                            isSelected={selectedCoverages.includes(coverage)}
                            naicsCode={organizationNaics}
                            onSelectionChange={toggleCard}
                            handleTooltipClick={() => handleTooltipClick(coverage)}
                            appearance={
                                canSelectMultipleCoverages || isMobile
                                    ? 'single-column-body'
                                    : 'two-column-body'
                            }
                        />
                    );
                })}
                <Modal
                    {...eplModal}
                    onDismiss={onEPLModalDismiss}
                    onClose={onEPLModalClose}
                    size="small"
                    dismissable
                >
                    <ModalLayout
                        title="Looks like you’re trying to get EPLI on its own"
                        primaryAction={{
                            label: 'Add Another Coverage',
                            onClick: () => handleEPLModalPrimaryCTAClick(),
                        }}
                        secondaryAction={{
                            label: 'Get EPLI Only',
                            onClick: () => handleEPLModalSecondaryCTAClick(),
                        }}
                    >
                        <StackLayout gap="12">
                            <Text style="body 1">
                                Unfortunately, in California, you can only get an EPLI policy online
                                if it’s packaged with other products.
                            </Text>
                            <Text style="body 1">
                                To get EPLI digitally, simply select at least one other coverage.
                            </Text>
                            <Text style="body 1">
                                If you do want EPLI only, you'll need to provide some additional
                                information so our team can find the best fit for your business.
                            </Text>
                        </StackLayout>
                    </ModalLayout>
                </Modal>
                <Modal
                    {...fiduciaryModal}
                    onDismiss={onFiduciaryModalDismiss}
                    onClose={onFiduciaryModalClose}
                    size="small"
                    dismissable
                >
                    <ESPFiduciaryWarningModalLayout
                        handlePrimaryCTAClick={handleFiduciaryModalPrimaryCTAClick}
                    />
                </Modal>
            </Fragment>
        </CoverageRecommendationLayout>
    );
}
