import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { navigateToErrorPage } from '../../../../view/errors';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import {
    GetApplicationList,
    GetApplicationListRequest,
} from '../../../../shopping/useCases/GetApplicationList';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { Policy, PolicyEndorsed } from '../../../entities/Policy';
import { EndorsementSubmittedStatus } from '../EndorsementSubmittedStatus';
import { AttorneyRosterCard } from './AttorneyRosterCard';
import { ClaimExpensesAndDeductibleTypeCard } from './ClaimExpensesAndDeductibleTypeCard';
import { CoverageSection } from './CoverageSection';
import { DocumentSection } from './DocumentSection';
import { PolicyInfo } from './PolicyInfo';
import { StatusBanner } from './StatusBanner';

interface PolicyProps {
    policy: Immutable<EntityProps<Policy>>;
    reloadPolicyMap: () => void;
    renewalStartYear?: number;
    onSeeRenewalClicked?: Function;
    isPolicyForRenew: boolean;
}

export function PolicyTab({
    policy,
    reloadPolicyMap,
    renewalStartYear,
    onSeeRenewalClicked,
    isPolicyForRenew,
}: PolicyProps) {
    const navigation = useNavigation();
    const [showEndorsementMessage, setShowEndorsementMessage] = useState(false);
    const [endorsementPolicyName, setEndorsementPolicyName] = useState('');
    const [endorsementPolicyNumber, setEndorsementPolicyNumber] = useState('');

    const { result: renewalsListResult, isLoading } = useUseCase(GetApplicationList, {
        sectionType: 'renewals',
    } as GetApplicationListRequest);

    if (policy.viewMode === 'PolicyViewStatusCodeListDraft') {
        navigation.navigate('/policies');
    }

    useDomainEvent<PolicyEndorsed>('Policy', 'Endorsed', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        showEndorsementToastMessage(event.policyName, event.policyNumber);
    });

    function showEndorsementToastMessage(policyName: string, policyNumber: string) {
        setShowEndorsementMessage(true);
        setEndorsementPolicyName(policyName);
        setEndorsementPolicyNumber(policyNumber);
    }

    const attorneyRoster =
        policy.lobList.find((lob) => lob.lobType === 'professionalLiabilityLob')?.liabilitySection
            ?.attorneyRoster ?? [];

    if (renewalsListResult && isErr(renewalsListResult)) {
        navigateToErrorPage(navigation.navigate, renewalsListResult.errors);
        return null;
    }

    const isRenewalAppReady =
        renewalsListResult?.value?.applicationList.some(({ renewedPolicyIdList }) =>
            renewedPolicyIdList.find((id) => id == policy.id),
        ) ?? false;

    return (
        <StackLayout>
            {showEndorsementMessage ? (
                <EndorsementSubmittedStatus
                    policyName={endorsementPolicyName}
                    policyNumber={endorsementPolicyNumber}
                />
            ) : null}
            {!isLoading && (
                <StatusBanner
                    policy={policy}
                    renewalStartYear={renewalStartYear}
                    onSeeRenewalClicked={onSeeRenewalClicked}
                    isPolicyForRenew={isPolicyForRenew}
                    isRenewalAppReady={isRenewalAppReady}
                />
            )}
            <PolicyInfo policy={policy} reloadPolicyMap={reloadPolicyMap} />
            <CoverageSection policy={policy} />
            {attorneyRoster.length > 0 ? (
                <AttorneyRosterCard attorneyRoster={attorneyRoster} />
            ) : null}
            {policy.lineOfBusiness == 'LineOfBusinessCodeListProfessionalLiability' ? (
                <ClaimExpensesAndDeductibleTypeCard
                    claimsExpense={policy.bindFactors?.claimsExpense}
                    separateClaimsExpenseLimit={policy.bindFactors?.separateClaimsExpenseLimit}
                    deductibleType={policy.bindFactors?.deductibleType}
                />
            ) : null}
            <DocumentSection documentList={policy.documents} />
        </StackLayout>
    );
}
