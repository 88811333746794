import { StackLayout, CoverLayout, Placeholder, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function IssueOptionsStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <Text style="heading 3">How can we help you?</Text>
                <Text style="body 1">
                    We’d like to get to know you better so we can point you in the right direction.
                    Simply indicate whether you need the certificates for yourself or for someone
                    else, and we can get started.
                </Text>
                <Placeholder backgroundColor="ui-50" appearance="compact" imageMaxWidth={270} />
            </StackLayout>
        </CoverLayout>
    );
}
