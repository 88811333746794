import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable, RequiredMembers } from '@embroker/shotwell/core/types';
import { ErrorLike, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { JSONSchema } from '@embroker/shotwell/view/components/JSONSchemaForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Loader, StatusMessage, WizardLayout, useStepper } from '@embroker/ui-toolkit/v2';
import React, {useContext, useMemo, useState} from 'react';
import { Policy } from '../../policy/entities/Policy';
import { EndorsementDefinition } from '../repositories/digitalEndorsements';
import { GetEndorsements } from '../useCases/GetEndorsementsUseCase';
import { EndorsementJSONSchemaFormSelector } from './EndorsementJSONSchemaFormSelector';
import { EndorsementSelector } from './EndorsementSelector';
import { AppContext } from '../../view/AppContext';
import { hasRole } from '../../userOrg/entities/Session';
import { GetActiveUserProfile } from '@app/userOrg/useCases/GetActiveUserProfile';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import {GetRestriction} from "@app/quote/esp/useCases/GetRestriction";
import {UUID} from "@embroker/shotwell/core/types/UUID";
import { mutable } from '@embroker/shotwell/core/types';
import {InsuranceApplicationRestriction} from "@app/quote/esp/types/InsuranceApplicationRestriction";
import {execute} from "@embroker/shotwell/core/UseCase";
import {getLogger} from "@app/quote/embrokerExcess/view/logger";

export type EndorsementOption = number;

export const SELECT_ENDORSEMENT_TYPE = 0,
    DISPLAY_SELECTED_ENDORSEMENT_TYPE: EndorsementOption = 1;

interface EndorsementSelectionSlideoutProps {
    onDismiss(): void;
    policy: Immutable<EntityProps<RequiredMembers<Policy, 'manifestId'>>>;
}

export function EndorsementSelectionSlideout({
    onDismiss,
    policy,
}: EndorsementSelectionSlideoutProps) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const { result: getActiveUserProfileResult, isLoading: isGetActiveUserProfileLoading } =
        useUseCase(GetActiveUserProfile);

    const [selectedEndorsement, setSelectedEndorsement] = useState<
        EndorsementDefinition | undefined
    >();

    const { activeStepIndex, next, previous } = useStepper({
        steps: [SELECT_ENDORSEMENT_TYPE, DISPLAY_SELECTED_ENDORSEMENT_TYPE].length,
        initialStep: SELECT_ENDORSEMENT_TYPE,
    });

    const { result: availableEndorsementsResult, isLoading } = useUseCase(GetEndorsements, {
        manifestId: policy.manifestId,
        policy: policy as Policy,
        isBroker: isBroker,
    });

    const { isLoading: isLoadingRestriction, result: restriction } = useUseCase(GetRestriction, {
        insuranceApplicationId: policy.insuranceApplicationId as UUID,
    });

    const restrictionResult: Immutable<InsuranceApplicationRestriction> | undefined =
        useMemo(() => {
            if (restriction) {
                if (isOK(restriction)) {
                    return restriction.value.restriction;
                }
                getLogger().error(restriction.errors);
                return undefined;
            }
            return undefined;
        }, [restriction]);

    if (
        availableEndorsementsResult == undefined ||
        restriction == undefined ||
        getActiveUserProfileResult === undefined ||
        isLoadingRestriction == undefined ||
        isGetActiveUserProfileLoading ||
        isLoading
    ) {
        return <Loader />;
    }

    let fullName = '';
    let userTitle = '';
    if (isOK(getActiveUserProfileResult)) {
        const { firstName, lastName, title } = getActiveUserProfileResult.value;
        fullName = `${firstName} ${lastName}'`;
        userTitle = title;
    } else {
        container.get<Logger>(Log).error(getActiveUserProfileResult);
    }

    if (isErr(availableEndorsementsResult)) {
        return (
            <WizardLayout title="Endorsements" onDismiss={onDismiss}>
                <StatusMessage status="error">
                    {(availableEndorsementsResult.errors[0] as ErrorLike).message}
                </StatusMessage>
            </WizardLayout>
        );
    }

    const deserializeResult = JSONSerdes.deserialize(selectedEndorsement?.schema ?? '{}');

    if (isErr(deserializeResult)) {
        return (
            <WizardLayout title="Endorsements" onDismiss={onDismiss}>
                <StatusMessage status="error">
                    {(deserializeResult.errors[0] as ErrorLike).message}
                </StatusMessage>
            </WizardLayout>
        );
    }

    const schema = deserializeResult.value as JSONSchema<unknown>;

    const activeStepIndexComponentToDisplayMap: Map<number, JSX.Element> = new Map<
        number,
        JSX.Element
    >([
        [
            SELECT_ENDORSEMENT_TYPE,
            <EndorsementSelector
                key="selectEndorsementType"
                availableEndorsements={availableEndorsementsResult.value}
                selectedEndorsement={selectedEndorsement}
                setSelectedEndorsement={setSelectedEndorsement}
                onNext={next}
                onDismiss={onDismiss}
            />,
        ],
        [
            DISPLAY_SELECTED_ENDORSEMENT_TYPE,
            <EndorsementJSONSchemaFormSelector
                key="viewEndorsement"
                endorsementTitle={selectedEndorsement?.name ?? ''}
                isPremiumBearing={selectedEndorsement?.isPremiumBearing ?? false}
                restriction={selectedEndorsement?.restriction}
                preBindEndorsements={mutable(restrictionResult?.endorsementList)}
                currentOptions={selectedEndorsement?.currentOptions}
                jsonSchema={schema}
                endorsementContext={{
                    policyId: policy.id,
                    policyState: policy.state ?? undefined,
                    userTitle: userTitle ?? '',
                    company: policy.insuredPartiesList[0]?.name ?? '',
                    address: policy.address ?? '',
                    addressLine2: policy.addressLine2 ?? '',
                    city: policy.city ?? '',
                    zip: policy.zip ?? '',
                    userFullName: fullName ?? '',
                    policyEffectiveDate: policy.startDate,
                    policyExpirationDate: policy.endDate,
                    endorsementType: selectedEndorsement?.type ?? '',
                    origin: 'postbind-endorsement',
                }}
                onDismiss={onDismiss}
                onBack={previous}
            />,
        ],
    ]);

    const getComponentToDisplay = () => {
        const componentToDisplay = activeStepIndexComponentToDisplayMap.get(activeStepIndex);

        if (!componentToDisplay)
            return (
                <StatusMessage status="error">
                    Something went wrong. Please try to reload page or inform engineering.
                </StatusMessage>
            );

        return componentToDisplay;
    };

    return (
        <WizardLayout title="Endorsements" onDismiss={onDismiss}>
            {getComponentToDisplay()}
        </WizardLayout>
    );
}
