import React from 'react';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { APIApplicationRepository } from '../repositories/ApplicationRepository/APIApplicationRepository';
import { IconName, Nullable } from '@embroker/ui-toolkit/v2';
import {
    CoverageEligibility,
    CoverageIneligibilityReasonTypes,
} from '../types/CoverageEligibility';
import { CoverageType } from '../types/CoverageType';
import { GetUserOnboardingQuestionnaireData } from '../../userOrg/useCases/GetUserOnboardingQuestionnaireData';
import { OnboardingPrefillQuestionnaireData } from '../../userOrg/types/OnboardingPrefillQuestionnaireData';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { State } from '@embroker/shotwell/core/types/StateList';
import { GetActiveSession } from '@app/userOrg/useCases/GetActiveSession';

export interface UnderlyingApp {
    title: string;
    icon: IconName;
    note: React.ReactNode;
    value: CoverageType;
    isSelected: boolean;
    isRequired: boolean;
    ineligibilityReasons?: CoverageIneligibilityReasonTypes[];
    onboardingQuestionnaire?: OnboardingPrefillQuestionnaireData;
}

export interface GetLawBundleApplicationsResponse {
    underlyingApps: UnderlyingApp[];
}

export interface GetLawBundleApplications extends UseCase {
    execute(): AsyncResult<
        GetLawBundleApplicationsResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    >;
}

@injectable()
class GetLawBundleApplicationsUseCase extends UseCase implements GetLawBundleApplications {
    public static type = Symbol('Shopping/GetLawBundleApplications');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: APIApplicationRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(GetActiveSession.type) private getActiveSession: GetActiveSession,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetLawBundleApplicationsResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        let stateWithMostAttorneys: Nullable<State> = null;

        const getActiveSessionResp = await this.getActiveSession.execute();

        if (isOK(getActiveSessionResp)) {
            const getApplicantResponse = await this.applicantRepository.getApplicant();

            if (isOK(getApplicantResponse)) {
                stateWithMostAttorneys = getApplicantResponse.value.headquarters.state;
            }
        }

        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return configResponse;
        }

        const getUserOnboardingQuestionnaireDataResp = await execute(
            GetUserOnboardingQuestionnaireData,
        );
        const onboardingQuestionnaire = isOK(getUserOnboardingQuestionnaireDataResp)
            ? getUserOnboardingQuestionnaireDataResp.value
            : undefined;

        const underlyingApps: UnderlyingApp[] = [
            {
                title: 'Lawyers Professional Liability',
                icon: 'law',
                note: 'Protects your practice from malpractice claims and other risks, for yourself and your partners, as well as your employees, contractors and Of Counsel attorneys.',
                value: CoverageType.LPL,
                isSelected: false,
                isRequired: false,
                onboardingQuestionnaire,
            },
            {
                title: 'Cyber Insurance',
                icon: 'thief',
                note: 'According to the American Bar Association, in 2022, 42% of small law firms reported that they had cyber insurance. How are you protecting your practice from cyber risks?',
                value: CoverageType.Cyber,
                isSelected: false,
                isRequired: false,
                onboardingQuestionnaire,
            },
        ];

        if (configResponse.value.isBOPChubbEnabled) {
            const BOPChubbIneligibilityReasons = CoverageEligibility.getIneligibilityReasons(
                'AppTypeCodeListBOPChubb',
                stateWithMostAttorneys,
            );
            underlyingApps.push({
                title: 'Business Owners Policy',
                icon: 'user-shield',
                note: 'Comprehensive coverage for your office and its contents. Combines general liability, commercial property, and business interruption insurance in 1 convenient policy.',
                value: CoverageType.BOP,
                isSelected: false,
                isRequired: false,
                ineligibilityReasons: BOPChubbIneligibilityReasons,
                onboardingQuestionnaire,
            });
        }
        const WCChubbIneligibilityReasons = CoverageEligibility.getIneligibilityReasons(
            'AppTypeCodeListWCChubb',
            stateWithMostAttorneys,
        );

        if (configResponse.value.isWCChubbEnabled) {
            underlyingApps.push({
                title: 'Workers Compensation',
                icon: 'workers-comp',
                note: 'Subject to state law, WC covers employee injuries/disease occurring the course/scope of employment. Employers Liability responds to claims alleging employer negligence.',
                value: CoverageType.WC,
                isSelected: false,
                isRequired: false,
                ineligibilityReasons: WCChubbIneligibilityReasons,
                onboardingQuestionnaire,
            });
        }

        return Success({ underlyingApps });
    }
}

export const GetLawBundleApplications: UseCaseClass<GetLawBundleApplications> =
    GetLawBundleApplicationsUseCase;
