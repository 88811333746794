import { ShoppingCoverageCodeListProfessionalLiability } from '@app/shopping/types/enums';
import { ColumnLayout, Form, StackLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageOptionsTermDefinitions } from '../../../types/enums';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { MPLQuote } from '../entities/MPLQuote';
import { mapFromMPLApiLimit } from '../types/MPLHigherLimit';
import { getMPLLimitsOptions, getMPLRetentionOptions } from '../types/MPLQuoteOptions';

export function MPLCoverageOptions({
    fields,
    disabled = false,
    quote,
    questionnaireData,
}: UnderlyingCoverageProps<MPLQuote>) {
    return (
        <StackLayout gap="12">
            <ColumnLayout gap="16" responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}>
                <Form.Field
                    label={CoverageOptionsTermDefinitions.perClaimAggregateLimit.title}
                    tooltip={CoverageOptionsTermDefinitions.perClaimAggregateLimit.definition}
                    type={fields.mplLimits.type}
                    messages={fields.mplLimits.messages}
                    inputProps={{
                        ...fields.mplLimits.props,
                        filterable: false,
                        items: getMPLLimitsOptions(
                            questionnaireData?.locationWithLargestNumber?.state ??
                                questionnaireData.state,
                            mapFromMPLApiLimit(
                                quote.higherLimit?.[ShoppingCoverageCodeListProfessionalLiability],
                            ),
                        ),
                        readOnly: disabled,
                    }}
                    data-e2e="mpl-limits"
                />
                <Form.Field
                    label={CoverageOptionsTermDefinitions.retention.title}
                    tooltip={CoverageOptionsTermDefinitions.retention.definition}
                    type={fields.mplRetention.type}
                    messages={fields.mplRetention.messages}
                    inputProps={{
                        ...fields.mplRetention.props,
                        filterable: false,
                        items: getMPLRetentionOptions(quote.mplVertical),
                        readOnly: disabled,
                    }}
                    data-e2e="mpl-retentions"
                />
            </ColumnLayout>
        </StackLayout>
    );
}
