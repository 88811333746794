import { ShoppingCoverageCodeListProfessionalLiability } from '@app/shopping/types/enums';
import { ColumnLayout, Form, SelectChangeEvent, StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { LPLQuote } from '../../../../quote/lpl/entities/LPLQuote';
import { mapFromApiLimit } from '../../../../quote/lpl/repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { ClaimsExpenseType, PerClaimLimit } from '../../../../quote/lpl/types/LPLQuoteOptions';
import {
    getDefaultAggregateLimit,
    getDefaultClaimExpenseType,
    getDefaultSeparateClaimExpenseLimit,
    getLPLQuoteFormItems,
    isSeparateClaimsExpensesOptionSelected,
} from '../../../../quote/lpl/view/components/getLPLQuoteFormItems';
import { CoverageOptionsTermDefinitions } from '../../../types/enums';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';

export function LPLBundleCoverageOptions({
    quote,
    value,
    setValue,
    organizationInfo,
    questionnaireData,
    fields,
    disabled = false,
}: UnderlyingCoverageProps<LPLQuote>) {
    const lplQuoteFormItems = useMemo(
        () =>
            getLPLQuoteFormItems(
                value.lplPerClaimLimit,
                value.lplClaimsExpenseType,
                questionnaireData.totalAttorneysThisYear,
                questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state,
                mapFromApiLimit(quote.higherLimit?.[ShoppingCoverageCodeListProfessionalLiability]),
            ),
        [value.lplPerClaimLimit, value.lplClaimsExpenseType, questionnaireData, quote.higherLimit],
    );

    const isSeparateClaimLimitDropdownVisible = isSeparateClaimsExpensesOptionSelected(
        value.lplClaimsExpenseType,
    );

    const handlePerClaimLimitChange = (e: SelectChangeEvent<PerClaimLimit, false>) => {
        // User can choose reduced set of aggregateLimit and separateClaimExpenseLimit options based on perClaimLimit he selected.
        // After change of perClaimLimit default aggregateLimit and separateClaimExpenseLimit to first valid option.
        if (!e.target.value) {
            return;
        }
        const newClaimsExpenseType: ClaimsExpenseType = getDefaultClaimExpenseType(
            questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state,
            e.target.value,
        );
        const newValue = {
            ...value,
            lplPerClaimLimit: e.target.value,
            lplAggregateLimit: getDefaultAggregateLimit(
                e.target.value,
                questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state,
            ),
            lplClaimsExpenseType: newClaimsExpenseType,
            lplSeparateClaimExpenseLimit: getDefaultSeparateClaimExpenseLimit(newClaimsExpenseType),
        };
        setValue(newValue);
    };

    const handleClaimsExpenseTypeChange = (e: SelectChangeEvent<ClaimsExpenseType, false>) => {
        if (!e.target.value) {
            return;
        }
        const newValue = {
            ...value,
            lplClaimsExpenseType: e.target.value,
            lplSeparateClaimExpenseLimit: getDefaultSeparateClaimExpenseLimit(e.target.value),
        };
        setValue(newValue);
    };

    return (
        <StackLayout gap="12">
            <ColumnLayout gap="16" responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}>
                <Form.Field
                    label={CoverageOptionsTermDefinitions.perClaimLimit.title}
                    tooltip={CoverageOptionsTermDefinitions.perClaimLimit.definition}
                    type={fields.lplPerClaimLimit.type}
                    messages={fields.lplPerClaimLimit.messages}
                    inputProps={{
                        ...fields.lplPerClaimLimit.props,
                        filterable: false,
                        items: lplQuoteFormItems.perClaimLimitItems,
                        onChange: handlePerClaimLimitChange,
                        disabled,
                    }}
                    data-e2e="per-claim-limit"
                />
                <Form.Field
                    label={CoverageOptionsTermDefinitions.aggregateLimit.title}
                    tooltip={CoverageOptionsTermDefinitions.aggregateLimit.definition}
                    type={fields.lplAggregateLimit.type}
                    messages={fields.lplAggregateLimit.messages}
                    inputProps={{
                        ...fields.lplAggregateLimit.props,
                        filterable: false,
                        items: lplQuoteFormItems.aggregateLimitItems,
                        disabled,
                    }}
                    data-e2e="aggregate-limit"
                />
            </ColumnLayout>
            <Form.Field
                label={CoverageOptionsTermDefinitions.perClaimDeductible.title}
                tooltip={CoverageOptionsTermDefinitions.perClaimDeductible.definition}
                type={fields.lplPerClaimDeductible.type}
                messages={fields.lplPerClaimDeductible.messages}
                inputProps={{
                    ...fields.lplPerClaimDeductible.props,
                    filterable: false,
                    items: lplQuoteFormItems.perClaimDeductibleItems,
                    disabled,
                }}
                data-e2e="per-claim-deductible"
            />
            <Form.Field
                label={CoverageOptionsTermDefinitions.firstDollarDefenseDamageOnlyDeductible.title}
                tooltip={
                    CoverageOptionsTermDefinitions.firstDollarDefenseDamageOnlyDeductible.definition
                }
                type={fields.lplDeductibleType.type}
                messages={fields.lplDeductibleType.messages}
                inputProps={{
                    ...fields.lplDeductibleType.props,
                    filterable: false,
                    items: lplQuoteFormItems.deductibleTypeItems,
                    disabled,
                }}
                data-e2e="first-dollar"
            />
            <Form.Field
                label={CoverageOptionsTermDefinitions.additionalClaimsExpenseOptions.title}
                tooltip={CoverageOptionsTermDefinitions.additionalClaimsExpenseOptions.definition}
                type={fields.lplClaimsExpenseType.type}
                messages={fields.lplClaimsExpenseType.messages}
                inputProps={{
                    ...fields.lplClaimsExpenseType.props,
                    items: lplQuoteFormItems.claimsExpenseTypeItems,
                    onChange: handleClaimsExpenseTypeChange,
                    disabled,
                }}
                data-e2e="claims-inside-limits"
            />
            {isSeparateClaimLimitDropdownVisible ? (
                <Form.Field
                    label={CoverageOptionsTermDefinitions.separateClaimsExpenseCoverageLimit.title}
                    type={fields.lplSeparateClaimExpenseLimit.type}
                    messages={fields.lplSeparateClaimExpenseLimit.messages}
                    inputProps={{
                        ...fields.lplSeparateClaimExpenseLimit.props,
                        disabled,
                        filterable: false,
                        items: lplQuoteFormItems.separateClaimExpenseLimitItems,
                    }}
                    data-e2e="separate-claims-expense-coverage-limit"
                />
            ) : null}
        </StackLayout>
    );
}
