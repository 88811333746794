import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Attorney {
    readonly name: string;
    readonly averageWeeklyHours: number;
}

export const Attorney = {
    ...defineValidator<Attorney>({
        name: Joi.string().required(),
        averageWeeklyHours: Joi.number().required(),
    }),
    create(attorney: Attorney) {
        return Attorney.validate(attorney);
    },
};

export interface AttorneyRoster {
    readonly list: Attorney[];
}

export const AttorneyRoster = {
    ...defineValidator<AttorneyRoster>({
        list: Joi.array().items(Attorney.schema),
    }),
};
