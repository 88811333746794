import { APIModule } from '@embroker/shotwell-api/app';
import { QUEL } from '@embroker/shotwell-api/quel';
import { container, ContainerModule } from '@embroker/shotwell/core/di';
import { WorkflowInstance } from '@embroker/shotwell/core/workflow';
import { EffectHandler } from '@embroker/shotwell/core/workflow/effects';
import { Orchestrator } from '@embroker/shotwell/core/workflow/Orchestrator';
import { AnalyticsModule } from './analytics/services';
import { ExperimentationModule } from './experimentation/services';
import { BrokerDashboardModule } from './brokerDashboard';
import { BundleQuoteModule } from './bundle';
import { NotificationsModule } from './notifications';
import { ShoppingQuestionerModule } from './shoppingQuestioner';
import { CertificatesModule } from './certificates';
import { ClaimsModule } from './claims';
import { ConfigModule } from './config';
import { DocumentModule } from './documents';
import { EndorsementModule } from './endorsement';
import { ESPEndorsementModule } from './endorsement/esp';
import { EndorsementIntakeModule } from './endorsement/intake';
import { PCoMLEndorsementModule } from './endorsement/pcoml';
import { getEnvVar } from './env';
import { FeatureRolloutModule } from './featureRollout';
import { HigherLimitsSupplementalQuestionnaireModule } from './higherLimitsSupplementalQuestionnaire';
import { IndustriesModule } from './industries';
import type { Module } from './Module';
import { MarketingModule } from './marketing';
import { PaymentsModule } from './payments';
import { LplRenewalModule } from './lplRenewal';
import { PolicyModule } from './policy';
import { QuoteModule } from './quote';
import { BOPCNAQuoteModule } from './quote/bopCna';
import { BrokerQuoteModule } from './quote/brokerage';
import { CrimeQuoteModule } from './quote/crime';
import { CyberQuoteModule } from './quote/cyber';
import { EmbrokerExcessModule } from './quote/embrokerExcess';
import { ESPQuoteModule } from './quote/esp';
import { ESPRenewalQuoteModule } from './quote/espRenewal';
import { LPLQuoteModule } from './quote/lpl';
import { PCoMLQuoteModule } from './quote/pcoml';
import { WCGAQuoteModule } from './quote/wcga';
import { RecommendationsModule } from './recommendations';
import { ServerEnumsModule } from './serverEnums';
import { ShoppingModule } from './shopping';
import { SummaryModule } from './summary';
import { SupportModule } from './support';
import { TasksModule } from './tasks';
import { UserOrgModule } from './userOrg';
import { AppProps } from './view';
import { AppContext, AppContextStore } from './view/AppContext';
import { loadRoutes } from './view/routes';
import { RouteDefinitions } from './view/routes/Route';
import { HartfordBopModule } from '@app/quote/hartford-bop';
import { DigitalEndorsementsModule } from './digitalEndorsements';
import { SandboxModule } from './sandbox';
import { PublicModule } from './public';
import { DataDrivenFormModule } from './view/components/DataDrivenForm';

/**
 * Loads all DI container modules and performs
 * all other required application bootstrap steps.
 */
export async function bootstrapApplication(): Promise<AppProps> {
    const modules: Module[] = [
        ConfigModule,
        APIModule,
        UserOrgModule,
        MarketingModule,
        PaymentsModule,
        DocumentModule,
        FeatureRolloutModule,
        IndustriesModule,
        CertificatesModule,
        PolicyModule,
        ServerEnumsModule,
        AnalyticsModule,
        ExperimentationModule,
        ClaimsModule,
        ShoppingModule,
        TasksModule,
        RecommendationsModule,
        LPLQuoteModule,
        ESPQuoteModule,
        QuoteModule,
        WCGAQuoteModule,
        CrimeQuoteModule,
        BundleQuoteModule,
        NotificationsModule,
        CyberQuoteModule,
        HartfordBopModule,
        EndorsementModule,
        ESPEndorsementModule,
        PCoMLEndorsementModule,
        EndorsementIntakeModule,
        SummaryModule,
        PCoMLQuoteModule,
        ESPRenewalQuoteModule,
        BOPCNAQuoteModule,
        BrokerQuoteModule,
        BrokerDashboardModule,
        SupportModule,
        HigherLimitsSupplementalQuestionnaireModule,
        EmbrokerExcessModule,
        ShoppingQuestionerModule,
        LplRenewalModule,
        DigitalEndorsementsModule,
        SandboxModule,
        PublicModule,
        DataDrivenFormModule,
    ];

    container.load(
        ...modules
            .filter((module) => module.container != null)
            .map((module) => module.container as ContainerModule),
    );

    const routes = loadRoutes(
        ...modules
            .filter((module: Module) => module.routes != null)
            .map((module: Module) => module.routes as RouteDefinitions<AppContext>),
    );

    const context = { ...AppContextStore.context };

    for (const module of modules) {
        if (typeof module.bootstrap === 'function') {
            Object.assign(context, await module.bootstrap());
        }
    }

    AppContextStore.update(context);

    const orchestrator = new Orchestrator();
    orchestrator.addEffectHandler(...container.getAll<EffectHandler>('EffectHandler'));
    orchestrator.addWorkflow(
        ...modules.flatMap((module) =>
            (module.workflows ?? []).map(
                (workflow) => new WorkflowInstance(orchestrator, workflow),
            ),
        ),
    );
    orchestrator.start();

    const quelServiceUrl = getEnvVar('QUEL_API_SERVER_URL');
    QUEL.setServiceURL(quelServiceUrl ?? '');

    return {
        context,
        routes,
    };
}
