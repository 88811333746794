import { Joi, Schema } from '@embroker/shotwell/core/validation/schema';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ActionHandler, createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Spinner,
    StatusMessage,
    ButtonBar,
    TextButton,
    Button,
    Form,
    Text,
    StackLayout,
    StatusMessageList,
} from '@embroker/ui-toolkit/v2';
import { isSameDay, isValid, startOfDay } from 'date-fns';
import React, { Fragment, useCallback, useMemo } from 'react';
import { ESPEndorsementPolicyAddressData } from '../../types/ESPEndorsementPolicy';
import {
    LocationFieldDefinition,
    LocationFieldSet,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';

export interface ESPEndorsementChangeAddressFormData {
    effectiveDate?: Date;
    address: MailingAddress;
}

interface CreateEspEndorsementChangeAddressFormParams {
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    address: ESPEndorsementPolicyAddressData;
    onSave: ActionHandler<ESPEndorsementChangeAddressFormData>;
}

function createEspEndorsementChangeAddressForm({
    effectivePeriodStart,
    effectivePeriodEnd,
    address,
    onSave,
}: CreateEspEndorsementChangeAddressFormParams) {
    return createForm<ESPEndorsementChangeAddressFormData>({
        fields: {
            address: LocationFieldDefinition,
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
                formatValidationError: (error) => {
                    if (
                        error.details.validator == 'date.min' ||
                        error.details.validator == 'date.max'
                    ) {
                        return 'The effective date does not coincide with the policy period.';
                    }
                    if (error.details.validator == 'any.required') {
                        return 'You must set an effective date.';
                    }
                    return error.message;
                },
            },
        },
        validator: Joi.object()
            .keys({
                address: Joi.any(),
                effectiveDate: Joi.any(),
            })
            .custom(
                (
                    formData: ESPEndorsementChangeAddressFormData,
                    helpers: Schema.CustomHelpers<number>,
                ): ESPEndorsementChangeAddressFormData | Schema.ErrorReport => {
                    if (
                        formData.address.addressLine1 == address.addressLine1 &&
                        formData.address.addressLine2 == address.addressLine2 &&
                        formData.address.city == address.city &&
                        formData.address.zip == address.zipCode
                    ) {
                        return helpers.error('addressData.pristine');
                    }

                    return formData;
                },
            ),
        formatSubmitErrors: (errors) => {
            if (errors.length === 0) {
                return [];
            }
            return ["You haven't made any changes to your address information."];
        },
        submit: onSave,
    });
}

interface ESPEndorsementSaveAddressProps {
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    address: ESPEndorsementPolicyAddressData;
    hasRenewalApplication?: boolean;
    onSave: ActionHandler<ESPEndorsementChangeAddressFormData>;
    onClose: () => void;
    onGoToContactUs: () => void;
}

export function ESPEndorsementAddress({
    effectivePeriodStart,
    effectivePeriodEnd,
    address,
    onSave,
    onClose,
    onGoToContactUs,
    hasRenewalApplication,
}: ESPEndorsementSaveAddressProps) {
    const espChangeAddressForm = useMemo(
        () =>
            createEspEndorsementChangeAddressForm({
                effectivePeriodStart,
                effectivePeriodEnd,
                address,
                onSave,
            }),
        [effectivePeriodStart, effectivePeriodEnd, address, onSave],
    );

    const { value, setValue, status, submit, fields, messages } = useForm(espChangeAddressForm, {
        effectiveDate: undefined,
        address: {
            addressLine1: address.addressLine1 ?? null,
            addressLine2: address.addressLine2 ?? null,
            city: address.city ?? null,
            state: address.state ?? null,
            zip: address.zipCode ?? null,
        },
    });

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== undefined && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleAddressOnChange = (address: Partial<MailingAddress>) => {
        setValue({
            ...value,
            address: address as MailingAddress,
        });
    };

    const isLoading = status === 'submitting';

    return (
        <Form>
            {isLoading && <Spinner appearance="transparent" />}
            <StackLayout gap="32">
                <Text style="heading 3">Update Address</Text>
                <StatusMessageList messages={messages} status="error" />
                <StackLayout>
                    <Text style="body 1">Address</Text>
                    <StackLayout gap="8">
                        <LocationFieldSet
                            fieldValue={fields.address.props.value}
                            messages={fields.address.messages}
                            onChange={handleAddressOnChange}
                            autoSuggest={false}
                            disableState
                        />
                        <StatusMessage status="info">
                            <TextButton onClick={onGoToContactUs}>Contact Embroker</TextButton> to
                            make a change to your state.
                        </StatusMessage>
                    </StackLayout>
                </StackLayout>
                <Form.Field
                    inputProps={{
                        ...fields.effectiveDate.props,
                        onChange: handleEffectiveDateChange,
                        disabled: isLoading,
                        note: (
                            <Fragment>
                                Current policy period:&nbsp;
                                <DateDisplay value={effectivePeriodStart} />
                                &nbsp;-&nbsp;
                                <DateDisplay value={effectivePeriodEnd} />
                            </Fragment>
                        ),
                    }}
                    title="Effective date"
                    type="date"
                    messages={fields.effectiveDate.messages}
                    data-e2e="effective-date"
                />
                {hasRenewalApplication && (
                    <StatusMessage status="warning">
                        The renewal application started prior to this change. Processing the request
                        will result in the renewal application being reset.
                    </StatusMessage>
                )}
                <ButtonBar>
                    <Button disabled={isLoading} onClick={submit} data-e2e="save">
                        Save
                    </Button>
                    <TextButton disabled={isLoading} onClick={onClose} data-e2e="cancel">
                        Cancel
                    </TextButton>
                </ButtonBar>
            </StackLayout>
        </Form>
    );
}
