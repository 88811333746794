import { container } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Loader, useModal } from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormEngine } from '../../../view/components';
import { useFormEngineEvent } from '../../../view/components/FormEngine/hooks/useFormEngineEvent';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ApplicationEarlyClearanceFailed } from '../../entities/Application';
import { DeepLinkData } from '../../types/DeepLinkData';
import { AppTypeCode } from '../../types/enums';
import { Place } from '../../types/Place';
import { CreateApplications } from '../../useCases/CreateApplications';
import { GetApplicant } from '../../useCases/GetApplicant';
import { GetApplicationCreationQuestionnaire } from '../../useCases/GetApplicationCreationQuestionnaire';
import { FormEventName, PublishFormEvents } from '../../useCases/PublishFormEvents';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { ErrorCode } from '../../errors';
import { DeclinedModal } from './DeclinedModal';
import { ApplicationQuestionnaireTitle } from '../../../view/components/ApplicationQuestionnaireTitle.view';
import { AppContext } from '../../../view/AppContext';
import { CreateWCEmployeeDataTable } from '@app/shopping/useCases/CreateWCEmployeeDataTable';

interface ApplicationCreationQuestionnaireProps {
    deepLinkData: DeepLinkData;
}

export function ApplicationCreationQuestionnaire({
    deepLinkData,
}: ApplicationCreationQuestionnaireProps) {
    const { navigate } = useNavigation();
    const { globalConfig } = useContext(AppContext);
    const [formEngine, setFormEngine] = useState<any>(null);
    const [appTypeList, setAppTypeList] = useState<Immutable<AppTypeCode[]>>(
        deepLinkData.appTypes ?? [],
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { result } = useUseCase(GetApplicationCreationQuestionnaire, {
        deepLinkData: {
            appTypes: deepLinkData.appTypes,
            naicsCode: deepLinkData.naicsCode,
            raisedFunding: deepLinkData.raisedFunding,
            firstPage: deepLinkData.firstPage,
            includeTechEO: deepLinkData.includeTechEO,
        },
        globalConfig,
    });
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const declinedModal = useModal();

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    useEffect(() => {
        if (result && isOK(result)) {
            const { appTypeList, formEngine } = result.value;
            setAppTypeList(appTypeList);
            setFormEngine(formEngine);
        }
    }, [result]);

    useEffect(() => {
        if (formEngine) {
            publishFormEvent(FormEventName.Viewed, 'Company Profile', formEngine, appTypeList);
            return () => {
                formEngine.dispose();
            };
        }
    }, [appTypeList, formEngine]);

    useFormEngineEvent(formEngine, 'show', () => {
        publishFormEvent(FormEventName.Viewed, 'Company Profile', formEngine, appTypeList);
    });

    useFormEngineEvent(formEngine, 'hide', () => {
        publishFormEvent(FormEventName.Saved, 'Company Profile', formEngine, appTypeList);
    });

    useFormEngineEvent(formEngine, 'submit', () => {
        async function handleSubmit() {
            publishFormEvent(FormEventName.Saved, 'Company Profile', formEngine, appTypeList);
            setIsSubmitting(true);
            const snapshot = formEngine.getSnapshot();
            const questionnaireData = snapshot.value;
            const createApplicationsResponse = await execute(CreateApplications, {
                appTypeList: appTypeList,
                questionnaireData,
                abortSignal: abortController.signal,
            });
            if (isErr(createApplicationsResponse)) {
                const error = createApplicationsResponse.errors[0];
                if (error.code == ErrorCode.NoEligibleAppTypeFound) {
                    setIsSubmitting(false);
                    declinedModal.show();
                    return;
                }
                navigateToErrorPage(navigate, createApplicationsResponse.errors);
                return;
            }

            if (!createApplicationsResponse.value.nameCleared) {
                const event: ApplicationEarlyClearanceFailed = {
                    id: UUID.create(),
                    origin: 'Application',
                    name: 'EarlyClearanceFailed',
                    createdAt: new Date(Date.now()),
                };
                await eventBus.publish(event);
                navigate('/shopping/early-quote-reservation');
                return;
            }

            const getApplicantResponse = await execute(GetApplicant);
            if (isErr(getApplicantResponse)) {
                navigateToErrorPage(navigate, getApplicantResponse.errors);
                return;
            }

            const {
                applicant: { locations },
            } = getApplicantResponse.value;

            const createWCTableResponse = await execute(CreateWCEmployeeDataTable, {
                locations: locations as Place[],
                naicsCode: questionnaireData.naics_code,
            });
            if (isErr(createWCTableResponse)) {
                navigateToErrorPage(navigate, createWCTableResponse.errors);
                return;
            }

            const { applicationIdList } = createApplicationsResponse.value;

            if (applicationIdList.length === 0) {
                navigateToErrorPage(navigate, [
                    OperationFailed({
                        message: 'Failed to obtain created application',
                    }),
                ]);
                return;
            }

            const firstApplicationId = applicationIdList[0];

            const uri = URI.build('/shopping/application', {
                applicationId: firstApplicationId,
            });
            navigate(uri);
        }
        handleSubmit().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    });

    if (result && isErr(result)) {
        return <ErrorPage errors={result.errors} />;
    }

    if (!formEngine || isSubmitting) {
        return <Loader />;
    }

    const goToDashboard = () => navigate('/summary');

    const progressBarRange = { max: 20 };
    return (
        <React.Fragment>
            <DeclinedModal modal={declinedModal} />
            <FormEngine
                instance={formEngine}
                navigation
                onExitFullScreen={() => {
                    publishFormEvent(
                        FormEventName.SaveAndExitClicked,
                        'Company Profile',
                        formEngine,
                        appTypeList,
                    );
                    goToDashboard();
                }}
                progressBarRange={progressBarRange}
                progressBarTitleRenderer={ApplicationQuestionnaireTitle}
                dismissAppearance="save-and-exit"
            />
        </React.Fragment>
    );
}

function publishFormEvent(
    eventName: FormEventName,
    formStage: string,
    formEngine: any,
    appTypeList: readonly AppTypeCode[],
) {
    const visiblePage = formEngine.machine.state.visiblePage;
    const pageList = formEngine.machine.state.pageList;
    let formName = pageList[visiblePage]?.machine.state.title;

    if (formStage === 'Company Profile' && formName === undefined) {
        formName = 'Basic Info';
    }

    execute(PublishFormEvents, {
        eventName,
        formStage,
        formName,
        applicationId: null,
        appTypeList,
        isRenewal: false, // Since the application is not yet created
    }).then((publishResult) => {
        if (isErr(publishResult)) {
            container.get<Logger>(Log).error(publishResult.errors);
            return;
        }
    });
}
