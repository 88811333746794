import { AppTypeCode, AppTypeCodeList } from './enums';
import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { MPLValidNaicsCodes } from '../../userOrg/types/MPLValidNaicsCodes';
import { MutuallyExclusiveProps } from '@embroker/ui-toolkit/v2';
import { NAICS_CODE_TO_VERTICAL, Vertical } from '@app/userOrg/types/enums';
import { Nullable } from '@embroker/shotwell/core/types';

const IneligibleStatesAppTypeMap: { [key: string]: State[] } = {
    AppTypeCodeListWCChubb: ['OH', 'ND', 'WA', 'WY', 'HI', 'AK', 'ME', 'MS', 'RI', 'FL', 'MN'],
    AppTypeCodeListGAWorkersCompensation: ['OH', 'ND', 'WA', 'WY'],
};

const ineligibleVerticalsAppTypeMap: { [key: string]: Vertical[] } = {
    AppTypeCodeListCyberCowbell: ['TechCompanies'],
};

const StatesWithManualProductLiabilityAvailable = ['NY', 'CA', 'CO', 'KY'];

type StateAvailabilityType = Partial<
    MutuallyExclusiveProps<{
        availableIn: State[];
        notAvailableIn: State[];
    }>
> & { monopolisticStates?: State[] };

const stateAvailabilityAppTypeMap: { [key in AppTypeCode]?: StateAvailabilityType } = {
    AppTypeCodeListBOPChubb: {
        notAvailableIn: [],
    },
    AppTypeCodeListWCChubb: {
        notAvailableIn: ['HI', 'AK', 'ME', 'MS', 'RI', 'FL', 'MN'],
        monopolisticStates: ['OH', 'ND', 'WA', 'WY'],
    },
    AppTypeCodeListGAWorkersCompensation: {
        monopolisticStates: ['OH', 'ND', 'WA', 'WY'],
    },
    AppTypeCodeListManualDefaultPL: {
        availableIn: ['NY', 'CA', 'CO', 'KY'],
    },
};

export type CoverageIneligibilityReasonTypes =
    | 'not-available-in-state'
    | 'monopolistic-state'
    | 'not-available-for-vertical';

export interface CoverageEligibility {
    appType: AppTypeCode;
}
export const CoverageEligibility = valueObject({
    ...defineValidator<CoverageEligibility>({
        appType: Joi.string().valid(...AppTypeCodeList),
    }),
    isStateEligabile(appType: AppTypeCode | undefined, state: State | string | null): boolean {
        const ineligibleStates = IneligibleStatesAppTypeMap[appType || ''] || [];
        // We can be loose with the typing here as we are only interested when this condition is true.
        return !ineligibleStates.includes(state as State);
    },
    isManualProductLiabilityEligibile(
        state: Nullable<State | string>,
        naicsCode: Nullable<string>,
    ): boolean {
        const shouldManualProductLiabilityBeRemoved =
            MPLValidNaicsCodes.isNaicCodeValid(naicsCode || '') &&
            !StatesWithManualProductLiabilityAvailable.includes(state || '');
        return shouldManualProductLiabilityBeRemoved;
    },
    getIneligibilityReasons(
        appType: AppTypeCode | undefined,
        organizationState: Nullable<State | string>,
        naicsCode?: Nullable<string>,
    ): CoverageIneligibilityReasonTypes[] {
        const ineligibilityReasons: CoverageIneligibilityReasonTypes[] = [];
        if (!appType || !organizationState) {
            return ineligibilityReasons;
        }
        const state = organizationState as State;
        const { notAvailableIn, monopolisticStates, availableIn } =
            stateAvailabilityAppTypeMap[appType] || {};

        if (notAvailableIn && notAvailableIn.includes(state)) {
            ineligibilityReasons.push('not-available-in-state');
        }
        if (availableIn && availableIn.includes(state)) {
            ineligibilityReasons.push('not-available-in-state');
        }

        if (monopolisticStates && monopolisticStates.includes(state)) {
            ineligibilityReasons.push('monopolistic-state');
        }

        const ineligibleVerticals = ineligibleVerticalsAppTypeMap[appType];
        const vertical = NAICS_CODE_TO_VERTICAL[naicsCode || ''];
        if (ineligibleVerticals && ineligibleVerticals.includes(vertical)) {
            ineligibilityReasons.push('not-available-for-vertical');
        }

        const ineligibilityReasonsSet = new Set(ineligibilityReasons);
        return Array.from(ineligibilityReasonsSet);
    },
});
