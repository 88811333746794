import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '@app/view/components/ModalLayout.view';

interface ClaimRequestThankYouModalInput {
    modal: ModalState & ModalActions;
}

export function ClaimRequestThankYouModal({ modal }: ClaimRequestThankYouModalInput) {
    const { navigate } = useNavigation();

    const handleClose = () => {
        modal.hide();
        navigate('/claims');
    };

    return (
        <Modal {...modal} size="small" dismissable onClose={handleClose}>
            <ModalLayout
                title="Your claim has been submitted."
                primaryAction={{
                    label: 'Back to Claims',
                    onClick: handleClose,
                }}
            >
                We’ve received your claim submission. You’ll receive a formal acknowledgement letter
                shortly that will include an assigned Claim Number and contact information for your
                Claim Professional.
            </ModalLayout>
        </Modal>
    );
}
