import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    InvoiceTable,
    Loader,
    Modal,
    StackLayout,
    StatusMessage,
    Text,
    Tooltip,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ExcessQuote } from '../../entities/ExcessQuote';
import { ExcessQuoteOptions } from '../../types/ExcessQuoteOptions';
import { ReQuoteExcess } from '../../useCases/ReQuoteExcess';

interface ExcessQuoteBreakdownProps {
    quote: ExcessQuote;
    className: string;
    limit: number;
    effectiveDate: Date;
    expirationDate: Date;
    isLimitDirty: boolean;
    isEffectiveDateDirty: boolean;
    isEffectiveDateError: boolean;
    isBroker: boolean;
    applicationId: UUID;
    abortController: AbortController;
    onRequote: (requote: ExcessQuote) => void;
}
const isKentuckyState = (quote: ExcessQuote): boolean => {
    return quote.questionnaire.state == 'KY';
};

const isSigned = (quote: ExcessQuote): boolean => {
    return quote.status == 'signed';
};

type HandleRecalculateAndErrorArgs = Pick<
    ExcessQuoteBreakdownProps,
    'isEffectiveDateError' | 'quote' | 'isBroker'
> & {
    shouldRecalculate: boolean;
    handleRecalculate: () => void;
    onContinue: () => void;
};

function handleRecalculateAndError({
    isEffectiveDateError,
    shouldRecalculate,
    quote,
    isBroker,
    handleRecalculate,
    onContinue,
}: HandleRecalculateAndErrorArgs) {
    if (isEffectiveDateError) {
        return (
            <Button size="regular" appearance="primary" disabled>
                Continue
            </Button>
        );
    }
    return (
        <React.Fragment>
            {shouldRecalculate && (
                <Button size="regular" appearance="primary" onClick={handleRecalculate}>
                    Recalculate
                </Button>
            )}
            {!shouldRecalculate && (
                <Button
                    size="regular"
                    appearance="primary"
                    onClick={onContinue}
                    disabled={!isSigned(quote) && !isBroker}
                >
                    Continue
                </Button>
            )}
        </React.Fragment>
    );
}

export function ExcessQuoteBreakdown({
    quote,
    className,
    limit,
    effectiveDate,
    expirationDate,
    isLimitDirty,
    isEffectiveDateDirty,
    isEffectiveDateError,
    isBroker,
    applicationId,
    abortController,
    onRequote,
}: ExcessQuoteBreakdownProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const proceedToSubmitForUwReviewModal = useModal();
    const { navigate } = useNavigation();
    const handleRecalculate = async () => {
        if (isEffectiveDateError) {
            return;
        }
        setIsSubmitting(true);
        const quoteOptions: ExcessQuoteOptions = {
            limit: limit,
            quoteCyber: quote.options.quoteCyber,
            effectiveDate: effectiveDate,
            expirationDate: expirationDate,
        };
        const reQuoteResult = await execute(ReQuoteExcess, {
            applicationId: applicationId,
            excessQuoteOptions: quoteOptions,
            abortSignal: abortController.signal,
        });
        if (isErr(reQuoteResult)) {
            setIsSubmitting(false);
            return Failure(
                OperationFailed({ message: 'Requote Failed', errors: reQuoteResult.errors }),
            );
        }
        setIsSubmitting(false);
        onRequote(reQuoteResult.value as ExcessQuote);
    };
    const onContinue = () => {
        proceedToSubmitForUwReviewModal.show();
    };
    const isKentucky = isKentuckyState(quote);
    const shouldRecalculate = isLimitDirty || isEffectiveDateDirty;
    return (
        <StackLayout gap="32">
            {isSubmitting && <Loader variant="loading" />}
            <InvoiceTable className={className}>
                <Text color="ui-500">Coverages</Text>
                <InvoiceTable.Section>
                    <InvoiceTable.Item title="Excess Tech E&O/Cyber">
                        <MoneyDisplay value={quote.totalPremium} />
                    </InvoiceTable.Item>
                </InvoiceTable.Section>
                <InvoiceTable.Section>
                    <InvoiceTable.Subtotal title="Total coverages">
                        <MoneyDisplay value={quote.totalPremium} />
                    </InvoiceTable.Subtotal>
                </InvoiceTable.Section>
                <InvoiceTable.Section>
                    <InvoiceTable.Subtotal title="Subtotal">
                        <MoneyDisplay value={quote.totalPremium} />
                    </InvoiceTable.Subtotal>
                    <InvoiceTable.Item
                        title={itemTitle(
                            'Taxes ',
                            'Surplus Lines Tax is required on all surplus lines insurance transactions and vary by state.',
                        )}
                    >
                        <MoneyDisplay value={quote.taxes} />
                    </InvoiceTable.Item>
                    {quote.details.fireMarshalTax && (
                        <InvoiceTable.Item title={itemTitle('Fire Marshal Tax')}>
                            <MoneyDisplay value={quote.details.fireMarshalTax} />
                        </InvoiceTable.Item>
                    )}
                    <InvoiceTable.Item
                        title={itemTitle(
                            'Fees (excluding transaction fees based on your choice of payment)',
                            'Often referred to as "Stamping Fees," Surplus Lines Fees are only required by certain states. In the applicable states, Surplus Lines Fees are charged on all insurance transactions.',
                        )}
                    >
                        <MoneyDisplay value={quote.fees} />
                    </InvoiceTable.Item>
                    {quote.details.clearingHouseFee && (
                        <InvoiceTable.Item title={itemTitle('Clearing House Fee')}>
                            <MoneyDisplay value={quote.details.clearingHouseFee} />
                        </InvoiceTable.Item>
                    )}
                    {quote.details.surplusLinesServiceChargeFee && (
                        <InvoiceTable.Item title={itemTitle('Surplus Lines Service Charge Fee')}>
                            <MoneyDisplay value={quote.details.surplusLinesServiceChargeFee} />
                        </InvoiceTable.Item>
                    )}
                    {quote.details.mississippiWindstormUnderwritingAssociationFee && (
                        <InvoiceTable.Item
                            title={itemTitle('Mississippi Windstorm Underwriting AssociationFee')}
                        >
                            <MoneyDisplay
                                value={quote.details.mississippiWindstormUnderwritingAssociationFee}
                            />
                        </InvoiceTable.Item>
                    )}
                    {quote.questionnaire.broker_email && quote.details.embrokerAccessFee && (
                        <InvoiceTable.Item title={itemTitle('Embroker Access Fee')}>
                            <MoneyDisplay value={quote.details.embrokerAccessFee} />
                        </InvoiceTable.Item>
                    )}
                    {isKentucky && (
                        <InvoiceTable.Item title={itemTitle('Kentucky-Specific taxes and fees')}>
                            <Text style="label 1">See note</Text>
                        </InvoiceTable.Item>
                    )}
                    <InvoiceTable.Total title="Total: ">
                        {!isKentucky && <MoneyDisplay value={quote.totalPayable} />}
                        {isKentucky && <Text style="heading 5">Forthcoming</Text>}
                    </InvoiceTable.Total>
                    {isKentucky && (
                        <StatusMessage status="helptext">
                            Various municipalities (towns, counties, etc.) in the state of Kentucky
                            are permitted to charge taxes on insurance premium per state law. These
                            tax amounts are typically between 2-16% of premium and are in addition
                            to any other applicable taxes, fees or surcharges. Your policy and
                            invoice will have these taxes included.
                        </StatusMessage>
                    )}
                    {handleRecalculateAndError({
                        isEffectiveDateError,
                        shouldRecalculate,
                        quote,
                        isBroker,
                        handleRecalculate,
                        onContinue,
                    })}
                </InvoiceTable.Section>
            </InvoiceTable>
            <Modal {...proceedToSubmitForUwReviewModal} size="small">
                <StackLayout gap="24">
                    <Text style="heading 3" data-e2e="a-quick-reminder">
                        A quick reminder:
                    </Text>
                    <Text style="body 2">
                        After selecting &quot;Continue&quot; you will need to upload all underlying
                        information for review by an underwriter. The Terms quoted are subject to
                        change based on those documents and any additional information used in
                        underwriting. Note that an Embroker policy can only be bound upon our
                        underwriter&apos;s acceptance of complete underlying information.
                    </Text>
                    <Button
                        onClick={() => {
                            navigate(
                                URI.build('/shopping/excess-submit-for-review', {
                                    applicationId,
                                }),
                            );
                        }}
                        data-e2e="submit-for-review"
                    >
                        Submit for Review
                    </Button>
                </StackLayout>
            </Modal>
        </StackLayout>
    );
}

function itemTitle(title: string, tooltipText?: string): React.ReactNode {
    return (
        <Text>
            {title}
            {tooltipText && <Tooltip iconSize="small" text={tooltipText} />}
        </Text>
    );
}
