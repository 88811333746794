import React from 'react';
import { InvoiceTable, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { ESPQuote } from '../entities/ESPQuote';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ESPCoverageRateItem } from '@app/quote/esp/types/ESPRate';
import { USD } from '@embroker/shotwell/core/types/Money';

const coverageMetadataMap = {
    do: {
        title: 'Directors and Officers',
        insurer: 'Insurer: Everspan Indemnity Insurance Company',
    },
    epli: {
        title: 'Employment Practices Liability',
        insurer: 'Insurer: Everspan Indemnity Insurance Company',
    },
    fiduciary: {
        title: 'Fiduciary Liability',
        insurer: 'Insurer: Everspan Indemnity Insurance Company',
    },
    techCyber: {
        title: 'Technology Error and Omissions / Cyber',
        insurer: 'Insurer: Everspan Indemnity Insurance Company',
    },
    eoCyber: {
        title: 'Technology Error and Omissions / Cyber',
        insurer: 'Insurer: Everspan Indemnity Insurance Company',
    },
};

export function ESPBundleSummary({
    questionnaireData,
    quote,
}: BundleSummaryComponentProps<ESPQuote>) {
    const isMississippi = questionnaireData.state === 'MS';
    return (
        <StackLayout gap="16">
            {quote.details.coverages.map((coverage) => (
                <ESPInvoiceItem quote={quote} coverage={coverage} key={coverage.coverageType} />
            ))}
            <InvoiceTable.Item
                style={{ alignItems: 'center' }}
                title={
                    <Text style="body 1" as="span">
                        Taxes
                    </Text>
                }
            >
                <Text style="body 1" as="span" data-e2e="lpl-premium">
                    <MoneyDisplay value={quote.details.rates.taxes} />
                </Text>
            </InvoiceTable.Item>
            <InvoiceTable.Item
                style={{ alignItems: 'center' }}
                title={
                    <Text style="body 1" as="span">
                        Fees
                    </Text>
                }
            >
                <Text style="body 1" as="span" data-e2e="lpl-premium">
                    <MoneyDisplay value={quote.details.rates.feesAndSurcharges} />
                </Text>
            </InvoiceTable.Item>
            {isMississippi && (
                <InvoiceTable.Item
                    style={{ alignItems: 'center' }}
                    title={
                        <Text style="body 1" as="span">
                            Mississippi Windstorm Underwriting Fee
                        </Text>
                    }
                >
                    <Text style="body 1" as="span" data-e2e="lpl-premium">
                        <MoneyDisplay value={quote.details.rates.mwuaFee ?? USD(0)} />
                    </Text>
                </InvoiceTable.Item>
            )}
        </StackLayout>
    );
}

const ESPInvoiceItem = ({
    quote,
    coverage,
}: {
    quote: ESPQuote;
    coverage: ESPCoverageRateItem;
}) => {
    const coverageMetadata = coverageMetadataMap[coverage.coverageType];
    return (
        <InvoiceTable.Item
            style={{ alignItems: 'center' }}
            title={
                <React.Fragment>
                    <Text style="body 1" as="span">
                        {coverageMetadata.title}
                    </Text>
                    <br />
                    <Text style="body 2" as="span" data-e2e="lpl-insurer">
                        {coverageMetadata.insurer}
                    </Text>
                </React.Fragment>
            }
        >
            <Text style="body 1" as="span" data-e2e="lpl-premium">
                <MoneyDisplay value={coverage.premium} />
            </Text>
        </InvoiceTable.Item>
    );
};
