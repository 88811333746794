import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { BOPHartfordQuote, PurchaseResponse } from '../../entities/BOPHartfordQuote';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface BOPHartfordQuoteRepository {
    getLatestQuote(
        applicationId: UUID,
    ): AsyncResult<BOPHartfordQuote, InvalidArgument | OperationFailed>;

    reQuote(
        applicationId: UUID,
        effectiveDate: Date,
        bopCoverageType: string,
        bppLimit: Money,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    purchaseHartfordBOP(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseResponse, InvalidArgument | OperationFailed>;

    getPaymentUrl(path: string): AsyncResult<string, InvalidArgument | OperationFailed>;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
}

export const BOPHartfordQuoteRepository = Symbol('BOPHartfordQuoteRepository');
