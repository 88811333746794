import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Immutable } from '@embroker/shotwell/core/types';
import { Locations } from '@app/shopping/types/Location';
import { AttorneyRoster } from '@app/shopping/types/Attorney';
import { LPLAreaOfPracticeList } from '@app/shopping/types/LPLAreaOfPractice';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { AnswerMissing, ApplicationNotFound, QuestionnaireNotFound } from '@app/shopping/errors';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { ApplicationRepository } from '@app/shopping/repositories/ApplicationRepository';

export interface GetReviewRenewalDataRequest {
    applicationId: UUID;
}

export interface GetReviewRenewalDataResponse {
    locations: Immutable<Locations>;
    attorneyRoster: Immutable<AttorneyRoster>;
    areasOfPractice: Immutable<LPLAreaOfPracticeList>;
}

export interface GetReviewRenewalData extends UseCase {
    execute(
        input: GetReviewRenewalDataRequest,
    ): AsyncResult<
        GetReviewRenewalDataResponse,
        ApplicationNotFound | QuestionnaireNotFound | AnswerMissing | OperationFailed
    >;
}

@injectable()
class GetReviewRenewalDataUseCase extends UseCase implements GetReviewRenewalData {
    public static type = Symbol('Shopping/GetReviewRenewalData');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        input: GetReviewRenewalDataRequest,
    ): AsyncResult<
        GetReviewRenewalDataResponse,
        ApplicationNotFound | QuestionnaireNotFound | AnswerMissing | OperationFailed
    > {
        const response = await this.applicationRepository.getReviewStreamlineRenewalData(
            input.applicationId,
        );

        if (isErr(response)) {
            return response;
        }

        const data = response.value;

        return Success<GetReviewRenewalDataResponse>({
            locations: data.locations,
            areasOfPractice: data.areasOfPractice,
            attorneyRoster: data.attorneyRoster,
        });
    }
}

export const GetReviewRenewalData: UseCaseClass<GetReviewRenewalData> = GetReviewRenewalDataUseCase;
