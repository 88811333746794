import { container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import {
    AppTypeCodeListLawBundle,
    QuotingEngineLPLEverest,
} from '../../../../shopping/types/enums';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm, WizardPages } from '../../../../view/hooks/useWizardForm';
import { BrokerSignaturePage } from '../../../brokerage/view/components/BrokerSignaturePage';
import { QuoteBindCoverage } from '../../../entities/Quote';
import { DocumentType } from '../../../types/Document';
import { DocGenErrorModal } from '../../../view/components/DocGenErrorModal';
import { DocumentModal } from '../../../view/components/DocumentModal';
import { DownloadAppButton } from '../../../view/components/DownloadAppButton';
import { DownloadDocumentButton } from '../../../view/components/DownloadDocumentButton';
import { DownloadSignaturePacketButton } from '../../../view/components/DownloadSignaturePacketButton';
import { EditApplicationButton } from '../../../view/components/EditApplicationButton';
import {
    MenuItem,
    Page,
    PageNavigationComponent,
    QuoteLandingPage,
} from '../../../view/components/QuoteLandingPage';
import { QuoteOnDemandNavigation } from '../../../view/components/QuoteOnDemandNavigation';
import { ReferredQuoteOnDemandNavigation } from '../../../view/components/ReferredQuoteOnDemandNavigation';
import { withExtraProps } from '../../../view/components/WithExtraProps';
import { LPLQuote } from '../../entities/LPLQuote';
import { LPLQuoteInfo } from '../../types/LPLQuoteInfo';
import { createLPLQuoteForm, LPLQuoteFormData } from './createLPLQuoteForm';
import { HigherLimitModal } from './LPLHigherLimitModal';
import { LPLHigherLimitNavigation } from './LPLHigherLimitNavigation';
import { LPLQuoteBreakdown } from './LPLQuoteBreakdown';
import { LPLQuoteStep } from './LPLQuoteStep';
import { LPLQuoteSummary } from './LPLQuoteSummary';
import { LPLSignStep } from './LPLSignStep';
import { ConfirmSocialEngineeringCoverageModal } from './ConfirmSocialEngineeringCoverageModal';

interface LPLQuoteLandingPageProps {
    applicationId: UUID;
    initialLplQuote: LPLQuote;
    lplQuoteInfo: LPLQuoteInfo;
    getToday: () => Date;
    referredQuote: boolean;
    isTestMode: boolean;
}

interface LPLQuoteLandingPageState {
    quote: LPLQuote;
    lplQuoteInfo: LPLQuoteInfo;
    quoteFileUrl?: URI;
    specimenPolicyFileUrl?: URI;
    lastGeneratedDocumentType?: DocumentType;
}

export function LPLQuoteLandingPage({
    applicationId,
    initialLplQuote,
    lplQuoteInfo,
    getToday,
    referredQuote,
    isTestMode,
}: LPLQuoteLandingPageProps) {
    const { navigate } = useNavigation();
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const isAdmin = hasRole(activeSession, 'admin');
    const documentModal = useModal();
    const docGenErrorModal = useModal();

    const higherLimitModal = useModal();
    const confirmSocialEngineeringCoverageModal = useModal();

    const [
        isConfirmSocialEngineeringCoverageModalInitialized,
        setIsConfirmSocialEngineeringCoverageModalInitialized,
    ] = useState(false);

    const [state, setState] = useState<LPLQuoteLandingPageState>({
        quote: initialLplQuote,
        lplQuoteInfo: lplQuoteInfo,
        quoteFileUrl: undefined,
        specimenPolicyFileUrl: undefined,
        lastGeneratedDocumentType: undefined,
    });
    const isRenewal = state.lplQuoteInfo.isRenewal;
    const isStreamlineRenewal = useMemo(() => lplQuoteInfo.isStreamlineRenewal, [lplQuoteInfo]);

    if (!isConfirmSocialEngineeringCoverageModalInitialized && isRenewal && !isBroker) {
        confirmSocialEngineeringCoverageModal.show();
        setIsConfirmSocialEngineeringCoverageModalInitialized(true);
    }
    const handleUpdateQuote = useCallback(
        (newLplQuote: LPLQuote, newLplQuoteInfo?: LPLQuoteInfo) => {
            setState((prevState: LPLQuoteLandingPageState) => ({
                ...prevState,
                ...(newLplQuoteInfo && { lplQuoteInfo: newLplQuoteInfo }),
                quote: newLplQuote,
                quoteFileUrl: undefined,
                specimenPolicyFileUrl: undefined,
                lastGeneratedDocumentType: undefined,
            }));
        },
        [],
    );

    const handleSetQuoteSummaryUrl = useCallback((url: URI) => {
        setState((prevState: LPLQuoteLandingPageState) => ({
            ...prevState,
            quoteFileUrl: url,
            lastGeneratedDocumentType: DocumentType.QuoteSummary,
        }));
    }, []);

    const handleSetSpecimenPolicyUrl = useCallback((url: URI) => {
        setState((prevState: LPLQuoteLandingPageState) => ({
            ...prevState,
            specimenPolicyFileUrl: url,
            lastGeneratedDocumentType: DocumentType.SpecimenPolicy,
        }));
    }, []);

    const navigateToThankYouPage = useCallback(() => {
        if (isBroker) {
            navigate(URI.build('/broker/dashboard', { applicationId }));
            return;
        }

        const selectedCoverages: Array<string> = ['Lawyers Professional Liability'];

        const purchasedAppType = PurchasedAppType.create({
            appTypes: selectedCoverages,
        });

        let appTypes: string | undefined;
        if (isOK(purchasedAppType)) {
            const result = PurchasedAppType.encode(purchasedAppType.value);
            if (isOK(result)) {
                appTypes = result.value;
            }
        }
        navigate(
            URI.build('/summary', {
                applicationId,
                appTypes,
                quotingEngine: QuotingEngineLPLEverest,
            }),
        );
    }, [navigate, applicationId, isBroker]);

    const handleBindCoverageEvent = useCallback(() => {
        execute(CalculateSKU, {
            event: 'quote',
            applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: state.quote.totalPayable,
                applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: state.lplQuoteInfo.isRenewal,
                isStreamline: state.lplQuoteInfo.isStreamlineRenewal,
            };
            eventBus.publish(event);
        });
    }, [
        applicationId,
        eventBus,
        state.lplQuoteInfo.isRenewal,
        state.quote,
        state.lplQuoteInfo.isStreamlineRenewal,
    ]);

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const lplQuoteForm = useMemo(
        () =>
            createLPLQuoteForm({
                isBroker,
                isAdmin,
                abortSignal: abortController.signal,
                onUpdateQuote: handleUpdateQuote,
                onPurchaseSuccess: navigateToThankYouPage,
                setQuoteSummaryUrl: handleSetQuoteSummaryUrl,
                setSpecimenPolicyUrl: handleSetSpecimenPolicyUrl,
                quote: state.quote,
                stateWithLargestNumber: state.lplQuoteInfo.stateWithLargestNumber,
                numberOfAttorneys: state.lplQuoteInfo.totalAttorneys,
                currentPolicyEndDate: state.lplQuoteInfo.currentPolicyEndDate,
                getToday,
                navigate,
                isRenewal,
                isStreamline: isStreamlineRenewal,
                onGenerateDocument: documentModal.show,
                onDocGenError: docGenErrorModal.show,
                openHigherLimitModal: higherLimitModal.show,
                referredQuote,
                higherLimit: state.lplQuoteInfo.higherLimit,
            }),
        [
            isBroker,
            isAdmin,
            isStreamlineRenewal,
            abortController.signal,
            handleUpdateQuote,
            navigateToThankYouPage,
            handleSetQuoteSummaryUrl,
            handleSetSpecimenPolicyUrl,
            state.quote,
            state.lplQuoteInfo.stateWithLargestNumber,
            state.lplQuoteInfo.totalAttorneys,
            state.lplQuoteInfo.currentPolicyEndDate,
            state.lplQuoteInfo.higherLimit,
            getToday,
            navigate,
            isRenewal,
            documentModal.show,
            docGenErrorModal.show,
            higherLimitModal.show,
            referredQuote,
        ],
    );

    const quotePage = {
        name: 'coverage',
        fields: [
            'effectiveDate',
            'perClaimLimit',
            'aggregateLimit',
            'perClaimDeductible',
            'deductibleType',
            'claimsExpenseType',
            'separateClaimExpenseLimit',
        ],
    };
    const signPage = {
        name: 'signature',
        fields: isBroker
            ? ['brokerSignature']
            : ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };

    // To enable renewals testing
    const daysToExpiration = !isTestMode ? state?.quote?.daysToExpire : undefined;
    const isQuoteExpired = daysToExpiration === -1 && !isAdmin;

    const isQuoteBindable = !isQuoteExpired && !referredQuote;
    const formPages = isQuoteBindable ? [quotePage, signPage] : [quotePage];

    const lplQuoteWizardForm = useWizardForm(lplQuoteForm, {
        pages: formPages as WizardPages<OpaqueForm<LPLQuoteFormData>>,
        initialValue: {
            effectiveDate: state.quote.options.effectiveDate,
            perClaimLimit: state.quote.options.perClaimLimit,
            aggregateLimit: state.quote.options.aggregateLimit,
            perClaimDeductible: state.quote.options.perClaimDeductible,
            deductibleType: state.quote.options.deductibleType,
            claimsExpenseType: state.quote.options.claimsExpenseType,
            separateClaimExpenseLimit: state.quote.options.separateClaimExpenseLimit,
            agreementToConductSignature: state.quote.status == 'signed',
            warrantyAndFraudSignature: state.quote.status == 'signed',
            brokerSignature: false,
        },
    });

    const quoteInfo = { lplQuoteInfo: state.lplQuoteInfo, referredQuote, today: getToday() };

    const quoteStepPage: Page<LPLQuoteFormData, LPLQuote> = {
        name: 'coverage',
        component: withExtraProps(LPLQuoteStep, {
            status: lplQuoteWizardForm.status,
            ...quoteInfo,
        }),
    };

    const signStepPage: Page<LPLQuoteFormData, LPLQuote> = {
        name: 'signature',
        component: isBroker ? BrokerSignaturePage : withExtraProps(LPLSignStep, quoteInfo),
    };

    const pages: Page<LPLQuoteFormData, LPLQuote>[] = isQuoteBindable
        ? [quoteStepPage, signStepPage]
        : [quoteStepPage];

    const isFormDirty = lplQuoteWizardForm.status === 'dirty';
    const navigation: PageNavigationComponent<LPLQuoteFormData, LPLQuote> = state.quote.isIndication
        ? withExtraProps(LPLHigherLimitNavigation, {
              showConfirmationModal: higherLimitModal.show,
          })
        : QuoteOnDemandNavigation;
    const referredNavigation: PageNavigationComponent<LPLQuoteFormData, LPLQuote> =
        ReferredQuoteOnDemandNavigation;

    const DownloadQuoteItem: MenuItem<LPLQuoteFormData> = {
        component: DownloadDocumentButton,
        isDisabled: isFormDirty || state.quote.isIndication,
        fileUrl: state.quoteFileUrl,
        documentType: DocumentType.QuoteSummary,
        onGenerateFileClick: () => lplQuoteWizardForm.trigger('downloadQuote'),
    };
    const DownloadSpecimenPolicyItem: MenuItem<LPLQuoteFormData> = {
        component: DownloadDocumentButton,
        isDisabled: isFormDirty || state.quote.isIndication,
        fileUrl: state.specimenPolicyFileUrl,
        documentType: DocumentType.SpecimenPolicy,
        onGenerateFileClick: () => lplQuoteWizardForm.trigger('downloadSpecimenPolicy'),
    };

    const DownloadApplicationItem: MenuItem<LPLQuoteFormData> = {
        component: DownloadAppButton,
        isDisabled: isFormDirty,
    };
    const EditApplicationItem: MenuItem<LPLQuoteFormData> = {
        component: EditApplicationButton,
        isDisabled: state.lplQuoteInfo.disableEditApp,
    };
    const DownloadSignaturePackageItem: MenuItem<LPLQuoteFormData> = {
        component: DownloadSignaturePacketButton,
        isDisabled: isFormDirty || state.quote.isIndication,
    };

    const brokerButtons = isBroker ? [EditApplicationItem, DownloadSignaturePackageItem] : [];
    const standardButtons = !isQuoteBindable
        ? [DownloadApplicationItem]
        : [DownloadQuoteItem, DownloadSpecimenPolicyItem, DownloadApplicationItem];
    const menuButtons = [...standardButtons, ...brokerButtons];

    return (
        <React.Fragment>
            <DocGenErrorModal modal={docGenErrorModal} />
            <DocumentModal
                modal={documentModal}
                fileUrl={
                    state.lastGeneratedDocumentType
                        ? state.lastGeneratedDocumentType === DocumentType.QuoteSummary
                            ? state.quoteFileUrl
                            : state.specimenPolicyFileUrl
                        : undefined
                }
                documentType={state.lastGeneratedDocumentType}
            />
            <HigherLimitModal modal={higherLimitModal} trigger={lplQuoteWizardForm.trigger} />
            <QuoteLandingPage
                title="Lawyers Professional Liability"
                appTypeCode={AppTypeCodeListLawBundle}
                applicationId={applicationId}
                quote={state.quote}
                optionsWizardForm={lplQuoteWizardForm}
                pages={pages}
                quoteBreakdown={withExtraProps(LPLQuoteBreakdown, {
                    status: lplQuoteWizardForm.status,
                    hasPrevious: lplQuoteWizardForm.hasPrevious,
                    ...quoteInfo,
                })}
                quoteSummary={withExtraProps(LPLQuoteSummary, quoteInfo)}
                pageNavigation={referredQuote ? referredNavigation : navigation}
                menuItems={menuButtons}
                exitUrl={cast<URI>(isBroker ? '/broker/dashboard' : '/summary')}
                daysToExpire={daysToExpiration}
                handleBindCoverage={handleBindCoverageEvent}
                disablePremiumFinancing={state.quote.isIndication}
            />
            <ConfirmSocialEngineeringCoverageModal
                modal={confirmSocialEngineeringCoverageModal}
                applicationId={applicationId}
            />
        </React.Fragment>
    );
}
