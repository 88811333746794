import React, { useMemo } from 'react';
import { Accordion, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { Immutable } from '@embroker/shotwell/core/types';

interface QuestionItem {
    title: string;
    content: React.ReactNode;
}

export interface FAQSection {
    sectionTitle: string;
    questions: QuestionItem[];
}

type NavigateCallback = (path: string) => void;

type GetFAQSection = (navigate: NavigateCallback) => FAQSection;

const getAboutEmbrokerSection: GetFAQSection = () => ({
    sectionTitle: 'About Embroker',
    questions: [
        {
            title: 'What is Embroker?',
            content: (
                <React.Fragment>
                    Embroker is an insurance brokerage for businesses. Our brokers are licensed to
                    sell insurance coverage to you, our customer. What sets us apart from other
                    insurance brokerages is our free online platform, which makes dealing with
                    insurance easier, faster and more cost-effective than ever before.
                </React.Fragment>
            ),
        },
        {
            title: 'What kind of businesses do you provide coverage for?',
            content: (
                <React.Fragment>
                    We provide insurance coverage solutions and expert risk-management consultation
                    to a wide range of business types and sizes. We are{' '}
                    <a href="https://www.embroker.com/licenses/" target="_blank" rel="noreferrer">
                        licensed
                    </a>{' '}
                    to sell insurance in all 50 U.S. states and the District of Columbia.
                </React.Fragment>
            ),
        },
        {
            title: 'Who are the people behind Embroker?',
            content: (
                <React.Fragment>
                    Embroker is powered by an experienced and stellar team of insurance brokers and
                    insurance industry executives, software engineers, product designers, and
                    marketers. We were founded in 2015 by our CEO, Matt Miller. Read more about us{' '}
                    <a href="https://www.embroker.com/about/" target="_blank" rel="noreferrer">
                        here
                    </a>
                    .
                </React.Fragment>
            ),
        },
    ],
});

const getJoinEmbrokerSection: GetFAQSection = () => ({
    sectionTitle: 'Joining Embroker',
    questions: [
        {
            title: 'How much does Embroker cost?',
            content: (
                <React.Fragment>
                    The Embroker platform is free to use. If you purchase insurance through us, we
                    get paid a commission by the insurance carrier.
                    <br />
                    To learn more, read our full{' '}
                    <a href="https://www.embroker.com/disclosure/" target="_blank" rel="noreferrer">
                        compensation disclosure
                    </a>
                    .
                </React.Fragment>
            ),
        },
        {
            title: 'I have policies with other insurance brokers. Can I still use Embroker?',
            content: (
                <React.Fragment>
                    Yes, you are welcome to start an account, upload your policies, and take
                    advantage of basic coverage comparison data. To get the most out of Embroker, we
                    recommend you make us your broker by transferring your policies to us and/or
                    purchasing coverage.
                </React.Fragment>
            ),
        },
        {
            title: 'Why should I make Embroker my broker?',
            content: (
                <React.Fragment>
                    When you make Embroker your broker, you can easily share certificates, schedule
                    renewals, file claims, get a licensed expert to review potential gaps in your
                    coverage, and more — with the convenience of a secure online platform.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I make Embroker my broker?',
            content: (
                <React.Fragment>
                    We become your broker when you transfer your policies to us and/or purchase
                    coverage. Please note: Each insurance carrier processes broker of record
                    transfers differently. It may take only a few days, or possibly up to the time
                    of renewal, before Embroker can act on your behalf with the current insurance
                    carrier. We can provide more details on what to expect as well as discuss with
                    you details about your policies, specific coverages, a strategy for your
                    renewal, and any potential gaps in coverage.
                </React.Fragment>
            ),
        },
    ],
});

const getSafetyAndSecuritySection: GetFAQSection = () => ({
    sectionTitle: 'Safety and Security',
    questions: [
        {
            title: 'How does Embroker keep my data and personal information safe?',
            content: (
                <React.Fragment>
                    We care deeply about your privacy and use industry-standard security measures,
                    which includes Amazon S3 encryption, to keep your data safe and secure. Your
                    data is never shared with a third-party, and is only used to help us make
                    smarter and more cost-effective decision about your coverage.
                </React.Fragment>
            ),
        },
    ],
});

const getPoliciesAndCertificatesSection: GetFAQSection = (navigate) => ({
    sectionTitle: 'Policies and certificates',
    questions: [
        {
            title: 'Why would I upload my insurance policy?',
            content: (
                <React.Fragment>
                    When you upload your policy to your Embroker account, we turn it into a clean
                    digital version which you can access at any time from your Policies page. Having
                    access to these paperless policies also lets you easily see what you’re covered
                    for, without the hassle of having to sift through policy documents.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I transfer my policies to Embroker?',
            content: (
                <React.Fragment>
                    It’s easy: You can start by uploading PDF versions of your policies on your
                    policies page. If you’re having issues, you can click the Support link to have a
                    broker help you.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I get a PDF version of my current insurance policy?',
            content: (
                <React.Fragment>
                    You may already have access to a PDF version of your policy from your current
                    broker. If not, you can scan your paper policy and upload it from your Policies
                    page. Alternatively, you can upload a PDF version of your coverage certificate
                    or binder. If you purchase coverage directly from us, your PDF policy will be
                    automatically added to your Policies page.
                </React.Fragment>
            ),
        },
        {
            title: 'What happens when I upload a policy?',
            content: (
                <React.Fragment>
                    We extract data from the PDF and create a new digital version that’s much easier
                    to view. Within 24 hours of uploading, we’ll create a snapshot summary of your
                    policy. A full-text version of your policy is created within 48 hours of
                    uploading.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I send you my policy?',
            content: (
                <React.Fragment>
                    It’s easy. Here are your options:
                    <br />
                    <div className="emB-additionalPadding-left">
                        {' '}
                        - <b>Text a photo</b> of the first page to 800-867-5309. Data and messaging
                        rates may apply.
                    </div>
                    <div className="emB-additionalPadding-left">
                        {' '}
                        - <b>Email us</b> the policy as an image or PDF attachment to{' '}
                        <a href="mailto:policies@embroker.com">policies@embroker</a>{' '}
                    </div>
                    <div className="emB-additionalPadding-left">
                        {' '}
                        - <b>Upload your policy PDF</b> to your{' '}
                        <a
                            onClick={() => {
                                navigate('/policies');
                            }}
                        >
                            Policies
                        </a>{' '}
                    </div>
                    The digital version of your policy will be available on your{' '}
                    <a
                        onClick={() => {
                            navigate('/policies');
                        }}
                    >
                        Policies
                    </a>{' '}
                    page within 48 hours.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I send a certificate for an expired policy?',
            content: (
                <React.Fragment>Please contact us for help with expired policies.</React.Fragment>
            ),
        },
        {
            title: 'How can I make changes to my policy?',
            content: (
                <React.Fragment>
                    You can{' '}
                    <a
                        onClick={() => {
                            navigate('/policies');
                        }}
                    >
                        request a change
                    </a>{' '}
                    to your business by filling out the request a change form.
                </React.Fragment>
            ),
        },
    ],
});

const getClaimsSection: GetFAQSection = () => ({
    sectionTitle: 'Claims',
    questions: [
        {
            title: 'What information do I need to report a claim?',
            content: (
                <StackLayout gap="8">
                    <p>
                        If you are experiencing a cyber-related threat event, such as denial of
                        service, cyber extortion, ransomware, ransom, hostage, or other highly
                        delicate situation,{' '}
                        <strong>
                            please call the support number/hotline listed on your policy for the
                            fastest assistance.
                        </strong>
                    </p>
                    <p>
                        If the matter is not an active cyber-related threat event, you can report it
                        to the insurer directly — consult your policy for those contact details. At
                        minimum, you’ll need to have the following information handy:
                    </p>
                    <StackLayout gap="none">
                        <li>The policy number</li>
                        <li>
                            The full name of the key contact at your company to manage through the
                            claim(s) — this will be the “Claims Contact Person”
                        </li>
                        <li>Claims Contact Person’s email and phone number</li>
                        <li>
                            Description of events, such as the names of parties involved/claimant
                            name, the type of loss, key dates/times of circumstances/occurrences,
                            and other details. You should also let us know if there’s a demand or a
                            lawsuit.
                        </li>
                    </StackLayout>
                </StackLayout>
            ),
        },
        {
            title: "I don't have all the information needed to report the claim. What should I do?",
            content: (
                <React.Fragment>
                    You should file the claim as soon as possible. You will be able to add and
                    update information later. For now, please submit any information you do have
                    available via the claims email address or claims reporting inks provided. Or,
                    call the claims reporting phone number listed on the policy.
                </React.Fragment>
            ),
        },
        {
            title: 'Where can I find more information about what I might need?',
            content: (
                <React.Fragment>
                    Every insurance policy has a section describing claims and their reporting. You
                    can view and download policies in your Embroker account and then search those
                    files for the words “Claim”, “Report”, “Notice”, or similar terms. Usually
                    within the first few or the last few pages, there’s also a “Claim Contact” or
                    “Report To” page that may contain other helpful information.
                </React.Fragment>
            ),
        },
        {
            title: 'What happens after I report the claim?',
            content: (
                <StackLayout gap="8">
                    <p>
                        If you file online, you should receive confirmation that you’ve filed. That
                        message may contain a claim number, or indicate that a claim number will be
                        forthcoming. If you reported the claim via phone, be on the lookout for
                        correspondence via email or mail from the adjuster. Those communications may
                        provide information about the claims process, and so it’s important to read
                        them fully and ask the adjuster questions along the way.
                    </p>
                    <p>
                        In general, the Claims Contact Person should expect to be contacted and be
                        ready to provide additional details, documentation and related information.
                        Each claim is different and adjusted in accordance with applicable policy
                        terms and conditions. Some claims, such as auto or property claims may
                        require a representative of the insurer to examine the damage in person at a
                        designated location.
                    </p>
                </StackLayout>
            ),
        },
        {
            title: 'How long will it take to work through the claim?',
            content: (
                <React.Fragment>
                    Time varies depending on the type of the loss, coverage available, and how long
                    it takes to investigate and manage each claim. While we cannot offer a specific
                    time frame, most state laws require that Claims Professionals respond within
                    defined time frames after a claim is reported, and provide regular updates to
                    the Claims Contact Person as the law requires.
                </React.Fragment>
            ),
        },
        {
            title: 'If I file a claim, will it impact future premiums or renewals?',
            content: (
                <React.Fragment>
                    It’s possible. A variety of factors — including your claim history, risk
                    profile, exposures, and type of insurance — make up your insurance premium and
                    coverage terms. If it appears that your premium or coverage terms will change as
                    a result of a claim, many, but not all, types of policies/insurers are required
                    to give you notice that change is coming. Many insurers are not required to
                    offer renewal terms automatically, and so the earlier you submit your
                    application, the more time you will have to plan for any impact to your renewal.
                </React.Fragment>
            ),
        },
        {
            title: 'When will the claim be paid?',
            content: (
                <StackLayout gap="8">
                    <p>
                        After all the facts about the loss are reviewed, and it is determined that
                        the loss is covered by the insurance policy, the assigned Claims
                        Professional will let the Claims Contact Person know about next steps in the
                        process, including the coordination of any applicable payment(s).
                    </p>
                    <p>
                        Please understand that all claims are adjusted on their merits and in
                        accordance and compliance with the policy’s terms and conditions.
                    </p>
                </StackLayout>
            ),
        },
    ],
});

const getMultipleCompaniesSection: GetFAQSection = (navigate) => ({
    sectionTitle: 'Managing Multiple Companies',
    questions: [
        {
            title: 'I manage insurance for more than one company. Can I add multiple companies to my account?',
            content: (
                <React.Fragment>
                    Yes, here’s how:
                    <div className="emB-additionalPadding-left">
                        {' '}
                        - go to your &lt;Your company profile&gt; menu
                    </div>
                    <div className="emB-additionalPadding-left">
                        {' '}
                        - click{' '}
                        <a
                            onClick={() => {
                                navigate('/user/switch-companies');
                            }}
                        >
                            Settings
                        </a>{' '}
                        then Add a new company
                    </div>
                    <div className="emB-additionalPadding-left">
                        {' '}
                        - follow prompts to add the new company.
                    </div>
                    To switch to a different company profile within your account, click the Switch
                    company button. <br />
                    Please note: your Embroker account is limited to one login email address,
                    regardless of how many companies you manage.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I remove a company from my account?',
            content: (
                <React.Fragment>
                    At the moment, you will need to{' '}
                    <a
                        onClick={() => {
                            navigate('/support/contact');
                        }}
                    >
                        contact us
                    </a>{' '}
                    to remove a company from your account.
                </React.Fragment>
            ),
        },
    ],
});

const getUserManagementSection: GetFAQSection = (navigate) => ({
    sectionTitle: 'User Management',
    questions: [
        {
            title: 'How do I add team members to my company?',
            content: (
                <React.Fragment>
                    Adding team members is the most secure and convenient way to share access to
                    your company’s insurance coverages. To add a team member, log into your Embroker
                    account and navigate to the{' '}
                    <a
                        onClick={() => {
                            navigate('/user/manage-users');
                        }}
                    >
                        Team settings
                    </a>{' '}
                    page from the upper right navigation near your company name. Invite new team
                    members using their email address, separating each one with a comma. <br />{' '}
                    Please note: Invitations expire after 14 days. Also, invited team members cannot
                    invite other team members. You can{' '}
                    <a
                        onClick={() => {
                            navigate('/support/contact');
                        }}
                    >
                        contact us
                    </a>{' '}
                    to have this changed.
                </React.Fragment>
            ),
        },
        {
            title: 'How do I control email notifications?',
            content: (
                <React.Fragment>
                    Notifications help you stay on top of activity within your account. To control
                    notifications, please{' '}
                    <a
                        onClick={() => {
                            navigate('/support/contact');
                        }}
                    >
                        contact us
                    </a>{' '}
                    and we’ll help you turn them on or off.
                </React.Fragment>
            ),
        },
    ],
});

const getPurchaseCoverageSection: GetFAQSection = (navigate) => ({
    sectionTitle: 'Purchasing Coverage',
    questions: [
        {
            title: 'Can I purchase insurance coverage for my business through Embroker?',
            content: (
                <React.Fragment>
                    Yes. You can{' '}
                    <a
                        onClick={() => {
                            navigate('/shopping');
                            console.log('This page need to be implemented');
                        }}
                    >
                        purchase coverage here
                    </a>
                    .
                </React.Fragment>
            ),
        },
        {
            title: 'How long does it take to get coverage?',
            content: (
                <React.Fragment>
                    You can have coverage for your business as early as the same day of purchase, up
                    to 1-2 weeks. Coverage timing depends on complexity of your business and
                    coverage needs.
                </React.Fragment>
            ),
        },
        {
            title: "How do I know I have the right coverage for my business? / How do I know I'm not over or underpaying for coverage?",
            content: (
                <React.Fragment>
                    From your account dashboard, you can view coverage and premium comparison data
                    for companies similar to yours. You can also connect with a broker at any time
                    to get help identifying your coverage gaps.
                </React.Fragment>
            ),
        },
        {
            title: 'What are my payment options, in terms of method and timing?',
            content: (
                <React.Fragment>
                    Presently, we accept checks as the method of payment. We are currently working
                    on offering ACH and credit card payment options. Our brokers will work with you
                    on a customized payment schedule.
                </React.Fragment>
            ),
        },
        {
            title: 'What happens if I leave Embroker in the future?',
            content: (
                <React.Fragment>
                    Nothing, except we’d be sad to see you go! Your data and account settings will
                    stay the way you left them should you decide to come back.
                </React.Fragment>
            ),
        },
        {
            title: 'Where can I leave suggestions for your product?',
            content: (
                <React.Fragment>
                    We’re breaking new ground in an exciting time for the insurance and financial
                    industries and would love to hear your suggestions. Please send an email to{' '}
                    <a href="mailto:hello@embroker.com">hello@embroker.com</a>.
                </React.Fragment>
            ),
        },
    ],
});

interface FAQProps {
    isBroker?: boolean;
    onNavigate?: NavigateCallback;
}

export default function FAQLayout({ isBroker, onNavigate = () => null }: FAQProps) {
    const faqItems = useMemo(() => {
        const faqItems: FAQSection[] = [
            getAboutEmbrokerSection(onNavigate),
            getJoinEmbrokerSection(onNavigate),
            getSafetyAndSecuritySection(onNavigate),
        ];

        if (!isBroker) {
            faqItems.push(
                getPoliciesAndCertificatesSection(onNavigate),
                getClaimsSection(onNavigate),
                getMultipleCompaniesSection(onNavigate),
                getUserManagementSection(onNavigate),
            );
        }

        faqItems.push(getPurchaseCoverageSection(onNavigate));

        return faqItems;
    }, [isBroker, onNavigate]);

    const sections = faqItems.map((item, itemIndex) => {
        const accords = item.questions.map(
            (question: Immutable<QuestionItem>, questionIndex: number) => {
                return (
                    <Accordion key={itemIndex + questionIndex}>
                        <Accordion.Item title={question.title}>{question.content}</Accordion.Item>
                    </Accordion>
                );
            },
        );
        return (
            <StackLayout gap="32" key={itemIndex}>
                <Text style="heading 3">{item.sectionTitle}</Text>
                <StackLayout gap="12">{accords}</StackLayout>
            </StackLayout>
        );
    });

    return <StackLayout gap="48">{sections}</StackLayout>;
}
