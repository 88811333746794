import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
    SuccessResult,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { TasksRepository } from '../../tasks/repositories';
import { ApplicationCreated } from '../entities/Application';
import { AppTypeNotAllowedForBroker, NoEligibleAppTypeFound } from '../errors';
import { ApplicationRepository, GetConfigResponse } from '../repositories/ApplicationRepository';
import { LawBundleQuestionnaireData } from '../types/LawBundleQuestionnaireData';
import { AppTypeCode } from '../types/enums';
import { CoverageEligibility } from '../types/CoverageEligibility';
import { GetLawBundlePrefill, GetLawBundlePrefillResponse } from './GetLawBundlePrefill';
import { CoverageType } from '../types/CoverageType';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';

const coverageToggleTpAppTypeMap = new Map<CoverageType, AppTypeCode>([
    [CoverageType.LPL, 'AppTypeCodeListEverestLawyersProfessionalLiability'],
    [CoverageType.Cyber, 'AppTypeCodeListCyberCowbell'],
    [CoverageType.BOP, 'AppTypeCodeListBOPChubb'],
    [CoverageType.WC, 'AppTypeCodeListWCChubb'],
]);

type LawBundleInputData = Partial<GetLawBundlePrefillResponse>;

export interface CreateLawBundleApplicationRequest {
    abortSignal: AbortSignal;
    selectedCoverageTypes: CoverageType[];
    additionalQuestionnaireData: LawBundleQuestionnaireData;
}

export interface CreateLawBundleApplicationResponse {
    applicationIdList: UUID[];
}

export interface CreateLawBundleApplication extends UseCase {
    execute(
        request: CreateLawBundleApplicationRequest,
    ): AsyncResult<
        CreateLawBundleApplicationResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    >;
}

export class CreateLawBundleApplicationUseCase
    extends UseCase
    implements CreateLawBundleApplication
{
    public static type = Symbol('Shopping/CreateLawBundleApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute({
        abortSignal,
        selectedCoverageTypes,
        additionalQuestionnaireData,
    }: CreateLawBundleApplicationRequest): AsyncResult<
        CreateLawBundleApplicationResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    > {
        const maxPollingRetries = 5;
        const pollingRetryIntervalInMilliseconds = 120;

        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return configResponse;
        }

        const getLawBundlePrefillResult = await execute(GetLawBundlePrefill);

        const createApplicationInputData =
            getLawBundlePrefillResult && isOK(getLawBundlePrefillResult)
                ? (getLawBundlePrefillResult.value as GetLawBundlePrefillResponse)
                : {};

        const coverageToggles: CoverageType[] = getCoverageToggles(configResponse);
        const eligibleCoverageToggles: CoverageType[] = coverageToggles.filter((coverageType) =>
            CoverageEligibility.isStateEligabile(
                coverageToggleTpAppTypeMap.get(coverageType),
                createApplicationInputData.state_with_most_attorneys || null,
            ),
        );

        const hasIneligibleCoverage = selectedCoverageTypes.some(
            (coverageType) => !eligibleCoverageToggles.includes(coverageType),
        );

        if (hasIneligibleCoverage) {
            return Failure(
                OperationFailed({
                    message: `Selected coverage is not eligible: ${selectedCoverageTypes.join(
                        ', ',
                    )}`,
                }),
            );
        }

        const createQuestionnaireDataResult = LawBundleQuestionnaireData.create({
            ...mapInputToQuestionnaireData(createApplicationInputData),
            ...additionalQuestionnaireData,
            coverage_toggles: selectedCoverageTypes,
        });

        if (isErr(createQuestionnaireDataResult)) {
            return handleOperationFailure(createQuestionnaireDataResult);
        }

        const questionnaireDataStringifiedResult = JSONSerdes.serialize(
            createQuestionnaireDataResult.value,
        );

        if (isErr(questionnaireDataStringifiedResult)) {
            return Failure(OperationFailed({ message: 'Failed to stringify questionnaire data' }));
        }

        const createApplicationsResult = await this.applicationRepository.createApplications({
            appTypeList: ['AppTypeCodeListLawBundle'],
            questionnaireData: questionnaireDataStringifiedResult.value,
        });

        if (isErr(createApplicationsResult)) {
            return createApplicationsResult;
        }

        const { taskId, applicationList } = createApplicationsResult.value;

        const skuResult = await this.calculateSKU.execute({
            event: 'app_created',
            appType: 'AppTypeCodeListLawBundle',
            shoppingCoverageList: applicationList[0].shoppingCoverageList,
            applicationId: applicationList[0].id,
        });
        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: applicationList[0].id,
            isRenewal: applicationList[0].isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        this.eventBus.publish(eventData);

        const applicationIdList: UUID[] = applicationList.map((item) => item.id);

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            this.logger.warn(
                `Create Applications UseCase - Got error for task with id: ${taskId}`,
                pollForTaskStatusResult.errors,
            );
        } else if (!UUID.check(pollForTaskStatusResult.value)) {
            this.logger.warn(
                `Create Applications UseCase - Invalid response type for task with id: ${taskId}`,
                pollForTaskStatusResult,
            );
        }

        return Success<CreateLawBundleApplicationResponse>({
            applicationIdList: applicationIdList,
        });
    }
}

const getCoverageToggles = (configResponse: SuccessResult<GetConfigResponse>): CoverageType[] => {
    const coverage_toggles: CoverageType[] = [CoverageType.LPL, CoverageType.Cyber];
    if (configResponse.value.isBOPChubbEnabled) {
        coverage_toggles.push(CoverageType.BOP);
    }
    if (configResponse.value.isWCChubbEnabled) {
        coverage_toggles.push(CoverageType.WC);
    }
    return coverage_toggles;
};

// The law bundle can be created via prospects or through the platform
// We set the NAICS code to law firms as fallback for the prospect scenario
function mapInputToQuestionnaireData({
    naics_code = '541110', // naics code for law firm
    gross_revenue_total,
    state_with_most_attorneys,
}: LawBundleInputData): LawBundleQuestionnaireData {
    return {
        naics_code,
        gross_revenue_total: gross_revenue_total && Money.toFloat(gross_revenue_total),
        state_with_most_attorneys,
        areas_of_practice: { areas_of_practice_rows: [] },
    };
}

export const CreateLawBundleApplication: UseCaseClass<CreateLawBundleApplication> =
    CreateLawBundleApplicationUseCase;
