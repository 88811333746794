import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Button, Spinner, StatusMessage, useModal } from '@embroker/ui-toolkit/v2';
import { container } from '@embroker/shotwell/core/di';
import React from 'react';
import { Application } from '../../../../shopping/entities/Application';
import {
    AppTypeCodeListEmbrokerExcess,
    AwaitingQuotes,
    DeclinedByCarrier,
    NotEligible,
    QuestionnaireInProgress,
    QuotesReady,
    QuotingEngineLPLEverest,
    Referred,
} from '../../../../shopping/types/enums';
import {
    isAppQuotingEngine,
    isLPLIndication,
} from '../../../../shopping/view/components/applicationFlags';
import { ViewQuestionnaireModal } from '../../../../shopping/view/components/ViewQuestionnaireModal';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { resolveBrokerViewQuotesLink } from '../BrokerQuoteActionButton';
import { RenewNowModal } from '../modals/RenewNowModal';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetGlobalConfig } from '@app/config/useCases/GetGlobalConfigUseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';

export interface ClientPageRenewalsActionButtonProps {
    application: Immutable<EntityProps<Application>>;
    organizationId: UUID;
}

export function ClientPageRenewalsActionButton({
    application,
    organizationId,
}: ClientPageRenewalsActionButtonProps) {
    const { navigate } = useNavigation();
    const viewQuestionnaireModal = useModal();
    const renewNowModal = useModal();

    const { result: globalConfigResult, isLoading: isLoadingGlobalConfig } =
        useUseCase(GetGlobalConfig);

    if (isLoadingGlobalConfig || globalConfigResult === undefined) {
        return <Spinner />;
    }

    if (globalConfigResult && isErr(globalConfigResult)) {
        const errorId = container.get<Logger>(Log).whoops(globalConfigResult?.errors);
        const whoopsErrorMessage = errorId ? `Whoops error id: ${errorId}` : '';

        return (
            <StatusMessage status="error">{`We failed to load the global configuration. Try again or report an error! ${whoopsErrorMessage}`}</StatusMessage>
        );
    }

    const handleViewEstimate = async () => {
        navigate(
            URI.build('/shopping/application/quote/lpl/referred-estimate', {
                applicationId: application.id,
            }),
        );
    };

    const handleViewQuestionnaire = async () => {
        viewQuestionnaireModal.show();
    };

    const handleRenewPolicy = async () => {
        if (application.isStreamline) await handleSubmitStreamlineApp();
        else await handleStartContinue();
    };

    const handleSubmitStreamlineApp = async () => {
        await execute(SelectOrganization, { organizationId });

        if (globalConfigResult.value.config.streamlineRenewalsEnhanced) {
            navigate(
                URI.build('/shopping/review-renewal-data', {
                    applicationId: application.id,
                }),
            );
        } else {
            navigate(
                URI.build('/shopping/submit-streamline-renewal', {
                    applicationId: application.id,
                }),
            );
        }
    };

    const handleStartContinue = async () => {
        if (
            application.isSubmittedExternally ||
            (application.appType && application.appType === AppTypeCodeListEmbrokerExcess)
        ) {
            await execute(SelectOrganization, { organizationId });
            navigate(
                URI.build('/shopping/application/basic-info', {
                    applicationId: application.id,
                }),
            );
        } else {
            renewNowModal.show();
        }
    };

    const handleViewQuotes = async () => {
        if (application.hasQuotes) {
            const link = resolveBrokerViewQuotesLink({
                applicationId: application.id,
                quotingEngine: application.quotingEngine ?? undefined,
                isRenewal: true,
            });
            if (link) {
                await execute(SelectOrganization, { organizationId });
                return navigate(link);
            }
            return navigateToErrorPage(navigate, [
                OperationFailed({
                    message: 'Failed to resolve view quotes link',
                }),
            ]);
        }
    };

    switch (application.status) {
        case AwaitingQuotes:
        case DeclinedByCarrier:
        case NotEligible:
            return (
                <React.Fragment>
                    <Button appearance="primary" onClick={handleViewQuestionnaire}>
                        View questionnaire
                    </Button>
                    <ViewQuestionnaireModal
                        applicationId={application.id}
                        modal={viewQuestionnaireModal}
                    />
                </React.Fragment>
            );
        case QuestionnaireInProgress:
            return (
                <React.Fragment>
                    <Button appearance="primary" onClick={handleRenewPolicy}>
                        Start renewal
                    </Button>
                    <RenewNowModal
                        modal={renewNowModal}
                        applicationId={application.id}
                        organizationId={organizationId}
                    />
                </React.Fragment>
            );
        case QuotesReady:
            return (
                <Button appearance="primary" onClick={handleViewQuotes}>
                    Renewal quote
                </Button>
            );
        case Referred:
            if (isAppQuotingEngine(application.quotingEngine, QuotingEngineLPLEverest)) {
                if (isLPLIndication(application)) {
                    return (
                        <Button appearance="primary" onClick={handleViewEstimate}>
                            View Estimate
                        </Button>
                    );
                }
            }
            return null;
        default:
            return null;
    }
}
