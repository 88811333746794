import { ShoppingCoverageCodeListProfessionalLiability } from '@app/shopping/types/spec_enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { keysOf } from '@embroker/shotwell/core/object';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { mapFromApiLimit } from '../../../quote/lpl/repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { LimitType } from '../../../quote/types/Limits';
import { QuotingEngineMPL } from '../../../shopping/types/enums';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageTypeEnum } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import { DownloadDocumentButton } from '../../view/components/DownloadDocumentButton';
import { GenerateDocumentButton } from '../../view/components/GenerateDocumentButton';
import { getDocumentUrl } from '../bundleMappingFunctions';
import {
    CoverageDefinition,
    distributedPrefix,
    generateDocumentDisplayName,
    GetDocumentDownloadMetadataResponse,
    MPL,
    MplDocumentTitle,
    MplProductName,
    MplProductTitle,
    ProductFormData,
} from '../coverageDefinition';
import { buildMPLCoverage } from './buildMPLCoverage';
import { MPLCoverageFooter } from './components/MPLCoverageFooter';
import { MPLModalCoverageSummary } from './components/MPLCoverageModalSummary';
import { MPLCoverageOptions } from './components/MPLCoverageOptions';
import { MPLFooter } from './components/MPLFooter';
import { MPLQuoteSummary } from './components/MPLSummary';
import { MPLQuote } from './entities/MPLQuote';
import {
    formDataToMPLQuoteOptions,
    isMplFormData,
    isMplQuoteOptions,
    toApiMplQuoteOptions,
} from './mappingFunctions';
import { MPLQuestionnaireData } from './types/MPLQuestionnaireData';
import {
    isValidMPLLimitsCombination,
    MPLAggregateLimit,
    MPLPerClaimLimit,
    MPLQuoteOptions,
    MPLRetentionList,
    validPerClaimAggregateCombinationList,
} from './types/MPLQuoteOptions';

export const MPLCoverageDefinition: CoverageDefinition<MPLQuote> = {
    productName: MplProductName,
    productTitle: MplProductTitle,
    documentTitle: MplDocumentTitle,
    type: BundleCoverageTypeEnum.MPLCoverage,
    quotingEngine: QuotingEngineMPL,
    buildCoverage: buildMPLCoverage,
    mapFormDataToQuoteOptions: formDataToMPLQuoteOptions,
    apiProductDesignation: MPL,
    mapQuoteOptionsToAPI: toApiMplQuoteOptions,
    coverageOptionsComponent: MPLCoverageOptions,
    coverageFooterComponent: MPLCoverageFooter,
    summaryComponent: MPLQuoteSummary,
    modalSummaryComponent: MPLModalCoverageSummary,
    footerComponent: MPLFooter,
    surplusLinesDiscDisabled: true,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<MPLQuoteOptions>>>['fields'] {
        return isMplFormData(input);
    },
    isOptionsValidType(input: any): input is MPLQuoteOptions {
        return isMplQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<MPLQuoteOptions>>>['fields'] {
        return {
            mplLimits: {
                type: 'select',
                validator: Joi.object({
                    perClaimLimit: Joi.number()
                        .allow(...MPLPerClaimLimit)
                        .required(),
                    aggregateLimit: Joi.number()
                        .allow(...MPLAggregateLimit)
                        .required(),
                })
                    .custom(isValidMPLLimitsCombination)
                    .required(),
            },
            mplRetention: {
                type: 'select',
                validator: Joi.number()
                    .allow(...MPLRetentionList)
                    .required(),
            },
            mplSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const mplCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        if (
            mplCoverage === undefined ||
            mplCoverage.quote === undefined ||
            !this.isOptionsValidType(mplCoverage.quote.options)
        ) {
            return {};
        }

        const mplOptions = mplCoverage.quote.options;
        return {
            mplLimits: mplOptions.limits,
            mplRetention: mplOptions.retention,
            mplSelected: !mplOptions.isDeselected,
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<MPLQuoteOptions>;
              fields: (keyof distributedPrefix<MPLQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<MPLQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateMPLQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.MPLCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            downloadMPLApp: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.MPLCoverage,
                        DocumentType.ApplicationAttestationWithPapyrus,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            generateMPLSpecimenPolicy: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.MPLCoverage,
                        DocumentType.CoverageDetails,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const shouldIncludeMPLDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.MPLCoverage) &&
            !bundleQuote.isCoverageReferredAwaitingReview(BundleCoverageTypeEnum.MPLCoverage) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.MPLCoverage) &&
            !bundleQuote.isCoverageIndication(BundleCoverageTypeEnum.MPLCoverage) &&
            value.mplSelected;

        if (!shouldIncludeMPLDocs) {
            return undefined;
        }
        const MPLQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.MPLCoverage,
            )?.url,
            handleTrigger: () => trigger('generateMPLQuoteDocument'),
            displayName: generateDocumentDisplayName({
                documentType: DocumentType.QuoteDocument,
                bundleCoverageType: BundleCoverageTypeEnum.MPLCoverage,
            }),
        };
        const MPLSpecimenPolicy: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.CoverageDetails &&
                    document.coverageType === BundleCoverageTypeEnum.MPLCoverage,
            )?.url,
            displayName: generateDocumentDisplayName({
                documentType: DocumentType.CoverageDetails,
                bundleCoverageType: BundleCoverageTypeEnum.MPLCoverage,
            }),
            handleTrigger: () => trigger('generateMPLSpecimenPolicy'),
        };
        const MPLApplicationItem: DocumentsItem = {
            component: DownloadDocumentButton,
            displayName: generateDocumentDisplayName({
                documentType: DocumentType.ApplicationAttestationWithPapyrus,
                bundleCoverageType: BundleCoverageTypeEnum.MPLCoverage,
            }),
            handleTrigger: () => {
                trigger('downloadMPLApp');
            },
        };
        return [MPLApplicationItem, MPLQuoteDocument, MPLSpecimenPolicy];
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { mplSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        mplQuote?: MPLQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        let fileKey;
        if (mplQuote === undefined) {
            return Failure(InvalidArgument({ argument: 'mplQuote', value: documentType }));
        }
        switch (documentType) {
            case DocumentType.QuoteDocument:
                fileKey = mplQuote.fileKey;
                break;
            case DocumentType.CoverageDetails:
                fileKey = mplQuote.details.specimen_policy_file_key;
                break;
            default:
                break;
        }
        if (fileKey === undefined) {
            return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
        const downloadUrlResult = await execute(GetDocumentUrl, {
            fileKey: fileKey,
        });
        if (isErr(downloadUrlResult)) {
            return downloadUrlResult;
        }
        return Success({
            fileKey: fileKey,
            downloadUrl: downloadUrlResult.value.downloadUrl,
        });
    },
    onSuccessHandler(value: any, action: string) {
        switch (action) {
            case 'generateMPLQuoteDocument':
                window.open(value.fileUrl as string, '_blank');
                break;
            case 'generateMPLSpecimenPolicy':
                window.open(value.fileUrl as string, '_blank');
                break;
            case 'downloadMPLApp':
                window.open(value.fileUrl as string, '_blank');
                break;
        }
    },
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<MPLQuoteOptions>,
        coverageSelected: boolean,
        questionnaire?: MPLQuestionnaireData,
        higherLimit?: Record<string, Record<LimitType, number>>,
    ): boolean {
        // TODO - verify what is the max regular limit for MPL
        const maxRegularPerClaimLimit: MPLPerClaimLimit =
            [...validPerClaimAggregateCombinationList]
                .reverse()
                .find(
                    (mplLimits) =>
                        questionnaire?.purchasedLimit &&
                        mplLimits.perClaimLimit <= questionnaire.purchasedLimit,
                )?.perClaimLimit ?? 2000000;

        const coverageHigherLimit = mapFromApiLimit(
            higherLimit?.[ShoppingCoverageCodeListProfessionalLiability],
        );
        return (
            coverageSelected &&
            formValue.mplLimits.perClaimLimit >
                (coverageHigherLimit?.per_claim_limit ?? maxRegularPerClaimLimit)
        );
    },
    getPremiumRange(quote: MPLQuote) {
        if (!quote || !quote.details) {
            return undefined;
        }
        const min = quote.premiumRange?.min;
        const max = quote.premiumRange?.max;
        if (!min || !max) {
            return undefined;
        }
        return { min, max };
    },
    getDownloadAppWithManifestDocAction(trigger: WizardForm<OpaqueForm<any>>['trigger']) {
        trigger('downloadMPLApp');
    },
    getHLCoverageSpecificText(): string {
        return 'Selecting a higher limit means that your application for this policy will need to be reviewed by an underwriter.';
    },
    overrideBundleAppDocument(): boolean {
        return true;
    },
};
