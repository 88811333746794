import { injectable } from '@embroker/shotwell/core/di';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Success, isOK } from '@embroker/shotwell/core/types/Result';
import { UserOnboardingDetails } from '../../types/UserOnboardingDetails';
import { OnboardingPrefillQuestionnaireData } from '../../types/OnboardingPrefillQuestionnaireData';
import { UserOnboardingRepository } from '.';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { IndexableObject } from '@embroker/shotwell/core/object';
import { SignUpRequest } from '@app/userOrg/useCases/SignUp';

const OnboardingQuestionnaireData = 'OnboardingQuestionnaireData';
const UserOnboardingDetailsData = 'UserOnboardingDetailsData';
const UnauthorizedOnboardingSignUpData = 'UnauthorizedOnboardingSignUpData';

@injectable()
export class APIUserOnboardingRepository implements UserOnboardingRepository {
    constructor() {
        //
    }

    public async setUserOnboardingDetails({
        redirectUrl,
    }: UserOnboardingDetails): AsyncResult<void, never> {
        const userOnboardingDetailsResp = await this.getUserOnboardingDetails();
        const userOnboardingDetails = isOK(userOnboardingDetailsResp)
            ? {
                  ...userOnboardingDetailsResp.value,
                  ...(redirectUrl && { redirectUrl }),
              }
            : {};
        window.sessionStorage.setItem(
            UserOnboardingDetailsData,
            JSON.stringify(userOnboardingDetails),
        );
        return Success();
    }
    public async removeUserOnboardingDetails(): AsyncResult<void, never> {
        window.sessionStorage.removeItem(UserOnboardingDetailsData);
        return Success();
    }

    public async getUserOnboardingDetails(): AsyncResult<Nullable<UserOnboardingDetails>, never> {
        const onboardingDetailsItem = window.sessionStorage.getItem(UserOnboardingDetailsData);

        if (!onboardingDetailsItem) {
            return Success(null);
        }

        const onboardingDetailsJsonResp = JSONSerdes.deserialize(onboardingDetailsItem);

        if (isOK(onboardingDetailsJsonResp)) {
            const userOnboardingDetails = UserOnboardingDetails.create(
                onboardingDetailsJsonResp.value as IndexableObject,
            );
            if (isOK(userOnboardingDetails)) {
                return Success(userOnboardingDetails.value);
            }
        }

        return Success(null);
    }

    public async setUserOnboardingQuestionnaireData(
        data: OnboardingPrefillQuestionnaireData,
    ): AsyncResult<void, never> {
        const onboardingQuestionnaireDataResp = await this.getUserOnboardingQuestionnaireData();
        const onboardingQuestionnaireData = isOK(onboardingQuestionnaireDataResp)
            ? {
                  ...onboardingQuestionnaireDataResp.value,
                  ...data,
              }
            : {};
        window.sessionStorage.setItem(
            OnboardingQuestionnaireData,
            JSON.stringify(onboardingQuestionnaireData),
        );
        return Success();
    }

    public async removeUserOnboardingQuestionnaireData(): AsyncResult<void, never> {
        window.sessionStorage.removeItem(OnboardingQuestionnaireData);
        return Success();
    }

    public async getUserOnboardingQuestionnaireData(): AsyncResult<
        Nullable<OnboardingPrefillQuestionnaireData>,
        never
    > {
        const onboardingQuestionnaireData = window.sessionStorage.getItem(
            OnboardingQuestionnaireData,
        );

        if (onboardingQuestionnaireData) {
            try {
                return Success(JSON.parse(onboardingQuestionnaireData));
            } catch (e) {
                //
            }
        }
        return Success(null);
    }

    public async setUnauthorizedOnboardingSignUpData(
        signupRequest: SignUpRequest,
    ): AsyncResult<any, never> {
        window.sessionStorage.setItem(
            UnauthorizedOnboardingSignUpData,
            JSON.stringify(signupRequest),
        );
        return Success(null);
    }
    public async getUnauthorizedOnboardingSignUpData(): AsyncResult<any, never> {
        const unauthorizedOnboardingSignUpData = window.sessionStorage.getItem(
            UnauthorizedOnboardingSignUpData,
        );

        if (!unauthorizedOnboardingSignUpData) {
            return Success(null);
        }

        const unauthorizedOnboardingSignUpResp = JSONSerdes.deserialize(
            unauthorizedOnboardingSignUpData,
        );

        if (isOK(unauthorizedOnboardingSignUpResp)) {
            return Success(unauthorizedOnboardingSignUpResp.value);
        }

        return Success(null);
    }

    public async removeUnauthorizedOnboardingSignUpData(): AsyncResult<any, never> {
        window.sessionStorage.removeItem(UnauthorizedOnboardingSignUpData);
        return Success(null);
    }
}
