import React, { SyntheticEvent } from 'react';
import {
    FormLayout,
    StackLayout,
    Text,
    StatusMessage,
    ButtonBar,
    Button,
    ElementProps,
    StatusMessageList,
    Form,
    TextButton,
    ColumnLayout,
} from '@embroker/ui-toolkit/v2';

export interface FormViewLayoutProps extends ElementProps<'div'> {
    title: string;
    subTitle?: string;
    helperText?: React.ReactNode | string;
    errorMessages?: string[] | readonly string[];
    submitText?: string;
    disableSubmit: boolean;
    backText?: string;
    handleBack?: (e: SyntheticEvent) => void;
    onFormSubmit: (event: React.FormEvent) => void;
    'data-e2e-submit'?: string;
    optionalLink?: React.ReactNode;
}

export function FormViewLayout({
    children,
    disableSubmit,
    title,
    submitText = 'Next',
    backText = 'Back',
    helperText,
    subTitle,
    errorMessages = [],
    onFormSubmit,
    handleBack,
    optionalLink,
    'data-e2e-submit': dataE2ESubmit = 'form-view-primary-btn',
}: FormViewLayoutProps) {
    return (
        <FormLayout>
            <Form noValidate onSubmit={onFormSubmit}>
                <StackLayout gap="24">
                    <Text style="heading 3">{title}</Text>
                    {subTitle && <Text style="body 1">{subTitle}</Text>}
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <StatusMessageList status="error" messages={errorMessages} />
                            {children}
                            {helperText && (
                                <StatusMessage status="info">{helperText}</StatusMessage>
                            )}
                        </StackLayout>
                        <ButtonBar
                            responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}
                            split="-1"
                            reverse
                            center
                        >
                            {handleBack && (
                                <TextButton
                                    icon="thin-arrow-left"
                                    iconPosition="left"
                                    iconSize="medium"
                                    disabled={false}
                                    onClick={handleBack}
                                >
                                    {backText}
                                </TextButton>
                            )}
                            <Button
                                icon="thin-arrow-right"
                                iconPosition="right"
                                iconSize="medium"
                                data-e2e={dataE2ESubmit}
                                data-testid={dataE2ESubmit}
                                type="submit"
                                disabled={disableSubmit}
                            >
                                {submitText}
                            </Button>
                        </ButtonBar>
                        {optionalLink && <ColumnLayout split="-1">{optionalLink}</ColumnLayout>}
                    </StackLayout>
                </StackLayout>
            </Form>
        </FormLayout>
    );
}
