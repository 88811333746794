import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { ClaimRepository } from './repositories/claims';
import { APIClaimRepository } from './repositories/claims/APIClaimRepository';
import { FileClaim } from './useCases/FileClaim';
import { routes } from './view/routes';
import { CreateClaimRequest } from './useCases/CreateClaimRequest';

/**
 * The claims module.
 */
export const ClaimsModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<FileClaim>(FileClaim.type).to(FileClaim).inSingletonScope();
        bind<ClaimRepository>(ClaimRepository).to(APIClaimRepository).inSingletonScope();
        bind<CreateClaimRequest>(CreateClaimRequest.type).to(CreateClaimRequest).inSingletonScope();
    }),
};
