import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Organization } from '../entities/Organization';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { UserRepository } from '../repositories/UserRepository';

/**
 * Request data for AddNewOrganization use case
 */
export interface AddNewOrganizationRequest {
    companyLegalName: string;
    raisedFunding?: boolean;
}

/**
 * Response data for AddNewOrganization use case
 */
export interface AddNewOrganizationResponse {
    /**
     * Identifier of the newly created organization.
     */
    organizationId: UUID;
    /**
     * Name of the newly create organization.
     */
    companyLegalName: string;
}

export interface AddNewOrganization extends UseCase {
    execute(
        request: AddNewOrganizationRequest,
    ): AsyncResult<AddNewOrganizationResponse, InvalidArgument | OperationFailed>;
}

/**
 * AddNewOrganization use case is used to add a new organization on the platform
 */
@injectable()
class AddNewOrganizationUseCase extends UseCase implements AddNewOrganization {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/AddNewOrganization');
    /**
     * Constructor for AddNewOrganization class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepository is the organization repo which will be used to save new organization.
     * @param UserRepository
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the AddNewOrganization use case.
     * @param data is representation of the new organization
     * @returns AddNewOrganizationResponse if execution was successful
     * @returns InvalidArgument if one of properties in AddNewOrganizationRequest was not valid
     * @returns OperationFailed if repo request failed
     */
    public async execute({
        companyLegalName,
        raisedFunding,
    }: AddNewOrganizationRequest): AsyncResult<
        AddNewOrganizationResponse,
        InvalidArgument | OperationFailed
    > {
        const organizationResult = await Organization.create({
            companyLegalName,
            website: null,
            email: null,
            entityType: null,
            howDoesYourCompanyGenerateRevenue: null,
            naics: null,
            otherLocations: null,
            headquarters: {
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                county: null,
                zip: null,
            },
            revenues: null,
            totalAnnualPayroll: null,
            totalNumberOfEmployees: null,
            userRoleList: [],
            yearStarted: null,
            isTestOrganization: false,
            raisedFunding: raisedFunding ?? null,
            techAreaOfFocus: null,
            higherLimitsApproved: false,
            providesTechServiceForFee: null,
        });

        if (isErr(organizationResult)) {
            return handleOperationFailure(organizationResult);
        }

        let organization = organizationResult.value;
        const result = await this.organizationRepository.save(organization as Organization);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        organization = result.value;

        this.eventBus.publishEntityEvents(organizationResult.value);

        return Success<AddNewOrganizationResponse>({
            organizationId: organization.id,
            companyLegalName: organization.companyLegalName,
        });
    }
}

export const AddNewOrganization: UseCaseClass<AddNewOrganization> = AddNewOrganizationUseCase;
