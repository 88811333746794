import { HartfordBopCoverage } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import {
    BusinessLiabilityLimit,
    EmploymentPracticesLiability,
    GeneralAggregateLimit,
} from '@app/quote/hartford-bop/view/components/coverageMapper';
import { CoverageLimit } from '@app/quote/hartford-bop/view/components/quoteCoverage/CoverageLimit';
import React from 'react';
import { CoverageProperty } from '@app/quote/hartford-bop/view/components/quoteCoverage/CoverageProperty';
import { Money } from '@embroker/shotwell/core/types/Money';
import { WizardForm } from '@app/view/hooks/useWizardForm';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { BOPHartfordQuoteOptionsFormData } from '@app/quote/hartford-bop/view/components/createBOPHartfordOptionsForm';

const NO_VALUE = 0;

interface Props {
    coverages: HartfordBopCoverage[];
    squareFootageAtLocation: number | undefined;
    bopCoverageType: string;
    setValue: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['value'];
    bppLimit: Money;
}

export function CoverageList({
    coverages,
    squareFootageAtLocation,
    bopCoverageType,
    bppLimit,
    setValue,
    value,
}: Props) {
    const codes = [BusinessLiabilityLimit, GeneralAggregateLimit, EmploymentPracticesLiability];

    const filtered = coverages
        .filter((coverage) => !codes.includes(coverage.code))
        .filter((coverage) => coverage.limits.some((limit) => limit.limit.amount !== NO_VALUE));

    const generalAggregateCoverage = coverages.find(
        (coverage) => coverage.code === GeneralAggregateLimit,
    );

    const businessLiabilityCoverage = coverages.find(
        (coverage) => coverage.code === BusinessLiabilityLimit,
    );

    if (!generalAggregateCoverage || !businessLiabilityCoverage) {
        return null;
    }

    filtered.sort((a, b) => a.description.localeCompare(b.description));

    return (
        <React.Fragment>
            <CoverageLimit
                coverage={businessLiabilityCoverage}
                aggregateLimit={generalAggregateCoverage}
            />
            {filtered.map((coverage, index) => (
                <CoverageLimit
                    key={index}
                    coverage={coverage}
                    aggregateLimit={generalAggregateCoverage}
                />
            ))}
            <CoverageProperty
                squareFootageAtLocation={squareFootageAtLocation}
                bopCoverageType={bopCoverageType}
                bppLimit={bppLimit}
                setValue={setValue}
                value={value}
            />
        </React.Fragment>
    );
}
