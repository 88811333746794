import { MainAreaOfFocusList, NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import {
    FormPageDefinition,
    FormQuestionDefinition,
} from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';

const getAreaOfFocusDisplayCondition = () => {
    return Object.keys(NAICS_CODE_TO_VERTICAL)
        .filter((key) => NAICS_CODE_TO_VERTICAL[key] === 'TechCompanies')
        .map((key) => [
            {
                displayWhen: {
                    questionKey: 'naics_code',
                    condition: { string: { equal: key } },
                },
            },
        ]);
};

export const signupQuestions: FormQuestionDefinition[] = [
    // Page 1 - Basic Company Info
    {
        key: 'static_key',
        questionType: 'static',
        staticOptions: {
            textElementProps: {
                children: `Welcome! Let's start with the basics so we can get the right coverage for your business — it'll take just a few minutes.`,
                style: 'body 1',
            },
        },
    },
    {
        key: 'company_name',
        questionType: 'text',
        title: 'What is the name of your company?',
        placeholder: 'Company name',
        isRequired: true,
    },
    {
        key: 'has_website',
        questionType: 'radioGroup',
        title: 'Does your company have a website? (Optional)',
        isMultiple: false,
        selectOptions: [
            {
                title: 'Yes',
                value: true,
            },
            {
                title: 'No',
                value: false,
            },
        ],
        isRequired: true,
    },
    {
        key: 'company_website',
        questionType: 'text',
        placeholder: 'Company website',
        conditionalDisplay: [
            [
                {
                    displayWhen: {
                        questionKey: 'has_website',
                        condition: {
                            boolean: {
                                isTrue: true,
                            },
                        },
                        questionField: '',
                    },
                },
            ],
        ],
        isRequired: true,
    },
];

export const signupPages: FormPageDefinition[] = [
    {
        name: 'company-basics',
        title: "Let's get your quote started",
        fields: ['static_key', 'company_name', 'has_website', 'company_website'],
    },
];

export const addressQuestions: FormQuestionDefinition[] = [
    {
        key: 'headquarters',
        questionType: 'addressField',
        title: 'Where is your headquarters located?',
        isRequired: true,
    },
];

export const addressPages: FormPageDefinition[] = [
    {
        name: 'address-info',
        title: "Let's get your quote started",
        fields: ['headquarters'],
    },
];

export const naicsQuestions: FormQuestionDefinition[] = [
    // Page 2 - Contact Details
    {
        key: 'naics_code',
        questionType: 'naicsCode',
        label: 'What is your NAICS code?',
        title: 'Start typing keywords to describe your business.',
        isRequired: true,
        statusMessage: {
            content:
                "Can't find an exact match? Pick the category that best describes what you do.",
            status: 'info',
        },
    },
    {
        key: 'tech_area_of_focus',
        questionType: 'select',
        title: `What is the company's main area of focus?`,
        selectOptions: MainAreaOfFocusList,
        isRequired: true,
        conditionalDisplay: getAreaOfFocusDisplayCondition(),
    },
];

export const naicsPages: FormPageDefinition[] = [
    {
        name: 'contact-info',
        title: 'What type of work do you do?',
        fields: ['naics_code', 'tech_area_of_focus'],
    },
];

export const techVerticalQuestions: FormQuestionDefinition[] = [
    {
        key: 'onb_raised_funding',
        questionType: 'radioGroup',
        title: 'Has the company raised funding?',
        isMultiple: false,
        selectOptions: [
            {
                title: 'Yes',
                value: true,
            },
            {
                title: 'No',
                value: false,
            },
        ],
        isRequired: true,
    },
    {
        key: 'total_revenue',
        questionType: 'projectedYearRevenue',
        title: 'What is your projected annual revenue for this year?',
        placeholder: 'Current year annual revenue (projected)',
        statusMessage: {
            content:
                'This refers to the revenue before any deductions, such as commission splits, revenue sharing, expenses, etc.',
            status: 'info',
        },
        isRequired: true,
    },
    {
        key: 'working_space_type',
        questionType: 'radioGroup',
        title: 'What best describes your workspace?',
        isMultiple: false,
        selectOptions: [
            {
                title: 'This is my home office location',
                value: 'home_office',
            },
            {
                title: 'I rent an office at this location',
                value: 'office_rented',
            },
            {
                title: 'I own this location',
                value: 'office_owned',
            },
        ],
        isRequired: true,
    },
    {
        key: 'total_number_employees',
        questionType: 'number',
        title: 'How many total employees does your company have?',
        placeholder: 'Total employee number',
        statusMessage: {
            content:
                '"Employee" refers to any staff on payroll, any independent contractors you hire, and the company’s owners.',
            status: 'info',
        },
        isRequired: true,
    },
];

export const techVerticalPages: FormPageDefinition[] = [
    {
        name: 'tech-vertical-page',
        title: 'Tell us a few more details about your business so we can help you find the right protection.',
        fields: [
            'onb_raised_funding',
            'total_revenue',
            'working_space_type',
            'total_number_employees',
        ],
    },
];

export const lawVerticalQuestions: FormQuestionDefinition[] = [
    {
        key: 'has_employees',
        questionType: 'radioGroup',
        title: 'Does your firm have at least one employee?',
        isMultiple: false,
        selectOptions: [
            {
                title: 'Yes',
                value: true,
            },
            {
                title: 'No',
                value: false,
            },
        ],
        statusMessage: {
            content: '"Employee" refers to any staff on payroll.',
            status: 'info',
        },
    },
    {
        key: 'working_space_type',
        questionType: 'radioGroup',
        title: 'What best describes your workspace?',
        isMultiple: false,
        selectOptions: [
            {
                title: 'This is my home office location',
                value: 'home_office',
            },
            {
                title: 'I rent an office at this location',
                value: 'office_rented',
            },
            {
                title: 'I own this location',
                value: 'office_owned',
            },
        ],
    },
    {
        key: 'has_automobiles',
        questionType: 'radioGroup',
        title: 'Does anyone at the firm use employee-owned or rental vehicles for work purposes (driving to court or to meet clients, etc.)?',
        isMultiple: false,
        selectOptions: [
            {
                title: 'Yes',
                value: true,
            },
            {
                title: 'No',
                value: false,
            },
        ],
    },
];

export const lawVerticalPages: FormPageDefinition[] = [
    {
        name: 'law-vertical-page',
        title: 'Tell us a few more details about your business so we can help you find the right protection.',
        fields: ['has_employees', 'working_space_type', 'has_automobiles'],
    },
];
