import { IndexableObject } from '@embroker/shotwell/core/object';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { map, Matcher, mount, NaviRequest, redirect, withData } from 'navi';
import { AppContext, AppContextStore } from '../AppContext';
import {
    AuthType,
    isMatcherWithAuth,
    isRouteGroupDefinition,
    MatcherWithAuth,
    Route,
    RouteDefinitions,
    RouteGroupDefinition,
    RouteMatchers,
} from './Route';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { hasRole } from '../../userOrg/entities/Session';
import { SelectOrganization } from '../../userOrg/useCases/SelectOrganization';
import { Nullable } from '@embroker/shotwell/core/types';
import {
    LOADING_DATA_ROUTE,
    USER_ONBOARDING_BASE_ROUTE,
} from '@app/userOrg/view/routes/onboardingRoutes';
import { GetUserOnboardingDetails } from '../../userOrg/useCases/GetUserOnboardingDetails';
import { GetActiveSession } from '@app/userOrg/useCases/GetActiveSession';
import { GetNBRVFunnelStatus } from '@app/userOrg/useCases/GetNBRVFunnelStatus';
import { SetNBRVFunnelStatus } from '@app/userOrg/useCases/SetNBRVFunnelStatus';
import { GetUnauthenticatedUserOnboardingDetails } from '@app/userOrg/useCases/GetUnauthenticatedUserOnboardingDetails';

export function loadRoutes(...moduleRoutes: RouteDefinitions<AppContext>[]) {
    const definitions: RouteDefinitions<AppContext>[] = [];
    for (const routeDefinitions of moduleRoutes) {
        definitions.push(routeDefinitions);
    }

    const routes = getRouteMatchers(definitions);

    return mount({ ...routes });
}

function getRouteMatchers(
    definitions: RouteDefinitions<AppContext>[] | RouteDefinitions<AppContext>,
    {
        prefix = '',
        authType = 'default',
        isOrganizationSpecific = true,
        registeredRoutes = new Set(),
    }: {
        prefix?: string;
        authType?: AuthType;
        isOrganizationSpecific?: boolean;
        registeredRoutes?: Set<string>;
    } = {},
): RouteMatchers<AppContext> {
    const routes: RouteMatchers<AppContext> = {};

    for (const routeGroup of Array.isArray(definitions) ? definitions : [definitions]) {
        for (const uri of Object.keys(routeGroup)) {
            if (process.env.NODE_ENV !== 'production') {
                const normalizedURI = URI.join('/', prefix, uri, '/')
                    .replace(/:[^/]+/g, '<param>')
                    .toUpperCase();
                if (registeredRoutes.has(normalizedURI)) {
                    throw new Error(
                        `Route ${normalizedURI.toLowerCase()} declared more than once.`,
                    );
                }
                registeredRoutes.add(normalizedURI);
            }

            const routeDefinition = routeGroup[uri];
            const routeUri = URI.build(prefix, uri);

            let matcher: Matcher<AppContext> | undefined;
            let routeAuthType = authType;
            let isRouteOrganizationSpecific = isOrganizationSpecific;

            if (typeof routeDefinition === 'function') {
                matcher = routeDefinition;
            } else if (isMatcherWithAuth(routeDefinition)) {
                routeAuthType = routeDefinition.auth;
                isRouteOrganizationSpecific = routeDefinition.isOrganizationSpecific ?? true;
                if (isRouteGroupDefinition<AppContext>(routeDefinition.handler)) {
                    const matchers = getRouteMatchers(routeDefinition.handler.routes, {
                        registeredRoutes,
                        prefix: routeUri,
                        authType: routeAuthType,
                        isOrganizationSpecific: isRouteOrganizationSpecific,
                    });
                    Object.assign(routes, matchers);
                } else {
                    matcher = routeDefinition.handler as Matcher<AppContext>;
                }
            } else if (isRouteGroupDefinition<AppContext>(routeDefinition)) {
                const matchers = getRouteMatchers(routeDefinition.routes, {
                    registeredRoutes,
                    prefix: routeUri,
                    authType: routeAuthType,
                    isOrganizationSpecific: isRouteOrganizationSpecific,
                });
                Object.assign(routes, matchers);
            }

            if (matcher !== undefined) {
                if (isRouteOrganizationSpecific) {
                    matcher = withOrganizationId(matcher);
                }
                routes[routeUri] = withAuthentication(routeAuthType, matcher);
            }
        }
    }

    return routes;
}

/**
 * Implicitly header is always rendered. This functions allows you to pass custom header for
 * passed routes or to avoid header rendering completely by sending null for header argument
 * @param header Custom header element or null for no header
 * @param route Routes that will use provided header
 */
export function withHeader<T extends Matcher<AppContext> | Route<AppContext>>(
    header: null | JSX.Element,
    route: T,
): T {
    return injectRouteData({ header: header }, route as any) as T;
}

export function withWizard<T extends object = AppContext>(route: Matcher<T>): Matcher<T> {
    return mount({
        '/': map<T>(async (request) =>
            withData({ url: request.mountpath, page: undefined }, route),
        ),
        '/:page': map<T>(async (request) =>
            withData({ url: request.mountpath, page: request.params.page }, route),
        ),
    } as any);
}

function injectRouteData<T extends object = AppContext>(
    data: IndexableObject,
    route: Matcher<T>,
): Matcher<T>;
function injectRouteData<T extends object = AppContext>(
    data: IndexableObject,
    route: MatcherWithAuth<T>,
): MatcherWithAuth<T>;
function injectRouteData<T extends object = AppContext>(
    data: IndexableObject,
    route: RouteGroupDefinition<T>,
): RouteGroupDefinition<T>;

function injectRouteData<T extends object = AppContext>(
    data: IndexableObject,
    route: Matcher<T> | Route<T>,
): typeof route {
    if (typeof route === 'function') {
        return withData(data, route);
    }

    if (route && 'handler' in route) {
        if (typeof route.handler === 'function') {
            return {
                ...route,
                handler: withData(data, route.handler),
            };
        }
        return {
            ...route,
            handler: injectRouteData(data, route.handler),
        };
    }

    if (route && 'type' in route) {
        const routes: RouteDefinitions<T> = {};
        for (const uri of Object.keys(route.routes)) {
            routes[uri] = injectRouteData(data, route.routes[uri] as any);
        }
        return {
            ...route,
            routes,
        };
    }

    return withData(data, route);
}

function withAuthentication(authType: AuthType, matcher: Matcher<AppContext, any>) {
    return map(async (request, context: AppContext) => {
        if (authType === 'any') {
            return matcher;
        }
        const { activeSession } = context;

        const getUserOnboardingDetailsResp = await execute(GetUserOnboardingDetails);

        const getUnauthorizedOnboardingSignUpDataResp = await execute(
            GetUnauthenticatedUserOnboardingDetails,
        );
        const UnauthorizedOnboardingSignUpData = isOK(getUnauthorizedOnboardingSignUpDataResp)
            ? getUnauthorizedOnboardingSignUpDataResp.value
            : null;

        const UserOnboardingDetails = isOK(getUserOnboardingDetailsResp)
            ? getUserOnboardingDetailsResp.value
            : null;

        if (UserOnboardingDetails && activeSession.isAuthenticated) {
            const isOnboardingPath = USER_ONBOARDING_BASE_ROUTE.includes(request.mountpath);
            if (isOnboardingPath) {
                return matcher;
            }
            return redirect(LOADING_DATA_ROUTE, { exact: false });
        }

        if (UnauthorizedOnboardingSignUpData && activeSession.isAuthenticated) {
            const isCreateApplicationPath = '/shopping/create-application'.includes(
                request.mountpath,
            );

            if (isCreateApplicationPath) {
                return matcher;
            }

            return redirect('/shopping/create-application', { exact: false });
        }

        if (activeSession.isAuthenticated) {
            const getActiveSessionResponse = await execute(GetActiveSession);
            const activeSession = isOK(getActiveSessionResponse)
                ? getActiveSessionResponse.value
                : null;

            const getNBRVFunnelStatusResponse = await execute(GetNBRVFunnelStatus);
            const nbrvFunnelStatus = isOK(getNBRVFunnelStatusResponse)
                ? getNBRVFunnelStatusResponse.value
                : null;

            if (activeSession && !nbrvFunnelStatus?.funnelDetails) {
                await execute(SetNBRVFunnelStatus, {
                    shown: false,
                    initialSessionId: activeSession.session.id,
                });
            }

            if (authType === 'none') {
                const { redirectTo } = request.query;
                const redirectUrl =
                    typeof redirectTo === 'string' ? decodeURIComponent(redirectTo) : '/';

                return redirect(redirectUrl, { exact: false });
            }
            return matcher;
        }

        if (authType === 'none') {
            return matcher;
        }

        // If we're logging out return early so we don't end up with /login?returnTo=/logout URL
        if (request.mountpath === '/logout') {
            return redirect('/login');
        }

        // deep link new user param
        const isNewUser = request.params.new;
        const page = isNewUser ? 'signup' : 'login';
        return redirect(
            URI.build('/', page, {
                redirectTo: request.originalUrl,
            }),
            {
                exact: false,
            },
        );
    });
}

function withOrganizationId(matcher: Matcher<AppContext, any>) {
    return map(async (request, context: AppContext) => {
        let { activeSession } = context;
        const { organizationId: orgIdQueryParameter } = request.query;
        const organizationId = UUID.check(orgIdQueryParameter) ? orgIdQueryParameter : null;

        const isBroker = hasRole(activeSession, 'broker');
        // If no organization context is provided and user is broker we need to redirect to broker dashboard.
        if (organizationId === null && activeSession.organizationId === null && isBroker) {
            return redirect('/broker/dashboard');
        }

        // If the Organization Id in the URL query parameter does not belong to the active user,
        // then clear that query parameter
        if (organizationId !== null && !isBroker) {
            const { userOrganizationIdList } = activeSession;
            const orgBelongsToActiveUser = userOrganizationIdList?.includes(organizationId);
            if (!orgBelongsToActiveUser) {
                const redirectUrl = URI.build('/', {
                    ...request.query,
                    organizationId: undefined,
                });
                return redirect(redirectUrl, { exact: false });
            }
        }

        // We DO NOT have an organization id in the URL but we DO have an organization
        // selected in the active session, redirect:
        //
        //  /whatever -> /whatever?organizationId=<<organizationId>>
        //
        if (organizationId === null && activeSession.organizationId !== null) {
            const redirectUrl = URI.build('/', request.originalUrl.replace(/^\//, ''), {
                organizationId: activeSession.organizationId,
            });
            return redirect(redirectUrl, { exact: false });
        }
        // We DO have an organization id in the URL but we DO NOT have an organization
        // selected in the active session:
        if (organizationId !== null && activeSession.organizationId === null) {
            // Try to select organization specified in the URL
            if (isErr(await execute(SelectOrganization, { organizationId }))) {
                // If it fails redirect:
                //
                //  /whatever -> /user/switch-companies?redirectTo=/whatever
                //
                const redirectUri = URI.build('/user/switch-companies', {
                    redirectTo: getRedirectUrl(request, organizationId),
                });
                return redirect(redirectUri, { exact: false });
            }
            // If SelectOrganization succeeds make sure to update the context and continue matching
            activeSession = AppContextStore.context.activeSession;
        }
        // We DO NOT have an organization id in the URL, OR
        // we DO NOT have an organization selected in the active session, OR
        // the selected organization's id DOES NOT MATCH one in the URL, redirect:
        //
        //    /whatever -> /user/switch-companies?redirectTo=/whatever
        //
        if (organizationId === null || activeSession.organizationId === null) {
            const redirectUri = URI.build('/user/switch-companies', {
                redirectTo: getRedirectUrl(request, organizationId),
            });
            return redirect(redirectUri, { exact: false });
        }

        // if selected organization DOES NOT MATCH one in the URL and user is a broker
        if (organizationId !== null && activeSession.organizationId !== null && isBroker) {
            // Try to select organization specified in the URL
            if (isErr(await execute(SelectOrganization, { organizationId }))) {
                // If it fails, redirect to dashboard
                return redirect('/broker/dashboard');
            }
        }

        return matcher;
    });
}

function getRedirectUrl(request: NaviRequest<AppContext>, organizationId: Nullable<UUID>) {
    const parts = request.originalUrl.split('/').slice(1);
    const shouldRemoveUrlQueryContext = organizationId !== null;
    if (shouldRemoveUrlQueryContext) {
        const pathQueryPair = parts[parts.length - 1].split('?');
        if (pathQueryPair.length > 1) {
            const query = pathQueryPair[1]
                .split('&')
                .filter((param) => {
                    const paramPair = param.split('=');
                    const decodedURI = URI.decodeURIComponent(paramPair[1]);
                    if (isErr(decodedURI)) {
                        return false;
                    }
                    return paramPair.length < 2 || !UUID.check(decodedURI);
                })
                .join('&');
            parts[parts.length - 1] =
                query.length > 0 ? `${pathQueryPair[0]}?${query}` : pathQueryPair[0];
        }
    }

    return URI.build('/', ...parts);
}
