import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface GlobalConfig {
    newBundleFlowEnabled: boolean;
    isBopEnabled: boolean;
    isPcomlEnabled: boolean;
    isWcgaEnabled: boolean;
    isLplEnabled: boolean;
    isEmbrokerCrimeEnabled: boolean;
    googleTagManagerToken: string;
    lplDevModeEffectiveDate: string;
    pcomlEndorsementEnabled: boolean;
    crimeDevModeEffectiveDate: string;
    salesforceBrowsingUrl: string;
    embrokerAppUrl: string;
    isNewESPEndorsementEnabled: boolean;
    isMidTermBorEnabled: boolean;
    isCertificatesPhoenixAdminEnabled: boolean;
    isEoCyberSplitEnabled: boolean;
    isPreBindEndorsementsEnabled: boolean;
    isBundleRepresentationEnabled: boolean;
    isPremiumFinanceBillingMethodUpdateEnabled: boolean;
    isClaimRequestEnabled: boolean;
    excessClearBlueEverspanSwitchDate: Date;
    espClearBlueEverspanSwitchDate: Date;
    pcoClearBlueEverspanSwitchDate: Date;
    crimeClearBlueEverspanSwitchDate: Date;
    cyberClearBlueEverspanSwitchDate: Date;
    isMplEnabled: boolean;
    isHomeInspectorsMplVerticalEnabled: boolean;
    isRealEstateAgentsMplVerticalEnabled: boolean;
    espIptChangesEnabled: boolean;
    espIptChangesReleaseDate: Date;
    pcomlIptChangesEnabled: boolean;
    excessIptChangesEnabled: boolean;
    excessRenewalsEnabled: boolean;
    omitEffectiveDateValidation: boolean;
    streamlineRenewalsEnhanced: boolean;
}

export const GlobalConfig = {
    ...defineValidator<GlobalConfig>({
        newBundleFlowEnabled: Joi.boolean(),
        isBopEnabled: Joi.boolean(),
        embrokerAppUrl: Joi.string(),
        crimeDevModeEffectiveDate: Joi.string().allow(''),
        isLplEnabled: Joi.boolean(),
        salesforceBrowsingUrl: Joi.string(),
        lplDevModeEffectiveDate: Joi.string().allow(''),
        pcomlEndorsementEnabled: Joi.boolean(),
        isWcgaEnabled: Joi.boolean(),
        isPcomlEnabled: Joi.boolean(),
        googleTagManagerToken: Joi.string().allow(''),
        isEmbrokerCrimeEnabled: Joi.boolean(),
        isNewESPEndorsementEnabled: Joi.boolean(),
        isMidTermBorEnabled: Joi.boolean(),
        isCertificatesPhoenixAdminEnabled: Joi.boolean(),
        isEoCyberSplitEnabled: Joi.boolean(),
        isPreBindEndorsementsEnabled: Joi.boolean(),
        isBundleRepresentationEnabled: Joi.boolean(),
        isPremiumFinanceBillingMethodUpdateEnabled: Joi.boolean(),
        isClaimRequestEnabled: Joi.boolean(),
        excessClearBlueEverspanSwitchDate: Joi.date(),
        espClearBlueEverspanSwitchDate: Joi.date(),
        pcoClearBlueEverspanSwitchDate: Joi.date(),
        crimeClearBlueEverspanSwitchDate: Joi.date(),
        cyberClearBlueEverspanSwitchDate: Joi.date(),
        isMplEnabled: Joi.boolean(),
        isHomeInspectorsMplVerticalEnabled: Joi.boolean(),
        isRealEstateAgentsMplVerticalEnabled: Joi.boolean(),
        espIptChangesEnabled: Joi.boolean(),
        espIptChangesReleaseDate: Joi.date(),
        pcomlIptChangesEnabled: Joi.boolean(),
        excessIptChangesEnabled: Joi.boolean(),
        excessRenewalsEnabled: Joi.boolean(),
        omitEffectiveDateValidation: Joi.boolean(),
        streamlineRenewalsEnhanced: Joi.boolean(),
    }),
    create(globalConfig: GlobalConfig) {
        return GlobalConfig.validate(globalConfig);
    },
};
