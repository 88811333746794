import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

export interface LogoClickedEvent extends DomainEvent {
    name: 'EmbrokerLogoClicked';
    path: '/signup' | '/signin';
}

export interface PublishLogoClickedEvent extends UseCase {
    execute(path: '/signup' | '/signin'): AsyncResult<void>;
}

@injectable()
class PublishLogoClickedEventUseCase extends UseCase implements PublishLogoClickedEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishLogoClickedEventUseCase');

    /**
     * Constructor for PublishLogoClickedEventUseCase class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishLogoClickedEvent use case.
     */
    public async execute(path: '/signup' | '/signin'): AsyncResult<void> {
        const logoClickedEvent: LogoClickedEvent = {
            origin: 'User',
            name: 'EmbrokerLogoClicked',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            path,
        };

        await this.eventBus.publish(logoClickedEvent);

        return Success();
    }
}

export const PublishLogoClickedEvent: UseCaseClass<PublishLogoClickedEvent> =
    PublishLogoClickedEventUseCase;
