import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../../Module';
import { PublishFormEvent } from './useCases/PublishFormEvent';

/**
 * The Data Driven Form module.
 */
export const DataDrivenFormModule: Module = {
    container: new ContainerModule((bind) => {
        bind<PublishFormEvent>(PublishFormEvent.type).to(PublishFormEvent).inSingletonScope();
    }),
};
