import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Answer, QuestionerQuestion, StaticQuestionConfigs } from './QuestionerQuestionType';
import { RequireAtLeastOne } from '@embroker/ui-toolkit/v2';
import {
    BooleanValidationDefinition,
    NumberValidationDefinition,
    StringValidationDefinition,
    ValidationTypeProps,
} from '@app/view/components/DataDrivenForm/types/fieldValidationFactory';
import {
    ConditionalDisplayOption,
    ConditionalDisplayOptions,
    ConditionalEnablementOption,
    ConditionalEnablementOptions,
} from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { AggregatorValueType } from './AggregatorTypes';
import { OperatorValueType } from './OperatorTypes';

export type ComparissonAnswerObjectType = RequireAtLeastOne<{
    [key in ComparissonAnswerType]: unknown[];
}> & { type: ComparisonValueType };
export const OracleAnswerTypeSchema = Joi.object().pattern(
    Joi.string(),
    Joi.array().items(Joi.any()),
);

export const ComparissonAnswerTypes = ['text', 'number', 'date', 'bool'] as const;
export type ComparissonAnswerType = (typeof ComparissonAnswerTypes)[number];

// ComparisonValueType definition
export const ComparisonValueSchema = Joi.object().pattern(Joi.string(), Joi.any());

//
// ComparisonValueType definition
export const ComparisonValueTypes = ['TEXT', 'NUMBER', 'DATE', 'BOOLEAN'] as const;
export type ComparisonValueType = (typeof ComparisonValueTypes)[number];

export interface Condition {
    answer?: Answer;
    comparison_value: ComparissonAnswerObjectType;
    operator: OperatorValueType;
    external_ref: { key: string; field?: string; aggregator?: AggregatorValueType };
}

export interface ConditionalAction {
    action: 'VISIBLE' | 'HIDDEN' | 'ENABLED' | 'DISABLED';
    rules: Condition[][];
}

export const RuleSchema = Joi.object({
    answer: Joi.object({
        key: Joi.string().required(),
        source: Joi.object({
            type: Joi.string().required().allow(''),
            id: Joi.string().required().allow(''),
        }),
        provided_at: Joi.string().isoDate().required(),
        received_at: Joi.string().isoDate().required(),
        type: Joi.string().required(),
        multiplicity: Joi.number().integer().required(),
        value: Joi.object().pattern(Joi.string(), Joi.array().items(Joi.any())).required(),
    }),
    operator: Joi.string().required(),
    comparison_value: Joi.any().optional(),
    external_ref: Joi.object({
        key: Joi.string().required(),
        field: Joi.string().allow('').optional(),
        aggregator: Joi.string().optional(),
    }),
});

export const RulesSchema = Joi.array().items(RuleSchema);

export const ConditionalActionSchema = Joi.object({
    action: Joi.string().valid('VISIBLE', 'HIDDEN', 'ENABLED', 'DISABLED').required(),
    rules: Joi.array().items(RulesSchema).required(),
});

export const getConditionalDisplayDefinition = (
    question: QuestionerQuestion,
    staticConditionalActions?: StaticQuestionConfigs['staticConditionalActions'],
): ConditionalDisplayOptions | undefined => {
    const staticConditionalRulesForQuestion =
        staticConditionalActions && staticConditionalActions[question.key]
            ? staticConditionalActions[question.key]
            : [];
    const conditionalActions = [
        ...staticConditionalRulesForQuestion,
        ...(question.conditional_actions || []),
    ];
    const displayAction = conditionalActions.find(({ action }) => action === 'VISIBLE');
    if (displayAction) {
        const displayWhenRules: ConditionalDisplayOptions = [];
        for (const rule of displayAction.rules) {
            if (rule) {
                const additiveRules: ConditionalDisplayOption[] = [];
                for (const condition of rule) {
                    const questionKey = condition.external_ref.key;
                    const questionField = condition.external_ref.field;
                    const questionAggregator = condition.external_ref.aggregator;
                    const conditionDefinition = getDisplayCondition(condition);
                    if (conditionDefinition) {
                        additiveRules.push({
                            displayWhen: {
                                questionKey,
                                condition: conditionDefinition,
                                questionField,
                                questionAggregator,
                            },
                        });
                    }
                }
                displayWhenRules.push(additiveRules);
            }
        }

        if (displayWhenRules.length) {
            return displayWhenRules;
        }
    }
    return;
};

export const getConditionalEnablementDefinition = (question: QuestionerQuestion) => {
    const enableAction = question.conditional_actions?.find(({ action }) => action === 'ENABLED');
    if (enableAction) {
        const enableWhenRules: ConditionalEnablementOptions = [];
        for (const rule of enableAction.rules) {
            if (rule) {
                const additiveRules: ConditionalEnablementOption[] = [];
                for (const condition of rule) {
                    const questionKey = condition.external_ref.key;
                    const conditionDefinition = getDisplayCondition(condition);
                    if (conditionDefinition) {
                        additiveRules.push({
                            enableWhen: { questionKey, condition: conditionDefinition },
                        });
                    }
                }
                enableWhenRules.push(additiveRules);
            }
        }
        if (enableWhenRules.length) {
            return enableWhenRules;
        }
    }
    return;
};

export const getDisplayCondition = (
    condition: Condition,
): Omit<ValidationTypeProps, 'conditional'> | undefined => {
    switch (condition.comparison_value.type) {
        case 'BOOLEAN':
            return { boolean: getBooleanDisplayCondition(condition) };
        case 'NUMBER':
            return { number: getNumberDisplayCondition(condition) };
        case 'TEXT':
            return { string: getStringDisplayCondition(condition) };
    }

    const stringifyCondition = JSON.stringify(condition);
    const errorMsg = `Unknown comparison value type: ${stringifyCondition}`;

    if (process.env.NODE_ENV === 'production') {
        // This scenario should never happen.
        // However if condition is not found we want to surface an error in the logs to catch this scenario
        container.get<Logger>(Log).error(errorMsg);
    } else {
        console.error(errorMsg);
    }
};

export const getBooleanDisplayCondition = (
    condition: Condition,
): BooleanValidationDefinition | undefined => {
    if (condition.operator === 'EQUAL') {
        if (condition.comparison_value.bool) {
            const comparisonValue = condition.comparison_value.bool[0] as boolean;
            return { isTrue: comparisonValue };
        }
    }
};

export const getStringDisplayCondition = (
    condition: Condition,
): StringValidationDefinition | undefined => {
    if (condition.comparison_value.text) {
        switch (condition.operator) {
            case 'EQUAL':
                switch (condition.external_ref.aggregator) {
                    case 'ANY':
                        return { includes: condition.comparison_value.text as string[] };
                }
                return { equal: condition.comparison_value.text[0] as string };
        }
    }
    return;
};

export const getNumberDisplayCondition = (
    condition: Condition,
): NumberValidationDefinition | undefined => {
    if (condition.comparison_value.number) {
        switch (condition.operator) {
            case 'EQUAL':
                return { equal: condition.comparison_value.number[0] as number };
            case 'GREATER_THAN':
                return { greaterThan: condition.comparison_value.number[0] as number };
            case 'GREATER_THAN_OR_EQUAL':
                return { greaterThan: (condition.comparison_value.number[0] as number) - 1 };
            case 'LESS_THAN':
                return { lessThan: condition.comparison_value.number[0] as number };
            case 'LESS_THAN_OR_EQUAL':
                return { lessThan: (condition.comparison_value.number[0] as number) + 1 };
        }
    }
};
