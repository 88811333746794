import {
    ColumnLayout,
    Form,
    Immutable,
    SelectChangeEvent,
    SelectOptionType,
    StackLayout,
    StatusMessage,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { WCChubbQuote } from '../entities/WCChubbQuote';
import { WCChubbPerAccidentLimit, WCChubbStatutoryText } from '../types/WCChubbQuoteOptions';
import { CoverageOptionsTermDefinitions } from '../../../types/enums';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';

interface WCChubbBundleCoverageViewProps extends UnderlyingCoverageProps<WCChubbQuote> {
    policyLimitOptions: Immutable<number[]>;
    perAccidentLimitOptions: Immutable<number[]>;
    eachEmployeeLimitOptions: Immutable<number[]>;
}

export function WCChubbBundleCoverageView({
    fields,
    value,
    setValue,
    organizationInfo,
    policyLimitOptions,
    perAccidentLimitOptions,
    eachEmployeeLimitOptions,
    disabled = false,
}: WCChubbBundleCoverageViewProps) {
    const excludeOfficersFromCoverage =
        organizationInfo.address.state === 'MT' || organizationInfo.address.state === 'NJ';

    // The only option that will be tweakable is the PolicyLimit, other limits are inferred from it.
    const handlePolicyLimitChange = (e: SelectChangeEvent<WCChubbPerAccidentLimit, false>) => {
        if (!e.target.value) {
            return;
        }
        const newValue = {
            ...value,
            wcChubbPolicyLimit: e.target.value,
            wcChubbPerAccidentLimit: e.target.value,
            wcChubbEachEmployeeLimit: e.target.value,
        };
        setValue(newValue);
    };

    const wcStatutoryOptions: SelectOptionType<string>[] = [
        { title: WCChubbStatutoryText, value: WCChubbStatutoryText },
    ];

    return (
        <StackLayout gap="12">
            {/* TODO: a constant "Statutory" string only for show */}
            <Form.Field
                type={'select'}
                tooltip={CoverageOptionsTermDefinitions.workerCompensationStatutory.definition}
                inputProps={{
                    value: WCChubbStatutoryText,
                    filterable: false,
                    items: wcStatutoryOptions,
                    label: CoverageOptionsTermDefinitions.workerCompensationStatutory.title,
                    readOnly: true,
                    disabled,
                }}
                data-e2e="wc-statutory"
            />
            <Form.Field
                type={fields.wcChubbPerAccidentLimit.type}
                tooltip={CoverageOptionsTermDefinitions.elPolicyLimit.definition}
                inputProps={{
                    ...fields.wcChubbPolicyLimit.props,
                    filterable: false,
                    items: policyLimitOptions,
                    label: CoverageOptionsTermDefinitions.elPolicyLimit.title,
                    onChange: handlePolicyLimitChange,
                    disabled,
                    createNewItem: toSelectCurrencyOption,
                }}
                data-e2e="wc-policy-limit"
            />
            <ColumnLayout gap="16" responsive={{ containerWidth: { smallerThan: 'mobile' } }}>
                <Form.Field
                    type={fields.wcChubbPerAccidentLimit.type}
                    inputProps={{
                        ...fields.wcChubbPerAccidentLimit.props,
                        filterable: false,
                        items: perAccidentLimitOptions,
                        label: CoverageOptionsTermDefinitions.perAccidentLimit.title,
                        readOnly: true,
                        disabled,
                        createNewItem: toSelectCurrencyOption,
                    }}
                    data-e2e="wc-per-accident"
                />
                <Form.Field
                    type={fields.wcChubbEachEmployeeLimit.type}
                    inputProps={{
                        ...fields.wcChubbEachEmployeeLimit.props,
                        filterable: false,
                        items: eachEmployeeLimitOptions,
                        label: CoverageOptionsTermDefinitions.elEachEmployeeLimit.title,
                        readOnly: true,
                        disabled,
                        createNewItem: toSelectCurrencyOption,
                    }}
                    data-e2e="wc-each-employee"
                />
            </ColumnLayout>
            <StatusMessage status="info">
                <Text style="microcopy">
                    Your officers and owners are{' '}
                    {excludeOfficersFromCoverage ? 'excluded' : 'included'} from this coverage.
                </Text>
            </StatusMessage>
        </StackLayout>
    );
}
