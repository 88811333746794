import { container, ContainerModule } from '@embroker/shotwell/core/di';
import { Nullable, Immutable } from '@embroker/shotwell/core/types';
import { Module } from '../../Module';
import { AppContext } from '../../view/AppContext';
import { ExperimentationTest } from '../types/ExperimentationTest';
import { LegacyGrowthBookExperimentationService } from './LegacyGrowthBookExperimentationService';
import { GrowthBookExperimentationService } from './GrowthBookExperimentationService';
import { ExperimentationTestName } from '../types/ExperimentationTestName';

/**
 * Interface for the experimentation service
 */
export interface ExperimentationService {
    getExperimentationTest(
        experimentationTestName: ExperimentationTestName,
    ): Nullable<Immutable<ExperimentationTest>>;
}

/**
 * Experimentation module
 */
export const ExperimentationModule: Module = {
    container: new ContainerModule((bind) => {
        bind<LegacyGrowthBookExperimentationService>(LegacyGrowthBookExperimentationService)
            .to(LegacyGrowthBookExperimentationService)
            .inSingletonScope();
        bind<GrowthBookExperimentationService>(GrowthBookExperimentationService)
            .to(GrowthBookExperimentationService)
            .inSingletonScope();
    }),
    bootstrap: async () => {
        const legacyGrowthbookExperimentationService =
            container.get<LegacyGrowthBookExperimentationService>(
                LegacyGrowthBookExperimentationService,
            );

        const growthBookExperimentationService = container.get<GrowthBookExperimentationService>(
            GrowthBookExperimentationService,
        );

        await legacyGrowthbookExperimentationService.onReady();
        await growthBookExperimentationService.onReady();

        // @ts-expect-error: Expose growthbook service as global variable
        window.__getGrowthbookAttributes = () => {
            growthBookExperimentationService.printUserIds();
        };

        return {} as AppContext;
    },
};
