import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { ExperimentationTestNames } from './enums';

export enum ExperimentationEventNames {
    Impression = 'Impression',
    FeatureUsed = 'FeatureUsed',
}

interface ExperimentationEvent extends DomainEvent {
    readonly origin: 'Experimentation';
    readonly name: ExperimentationEventNames;
    readonly deviceId: string | undefined;
    readonly sessionId: string | undefined;
    readonly userId: string | undefined;
    readonly HEAP_userId: string | undefined;
    readonly HEAP_sessionId: string | undefined;
    readonly GA_userId: string | undefined;
    readonly localStorage_deviceId: string | undefined;
    readonly cookie_deviceId: string | undefined;
}

export interface ExperimentationImpressionEvent extends ExperimentationEvent {
    readonly name: ExperimentationEventNames.Impression;
    readonly experimentationName: ExperimentationTestNames;
    readonly assignment: number;
    readonly raw?: object;
}

export interface FeatureUsedEvent extends ExperimentationEvent {
    readonly name: ExperimentationEventNames.FeatureUsed;
    readonly featureName: ExperimentationTestNames;
    readonly value: any;
    readonly raw?: object;
}
