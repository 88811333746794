import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, FailureResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SignUp } from './SignUp';
import { UserOnboardingRepository } from '../repositories/UserOnboardingRepository';
import { QuestionnaireData } from '../view/components/userOnboardingFlow/SignupOnboardingWrapper';
import { SaveOnboardingAnswers } from '@app/shoppingQuestioner/useCases/SaveOnboardingAnswers';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';
import { NAICS_CODE_TO_VERTICAL } from '../types/enums';
import {
    UpdateOrganizationProfile,
    UpdateOrganizationProfileRequest,
} from './UpdateOrganizationProfile';
import { API } from '@embroker/shotwell-api/app';

/**
 * Request data for UnauthorizedOnboardingSignUp use case
 */
export interface UnauthorizedOnboardingSignUpRequest {
    questionnaireData: QuestionnaireData;
    redirectUrl?: string;
}

/**
 * Response data for UnauthorizedOnboardingSignUp use case
 */
type UnauthorizedOnboardingSignUpResponse = null;

export interface UnauthorizedOnboardingSignUp extends UseCase {
    execute(
        request: UnauthorizedOnboardingSignUpRequest,
    ): AsyncResult<UnauthorizedOnboardingSignUpResponse, OperationFailed>;
}

@injectable()
class UnauthorizedOnboardingSignUpUseCase extends UseCase implements UnauthorizedOnboardingSignUp {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UnauthorizedOnboardingSignUp');
    /**
     * Constructor for UnauthorizedOnboardingSignUp use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executed UnauthorizedOnboardingSignUp use case
     * Input is of type UnauthorizedOnboardingSignUpRequest
     * @returns data of type UnauthorizedOnboardingSignUpResponse after successful execution
     * @returns OperationFailed
     */
    public async execute({
        questionnaireData,
        redirectUrl,
    }: UnauthorizedOnboardingSignUpRequest): AsyncResult<
        UnauthorizedOnboardingSignUpResponse,
        OperationFailed
    > {
        const signupRequest = {
            organizationName: questionnaireData.company_name,
            emailAddress: questionnaireData.email,
            naicsCode: questionnaireData.naics_code,
            website: questionnaireData.company_website,
            selectedAppTypes: [questionnaireData.applicationType],
            coverageSelection: questionnaireData.selectedCoverages,
            signUpInviteToken: null,
            certificateInviteToken: null,
            redirectUrl,
            questionnaireData,
        };

        await this.userOnboardingRepository.setUnauthorizedOnboardingSignUpData(signupRequest);
        const signUpUseCaseResponse = await execute(SignUp, signupRequest);

        if (isErr(signUpUseCaseResponse)) {
            return signUpUseCaseResponse as FailureResult<OperationFailed>;
        } else {
            const organization = {
                id: signUpUseCaseResponse.value.organization.id,
                naics: questionnaireData.naics_code,
                companyLegalName: questionnaireData.company_name,
                totalNumberOfEmployees: Number(questionnaireData.total_number_employees),
                headquarters: questionnaireData.headquarters,
                raisedFunding: questionnaireData.onb_raised_funding,
                techAreaOfFocus: questionnaireData.tech_area_of_focus,
                email: questionnaireData.email,
                workspaceOwnership: questionnaireData.working_space_type,
                revenues: questionnaireData.total_revenue
                    ? [questionnaireData.total_revenue]
                    : undefined,
            } as UpdateOrganizationProfileRequest;

            // Fixing a really weird race condition where we seem to reach the useCase execution
            // before the API context is updated with the organization.id
            // despite us having the value available to us
            API.updateContext({
                organization_id: organization.id,
            });

            await execute(UpdateOrganizationProfile, organization);
        }

        if (NAICS_CODE_TO_VERTICAL[questionnaireData.naics_code] === 'TechCompanies') {
            const saveOnboardingAnswers = {
                main_business_location: questionnaireData.headquarters,
                onb_naics_code: questionnaireData.naics_code || undefined,
                has_employees: questionnaireData.has_employees,
                onb_raised_funding:
                    questionnaireData.onb_raised_funding === null
                        ? undefined
                        : Boolean(questionnaireData.onb_raised_funding),
                onb_area_of_focus: questionnaireData.tech_area_of_focus || undefined,
                total_number_employees: questionnaireData.total_number_employees
                    ? Number(questionnaireData.total_number_employees)
                    : undefined,
                working_space_type: questionnaireData.working_space_type,
                uses_employee_or_rental_vehicles: questionnaireData.has_automobiles,
                company_name: questionnaireData.company_name,
                company_website: questionnaireData.company_website,
                has_website: questionnaireData.has_website,
            };
            await execute(SaveOnboardingAnswers, {
                currentStepQuestionnaireData:
                    saveOnboardingAnswers as OnboardingPrefillQuestionnaireData,
            });
        }

        return Success(null);
    }
}

export const UnauthorizedOnboardingSignUp: UseCaseClass<UnauthorizedOnboardingSignUp> =
    UnauthorizedOnboardingSignUpUseCase;
