import { injectable } from '@embroker/shotwell/core/di';
import {
    AsyncResult,
    Failure,
    Success,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { QuestionerRepository } from '.';
import { FailedToGetQuestionerForm, FailedToSaveQuestionerAnswers } from '../errors';
import { QuestionerQuestion } from '../types/QuestionerQuestionType';
import { API } from '@embroker/shotwell-api/v2/app';
import { Section } from '../types/QuestionerSectionType';
import { OracleAnswer } from '../types/OracleAnswerType';
import { getExternalQuestions } from '../types/ExternalQuestions';
import { ErrorCode } from '@embroker/shotwell/core/Error';

export interface SaveQuestionnaireAnswersRequest {
    questionnaireKeys?: string[];
    sectionKeys?: string[];
    answers: OracleAnswer[];
}
export type SaveQuestionnaireAnswersResponse = {};
export interface GetQuestionnaireParams {
    questionnaireIds: string[];
    includeAnswers: boolean;
}
export interface GetQuestionerFormRepositoryResponse {
    questions: QuestionerQuestion[];
    externalQuestions: QuestionerQuestion[];
    sections?: Section[];
}
@injectable()
export class APIQuestionerRepository implements QuestionerRepository {
    public async getQuestionerForm(
        request: GetQuestionnaireParams,
    ): AsyncResult<GetQuestionerFormRepositoryResponse, FailedToGetQuestionerForm> {
        const { includeAnswers, questionnaireIds } = request;

        const questionnaireIdsString = questionnaireIds.join(',');
        const questionnaireResult = await API.get(
            `questions?includeAnswers=${includeAnswers}&questionnaire_ids=${questionnaireIdsString}`,
        );

        if (isErr(questionnaireResult)) {
            return Failure(FailedToGetQuestionerForm());
        }

        const questionnaireQuestions = questionnaireResult.value.questions;

        const questionResults = await Promise.all(
            questionnaireQuestions.map((question) => createQuestion(question)),
        );

        const respSections: Section[] = [];
        const sectionResults = await Promise.all(
            questionnaireResult.value.sections.map((section) => createSection(section)),
        );

        const externalQuestionDefinitions = getExternalQuestions(
            questionnaireQuestions as unknown as QuestionerQuestion[],
        );
        const externalQuestionDefinitionsResults = await Promise.all(
            externalQuestionDefinitions.map((question) => createQuestion(question)),
        );

        const respQuestions: QuestionerQuestion[] = [];
        for (const result of questionResults) {
            if (isErr(result)) {
                // TODO: https://embroker.atlassian.net/browse/EM-43041
                // We want to be defensive in how we handle errors
                // In this scenario we may want to ensure there is a falback question to ensure a user can input something. For now we skip over it and remove from the questionnaire.
            } else {
                const questionerQuestion = result.value.value as QuestionerQuestion;
                respQuestions.push(questionerQuestion);
            }
        }

        const externalQuestions: QuestionerQuestion[] = [];
        for (const result of externalQuestionDefinitionsResults) {
            if (!isErr(result)) {
                const externalQuestion = result.value.value as QuestionerQuestion;
                externalQuestions.push(externalQuestion);
            }
        }

        for (const result of sectionResults) {
            if (isErr(result)) {
                // TODO: https://embroker.atlassian.net/browse/EM-43041
                // We want to be defensive in how we handle errors
                // In this scenario we may want to ensure that malformed sections do not blow upn the user experience. For now we skip over it and remove from the questionnaire.
            } else {
                const section = result.value.value as Section;
                respSections.push(section);
            }
        }

        return Success({
            questions: respQuestions,
            sections: respSections,
            externalQuestions,
        });
    }
    public async saveQuestionerAnswers(
        request: SaveQuestionnaireAnswersRequest,
    ): AsyncResult<SaveQuestionnaireAnswersResponse, FailedToSaveQuestionerAnswers> {
        const { answers, questionnaireKeys, sectionKeys } = request;

        const saveAnswersResult = await API.post(`answers`, {
            answers,
            questionnaire_keys: questionnaireKeys || [],
            section_keys: sectionKeys || [],
        });

        if (isErr(saveAnswersResult)) {
            // Currently POST /answers returns a 200 status code with empty body on success
            // This will throw a NotAcceptable error due to how the core network layer is implemented, https://github.com/embroker/shotwell/blob/aad8046af620fce7c50bc3fef9acb5d360b7747e/core/networking/request.ts#L269-L278
            // Follow up ticket for a longer term solution https://embroker.atlassian.net/browse/EM-45601
            const hasInvalidArgument = saveAnswersResult.errors.some(
                (error) => error.code !== ErrorCode.NotAcceptable,
            );

            if (hasInvalidArgument) {
                return Failure(FailedToSaveQuestionerAnswers());
            }
        }

        return Success({});
    }
}

export const createQuestion = async (questionObj: unknown) => {
    const question = QuestionerQuestion.create(questionObj);
    if (isErr(question)) {
        return handleOperationFailure(question);
    }
    return Success(question);
};

export const createSection = async (sectionObj: unknown) => {
    const section = Section.create(sectionObj);
    if (isErr(section)) {
        return handleOperationFailure(section);
    }
    return Success(section);
};
