import React, { useEffect } from 'react';
import { useNavigation } from '@app/view/hooks/useNavigation';
import {
    onboardingStepUrlMap,
    USER_ONBOARDING_BASE_ROUTE,
} from '@app/userOrg/view/routes/onboardingRoutes';
import { TechVerticalProductSelectionPage } from './techVerticalProductSelection/TechVerticalProductSelectionPage';
import { StartGenericBundleRequest, useStartApplication } from '../hooks/useStartApplication';
import { Spinner } from '@embroker/ui-toolkit/v2';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    FormEventName,
    PublishFormEvent,
} from '@app/view/components/DataDrivenForm/useCases/PublishFormEvent';
import { Vertical } from '@app/userOrg/types/enums';
import { GetUserOnboardingStep } from '@app/userOrg/useCases/GetUserOnboardingStep';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UserOnboardingStepType } from '@app/userOrg/types/UserOnboardingDetails';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { Organization } from '@app/userOrg/entities/Organization';

export function ProductSelectionPage() {
    const { navigate } = useNavigation();
    const { startGenericBundleApplication, isLoading } = useStartApplication();
    const { result, isLoading: getActiveOrganizationProfileLoading } = useUseCase(
        GetActiveOrganizationProfile,
    );
    const userOrganizationProfile: Organization | undefined =
        result && isOK(result) ? (result.value.organization as Organization) : undefined;

    const handleBackClicked = async () => {
        const onboardingStep = await execute(GetUserOnboardingStep, {
            currentStep: null,
            progessType: 'backward',
            questionnaireData: { onb_naics_code: userOrganizationProfile?.naics ?? undefined },
        });

        const lastStep: UserOnboardingStepType =
            isOK(onboardingStep) && onboardingStep.value?.stepType
                ? onboardingStep.value.stepType
                : 'businessLocation';

        navigate(`${USER_ONBOARDING_BASE_ROUTE}${onboardingStepUrlMap[lastStep]}`);
    };

    const handleContinueClicked = (startGenericBundleRequest: StartGenericBundleRequest) => {
        startGenericBundleApplication(startGenericBundleRequest);
    };

    useEffect(() => {
        // Currently we only suport TechVerticalProductSelection but this page will be built out to support other product selection pages
        const vertical: Vertical = 'TechCompanies';
        const publishFormEventPayload = {
            name: FormEventName.Viewed,
            formStage: 'product-selection',
            formName: vertical,
        };

        execute(PublishFormEvent, publishFormEventPayload);
    }, []);

    if (isLoading || getActiveOrganizationProfileLoading) {
        return <Spinner />;
    }

    // Currently we only suport TechVerticalProductSelection but this page will be built out to support other product selection pages
    return (
        <TechVerticalProductSelectionPage
            organization={userOrganizationProfile}
            onBackClicked={handleBackClicked}
            onContinueClicked={handleContinueClicked}
        />
    );
}
