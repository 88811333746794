import React, { useEffect, useMemo } from 'react';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { NaicsCodeRequiresRefinementError } from '../../../errors';
import { UpdateOrganizationNaicsCode } from '../../../useCases/UpdateOrganizationNaicsCode';
import { FormViewLayout } from '../../../../view/components/FormViewLayout.view';
import { PublishOnboardingStepVisitedEvent } from '../../../useCases/PublishOnboardingStepVisitedEvent';
import {
    NAICsOptionValueType,
    NaicsCodeRefinementField,
    REFINEMENT_QUESTION_SETS,
} from './NaicsCodeRefinementField';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { Nullable, PageLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { OnboardingPrefillQuestionnaireData } from '../../../types/OnboardingPrefillQuestionnaireData';
import { Success } from '@embroker/shotwell/core/types/Result';
import { NAICS_CODE_TO_VERTICAL } from '../../../types/enums';
import { getNaicsRefinementType } from '@app/userOrg/useCases/GetUserOnboardingStep';
import { PrefilledBanner } from './PrefilledBanner.view';

interface NaicsConfirmationFormData {
    refinedNaics: Nullable<string>;
}

interface NaicsConfirmationFormData {
    refinedNaics: string | null;
}

const createNaicsCodeRefinmentForm = ({
    userNaicsCode,
    onCompleteOnboardingStep,
}: {
    userNaicsCode?: string;
    onCompleteOnboardingStep: (questionnaireData: OnboardingPrefillQuestionnaireData) => void;
}) => {
    const NaicsConfirmationForm = createForm<NaicsConfirmationFormData>({
        fields: {
            refinedNaics: {
                type: 'radioGroup',
                validator: Joi.string().allow('').allow(null),
                formatValidationError(error) {
                    return 'refinedNaics';
                },
            },
        },
        submit: async ({ refinedNaics }) => {
            const refinedNaicsCode = refinedNaics === null ? userNaicsCode : refinedNaics;
            if (refinedNaics) {
                await execute(UpdateOrganizationNaicsCode, {
                    confirmedNaicsCode: refinedNaics,
                });
            }

            const isTechnologyConsultant = Boolean(
                refinedNaicsCode && NAICS_CODE_TO_VERTICAL[refinedNaicsCode] === 'TechCompanies',
            );

            onCompleteOnboardingStep({
                tech_consulting_services: isTechnologyConsultant,
                onb_naics_code: refinedNaicsCode,
                refined_naics_code: refinedNaicsCode,
            });
            return Success();
        },
        formatSubmitErrors(errors) {
            if (errors.length === 0) {
                return [];
            }

            const refinementError = errors.find(
                (formError) => formError.code === NaicsCodeRequiresRefinementError().code,
            );
            if (refinementError) {
                return [refinementError.message];
            }
            container
                .get<Logger>(Log)
                .warn(`User onboarding - NAICs code refinement page formatSubmitErrors: ${errors}`);
            return ['Sorry, something went wrong. Please try again later.'];
        },
    });
    return NaicsConfirmationForm;
};

export function NaicsCodeRefinement({
    onCompleteOnboardingStep,
    onBackClicked,
    questionnaireData,
    displayPrefilledBanner,
    crunchbaseLink,
}: OnboardingPageProps) {
    const refinementType = getNaicsRefinementType(questionnaireData || {});
    const userNaicsCode = questionnaireData?.onb_naics_code;

    useEffect(() => {
        if (!refinementType) {
            onCompleteOnboardingStep();
        }
    }, [refinementType, onCompleteOnboardingStep]);

    const naicsConfirmationForm = useMemo(
        () => createNaicsCodeRefinmentForm({ userNaicsCode, onCompleteOnboardingStep }),
        [userNaicsCode, onCompleteOnboardingStep],
    );
    const { fields, value, messages, setValue, submit } = useForm(naicsConfirmationForm, {
        refinedNaics: '',
    });

    useEffect(() => {
        execute(PublishOnboardingStepVisitedEvent, {
            step: 'naicsRefinement',
        });
    }, []);

    const handleNaicsRefinementOnChange = (refinedNaics: NAICsOptionValueType) => {
        setValue({
            ...value,
            refinedNaics,
        });
    };

    const displayMessages = messages.filter(
        (formError) => formError !== NaicsCodeRequiresRefinementError().message,
    );

    const disableButton = value.refinedNaics !== null && !value.refinedNaics;

    if (!refinementType) {
        // if refinementType is undefined, onCompleteOnboardingStep will be executed on page mount
        return null;
    }

    const refinementQuestion = REFINEMENT_QUESTION_SETS[refinementType];
    const { title, question } = refinementQuestion;

    return (
        <PageLayout.Section>
            <FormViewLayout
                title={title}
                errorMessages={displayMessages}
                disableSubmit={disableButton}
                onFormSubmit={submit}
                handleBack={onBackClicked}
                data-e2e-submit="user-org-public-submit-btn"
            >
                {displayPrefilledBanner !== '' && (
                    <PrefilledBanner
                        displayMessageStatus={displayPrefilledBanner}
                        crunchbaseLink={crunchbaseLink}
                    />
                )}
                <StackLayout gap="16">
                    {question && <Text>{question}</Text>}
                    <NaicsCodeRefinementField
                        refinementQuestionType={refinementType}
                        refinedNaicsCode={fields.refinedNaics.props.value}
                        onChange={handleNaicsRefinementOnChange}
                    />
                </StackLayout>
            </FormViewLayout>
        </PageLayout.Section>
    );
}
