import {
    HartfordBopCoverage,
    HartfordBopCoverageLimit,
} from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { ColumnLayout } from '@embroker/ui-toolkit/v2';
import { GetCoverageLimitCode } from '@app/quote/hartford-bop/view/components/coverageMapper';
import React from 'react';
import { CurrencyInput } from '@embroker/shotwell/view/components/CurrencyInput';

const NO_VALUE = 0;

interface Props {
    coverage: HartfordBopCoverage;
    aggregateLimit: HartfordBopCoverage;
    limit: HartfordBopCoverageLimit;
}

export function CoverageLimitValue({ coverage, limit, aggregateLimit }: Props) {
    const aggregateLimitValue = aggregateLimit.limits.find((l) => l.limit.amount !== NO_VALUE);

    if (!aggregateLimitValue) {
        return null;
    }

    return (
        <ColumnLayout>
            <CurrencyInput
                readOnly
                inputMode="decimal"
                label={GetCoverageLimitCode(coverage.code)}
                value={limit.limit}
            />

            <CurrencyInput
                readOnly
                inputMode="decimal"
                label={GetCoverageLimitCode(aggregateLimit.code)}
                value={aggregateLimitValue.limit}
            />
        </ColumnLayout>
    );
}
