import { container, inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { URI } from '@embroker/shotwell/core/types/URI';
import { GetActiveOrganizationProfile } from './GetActiveOrganizationProfile';
import { GetUserOnboardingDetails } from './GetUserOnboardingDetails';
import { MPLValidNaicsCodes } from '../types/MPLValidNaicsCodes';
import { State } from '@embroker/shotwell/core/types/StateList';
import { Nullable } from '@embroker/shotwell/core/types';
import { MPLVerticalList, NAICS_CODE_TO_VERTICAL, Vertical } from '../types/enums';
import {
    AppTypeCodeListCNABOP,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListHartfordBOP,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListPCoML,
} from '@app/shopping/types/enums';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';
import { LegacyGrowthBookExperimentationService } from '@app/experimentation/services/LegacyGrowthBookExperimentationService';
import { isVettingRequired } from './GetUserOnboardingStep';
import { deepLinks } from '@app/shopping/view/routes';

export type GetOnboardingRedirectResponse = string;
export type GetOnboardingRedirectRequest = {
    questionnaireData: OnboardingPrefillQuestionnaireData;
};

export interface GetOnboardingRedirect extends UseCase {
    execute(request: GetOnboardingRedirectRequest): AsyncResult<string, never>;
}

const LAW_FIRM_NAICS = '541110';
export const LAW_BUNDLE_REDIRECT = '/shopping/law-bundle';
export const TECH_BUNDLE_REDIRECT = '/shopping/tech-bundle';
export const GUIDANCE_PAGE_REDIRECT = '/shopping/guidance-page';
export const DEFAULT_REDIRECT = '/shopping';
export const PRODUCT_SELECTION_PAGE_URL = '/shopping/product-selection';

const WC_QUESTIONNAIRE_REDRIECT = URI.build('/shopping/questionnaire', {
    qd: JSON.stringify({
        app_type_list: [AppTypeCodeListManualWorkersCompensation],
    }),
});

export const CNA_BOP_QUESTIONNAIRE_REDRIECT = URI.build('/shopping/questionnaire', {
    qd: JSON.stringify({
        app_type_list: [AppTypeCodeListCNABOP],
    }),
});

export const HARTFORD_BOP_QUESTIONNAIRE_REDRIECT = URI.build('/shopping/questionnaire', {
    qd: JSON.stringify({
        app_type_list: [AppTypeCodeListHartfordBOP],
    }),
});

@injectable()
class GetOnboardingRedirectUseCase extends UseCase implements GetOnboardingRedirect {
    public static type = Symbol('Global/GetOnboardingRedirect');

    /**
     * constructor for the GetOnboardingRedirect use case
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetActiveOrganizationProfile.type)
        private getActiveOrganizationProfile: GetActiveOrganizationProfile,
        @inject(GetUserOnboardingDetails.type)
        private getUserOnboardingDetails: GetUserOnboardingDetails,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetOnboardingRedirectRequest,
    ): AsyncResult<GetOnboardingRedirectResponse, never> {
        const getActiveOrganizationProfileResponse =
            await this.getActiveOrganizationProfile.execute();

        if (isErr(getActiveOrganizationProfileResponse)) {
            return Success<GetOnboardingRedirectResponse>('/');
        }

        const {
            value: { organization },
        } = getActiveOrganizationProfileResponse;
        const { naics, headquarters } = organization;

        const getUserOnboardingDetailsResp = await this.getUserOnboardingDetails.execute();
        const redirectUrl = isOK(getUserOnboardingDetailsResp)
            ? getUserOnboardingDetailsResp.value?.redirectUrl
            : undefined;

        // TODO: As this redirect logic evolves, we will want to build out a more robust pattern.
        // It is tough to forsee the business logic right now, making it difficult to establish this patter at the moment.
        const onboardingRedirct = resolveOnboardingRedirectUrl(
            naics,
            redirectUrl,
            request.questionnaireData,
        );

        if (validateRedirectUrl(naics, headquarters.state, onboardingRedirct)) {
            return Success<GetOnboardingRedirectResponse>(onboardingRedirct);
        }

        return Success<GetOnboardingRedirectResponse>(DEFAULT_REDIRECT);
    }
}

export function isDeepLinkCoverageInTechBundle(redirectUrl: string, isFunded?: boolean): boolean {
    const fundedDeeplinks = [
        deepLinks.cyber,
        deepLinks.pcoml_epl,
        deepLinks.pcoml_do,
        deepLinks.pcoml_do_epl,
    ];

    const bundleEligableDeepLinks = [
        deepLinks.esp_do,
        deepLinks.esp_eo,
        deepLinks.esp_all,
        deepLinks.tech_eo_unfunded,
        deepLinks.techBundle,
        deepLinks.fiduciary,
    ].concat(isFunded ? fundedDeeplinks : []);

    const deepLinkUrls = bundleEligableDeepLinks.reduce(
        (acc, link) => [...acc, ...link.urls],
        [] as string[],
    );

    return deepLinkUrls.some((url) => redirectUrl?.includes(url));
}

export const validateRedirectUrl = (
    naicsCode: Nullable<string>,
    headquartersState: Nullable<State>,
    redirectUrl?: string,
): boolean => {
    const vertical = NAICS_CODE_TO_VERTICAL[naicsCode ?? ''];

    switch (naicsCode) {
        case LAW_FIRM_NAICS: {
            // Validate law firm NAICs and state combination
            const invalidLawStates: State[] = ['AK', 'OR'];
            const isRedirectToLawBundle = Boolean(
                redirectUrl && redirectUrl.includes(LAW_BUNDLE_REDIRECT),
            );
            const isInvalidLawState = Boolean(
                headquartersState && invalidLawStates.includes(headquartersState),
            );

            return !(isRedirectToLawBundle && isInvalidLawState);
        }
    }

    if (redirectUrl && redirectUrl.includes(TECH_BUNDLE_REDIRECT)) {
        return vertical === 'TechCompanies';
    }

    return true;
};

export const resolveOnboardingRedirectUrl = (
    naicsCode: Nullable<string>,
    redirectUrl: string | undefined,
    questionnaireData: OnboardingPrefillQuestionnaireData,
): string => {
    const redirectUrlStr = redirectUrl ?? '';

    const shouldOverrideDeepLink =
        redirectUrlStr.includes('/shopping/cyber') ||
        redirectUrlStr.includes('/shopping/bop') ||
        redirectUrlStr.includes('/shopping/hartford_bop') ||
        redirectUrlStr.includes('/shopping/wc');

    // should redirect to Law bundle
    if (naicsCode && NAICS_CODE_TO_VERTICAL[naicsCode] === 'LawFirm') {
        // redirect to law bundle if naics is law firm and
        // redirectUrl (deep link) is either empty or cyber, bop or wc
        if (!redirectUrlStr || shouldOverrideDeepLink) {
            return LAW_BUNDLE_REDIRECT;
        }
    }

    // should redirect to MPL bundle
    if (
        naicsCode &&
        MPLVerticalList.includes(NAICS_CODE_TO_VERTICAL[naicsCode]) &&
        MPLValidNaicsCodes.isNaicCodeValid(naicsCode) &&
        MPLValidNaicsCodes.isMPLVerticalEnabled(naicsCode)
    ) {
        // redirect to guidance page (mpl bundle) if naics is MPL vertical and
        // redirectUrl (deep link) is either empty or cyber, bop or wc
        if (!redirectUrlStr || shouldOverrideDeepLink) {
            return GUIDANCE_PAGE_REDIRECT;
        }
    }

    // should redirect to Tech bundle
    if (
        naicsCode &&
        NAICS_CODE_TO_VERTICAL[naicsCode] === 'TechCompanies' &&
        container
            .get<LegacyGrowthBookExperimentationService>(LegacyGrowthBookExperimentationService)
            .getFeatureValue('tech-vertical-one-by-embroker', false)
    ) {
        const { onb_raised_funding, provides_tech_service_for_fee } = questionnaireData;

        if (isVettingRequired(naicsCode)) {
            // vetting = Yes
            if (provides_tech_service_for_fee === true) {
                if (
                    !redirectUrlStr ||
                    redirectUrlStr.includes('/shopping/pcoml_do') ||
                    redirectUrlStr.includes('/shopping/pcoml_epl') ||
                    redirectUrlStr.includes('/shopping/fiduciary_liability') ||
                    redirectUrlStr.includes('/shopping/tech_eo_unfunded') ||
                    redirectUrlStr.includes('/shopping/cyber')
                ) {
                    return PRODUCT_SELECTION_PAGE_URL;
                }
            }
            // vetting = No
            if (provides_tech_service_for_fee === false) {
                if (
                    !redirectUrlStr ||
                    redirectUrlStr.includes('/shopping/tech-bundle') ||
                    redirectUrlStr.includes('/shopping/esp_eo')
                ) {
                    return DEFAULT_REDIRECT;
                }
                if (
                    redirectUrlStr.includes('/shopping/tech_eo_unfunded') ||
                    redirectUrlStr.includes('/shopping/cyber')
                ) {
                    return URI.build('/shopping/questionnaire', {
                        qd: JSON.stringify({
                            app_type_list: [AppTypeCodeListCyberCowbell],
                        }),
                    });
                }
            }
        }

        if (!onb_raised_funding) {
            if (redirectUrlStr.includes('/shopping/pcoml_do')) {
                return URI.build('/shopping/questionnaire', {
                    qd: JSON.stringify({
                        app_type_list: [AppTypeCodeListManualDirectorsAndOfficers],
                        raisedFunding: false,
                    }),
                });
            }
            if (redirectUrlStr.includes('/shopping/pcoml_epl')) {
                return URI.build('/shopping/questionnaire', {
                    qd: JSON.stringify({
                        app_type_list: [AppTypeCodeListManualEmploymentPractices],
                        raisedFunding: false,
                    }),
                });
            }
            if (redirectUrlStr.includes('/shopping/pcoml')) {
                return URI.build('/shopping/questionnaire', {
                    qd: JSON.stringify({
                        app_type_list: [AppTypeCodeListPCoML],
                        raisedFunding: false,
                    }),
                });
            }
            if (redirectUrlStr.includes('/shopping/fiduciary_liability')) {
                return URI.build('/shopping/questionnaire', {
                    qd: JSON.stringify({
                        app_type_list: [AppTypeCodeListManualFiduciary],
                        raisedFunding: false,
                    }),
                });
            }
        }
        if (
            redirectUrlStr.includes('/shopping/bop') ||
            redirectUrlStr.includes('/shopping/hartford_bop')
        ) {
            return HARTFORD_BOP_QUESTIONNAIRE_REDRIECT;
        }
        if (redirectUrlStr.includes('/shopping/wc')) {
            return WC_QUESTIONNAIRE_REDRIECT;
        }
        const deepLinkCoverageIsInTechBundle = isDeepLinkCoverageInTechBundle(
            redirectUrl ?? '',
            onb_raised_funding ?? false,
        );

        if (redirectUrl && !deepLinkCoverageIsInTechBundle) {
            return redirectUrl;
        }

        return PRODUCT_SELECTION_PAGE_URL;
    }

    // If user is not LPL or MPL or Tech and trying to access worker's comp,
    // we redirect him to the the manual worker's comp application
    if (redirectUrlStr.includes('/shopping/wc')) {
        return WC_QUESTIONNAIRE_REDRIECT;
    }

    if (redirectUrlStr.includes('/shopping/hartford_bop')) {
        return CNA_BOP_QUESTIONNAIRE_REDRIECT;
    }

    if (naicsCode !== LAW_FIRM_NAICS && redirectUrlStr.includes(LAW_BUNDLE_REDIRECT)) {
        return DEFAULT_REDIRECT;
    }

    return redirectUrlStr || DEFAULT_REDIRECT;
};

export const calculateOnboardingRedirectURLForUnauthenticatedSignup = ({
    naicsCode,
    redirectUrl,
    questionnaireData,
    vertical,
}: {
    naicsCode: string;
    redirectUrl: string | undefined;
    questionnaireData: any;
    vertical: Vertical;
}) => {
    const onboardingRedirectURL = resolveOnboardingRedirectUrl(naicsCode, redirectUrl, {
        ...questionnaireData,
        main_business_location: questionnaireData.headquarters,
        onb_naics_code: naicsCode,
        onb_area_of_focus: questionnaireData.tech_area_of_focus,
    });

    const vettingRequired = isVettingRequired(naicsCode);
    const isOneByEmbrokerEligible =
        vertical === 'TechCompanies' &&
        (!vettingRequired ||
            (vettingRequired && questionnaireData.provides_tech_service_for_fee)) &&
        (!redirectUrl || isDeepLinkCoverageInTechBundle(redirectUrl));

    const isRedirectingUserToProductSelectionPage = onboardingRedirectURL.includes(
        '/shopping/product-selection',
    );

    const shouldIgnoreLawFirm =
        vertical === 'LawFirm' && onboardingRedirectURL.includes('/shopping/law-bundle');

    const isRedirectURLValid = validateRedirectUrl(
        naicsCode,
        questionnaireData.headquarters.state,
        onboardingRedirectURL,
    );

    const shouldRelyOnRedirectURL =
        isRedirectURLValid &&
        !isOneByEmbrokerEligible &&
        !isRedirectingUserToProductSelectionPage &&
        !shouldIgnoreLawFirm;

    if (shouldRelyOnRedirectURL) {
        return onboardingRedirectURL;
    }

    return null;
};

export const GetOnboardingRedirect: UseCaseClass<GetOnboardingRedirect> =
    GetOnboardingRedirectUseCase;
