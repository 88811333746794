import { OperationFailed } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { ErrorObject, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    DefaultFormFieldRegistry,
    Renderer as Form,
    FormFieldRegistry,
    JSONSchema,
    useJSONSchemaForm,
} from '@embroker/shotwell/view/components/JSONSchemaForm';
import { useAsyncTrigger } from '@embroker/shotwell/view/hooks/useAsyncTrigger';
import { FormData } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Loader,
    RadioGroup,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import { addYears, format, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';
import {
    DocumentPublicationType,
    ESP_ADD_DO_COVERAGE,
    ESP_ADD_EPLI_COVERAGE,
    ESP_AMEND_DO_COVERAGE,
    ESP_AMEND_EPLI_COVERAGE,
    ESP_AMEND_TECH_EO_COVERAGE,
    EndorsementContext,
    validateEndorsement,
    LimitAndRetention,
    ESP_ADD_FIDUCIARY_COVERAGE,
} from '../types';
import {
    GetEndorsementQuote,
    GetEndorsementQuoteResponse,
} from '../useCases/GetEndorsementQuoteUseCase';
import { IssueEndorsement } from '../useCases/IssueEndorsementUseCase';
import { PreviewEndorsement } from '../useCases/PreviewEndorsementUseCase';
import {
    CreateOtherEndorsement,
    CreateOtherEndorsementRequest,
} from '../../endorsement/intake/useCases/CreateOtherEndorsement';
import { DocumentModal } from './DocumentModal';
import { EndorsementQuote } from './EndorsementQuote';
import { CoverageRestriction } from '../types/CoverageRestriction';
import { HigherLimitSuccessModal } from './HigherLimitSuccessModal';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { EndorsementSignature } from '@app/digitalEndorsements/view/EndorsementSignature';
import { EndorsementUserData } from '@app/digitalEndorsements/types/EndorsementUserData';
import { EndorsementList } from '@app/quote/esp/types/EndorsementList';

const AMOUNT_10_000 = Money.tryFromFloat(10_000);
const AMOUNT_25_000 = Money.tryFromFloat(25_000);
const AMOUNT_50_000 = Money.tryFromFloat(50_000);
const AMOUNT_100_000 = Money.tryFromFloat(100_000);
const AMOUNT_250_000 = Money.tryFromFloat(250_000);
const AMOUNT_500_000 = Money.tryFromFloat(500_000);
const AMOUNT_1_000_000 = Money.tryFromFloat(1_000_000);
const AMOUNT_2_000_000 = Money.tryFromFloat(2_000_000);
const AMOUNT_3_000_000 = Money.tryFromFloat(3_000_000);
const AMOUNT_4_000_000 = Money.tryFromFloat(4_000_000);
const AMOUNT_5_000_000 = Money.tryFromFloat(5_000_000);

const allowedLimitCombinations: {
    [perClaimLimit: number]: Money[];
} = {
    [AMOUNT_10_000.amount]: [AMOUNT_10_000],
    [AMOUNT_25_000.amount]: [AMOUNT_25_000],
    [AMOUNT_50_000.amount]: [AMOUNT_50_000],
    [AMOUNT_100_000.amount]: [AMOUNT_100_000],
    [AMOUNT_250_000.amount]: [AMOUNT_250_000, AMOUNT_500_000],
    [AMOUNT_500_000.amount]: [AMOUNT_500_000, AMOUNT_1_000_000],
    [AMOUNT_1_000_000.amount]: [AMOUNT_1_000_000, AMOUNT_2_000_000, AMOUNT_3_000_000],
    [AMOUNT_2_000_000.amount]: [AMOUNT_2_000_000],
    [AMOUNT_3_000_000.amount]: [AMOUNT_3_000_000],
    [AMOUNT_4_000_000.amount]: [AMOUNT_4_000_000],
    [AMOUNT_5_000_000.amount]: [AMOUNT_5_000_000],
};

function isValidPerClaimAndAggregateLimitSelection(
    endorsementType: string,
    formData: Record<string, unknown>,
): boolean {
    if (endorsementType !== 'amendDeclarationsEndorsement') {
        return true;
    }

    if (formData != undefined) {
        if (
            !formData.hasOwnProperty('perClaimLimit') ||
            !formData.hasOwnProperty('aggregateLimit')
        ) {
            return true;
        }

        return (
            allowedLimitCombinations[(formData.perClaimLimit as Money).amount] !== undefined &&
            allowedLimitCombinations[(formData.perClaimLimit as Money).amount].findIndex(
                (allowedAggregateLimit: Money) =>
                    Money.isEqual(allowedAggregateLimit, formData.aggregateLimit as Money),
            ) !== -1
        );
    }

    return true;
}

type Action = 'quote' | 'preview' | 'issue' | 'higherLimit';

export type PremiumBearingEndorsementJSONSchemaFormProps<T extends FormData, U extends string> = {
    endorsementTitle: string;
    jsonSchema: JSONSchema<T>;
    onDismiss(): void;
    onBack(): void;
    restriction?: CoverageRestriction;
    preBindEndorsements: EndorsementList | undefined;
    currentOptions?: LimitAndRetention;
    endorsementContext: EndorsementContext;
    formFieldRegistry?: FormFieldRegistry<U>;
};

function erpNumberOfYearsToNumber(erpNumberOfYears: string | undefined): number {
    switch (erpNumberOfYears) {
        case '1 year':
            return 1;
        case '2 years':
            return 2;
        case '3 years':
        case '3 years - reinstated':
            return 3;
        case '5 years':
            return 5;
        default:
            return 0;
    }
}

function runOffNumberOfYearsToNumber(numberOfYears: string | undefined): number {
    switch (numberOfYears) {
        case '1 year':
            return 1;
        case '3 years':
            return 3;
        case '6 years':
            return 6;
        default:
            return 0;
    }
}

interface DigitalEndorsementError {
    readonly code: number;
    readonly details: InnerDetails;
}

interface ResponseBody {
    readonly code: string;
    readonly message: string;
    readonly data: string;
}

interface InnerDetails {
    readonly name: string;
    readonly responseBody: ResponseBody;
}

interface ErrorDetails {
    readonly errors: DigitalEndorsementError[];
}

const formatErrorMessage = (err: ErrorObject<number, ErrorDetails | {}>): string => {
    if (
        (err.details as ErrorDetails) !== undefined &&
        (err.details as ErrorDetails).errors !== undefined &&
        (err.details as ErrorDetails).errors.length > 0
    ) {
        const data = (err.details as ErrorDetails).errors[0]?.details.responseBody.data;
        if (data !== '') {
            return data;
        }

        const msg = (err.details as ErrorDetails).errors[0]?.details.responseBody.message;
        if (msg !== '') {
            return msg;
        }
    }

    return err.message;
};

export function PremiumBearingEndorsementJSONSchemaForm<T extends FormData, U extends string>(
    props: PremiumBearingEndorsementJSONSchemaFormProps<T, U>,
) {
    const {
        endorsementTitle,
        jsonSchema,
        onDismiss,
        onBack,
        restriction,
        preBindEndorsements,
        currentOptions,
        endorsementContext,
        formFieldRegistry = new DefaultFormFieldRegistry<any>(),
    } = props;

    const higherLimitSuccessModal = useModal();
    const documentModal = useModal();
    const [documentPublicationType, setDocumentPublicationType] = useState<DocumentPublicationType>(
        DocumentPublicationType.Preview,
    );
    const [endorsementDocumentUrl, setEndorsementDocumentUrl] = useState<URI | undefined>();

    // Extract the namespace and corresponding subschema
    const namespace = jsonSchema.$id ?? '';
    const subschema = jsonSchema.properties[namespace];

    const [endorsementQuote, setEndorsementQuote] = useState<GetEndorsementQuoteResponse>();
    const [endorsementData, setEndorsementData] = useState<Immutable<T>>();
    const [errors, setErrors] = useState<Immutable<ErrorObject<number, ErrorDetails | {}>[]>>();
    const [isHigherLimitReviewRequired, setIsHigherLimitReviewRequired] = useState<boolean>(false);
    const [warrantyAndFraudSigned, setWarrantyAndFraudSigned] = useState<boolean>(false);
    const [agreementToConductSigned, setAgreementToConductSigned] = useState<boolean>(false);

    subschema.properties = filterPropertieOptions(
        subschema.properties,
        namespace,
        restriction,
        currentOptions,
    );

    const { form, resolvedJSONSchema } = useJSONSchemaForm(
        subschema as JSONSchema<T>,
        formFieldRegistry,
        getInitialValue(namespace, currentOptions) as unknown as Partial<T>,
    );

    const { trigger: handleQuoteEndorsement, isLoading: isProrateLoading } = useAsyncTrigger(
        async () => {
            if (endorsementContext.endorsementId === undefined) {
                const error: OperationFailed = OperationFailed({
                    message: 'Endorsement has not been precreated.',
                });
                setErrors([error]);
                return Failure(error);
            }

            if (
                !isValidPerClaimAndAggregateLimitSelection(
                    endorsementContext.endorsementType,
                    form.value as Record<string, unknown>,
                )
            ) {
                const error: OperationFailed = OperationFailed({
                    message:
                        'The selected combination of per claim and aggregate limit is not allowed.',
                });
                setErrors([error]);
                return Failure(error);
            }

            const [valid, errorMsg] = validateEndorsement(
                endorsementContext,
                form.value as Record<string, unknown>,
            ); // Cast form.value to Record<string, unknown>
            if (!valid) {
                const error: OperationFailed = OperationFailed({
                    message: errorMsg,
                });
                setErrors([error]);
                return Failure(error);
            }

            setEndorsementData(form.value);

            const prorateResult = await execute(GetEndorsementQuote, {
                endorsementId: endorsementContext.endorsementId,
                endorsementData: {
                    [namespace]: extendEndorsementDataWithCalculatedFields(
                        endorsementContext.endorsementType,
                        form.value,
                    ),
                },
            });

            if (isErr(prorateResult)) {
                setErrors(prorateResult.errors);
                setIsHigherLimitReviewRequired(false);
            } else {
                setIsHigherLimitReviewRequired(
                    isHigherLimitRequestRequired(
                        form.value as Record<string, any>,
                        namespace,
                        restriction,
                    ),
                );
                setEndorsementQuote(prorateResult.value);
                setErrors(undefined);
            }

            return prorateResult;
        },
    );

    const { trigger: handleIssueEndorsement, isLoading: isIssueEndorsementLoading } =
        useAsyncTrigger(async () => {
            if (endorsementContext.endorsementId === undefined) {
                const error: OperationFailed = OperationFailed({
                    message: 'Endorsement has not been precreated.',
                });
                setErrors([error]);
                return Failure(error);
            }

            if (!endorsementQuote) {
                const error = OperationFailed({
                    message: 'Please create an endorsement quote first.',
                });
                setErrors([error]);
                return Failure(error);
            }

            if (form.status === 'dirty') {
                const error = OperationFailed({
                    message:
                        'The premium is out of date with your input. Please requote or reset your input.',
                });
                setErrors([error]);
                return Failure(error);
            }

            const [valid, errorMsg] = validateEndorsement(
                endorsementContext,
                form.value as Record<string, any>,
            );
            if (!valid) {
                const error: OperationFailed = OperationFailed({
                    message: errorMsg,
                });
                setErrors([error]);
                return Failure(error);
            }

            const issueEndorsementResult = await execute(IssueEndorsement, {
                endorsementId: endorsementContext.endorsementId,
                endorsementData: {
                    [namespace]: extendEndorsementDataWithCalculatedFields(
                        endorsementContext.endorsementType,
                        endorsementData,
                    ),
                },
                rateId: endorsementQuote?.rateId,
                prorateId: endorsementQuote?.prorateId,
            });

            if (isErr(issueEndorsementResult)) {
                setErrors(issueEndorsementResult.errors);
            } else {
                setErrors(undefined);
                setEndorsementDocumentUrl(issueEndorsementResult.value);
                setDocumentPublicationType(DocumentPublicationType.Issue);
                documentModal.show();
            }

            return Success();
        });

    const { trigger: handleRequestHigherLimit, isLoading: isHigherLimitRequestLoading } =
        useAsyncTrigger(async () => {
            if (endorsementContext.endorsementId === undefined) {
                const error: OperationFailed = OperationFailed({
                    message: 'Something went wrong.',
                });
                setErrors([error]);
                return Failure(error);
            }

            if (form.status === 'dirty') {
                const error = OperationFailed({
                    message: 'Please requote or reset your input.',
                });
                setErrors([error]);
                return Failure(error);
            }

            const formData = form.value as Record<string, any>;
            const request: CreateOtherEndorsementRequest = {
                effectiveDate: new Date(formData.endorsementEffectiveDate as string),
                requestedChangeMessage: formatRequestHigherLimitMessage(
                    formData,
                    namespace,
                    currentOptions,
                ),
                policyId: endorsementContext.policyId,
            };

            const response = await execute(CreateOtherEndorsement, request);
            if (isErr(response)) {
                setErrors(response.errors);
            } else {
                setErrors(undefined);
                higherLimitSuccessModal.show();
            }

            return Success();
        });

    const { trigger: handlePreviewEndorsement, isLoading: isPreviewEndorsementLoading } =
        useAsyncTrigger(async () => {
            if (endorsementContext.endorsementId === undefined) {
                const error: OperationFailed = OperationFailed({
                    message: 'Endorsement has not been precreated.',
                });
                setErrors([error]);
                return Failure(error);
            }

            if (!endorsementQuote) {
                const error = OperationFailed({
                    message: 'Please create an endorsement quote first.',
                });
                setErrors([error]);
                return Failure(error);
            }

            if (form.status === 'dirty') {
                const error = OperationFailed({
                    message:
                        'The premium is out of date with your input. Please requote or reset your input.',
                });
                setErrors([error]);
                return Failure(error);
            }

            const [valid, errorMsg] = validateEndorsement(
                endorsementContext,
                form.value as Record<string, any>,
            );
            if (!valid) {
                const error: OperationFailed = OperationFailed({
                    message: errorMsg,
                });
                setErrors([error]);
                return Failure(error);
            }

            const previewEndorsementResult = await execute(PreviewEndorsement, {
                endorsementId: endorsementContext.endorsementId,
                endorsementData: {
                    [namespace]: extendEndorsementDataWithCalculatedFields(
                        endorsementContext.endorsementType,
                        endorsementData,
                    ),
                },
                prorateId: endorsementQuote?.prorateId,
            });

            if (isErr(previewEndorsementResult)) {
                setErrors(previewEndorsementResult.errors);
            } else {
                setErrors(undefined);
                setEndorsementDocumentUrl(previewEndorsementResult.value);
                setDocumentPublicationType(DocumentPublicationType.Preview);
                documentModal.show();
            }

            return Success();
        });

    const extendEndorsementDataWithCalculatedFields = (
        endorsementType: string,
        endorsementData: any,
    ): any => {
        if (endorsementType == 'extendedReportingPeriodElectionEndorsement') {
            const dateFrom = endorsementData?.extendReportingPeriodFrom as string;

            if (endorsementData?.erpNumberOfYears === 'unlimited') {
                return {
                    ...endorsementData,
                    extendReportingPeriodTo: 'Unlimited',
                };
            }

            const numberOfYears = erpNumberOfYearsToNumber(endorsementData?.erpNumberOfYears);
            const dateTo = addYears(parseISO(dateFrom), numberOfYears);

            return {
                ...endorsementData,
                extendReportingPeriodTo: format(dateTo, 'yyyy-MM-dd'),
            };
        } else if (
            endorsementType === 'pcoRunOffCoverageEndorsement' ||
            endorsementType === 'espRunOffEndorsement'
        ) {
            const dateFrom = endorsementData?.endorsementEffectiveDate as string;
            const numberOfYears = runOffNumberOfYearsToNumber(endorsementData?.erpNumberOfYears);
            const dateTo = addYears(parseISO(dateFrom), numberOfYears);

            return {
                ...endorsementData,
                policyRunOffExpirationDate: format(dateTo, 'yyyy-MM-dd'),
            };
        }

        return endorsementData;
    };

    const handleAgreementToConductSignatureChange = () => {
        setAgreementToConductSigned(!agreementToConductSigned);
    };

    const handleWarrantyAndFraudSignatureChange = () => {
        setWarrantyAndFraudSigned(!warrantyAndFraudSigned);
    };

    const [action, setAction] = useState<Action>();

    const handleActionChange = useCallback(
        ({ target: { value } }: { target: { value: string } }) => {
            setAction(value as Action);
        },
        [],
    );

    const handlePerformAction = useCallback(() => {
        setErrors(undefined);
        if (!warrantyAndFraudSigned || !agreementToConductSigned) {
            setErrors([
                OperationFailed({
                    message: 'Please sign the agreement to conduct and warranty and fraud.',
                }),
            ]);
            return;
        }
        switch (action) {
            case 'quote':
                setEndorsementQuote(undefined);
                form.submit();
                break;
            case 'issue':
                handleIssueEndorsement();
                break;
            case 'preview':
                handlePreviewEndorsement();
                break;
            case 'higherLimit':
                handleRequestHigherLimit();
                break;
            default:
                setErrors([OperationFailed({ message: 'Please select an action.' })]);
                break;
        }
    }, [
        setErrors,
        action,
        form,
        handleIssueEndorsement,
        handlePreviewEndorsement,
        handleRequestHigherLimit,
    ]);

    return (
        <StackLayout>
            {isProrateLoading ||
            isIssueEndorsementLoading ||
            isPreviewEndorsementLoading ||
            isHigherLimitRequestLoading ? (
                <Loader />
            ) : null}
            <DocumentModal
                modal={documentModal}
                policyId={endorsementContext.policyId}
                endorsementType={endorsementContext.endorsementType}
                documentPublicationType={documentPublicationType}
                fileUrl={endorsementDocumentUrl}
                onDismiss={onDismiss}
            />
            <HigherLimitSuccessModal modal={higherLimitSuccessModal} onDismiss={onDismiss} />
            <Text data-e2e="title-kind-of-endorsement" style="heading 3">
                {endorsementTitle}
            </Text>
            <br />
            <Text style="heading 5">
                Current Policy Period:{' '}
                <DateDisplay value={endorsementContext.policyEffectiveDate} /> -{' '}
                <DateDisplay value={endorsementContext.policyExpirationDate} />
            </Text>{' '}
            <Form
                id="endorsementForm"
                form={form}
                json={resolvedJSONSchema}
                formFieldRegistry={formFieldRegistry}
                onSubmit={handleQuoteEndorsement}
            />
            <EndorsementQuote
                quote={endorsementQuote}
                isHigherLimitReviewRequired={isHigherLimitReviewRequired}
            />
            {!isValidPerClaimAndAggregateLimitSelection(
                endorsementContext.endorsementType,
                form.value as Record<string, any>,
            ) && (
                <StatusMessage status="warning">
                    The selected combination of per claim and aggregate limit is not allowed.
                </StatusMessage>
            )}
            {isHigherLimitReviewRequired && (
                <StatusMessage status="warning">
                    Your quote will require an underwriting review if you choose to proceed. We
                    would contact you as soon as possible.
                </StatusMessage>
            )}
            {preBindEndorsements !== undefined && preBindEndorsements.length > 0 && (
                <React.Fragment>
                    <Text style="heading 4">Snapshot of Endorsements</Text>
                    <ul>
                        <li>
                            {endorsementTypeToDisplayName[namespace]} <b>NEW</b>
                        </li>
                        {filterPreBindEndorsements(preBindEndorsements, namespace).map(
                            (endorsement) => {
                                return <li key={endorsement}>{endorsement}</li>;
                            },
                        )}
                    </ul>
                </React.Fragment>
            )}
            <Text style="heading 4">Available actions</Text>
            <StackLayout gap="32">
                <RadioGroup
                    items={
                        isHigherLimitReviewRequired
                            ? [
                                  { title: 'Get Endorsement Quote', value: 'quote' },
                                  { title: 'Request Higher Limit', value: 'higherLimit' },
                              ]
                            : [
                                  { title: 'Get Endorsement Quote', value: 'quote' },
                                  { title: 'Preview Endorsement', value: 'preview' },
                                  { title: 'Issue Endorsement', value: 'issue' },
                              ]
                    }
                    value={action}
                    onChange={handleActionChange}
                />

                <ButtonBar split="-1">
                    <Button appearance="primary" onClick={handlePerformAction}>
                        Perform action
                    </Button>
                    <TextButton data-e2e="back-button" onClick={() => onBack()}>
                        Back
                    </TextButton>
                </ButtonBar>
                <EndorsementSignature
                    agreementToConductSignature={agreementToConductSigned}
                    onAgreementToConductSignatureChange={handleAgreementToConductSignatureChange}
                    userData={
                        {
                            company: endorsementContext.company,
                            fullName: endorsementContext.userFullName,
                            title: endorsementContext.userTitle,
                            usaState: endorsementContext.policyState,
                        } as EndorsementUserData
                    }
                    warrantyAndFraudSignature={warrantyAndFraudSigned}
                    onWarrantyAndFraudSignatureChange={handleWarrantyAndFraudSignatureChange}
                    agreementToConductSignatureMessages={[]}
                    warrantyAndFraudSignatureMessages={[]}
                    isFormInvalid={false}
                />
            </StackLayout>
            {errors ? (
                <StatusMessage status="error">{formatErrorMessage(errors[0])}</StatusMessage>
            ) : null}
        </StackLayout>
    );
}

function filterPropertieOptions(
    properties: any,
    namespace: string,
    restriction?: CoverageRestriction,
    currentOptions?: LimitAndRetention,
) {
    if (namespace === ESP_AMEND_DO_COVERAGE) {
        properties = filterRetentionOptions(
            properties,
            'doRetention',
            restriction?.minRetention,
            currentOptions?.retention,
        );
        properties = filterLimitOptions(properties, 'doLimit', currentOptions?.limit);
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'doAnalogUnderwritingFactor',
        );
    }

    if (namespace === ESP_ADD_DO_COVERAGE) {
        properties = filterRetentionOptions(properties, 'doRetention', restriction?.minRetention);
        properties = filterTypeOptions(properties, 'doLevel', restriction);
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'doAnalogUnderwritingFactor',
        );
    }

    if (namespace === ESP_AMEND_EPLI_COVERAGE) {
        properties = filterRetentionOptions(
            properties,
            'epliRetention',
            restriction?.minRetention,
            currentOptions?.retention,
        );
        properties = filterLimitOptions(properties, 'epliLimit', currentOptions?.limit);
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'epliAnalogUnderwritingFactor',
        );
    }

    if (namespace === ESP_ADD_EPLI_COVERAGE) {
        properties = filterRetentionOptions(properties, 'epliRetention', restriction?.minRetention);
        properties = filterTypeOptions(properties, 'epliLevel', restriction);
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'epliAnalogUnderwritingFactor',
        );
    }

    if (namespace === ESP_AMEND_TECH_EO_COVERAGE) {
        properties = filterRetentionOptions(
            properties,
            'techEoRetention',
            restriction?.minRetention,
            currentOptions?.retention,
        );
        properties = filterLimitOptions(properties, 'techEoLimit', currentOptions?.limit);
        properties = filterLimitOptions(properties, 'cyberLimit', currentOptions?.subLimit);
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'techEoAnalogUnderwritingFactor',
        );
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'cyberAnalogUnderwritingFactor',
        );
    }

    if (namespace === ESP_ADD_FIDUCIARY_COVERAGE) {
        properties = filterAnalogUnderwritingFactorOptions(
            properties,
            'fiduciaryAnalogUnderwritingFactor',
        );
    }

    return properties;
}

export function filterAnalogUnderwritingFactorOptions(properties: any, key: string) {
    if (!properties || !properties.hasOwnProperty(key)) {
        return properties;
    }

    const { [key]: _, ...filteredProperties } = properties;
    return filteredProperties;
}

function isHigherLimitRequestRequired(
    formData: Record<string, any>,
    namespace: string,
    restriction?: CoverageRestriction,
): boolean {
    if (!restriction) {
        return false;
    }

    const limit = getLimitFromFormData(formData, namespace);

    if (Money.isGreaterThan(limit, restriction.maxLimit)) {
        return true;
    }

    return false;
}

export function filterLimitOptions(properties: any, key: string, currentLimit?: Money) {
    if (
        !properties[key] ||
        !properties[key].presentation ||
        !properties[key].presentation.options ||
        !currentLimit
    ) {
        return properties;
    }

    return {
        ...properties,
        [key]: {
            ...properties[key],
            presentation: {
                ...properties[key].presentation,
                options: properties[key].presentation.options.filter(
                    ({ value }: { value: Money }) =>
                        Money.isGreaterThanOrEqual(value, currentLimit),
                ),
            },
        },
    };
}

export function filterRetentionOptions(
    properties: any,
    key: string,
    minRetention: Money = USD(0),
    currentRetention?: Money,
) {
    if (
        !properties[key] ||
        !properties[key].presentation ||
        !properties[key].presentation.options
    ) {
        return properties;
    }

    return {
        ...properties,
        [key]: {
            ...properties[key],
            presentation: {
                ...properties[key].presentation,
                options: properties[key].presentation.options.filter(
                    ({ value }: { value: Money }) =>
                        Money.isGreaterThanOrEqual(value, minRetention) &&
                        (currentRetention
                            ? Money.isLessThanOrEqual(value, currentRetention)
                            : true),
                ),
            },
        },
    };
}

function formatRequestHigherLimitMessage(
    formData: Record<string, any>,
    namespace: string,
    currentOptions?: LimitAndRetention,
): string {
    if (namespace.includes('Amend')) {
        return `Increase ${endorsementTypeToCoverageType[namespace]} limit from ${Money.toString(
            currentOptions?.limit || USD(0),
        )} to ${Money.toString(getLimitFromFormData(formData, namespace))}`;
    } else {
        return `Add ${endorsementTypeToCoverageType[namespace]} ${getLevelFromFormData(
            formData,
            namespace,
        )}\n Limit: ${Money.toString(
            getLimitFromFormData(formData, namespace),
        )}\n Retention: ${Money.toString(getRetentionFromFormData(formData, namespace))}`;
    }
}

const endorsementTypeToCoverageType: { [key: string]: string } = {
    espAmendDoCoverageLimitOfLiability: 'Directors & Officers',
    espAddDoCoverage: 'Directors & Officers',
    espAmendEpliLimitsRetentionEndorsement: 'Employment Practices Liability',
    espAddEpliCoverageEndorsement: 'Employment Practices Liability',
    espAmendTechEoCyberLiabilityCoverageLimitOfLiability: 'Errors and Omissions/Cyber Liability',
    espAddFiduciaryLiabilityCoverageEndorsement: 'Fiduciary',
};

const endorsementTypeToDisplayName: { [key: string]: string } = {
    espAmendDoCoverageLimitOfLiability: 'Amend Directors & Officers Liability',
    espAddDoCoverage: 'Add Directors & Officers Liability',
    espAmendEpliLimitsRetentionEndorsement: 'Amend Employment Practices Liability',
    espAddEpliCoverageEndorsement: 'Add Employment Practices Liability',
    espAmendTechEoCyberLiabilityCoverageLimitOfLiability:
        'Amend Errors and Omissions/Cyber Liability',
    espAddFiduciaryLiabilityCoverageEndorsement: 'Add Fiduciary Liability',
};

function filterPreBindEndorsements(
    preBindEndorsements: EndorsementList,
    namespace: string,
): string[] {
    const endorsements = preBindEndorsementToDisplayName[namespace];
    if (endorsements === undefined) return [];
    const result = endorsements.filter((endt1) =>
        preBindEndorsements.some((endt2) => {
            return endt1 === endt2.displayName;
        }),
    );
    return result;
}

const preBindEndorsementToDisplayName: { [key: string]: string[] } = {
    espAddDoCoverage: [
        'D&O Increased Limit with Prior Acts Exclusion',
        'D&O Prior Acts Exclusion',
        'D&O Prior and Pending Litigation Date Endorsement',
        'D&O Increased Limit Prior and Pending Litigation Date Endorsement',
        'D&O Percentage Shareholder Exclusion Endorsement',
        'D&O Breach Event Exclusion',
        'Family Claims Exclusion',
        'D&O Absolute Bodily Injury and Property Damage Exclusion',
        'D&O Advertising & Labeling Exclusion',
        'Creditors and Debt Holders Exclusion',
        'D&O Financial Services Exclusion',
        'Gambling Regulation Exclusion',
        'D&O Medical Professional Services Exclusion',
        'Nuclear Energy Exclusion',
        'Telephone Consumer Protection Act Exclusion',
        'Financial Impairment Exclusion',
        'D&O Franchisee Exclusion',
        'D&O Insurance Services Exclusion',
    ],
    espAddEpliCoverageEndorsement: [
        'High Wage Earner Retention Endorsement',
        'EPL Prior Acts Exclusion',
        'EPL Increased Limit with Prior Acts Exclusion',
        'EPL Prior and Pending Litigation Date Endorsement',
        'EPL Increased Limit Prior and Pending Litigation Date Endorsement',
        'State Specific Retention',
        'EPL Amended Retention Layoff Claim Endorsement',
        'EPL Failure to Make Partner Exclusion Endorsement',
        'EPL Independent Contractor Exclusion Endorsement',
        'EPL Third Party Discrimination Only Coverage Endorsement',
    ],
};

function getLimitFromFormData(formData: Record<string, any>, namespace: string): Money {
    if (namespace === ESP_AMEND_DO_COVERAGE || namespace === ESP_ADD_DO_COVERAGE) {
        return formData.doLimit as Money;
    }
    if (namespace === ESP_AMEND_EPLI_COVERAGE || namespace === ESP_ADD_EPLI_COVERAGE) {
        return formData.epliLimit as Money;
    }
    if (namespace === ESP_AMEND_TECH_EO_COVERAGE) {
        return formData.techEoLimit as Money;
    }

    return USD(0);
}

function getRetentionFromFormData(formData: Record<string, any>, namespace: string): Money {
    if (namespace === ESP_ADD_DO_COVERAGE) {
        return formData.doRetention as Money;
    }
    if (namespace === ESP_ADD_EPLI_COVERAGE) {
        return formData.epliRetention as Money;
    }

    return USD(0);
}

function getLevelFromFormData(formData: Record<string, any>, namespace: string): string {
    if (namespace === ESP_ADD_DO_COVERAGE) {
        return formData.doLevel == 'enhanced' ? 'Plus' : 'Standard';
    }
    if (namespace === ESP_ADD_EPLI_COVERAGE) {
        return formData.epliLevel == 'enhanced' ? 'Plus' : 'Standard';
    }
    return '';
}

function filterTypeOptions(properties: any, key: string, restriction?: CoverageRestriction) {
    if (
        !properties[key] ||
        !properties[key].presentation ||
        !properties[key].presentation.options ||
        !restriction
    ) {
        return properties;
    }

    if (restriction.allowPlus) {
        return properties;
    }

    return {
        ...properties,
        [key]: {
            ...properties[key],
            presentation: {
                ...properties[key].presentation,
                options: properties[key].presentation.options.filter(
                    ({ value }: { value: string }) => value != 'enhanced',
                ),
            },
        },
    };
}

function getInitialValue(namespace: string, currentOptions?: LimitAndRetention) {
    if (namespace === ESP_AMEND_DO_COVERAGE) {
        return {
            doLimit: currentOptions?.limit,
            doRetention: currentOptions?.retention,
        };
    }

    if (namespace === ESP_AMEND_EPLI_COVERAGE) {
        return {
            epliLimit: currentOptions?.limit,
            epliRetention: currentOptions?.retention,
        };
    }

    if (namespace === ESP_AMEND_TECH_EO_COVERAGE) {
        return {
            techEoLimit: currentOptions?.limit,
            cyberLimit: currentOptions?.subLimit,
            techEoRetention: currentOptions?.retention,
        };
    }

    return {};
}
