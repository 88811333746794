import { isDateBefore } from '@embroker/service-app-engine';
import { defineEntityValidator, Entity, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { UnionToIntersection } from 'chart.js/types/utils';
import { isSameDay, startOfToday } from 'date-fns';
import { SKU } from '../../analytics/types/SKU';
import { PremiumRange, Quote } from '../../quote/entities/Quote';
import { LimitType } from '../../quote/types/Limits';
import {
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleQuestionnaireDataType,
    BundleQuoteCoverage,
    BundleQuoteCoverageType,
} from '../types/BundleQuoteCoverage';
import { BundleQuoteOptions } from '../types/BundleQuoteOptions';
import { BundleQuoteOrganization } from '../types/BundleQuoteOrganization';
import { ESPRate } from '@app/quote/esp/types/ESPRate';

export interface BundleQuotePurchased extends DomainEvent {
    readonly origin: 'BundleQuote';
    readonly name: 'Purchased';
    readonly isOFACRejected: boolean;
    readonly totalPremium: Nullable<Money>;
    readonly applicationId: UUID;
    readonly sku?: SKU;
    readonly isRenewal: boolean;
}

export interface BundleQuote extends Entity {
    readonly applicationId: UUID;
    readonly effectiveDate: Date;
    readonly organizationInfo: BundleQuoteOrganization;
    readonly coverageList: Immutable<BundleQuoteCoverageType[]>;
    readonly isRenewal?: boolean;

    getTotalBundlePremium(): Money;

    getTotalBindablePremium(): Money;

    getQuoteTotalValue(): Money | PremiumRange | undefined;

    getQuoteValidityInDays(): number | undefined;

    getSelectedCoverages(): Immutable<BundleQuoteCoverageType[]>;

    getState(): State;

    getEnabledCoverages(): Immutable<BundleQuoteCoverageType[]>;

    isAnyCoverageSelected(bundleCoverageType: BundleCoverageType[]): boolean;

    isCoverageSelected(bundleCoverageType: BundleCoverageType): boolean;

    getCoverageQuoteOptions(bundleCoverageType: BundleCoverageType): BundleQuoteOptions | undefined;

    getCoverageQuestionnaire(
        bundleCoverageType: BundleCoverageType,
    ): UnionToIntersection<BundleQuestionnaireDataType> | undefined;

    getCoverageHigherLimit(
        bundleCoverageType: BundleCoverageType,
    ): Record<string, Record<LimitType, number>> | undefined;

    isAnyCoverageReferred(): boolean;

    isAnyCoverageReferredAwaitingReview(): boolean;

    isAnyCoverageDisabled(): boolean;

    isCoverageIndication(bundleCoverageType: BundleCoverageType): boolean;

    isAnyCoverageIndication(): boolean;

    isAnyCoverageAppExpired(): boolean;

    isAnyEligibleCoverageDeselected(): boolean;

    getEligibleCoverages(): Immutable<BundleQuoteCoverageType[]>;

    getIneligibleCoverages(): Immutable<BundleQuoteCoverageType[]>;

    areAllCoveragesDisabledOrDeselected(): boolean;

    areAllCoveragesDisabled(): boolean;

    areAllCoveragesNotEligible(): boolean;

    areAllCoveragesReferred(): boolean;

    isCoverageEnabled(bundleCoverageType: BundleCoverageType): boolean;

    isCoverageReferred(bundleCoverageType: BundleCoverageType): boolean;

    isCoverageReferredAwaitingReview(bundleCoverageType: BundleCoverageType): boolean;

    isBundleQuoteBindable(): boolean;

    getCoverageByCoverageType(
        bundleCoverageType: BundleCoverageType,
    ): Nullable<Immutable<BundleQuoteCoverageType>>;

    getCoverageListSummary(): CoverageListSummary;

    getPreBindEndorsements(): Record<BundleCoverageType, string[]>;

    getESPQuoteRates(): ESPRate | undefined;
}

export interface CoverageListSummary {
    bindableQuoteList: Quote[];
    currentLimitExceedList: Quote[];
    indicationList: Quote[];
    referredQuoteList: Quote[];
    deselectedQuoteList: Quote[];
}

export const BundleQuote = entity<BundleQuote>({
    validator: defineEntityValidator<BundleQuote>({
        applicationId: UUID.schema.required(),
        effectiveDate: Joi.date().custom((bundleEffectiveDate, helpers) => {
            for (const underlyingAppCoverage of helpers.state.ancestors[0].coverageList) {
                if (
                    underlyingAppCoverage.quote &&
                    !isSameDay(
                        underlyingAppCoverage.quote.options.effectiveDate,
                        bundleEffectiveDate,
                    )
                ) {
                    return helpers.error(
                        'Bundle Effective Date must align with all underlying applications effective dates',
                    );
                }
            }
            return bundleEffectiveDate;
        }),
        organizationInfo: BundleQuoteOrganization.schema,
        coverageList: Joi.array().min(1).items(BundleQuoteCoverage.schema),
        isRenewal: Joi.boolean().optional(),
    }),
    getTotalBundlePremium(): Money {
        const bundleCoveragePremiumList = this.getSelectedCoverages().map(
            (coverage) => coverage.quote?.totalPremium ?? USD(0),
        );
        return sum(bundleCoveragePremiumList);
    },
    getTotalBindablePremium(): Money {
        const bundleCoveragePremiumList = this.getSelectedCoverages()
            .filter(
                (coverage) =>
                    (coverage.status === BundleCoverageAppStatusEnum.QuotesReady ||
                        coverage.status === BundleCoverageAppStatusEnum.Purchased) &&
                    !coverage.quote?.isIndication,
            )
            .map((coverage) => coverage.quote?.totalPayable ?? USD(0));
        return sum(bundleCoveragePremiumList);
    },
    getQuoteTotalValue(): Money | PremiumRange | undefined {
        const quotableCoverages = this.getSelectedCoverages();
        const referredStatuses: string[] = [
            BundleCoverageAppStatusEnum.Referred,
            BundleCoverageAppStatusEnum.ReferredAwaitingReview,
        ];
        const premiumRange = quotableCoverages?.reduce<PremiumRange | undefined>((agg, curr) => {
            if (curr.quote === undefined) {
                return agg;
            }
            if (curr.quote.isIndication || referredStatuses.includes(curr.status)) {
                if (curr.quote.premiumRange) {
                    return {
                        min: sum([curr.quote.premiumRange.min, ...(agg ? [agg.min] : [])]),
                        max: sum([curr.quote.premiumRange.max, ...(agg ? [agg.max] : [])]),
                    };
                }
            } else {
                if (curr.status === BundleCoverageAppStatusEnum.QuotesReady) {
                    return {
                        min: sum([curr.quote.totalPayable, ...(agg ? [agg.min] : [])]),
                        max: sum([curr.quote.totalPayable, ...(agg ? [agg.max] : [])]),
                    };
                }
            }
            return agg;
        }, undefined);

        return premiumRange?.min.amount === premiumRange?.max.amount
            ? premiumRange?.min
            : premiumRange;
    },
    getQuoteValidityInDays() {
        if (this.areAllCoveragesDisabled()) {
            return undefined;
        }

        return Math.min(
            ...this.getSelectedCoverages().map(({ quote }) => {
                return quote?.daysToExpire ?? 0;
            }),
        );
    },
    getSelectedCoverages(): Immutable<BundleQuoteCoverageType[]> {
        return this.props.coverageList.filter(
            (coverage) => coverage.quote !== undefined && !coverage.quote?.options.isDeselected,
        );
    },
    getEligibleCoverages(): Immutable<BundleQuoteCoverageType[]> {
        const eligibleStatusTypes = [BundleCoverageAppStatusEnum.QuotesReady];
        return this.props.coverageList.filter((coverage) =>
            eligibleStatusTypes.includes(coverage.status),
        );
    },
    getIneligibleCoverages(): Immutable<BundleQuoteCoverageType[]> {
        const ineligibleStatusTypes = [
            BundleCoverageAppStatusEnum.NotEligible,
            BundleCoverageAppStatusEnum.ClearanceFailed,
        ];
        return this.props.coverageList.filter((coverage) =>
            ineligibleStatusTypes.includes(coverage.status),
        );
    },
    getEnabledCoverages(): Immutable<BundleQuoteCoverageType[]> {
        return this.props.coverageList.filter(
            (coverage) =>
                coverage.quote !== undefined &&
                coverage.status !== BundleCoverageAppStatusEnum.NotEligible &&
                coverage.status !== BundleCoverageAppStatusEnum.UserDeclined,
        );
    },
    isAnyCoverageSelected(bundleCoverageTypes: BundleCoverageType[]) {
        for (const bundleCoverageType of bundleCoverageTypes) {
            if (this.isCoverageSelected(bundleCoverageType)) {
                return true;
            }
        }
        return false;
    },
    isCoverageSelected(bundleCoverageType: BundleCoverageType) {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );
        return coverage?.quote !== undefined && !coverage.quote.options.isDeselected;
    },
    getCoverageQuoteOptions(
        bundleCoverageType: BundleCoverageType,
    ): BundleQuoteOptions | undefined {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );

        return coverage?.quote?.options;
    },
    getCoverageQuestionnaire(
        bundleCoverageType: BundleCoverageType,
    ): UnionToIntersection<BundleQuestionnaireDataType> | undefined {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );

        return coverage?.questionnaireData as UnionToIntersection<BundleQuestionnaireDataType>;
    },
    getState(): State {
        const selectedCoverages = this.getSelectedCoverages();
        const coverageWithQDLocation = selectedCoverages.find((coverage) => {
            const qdWithLarges = coverage.questionnaireData as
                | { location_with_largest_number?: { state: State } }
                | undefined;
            return qdWithLarges?.location_with_largest_number?.state !== undefined;
        });

        if (coverageWithQDLocation) {
            return (
                coverageWithQDLocation.questionnaireData as {
                    location_with_largest_number?: { state: State };
                }
            ).location_with_largest_number?.state as State;
        }

        const coverageWithQDState = selectedCoverages.find((coverage) => {
            return coverage.questionnaireData?.state !== undefined;
        });

        return coverageWithQDState?.questionnaireData?.state as State;
    },
    getCoverageHigherLimit(
        bundleCoverageType: BundleCoverageType,
    ): Record<string, Record<LimitType, number>> | undefined {
        const coverage = this.props.coverageList.find(
            (coverage) =>
                coverage.type === bundleCoverageType && !coverage.quote?.options.isDeselected,
        );

        return coverage?.quote?.higherLimit;
    },
    isAnyCoverageReferred() {
        return this.getSelectedCoverages().some(
            (coverage) => coverage.status === BundleCoverageAppStatusEnum.Referred,
        );
    },
    isAnyCoverageDisabled() {
        return this.props.coverageList.some(
            (coverage) =>
                coverage.status === BundleCoverageAppStatusEnum.NotEligible ||
                coverage.status === BundleCoverageAppStatusEnum.UserDeclined,
        );
    },
    isAnyCoverageReferredAwaitingReview() {
        return this.props.coverageList.some(
            (coverage) => coverage.status === BundleCoverageAppStatusEnum.ReferredAwaitingReview,
        );
    },
    isAnyCoverageIndication() {
        return this.getSelectedCoverages().some(
            (coverage) => coverage.quote?.isIndication ?? false,
        );
    },
    isCoverageIndication(bundleCoverageType: BundleCoverageType) {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );

        return coverage?.quote?.isIndication ?? false;
    },
    isAnyCoverageAppExpired() {
        return this.props.coverageList.some(
            (coverage) =>
                isDateBefore(coverage.appValidUntil, startOfToday()) &&
                this.isCoverageEnabled(coverage.type),
        );
    },
    isAnyEligibleCoverageDeselected() {
        return this.getEnabledCoverages().some((coverage) => coverage.quote?.options.isDeselected);
    },
    areAllCoveragesDisabledOrDeselected() {
        return this.props.coverageList.every(
            (coverage) =>
                coverage.status === BundleCoverageAppStatusEnum.NotEligible ||
                coverage.status === BundleCoverageAppStatusEnum.UserDeclined ||
                coverage.quote?.options.isDeselected,
        );
    },
    areAllCoveragesDisabled() {
        return this.props.coverageList.every(
            (coverage) =>
                coverage.status === BundleCoverageAppStatusEnum.NotEligible ||
                coverage.status === BundleCoverageAppStatusEnum.UserDeclined,
        );
    },
    areAllCoveragesNotEligible() {
        return this.props.coverageList.every(
            (coverage) => coverage.status === BundleCoverageAppStatusEnum.NotEligible,
        );
    },
    areAllCoveragesReferred() {
        return this.getSelectedCoverages().every(
            (coverage) =>
                coverage.status === BundleCoverageAppStatusEnum.Referred ||
                coverage.status === BundleCoverageAppStatusEnum.ReferredAwaitingReview ||
                coverage.quote?.isIndication,
        );
    },
    isCoverageEnabled(bundleCoverageType: BundleCoverageType) {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );
        if (!coverage) {
            return false;
        }
        return (
            coverage.status !== BundleCoverageAppStatusEnum.NotEligible &&
            coverage.status !== BundleCoverageAppStatusEnum.UserDeclined
        );
    },
    isCoverageReferred(bundleCoverageType: BundleCoverageType) {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );

        if (!coverage) {
            return false;
        }
        return coverage.status === BundleCoverageAppStatusEnum.Referred;
    },
    isCoverageReferredAwaitingReview(bundleCoverageType: BundleCoverageType) {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );

        if (!coverage) {
            return false;
        }
        return coverage.status === BundleCoverageAppStatusEnum.ReferredAwaitingReview;
    },
    isBundleQuoteBindable() {
        return (
            this.getCoverageListSummary().bindableQuoteList.length > 0 &&
            !this.areAllCoveragesDisabled()
        );
    },
    getCoverageByCoverageType(
        bundleCoverageType: BundleCoverageType,
    ): Nullable<Immutable<BundleQuoteCoverageType>> {
        const coverage = this.props.coverageList.find(
            (coverage) => coverage.type === bundleCoverageType,
        );

        return coverage ?? null;
    },
    getCoverageListSummary(): CoverageListSummary {
        return this.coverageList.reduce<CoverageListSummary>(
            (acc, coverage) => {
                if (!coverage.quote) {
                    return acc;
                }
                if (coverage.quote.options.isDeselected) {
                    return {
                        ...acc,
                        deselectedQuoteList: [...acc.deselectedQuoteList, coverage.quote],
                    };
                }
                if (coverage.quote.isCurrentLimitExceed) {
                    return {
                        ...acc,
                        currentLimitExceedList: [...acc.currentLimitExceedList, coverage.quote],
                    };
                }
                if (coverage.quote.isIndication) {
                    return {
                        ...acc,
                        indicationList: [...acc.indicationList, coverage.quote],
                    };
                }
                if (coverage.status === BundleCoverageAppStatusEnum.Referred) {
                    return {
                        ...acc,
                        referredQuoteList: [...acc.referredQuoteList, coverage.quote],
                    };
                }
                if (coverage.status === BundleCoverageAppStatusEnum.QuotesReady) {
                    return {
                        ...acc,
                        bindableQuoteList: [...acc.bindableQuoteList, coverage.quote],
                    };
                }
                return acc;
            },
            {
                bindableQuoteList: [],
                currentLimitExceedList: [],
                indicationList: [],
                deselectedQuoteList: [],
                referredQuoteList: [],
            },
        );
    },
    getPreBindEndorsements(): Record<BundleCoverageType, string[]> {
        const selectedCoverages = this.getSelectedCoverages();
        const preBindEndorsements = {} as Record<BundleCoverageType, string[]>;
        selectedCoverages.forEach((coverage) => {
            if (
                coverage?.quote?.preBindEndorsements &&
                Object.values(coverage.quote.preBindEndorsements).length > 0
            ) {
                preBindEndorsements[coverage.type] = Object.values(
                    coverage.quote.preBindEndorsements,
                );
            }
        });
        return preBindEndorsements;
    },
    getESPQuoteRates(): ESPRate | undefined {
        const espCoverage = this.coverageList.find((coverage) => coverage.type === 'ESPCoverage');
        const referredStatuses: string[] = [
            BundleCoverageAppStatusEnum.Referred,
            BundleCoverageAppStatusEnum.ReferredAwaitingReview,
        ];
        if (espCoverage?.quote?.details && !referredStatuses.includes(espCoverage.status)) {
            return espCoverage.quote.details as ESPRate;
        }
    },
});

export function sum(values: Money[]): Money {
    const sumResult = Money.sum(values);
    if (isOK(sumResult)) {
        return sumResult.value;
    }

    return USD(0);
}
