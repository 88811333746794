import React from 'react';
import { IconPhosphor, IconPhosphorName, IconPhosphorProps } from '@embroker/ui-toolkit/v2';
import {
    AppTypeCode,
    AppTypeCodeListManualAccountantsPL,
    AppTypeCodeListManualConstructionPL,
    AppTypeCodeListManualTechEO,
    AppTypeCodeListCNABOP,
    AppTypeCodeListESP,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListManualAuto,
    AppTypeCodeListManualDefaultPL,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualGL,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualProperty,
    AppTypeCodeListManualTravel,
    AppTypeCodeListManualUmbrella,
    AppTypeCodeListTechEO,
    AppTypeCodeListManualHomeownersAssociation,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListMPL,
    AppTypeCodeListMPLBundle,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListWCChubb,
    AppTypeCodeListHartfordBOP,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListManualConstructionGL,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListManualCyber,
    AppTypeCodeListManualCrime,
    AppTypeCodeListManualLawyersProfessionalLiability,
    AppTypeCodeListGAWorkersCompensation,
    AppTypeCodeListLawBundle,
    AppTypeCodeListLawBundleCyber,
    AppTypeCodeListGenericBundle,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListPCoML,
    AppTypeCodeListManualCannabis,
    AppTypeCodeListManualVentureCapitalAssetProtection,
    ShopppingCoverageCode,
    ShoppingCoverageCodeListDirectorsAndOfficers,
    ShoppingCoverageCodeListEmploymentPractices,
    ShoppingCoverageCodeListFiduciary,
    ShoppingCoverageCodeListProfessionalLiability,
    ShoppingCoverageCodeListAuto,
    ShoppingCoverageCodeListCrime,
    ShoppingCoverageCodeListCyber,
    ShoppingCoverageCodeListWorkersCompensation,
    ShoppingCoverageCodeListGeneralLiability,
    ShoppingCoverageCodeListProperty,
    ShoppingCoverageCodeListProductLiability,
    ShoppingCoverageCodeListUmbrella,
    ShoppingCoverageCodeListTravel,
} from '@app/shopping/types/enums';
import { Vertical } from '@app/userOrg/types/enums';
import {
    LineOfBusinessCodeListItem,
    LineOfBusinessSubtypeCodeListItem,
} from '@embroker/shotwell-api/enums';

const lineOfBusinessCodeIconMap = new Map<LineOfBusinessCodeListItem, IconPhosphorName>([
    ['LineOfBusinessCodeListAuto', 'car'],
    ['LineOfBusinessCodeListAviation', 'airplane-takeoff'],
    ['LineOfBusinessCodeListBOP', 'user-check'],
    ['LineOfBusinessCodeListCrime', 'siren'],
    ['LineOfBusinessCodeListCyber', 'detective'],
    ['LineOfBusinessCodeListDental', 'crown'],
    ['LineOfBusinessCodeListDirectorsAndOfficers', 'crown'],
    ['LineOfBusinessCodeListEarthquake', 'user-check'],
    ['LineOfBusinessCodeListEmploymentPractices', 'briefcase'],
    ['LineOfBusinessCodeListExcessTechEOCyber', 'dots-three-circle-vertical'],
    ['LineOfBusinessCodeListExcessInterestLife', 'handshake'],
    ['LineOfBusinessCodeListFiduciary', 'handshake'],
    ['LineOfBusinessCodeListFixedAnnuity', 'handshake'],
    ['LineOfBusinessCodeListGarageAndDealers', 'car'],
    ['LineOfBusinessCodeListGeneralLiability', 'shield-check'],
    ['LineOfBusinessCodeListIndexedAnnuity', 'handshake'],
    ['LineOfBusinessCodeListLiability', 'shield-check'],
    ['LineOfBusinessCodeListOwnersAndContractors', 'house'],
    ['LineOfBusinessCodeListPackage', 'package'],
    ['LineOfBusinessCodeListProductLiability', 'megaphone'],
    ['LineOfBusinessCodeListProfessionalLiability', 'pen'],
    ['LineOfBusinessCodeListProperty', 'building'],
    ['LineOfBusinessCodeListTermLife', 'handshake'],
    ['LineOfBusinessCodeListTravel', 'airplane-takeoff'],
    ['LineOfBusinessCodeListTruckers', 'car'],
    ['LineOfBusinessCodeListUmbrella', 'umbrella'],
    ['LineOfBusinessCodeListUniversalLife', 'handshake'],
    ['LineOfBusinessCodeListVariableAnnuity', 'handshake'],
    ['LineOfBusinessCodeListVariableUniversalLife', 'handshake'],
    ['LineOfBusinessCodeListVariableWholeLife', 'handshake'],
    ['LineOfBusinessCodeListVentureCapitalAssetProtection', 'chart-line-up'],
    ['LineOfBusinessCodeListWholeLife', 'handshake'],
    ['LineOfBusinessCodeListWorkersCompensation', 'hard-hat'],
    ['LineOfBusinessCodeListAccountantsProfessionalLiability', 'calculator'],
    ['LineOfBusinessCodeListRealEstateAgentsProfessionalLiability', 'city'],
    ['LineOfBusinessCodeListTaxPreparersAndBookkeepersProfessionalLiability', 'book-open-text'],
    ['LineOfBusinessCodeListHomeInspectorsProfessionalLiability', 'eyes'],
    [
        'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability',
        'head-circuit',
    ],
    ['LineOfBusinessCodeListESP', 'rocket'],
    ['LineOfBusinessCodeListPCoML', 'briefcase'],
    ['LineOfBusinessCodeListAccountantsProfessionalLiability', 'calculator'],
    [
        'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability',
        'head-circuit',
    ],
    ['LineOfBusinessCodeListTaxPreparersAndBookkeepersProfessionalLiability', 'book-open-text'],
    ['LineOfBusinessCodeListRealEstateAgentsProfessionalLiability', 'city'],
    ['LineOfBusinessCodeListHomeInspectorsProfessionalLiability', 'eyes'],
]);

const lineOfBusinessSubtypeCodeIconMap = new Map<
    LineOfBusinessSubtypeCodeListItem,
    IconPhosphorName
>([
    ['LineOfBusinessSubtypeCodeListLawyersProfessional', 'scales'],
    ['LineOfBusinessSubtypeCodeListManagementLiability', 'rocket'],
    ['LineOfBusinessSubtypeCodeListCrime', 'siren'],
    ['LineOfBusinessSubtypeCodeListCrimeDigital', 'siren'],
    ['LineOfBusinessSubtypeCodeListHiredNonOwnedAutoOnly', 'car'],
    ['LineOfBusinessSubtypeCodeListRecreationalVehicles', 'car'],
    ['LineOfBusinessSubtypeCodeListGarageAndDealers', 'car'],
    ['LineOfBusinessSubtypeCodeListMotorTruckCargo', 'car'],
    ['LineOfBusinessSubtypeCodeListPersonalAuto', 'car'],
    ['LineOfBusinessSubtypeCodeListTruckers', 'car'],
    ['LineOfBusinessSubtypeCodeListAviation', 'airplane-takeoff'],
    ['LineOfBusinessSubtypeCodeListProductsLiability', 'megaphone'],
    ['LineOfBusinessSubtypeCodeListCyberEO', 'detective'],
    ['LineOfBusinessSubtypeCodeListTechProfessionalCyberPackage', 'laptop'],
    ['LineOfBusinessSubtypeCodeListCyberPrivacyLiability', 'detective'],
    ['LineOfBusinessSubtypeCodeListExcessCyberLiability', 'detective'],
    ['LineOfBusinessSubtypeCodeListTechEOCyberDigital', 'laptop'],
    ['LineOfBusinessSubtypeCodeListEmploymentPracticesLiability', 'suitcase'],
    ['LineOfBusinessSubtypeCodeListEPLIDigital', 'suitcase'],
    ['LineOfBusinessSubtypeCodeListFiduciary', 'handshake'],
    ['LineOfBusinessSubtypeCodeListFiduciaryDigital', 'handshake'],
    ['LineOfBusinessSubtypeCodeListGeneralProfessionalLiability', 'person-simple-hike'],
    ['LineOfBusinessSubtypeCodeListHomeowners', 'house'],
    ['LineOfBusinessSubtypeCodeListErrorsAndOmissions', 'person-simple-hike'],
    ['LineOfBusinessSubtypeCodeListDODigital', 'crown'],
    ['LineOfBusinessSubtypeCodeListWorkersCompensation', 'hard-hat'],
]);

const appTypeCodeIconMap = new Map<AppTypeCode, IconPhosphorName>([
    [AppTypeCodeListManualAuto, 'car'],
    [AppTypeCodeListEmbrokerCrime, 'siren'],
    [AppTypeCodeListManualCrime, 'siren'],
    [AppTypeCodeListManualCyber, 'detective'],
    [AppTypeCodeListEmbrokerCyber, 'detective'],
    [AppTypeCodeListManualCannabis, 'shield-check'],
    [AppTypeCodeListManualConstructionGL, 'shield-check'],
    [AppTypeCodeListManualGL, 'shield-check'],
    [AppTypeCodeListManualLawyersProfessionalLiability, 'scales'],
    [AppTypeCodeListEverestLawyersProfessionalLiability, 'scales'],
    [AppTypeCodeListManualProperty, 'building'],
    [AppTypeCodeListManualProductLiability, 'megaphone'],
    [AppTypeCodeListManualUmbrella, 'umbrella'],
    [AppTypeCodeListManualWorkersCompensation, 'hard-hat'],
    [AppTypeCodeListGAWorkersCompensation, 'hard-hat'],
    [AppTypeCodeListManualTravel, 'airplane-takeoff'],
    [AppTypeCodeListManualHomeownersAssociation, 'house'],
    [AppTypeCodeListCNABOP, 'user-check'],
    [AppTypeCodeListESP, 'rocket'],
    [AppTypeCodeListTechEO, 'laptop'],
    [AppTypeCodeListManualAccountantsPL, 'person-simple-hike'],
    [AppTypeCodeListManualTechEO, 'laptop'],
    [AppTypeCodeListManualDefaultPL, 'person-simple-hike'],
    [AppTypeCodeListManualConstructionPL, 'person-simple-hike'],
    [AppTypeCodeListManualDirectorsAndOfficers, 'crown'],
    [AppTypeCodeListManualEmploymentPractices, 'briefcase'],
    [AppTypeCodeListManualFiduciary, 'handshake'],
    [AppTypeCodeListLawBundle, 'package'],
    [AppTypeCodeListLawBundleCyber, 'detective'],
    [AppTypeCodeListEmbrokerExcess, 'dots-three-circle-vertical'],
    [AppTypeCodeListCyberCowbell, 'detective'],
    [AppTypeCodeListBOPChubb, 'user-check'],
    [AppTypeCodeListMPL, 'tree-structure'],
    [AppTypeCodeListMPLBundle, 'tree-structure'],
    [AppTypeCodeListWCChubb, 'hard-hat'],
    [AppTypeCodeListHartfordBOP, 'shield-check'],
    [AppTypeCodeListGenericBundle, 'scroll'],
    [AppTypeCodeListPCoML, 'briefcase'],
    [AppTypeCodeListManualVentureCapitalAssetProtection, 'chart-line-up'],
]);

const coverageCodeIconMap = new Map<ShopppingCoverageCode, IconPhosphorName>([
    [ShoppingCoverageCodeListDirectorsAndOfficers, 'crown'],
    [ShoppingCoverageCodeListEmploymentPractices, 'briefcase'],
    [ShoppingCoverageCodeListFiduciary, 'handshake'],
    [ShoppingCoverageCodeListProfessionalLiability, 'person-simple-hike'],
    [ShoppingCoverageCodeListAuto, 'car'],
    [ShoppingCoverageCodeListCrime, 'siren'],
    [ShoppingCoverageCodeListCyber, 'detective'],
    [ShoppingCoverageCodeListWorkersCompensation, 'hard-hat'],
    [ShoppingCoverageCodeListGeneralLiability, 'shield-check'],
    [ShoppingCoverageCodeListProperty, 'house'],
    [ShoppingCoverageCodeListProductLiability, 'megaphone'],
    [ShoppingCoverageCodeListUmbrella, 'umbrella'],
    [ShoppingCoverageCodeListTravel, 'airplane-takeoff'],
]);

const verticalIconMap = new Map<Vertical, IconPhosphorName>([
    ['LawFirm', 'package'],
    ['Accounting', 'calculator'],
    ['TaxPreparation', 'book-open-text'],
    ['RealEstateAgent', 'city'],
    ['HomeInspectorAndBuildingInspectionServices', 'eyes'],
    ['NonTechnologyConsultants', 'head-circuit'],
    ['TechCompanies', 'cpu'],
]);

interface BaseCoverageIconProps {
    size?: IconPhosphorProps['size'];
    disabled?: boolean;
}

interface AppTypeCodeCoverageIconProps extends BaseCoverageIconProps {
    appTypeCode: AppTypeCode;
    coverageCode?: undefined;
    lineOfBusinessCode?: undefined;
    lineOfBusinessSubType?: undefined;
    vertical?: undefined;
}

interface CoverageCodeCoverageIconProps extends BaseCoverageIconProps {
    appTypeCode?: undefined;
    coverageCode: ShopppingCoverageCode;
    lineOfBusinessCode?: undefined;
    lineOfBusinessSubType?: undefined;
    vertical?: undefined;
}

interface LineOfBusinessCoverageIconProps extends BaseCoverageIconProps {
    appTypeCode?: undefined;
    coverageCode?: undefined;
    lineOfBusinessCode: LineOfBusinessCodeListItem;
    lineOfBusinessSubType: LineOfBusinessSubtypeCodeListItem;
    vertical?: undefined;
}

interface VerticalCoverageIconProps extends BaseCoverageIconProps {
    appTypeCode?: AppTypeCode;
    coverageCode?: undefined;
    lineOfBusinessCode?: undefined;
    lineOfBusinessSubType?: undefined;
    vertical: Vertical | undefined;
}

export function CoverageIcon({
    appTypeCode,
    coverageCode,
    lineOfBusinessCode,
    lineOfBusinessSubType,
    vertical,
    size = 'small',
    disabled = false,
}:
    | AppTypeCodeCoverageIconProps
    | CoverageCodeCoverageIconProps
    | LineOfBusinessCoverageIconProps
    | VerticalCoverageIconProps) {
    let iconName: IconPhosphorName = 'scroll';

    if (vertical) iconName = verticalIconMap.get(vertical) ?? iconName;
    else if (appTypeCode) iconName = appTypeCodeIconMap.get(appTypeCode) ?? iconName;
    else if (coverageCode) iconName = coverageCodeIconMap.get(coverageCode) ?? iconName;
    else if (lineOfBusinessCode) {
        iconName = lineOfBusinessCodeIconMap.get(lineOfBusinessCode) ?? iconName;

        if (
            lineOfBusinessSubType &&
            ![
                'LineOfBusinessCodeListOther',
                'LineOfBusinessCodeListESP',
                'LineOfBusinessCodeListPCoML',
            ].includes(lineOfBusinessCode)
        ) {
            iconName = lineOfBusinessSubtypeCodeIconMap.get(lineOfBusinessSubType) ?? iconName;
        }
    }

    return (
        <IconPhosphor
            name={iconName ?? 'file-dashed'}
            size={size}
            backgroundColor={disabled ? 'ui-100' : 'blue-300'}
            color={disabled ? 'ui-300' : 'ui-500'}
        />
    );
}
