import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileReportToggleAdditionalRisks } from '@app/public/entities/events';

export interface PublishRiskProfileReportToggleAdditionalRisksEvent extends UseCase {
    execute(): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileReportToggleAdditionalRisksUseCase
    extends UseCase
    implements PublishRiskProfileReportToggleAdditionalRisksEvent
{
    public static type = Symbol('RiskProfile/PublishRiskProfileReportToggleAdditionalRisksEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(): AsyncResult<void, InvalidArgument | OperationFailed> {
        const toggleAdditionalRisksEvent: RiskProfileReportToggleAdditionalRisks = {
            origin: 'RiskProfile',
            name: 'ToggleAdditionalRisks',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };

        await this.eventBus.publish(toggleAdditionalRisksEvent);

        return Success();
    }
}

export const PublishRiskProfileReportToggleAdditionalRisksEvent: UseCaseClass<PublishRiskProfileReportToggleAdditionalRisksEvent> =
    PublishRiskProfileReportToggleAdditionalRisksUseCase;
