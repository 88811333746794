import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ExcessQuoteOptions {
    readonly effectiveDate: Date;
    readonly expirationDate: Date;
    readonly limit: number;
    readonly quoteCyber: boolean;
}

export const ExcessLimitOptions = [
    1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000,
] as const;

export const ExcessQuoteOptions = {
    ...defineValidator<ExcessQuoteOptions>({
        effectiveDate: Joi.date().required(),
        expirationDate: Joi.date().required(),
        limit: Joi.number()
            .required()
            .valid(...ExcessLimitOptions),
        quoteCyber: Joi.bool().required(),
    }),
    create(excessQuoteOptions: ExcessQuoteOptions) {
        return ExcessQuoteOptions.validate(excessQuoteOptions);
    },
};
