import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

export enum FormEventName {
    Viewed = 'Impression',
    Submitted = 'Submitted',
    Next = 'Next',
    Previous = 'Previous',
}

export interface PublishFormEventRequest {
    name: FormEventName;
    formStage: string;
    formName: string;
}

export interface FormEvent extends DomainEvent {
    readonly origin: 'Form';
    readonly name: 'FormEvent';
    readonly eventName: FormEventName;
    readonly eventPayload: {
        readonly formStage: string;
        readonly formName: string;
    };
}

export interface PublishFormEvent extends UseCase {
    execute(request: PublishFormEventRequest): AsyncResult<void>;
}

class PublishFormEventUseCase extends UseCase implements PublishFormEvent {
    public static type = Symbol('DataDrivenForm/PublishFormEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(
        request: PublishFormEventRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const { name: eventName, ...eventPayload } = request;

        const formEvent: FormEvent = {
            origin: 'Form',
            name: 'FormEvent',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            eventName,
            eventPayload,
        };

        await this.eventBus.publish(formEvent);

        return Success();
    }
}

export const PublishFormEvent: UseCaseClass<PublishFormEvent> = PublishFormEventUseCase;
