import { CoverLayout, Placeholder, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function IssueCertificateProvisionsStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <StackLayout gap="4">
                    <Text style={'overline'}>Step 5</Text>
                    <Text style={'heading 3'}>Provisions</Text>
                </StackLayout>
                <Text style="body 1">
                    Now, simply customize your certificate by choosing the provisions you’d like to
                    leave out. This way, you don’t add in any unnecessary information.
                </Text>
                <Placeholder backgroundColor="ui-50" appearance="compact" imageMaxWidth={270} />
            </StackLayout>
        </CoverLayout>
    );
}
