import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetOnboardingQuestionnaireDataFromOPTIMAL } from './GetOnboardingQuestionnaireDataFromOPTIMAL';
import { GetActiveOrganizationProfile } from './GetActiveOrganizationProfile';
import {
    getOnboardingQuestionnaireDataFromUserProfile,
    OnboardingPrefillQuestionnaireData,
} from '../types/OnboardingPrefillQuestionnaireData';

type OnboardingPrefillQuestionnaireDataSource = {
    [K in keyof OnboardingPrefillQuestionnaireData]: string;
};

export type GetOnboardingQuestionnaireDataResponse = {
    questionnaireData: OnboardingPrefillQuestionnaireData;
    questionnaireDataMap: OnboardingPrefillQuestionnaireDataSource;
};

export interface GetOnboardingQuestionnaireData extends UseCase {
    execute(): AsyncResult<GetOnboardingQuestionnaireDataResponse, never>;
}

@injectable()
class GetOnboardingQuestionnaireDataUseCase
    extends UseCase
    implements GetOnboardingQuestionnaireData
{
    public static type = Symbol('Global/GetOnboardingQuestionnaireData');

    /**
     * constructor for the GetOnboardingQuestionnaireData use case
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetActiveOrganizationProfile.type)
        private getActiveOrganizationProfile: GetActiveOrganizationProfile,
        @inject(GetOnboardingQuestionnaireDataFromOPTIMAL.type)
        private getOnboardingQuestionnaireDataFromOPTIMAL: GetOnboardingQuestionnaireDataFromOPTIMAL,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<GetOnboardingQuestionnaireDataResponse, never> {
        const getActiveOrganizationProfileResponse =
            await this.getActiveOrganizationProfile.execute();

        const organization = isOK(getActiveOrganizationProfileResponse)
            ? getActiveOrganizationProfileResponse.value.organization
            : undefined;

        const onboardingQuestionnaireDataFromOrg = organization
            ? getOnboardingQuestionnaireDataFromUserProfile(organization)
            : {};

        const preFillDataFromOptimal =
            await this.getOnboardingQuestionnaireDataFromOPTIMAL.execute();
        const optimalPrefillData = isOK(preFillDataFromOptimal) ? preFillDataFromOptimal.value : [];

        const questionnaireData: OnboardingPrefillQuestionnaireData = {};
        const questionnaireDataMap: OnboardingPrefillQuestionnaireDataSource = {};

        for (const k in onboardingQuestionnaireDataFromOrg) {
            const key = k as keyof OnboardingPrefillQuestionnaireData;
            const value = onboardingQuestionnaireDataFromOrg[key];

            questionnaireData[key] = value as any;
            if (value) {
                questionnaireDataMap[key] = 'USER';
            }
        }

        for (const answer of optimalPrefillData) {
            const key = answer.key as keyof OnboardingPrefillQuestionnaireData;

            // if there is aready a value for this key, we know it came fomr the userOrg (ie the user), skip it.
            if (!questionnaireData[key]) {
                const { value, source } = answer;
                questionnaireData[key] = value as any;
                questionnaireDataMap[key] = source;
            }
        }

        return Success<GetOnboardingQuestionnaireDataResponse>({
            questionnaireData,
            questionnaireDataMap,
        });
    }
}

export const GetOnboardingQuestionnaireData: UseCaseClass<GetOnboardingQuestionnaireData> =
    GetOnboardingQuestionnaireDataUseCase;
