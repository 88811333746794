import React, { useState, useEffect } from 'react';
import {
    CoverLayout,
    ColumnLayout,
    CenterLayout,
    GridLayout,
    ProgressBar,
    StackLayout,
    Text,
    Transition,
    useInterval,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { useNavigation } from '@app/view/hooks/useNavigation';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useCurrentRoute } from 'react-navi';
import { RiskProfilePageLayout } from './RiskProfilePageLayout.view';

export function RiskProfileInterstitial() {
    const { navigate } = useNavigation();
    const route = useCurrentRoute();
    const [pageExiting, setPageExiting] = useState(false);
    // This matrix sets delays for each progress step
    const [stepsProgress, setStepsProgress] = useState([-15, -30, -50]);
    const isLargeScreen = useResponsive({ screenWidth: { greaterThan: 'large-tablet' } });

    useInterval(() => {
        const step = Math.floor(Math.random() * stepsProgress.length);

        if (
            stepsProgress.slice(0, step).some((progress) => progress < 100) &&
            Math.round(Math.random())
        ) {
            return;
        }

        setStepsProgress(stepsProgress.toSpliced(step, 1, stepsProgress[step] + 1));
    }, 10); // This interval sets speed of progress

    // Once all steps are complete, cinematic outro and progress to report page
    useEffect(() => {
        if (stepsProgress.every((progress) => progress >= 100)) {
            setTimeout(() => {
                setPageExiting(true);

                setTimeout(() => {
                    navigate(URI.build('/explore/risk-profile/report', route.url.query));
                }, 1500);
            }, 1000);
        }
    }, [stepsProgress, navigate, route]);

    return (
        <RiskProfilePageLayout>
            <StackLayout gap="48">
                <span />
                <Transition.Fade opacity={pageExiting ? 0 : 1}>
                    <StackLayout center gap="64">
                        <Text style="heading 3">Building your risk profile</Text>
                        <StackLayout gap="48" style={{ width: '100%' }}>
                            <ColumnLayout>
                                <Transition.Fade opacity={stepsProgress[0] > -10 ? 1 : 0.1}>
                                    <ColumnLayout center grow="fixed">
                                        <Text style="body 1">Reviewing your info</Text>
                                        <ProgressBar
                                            color={
                                                stepsProgress[0] >= 100
                                                    ? 'positive-500'
                                                    : 'violet-400'
                                            }
                                            percent={Math.max(stepsProgress[0], 0)}
                                        />
                                    </ColumnLayout>
                                </Transition.Fade>
                            </ColumnLayout>
                            <ColumnLayout>
                                <Transition.Fade opacity={stepsProgress[1] > -10 ? 1 : 0.1}>
                                    <ColumnLayout center grow="fixed">
                                        <Text style="body 1">Analyzing your info</Text>
                                        <ProgressBar
                                            color={
                                                stepsProgress[1] >= 100
                                                    ? 'positive-500'
                                                    : 'violet-400'
                                            }
                                            percent={Math.max(stepsProgress[1], 0)}
                                        />
                                    </ColumnLayout>
                                </Transition.Fade>
                            </ColumnLayout>
                            <ColumnLayout>
                                <Transition.Fade opacity={stepsProgress[2] > -10 ? 1 : 0.1}>
                                    <ColumnLayout center grow="fixed">
                                        <Text style="body 1">Recommending coverages</Text>
                                        <ProgressBar
                                            color={
                                                stepsProgress[2] >= 100
                                                    ? 'positive-500'
                                                    : 'violet-400'
                                            }
                                            percent={Math.max(stepsProgress[2], 0)}
                                        />
                                    </ColumnLayout>
                                </Transition.Fade>
                            </ColumnLayout>
                        </StackLayout>
                    </StackLayout>
                </Transition.Fade>
            </StackLayout>
        </RiskProfilePageLayout>
    );
}
