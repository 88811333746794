import React, { useContext } from 'react';
import {
    CoverageHeroBanner as CoverageHeroBannerView,
    CoverageHeroBannerComponentProps,
} from './CoverageHeroBanner.view';
import { hasRole } from '@app/userOrg/entities/Session';
import { AppContext } from '@app/view/AppContext';

export const CoverageHeroBanner = (
    props: Omit<CoverageHeroBannerComponentProps, 'showBrokerOrganizationName'>,
) => {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    return <CoverageHeroBannerView {...props} showBrokerOrganizationName={isBroker} />;
};
