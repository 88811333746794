import { ApplicationEdited } from '@app/brokerDashboard/useCases/EditApplication';
import { CurrencyMarshaller } from '@embroker/shotwell-api';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { container, inject, injectable } from '@embroker/shotwell/core/di';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Failure, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { AnalyticsService } from '.';
import { BundleQuotePurchased } from '../../bundle/entities/BundleQuote';
import { BundleUserClickEvent } from '../../bundle/useCases/PublishBundleUserClickEvent';
import {
    OwnSelfServingCertificateCreated,
    ThirdPartySelfServingCertificateCreated,
} from '../../certificates/useCases/CreateSelfServingCertificate';
import { SelfServingFlowEnteredEvent } from '../../certificates/useCases/SendEnterSelfSevingFlowEvent';
import { SelfServingCertificateRequestSubmittedEvent } from '../../certificates/useCases/ShareCertificate';
import { DocumentRequested } from '../../documents/entities/Document';
import {
    ExperimentationEventNames,
    ExperimentationImpressionEvent,
    FeatureUsedEvent,
} from '../../experimentation/types/ExperimentationEvents';
import { OnPayment, OnPremiumFinance, PaymentType } from '../../payments/entities/Invoice';
import { PaymentCTAClickEvent } from '../../payments/useCases/PaymentCTAClickEvent';
import { PolicyDocumentUploaded } from '../../policy/useCases/UploadPolicy';
import { CNAQuotePurchased } from '../../quote/bopCna/entities/CNAQuote';
import { CrimeQuotePurchased } from '../../quote/crime/entities/CrimeQuote';
import { CyberQuotePurchased } from '../../quote/cyber/entities/CyberQuote';
import { QuoteBindCoverage } from '../../quote/entities/Quote';
import { ESPQuotePurchased } from '../../quote/esp/types/ESPQuoteDetails';
import { ESPRenewalQuotePurchased } from '../../quote/espRenewal/entities/ESPRenewalQuote';
import { LPLQuotePurchased } from '../../quote/lpl/entities/LPLQuote';
import { PCoMLQuotePurchased } from '../../quote/pcoml/entities/PCoMLQuote';
import { WCGAQuotePurchased } from '../../quote/wcga/entities/WCGAQuote';
import {
    ApplicationAccessStartQuote,
    ApplicationCreated,
    ApplicationEarlyClearanceFailed,
    ApplicationForm,
    ApplicationNotEligible,
    ApplicationOFACRejected,
    ApplicationPurchaseIntent,
    ApplicationQuoteCreated,
    ApplicationReferred,
    ApplicationSubmitClearanceFailed,
    ApplicationSubmitted,
    ApplicationSubmittedForReview,
} from '../../shopping/entities/Application';
import { IndicativePremiumCreated } from '../../shopping/entities/IndicativePremium';
import { FormEventName as ApplicationFormEventName } from '../../shopping/useCases/PublishFormEvents';
import { ShoppingUserClickEvent } from '../../shopping/useCases/PublishShoppingUserClickEvent';
import {
    Organization,
    OrganizationAdded,
    OrganizationSwitched,
} from '../../userOrg/entities/Organization';
import { SessionOrganizationSelected, SessionUserLogin } from '../../userOrg/entities/Session';
import {
    SignedUp,
    UserLoginByAdmin,
    UserOnboardingCompleted,
    UserOnboardingDnbNaicsRetrieved,
    UserOnboardingNaicsChanged,
    UserOnboardingStarted,
    UserOnboardingStepVisited,
    UserSignUpEmailProvided,
    UserSignUpStarted,
    UserUpdated,
} from '../../userOrg/entities/User';
import { UserInvited } from '../../userOrg/entities/UserInvite';
import { OrganizationRepository } from '../../userOrg/repositories/OrganizationRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { UserRepository } from '../../userOrg/repositories/UserRepository';
import { UserOrgUserClickEvent } from '../../userOrg/useCases/PublishUserOrgUserClickEvent';
import {
    GetActiveSessionFailedError,
    GetActiveUserFailedError,
    GetOrganizationFailedError,
    GetOrganizationsForUserFailedError,
} from '../errors';
import { GoogleAnalyticsRepository } from '../repositories/GoogleAnalyticsRepository';
import { SKU } from '../types/SKU';

import { OnboardingWelcomeBackPopupEvent } from '../../userOrg/useCases/PublishOnboardingWelcomeBackPopupEvent';
import { SingleInProgressAppWelcomeBackPopupEvent } from '@app/userOrg/useCases/PublishSingleInProgressAppWelcomeBackPopupEvent';
import { SingleValidAndExpiredQuotePopupEvent } from '@app/userOrg/useCases/PublishSingleValidAndExpiredQuotePopupEvent';
import { CyberCrossSellEvent } from '@app/lplRenewal/useCases/PublishCyberCrossSellEvent';
import { ReturnFromAscendEvent } from '@app/policy/useCases/PublishReturnFromAscendEvent';
import { QuoteUserClickEvent } from '@app/quote/useCases/PublishQuoteUserClickEvent';
import { FormEvent } from '@app/view/components/DataDrivenForm/useCases/PublishFormEvent';
import {
    RiskProfileIneligibleModalShow,
    RiskProfileIneligibleModalActionClick,
    RiskProfileReportToggleAdditionalRisks,
    RiskProfileReportClickCoverageLearnMore,
    RiskProfileReportBackToEmbrokerClick,
    RiskProfileReportGetAQuoteClick,
    RiskProfileReportRisksCoveredIncludeToggle,
} from '@app/public/entities/events';
import { LogoClickedEvent } from '@app/userOrg/useCases/PublishLogoClickEvent';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetApplicationList } from '@app/shopping/useCases/GetApplicationList';
import { CardPolicyClicked } from '@app/policy/useCases/PublishPolicyCardClickedEvent';

interface QuoteEventInput {
    applicationId: UUID;
    totalPremium: Nullable<Money>;
    isRenewal: boolean;
    isStreamline?: boolean;
    sku?: SKU;
    isPartiallyQuoted?: boolean;
}

interface PaymentEventInput {
    total: Money;
    paymentType: PaymentType;
    success: boolean;
}

interface QuoteDocumentRequestedInput {
    documentType: string;
}

interface PremiumFinanceEventInput {
    success: boolean;
}

interface FormEventInput {
    eventName: string;
    formStage: string;
    formName: string;
    applicationId: Nullable<UUID>;
    isRenewal: boolean;
    readonly sku?: SKU;
}

interface ApplicationEventInput {
    eventName: string;
    applicationId: UUID;
    isRenewal: boolean;
    sku?: SKU;
}

interface SelfServingCertificateCreatedEventInput {
    certificateId: UUID;
    holderEmailProvided?: EmailAddress;
    includesProvisionCustomization?: boolean;
    isAdditionalInsuredDisabled?: boolean;
    isWaiverOfSubrogationDisabled?: boolean;
    isPrimaryNonContributoryDisabled?: boolean;
    includesPolicyCustomization?: boolean;
    sku?: SKU;
}

interface SelfServingCertificateRequestSubmittedEventInput {
    additionalWordingText?: string;
    isPdfAttached: boolean;
    sku?: SKU;
}

interface PolicyCardClickedEventInput {
    eventName:
        | 'Policy Card Pay Now Clicked'
        | 'Policy Card View Policy Clicked'
        | 'Policy Card Modify Policy Clicked';
    policyNumber: string;
    isRenewal: boolean;
}

@injectable()
export class GoogleAnalyticsService implements AnalyticsService {
    constructor(
        @inject(DomainEventBus) private eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(GoogleAnalyticsRepository)
        private googleAnalyticsRepository: GoogleAnalyticsRepository,
        @inject(Log) private logger: Logger,
    ) {}

    public subscribeToEvents(): void {
        this.eventBus.subscribe<QuoteUserClickEvent>(
            'Quote',
            'QuoteUserClickEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName);
            },
        );
        this.eventBus.subscribe<ShoppingUserClickEvent>(
            'Shopping',
            'ShoppingUserClickEvent',
            async ({ clickEventName, clickEventPayload }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName, clickEventPayload);
            },
        );
        this.eventBus.subscribe<BundleUserClickEvent>(
            'Bundle',
            'BundleUserClickEvent',
            async ({ clickEventName, clickEventPayload }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName, clickEventPayload);
            },
        );
        this.eventBus.subscribe<UserOrgUserClickEvent>(
            'UserOrg',
            'UserOrgUserClickEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName);
            },
        );
        this.eventBus.subscribe<SessionUserLogin>('Session', 'UserLogin', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            const result = await this.handleUserLoginEvent(event);
            if (isErr(result)) {
                this.logger.warn('Analytics failed to handle User Login event.', result);
            }
        });
        this.eventBus.subscribe<UserSignUpStarted>('User', 'SignUpStarted', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            this.googleAnalyticsRepository.sendCustomEvent('Signup Started', {
                sku_string: event.sku?.sku_string,
                sku_string_plaintext: event.sku?.sku_string_plaintext,
            });
        });
        this.eventBus.subscribe<UserSignUpEmailProvided>(
            'User',
            'SignUpEmailProvided',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }
                this.googleAnalyticsRepository.sendCustomEvent('Signup Email Provided', {});
            },
        );
        this.eventBus.subscribe<SignedUp>('User', 'SignedUp', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            const result = await this.handleUserSignUpEvent(event);
            if (isErr(result)) {
                this.logger.warn('Analytics failed to handle User SignUp event.', result);
            }
        });
        this.eventBus.subscribe<UserLoginByAdmin>('User', 'LoginByAdmin', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            const result = await this.handleUserLoginByAdmin();
            if (isErr(result)) {
                this.logger.warn('Analytics failed to handle User LoginByAdmin event.', result);
            }
        });
        this.eventBus.subscribe<UserUpdated>('User', 'Updated', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            const result = await this.handleUserDetailsUpdate();
            if (isErr(result)) {
                this.logger.warn('Analytics failed to handle Update user event.', result);
            }
        });
        this.eventBus.subscribe<UserInvited>('UserInvite', 'Invited', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            const result = await this.handleUserInvitedEvent();
            if (isErr(result)) {
                this.logger.warn('Analytics failed to handle User invited event.', result);
            }
        });
        this.eventBus.subscribe<OrganizationSwitched>('Organization', 'Switched', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            this.googleAnalyticsRepository.sendCustomEvent('Switch Company', {
                company_id: event.organizationId,
            });
        });
        this.eventBus.subscribe<OrganizationAdded>('Organization', 'Added', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            const result = await this.handleOrganizationAdded(event.organizationId);
            if (isErr(result)) {
                this.logger.warn('Analytics failed to handle Organization added event.', result);
            }
        });
        this.eventBus.subscribe<SessionOrganizationSelected>(
            'Session',
            'OrganizationSelected',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.handleOrganizationRenewalAttribution();

                const result = await this.handleOrganizationChanged(event.organizationId);
                if (isErr(result)) {
                    this.logger.warn(
                        'Analytics failed to handle Organization changed event.',
                        result,
                    );
                }
            },
        );
        this.eventBus.subscribe<ApplicationPurchaseIntent>(
            'Application',
            'PurchaseIntent',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handlePurchaseIntentEvent(event.sku);
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.Viewed,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'Form Impression',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.Saved,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'Form Saved',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.TierICompleted,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'Tier I Questions Completed',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.TierIICompleted,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'Tier II Questions Completed',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.TierIIICompleted,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'Tier III Questions Completed',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.SaveAndExitClicked,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'App Save and Exit clicked',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationForm>(
            'Application',
            ApplicationFormEventName.SaveAndExitModalClicked,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleFormEvent({
                    eventName: 'Modal Save and Exit CTA clicked',
                    formStage: event.formStage,
                    formName: event.formName,
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );

        this.eventBus.subscribe<ApplicationCreated>(
            'Application',
            'ApplicationCreated',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleApplicationEvent({
                    eventName: 'Application Started',
                    applicationId: event.id,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationSubmitted>('Application', 'Submitted', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handleApplicationEvent({
                eventName: 'Application Submitted',
                applicationId: event.applicationId,
                isRenewal: event.isRenewal,
                sku: event.sku,
            });
        });
        this.eventBus.subscribe<ApplicationOFACRejected>(
            'Application',
            'OFACRejected',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('ApplicationSubmittedOFACRejected');
            },
        );
        this.eventBus.subscribe<ApplicationAccessStartQuote>(
            'Application',
            'AccessStartQuote',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('AccessStartQuote');
            },
        );
        this.eventBus.subscribe<ApplicationSubmitClearanceFailed>(
            'Application',
            'SubmitClearanceFailed',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('SubmitClearanceFailed', {
                    clearanceFailureType: 'quoteReservation',
                });
            },
        );
        this.eventBus.subscribe<ApplicationEarlyClearanceFailed>(
            'Application',
            'EarlyClearanceFailed',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('EarlyClearanceFailed', {
                    clearanceFailureType: 'quoteReservation',
                });
            },
        );
        this.eventBus.subscribe<ApplicationNotEligible>(
            'Application',
            'NotEligible',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleApplicationEvent({
                    eventName: 'Application Not Eligible',
                    applicationId: event.applicationId,
                    isRenewal: event.isRenewal,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<ApplicationQuoteCreated>(
            'Application',
            'QuoteCreated',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleQuoteEvent({
                    applicationId: event.applicationId,
                    totalPremium: event.totalPremium,
                    sku: event.sku,
                    isRenewal: event.isRenewal,
                    isStreamline: event.isStreamline,
                    isPartiallyQuoted: event.isPartiallyQuoted,
                });
            },
        );
        this.eventBus.subscribe<ApplicationReferred>('Application', 'Referred', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handleReferredEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<ApplicationSubmittedForReview>(
            'Application',
            'SubmittedForReview',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleSubmittedForReviewEvent(event);
            },
        );
        this.eventBus.subscribe<IndicativePremiumCreated>(
            'IndicativePremium',
            'Created',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleIndicativePremiumEvent({
                    applicationId: event.applicationId,
                    totalPremium: event.indicativePremiumAmount,
                    sku: event.sku,
                    isRenewal: event.isRenewal,
                });
            },
        );
        this.eventBus.subscribe<ApplicationEdited>('Application', 'Edited', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handleApplicationEditedEvent(event);
        });
        this.eventBus.subscribe<OnPayment>('Invoice', 'OnPayment', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePaymentEvent({
                total: event.paymentAmount,
                paymentType: event.paymentType,
                success: event.success,
            });
        });
        this.eventBus.subscribe<DocumentRequested>(
            'Document',
            'QuotePageDocumentRequested',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleQuoteDocumentRequestedEvent({
                    documentType: event.documentType,
                });
            },
        );
        this.eventBus.subscribe<OnPremiumFinance>('Invoice', 'OnPremiumFinance', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePremiumFinanceEvent({
                success: event.success,
            });
        });
        this.eventBus.subscribe<PolicyDocumentUploaded>(
            'Policy',
            'DocumentUploaded',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handlePolicyDocumentUploadedEvent();
            },
        );
        this.eventBus.subscribe<QuoteBindCoverage>('Quote', 'BindCoverage', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handleBindCoverageEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
                isStreamline: event.isStreamline,
            });
        });

        this.eventBus.subscribe<CNAQuotePurchased>('CNAQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<ESPQuotePurchased>('ESPQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: false,
            });
        });
        this.eventBus.subscribe<ESPRenewalQuotePurchased>(
            'ESPRenewalQuote',
            'Purchased',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handlePurchaseEvent({
                    applicationId: event.applicationId,
                    totalPremium: event.totalPremium,
                    sku: event.sku,
                    isRenewal: true,
                });
            },
        );
        this.eventBus.subscribe<LPLQuotePurchased>('LPLQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
                isStreamline: event.isStreamline,
            });
        });
        this.eventBus.subscribe<PCoMLQuotePurchased>('PCoMLQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<WCGAQuotePurchased>('WCGAQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<CyberQuotePurchased>('CyberQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<CrimeQuotePurchased>('CrimeQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<BundleQuotePurchased>('BundleQuote', 'Purchased', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePurchaseEvent({
                applicationId: event.applicationId,
                totalPremium: event.totalPremium,
                sku: event.sku,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<OwnSelfServingCertificateCreated>(
            'Certificates',
            'OwnSelfServingCertificateCreated',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleOwnSelfServingCertificateCreated(event.certificateId);
            },
        );
        this.eventBus.subscribe<ThirdPartySelfServingCertificateCreated>(
            'Certificates',
            'ThirdPartySelfServingCertificateCreated',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleThirdPartySelfServingCertificateCreated({
                    certificateId: event.certificateId,
                    holderEmailProvided: event.holderEmailProvided,
                    includesPolicyCustomization: event.includesPolicyCustomization,
                    isWaiverOfSubrogationDisabled: event.isWaiverOfSubrogationDisabled,
                    isAdditionalInsuredDisabled: event.isAdditionalInsuredDisabled,
                    isPrimaryNonContributoryDisabled: event.isPrimaryNonContributoryDisabled,
                    includesProvisionCustomization: event.includesProvisionCustomization,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<SelfServingCertificateRequestSubmittedEvent>(
            'Certificates',
            'SelfServingCertificateRequestSubmittedEvent',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleSelfServingCertificateRequestSubmitted({
                    additionalWordingText: event.additionalWordingText,
                    isPdfAttached: event.isPdfAttached,
                    sku: event.sku,
                });
            },
        );
        this.eventBus.subscribe<SelfServingFlowEnteredEvent>(
            'Certificates',
            'SelfServingFlowEntered',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handleSelfServingCertificateFlowEntered();
            },
        );
        this.eventBus.subscribe<CardPolicyClicked>('Policy', 'CardPayNowClicked', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            await this.handlePolicyCardClickedEvent({
                eventName: 'Policy Card Pay Now Clicked',
                policyNumber: event.policyNumber,
                isRenewal: event.isRenewal,
            });
        });
        this.eventBus.subscribe<CardPolicyClicked>(
            'Policy',
            'CardViewPolicyClicked',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handlePolicyCardClickedEvent({
                    eventName: 'Policy Card View Policy Clicked',
                    policyNumber: event.policyNumber,
                    isRenewal: event.isRenewal,
                });
            },
        );

        this.eventBus.subscribe<CardPolicyClicked>(
            'Policy',
            'CardModifyPolicyClicked',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                await this.handlePolicyCardClickedEvent({
                    eventName: 'Policy Card Modify Policy Clicked',
                    policyNumber: event.policyNumber,
                    isRenewal: event.isRenewal,
                });
            },
        );
        this.eventBus.subscribe<ExperimentationImpressionEvent>(
            'Experimentation',
            ExperimentationEventNames.Impression,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('Experimentation Impression', {
                    experimentationTestName: event.experimentationName,
                    assignment: event.assignment,
                    raw: event.raw,
                    deviceId: event.deviceId,
                    sessionId: event.sessionId,
                    userId: event.userId,
                    GA_userId: event.GA_userId,
                    HEAP_userId: event.HEAP_userId,
                    HEAP_sessionId: event.HEAP_sessionId,
                    localStorage_deviceId: event.localStorage_deviceId,
                    cookie_deviceId: event.cookie_deviceId,
                });
            },
        );
        this.eventBus.subscribe<FeatureUsedEvent>(
            'Experimentation',
            ExperimentationEventNames.FeatureUsed,
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('Feature Used', {
                    feature: event.featureName,
                    value: event.value,
                    raw: event.raw,
                    deviceId: event.deviceId,
                    sessionId: event.sessionId,
                    userId: event.userId,
                    GA_userId: event.GA_userId,
                    HEAP_userId: event.HEAP_userId,
                    HEAP_sessionId: event.HEAP_sessionId,
                    localStorage_deviceId: event.localStorage_deviceId,
                    cookie_deviceId: event.cookie_deviceId,
                });
            },
        );
        this.eventBus.subscribe<UserOnboardingDnbNaicsRetrieved>(
            'User',
            'OnboardingDnbNaicsRetrieved',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('NAICs D&B Value Retrieved', {
                    naics: event.naicsCode,
                });
            },
        );
        this.eventBus.subscribe<UserOnboardingNaicsChanged>(
            'User',
            'OnboardingNaicsChanged',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('NAICs D&B Value Changed', {
                    previousNaics: event.previousNaicsCode,
                    naics: event.naicsCode,
                });
            },
        );
        this.eventBus.subscribe<UserOnboardingStarted>(
            'User',
            'OnboardingStarted',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('Onboarding Started');
            },
        );
        this.eventBus.subscribe<UserOnboardingCompleted>(
            'User',
            'OnboardingCompleted',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('Onboarding Completed');
            },
        );
        this.eventBus.subscribe<UserOnboardingStepVisited>(
            'User',
            'OnboardingStepVisited',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('Onboarding Step Visited', {
                    step: event.step,
                });
            },
        );
        this.eventBus.subscribe<LogoClickedEvent>('User', 'EmbrokerLogoClicked', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            this.googleAnalyticsRepository.sendCustomEvent('Embroker Logo Clicked', {
                path: event.path,
            });
        });
        this.eventBus.subscribe<PaymentCTAClickEvent>(
            'Payments',
            'PaymentCTAClickEvent',
            async ({ message }) => {
                this.googleAnalyticsRepository.sendCustomEvent(message);
            },
        );
        this.eventBus.subscribe<CyberCrossSellEvent>(
            'LPLRenewal',
            'CyberCrossSellEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(
                    `Renewal Cyber Cross-sell - ${clickEventName}`,
                );
            },
        );
        this.eventBus.subscribe<OnboardingWelcomeBackPopupEvent>(
            'User',
            'OnboardingWelcomeBackPopupEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName);
            },
        );
        this.eventBus.subscribe<SingleValidAndExpiredQuotePopupEvent>(
            'User',
            'SingleValidAndExpiredQuotePopupEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName);
            },
        );
        this.eventBus.subscribe<SingleInProgressAppWelcomeBackPopupEvent>(
            'User',
            'SingleInProgressAppWelcomeBackPopupEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName);
            },
        );
        this.eventBus.subscribe<ReturnFromAscendEvent>(
            'Policy',
            'ReturnFromAscendEvent',
            async ({ clickEventName }) => {
                this.googleAnalyticsRepository.sendCustomEvent(clickEventName);
            },
        );

        this.eventBus.subscribe<FormEvent>('Form', 'FormEvent', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            this.googleAnalyticsRepository.sendCustomEvent(
                `Form ${event.eventName}`,
                event.eventPayload,
            );
        });
        this.eventBus.subscribe<RiskProfileIneligibleModalShow>(
            'RiskProfile',
            'IneligibleKickoutModalShow',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent(
                    'Risk Profile Ineligibility Modal Show',
                );
            },
        );
        this.eventBus.subscribe<RiskProfileIneligibleModalActionClick>(
            'RiskProfile',
            'IneligibleKickoutActionClick',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent(
                    'Risk Profile Ineligibility Modal Action Click',
                );
            },
        );
        this.eventBus.subscribe<RiskProfileReportToggleAdditionalRisks>(
            'RiskProfile',
            'ToggleAdditionalRisks',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent(
                    'Risk Profile Report Toggle Additional Risks',
                );
            },
        );
        this.eventBus.subscribe<RiskProfileReportClickCoverageLearnMore>(
            'RiskProfile',
            'ClickCoverageLearnMore',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent(
                    'Risk Profile Report Click Coverage Learn More',
                    {
                        appTypeCode: event.appTypeCode,
                    },
                );
            },
        );
        this.eventBus.subscribe<RiskProfileReportBackToEmbrokerClick>(
            'RiskProfile',
            'BackToEmbrokerClick',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent(
                    'Risk Profile Report Click Back To Embroker',
                    {
                        appTypeCode: event.appTypeCode,
                    },
                );
            },
        );
        this.eventBus.subscribe<RiskProfileReportGetAQuoteClick>(
            'RiskProfile',
            'GetAQuoteClick',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent('Risk Profile Report Get A Quote', {
                    appTypeCode: event.appTypeCode,
                });
            },
        );
        this.eventBus.subscribe<RiskProfileReportRisksCoveredIncludeToggle>(
            'RiskProfile',
            'RisksCoveredIncludeToggle',
            async (event) => {
                if (!isDomainEventLocal(event)) {
                    return;
                }

                this.googleAnalyticsRepository.sendCustomEvent(
                    'Risk Profile Report Toggle Risks Covered Include',
                );
            },
        );
    }

    private async handleOrganizationRenewalAttribution(): AsyncResult<
        void | InvalidArgument | OperationFailed
    > {
        const applicationListResult = await execute(GetApplicationList, {
            sectionType: 'renewals',
        });

        if (isOK(applicationListResult)) {
            const renewalApplications = applicationListResult.value.applicationList.reduce(
                (acc, application) => {
                    acc.push({
                        applicationId: application.id,
                        renewedPolicyIds: application.renewedPolicyIdList,
                    });

                    return acc;
                },
                [] as any[],
            );

            if (renewalApplications.length > 0) {
                return this.googleAnalyticsRepository.sendCustomEvent('Policy Renewal Prompted', {
                    renewalApplications,
                });
            }
        }

        return Failure(OperationFailed({ message: 'No applications found' }));
    }

    private async handleUserLoginEvent(
        event: Immutable<SessionUserLogin>,
    ): AsyncResult<void | InvalidArgument | OperationFailed> {
        // TODO replace with fetch user by ID once that method is implemented in user repository
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            container.get<Logger>(Log).error(userResult.errors);
            return Failure(GetActiveUserFailedError());
        }
        let organization: Nullable<Organization> = null;
        if (event.organizationId !== null) {
            const organizationResult = await this.organizationRepository.getOrganization(
                event.organizationId,
            );
            if (isErr(organizationResult)) {
                container.get<Logger>(Log).error(organizationResult.errors);
                return Failure(GetOrganizationFailedError(event.organizationId));
            }
            organization = organizationResult.value as Organization;
        }

        return this.googleAnalyticsRepository.sendLoginEvent(userResult.value, organization, false);
    }

    private async handleUserSignUpEvent(
        event: Immutable<SignedUp>,
    ): AsyncResult<void | InvalidArgument | OperationFailed> {
        // TODO replace with fetch user by ID once that method is implemented in user repository
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            container.get<Logger>(Log).error(userResult.errors);
            return Failure(GetActiveUserFailedError());
        }
        const user = userResult.value;
        const organization = event.organization;
        const sku = event.sku;
        const eventResult = this.googleAnalyticsRepository.sendRegistrationEvent(
            user,
            organization,
            sku,
        );
        if (isErr(eventResult)) {
            return eventResult;
        }

        return this.googleAnalyticsRepository.sendLoginEvent(user, organization, false, true);
    }

    private async handleUserLoginByAdmin(): AsyncResult<void | InvalidArgument | OperationFailed> {
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            container.get<Logger>(Log).error(userResult.errors);
            return Failure(GetActiveUserFailedError());
        }
        const user = userResult.value;
        const organizationsResult = await this.organizationRepository.getOrganizationsForUser(
            user.id,
        );
        if (isErr(organizationsResult)) {
            container.get<Logger>(Log).error(organizationsResult.errors);
            return Failure(GetOrganizationsForUserFailedError(user.id));
        }
        const organizations = organizationsResult.value;
        const organization = organizations.length > 0 ? (organizations[0] as Organization) : null;

        return this.googleAnalyticsRepository.sendLoginEvent(user, organization, true);
    }

    private async handleOrganizationChanged(orgId: UUID): AsyncResult<void> {
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            container.get<Logger>(Log).error(userResult.errors);
            return Failure(GetActiveUserFailedError());
        }
        const user = userResult.value;
        const organizationResult = await this.organizationRepository.getOrganization(orgId);
        if (isErr(organizationResult)) {
            container.get<Logger>(Log).error(organizationResult.errors);
            return Failure(GetOrganizationFailedError(orgId));
        }
        const organization = organizationResult.value as Organization;

        return this.googleAnalyticsRepository.updateUserTraits(user, organization);
    }

    private async handleUserDetailsUpdate(): AsyncResult<void> {
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            container.get<Logger>(Log).error(userResult.errors);
            return Failure(GetActiveUserFailedError());
        }
        const user = userResult.value;
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                GetActiveSessionFailedError('Detected null for activeSessionResult value'),
            );
        }
        if (activeSessionResult.value.organizationId === null) {
            return Failure(
                GetActiveSessionFailedError(
                    'Detected null for organizationId in activeSessionResult',
                ),
            );
        }
        const organizationResult = await this.organizationRepository.getOrganization(
            activeSessionResult.value.organizationId,
        );
        if (isErr(organizationResult)) {
            container.get<Logger>(Log).error(organizationResult.errors);
            return Failure(GetOrganizationFailedError(activeSessionResult.value.organizationId));
        }
        const organization = organizationResult.value;

        return this.googleAnalyticsRepository.updateUserTraits(user, organization);
    }

    private async handleUserInvitedEvent(): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        return this.googleAnalyticsRepository.sendCustomEvent('Teammate Added', {
            company_id: activeSessionResult.value?.organizationId,
        });
    }
    private async handleOrganizationAdded(orgId: UUID): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        return this.googleAnalyticsRepository.sendCustomEvent('Company Added', {
            company_id: activeSessionResult.value?.organizationId ?? undefined,
            added_company_id: orgId,
        });
    }

    private async handlePurchaseIntentEvent(sku?: SKU): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        return this.googleAnalyticsRepository.sendCustomEvent('Purchase Intent', {
            company_id: activeSessionResult.value?.organizationId,
            sku_string: sku?.sku_string,
            sku_string_plaintext: sku?.sku_string_plaintext,
        });
    }

    private async handleFormEvent(formEventInput: FormEventInput): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        return this.googleAnalyticsRepository.sendCustomEvent(formEventInput.eventName, {
            form_stage: formEventInput.formStage,
            form_name: formEventInput.formName,
            company_id: activeSessionResult.value?.organizationId,
            application_id: formEventInput.applicationId,
            is_renewal: formEventInput.isRenewal,
            sku_string: formEventInput.sku?.sku_string,
            sku_string_plaintext: formEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleApplicationEvent(
        applicationEventInput: ApplicationEventInput,
    ): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        return this.googleAnalyticsRepository.sendCustomEvent(applicationEventInput.eventName, {
            application_id: applicationEventInput.applicationId,
            is_renewal: applicationEventInput.isRenewal,
            company_id: activeSessionResult.value?.organizationId,
            sku_string: applicationEventInput.sku?.sku_string,
            sku_string_plaintext: applicationEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleIndicativePremiumEvent(quoteEventInput: QuoteEventInput) {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        const indicativePremiumAmount =
            quoteEventInput.totalPremium !== null
                ? CurrencyMarshaller.marshal(quoteEventInput.totalPremium)
                : null;
        return this.googleAnalyticsRepository.sendCustomEvent('Indicative Premium', {
            application_id: quoteEventInput.applicationId,
            company_id: activeSessionResult.value?.organizationId,
            indicative_premium_amount: indicativePremiumAmount,
            sku_string: quoteEventInput.sku?.sku_string,
            sku_string_plaintext: quoteEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleQuoteEvent(quoteEventInput: QuoteEventInput): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        const quoteAmount =
            quoteEventInput.totalPremium !== null
                ? CurrencyMarshaller.marshal(quoteEventInput.totalPremium)
                : null;
        return this.googleAnalyticsRepository.sendCustomEvent('Application Quoted', {
            application_id: quoteEventInput.applicationId,
            is_renewal: quoteEventInput.isRenewal,
            is_streamline: quoteEventInput.isStreamline,
            company_id: activeSessionResult.value?.organizationId,
            quote_amount: quoteAmount,
            sku_string: quoteEventInput.sku?.sku_string,
            sku_string_plaintext: quoteEventInput.sku?.sku_string_plaintext,
            is_partially_quoted: quoteEventInput.isPartiallyQuoted,
        });
    }

    private async handleApplicationEditedEvent(
        appEventInput: ApplicationEdited,
    ): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }

        return this.googleAnalyticsRepository.sendCustomEvent('Edit Application clicked', {
            application_id: appEventInput.id,
            company_id: activeSessionResult.value?.organizationId,
            sku_string: appEventInput.sku?.sku_string,
            sku_string_plaintext: appEventInput.sku?.sku_string_plaintext,
            is_renewal: appEventInput.isRenewal,
        });
    }

    private async handlePaymentEvent(paymentEventInput: PaymentEventInput): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        if (paymentEventInput.success) {
            return this.googleAnalyticsRepository.sendCustomEvent('Payment Succeeded', {
                payment_amount: paymentEventInput.total,
                payment_type: paymentEventInput.paymentType,
                company_id: activeSessionResult.value?.organizationId,
            });
        }

        return this.googleAnalyticsRepository.sendCustomEvent('Payment Failed', {
            payment_amount: paymentEventInput.total,
            payment_type: paymentEventInput.paymentType,
            company_id: activeSessionResult.value?.organizationId,
        });
    }

    private async handleQuoteDocumentRequestedEvent(
        quoteDocumentRequestedInput: QuoteDocumentRequestedInput,
    ): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }

        return this.googleAnalyticsRepository.sendCustomEvent('Quote Page Document Requested', {
            quote_page_document_type: quoteDocumentRequestedInput.documentType,
            company_id: activeSessionResult.value?.organizationId,
        });
    }

    private async handlePremiumFinanceEvent(
        premiumFinanceEventInput: PremiumFinanceEventInput,
    ): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        if (premiumFinanceEventInput.success) {
            return this.googleAnalyticsRepository.sendCustomEvent('Premium Finance Completed', {
                company_id: activeSessionResult.value?.organizationId,
            });
        }

        return this.googleAnalyticsRepository.sendCustomEvent('Premium Finance Failed', {
            company_id: activeSessionResult.value?.organizationId,
        });
    }

    private async handlePolicyDocumentUploadedEvent(): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }

        return this.googleAnalyticsRepository.sendCustomEvent('Policy Document Uploaded', {
            company_id: activeSessionResult.value?.organizationId,
        });
    }

    private async handleReferredEvent(quoteEventInput: QuoteEventInput): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        const quoteAmount =
            quoteEventInput.totalPremium !== null
                ? CurrencyMarshaller.marshal(quoteEventInput.totalPremium)
                : null;
        return this.googleAnalyticsRepository.sendCustomEvent('Application Referred', {
            application_id: quoteEventInput.applicationId,
            is_renewal: quoteEventInput.isRenewal,
            company_id: activeSessionResult.value?.organizationId,
            quote_amount: quoteAmount,
            sku_string: quoteEventInput.sku?.sku_string,
            sku_string_plaintext: quoteEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleSubmittedForReviewEvent(
        event: ApplicationSubmittedForReview,
    ): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }

        return this.googleAnalyticsRepository.sendCustomEvent('Application submitted for review', {
            application_id: event.applicationId,
            is_renewal: event.isRenewal,
            company_id: activeSessionResult.value?.organizationId,
            sku_string: event.sku?.sku_string,
            sku_string_plaintext: event.sku?.sku_string_plaintext,
        });
    }

    private async handlePurchaseEvent(purchaseEventInput: QuoteEventInput) {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        const quoteAmount =
            purchaseEventInput.totalPremium !== null
                ? CurrencyMarshaller.marshal(purchaseEventInput.totalPremium)
                : null;
        this.googleAnalyticsRepository.sendCustomEvent('Confirm Purchase', {
            application_id: purchaseEventInput.applicationId,
            is_renewal: purchaseEventInput.isRenewal,
            is_streamline: purchaseEventInput.isStreamline,
            company_id: activeSessionResult.value?.organizationId,
            quote_amount: quoteAmount,
            sku_string: purchaseEventInput.sku?.sku_string,
            sku_string_plaintext: purchaseEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleBindCoverageEvent(bindCoverageEventInput: QuoteEventInput) {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        const quoteAmount =
            bindCoverageEventInput.totalPremium !== null
                ? CurrencyMarshaller.marshal(bindCoverageEventInput.totalPremium)
                : null;
        this.googleAnalyticsRepository.sendCustomEvent('Bind Coverage', {
            application_id: bindCoverageEventInput.applicationId,
            isRenewal: bindCoverageEventInput.isRenewal,
            isStreamline: bindCoverageEventInput.isStreamline,
            company_id: activeSessionResult.value?.organizationId,
            quote_amount: quoteAmount,
            sku_string: bindCoverageEventInput.sku?.sku_string,
            sku_string_plaintext: bindCoverageEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleOwnSelfServingCertificateCreated(certificateId: UUID) {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        this.googleAnalyticsRepository.sendCustomEvent('Coverage Summary Certificate Issued', {
            certificate_id: certificateId,
            company_id: activeSessionResult.value?.organizationId,
        });
    }

    private async handleThirdPartySelfServingCertificateCreated(
        selfServingCertificateCreatedEventInput: SelfServingCertificateCreatedEventInput,
    ) {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }

        this.googleAnalyticsRepository.sendCustomEvent('Third Party Certificate Issued', {
            certificate_id: selfServingCertificateCreatedEventInput.certificateId,
            company_id: activeSessionResult.value?.organizationId,
            holder_email_provided: selfServingCertificateCreatedEventInput.holderEmailProvided,
            includes_policy_customization:
                selfServingCertificateCreatedEventInput.includesPolicyCustomization,
            includes_provision_customization:
                selfServingCertificateCreatedEventInput.includesProvisionCustomization,
            ai_disabled: selfServingCertificateCreatedEventInput.includesProvisionCustomization
                ? selfServingCertificateCreatedEventInput.isAdditionalInsuredDisabled
                : undefined,
            wos_disabled: selfServingCertificateCreatedEventInput.includesProvisionCustomization
                ? selfServingCertificateCreatedEventInput.isWaiverOfSubrogationDisabled
                : undefined,
            pnc_disabled: selfServingCertificateCreatedEventInput.includesProvisionCustomization
                ? selfServingCertificateCreatedEventInput.isPrimaryNonContributoryDisabled
                : undefined,
            sku_string: selfServingCertificateCreatedEventInput.sku?.sku_string,
            sku_string_plaintext: selfServingCertificateCreatedEventInput.sku?.sku_string_plaintext,
        });
    }

    private async handleSelfServingCertificateRequestSubmitted(
        input: SelfServingCertificateRequestSubmittedEventInput,
    ) {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        this.googleAnalyticsRepository.sendCustomEvent('Certificate Request Submitted', {
            company_id: activeSessionResult.value?.organizationId,
            additional_wording_text: input.additionalWordingText,
            attached_pdf: input.isPdfAttached,
            sku_string: input.sku?.sku_string,
            sku_string_plaintext: input.sku?.sku_string_plaintext,
        });
    }

    private async handleSelfServingCertificateFlowEntered() {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        this.googleAnalyticsRepository.sendCustomEvent('Certificates Self-Served Flow Started', {
            company_id: activeSessionResult.value?.organizationId,
        });
    }

    private async handlePolicyCardClickedEvent(
        input: PolicyCardClickedEventInput,
    ): AsyncResult<void> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            container.get<Logger>(Log).error(activeSessionResult);
            return Failure(GetActiveSessionFailedError('Failed to get active session'));
        }
        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                GetActiveSessionFailedError('Detected null for activeSessionResult value'),
            );
        }
        if (activeSessionResult.value.organizationId === null) {
            return Failure(
                GetActiveSessionFailedError(
                    'Detected null for organizationId in activeSessionResult',
                ),
            );
        }

        return this.googleAnalyticsRepository.sendCustomEvent(input.eventName, {
            policy_number: input.policyNumber,
            company_id: activeSessionResult.value.organizationId,
            is_renewal: input.isRenewal,
        });
    }
}
