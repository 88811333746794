import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../../tasks/repositories';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationQuoteCreated } from '../../../shopping/entities/Application';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { BOPHartfordQuoteRepository } from '@app/quote/hartford-bop/repositories/BOPHartfordQuoteRepository';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface ReQuoteRequest {
    applicationId: UUID;
    effectiveDate: Date;
    bopCoverageType: string;
    bppLimit: Money;
    abortSignal: AbortSignal;
}

export interface ReQuote extends UseCase {
    execute(
        request: ReQuoteRequest,
    ): AsyncResult<BOPHartfordQuote, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class ReQuoteUseCase extends UseCase implements ReQuote {
    public static type = Symbol('BopHartford/ReQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BOPHartfordQuoteRepository) private repository: BOPHartfordQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        effectiveDate,
        bopCoverageType,
        bppLimit,
        abortSignal,
    }: ReQuoteRequest): AsyncResult<
        BOPHartfordQuote,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const maxPollingRetries = 120;
        const pollingRetryIntervalInMilliseconds = 1000;
        const reQuoteResult = await this.repository.reQuote(
            applicationId,
            effectiveDate,
            bopCoverageType,
            bppLimit,
        );

        if (isErr(reQuoteResult)) {
            return reQuoteResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            reQuoteResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentQuoteResult = await this.repository.getLatestQuote(applicationId);

        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        const quote = getCurrentQuoteResult.value;

        await this.calculateSku
            .execute({
                event: 'quote',
                applicationId: quote.applicationId,
            })
            .then((skuResult) => {
                const event: ApplicationQuoteCreated = {
                    origin: 'Application',
                    name: 'QuoteCreated',
                    createdAt: new Date(Date.now()),
                    applicationId,
                    totalPremium: quote.totalPayable,
                    id: UUID.create(),
                    sku: isOK(skuResult) ? skuResult.value : undefined,
                    isRenewal: false,
                };
                this.eventBus.publish(event);
            });

        return Success(quote);
    }
}

export const ReQuote: UseCaseClass<ReQuote> = ReQuoteUseCase;
