import { Text } from '@embroker/ui-toolkit/v2';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import React from 'react';
import { GetOrganizationProfile } from '../../../userOrg/useCases/GetOrganizationProfile';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { LandingPageLayout } from '../../../view/components/LandingPageLayout.view';
import { PublishUserOrgUserClickEvent } from '../../../userOrg/useCases/PublishUserOrgUserClickEvent';

type UserGuidancePageOnboardingPageProps = {
    isLoading: boolean;
    organizationId: UUID;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
};

export function UserGuidancePageOnboarding({
    isLoading,
    organizationId,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}: UserGuidancePageOnboardingPageProps) {
    const { result: getOrganizationProfileResult } = useUseCase(GetOrganizationProfile, {
        organizationId,
    });

    let companyLegalName;

    if (getOrganizationProfileResult !== undefined && isOK(getOrganizationProfileResult)) {
        companyLegalName = getOrganizationProfileResult.value.organization.companyLegalName;
    }

    const handlePrimaryButtonClick = async () => {
        execute(PublishUserOrgUserClickEvent, { clickEventName: 'Guided Flow CTA clicked' });
        onPrimaryButtonClick();
    };

    const handleSecondaryButtonClick = async () => {
        execute(PublishUserOrgUserClickEvent, { clickEventName: 'I know what i need CTA clicked' });
        onSecondaryButtonClick();
    };

    return (
        <LandingPageLayout
            isLoading={isLoading}
            title={`Embroker makes insurance radically simple for ${companyLegalName}`}
            primaryAction={{
                label: 'Create My Package',
                onClick: handlePrimaryButtonClick,
            }}
            secondaryAction={{
                label: 'I Already Know What I Need',
                onClick: handleSecondaryButtonClick,
            }}
            imageProps={{
                width: '75%',
                name: 'shotwell-1',
            }}
        >
            <Text style="body 1">
                Need help figuring out your coverage needs? Let us build an insurance package for
                you! We'll ask you some questions to get a sense of your company's risks and tailor
                an insurance package that's just right for your business.
            </Text>
        </LandingPageLayout>
    );
}
