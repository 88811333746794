import React, { useContext, useEffect } from 'react';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Button,
    CardLayout,
    FormLayout,
    GridLayout,
    Spinner,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetReviewRenewalData } from '@app/shopping/useCases/GetReviewRenewalData';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { URI } from '@embroker/shotwell/core/types/URI';
import { AppContext } from '@app/view/AppContext';
import { hasRole } from '@app/userOrg/entities/Session';
import { useNavigation } from '@app/view/hooks/useNavigation';
import { LPLAreasOfPractice } from '@app/shopping/types/enums';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';

type StreamlineConfirmationProps = {
    applicationId: UUID;
};

export function StreamlineConfirmation({ applicationId }: StreamlineConfirmationProps) {
    const { navigate } = useNavigation();
    const { result: reviewResult, isLoading } = useUseCase(GetReviewRenewalData, {
        applicationId: applicationId,
    });

    const { activeSession } = useContext(AppContext);
    const isDirect = !hasRole(activeSession, 'broker');

    useEffect(() => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Renewal Confirmation Page Load',
        });
    }, []);

    if (isLoading || reviewResult === undefined) {
        return <Spinner />;
    }

    if (isErr(reviewResult)) {
        return <ErrorPage errors={reviewResult.errors} />;
    }

    const data = reviewResult.value;

    const onSubmit = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'View My Renewal Quote clicked',
        });
        return navigate(
            URI.build('/shopping/submit-streamline-renewal', {
                applicationId: applicationId,
            }),
        );
    };

    const onReject = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Edit my Renewal Application clicked',
        });
        return navigate(
            URI.build('/shopping/opt-out-streamline-renewal', {
                applicationId: applicationId,
            }),
        );
    };

    const title = isDirect
        ? `Just one more step. Let's do a quick review`
        : `Almost there. Let's check your client's details.`;

    const subTitle = isDirect
        ? `Below are your responses from last year's Application. Please review and confirm the information is correct.`
        : `Below are your client's responses from last year's Application. Please review and confirm the information is correct.`;

    const confirmationQuestion = isDirect
        ? `Yes, everything is the same.`
        : `Yes, I have confirmed with my client that everything is the same.`;

    const yesButtonWording = isDirect ? `View My Renewal Quote` : `View Renewal Quote`;
    const editButtonWording = isDirect ? `Edit My Renewal Application` : `Edit Renewal Application`;

    const copyMap = isDirect ? questionToCopyDirectMap : questionToCopyAccess;

    return (
        <FormLayout>
            <StackLayout center gap="16">
                <Text style="heading 2">{title}</Text>
                <Text>{subTitle}</Text>

                <CardLayout radius="24" className="u-1/1">
                    {/* className instead of backgroundColor is a workaround since violet is not supported as */}
                    <CardLayout.Header className="c-page-layout__banner--background-color-violet-50">
                        <Text style="heading 5">Company locations</Text>
                    </CardLayout.Header>
                    <CardLayout.Body>
                        <ol>
                            <li style={{ fontWeight: 700 }}>
                                {copyMap.get('location')?.()}
                                {data.locations.location.map((location, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div style={{ marginTop: '1rem' }}>
                                                <Text style="body 2">
                                                    <b>Address:</b>
                                                </Text>
                                                <Text>{location.mailing_address}</Text>
                                                <Text>{location.suite}</Text>
                                                <Text>
                                                    {location.city}, {location.state} {location.zip}
                                                </Text>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </li>
                        </ol>
                    </CardLayout.Body>
                </CardLayout>

                <CardLayout radius="24" className="u-1/1">
                    <CardLayout.Header className="c-page-layout__banner--background-color-violet-50">
                        <Text style="heading 5">Professional staff</Text>
                    </CardLayout.Header>
                    <CardLayout.Body>
                        <StackLayout color="dark">
                            <ol>
                                <li style={{ fontWeight: 700 }}>
                                    <div>{copyMap.get('staff')?.()}</div>
                                    <GridLayout>
                                        <Text className="u-grid-size-3" style={'fontWeight: 700'}>
                                            Last year's roster:
                                        </Text>
                                        <Text className="u-grid-size-3" style={'fontWeight: 700'}>
                                            Hours worked per week:
                                        </Text>
                                    </GridLayout>
                                    {data.attorneyRoster.list.map((attorney, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <GridLayout>
                                                    <Text className="u-grid-size-3">
                                                        {attorney.name}
                                                    </Text>
                                                    <Text className="u-grid-size-3">
                                                        {hoursWorkedTitle.get(
                                                            attorney.averageWeeklyHours,
                                                        )}{' '}
                                                        hrs
                                                    </Text>
                                                </GridLayout>
                                            </React.Fragment>
                                        );
                                    })}
                                </li>
                            </ol>
                        </StackLayout>
                    </CardLayout.Body>
                </CardLayout>

                <CardLayout radius="24" className="u-1/1">
                    <CardLayout.Header className="c-page-layout__banner--background-color-violet-50">
                        <Text style="heading 5">Areas of practice</Text>
                    </CardLayout.Header>
                    <CardLayout.Body>
                        <StackLayout gap="16">
                            <ol>
                                <li style={{ fontWeight: 700 }}>
                                    <div>{copyMap.get('AOP')?.()}</div>
                                    <GridLayout style={{ marginTop: '1.5rem' }}>
                                        <Text className="u-grid-size-3" style={'fontWeight: 700'}>
                                            Areas of practice:
                                        </Text>
                                        <Text className="u-grid-size-3" style={'fontWeight: 700'}>
                                            Percentage:
                                        </Text>
                                    </GridLayout>
                                    {data.areasOfPractice.map((aop, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <GridLayout>
                                                    <Text className="u-grid-size-3">
                                                        {
                                                            LPLAreasOfPractice.find(
                                                                (item) =>
                                                                    item.value ===
                                                                    aop.areaOfPracticeCode,
                                                            )?.title
                                                        }
                                                    </Text>
                                                    <Text className="u-grid-size-3">
                                                        {aop.percentage} %
                                                    </Text>
                                                </GridLayout>
                                            </React.Fragment>
                                        );
                                    })}
                                </li>
                                <li style={{ fontWeight: 700, marginTop: '1.5rem' }}>
                                    {copyMap.get('currentLitigation')?.()}
                                </li>
                                <li style={{ fontWeight: 700, marginTop: '1.5rem' }}>
                                    {copyMap.get('cannabis')?.()}
                                </li>
                            </ol>
                        </StackLayout>
                    </CardLayout.Body>
                </CardLayout>

                <CardLayout radius="24" className="u-1/1">
                    <CardLayout.Header className="c-page-layout__banner--background-color-violet-50">
                        <Text style="heading 5">Claims</Text>
                    </CardLayout.Header>
                    <CardLayout.Body>
                        <Text color="ui-500">
                            <b>In the past year:</b>
                        </Text>
                        <ol>
                            <li style={{ fontWeight: 700, marginTop: '1.5rem' }}>
                                <div style={{ marginTop: '16px' }}>
                                    {copyMap.get('attorneyOffences')?.()}
                                </div>
                                <li>
                                    <div>{copyMap.get('claims')?.()}</div>
                                </li>
                                <li>
                                    <div>{copyMap.get('awarenessOfIssues')?.()}</div>
                                </li>
                            </li>
                        </ol>
                    </CardLayout.Body>
                </CardLayout>
                <StackLayout className="u-align-start">
                    <Text>Is the above information correct?</Text>
                    <Text>{confirmationQuestion}</Text>
                    <Button onClick={onSubmit}>{yesButtonWording}</Button>
                    <Text>No, I need to update some details.</Text>
                    <Button
                        appearance="secondary"
                        size="large"
                        className="u-1/1"
                        onClick={onReject}
                    >
                        {editButtonWording}
                    </Button>
                </StackLayout>
            </StackLayout>
        </FormLayout>
    );
}

const questionToCopyDirectMap = new Map<string, () => JSX.Element>([
    [
        'location',
        () => (
            <Text color="ui-500">
                <b>Your business address is the same </b>
                <Text as="span" color="ui-400">
                    as last year.
                </Text>
            </Text>
        ),
    ],
    [
        'staff',
        () => (
            <Text color="ui-500">
                <b>Your current attorney roster is as shown below:</b>
            </Text>
        ),
    ],
    [
        'AOP',
        () => (
            <Text color="ui-500">
                <b>Your Areas of Practice are the same</b> as noted below and have not changed by
                more than +/-5%.
            </Text>
        ),
    ],
    [
        'currentLitigation',
        () => (
            <Text color="ui-500">
                <b>No attorney in the firm</b> has handled or is currently handling any class
                action/mass tort litigation.
            </Text>
        ),
    ],
    [
        'cannabis',
        () => (
            <Text color="ui-500">
                <b>No attorney in the firm provides services or legal counsel</b> to clients,
                individuals, or businesses involved in the growing, storage, testing, handling,
                manufacturing, packaging, dispensing, distribution, transportation or sale of
                cannabis and/or products containing cannabis.
            </Text>
        ),
    ],
    [
        'attorneyOffences',
        () => (
            <Text color="ui-500">
                <b>No attorney has been the subject of</b> a regulatory investigation or
                disciplinary inquiry, bar complaint, bar grievance, denied the right to practice,
                suspended from practice, disbarred, reprimanded, or had any other disciplinary
                action by any court or administrative agency.
            </Text>
        ),
    ],
    [
        'claims',
        () => (
            <Text color="ui-500">
                <b>No claims or incidents</b> have been alleged or otherwise active against
                attorneys in the firm (past and present).
            </Text>
        ),
    ],
    [
        'awarenessOfIssues',
        () => (
            <Text color="ui-500">
                <b>No member of the firm</b> are aware of any incident, act, error or omission that
                may result in a claim or disciplinary action being brought against the firm.
            </Text>
        ),
    ],
]);

const questionToCopyAccess = new Map<string, () => JSX.Element>([
    [
        'location',
        () => (
            <Text>
                <b>The Insured's business address is the same</b> as last year.
            </Text>
        ),
    ],
    [
        'staff',
        () => (
            <Text>
                <b>The Insured's attorney roster is as shown below:</b>
            </Text>
        ),
    ],
    [
        'AOP',
        () => (
            <Text>
                <b>Their Areas of Practice are the same</b> as noted below and have not changed by
                more than +/-5%.
            </Text>
        ),
    ],
    [
        'currentLitigation',
        () => (
            <Text>
                <b>No attorney in the firm</b> has handled or is currently handling any class
                action/mass tort litigation.
            </Text>
        ),
    ],
    [
        'cannabis',
        () => (
            <Text>
                <b>No attorney in the firm provides services or legal counsel</b> to clients,
                individuals, or businesses involved in the growing, storage, testing, handling,
                manufacturing, packaging, dispensing, distribution, transportation or sale of
                cannabis and/or products containing cannabis.
            </Text>
        ),
    ],
    [
        'attorneyOffences',
        () => (
            <Text>
                <b>No attorney has been the subject of</b> a regulatory investigation or
                disciplinary inquiry, bar complaint, bar grievance, denied the right to practice,
                suspended from practice, disbarred, reprimanded, or had any other disciplinary
                action by any court or administrative agency.
            </Text>
        ),
    ],
    [
        'claims',
        () => (
            <Text>
                <b>No claims or incidents</b> have been alleged or otherwise active against
                attorneys in the firm (past and present).
            </Text>
        ),
    ],
    [
        'awarenessOfIssues',
        () => (
            <Text>
                <b>No member of the firm</b> are aware of any incident, act, error or omission that
                may result in a claim or disciplinary action being brought against the firm.
            </Text>
        ),
    ],
]);

const hoursWorkedTitle = new Map<number, string>([
    [0, '<9'],
    [10, '10-20'],
    [21, '21+'],
]);
