import { StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import FAQLayout from './FAQLayout.view';
import { SupportPageLayout } from './SupportPageLayout.view';

export function FAQEmbroker() {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    return (
        <SupportPageLayout>
            <StackLayout gap="48">
                <div />
                <FAQLayout isBroker={isBroker} onNavigate={navigate} />
            </StackLayout>
        </SupportPageLayout>
    );

    return;
}
