import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProductSelectionPageProps } from './SignupOnboardingWrapper';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    GetLawBundleApplications,
    UnderlyingApp,
} from '@app/shopping/useCases/GetLawBundleApplications';
import { CoverageRecommendationLayout } from '@app/shopping/view/components/lawBundle/CoverageRecommendationLayout.view';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { PageLayout, StackLayout, ButtonBar, TextButton, Button } from '@embroker/ui-toolkit/v2';
import { CoverageType } from '@app/shopping/types/CoverageType';
import {
    LPLCoverageRecommendationCard,
    CyberCoverageRecommendationCard,
    BOPCoverageRecommendationCard,
    WCCoverageRecommendationCard,
} from '@app/shopping/view/components/lawBundle/CoverageRecommendationLayoutCardTemplates';
import { AppContext } from '@app/view/AppContext';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import { CoverageInformationSlideout } from '@app/shopping/view/components/lawBundle/CoverageInformationSlideout.view';
import { AppTypeCode } from '@app/shopping/types/enums';

type AppTypesProps = Record<CoverageType, AppTypeCode>;
const AppTypes: AppTypesProps = {
    bop: 'AppTypeCodeListBOPChubb',
    cyber: 'AppTypeCodeListCyberCowbell',
    lpl: 'AppTypeCodeListEverestLawyersProfessionalLiability',
    wc: 'AppTypeCodeListWCChubb',
};

export function LawVerticalProductSelection({
    organization,
    onBackClicked,
    onContinueClicked,
}: ProductSelectionPageProps) {
    const { result: getLawBundleApplicationsUnauthenticatedResult } =
        useUseCase(GetLawBundleApplications);

    const { setSlideout } = useContext(AppContext);

    const [radioItems, setRadioItems] = useState<UnderlyingApp[]>([]);

    useEffect(() => {
        if (
            getLawBundleApplicationsUnauthenticatedResult &&
            isOK(getLawBundleApplicationsUnauthenticatedResult)
        ) {
            setRadioItems(
                getLawBundleApplicationsUnauthenticatedResult.value
                    .underlyingApps as UnderlyingApp[],
            );
        }
    }, [getLawBundleApplicationsUnauthenticatedResult]);

    const changeApplicationSelection = (value: string) => {
        setRadioItems(
            radioItems.map((item) => ({
                ...item,
                isSelected: item.value === value ? !item.isSelected : item.isSelected,
            })),
        );
    };

    const selectedCoverages = radioItems
        .filter((item) => item.isSelected)
        .map((item) => item.value);

    const handleNextClicked = () => {
        onContinueClicked({ applicationType: 'AppTypeCodeListLawBundle', selectedCoverages });
    };

    const isAllRadioItemsNotSelected = useMemo(
        () => radioItems.reduce((acc, item) => acc && item.isSelected === false, true),
        [radioItems],
    );

    const lawVertical2v4ProductsIsVariant = useMemo(
        () =>
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('law-vertical-product-page-2-vs-4-products', false),
        [],
    );

    const lplCoverage = radioItems.find((item) => item.value === CoverageType.LPL);
    const cyberCoverage = radioItems.find((item) => item.value === CoverageType.Cyber);
    const bopCoverage = radioItems.find((item) => item.value === CoverageType.BOP);
    const wcCoverage = radioItems.find((item) => item.value === CoverageType.WC);

    const isBopIneligible = Boolean(bopCoverage?.ineligibilityReasons?.length);
    const isWcEssential =
        wcCoverage?.ineligibilityReasons?.length === 0 &&
        wcCoverage?.onboardingQuestionnaire?.has_employees;

    const bobCard = bopCoverage && (
        <BOPCoverageRecommendationCard
            ineligibilityReasons={bopCoverage.ineligibilityReasons}
            onboardingQuestionnaire={bopCoverage.onboardingQuestionnaire}
            isSelected={bopCoverage.isSelected}
            handleTooltipClick={() => onCoverageShowSlideout(CoverageType.BOP, bopCoverage)}
            onCoverageSelectionChange={() => changeApplicationSelection(CoverageType.BOP)}
        />
    );
    const wcCard = wcCoverage && (
        <WCCoverageRecommendationCard
            ineligibilityReasons={wcCoverage.ineligibilityReasons}
            onboardingQuestionnaire={wcCoverage.onboardingQuestionnaire}
            isSelected={wcCoverage.isSelected}
            handleTooltipClick={() => onCoverageShowSlideout(CoverageType.WC, wcCoverage)}
            onCoverageSelectionChange={() => changeApplicationSelection(CoverageType.WC)}
        />
    );

    const onCoverageShowSlideout = (coverageType: CoverageType, coverage: UnderlyingApp) => {
        setSlideout(
            <CoverageInformationSlideout
                appTypeCode={AppTypes[coverageType]}
                naicsCode={organization.naics ?? undefined}
                onClose={() => setSlideout(null)}
                isSelected={coverage.isSelected}
                onCoverageSelect={() => {
                    changeApplicationSelection(coverageType);
                    setSlideout(null);
                }}
            />,
        );
    };

    return (
        <React.Fragment>
            <CoverageRecommendationLayout isAllProductsNotSelected={isAllRadioItemsNotSelected}>
                <React.Fragment>
                    {lplCoverage && (
                        <LPLCoverageRecommendationCard
                            isSelected={lplCoverage.isSelected}
                            handleTooltipClick={() =>
                                onCoverageShowSlideout(CoverageType.LPL, lplCoverage)
                            }
                            onCoverageSelectionChange={() =>
                                changeApplicationSelection(CoverageType.LPL)
                            }
                        />
                    )}
                    {cyberCoverage && (
                        <CyberCoverageRecommendationCard
                            isSelected={cyberCoverage.isSelected}
                            handleTooltipClick={() =>
                                onCoverageShowSlideout(CoverageType.Cyber, cyberCoverage)
                            }
                            onCoverageSelectionChange={() =>
                                changeApplicationSelection(CoverageType.Cyber)
                            }
                        />
                    )}
                    {!lawVertical2v4ProductsIsVariant ? (
                        isBopIneligible || isWcEssential ? (
                            <React.Fragment>
                                {wcCard} {bobCard}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {bobCard} {wcCard}
                            </React.Fragment>
                        )
                    ) : undefined}
                </React.Fragment>
            </CoverageRecommendationLayout>
            <PageLayout.Section>
                <StackLayout gap="48">
                    <ButtonBar
                        split={'-1'}
                        reverse
                        responsive={{ containerWidth: { smallerThan: 400 } }}
                    >
                        <TextButton onClick={onBackClicked} data-e2e="location-step-back">
                            Back
                        </TextButton>
                        <Button
                            onClick={handleNextClicked}
                            data-e2e="location-step-submit"
                            disabled={selectedCoverages.length === 0}
                        >
                            Next
                        </Button>
                    </ButtonBar>
                </StackLayout>
            </PageLayout.Section>
        </React.Fragment>
    );
}
