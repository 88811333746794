import { Nullable } from '@embroker/shotwell/core/types';
import { Applicant } from '../../../shopping/entities/Applicant';
import { AppTypeCode } from '../../../shopping/types/enums';
import { NAICS_CODE_TO_VERTICAL, Vertical } from '../../../userOrg/types/enums';
import {
    getCoverageDetails,
    CoverageDetails,
    coverageCardTitleMap,
    coverageProgramIconMap,
} from '../../../shopping/view/components/coverageDetails';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { BundleDetails } from '../../../shopping/types/BundleDetails';
import { AppDetails } from '../../../shopping/types/AppDetails';

interface PolicyDetails {
    icon: CoverageDetails['icon'];
    estimatedTime: CoverageDetails['estimatedTime'];
    title: React.ReactNode;
    description: React.ReactNode;
}

export const getAppTypeCodeFromBundleDetails = (
    bundleDetails?: BundleDetails,
): AppTypeCode | undefined => {
    const bundleAppType = AppDetails.getBundleAppType(bundleDetails?.appDetails);
    const underlyingApps = AppDetails.getAppTypes(bundleDetails?.appDetails, {
        creationType: 'InsuranceApplicationCreationTypeCodeListUnderlying',
    });

    if (bundleAppType === 'AppTypeCodeListMPLBundle') {
        return underlyingApps?.length === 1 ? underlyingApps[0] : 'AppTypeCodeListMPLBundle';
    } else if (bundleAppType === 'AppTypeCodeListGenericBundle') {
        return underlyingApps?.length === 1 ? underlyingApps[0] : 'AppTypeCodeListGenericBundle';
    }
};

export function getPolicyDetails(
    appTypeCode: AppTypeCode,
    appQuestionnaireNaicsCode?: string,
    applicant?: Nullable<EntityProps<Applicant>>,
    isRenewal?: boolean,
): PolicyDetails {
    const naicsCode = appQuestionnaireNaicsCode ?? applicant?.naicsIndustry;
    const vertical = naicsCode ? NAICS_CODE_TO_VERTICAL[naicsCode] : undefined;

    const {
        icon,
        title: coverageTitle,
        description: coverageDescription,
        estimatedTime,
    } = getCoverageDetails(appTypeCode, naicsCode, undefined, isRenewal);

    const policyIcon = vertical && coverageProgramIconMap?.get(appTypeCode)?.get(vertical);
    const policyProgramTitle =
        vertical && applicationProgramCardTitleMap.get(appTypeCode)?.get(vertical);
    const policyTitle = applicationCardTitleMap.get(appTypeCode);

    const applicationProgramDescription =
        vertical && applicationProgramCardDescriptionMap.get(appTypeCode)?.get(vertical);
    const applicationDescription = applicationCardDescriptionMap.get(appTypeCode);

    return {
        title: policyProgramTitle || policyTitle || coverageTitle,
        description: applicationProgramDescription || applicationDescription || coverageDescription,
        estimatedTime,
        icon: policyIcon || icon,
    };
}

const applicationCardTitleMap = new Map<AppTypeCode, string>([
    ...coverageCardTitleMap,
    ['AppTypeCodeListMPLBundle', 'Miscellaneous Professional Liability Package'],
]);

const applicationProgramCardTitleMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListMPLBundle',
        new Map([
            ['Accounting', 'Accountants Package'],
            ['TaxPreparation', 'Tax Preparers & Bookkeepers Package'],
            ['RealEstateAgent', 'Real Estate Agents Package'],
            ['HomeInspectorAndBuildingInspectionServices', 'Home Inspectors Package'],
            ['NonTechnologyConsultants', 'Non-Technology Consultants Package'],
        ]),
    ],
    [
        'AppTypeCodeListMPL',
        new Map([
            ['Accounting', 'Accountants Professional Liability'],
            ['TaxPreparation', 'Tax Preparers & Bookkeepers Professional Liability'],
            ['RealEstateAgent', 'Real Estate Agent Professional Liability'],
            [
                'HomeInspectorAndBuildingInspectionServices',
                'Home Inspectors Professional Liability',
            ],
            ['NonTechnologyConsultants', 'Non-Technology Consultants Professional Liability'],
        ]),
    ],
]);

const applicationCardDescriptionMap = new Map<AppTypeCode, string>([
    [
        'AppTypeCodeListMPLBundle',
        `Protect you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`,
    ],
]);

const applicationProgramCardDescriptionMap = new Map<AppTypeCode, Map<Vertical, string>>([
    [
        'AppTypeCodeListMPLBundle',
        new Map([
            [
                'Accounting',
                `Protect your accounting business from all angles with comprehensive coverage — whether it's an employee error, data breach, or natural disaster, we've got you covered!`,
            ],
            [
                'TaxPreparation',
                `Protect your tax preparation business from all angles with comprehensive coverage — whether it's an employee error, data breach, or natural disaster, we've got you covered!`,
            ],
            [
                'RealEstateAgent',
                `Protect your real estate business from all angles with comprehensive coverage — whether it's an employee error, data breach, or natural disaster, we've got you covered!`,
            ],
            [
                'HomeInspectorAndBuildingInspectionServices',
                `Protect your home inspection business from all angles with comprehensive coverage — whether it's an employee error, data breach, or natural disaster, we've got you covered!`,
            ],
            [
                'NonTechnologyConsultants',
                `Protect your non-technology business and management consulting company from all angles — whether it's an error, data breach, or natural disaster, we've got you covered.`,
            ],
        ]),
    ],
]);
