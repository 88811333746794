import {
    CompanyDimension,
    RiskProfileSerializedKeys,
    RiskProfileKeys,
} from '@app/public/types/enums';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';

export interface RiskProfileSerialized {
    [RiskProfileSerializedKeys.AreaOfFocus]: string;
    [RiskProfileSerializedKeys.CompanyDimensions]: string;
    [RiskProfileSerializedKeys.NaicsCode]: string;
    [RiskProfileSerializedKeys.NumberOfEmployees]: string;
    [RiskProfileSerializedKeys.TechnologyServicesOrProducts]: string;
}

export interface RiskProfile {
    [RiskProfileKeys.NaicsCode]: string;
    [RiskProfileKeys.AreaOfFocus]: string;
    [RiskProfileKeys.TechnologyServicesOrProducts]: boolean;
    [RiskProfileKeys.NumberOfEmployees]: number;
    [RiskProfileKeys.CompanyDimensions]: CompanyDimension[];
}

const TECH_VERTICAL_NAICS_CODES = Object.keys(NAICS_CODE_TO_VERTICAL).reduce((acc, naicsCode) => {
    if (NAICS_CODE_TO_VERTICAL[naicsCode] === 'TechCompanies') {
        acc.push(naicsCode);
    }

    return acc;
}, [] as string[]);

export const RiskProfile = {
    ...defineValidator<RiskProfile>({
        naics: Joi.string(),
        areaOfFocus: Joi.string(),
        technologyServicesOrProducts: Joi.boolean(),
        numberOfEmployees: Joi.number(),
        companyDimensions: Joi.array().items(Joi.string()),
    }),
    deserialize(riskProfilePayload: RiskProfileSerialized): RiskProfile {
        return {
            naics: riskProfilePayload.naics_code,
            areaOfFocus: riskProfilePayload.area_of_focus,
            technologyServicesOrProducts:
                riskProfilePayload.technology_services_or_products === 'true',
            numberOfEmployees: parseInt(riskProfilePayload.number_of_employees),
            companyDimensions:
                (riskProfilePayload.company_dimensions?.split(',') as CompanyDimension[]) ?? [],
        };
    },
    serialize(riskProfile: RiskProfile): RiskProfileSerialized {
        return {
            naics_code: riskProfile?.naics,
            area_of_focus: riskProfile?.areaOfFocus,
            technology_services_or_products:
                riskProfile?.technologyServicesOrProducts?.toString() ?? '',
            number_of_employees: riskProfile?.numberOfEmployees?.toString() ?? '',
            company_dimensions: riskProfile?.companyDimensions?.join(',') ?? '',
        };
    },
    isFunded(riskProfile: RiskProfile): boolean {
        return riskProfile.companyDimensions.includes(CompanyDimension.RaisedFunds);
    },
    isValidTechnologyNaics(naics: string): boolean {
        return TECH_VERTICAL_NAICS_CODES.includes(naics);
    },
};
