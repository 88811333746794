import React, { useRef } from 'react';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { CenterLayout, Spinner, Text, WizardLayout } from '@embroker/ui-toolkit/v2';
import { navigateToErrorPage } from '../../../view/errors';
import { execute } from '@embroker/shotwell/core/UseCase';
import { SaveQuestionerAnswers } from '@app/shoppingQuestioner/useCases/SaveQuestionerAnswers';
import {
    QuestionerRenderer,
    QuestionerRendererRefrence,
} from '@app/shoppingQuestioner/view/components/QuestionerRenderer';
import {
    GetQuestionerForm,
    GetQuestionerFormResponse,
} from '@app/shoppingQuestioner/useCases/GetQuestionerForm';
import { getLastPageAnswers } from '@app/shoppingQuestioner/view/components/QuestionerApplicationQuestionnaire';

interface OptimalTestQuestionnaireProps {
    questionnaireIds: string[];
}
export function OptimalTestQuestionnaire({ questionnaireIds }: OptimalTestQuestionnaireProps) {
    const { navigate } = useNavigation();

    const questionerRendererRef = useRef<QuestionerRendererRefrence>(null);

    const getQuestionerFormResp = useUseCase(GetQuestionerForm, { questionnaireIds });
    const { isLoading, result: getQuestionerFormResult } = getQuestionerFormResp;

    if (isLoading || !getQuestionerFormResult) {
        return <Spinner />;
    }

    const handleSaveAndExit = () => {
        navigate('/');
    };

    if (
        questionnaireIds.length === 0 ||
        (getQuestionerFormResult && isErr(getQuestionerFormResult)) ||
        getQuestionerFormResult.value.questionerQuestions.length === 0
    ) {
        return (
            <WizardLayout
                progress={0}
                title="Application Questionnaire"
                onDismiss={handleSaveAndExit}
                dismissAppearance="save-and-exit"
            >
                <CenterLayout>
                    <Text style="heading 1"> No questionnaire to display </Text>
                </CenterLayout>
            </WizardLayout>
        );
    }

    const {
        questionerPageDefinitions,
        formQuestionDefinitions,
        questionerQuestions,
        requestQuestionnaireIds,
    } = getQuestionerFormResult.value as GetQuestionerFormResponse;

    const handleFormSubmit = async (formData: { [key: string]: unknown }, activePage: string) => {
        const lastPageFormData = getLastPageAnswers(formData, questionerPageDefinitions);
        const saveAnswersResult = await execute(SaveQuestionerAnswers, {
            formData: lastPageFormData,
            questionerQuestions,
            completedPageKeys: [activePage],
            questionnaireIds: requestQuestionnaireIds,
        });
        if (isErr(saveAnswersResult)) {
            navigateToErrorPage(navigate, saveAnswersResult.errors);
        }
    };

    const handlePageComplete = async (formData: { [key: string]: unknown }, activePage: string) => {
        const saveAnswersResult = await execute(SaveQuestionerAnswers, {
            formData,
            questionerQuestions,
            completedPageKeys: [activePage],
            questionnaireIds: requestQuestionnaireIds,
        });
        if (isErr(saveAnswersResult)) {
            navigateToErrorPage(navigate, saveAnswersResult.errors);
            return;
        }

        const questionerQuestionsResult = await execute(GetQuestionerForm, { questionnaireIds });
        if (isErr(questionerQuestionsResult)) {
            navigateToErrorPage(navigate, questionerQuestionsResult.errors);
            return;
        }

        const { formQuestionDefinitions: updatedFormQuestions } =
            questionerQuestionsResult.value as GetQuestionerFormResponse;
        const formValues = updatedFormQuestions.reduce((acc, question) => {
            if (question.initialValue !== undefined) {
                acc[question.key] = question.initialValue;
            }
            return acc;
        }, {} as { [key: string]: unknown });

        questionerRendererRef.current?.setUnansweredFormValues(formValues);
    };

    const handleCallback = () => {
        // Test questionnaires are not linked to an appliction so nothing to do here
    };

    return (
        <QuestionerRenderer
            pages={questionerPageDefinitions}
            questions={formQuestionDefinitions}
            ref={questionerRendererRef}
            onFormSubmit={handleFormSubmit}
            onSaveAndExit={handleSaveAndExit}
            onPageComplete={handlePageComplete}
            onPageShow={handleCallback}
            onFirstIncompletePageChange={handleCallback}
        />
    );
}
