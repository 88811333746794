import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKUFromAppTypes } from '@app/analytics/useCases/CalculateSKUFromAppTypes';
import { PolicyFilterRepository } from '@app/policy/repositories/PolicyFilterRepository';
import { Coverage } from '@app/shopping/types/Coverage';
import { AppTypeCode, NumberRangeOfW2Employees } from '@app/shopping/types/enums';
import { Organization } from '../entities/Organization';
import { User } from '../entities/User';
import { EmailAlreadyInUse, InvalidEmail, MaxNumberOfSignUpAttemptsExceeded } from '../errors';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { UserRepository } from '../repositories/UserRepository';
import { MailingAddress } from '../types/MailingAddress';

/**
 * Request data for SignUp use case
 * @param firstName is the user's first name
 * @param lastName is the user's last name
 * @param emailAddress is the user's email address. This email address will also be stored in user's organization
 * @param password is user's password
 * @param organizationName is the name of user's organization
 * @param howYouHearAboutEm Optional description how customer got in touch with Embroker
 */
export interface SignUpRequest {
    firstName?: string;
    lastName?: string;
    emailAddress: EmailAddress;
    password?: string;
    organizationName: string;
    howYouHearAboutEm?: string;
    signUpInviteToken: Nullable<string>;
    certificateInviteToken: Nullable<string>;
    selectedCoverages?: Coverage[];
    website?: string;
    naicsCode?: string;
    hasReceivedVCFunding?: boolean;
    isTotalRevenueLargerThan20MillionDollars?: boolean;
    numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    hasAutomobiles?: boolean;
    headquarters?: MailingAddress;
}

/**
 * Response data for SignUp use case
 * @param user is the new User entity created on the platform
 * @param organization is the new Organization entity created on the platform
 */
interface SignUpResponse {
    user: User;
    organization: Organization;
}

/**
 * Request data for private method createOrganizationEntity of class SignUp
 * @param id is the organization's id
 * @param emailAddress is the organization's email address
 * @param organizationName is the organization's name
 */
interface CreateOrganizationEntityRequest {
    id: UUID;
    emailAddress: EmailAddress;
    organizationName: string;
}

/**
 * Request data for private method createUserEntity of class SignUp
 * @param firstName is the user's first name
 * @param lastName is the user's last name
 * @param emailAddress is the user's email address
 * @param phoneNumber is the user's phone number
 * @param password is the user's password
 * @param signUpInviteToken is the user's invitation token
 */
interface SaveUserRequest {
    firstName?: string;
    lastName?: string;
    emailAddress: EmailAddress;
    phoneNumber?: PhoneNumber;
    password?: string;
    signUpInviteToken: Nullable<string>;
    certificateInviteToken: Nullable<string>;
}

/**
 * SignUp use case is used to add new user and organization to our platform
 * After successful execution new user and organization for that user will be created
 */

export interface SignUp extends UseCase {
    execute(
        request: SignUpRequest,
    ): AsyncResult<
        SignUpResponse,
        | OperationFailed
        | InvalidArgument
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    >;
}

@injectable()
class SignUpUseCase extends UseCase implements SignUp {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/SignUp');
    /**
     * Constructor for SignUp use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepository is used to store new organization entity created.
     * New organization entity will also store the user as the organization's owner
     * @param userRepository is used to store new user entity created
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
        @inject(UserRepository) private userRepository: UserRepository,
        @inject(PolicyFilterRepository) private policyFilterRepository: PolicyFilterRepository,
        @inject(CalculateSKUFromAppTypes.type)
        private calculateSKUFromAppTypes: CalculateSKUFromAppTypes,
    ) {
        super(eventBus);
    }

    /**
     * Executed SignUp use case
     * Input is of type SignUpRequest
     * @returns data of type SignUpResponse after successful execution
     * @returns InvalidArgument error if either user of organization entities could not be stored in the repository
     * @returns EmailAlreadyInUse error if organization or user with provided email address already exists on platform
     */
    public async execute({
        firstName,
        lastName,
        emailAddress,
        password,
        organizationName,
        howYouHearAboutEm,
        signUpInviteToken,
        certificateInviteToken,
        selectedCoverages,
        website,
        naicsCode,
        hasReceivedVCFunding,
        isTotalRevenueLargerThan20MillionDollars,
        numberRangeOfW2Employees,
        hasAutomobiles,
        headquarters,
    }: SignUpRequest): AsyncResult<
        SignUpResponse,
        | OperationFailed
        | InvalidArgument
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    > {
        signUpInviteToken = signUpInviteToken === undefined ? null : signUpInviteToken;
        certificateInviteToken =
            certificateInviteToken === undefined ? null : certificateInviteToken;

        // create user entity
        const userResult = await this.createUserEntity({
            firstName,
            lastName,
            emailAddress,
            password,
            signUpInviteToken: signUpInviteToken,
            certificateInviteToken: certificateInviteToken,
        } as SaveUserRequest);
        if (isErr(userResult)) {
            return userResult;
        }
        const userEntity = userResult.value;

        // Sign up user and store his entity
        const userRepoResult = await this.userRepository.signUp({
            user: userEntity,
            organizationName,
            howDidYouHearAboutEmbroker: howYouHearAboutEm,
            hasAutomobiles,
            hasReceivedVCFunding,
            isTotalRevenueLargerThan20MillionDollars,
            naicsCode,
            numberRangeOfW2Employees,
            website,
            headquarters,
        });
        if (isErr(userRepoResult)) {
            return userRepoResult;
        }
        const user = userRepoResult.value.user;

        // create organization entity
        const organizationResult = await this.createOrganizationEntity({
            id: userRepoResult.value.organizationId,
            emailAddress,
            organizationName,
        } as CreateOrganizationEntityRequest);
        if (isErr(organizationResult)) {
            return organizationResult;
        }
        const organizationEntity = organizationResult.value;

        // save user roles in organization entity
        organizationEntity.addUser({
            role: 'owner',
            userId: userResult.value.id,
        });
        let selectedAppTypes: AppTypeCode[] = [];
        if (selectedCoverages) {
            selectedAppTypes = selectedCoverages.map((coverage) => coverage.appType);
        }

        const skuResult = await this.calculateSKUFromAppTypes.execute({
            appTypeList: selectedAppTypes,
        });

        // inject organization data inside user signUp event
        user.signUp(organizationEntity, howYouHearAboutEm ?? null, skuResult.value);
        // retain org events in user entity
        user.retainEventsOf(organizationEntity);
        // Store organization in repo
        const organizationSaveResult = await this.organizationRepository.save(
            organizationEntity as Organization,
        );
        if (isErr(organizationSaveResult)) {
            return handleOperationFailure(organizationSaveResult);
        }

        const organization = organizationSaveResult.value;

        // reset policy filter to default
        const defaultFilter = this.policyFilterRepository.getDefault();
        this.policyFilterRepository.save(defaultFilter);

        // publish event from user entity (along with retained event from organization)
        await this.eventBus.publishEntityEvents(user);

        // send response
        return Success({
            user: userResult.value,
            organization: organization,
        });
    }

    /**
     * Creates organization entity
     * Input if of type CreateOrganizationEntityRequest
     * @returns organization entity store in the repository
     * @returns InvalidArgument error if organization entity contained an invalid property
     */
    private async createOrganizationEntity({
        id,
        emailAddress,
        organizationName,
    }: CreateOrganizationEntityRequest): AsyncResult<
        Organization,
        InvalidArgument | OperationFailed
    > {
        const result = await Organization.create({
            id,
            email: emailAddress,
            website: null,
            companyLegalName: organizationName,
            naics: null,
            entityType: null,
            yearStarted: null,
            revenues: null,
            headquarters: {
                addressLine1: null,
                addressLine2: null,
                city: null,
                county: null,
                state: null,
                zip: null,
            },
            otherLocations: [],
            howDoesYourCompanyGenerateRevenue: null,
            totalNumberOfEmployees: null,
            totalAnnualPayroll: null,
            userRoleList: [],
            isTestOrganization: false,
            raisedFunding: null,
            techAreaOfFocus: null,
            higherLimitsApproved: false,
            providesTechServiceForFee: null,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        return Success(result.value);
    }

    /**
     * Creates user entity
     * Input is of type SaveUserRequest
     * @returns new user entity
     * @returns InvalidArgument error if user entity contained an invalid property
     */
    private async createUserEntity({
        firstName,
        lastName,
        emailAddress,
        password,
        signUpInviteToken,
        certificateInviteToken,
    }: SaveUserRequest): AsyncResult<User, InvalidArgument | OperationFailed | InvalidEmail> {
        const validEmail = EmailAddress.validate(emailAddress);
        if (isErr(validEmail)) {
            return Failure(InvalidEmail(emailAddress));
        }
        const result = await User.create({
            firstName: firstName || null,
            lastName: lastName || null,
            phoneNumber: null,
            title: null,
            email: emailAddress,
            password: password || null,
            passwordResetToken: null,
            oldPassword: password || null,
            signUpInviteToken,
            createdAt: null,
            certificateInviteToken,
            isUserLoggedInBySignup: true,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }
}

export const SignUp: UseCaseClass<SignUp> = SignUpUseCase;
