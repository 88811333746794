import React, { useState, useContext, useEffect, Fragment, useCallback } from 'react';
import {
    StackLayout,
    ColumnLayout,
    CardLayout,
    Text,
    BoxLayout,
    ButtonBar,
    Button,
    TextButton,
    Collapsible,
    List,
    CenterLayout,
    Badge,
    useModal,
} from '@embroker/ui-toolkit/v2';
import { AppContext } from '@app/view/AppContext';
import { RiskProfilePageLayout } from './RiskProfilePageLayout.view';
import { RiskProfileLegalDisclosure } from './RiskProfileLegalDisclosure.view';
import { RiskProfileIneligibilityModal } from './RiskProfileIneligibilityModal';
import { RiskProfileRiskCard, RiskProfileRiskCardProps } from './RiskProfileRiskCard.view';
import { RiskProfile, RiskProfileSerialized } from '../../types/RiskProfile';
import { CoverageRisk } from '@app/public/types/enums';
import { RiskProfileDisplay } from '@app/public/types/RiskProfileDisplay';
import { URI } from '@embroker/shotwell/core/types/URI';
import { LearnMoreSlideout } from '@app/shopping/view/components/LearnMoreSlideout';
import { PublishRiskProfileReportToggleAdditionalRisksEvent } from '@app/public/useCases/PublishRiskProfileReportToggleAdditionalRisksEvent';
import { PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent } from '@app/public/useCases/PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent';
import { PublishRiskProfileReportGetAQuoteClickEvent } from '@app/public/useCases/PublishRiskProfileReportGetAQuoteClickEvent';
import { PublishRiskProfileReportRisksCoveredIncludeToggleEvent } from '@app/public/useCases/PublishRiskProfileReportRisksCoveredIncludeToggleEvent';
import { useNavigation } from '@app/view/hooks/useNavigation';
import {
    AppTypeCode,
    AppTypeCodeListManualTravel,
    AppTypeCodeListTechEO,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListWCChubb,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListHartfordBOP,
} from '@app/shopping/types/enums';
import { execute } from '@embroker/shotwell/core/UseCase';

interface RiskProfileReportProps {
    riskProfile: RiskProfile;
}

const RISK_COVERAGE_CARDS_DATA_MAP: Record<
    CoverageRisk,
    Omit<RiskProfileRiskCardProps, 'onCoverageLearnMoreClick'>
> = {
    'technology-services': {
        icon: 'bug',
        title: 'Technology Services Risk',
        tagline: "Don't let bugs, blips, or breakdowns derail your business.",
        children: (
            <Text>
                Software bugs, performance issues, or product failures can put your clients at risk
                of financial loss or a data breach — and these can lead to client lawsuits alleging
                negligence or breach of contract.
            </Text>
        ),
        coverageLabel: 'Tech E&O/Cyber insurance',
        appTypeCode: AppTypeCodeListTechEO,
    },
    cybersecurity: {
        icon: 'detective',
        title: 'Cybersecurity Risk',
        tagline: 'Secure your operations, shield your business from cyber threats.',
        children: (
            <Text>
                Security breaches due to network vulnerabilities or human error could expose
                sensitive client data or interrupt business operations, resulting in significant
                financial loss and damage to your reputation.
            </Text>
        ),
        coverageLabel: 'Tech E&O/Cyber insurance',
        appTypeCode: AppTypeCodeListTechEO,
    },
    'directors-and-officers-liability': {
        icon: 'crown',
        title: 'Directors and Officers Liability Risk',
        tagline: 'Protect your leadership team.',
        children: (
            <Fragment>
                <Text>
                    Your leadership team can face serious and costly legal challenges — and their
                    own personal assets could be at stake.
                </Text>
                <Text>
                    Common triggers for legal action include shareholder disputes about financial
                    reporting and strategy — including merger and acquisition choices — claims
                    alleging mismanagement, and regulatory investigations. Directors and Officers
                    insurance can also enhance credibility with investors.
                </Text>
            </Fragment>
        ),
        coverageLabel: 'Directors and Officers (D&O) insurance',
        appTypeCode: AppTypeCodeListManualDirectorsAndOfficers,
    },
    'employment-practices': {
        icon: 'briefcase',
        title: 'Employment Practices Risk',
        tagline: 'Stay one step ahead of workplace issues.',
        children: (
            <Text>
                Employees can file complaints of discrimination, harassment, wrongful termination,
                or other employment-related issues. Interacting with third parties such as clients
                or vendors can also pose a risk of discrimination or harassment complaints.
            </Text>
        ),
        coverageLabel: 'Employment Practices Liability insurance',
        appTypeCode: AppTypeCodeListManualEmploymentPractices,
    },
    'employee-benefits-and-fiduciary-liability': {
        icon: 'crown',
        title: 'Fiduciary Liability & Employee Benefits Risks',
        tagline: 'Safeguard your team, and keep claims at bay.',
        children: (
            <Text>
                Mistakes such as incorrect enrollment, miscalculations of benefits, or failure to
                update employee information can lead to employee claims and legal actions against
                your company.
            </Text>
        ),
        coverageLabel: 'Fiduciary Liability insurance',
        appTypeCode: AppTypeCodeListManualFiduciary,
    },
    'general-liability': {
        icon: 'house-line',
        title: 'General Liability Risk',
        tagline: "Remote doesn't mean risk-free. Are you covered enough?",
        children: (
            <Text>
                Even with a home office/remote work, your business still faces potential
                liabilities. You could be held responsible for third-party injuries in your
                workspace, property damage, or marketing activities that trigger claims of
                defamation, libel, or copyright infringement. Plus, any time your team drives for
                work purposes — in their own cars or rentals — you face auto-related risks.
            </Text>
        ),
        coverageLabel: 'General Liability insurance',
        appTypeCode: AppTypeCodeListHartfordBOP,
    },
    'general-liability-property': {
        icon: 'building-office',
        title: 'General Liability Risk',
        tagline: 'Take action — protect your business from common liabilities.',
        children: (
            <Text>
                You could be held responsible for third-party injuries in your workspace, property
                damage, or marketing activities that trigger claims of defamation, libel, or
                copyright infringement. Plus, any time your team drives for work purposes — in their
                own cars or rentals — you face auto-related risks.
            </Text>
        ),
        coverageLabel: 'General Liability + Property insurance',
        appTypeCode: AppTypeCodeListBOPChubb,
    },
    'property-damage': {
        icon: 'tornado',
        title: 'Property Damage Risk',
        tagline: 'Prepare for the unexpected.',
        children: (
            <Text>
                Your office and company property may suffer damage from fire, theft, vandalism, or
                certain natural disasters, leading to costly repairs or replacement costs.
            </Text>
        ),
        coverageLabel: 'General Liability + Property insurance',
        appTypeCode: AppTypeCodeListBOPChubb,
    },
    'business-interruption': {
        icon: 'gear',
        title: 'Business Interruption Risk',
        tagline: 'Keep your business moving.',
        children: (
            <Text>
                Damage to your office might halt operations, causing loss of income and additional
                expenses to resume business activities.
            </Text>
        ),
        coverageLabel: 'General Liability + Property insurance',
        appTypeCode: AppTypeCodeListBOPChubb,
    },
    'international-liability-and-travel': {
        icon: 'globe-hemisphere-west',
        title: 'International Liability & Travel Risks',
        tagline: 'Keep your business protected globally.',
        children: (
            <Text>
                If you travel internationally, have international customers, or employ people
                outside of the U.S., you may be at risk of third-party injuries, property damage,
                auto accidents, and employee injury — including employee abduction. An international
                package helps bridge the gap between your U.S. policies and international exposures.
            </Text>
        ),
        coverageLabel: 'International Package insurance',
        appTypeCode: AppTypeCodeListManualTravel,
    },
    'product-liability': {
        icon: 'gavel',
        title: 'Product Liability Risk',
        tagline: 'Get protected against product-related claims.',
        children: (
            <Text>
                Defective or unsafe products may cause bodily injury or property damage to
                consumers, leading to costly lawsuits and reputational harm. While some product
                liability risks are covered by general liability insurance, you may need specialized
                additional coverage depending on the nature of your products and their potential for
                causing harm.
            </Text>
        ),
        coverageLabel: 'Product Liability insurance',
        appTypeCode: AppTypeCodeListManualProductLiability,
    },
    'workers-compensation': {
        icon: 'first-aid-kit',
        title: 'Workers Compensation Risk',
        tagline: 'Keep your team safe and your business secure.',
        children: (
            <Text>
                Your employees may suffer injuries or illnesses while performing their job duties,
                leading to medical expenses and lost productivity.
            </Text>
        ),
        coverageLabel: 'Workers Compensation insurance',
        appTypeCode: AppTypeCodeListWCChubb,
    },
};

export function RiskProfileReport({ riskProfile }: RiskProfileReportProps) {
    const [showRisks, setShowRisks] = useState<boolean>(false);
    const [showAdditionalRiskAreas, setShowAdditionalRiskAreas] = useState<boolean>(false);
    const ineligibilityModal = useModal();
    const { setSlideout, closeSlideout } = useContext(AppContext);
    const { navigate } = useNavigation();

    function handleCoverageLearnMoreOnClick(appTypeCode: AppTypeCode) {
        setSlideout(
            <LearnMoreSlideout
                appTypeCode={appTypeCode}
                isSelected={false}
                onCoverageSelect={() => startApplicationFromRiskProfile(appTypeCode)}
            />,
            {
                isDismissable: true,
            },
        );
    }

    function handleSeeMoreToggle() {
        setShowAdditionalRiskAreas(!showAdditionalRiskAreas);
        execute(PublishRiskProfileReportToggleAdditionalRisksEvent);
    }

    const startApplicationFromRiskProfile = useCallback(
        (appTypeCode?: AppTypeCode) => {
            const baseQueries = { new: true };
            let redirectUrl = URI.build('/shopping/tech-bundle', baseQueries);

            switch (appTypeCode) {
                case AppTypeCodeListTechEO:
                    redirectUrl = URI.build('/shopping/tech-bundle', baseQueries);
                    break;
                case AppTypeCodeListManualEmploymentPractices:
                    if (RiskProfile.isFunded(riskProfile)) {
                        redirectUrl = URI.build('/shopping/tech-bundle', baseQueries);
                    } else {
                        redirectUrl = URI.build('/shopping/pcoml', baseQueries);
                    }
                    break;
                case AppTypeCodeListManualFiduciary:
                    if (RiskProfile.isFunded(riskProfile)) {
                        redirectUrl = URI.build('/shopping/tech-bundle', baseQueries);
                    } else {
                        redirectUrl = URI.build('/shopping/fiduciary_liability', {
                            ...baseQueries,
                        });
                    }
                    break;
                case AppTypeCodeListManualDirectorsAndOfficers:
                    if (RiskProfile.isFunded(riskProfile)) {
                        redirectUrl = URI.build('/shopping/tech-bundle', baseQueries);
                    } else {
                        redirectUrl = URI.build('/shopping/pcoml_do', {
                            ...baseQueries,
                        });
                    }
                    break;
                case AppTypeCodeListHartfordBOP:
                case AppTypeCodeListBOPChubb:
                    redirectUrl = URI.build('/shopping/bop', baseQueries);
                    break;
                case AppTypeCodeListManualTravel:
                    redirectUrl = URI.build('/shopping/international_package', baseQueries);
                    break;
                case AppTypeCodeListManualProductLiability:
                    redirectUrl = URI.build('/shopping/product_liability', baseQueries);
                    break;
                case AppTypeCodeListWCChubb:
                    redirectUrl = URI.build('/shopping/wc', baseQueries);
                    break;
                case undefined:
                    redirectUrl = URI.build('/shopping/tech-bundle', baseQueries);
                    break;
            }

            execute(PublishRiskProfileReportGetAQuoteClickEvent, { appTypeCode });
            closeSlideout();
            navigate(redirectUrl);
        },
        [riskProfile, navigate, closeSlideout],
    );

    const questionnaireURL = URI.build(
        '/explore/risk-profile/questionnaire',
        RiskProfile.serialize(riskProfile) as Record<
            keyof RiskProfileSerialized,
            RiskProfileSerialized[keyof RiskProfileSerialized]
        >,
        {
            // Return user to the last page of the questionnaire
            p: '-1',
        },
    );

    const coverageRisksDisplayRules = RiskProfileDisplay.getRiskDisplayFromRiskProfile(riskProfile);
    const eligibleCoverageRiskData = (Object.keys(coverageRisksDisplayRules) as CoverageRisk[])
        .filter((riskKey) => coverageRisksDisplayRules[riskKey])
        .map((riskKey) => RISK_COVERAGE_CARDS_DATA_MAP[riskKey]) as RiskProfileRiskCardProps[];

    const MAX_PRIMARY_RISK_CARDS_TO_SHOW = 4;
    const primaryCoverageRiskPropData = eligibleCoverageRiskData.slice(
        0,
        MAX_PRIMARY_RISK_CARDS_TO_SHOW,
    );
    const additionalCoverageRiskPropData = eligibleCoverageRiskData.slice(
        MAX_PRIMARY_RISK_CARDS_TO_SHOW,
    );

    useEffect(() => {
        // if no risk cards are shown, fallback to ineligible modal for user experience continuity
        if (!eligibleCoverageRiskData.length) {
            ineligibilityModal.show();
        }
    }, [eligibleCoverageRiskData, ineligibilityModal]);

    return (
        <RiskProfilePageLayout showExitButton>
            <StackLayout gap="48">
                <StackLayout gap="24">
                    <TextButton
                        icon="thin-arrow-left"
                        iconPosition="left"
                        onClick={() => navigate(questionnaireURL)}
                    >
                        Back
                    </TextButton>
                    <Text style="heading 2">Here's your risk profile</Text>
                    <Text>
                        Based on what you've told us and what we've learned from hundreds of
                        clients, here's a clear look at your risks.
                    </Text>
                    <Text>
                        Take a look at your risks and our recommended coverages — you can use these
                        tools to protect your business, just like thousands of others do every day!
                    </Text>
                </StackLayout>
                <ColumnLayout responsive={{ screenWidth: { smallerThan: 'large-tablet' } }}>
                    <StackLayout gap="64" className="u-1/1">
                        <StackLayout gap="32">
                            <StackLayout gap="24">
                                {primaryCoverageRiskPropData.map((riskPropData, index) => (
                                    <RiskProfileRiskCard
                                        key={index}
                                        {...riskPropData}
                                        onCoverageLearnMoreClick={() => {
                                            handleCoverageLearnMoreOnClick(
                                                riskPropData.appTypeCode,
                                            );
                                            execute(
                                                PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent,
                                                { appTypeCode: riskPropData.appTypeCode },
                                            );
                                        }}
                                    />
                                ))}
                                {showAdditionalRiskAreas && (
                                    <Fragment>
                                        {additionalCoverageRiskPropData.map(
                                            (riskPropData, index) => (
                                                <RiskProfileRiskCard
                                                    key={index}
                                                    {...riskPropData}
                                                    onCoverageLearnMoreClick={() =>
                                                        handleCoverageLearnMoreOnClick(
                                                            riskPropData.appTypeCode,
                                                        )
                                                    }
                                                />
                                            ),
                                        )}
                                    </Fragment>
                                )}
                                {additionalCoverageRiskPropData.length > 0 && (
                                    <CenterLayout>
                                        <TextButton
                                            onClick={handleSeeMoreToggle}
                                            icon={
                                                showAdditionalRiskAreas ? 'caret-up' : 'caret-down'
                                            }
                                            iconPosition="right"
                                        >
                                            <ColumnLayout gap="8">
                                                <Badge
                                                    count={additionalCoverageRiskPropData.length}
                                                />
                                                <span>
                                                    {showAdditionalRiskAreas ? 'Hide' : 'Show'}{' '}
                                                    additional risk areas
                                                </span>
                                            </ColumnLayout>
                                        </TextButton>
                                    </CenterLayout>
                                )}
                            </StackLayout>
                        </StackLayout>
                        <RiskProfileLegalDisclosure />
                    </StackLayout>
                    <StackLayout gap="24" className="u-1/1 u-3/6@tablet">
                        <CardLayout>
                            <BoxLayout gap="16">
                                <StackLayout gap="32">
                                    <StackLayout gap="8">
                                        <Text style="heading 4">
                                            Your business, your coverage: Tailored just for you
                                        </Text>
                                        <Text>
                                            Based on your unique risk profile, get a coverage
                                            package designed to protect what matters most — simple,
                                            secure, and tailored to you.
                                        </Text>
                                    </StackLayout>
                                    <StackLayout gap="16">
                                        <TextButton
                                            icon={showRisks ? 'caret-up' : 'caret-down'}
                                            iconPosition="right"
                                            onClick={() => {
                                                execute(
                                                    PublishRiskProfileReportRisksCoveredIncludeToggleEvent,
                                                );
                                                setShowRisks(!showRisks);
                                            }}
                                        >
                                            Risks covered include
                                        </TextButton>
                                        <Collapsible state={showRisks ? 'open' : 'closed'}>
                                            <List>
                                                <List.Item>Technology Services Risk</List.Item>
                                                <List.Item>Cybersecurity Risk</List.Item>
                                                {RiskProfile.isFunded(riskProfile) && (
                                                    <Fragment>
                                                        <List.Item>
                                                            Directors and Officers Risk
                                                        </List.Item>
                                                        <List.Item>
                                                            Employment Practices Risk
                                                        </List.Item>
                                                        <List.Item>
                                                            Fiduciary Liability Risk
                                                        </List.Item>
                                                    </Fragment>
                                                )}
                                            </List>
                                        </Collapsible>
                                    </StackLayout>
                                    <ButtonBar
                                        responsive={{ screenWidth: { smallerThan: Infinity } }}
                                    >
                                        <Button onClick={() => startApplicationFromRiskProfile()}>
                                            Get a Quote
                                        </Button>
                                    </ButtonBar>
                                </StackLayout>
                            </BoxLayout>
                        </CardLayout>
                    </StackLayout>
                </ColumnLayout>
            </StackLayout>
            <RiskProfileIneligibilityModal modal={ineligibilityModal} />
        </RiskProfilePageLayout>
    );
}
