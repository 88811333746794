import { Organization } from '@app/userOrg/entities/Organization';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Button, ColumnLayout, GridLayout, Spinner, Text, useModal } from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useState } from 'react';
import { Applicant } from '@app/shopping/entities/Applicant';
import { applyPriorityOrder, Coverage } from '@app/shopping/types/Coverage';
import { AppTypeCode } from '@app/shopping/types/enums';
import { GetApplicant } from '@app/shopping/useCases/GetApplicant';
import { CoverageCard } from '@app/shopping/view/components/CoverageCard.view';
import { LearnMoreSlideout } from '@app/shopping/view/components/LearnMoreSlideout';
import { RenewalWarningModal } from '@app/shopping/view/components/RenewalWarningModal';
import { useStartApplication } from '@app/shopping/view/hooks/useStartApplication';
import { AppContext, AppContextStore } from '../../../view/AppContext';

interface RecommendedCoveragesProps {
    coverageList: Immutable<Coverage[]>;
    organization: Immutable<EntityProps<Organization>>;
}

export function RecommendedCoverages(props: RecommendedCoveragesProps) {
    const [coverageList, setCoverageList] = useState<Coverage[]>([...props.coverageList]);
    const { setSlideout, selectedCoverages } = useContext(AppContext);
    const [applicant, setApplicant] = useState<Nullable<EntityProps<Applicant>>>(null);
    const { result: getApplicantResult } = useUseCase(GetApplicant);
    const [lplRenewalApplicationId, setLPLRenewalApplicationId] =
        useState<Nominal<string, 'UUID'>>();

    const lplRenewalWarningModal = useModal();

    useEffect(() => {
        const selectedCoverages = coverageList.filter((coverage) => coverage.isSelected);
        AppContextStore.update({
            selectedCoverages,
        });
    }, [coverageList]);

    useEffect(() => {
        if (getApplicantResult && isOK(getApplicantResult)) {
            setApplicant(getApplicantResult.value.applicant as Applicant);
        }
    }, [getApplicantResult]);

    const setupLearnMoreComponent = (code: AppTypeCode, isSelected: boolean) => {
        setSlideout(
            <LearnMoreSlideout
                appTypeCode={code}
                naicsCode={applicant?.naicsIndustry}
                isSelected={isSelected}
                onCoverageSelect={handleCoverageSelected}
            />,
        );
    };

    const handleCoverageSelected = (code: AppTypeCode, isSelected: boolean) => {
        const updatedCoverageTile = coverageList.find((coverage) => code === coverage.appType);
        if (!updatedCoverageTile) {
            return;
        }

        updatedCoverageTile.isSelected = isSelected;
        const updateCoverageList = coverageList.map((coverage) => {
            if (coverage.appType === code) {
                return updatedCoverageTile;
            }
            return coverage;
        });

        setCoverageList(updateCoverageList);
        setupLearnMoreComponent(code, isSelected);
    };

    const handleShowDetails = (appTypeCode: AppTypeCode, isSelected = false) => {
        setupLearnMoreComponent(appTypeCode, isSelected);
    };

    const selectedCoveragesCount = selectedCoverages ? selectedCoverages.length : 0;

    const getSelectedCoverageTypes = () => {
        return coverageList
            .filter((coverage) => {
                return coverage.isSelected ? true : false;
            })
            .map((coverage) => coverage.appType);
    };

    const setCoverageSelectStatus = (code: AppTypeCode, isSelected: boolean) => {
        const updateCoverageList = coverageList.map((coverage) => {
            if (coverage.appType === code) {
                return { ...coverage, isSelected: isSelected };
            }
            return coverage;
        });

        setCoverageList(updateCoverageList);
    };

    const coverageCards =
        coverageList.length > 0
            ? applyPriorityOrder(coverageList)
                  .slice(0, 3)
                  .map((coverage: Coverage) => {
                      return (
                          <CoverageCard
                              key={coverage.appType}
                              appType={coverage.appType}
                              naicsCode={applicant?.naicsIndustry}
                              isSelected={coverage.isSelected}
                              handleAddAndRemoveCoverage={setCoverageSelectStatus}
                              handleShowDetails={handleShowDetails}
                          />
                      );
                  })
            : null;

    const { getLPLRenewalApplicationId, startApplication, isLoading } = useStartApplication();

    const handleStartApplication = async () => {
        const selectedAppTypes = getSelectedCoverageTypes();

        const lplRenewalApplicationId = await getLPLRenewalApplicationId(selectedAppTypes, false);
        if (lplRenewalApplicationId) {
            setLPLRenewalApplicationId(lplRenewalApplicationId);
            lplRenewalWarningModal.show();
            return;
        }

        startApplication({ selectedAppTypes }, applicant?.naicsIndustry);
    };

    const handleCreateNewApp = () =>
        startApplication(
            { selectedAppTypes: getSelectedCoverageTypes() },
            applicant?.naicsIndustry,
        );

    return coverageCards ? (
        <GridLayout>
            {isLoading && <Spinner />}
            <RenewalWarningModal
                modal={lplRenewalWarningModal}
                onCreateNewApp={handleCreateNewApp}
                renewalApplicationId={lplRenewalApplicationId}
            />
            <ColumnLayout
                center
                split={selectedCoveragesCount > 0 ? '-1' : undefined}
                className="u-grid-size-12"
                style={{ height: 48 }}
            >
                <Text style="heading 4" data-e2e="recommended-coverages">
                    Recommended Coverages
                </Text>
                {selectedCoveragesCount > 0 ? (
                    <Button
                        onClick={handleStartApplication}
                        notificationNumber={selectedCoveragesCount}
                    >
                        Start application{selectedCoveragesCount > 1 ? 's' : ''}
                    </Button>
                ) : null}
            </ColumnLayout>
            {coverageCards}
        </GridLayout>
    ) : null;
}
