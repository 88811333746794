import React from 'react';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { BOPChubbQuote } from '../entities/BOPChubbQuote';
import {
    BOPChubbAggregateLimit,
    BOPChubbPerOccurrenceLimit,
    BOPChubbProductCode,
} from '../types/BOPChubbQuoteOptions';
import { BOPChubbBundleCoverageView } from './BOPChubbBundleCoverageView';

export interface ProductCodeRadioItem {
    title: string;
    value: BOPChubbProductCode;
}

export function BOPChubbBundleCoverageOptions(
    underlyingCoverageProps: UnderlyingCoverageProps<BOPChubbQuote>,
) {
    const toRadioOption = (v: BOPChubbProductCode): ProductCodeRadioItem => ({
        title: getProductCodeTitle(v),
        value: v,
    });

    return (
        <BOPChubbBundleCoverageView
            {...underlyingCoverageProps}
            perOccurrenceLimitOptions={BOPChubbPerOccurrenceLimit}
            aggregateLimitOptions={BOPChubbAggregateLimit}
            productCodeOptions={BOPChubbProductCode.filter((v) => {
                if (underlyingCoverageProps.organizationInfo.address.state === 'FL') {
                    return v === 'GeneralLiabilityBusinessPersonalProperty';
                }
                return true;
            }).map(toRadioOption)}
        />
    );
}

function getProductCodeTitle(productCode: BOPChubbProductCode): string {
    if (productCode === 'GeneralLiability') {
        return 'General Liability Only';
    }

    return 'General Liability + Business Personal Property';
}
