import React from 'react';
import { LearnMoreSlideout } from '../LearnMoreSlideout';
import { lawProducts, techProducts } from '../coverageDetails';
import { AppTypeCode } from '../../../types/enums';

interface CoverageSelectionSlideoutProps {
    appTypeCode: AppTypeCode;
    naicsCode?: string;
    onClose: () => void;
    isSelected: boolean;
    onCoverageSelect?: (code: AppTypeCode, isSelected: boolean) => void;
}

export function CoverageInformationSlideout({
    appTypeCode,
    naicsCode,
    isSelected,
    onCoverageSelect,
}: CoverageSelectionSlideoutProps) {
    const getSlideoutContent = (appTypeCode: AppTypeCode): JSX.Element => {
        return (
            <LearnMoreSlideout
                appTypeCode={appTypeCode}
                naicsCode={naicsCode}
                isSelected={isSelected}
                onCoverageSelect={onCoverageSelect}
                variant={
                    lawProducts.includes(appTypeCode) || techProducts.includes(appTypeCode)
                        ? 'new'
                        : 'standard'
                }
                actionText="Select Coverage"
            />
        );
    };
    const sildeoutContent = getSlideoutContent(appTypeCode);
    return sildeoutContent;
}
