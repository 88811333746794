import {
    API,
    CoverageLimit as APICoverageLimit,
    DriverList as APIDriverList,
    LiabilityPolicy as APILiabilityPolicy,
    LiabilitySection as APILiabilitySection,
    LiabilitySectionItem as APILiabilitySectionItem,
    Policy as APIPolicy,
    PolicySection as APIPolicySection,
    PolicyAttorney as APIPolicyAttorney,
    PolicyExtractPolicyDataFromPdfRequest as APIPolicyExtractPolicyDataFromPdfRequest,
    PolicyGetTransferRequestSignedDateRequest as APIPolicyGetTransferRequestSignedDateRequest,
    PolicyLobAutoVehicle as APIPolicyLobAutoVehicle,
    PolicyProcessUploadRequest as APIPolicyProcessUploadRequest,
    PolicyUserPolicyRequest as APIPolicyUserPolicyRequest,
    PolicyUserPolicySeriesRequest as APIPolicyUserPolicySeriesRequest,
    PropertyCoverageSectionItem as APIPropertyCoverageSectionItem,
    PropertySection as APIPropertySection,
    BorCreateTransferRequestRequest,
    BorSendTransferRequestRequest,
    GlobalDownloadUrlRequest,
    PolicyGetPreviousPolicyForUserByApplicationTokenRequest,
    SubjectOfInsuranceList,
} from '@embroker/shotwell-api/app';
import {
    InsuredTypeCodeListItem,
    LineOfBusinessCodeList,
    LineOfBusinessCodeListItem,
    LineOfBusinessCodeListMap,
    LineOfBusinessSubtypeCodeListItem,
    PolicyBookingTypeItem,
    PolicyViewStatusCodeListItem,
    StructuralComponentTypeCodeListItem,
    VehicleUseCodeListItem,
} from '@embroker/shotwell-api/enums';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    Result,
    Success,
    handleOperationFailure,
    isErr,
    isOK,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { QuotingEngine } from '../../../shopping/types/enums';
import { Policy, isPolicyLPL } from '../../entities/Policy';
import { WritingCompany } from '../../entities/WritingCompany';
import { GetWritingCompanyListError, OneOrMoreWritingCompanyNotFound } from '../../errors';
import { AdditionalInterest } from '../../types/AdditionalInterest';
import { AutoLiabilitySection } from '../../types/AutoLiabilitySection';
import { BindFactors, LPLBindFactors } from '../../types/BindFactors';
import { Document } from '../../types/Document';
import { Driver } from '../../types/Driver';
import { InsuredParty } from '../../types/InsuredParty';
import { LiabilityCoverage } from '../../types/LiabilityCoverage';
import { LiabilitySection } from '../../types/LiabilitySection';
import { Limit } from '../../types/Limit';
import {
    Lob,
    LobAuto,
    LobExcessTechEOCyber,
    LobProperty,
    LobType,
    LobUmbrellaExcess,
    LobWorkersCompensation,
} from '../../types/Lob';
import { PolicyAttorney } from '../../types/PolicyAttorney';
import { PropertyCoverage } from '../../types/PropertyCoverage';
import { PropertySection } from '../../types/PropertySection';
import { SignatureRequest } from '../../types/SignatureRequest';
import { SubjectOfInsurance } from '../../types/SubjectOfInsurance';
import {
    PolicySection,
    StructuralComponentTypeCodeListTechEOCyberLiabilitySection,
} from '../../types/PolicySection';
import {
    PolicyIds,
    UmbrellaExcessLiabilitySection,
} from '../../types/UmbrellaExcessLiabilitySection';
import { Vehicle } from '../../types/Vehicle';
import { WorkersCompensationLiabilitySection } from '../../types/WorkersCompensationLiabilitySection';
import { WorkersCompensationLocation } from '../../types/WorkersCompensationLocation';
import { WorkersCompensationLocationGroup } from '../../types/WorkersCompensationLocationGroup';
import {
    BORCreateTransferRequestResponse,
    CreatePolicyFromPDFRequest,
    CreatePolicyFromPDFResponse,
    GetTaskStatusResponse,
    PolicyRepository,
    UploadExtractPDFResponse,
    UploadPolicyRequest,
    UploadUrlResponse,
} from './index';
import { Money } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { API as APIv2 } from '@embroker/shotwell-api/v2/app';

const PolicyNameByLineOfBusiness: Map<LineOfBusinessCodeListItem, string> = new Map([
    ['LineOfBusinessCodeListAuto', 'Commercial Auto'],
    ['LineOfBusinessCodeListBOP', 'Business Owner’s'],
    ['LineOfBusinessCodeListOwnersAndContractors', 'Owners and Contractors Protective Liability'],
    ['LineOfBusinessCodeListEmploymentPractices', 'Employment Practices Liability'],
    [
        'LineOfBusinessCodeListProfessionalLiability',
        'Professional Liability / Errors and Omissions',
    ],
    ['LineOfBusinessCodeListCyber', 'Cyber / Data Breach'],
    ['LineOfBusinessCodeListOther', 'Other Liability'],
    ['LineOfBusinessCodeListTravel', 'International/Foreign Package'],
    ['LineOfBusinessCodeListESP', 'Embroker Startup Package'],
    ['LineOfBusinessCodeListPCoML', 'Private Company Management Liability'],
    [
        'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability',
        'Non-Technology Business and Management Consultants Professional Liability',
    ],
]);

const policyLabelMap: Map<LineOfBusinessCodeListItem, string> = new Map([
    ['LineOfBusinessCodeListCyber', 'Cyber Insurance'],
]);

interface UnderlyingAgreement {
    id: UUID;
    renewal_series_id: Nullable<UUID>;
}

interface APIAutoPolicy {
    liability_section: APILiabilitySection;
    property_coverage_list: APIPropertyCoverageSectionItem[];
    vehicle_list: APIPolicyLobAutoVehicle[];
    driver_list: APIDriverList;
}

interface APIPackageAutoSection {
    auto_liability_section: Nullable<APILiabilitySection>;
    driver_list: APIDriverList;
}

interface ESPAndPcoPolicyDisplayData {
    policyLabel: string;
    subLineOfBusiness: LineOfBusinessSubtypeCodeListItem;
}

function isAutoPolicy(
    policyOrSection: Immutable<APIAutoPolicy | APIPackageAutoSection>,
): policyOrSection is APIAutoPolicy {
    return (policyOrSection as APIAutoPolicy).vehicle_list !== undefined;
}

interface APIUmbrellaPolicy {
    liability_section: APILiabilitySection;
    underlying_agreement_list: UnderlyingAgreement[];
}

interface APIPackageUmbrellaSection {
    umbrella_excess_liability_section: Nullable<APILiabilitySection>;
    underlying_agreement_list: UnderlyingAgreement[];
}

function isUmbrellaPolicy(
    policyOrSection: Immutable<APIUmbrellaPolicy | APIPackageUmbrellaSection>,
): policyOrSection is APIUmbrellaPolicy {
    return (policyOrSection as APIUmbrellaPolicy).liability_section !== undefined;
}

function isLiabilityPolicy(
    policyOrSection: Immutable<Nullable<APILiabilityPolicy | APILiabilitySection>>,
): policyOrSection is APILiabilityPolicy {
    return (policyOrSection as APILiabilityPolicy).liability_section !== undefined;
}

@injectable()
export class APIPolicyRepository implements PolicyRepository {
    async getPolicy(
        policyId: UUID,
    ): AsyncResult<Policy, UnknownEntity | InvalidArgument | OperationFailed> {
        const request: APIPolicyUserPolicyRequest = {
            id: policyId,
        };
        const result = await API.request('policy/user_policy', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return this.createPolicy(result.value);
    }

    async getClientPreviousPolicy(
        token: string,
    ): AsyncResult<Policy, InvalidArgument | OperationFailed> {
        const request: PolicyGetPreviousPolicyForUserByApplicationTokenRequest = {
            token,
        };
        const result = await API.request(
            'policy/get_previous_policy_for_user_by_application_token',
            request,
        );
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return this.createPolicy(result.value);
    }

    async getPolicies(): AsyncResult<Policy[], InvalidArgument | OperationFailed> {
        const result = await API.request('policy/user_policies_full');
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return this.createPolicyList(result.value);
    }

    async getUnprocessedPolicies(): AsyncResult<string[], InvalidArgument | OperationFailed> {
        const result = await API.request('policy/user_unprocessed_policies');
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }

    async getPolicySeries(
        seriesId: UUID,
    ): AsyncResult<Policy[], UnknownEntity | InvalidArgument | OperationFailed> {
        const policySeriesRequest: APIPolicyUserPolicySeriesRequest = {
            renewal_series_id: seriesId,
        };
        const result = await API.request('policy/user_policy_series', policySeriesRequest);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return this.createPolicyList(result.value);
    }

    async getPolicyTransferRequestSignedDate(
        policyId: UUID,
    ): AsyncResult<Nullable<Date>, InvalidArgument | OperationFailed> {
        const transferSignedDateRequest: APIPolicyGetTransferRequestSignedDateRequest = {
            policy_id: policyId,
        };

        const result = await API.request(
            'policy/get_transfer_request_signed_date',
            transferSignedDateRequest,
        );

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value.signed_date);
    }

    async borCreateTransferRequest(
        insurerIdList: UUID[],
    ): AsyncResult<BORCreateTransferRequestResponse, InvalidArgument | OperationFailed> {
        const request: BorCreateTransferRequestRequest = {
            insurer_id_list: insurerIdList,
        };
        const result = await API.request('bor/create_transfer_request', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const signatureRequestsResult = this.createSignatureRequestList(
            result.value.signature_request_list,
        );
        if (isErr(signatureRequestsResult)) {
            return signatureRequestsResult;
        }

        const borCreateTransferRequestResponse: Immutable<BORCreateTransferRequestResponse> = {
            clientId: result.value.client_id,
            signatureRequestList: signatureRequestsResult.value,
        };

        return Success(borCreateTransferRequestResponse);
    }

    async borSendTransferRequest(
        signatureRequestIdList: string[],
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const request: BorSendTransferRequestRequest = {
            signature_request_id_list: signatureRequestIdList,
        };
        const result = await API.request('bor/send_transfer_request', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success();
    }

    async policyUpload(
        policyUploadRequest: UploadPolicyRequest,
    ): AsyncResult<Nullable<UUID>, InvalidArgument | OperationFailed> {
        const request: APIPolicyProcessUploadRequest = {
            agreement_id: policyUploadRequest.agreementId,
            file_key: policyUploadRequest.fileKey,
            is_from_onboarding: policyUploadRequest.isFromOnboarding,
            line_of_business: policyUploadRequest.lineOfBusiness,
            unprocessed_policy_name: policyUploadRequest.unprocessedPolicyName,
        };

        const result = await API.request('policy/process_upload', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value.activity_id);
    }

    async policyUploadExtractPDF(
        fileKey: string,
    ): AsyncResult<UploadExtractPDFResponse, InvalidArgument | OperationFailed> {
        const request: APIPolicyExtractPolicyDataFromPdfRequest = {
            file_key: fileKey,
        };

        const result = await API.request('policy/extract_policy_data_from_pdf', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return UploadExtractPDFResponse.create({ taskId: result.value.task_id });
    }

    private async createPolicy(
        response: Immutable<APIPolicy>,
    ): AsyncResult<Policy, InvalidArgument | OperationFailed> {
        if (response.insurer === null) {
            return Failure(InvalidArgument({ argument: 'insurer', value: response.insurer }));
        }
        if (response.insurer.id === null) {
            return Failure(InvalidArgument({ argument: 'insurer_id', value: response.insurer.id }));
        }

        const lineOfBusiness = LineOfBusinessCodeList.find(
            (item) => item === response.line_of_business,
        );

        if (lineOfBusiness === undefined) {
            return Failure(
                InvalidArgument({ argument: 'line_of_business', value: response.line_of_business }),
            );
        }

        const documentList: Document[] = [];
        for (const documentObject of response.document_list) {
            const documentData: Document = {
                isCertificate: documentObject.is_certificate,
                name: documentObject.name,
                storageLocation: documentObject.storage_location,
                typeCode: documentObject.type_code,
            };

            const documentResult = Document.create(documentData);
            if (isErr(documentResult)) {
                return documentResult;
            }

            documentList.push(documentResult.value);
        }

        const insuredPartyList: InsuredParty[] = [];
        for (const insuredPartyElement of response.insured_list) {
            const insuredPartyResult = InsuredParty.create(
                insuredPartyElement.name,
                insuredPartyElement.type_code as InsuredTypeCodeListItem,
            );
            if (isErr(insuredPartyResult)) {
                return insuredPartyResult;
            }
            insuredPartyList.push(insuredPartyResult.value);
        }

        const bindFactorsResult = this.createBindFactors(response);
        if (isErr(bindFactorsResult)) {
            return bindFactorsResult;
        }

        const lobListResult = this.createLOBList(response);
        if (!lobListResult || isErr(lobListResult)) {
            return lobListResult;
        }

        const policyNameAndSubtypeDisplayData = this.buildPolicyNameAndSubtype(response);

        const finalAmount = response.policy_premium.final_amount?.amount ?? null;
        const totalFees = response.policy_premium.total_fees?.amount
            ? response.policy_premium.total_fees.amount
            : 0;
        const totalTaxes = response.policy_premium.total_taxes?.amount
            ? response.policy_premium.total_taxes.amount
            : 0;
        const basePremiumWithAccessFeeIncluded = finalAmount
            ? finalAmount - (totalFees + totalTaxes)
            : null;

        const policySectionListResult = this.mapToViewPolicySectionList(
            response.coverage_section_list as APIPolicySection[],
        );

        const policyResult = await Policy.create({
            id: response.id,
            borStatus: response.bor_status,
            cancellationDate: response.cancellation_date,
            endDate: response.effective_period_end,
            startDate: response.effective_period_start,
            insurerName: response.insurer.name,
            insurerId: response.insurer.id,
            lineOfBusiness: lineOfBusiness,
            subLineOfBusiness: policyNameAndSubtypeDisplayData.subLineOfBusiness,
            name: policyNameAndSubtypeDisplayData.policyLabel,
            policyNumber: response.policy_num,
            insuranceApplicationId: response.insurance_application_id,
            bundleId: response.bundle_id,
            status: response.status,
            premiumPerYear: response.policy_premium.final_amount ?? null,
            basePremiumWithEmbrokerAccessFee: basePremiumWithAccessFeeIncluded
                ? Money.tryFromFloat(basePremiumWithAccessFeeIncluded / 100)
                : null,
            viewMode: response.view_mode as PolicyViewStatusCodeListItem,
            isReferred: response.referred !== null ? response.referred : undefined,
            renewalSeriesId: response.renewal_series_id,
            documents: documentList,
            insuredPartiesList: insuredPartyList,
            lobList: lobListResult.value as Lob[],
            solartisPolicyNumber: response.solartis_policy_number,
            isDirectBill: response.is_direct_bill,
            insurerBillingPhone: response.insurer.billing_phone,
            insurerBillingURL: response.insurer.billing_url,
            bookingType: response.booking_type as PolicyBookingTypeItem,
            bindFactors: bindFactorsResult ? bindFactorsResult.value : undefined,
            claimWebsite: response.claims_website,
            claimPhoneNumber: response.claims_phone_number,
            claimPhoneExtension: response.claims_phone_extension,
            claimEmail: response.claims_email_address,
            address: response.address,
            addressLine2: response.suite_number,
            city: response.city,
            state: response.state as State,
            zip: response.zip_code as ZipCode,
            manifestId: response.manifest_id,
            coverageSectionList: policySectionListResult,
            inRunoff: response.in_runoff,
        });

        if (isErr(policyResult)) {
            return handleOperationFailure(policyResult);
        }

        return policyResult;
    }

    private createBindFactors(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<BindFactors, InvalidArgument | OperationFailed> {
        if (isPolicyLPL(apiPolicy.policy_num)) {
            if (apiPolicy.bind_factors !== null) {
                return LPLBindFactors.create({
                    claimsExpense:
                        apiPolicy.bind_factors.claims_expense_type_factor?.additional_options ?? '',
                    separateClaimsExpenseLimit:
                        apiPolicy.bind_factors.claims_expense_type_factor?.separate_coverage_limit,
                    deductibleType: apiPolicy.bind_factors.deductible_type_factor ?? '',
                });
            }
        }

        return Success(undefined);
    }

    private buildPolicyNameAndSubtype(response: Immutable<APIPolicy>): ESPAndPcoPolicyDisplayData {
        const lobPrefixMap: Map<LineOfBusinessCodeListItem, string> = new Map([
            ['LineOfBusinessCodeListESP', 'Startup Package: '],
            ['LineOfBusinessCodeListPCoML', 'Business Management Insurance: '],
        ]);

        const lobPrefix = lobPrefixMap.get(response.line_of_business as LineOfBusinessCodeListItem);
        if (lobPrefix) {
            const policyDisplayData = this.buildEspOrPCoPolicyNameAndSubtype(response);
            policyDisplayData.policyLabel = lobPrefix + policyDisplayData.policyLabel;
            return policyDisplayData;
        }

        const mappedName = policyLabelMap.get(
            response.line_of_business as LineOfBusinessCodeListItem,
        );
        if (mappedName != undefined) {
            return {
                policyLabel: mappedName,
                subLineOfBusiness:
                    response.line_of_business_subtype as LineOfBusinessSubtypeCodeListItem,
            };
        }

        return {
            policyLabel:
                response.policy_name !== ''
                    ? response.policy_name
                    : this.generatePolicyNameByLoB(
                          response.line_of_business as LineOfBusinessCodeListItem,
                      ),
            subLineOfBusiness:
                response.line_of_business_subtype as LineOfBusinessSubtypeCodeListItem,
        };
    }

    private buildEspOrPCoPolicyNameAndSubtype(
        response: Immutable<APIPolicy>,
        quotingEngine?: QuotingEngine,
    ): ESPAndPcoPolicyDisplayData {
        const ESPAndPcoLiabilityCoverageTypesList = new Map<string, ESPAndPcoPolicyDisplayData>([
            [
                'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
                {
                    policyLabel: 'Directors & Officers Liability',
                    subLineOfBusiness: 'LineOfBusinessSubtypeCodeListDODigital',
                },
            ],
            [
                'LiabilityCoverageCodeListDirectorsAndOfficersLiability',
                {
                    policyLabel: 'Directors & Officers Liability',
                    subLineOfBusiness: 'LineOfBusinessSubtypeCodeListDODigital',
                },
            ],
            [
                'LiabilityCoverageCodeListEmploymentPracticesLiability',
                {
                    policyLabel: 'Employment Practices Liability',
                    subLineOfBusiness: 'LineOfBusinessSubtypeCodeListEPLIDigital',
                },
            ],
            [
                'LiabilityCoverageCodeListFiduciaryLiability',
                {
                    policyLabel: 'Fiduciary Liability',
                    subLineOfBusiness: 'LineOfBusinessSubtypeCodeListFiduciaryDigital',
                },
            ],
            [
                'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
                {
                    policyLabel: 'Technology E&O/Cyber Liability',
                    subLineOfBusiness: 'LineOfBusinessSubtypeCodeListErrorsAndOmissions',
                },
            ],
        ]);

        const defaultDisplayData: ESPAndPcoPolicyDisplayData = {
            policyLabel: 'Management Liability',
            subLineOfBusiness: 'LineOfBusinessSubtypeCodeListManagementLiability',
        };

        if (response.lob_other_liability == null) {
            return defaultDisplayData;
        }

        const displayDataArray: ESPAndPcoPolicyDisplayData[] = [];

        response.lob_other_liability.liability_section.liability_list.forEach((liability) => {
            const displayData = ESPAndPcoLiabilityCoverageTypesList.get(liability.type_code);
            if (displayData != undefined) {
                displayDataArray.push(displayData);
            }
        });

        if (displayDataArray.length == 1) {
            return displayDataArray[0];
        }

        if (displayDataArray.length > 1) {
            if (
                displayDataArray.find(
                    (type) =>
                        type.policyLabel == 'Errors & Omissions / Cyber Liability' ||
                        type.policyLabel == 'Technology E&O/Cyber Liability',
                )
            ) {
                return {
                    policyLabel: 'Management Liability / Technology E&O/Cyber Liability',
                    subLineOfBusiness: 'LineOfBusinessSubtypeCodeListManagementLiability',
                };
            }
            if (quotingEngine == 'QuotingEnginePCoML') {
                defaultDisplayData.subLineOfBusiness =
                    'LineOfBusinessSubtypeCodeListEmploymentPracticesLiability';
                return defaultDisplayData;
            }
            return defaultDisplayData;
        }
        return defaultDisplayData;
    }

    private generatePolicyNameByLoB(lob: LineOfBusinessCodeListItem): string {
        const mappedName = PolicyNameByLineOfBusiness.get(lob);
        if (mappedName != undefined) {
            return mappedName;
        }

        return LineOfBusinessCodeListMap[lob];
    }

    private async createPolicyList(
        apiPolicy: Immutable<APIPolicy[]>,
    ): AsyncResult<Policy[], InvalidArgument | OperationFailed> {
        const policies: Immutable<Policy>[] = [];
        for (const policy of apiPolicy) {
            const policyResult = await this.createPolicy(policy);
            if (isErr(policyResult)) {
                return handleOperationFailure(policyResult);
            }
            policies.push(policyResult.value);
        }
        return Success(policies);
    }

    private createLOBList(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<Lob[], InvalidArgument | OperationFailed> {
        const lobList: Immutable<Lob>[] = [];
        const sectionLobs = [
            'LineOfBusinessCodeListESP',
            'LineOfBusinessCodeListPCoML',
            'LineOfBusinessCodeListAccountantsProfessionalLiability',
            'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability',
            'LineOfBusinessCodeListTaxPreparersAndBookkeepersProfessionalLiability',
            'LineOfBusinessCodeListRealEstateAgentsProfessionalLiability',
            'LineOfBusinessCodeListHomeInspectorsProfessionalLiability',
        ];
        if (sectionLobs.includes(apiPolicy.line_of_business)) {
            const sectionsResult = this.createPolicyLOBForSectionList(apiPolicy);
            return sectionsResult;
        }

        const autoLOBResult = this.createLOBAuto(apiPolicy.lob_auto);
        if (isErr(autoLOBResult)) {
            return autoLOBResult;
        }

        if (autoLOBResult.value != null) {
            lobList.push(autoLOBResult.value);
        }

        const umbrellaLOBResult = this.createLOBUmbrellaExcessTechEO(apiPolicy, false);
        if (isErr(umbrellaLOBResult)) {
            return umbrellaLOBResult;
        }

        if (umbrellaLOBResult.value != null) {
            lobList.push(umbrellaLOBResult.value);
        }

        const workersCompensationLOBResult = this.createLOBWorkersCompensation(apiPolicy);
        if (isErr(workersCompensationLOBResult)) {
            return workersCompensationLOBResult;
        }

        if (workersCompensationLOBResult.value != null) {
            lobList.push(workersCompensationLOBResult.value);
        }

        const cyberLOBResult = this.createLOBCyber(apiPolicy.lob_cyber);
        if (isErr(cyberLOBResult)) {
            return cyberLOBResult;
        }

        if (cyberLOBResult.value != null) {
            lobList.push(cyberLOBResult.value);
        }

        const directorsAndOfficersLOBResult = this.createLOBDirectorsAndOfficers(
            apiPolicy.lob_directors_and_officers,
        );
        if (isErr(directorsAndOfficersLOBResult)) {
            return directorsAndOfficersLOBResult;
        }

        if (directorsAndOfficersLOBResult.value != null) {
            lobList.push(directorsAndOfficersLOBResult.value);
        }

        const employmentPracticesLOBResult = this.createLOBEmploymentPractices(
            apiPolicy.lob_employment_practices,
        );
        if (isErr(employmentPracticesLOBResult)) {
            return employmentPracticesLOBResult;
        }

        if (employmentPracticesLOBResult.value != null) {
            lobList.push(employmentPracticesLOBResult.value);
        }

        const generalLiabilityLOBResult = this.createLOBGeneral(apiPolicy.lob_general_liability);
        if (isErr(generalLiabilityLOBResult)) {
            return generalLiabilityLOBResult;
        }

        if (generalLiabilityLOBResult.value != null) {
            lobList.push(generalLiabilityLOBResult.value);
        }

        const otherLiabilityLOBResult = this.createLOBOther(apiPolicy);
        if (isErr(otherLiabilityLOBResult)) {
            return otherLiabilityLOBResult;
        }
        if (otherLiabilityLOBResult.value != null) {
            lobList.push(otherLiabilityLOBResult.value);
        }

        const ownersAndContractorsLOBResult = this.createLOBOwnersAndContractors(apiPolicy);
        if (isErr(ownersAndContractorsLOBResult)) {
            return ownersAndContractorsLOBResult;
        }

        if (ownersAndContractorsLOBResult.value != null) {
            lobList.push(ownersAndContractorsLOBResult.value);
        }

        const professionalLOBResult = this.createLOBProfessional(
            apiPolicy.lob_professional_liability,
        );
        if (isErr(professionalLOBResult)) {
            return professionalLOBResult;
        }

        if (professionalLOBResult.value != null) {
            lobList.push(professionalLOBResult.value);
        }

        const propertyLOBResult = this.createLOBProperty(apiPolicy.lob_property);
        if (isErr(propertyLOBResult)) {
            return propertyLOBResult;
        }

        if (propertyLOBResult.value != null) {
            lobList.push(propertyLOBResult.value);
        }

        const packageLOBResult = this.createLOBPackage(apiPolicy);

        if (!packageLOBResult || isErr(packageLOBResult)) {
            return packageLOBResult;
        }

        if (packageLOBResult.value != null) {
            lobList.push(...packageLOBResult.value);
        }

        return Success(lobList);
    }

    private createLOBPackage(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<Lob[], InvalidArgument | OperationFailed> {
        if (apiPolicy.lob_package == null) {
            return Success([]);
        }

        const lobList: Immutable<Lob>[] = [];

        const autoLOBResult = this.createLOBAuto(apiPolicy.lob_package);

        if (isErr(autoLOBResult)) {
            return autoLOBResult;
        }

        if (autoLOBResult.value != null) {
            lobList.push(autoLOBResult.value);
        }

        const generalLOBResult = this.createLOBGeneral(
            apiPolicy.lob_package.general_liability_section,
        );
        if (isErr(generalLOBResult)) {
            return generalLOBResult;
        }

        if (generalLOBResult.value != null) {
            lobList.push(generalLOBResult.value);
        }

        const propertyLOBResult = this.createLOBProperty(apiPolicy.lob_package.property_section);
        if (isErr(propertyLOBResult)) {
            return propertyLOBResult;
        }

        if (propertyLOBResult.value != null) {
            lobList.push(propertyLOBResult.value);
        }

        const directorsAndOfficersLOBResult = this.createLOBDirectorsAndOfficers(
            apiPolicy.lob_package.directors_and_officers_liability_section,
        );
        if (isErr(directorsAndOfficersLOBResult)) {
            return directorsAndOfficersLOBResult;
        }

        if (directorsAndOfficersLOBResult.value != null) {
            lobList.push(directorsAndOfficersLOBResult.value);
        }

        const employmentPracticesLOBResult = this.createLOBEmploymentPractices(
            apiPolicy.lob_package.employment_practices_liability_section,
        );
        if (isErr(employmentPracticesLOBResult)) {
            return employmentPracticesLOBResult;
        }

        if (employmentPracticesLOBResult.value != null) {
            lobList.push(employmentPracticesLOBResult.value);
        }

        const professionalLiabilityLOBResult = this.createLOBProfessional(
            apiPolicy.lob_package.professional_liability_section,
        );
        if (isErr(professionalLiabilityLOBResult)) {
            return professionalLiabilityLOBResult;
        }

        if (professionalLiabilityLOBResult.value != null) {
            lobList.push(professionalLiabilityLOBResult.value);
        }

        const umbrellaLOBResult = this.createLOBUmbrellaExcessTechEO(apiPolicy, true);

        if (!umbrellaLOBResult || isErr(umbrellaLOBResult)) {
            return umbrellaLOBResult;
        }

        if (umbrellaLOBResult.value != null) {
            lobList.push(umbrellaLOBResult.value);
        }

        return Success(lobList);
    }

    private createLOBCyber(
        cyber: Immutable<Nullable<APILiabilitySection | APILiabilityPolicy>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (cyber === null || cyber === undefined) {
            return Success(null);
        }

        const section: Immutable<APILiabilitySection> = isLiabilityPolicy(cyber)
            ? cyber.liability_section
            : (cyber as Immutable<APILiabilitySection>);

        const lobResult = this.createLOBGeneric(section, 'cyberInsuranceLob');
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBDirectorsAndOfficers(
        directorsAndOfficers: Immutable<Nullable<APILiabilitySection | APILiabilityPolicy>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (directorsAndOfficers === null || directorsAndOfficers === undefined) {
            return Success(null);
        }

        const section: Immutable<APILiabilitySection> = isLiabilityPolicy(directorsAndOfficers)
            ? directorsAndOfficers.liability_section
            : (directorsAndOfficers as Immutable<APILiabilitySection>);
        const lobResult = this.createLOBGeneric(section, 'directorsAndOfficersLiabilityLob');
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBEmploymentPractices(
        empPractices: Immutable<Nullable<APILiabilityPolicy | APILiabilitySection>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (empPractices === null || empPractices === undefined) {
            return Success(null);
        }

        const section: Immutable<APILiabilitySection> = isLiabilityPolicy(empPractices)
            ? empPractices.liability_section
            : (empPractices as Immutable<APILiabilitySection>);
        const lobResult = this.createLOBGeneric(section, 'employmentPracticesLiabilityLob');
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBGeneral(
        generalLiability: Immutable<Nullable<APILiabilityPolicy | APILiabilitySection>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (generalLiability === null || generalLiability === undefined) {
            return Success(null);
        }

        const section: Immutable<APILiabilitySection> = isLiabilityPolicy(generalLiability)
            ? generalLiability.liability_section
            : (generalLiability as Immutable<APILiabilitySection>);
        const lobResult = this.createLOBGeneric(section, 'generalLiabilityLob');
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBOther(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (apiPolicy.lob_other_liability == null) {
            return Success(null);
        }

        const lobResult = this.createLOBGeneric(
            apiPolicy.lob_other_liability.liability_section,
            'otherLiabilityLob',
        );
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createPolicyLOBForSectionList(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<Lob[], InvalidArgument | OperationFailed> {
        const digitalSectionTypeToLoBMap: Map<StructuralComponentTypeCodeListItem, LobType> =
            new Map([
                [
                    'StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection',
                    'directorsAndOfficersLiabilityLob',
                ],
                [
                    'StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection',
                    'employmentPracticesLiabilityLob',
                ],
                [
                    'StructuralComponentTypeCodeListFiduciaryLiabilitySection',
                    'fiduciaryLiabilityLob',
                ],
                ['StructuralComponentTypeCodeListTechEOCyberLiabilitySection', 'cyberLiabilityLob'],
                [
                    'StructuralComponentTypeCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiabilitySection',
                    'nonTechnologyBusinessAndManagementConsultantProfessionalLiabilityLob',
                ],
                [
                    'StructuralComponentTypeCodeListTaxPreparersAndBookkeepersProfessionalLiabilitySection',
                    'taxPreparersAndBookkeepersProfessionalLiabilityLob',
                ],
                [
                    'StructuralComponentTypeCodeListAccountantsProfessionalLiabilitySection',
                    'accountantsProfessionalLiabilityLob',
                ],
                [
                    'StructuralComponentTypeCodeListRealEstateAgentsProfessionalLiabilitySection',
                    'realEstateAgentsProfessionalLiabilityLob',
                ],
                [
                    'StructuralComponentTypeCodeListHomeInspectorsProfessionalLiabilitySection',
                    'homeInspectorsProfessionalLiabilityLob',
                ],
            ]);

        if (
            apiPolicy.coverage_section_list == null ||
            apiPolicy.coverage_section_list.length === 0
        ) {
            return Success([]);
        }

        const lobResult: Lob[] = [];

        for (const section of apiPolicy.coverage_section_list) {
            const sectionResult = this.createLOBGeneric(
                section,
                digitalSectionTypeToLoBMap.get(
                    section.type as StructuralComponentTypeCodeListItem,
                ) || 'otherLiabilityLob',
            );

            if (isErr(sectionResult)) {
                return sectionResult;
            }

            lobResult.push(sectionResult.value as Lob);
        }

        return Success(lobResult);
    }

    private createLOBOwnersAndContractors(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (apiPolicy.lob_owners_and_contractors_liability == null) {
            return Success(null);
        }

        const lobResult = this.createLOBGeneric(
            apiPolicy.lob_owners_and_contractors_liability.liability_section,
            'ownersAndContractorsLiabilityLob',
        );
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBProfessional(
        professionalLiability: Immutable<Nullable<APILiabilityPolicy | APILiabilitySection>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (professionalLiability === null || professionalLiability === undefined) {
            return Success(null);
        }

        const section: Immutable<APILiabilitySection> = isLiabilityPolicy(professionalLiability)
            ? professionalLiability.liability_section
            : (professionalLiability as Immutable<APILiabilitySection>);
        const lobResult = this.createLOBGeneric(section, 'professionalLiabilityLob');
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createAdditionalInterestList(
        additional_interest_list: Immutable<any[]>,
    ): Immutable<AdditionalInterest[]> {
        return additional_interest_list.map((item) => {
            return {
                partyID: item.party_id,
                partyName: item.party_name,
                typeCode: item.type_code,
            };
        });
    }

    private createPropertyCoverageList(
        property_coverage_list: Immutable<any[]>,
    ): PropertyCoverage[] {
        return property_coverage_list.map((item) => {
            return {
                deductible: item.deductible,
                description: item.description,
                limit: item.limit,
                typeCode: item.type_code,
            };
        });
    }

    private createSubjectOfInsuranceList(
        subjectOfInsuranceList: Immutable<SubjectOfInsuranceList>,
    ): Immutable<SubjectOfInsurance[]> {
        return subjectOfInsuranceList.map((item) => {
            return {
                additionalInterestList: this.createAdditionalInterestList(
                    item.additional_interest_list,
                ),
                buildingNumber: item.building_number,
                city: item.city || '',
                id: item.id,
                natureOfBusiness: item.nature_of_business,
                propertyCoverageList: this.createPropertyCoverageList(item.property_coverage_list),
                state: item.state || '',
                streetAddress: item.street_address || '',
                zipCode: item.zip_code || '',
                deductible: item.deductible,
            };
        });
    }

    private createLOBProperty(
        propertySection: Immutable<Nullable<APIPropertySection>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (propertySection === null || propertySection === undefined) {
            return Success(null);
        }

        const liabilitySectionLimit = this.createLiabilitySectionLimit(propertySection.limit);
        if (isErr(liabilitySectionLimit)) {
            return liabilitySectionLimit;
        }

        const lobPropertySectionData: PropertySection = {
            blanketAdditionalInsured: propertySection.blanket_additional_insured,
            claimsMade: propertySection.claims_made,
            occur: propertySection.occur,
            primaryNonContributory: propertySection.primary_non_contributory,
            subjectOfInsuranceList: this.createSubjectOfInsuranceList(
                propertySection.subject_of_insurance_list,
            ) as SubjectOfInsurance[],
            waiverOfSubrogation: propertySection.waiver_of_subrogation,
            limit: liabilitySectionLimit.value,
        };

        const lobResult = LobProperty.create(lobPropertySectionData);
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBUmbrellaExcessTechEO(
        policy: Immutable<APIPolicy>,
        isPackage: Boolean,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        const section = isPackage ? policy.lob_package : policy.lob_umbrella_excess;

        if (section === null || section === undefined) {
            return Success(null);
        }

        const underlyingAgreementListResult = this.createPolicyIDsList(
            section.underlying_agreement_list,
        );
        if (isErr(underlyingAgreementListResult)) {
            return underlyingAgreementListResult;
        }

        const liabilitySection: Immutable<APILiabilitySection | null> = isUmbrellaPolicy(section)
            ? section.liability_section
            : (section as Immutable<APIPackageUmbrellaSection>).umbrella_excess_liability_section;

        if (!liabilitySection) {
            return Success(null);
        }

        const liabilityCoverageListResult = this.createLiabilitySectionList(
            liabilitySection.liability_list,
        );
        if (isErr(liabilityCoverageListResult)) {
            return liabilityCoverageListResult;
        }

        const liabilitySectionLimit = this.createLiabilitySectionLimit(liabilitySection.limit);
        if (isErr(liabilitySectionLimit)) {
            return liabilitySectionLimit;
        }

        const umbrellaSectionData: UmbrellaExcessLiabilitySection = {
            blanketAdditionalInsured: liabilitySection.blanket_additional_insured,
            blanketLimitsList: null,
            claimsMade: liabilitySection.claims_made,
            liabilityList: liabilityCoverageListResult.value as LiabilityCoverage[],
            limit: liabilitySectionLimit.value,
            occur: liabilitySection.occur,
            primaryNonContributory: liabilitySection.primary_non_contributory,
            subjectOfInsuranceList: null,
            underlyingAgreementList: underlyingAgreementListResult.value as PolicyIds[],
            waiverOfSubrogation: liabilitySection.waiver_of_subrogation,
        };

        const lobResult =
            policy.line_of_business == 'LineOfBusinessCodeListExcessTechEOCyber'
                ? LobExcessTechEOCyber.create(umbrellaSectionData)
                : LobUmbrellaExcess.create(umbrellaSectionData);

        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBWorkersCompensation(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (apiPolicy.lob_workers_comp == null) {
            return Success(null);
        }
        const workersCompensationProp = apiPolicy.lob_workers_comp;

        const liabilityCoverageListResult = this.createLiabilitySectionList(
            workersCompensationProp.liability_section.liability_list,
        );
        if (isErr(liabilityCoverageListResult)) {
            return liabilityCoverageListResult;
        }

        const locationListResult = this.createLocationList(apiPolicy);
        if (isErr(locationListResult)) {
            return locationListResult;
        }

        const liabilitySectionLimit = this.createLiabilitySectionLimit(
            workersCompensationProp.liability_section.limit,
        );
        if (isErr(liabilitySectionLimit)) {
            return liabilitySectionLimit;
        }

        const workersCompensationSectionData: WorkersCompensationLiabilitySection = {
            blanketAdditionalInsured:
                workersCompensationProp.liability_section.blanket_additional_insured,
            blanketLimitsList: null,
            claimsMade: workersCompensationProp.liability_section.claims_made,
            grossPremium: workersCompensationProp.gross_premium,
            liabilityList: liabilityCoverageListResult.value as LiabilityCoverage[],
            limit: liabilitySectionLimit.value,
            locationList: locationListResult.value as WorkersCompensationLocation[],
            occur: workersCompensationProp.liability_section.occur,
            officersIncluded: workersCompensationProp.officers_included,
            primaryNonContributory:
                workersCompensationProp.liability_section.primary_non_contributory,
            subjectOfInsuranceList: null,
            waiverOfSubrogation: workersCompensationProp.liability_section.waiver_of_subrogation,
        };
        const lobResult = LobWorkersCompensation.create(workersCompensationSectionData);
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createLOBAuto(
        autoPolicy: Immutable<Nullable<APIAutoPolicy | APIPackageAutoSection>>,
    ): Result<Nullable<Lob>, InvalidArgument | OperationFailed> {
        if (autoPolicy === null || autoPolicy === undefined) {
            return Success(null);
        }

        const driverList: Driver[] = [];
        if (autoPolicy.driver_list) {
            for (const driverElement of autoPolicy.driver_list) {
                const driverData = {
                    issuingState: driverElement.issuing_state,
                    licenseNum: driverElement.license_num,
                    name: driverElement.name,
                    numDriver: driverElement.num_driver,
                };
                const driverResult = Driver.create(driverData);
                if (isErr(driverResult)) {
                    return driverResult;
                }

                driverList.push(driverResult.value);
            }
        }

        const liabilitySection: Immutable<APILiabilitySection | null> = isAutoPolicy(autoPolicy)
            ? autoPolicy.liability_section
            : (autoPolicy as Immutable<APIPackageAutoSection>).auto_liability_section;

        if (!liabilitySection) {
            // It is possible for auto_liability_section to be null while lob_package is not null.
            return Success(null);
        }

        const liabilityCoverageResult = this.createLiabilitySectionList(
            liabilitySection.liability_list,
        );
        if (isErr(liabilityCoverageResult)) {
            return liabilityCoverageResult;
        }

        let vehicleList: Immutable<Vehicle[]> = [];
        if (isAutoPolicy(autoPolicy)) {
            const vehicleListResult = this.createVehicleList(autoPolicy.vehicle_list);
            if (isErr(vehicleListResult)) {
                return vehicleListResult;
            }
            vehicleList = vehicleListResult.value;
        }

        const liabilitySectionLimit = this.createLiabilitySectionLimit(liabilitySection.limit);
        if (isErr(liabilitySectionLimit)) {
            return liabilitySectionLimit;
        }

        const autoLiabilitySectionData: AutoLiabilitySection = {
            blanketAdditionalInsured: liabilitySection.blanket_additional_insured,
            blanketLimitsList: null,
            claimsMade: liabilitySection.claims_made,
            driverList: driverList,
            liabilityList: liabilityCoverageResult.value as LiabilityCoverage[],
            limit: liabilitySectionLimit.value,
            occur: liabilitySection.occur,
            primaryNonContributory: liabilitySection.primary_non_contributory,
            propertyCoverageList: null,
            subjectOfInsuranceList: null,
            vehicleList: vehicleList as Vehicle[],
            waiverOfSubrogation: liabilitySection.waiver_of_subrogation,
        };

        const autoLiabilitySection = AutoLiabilitySection.create(autoLiabilitySectionData);
        if (isErr(autoLiabilitySection)) {
            return autoLiabilitySection;
        }

        const lobResult = LobAuto.create(autoLiabilitySection.value as AutoLiabilitySection);
        if (isErr(lobResult)) {
            return lobResult;
        }
        return Success(lobResult.value);
    }

    private createSignatureRequestList(
        signatureRequestList: Immutable<
            {
                id: string;
                signing_url: string;
            }[]
        >,
    ): Result<SignatureRequest[], InvalidArgument | OperationFailed> {
        const signatureRequests: SignatureRequest[] = [];
        for (const signatureElement of signatureRequestList) {
            const signatureResult = SignatureRequest.create({
                id: signatureElement.id,
                signingURL: URI.build(signatureElement.signing_url),
            });
            if (isErr(signatureResult)) {
                return signatureResult;
            }
            signatureRequests.push(signatureResult.value);
        }
        return Success(signatureRequests);
    }

    private createVehicleList(
        apiLobVehicle: APIPolicyLobAutoVehicle[],
    ): Result<Vehicle[], InvalidArgument | OperationFailed> {
        const vehicleList: Immutable<Vehicle>[] = [];
        for (const vehicleElement of apiLobVehicle) {
            const garagingZipCodeResult = ZipCode.create(vehicleElement.garaging_zip);
            if (isErr(garagingZipCodeResult)) {
                return garagingZipCodeResult;
            }

            const liabilityCoverageListResult = this.createLiabilitySectionList(
                vehicleElement.liability_list,
            );
            if (isErr(liabilityCoverageListResult)) {
                return liabilityCoverageListResult;
            }

            const vehicleData: Vehicle = {
                audioVisualDataEquipmentLimit: vehicleElement.audio_visual_data_equipment_limit,
                audioVisualDataEquipmentPremium: vehicleElement.audio_visual_data_equipment_premium,
                collisionDeductible: vehicleElement.collision_deductible,
                collisionDeductibleWaiverPremium:
                    vehicleElement.collision_deductible_waiver_premium,
                collisionPremium: vehicleElement.collision_premium,
                comprehensiveDeductible: vehicleElement.comprehensive_deductible,
                comprehensivePremium: vehicleElement.comprehensive_premium,
                garagingCity: vehicleElement.garaging_city,
                garagingState: vehicleElement.garaging_state,
                garagingZip: garagingZipCodeResult.value,
                leaseLoanGapPremium: vehicleElement.lease_loan_gap_premium,
                liabilityList: liabilityCoverageListResult.value as LiabilityCoverage[],
                lossPayee: vehicleElement.loss_payee,
                name: vehicleElement.name,
                radius: vehicleElement.radius,
                rentalReimbursementDailyLimit: vehicleElement.rental_reimbursment_daily_limit,
                rentalReimbursementMaxLimit: vehicleElement.rental_reimbursment_max_limit,
                rentalReimbursementPremium: vehicleElement.rental_reimbursment_premium,
                towingAndLaborLimit: vehicleElement.towing_and_labor_limit,
                towingAndLaborPremium: vehicleElement.towing_and_labor_premium,
                usage: vehicleElement.usage as VehicleUseCodeListItem,
                vin: vehicleElement.vin,
            };

            const vehicleResult = Vehicle.create(vehicleData);
            if (isErr(vehicleResult)) {
                return vehicleResult;
            }
            vehicleList.push(vehicleResult.value);
        }

        return Success(vehicleList);
    }

    private createLiabilitySectionList(
        liabilityItemList: Immutable<APILiabilitySectionItem[]>,
    ): Result<LiabilityCoverage[], InvalidArgument | OperationFailed> {
        const liabilitySectionList: LiabilityCoverage[] = [];
        for (const liabilityItem of liabilityItemList) {
            const liabilityCoverageData: LiabilityCoverage = {
                coveredVehiclesCodeList: liabilityItem.covered_vehicles_code,
                deductible1Amount: liabilityItem.deductible1_amount,
                deductible1Code: liabilityItem.deductible1_code,
                deductible2Amount: liabilityItem.deductible2_amount,
                deductible2Code: liabilityItem.deductible2_code,
                limit1Amount: liabilityItem.limit1_amount,
                limit1Code: liabilityItem.limit1_code,
                limit1Description: liabilityItem.limit1_description,
                limit1SubType: liabilityItem.limit1_sub_type,
                limit1SubTypeOtherText: liabilityItem.limit1_sub_type_other_text,
                limit2Amount: liabilityItem.limit2_amount,
                limit2Code: liabilityItem.limit2_code,
                limit2Description: liabilityItem.limit2_description,
                premiumAmount: liabilityItem.premium_amount,
                premiumRate: liabilityItem.premium_rate,
                sir1Amount: liabilityItem.s_i_r_1_amount,
                sir1Code: liabilityItem.s_i_r_1_code,
                sir2Amount: liabilityItem.s_i_r_2_amount,
                sir2Code: liabilityItem.s_i_r_2_code,
                sirPercent: liabilityItem.s_i_r_percent,
                typeCode: liabilityItem.type_code,
            };

            const liabilityCoverageResult = LiabilityCoverage.create(liabilityCoverageData);
            if (isErr(liabilityCoverageResult)) {
                return liabilityCoverageResult;
            }

            liabilitySectionList.push(liabilityCoverageResult.value);
        }
        return Success(liabilitySectionList);
    }

    private createAttorneyRoster(
        attorneyRoster: Immutable<APIPolicyAttorney[] | undefined>,
    ): Result<PolicyAttorney[], InvalidArgument | OperationFailed> {
        const attorneyList: PolicyAttorney[] = [];
        if (attorneyRoster) {
            for (const attorneyItem of attorneyRoster) {
                const attorneyResult = PolicyAttorney.create({
                    id: attorneyItem.id,
                    averageWeeklyHours: attorneyItem.average_weekly_hours,
                    barAdmittedDate: attorneyItem.bar_admitted_date,
                    fullName: attorneyItem.full_name,
                    hireDate: attorneyItem.hire_date,
                } as PolicyAttorney);
                if (isErr(attorneyResult)) {
                    return attorneyResult;
                }

                attorneyList.push(attorneyResult.value);
            }
        }
        return Success(attorneyList);
    }

    private createPolicyIDsList(
        underlyingAgreementList: Immutable<
            {
                id: UUID;
                renewal_series_id: Nullable<UUID>;
            }[]
        >,
    ): Result<PolicyIds[], InvalidArgument | OperationFailed> {
        const policyIdsList: PolicyIds[] = [];
        for (const underlyingAgreement of underlyingAgreementList) {
            const policyId: PolicyIds = {
                id: underlyingAgreement.id,
                renewalSeriesId: underlyingAgreement.renewal_series_id,
            };
            const policyIdResult = PolicyIds.create(policyId);
            if (isErr(policyIdResult)) {
                return policyIdResult;
            }
            policyIdsList.push(policyIdResult.value);
        }
        return Success(policyIdsList);
    }

    private createLocationList(
        apiPolicy: Immutable<APIPolicy>,
    ): Result<WorkersCompensationLocation[], InvalidArgument | OperationFailed> {
        if (apiPolicy.lob_workers_comp == null) {
            return Failure(
                InvalidArgument({
                    argument: 'apiPolicy.lob_workers_comp',
                    value: apiPolicy.lob_workers_comp,
                }),
            );
        }

        const locationList: Immutable<WorkersCompensationLocation>[] = [];
        for (const apiLocation of apiPolicy.lob_workers_comp.location_list) {
            let zipCode: Nullable<ZipCode> = null;
            if (apiLocation.zip != null) {
                const zipCodeResult = ZipCode.create(apiLocation.zip);
                if (isOK(zipCodeResult)) {
                    zipCode = zipCodeResult.value;
                }
                /* temporary solution, should be:
                    if (isErr(zipCodeResult)) {
                        return zipCodeResult;
                    }
                    zipCode = zipCodeResult.value;
                */
            }

            const locationGroupList: WorkersCompensationLocationGroup[] = [];

            for (const locationGroupData of apiLocation.location_group_list) {
                const locationGroup: WorkersCompensationLocationGroup = {
                    classificationCode: locationGroupData.classification_code,
                    estimateAnnualPayroll: locationGroupData.estimate_annual_payroll,
                    groupName: locationGroupData.group_name,
                    ratingAmount: locationGroupData.rating_amount,
                    ratingRate: locationGroupData.rating_rate,
                };

                const locationGroupResult = WorkersCompensationLocationGroup.create(locationGroup);
                if (isErr(locationGroupResult)) {
                    return locationGroupResult;
                }
                locationGroupList.push(locationGroupResult.value);
            }

            const location: WorkersCompensationLocation = {
                id: apiLocation.id,
                grossPremium: apiLocation.gross_premium,
                numLocation: apiLocation.num_location,
                name: apiLocation.name,
                state: apiLocation.state,
                streetAddress: apiLocation.street_address,
                city: apiLocation.city,
                zip: zipCode,
                locationGroupList: locationGroupList,
            };

            const locationResult = WorkersCompensationLocation.create(location);
            if (isErr(locationResult)) {
                return locationResult;
            }

            locationList.push(locationResult.value);
        }
        return Success(locationList);
    }

    private createLiabilitySection(
        apiLiabilitySection: Immutable<APILiabilitySection>,
    ): Result<LiabilitySection, InvalidArgument | OperationFailed> {
        const liabilityListResult = this.createLiabilitySectionList(
            apiLiabilitySection.liability_list,
        );
        if (isErr(liabilityListResult)) {
            return liabilityListResult;
        }

        const liabilitySectionLimit = this.createLiabilitySectionLimit(apiLiabilitySection.limit);
        if (isErr(liabilitySectionLimit)) {
            return liabilitySectionLimit;
        }

        const attorneyRosterResult = this.createAttorneyRoster(apiLiabilitySection.attorney_roster);
        if (isErr(attorneyRosterResult)) {
            return attorneyRosterResult;
        }

        const liabilitySectionData: LiabilitySection = {
            attorneyRoster: attorneyRosterResult.value as PolicyAttorney[],
            blanketAdditionalInsured: apiLiabilitySection.blanket_additional_insured,
            blanketLimitsList: null,
            claimsMade: apiLiabilitySection.claims_made,
            liabilityList: liabilityListResult.value as LiabilityCoverage[],
            limit: liabilitySectionLimit.value,
            occur: apiLiabilitySection.occur,
            primaryNonContributory: apiLiabilitySection.primary_non_contributory,
            subjectOfInsuranceList: null,
            waiverOfSubrogation: apiLiabilitySection.waiver_of_subrogation,
        };
        return LiabilitySection.create(liabilitySectionData);
    }

    private createLiabilitySectionLimit(
        apiSectionLimit: APICoverageLimit,
    ): Result<Limit, InvalidArgument | OperationFailed> {
        return Limit.create({
            id: apiSectionLimit.id,
            type: apiSectionLimit.limit_type,
            amount: apiSectionLimit.limit_amount,
            description: apiSectionLimit.limit_description,
            deductibleAmount: apiSectionLimit.deductible_amount,
            deductibleCode: apiSectionLimit.deductible_code,
            sirAmount: apiSectionLimit.sir_amount,
            sirType: apiSectionLimit.sir_type,
        });
    }

    private createLOBGeneric(
        liabilitySection: Immutable<APILiabilitySection>,
        lobType: Immutable<LobType>,
    ): Result<Lob, InvalidArgument | OperationFailed> {
        const liabilitySectionResult = this.createLiabilitySection(liabilitySection);

        if (isErr(liabilitySectionResult)) {
            return liabilitySectionResult;
        }

        const lobData: Lob = {
            liabilitySection: liabilitySectionResult.value as LiabilitySection,
            lobType: lobType,
            propertySection: null,
        };

        const liabilityPolicyResult = Lob.create(lobData);
        if (isErr(liabilityPolicyResult)) {
            return liabilityPolicyResult;
        }

        return Success(liabilityPolicyResult.value);
    }

    async uploadUrl(): AsyncResult<UploadUrlResponse, InvalidArgument | OperationFailed> {
        const result = await API.request('global/upload_url');
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }

    async createPolicyFromPDF(
        createPolicyFromPDFRequest: CreatePolicyFromPDFRequest,
    ): AsyncResult<CreatePolicyFromPDFResponse, InvalidArgument | OperationFailed> {
        const result = await API.request(
            'policy/create_policy_from_pdf',
            createPolicyFromPDFRequest,
        );
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value as CreatePolicyFromPDFResponse);
    }

    async getTaskStatus(
        id: UUID,
    ): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed> {
        const result = await API.request('task/get_task_status', { id });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value as GetTaskStatusResponse);
    }

    async downloadUrl(file_key: string): AsyncResult<URI, InvalidArgument | OperationFailed> {
        const globalDownloadUrlRequest: GlobalDownloadUrlRequest = {
            content_type: 'application/pdf',
            dest_file_name: null,
            file_key: file_key,
        };

        const result = await API.request('global/download_url', globalDownloadUrlRequest);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        const resultURL = cast<URI>(result.value);
        return Success(resultURL);
    }

    async getWritingCompanyList(
        insurerIdList: UUID[],
    ): AsyncResult<WritingCompany[], GetWritingCompanyListError | OneOrMoreWritingCompanyNotFound> {
        const writingCompanyList: WritingCompany[] = [];

        const result = await API.request('policy/writing_company_get_by_id_list', {
            id_list: insurerIdList,
        });

        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error) && error.details.name === 'not_found') {
                return Failure(OneOrMoreWritingCompanyNotFound(insurerIdList));
            }

            return Failure(GetWritingCompanyListError(insurerIdList, result.errors));
        }

        for (const writingCompany of result.value) {
            const result = await WritingCompany.create({
                id: writingCompany.id,
                name: writingCompany.name,
                naicCode: writingCompany.naic_code ?? undefined,
                billingPhone: writingCompany.billing_phone,
                billingUrl: writingCompany.billing_url,
            });

            if (isErr(result)) {
                return Failure(GetWritingCompanyListError(insurerIdList, result.errors));
            }

            writingCompanyList.push(result.value);
        }

        return Success(writingCompanyList);
    }

    public async getPolicyRenewalStatus(
        policyId: string,
    ): AsyncResult<string, UnknownEntity | InvalidArgument | OperationFailed> {
        const result = await APIv2.get(`policies/actions/renewal_status/${policyId}`);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value.renewal_status);
    }

    private mapToViewPolicySectionList(apiSectionList: APIPolicySection[]): PolicySection[] {
        const results: PolicySection[] = [];
        if (apiSectionList != null) {
            for (const apiSection of apiSectionList) {
                const policySection: PolicySection = {
                    type: apiSection.type as StructuralComponentTypeCodeListItem,
                    limit: apiSection.limit.limit_amount,
                    retention: apiSection.limit.sir_amount,
                };

                if (
                    policySection.type ===
                    StructuralComponentTypeCodeListTechEOCyberLiabilitySection
                ) {
                    policySection.subLimit = getCyberLimit(apiSection);
                }

                results.push(policySection);
            }
        }

        return results;
    }
}

function getCyberLimit(apiSection: APIPolicySection): Money | undefined {
    const cyberLiabilitySection = apiSection.liability_list.find(
        (liability) => liability.type_code === 'LiabilityCoverageCodeListCyberExtortion',
    );

    const limitAmount = cyberLiabilitySection?.limit1_amount;
    return limitAmount !== null ? limitAmount : undefined;
}
