import { isDateAfter, isDateBefore } from '@embroker/service-app-engine';
import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import {
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { useModal } from '@embroker/ui-toolkit/v2';
import { addDays } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { EditApplication } from '../../../../brokerDashboard/useCases/EditApplication';
import { AppTypeCodeListESP, QuotingEngineESP } from '../../../../shopping/types/enums';
import {
    hasAnyUWReferralReasons,
    hasExistingCoverage,
} from '../../../../shopping/view/components/applicationFlags';
import { ManualQuotingConfirmationModal } from '../../../../shopping/view/components/ManualQuotingConfirmationModal';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm } from '../../../../view/hooks/useWizardForm';
import { GetSignaturePacketFileUrl } from '../../../brokerage/useCases/GetSignaturePacketFileUrl';
import { BrokerSignaturePage } from '../../../brokerage/view/components/BrokerSignaturePage';
import { QuoteBindCoverage } from '../../../entities/Quote';
import { CoverageType, ESPCoverageTypes } from '../../../esp/types/CoverageRestriction';
import { QuoteOptionsExtensionRequest } from '../../../esp/types/QuoteOptionsExtensionRequest';
import { ESPRequestHigherLimits } from '../../../esp/useCases/ESPRequestHigherLimits';
import { GetHigherLimitRequestList } from '../../../esp/useCases/GetHigherLimitRequests';
import { HigherLimitModal } from '../../../esp/view/components/ESPHigherLimitModal';
import {
    ESPGeneralTermsAndConditionsPlusAfterTechEOCyberSplitURL,
    ESPGeneralTermsAndConditionsPlusNewInsurerURL,
    ESPGeneralTermsAndConditionsPlusPostIPT2024URL,
    ESPGeneralTermsAndConditionsPlusURL,
    ESPGeneralTermsAndConditionsStandardAfterTechEOCyberSplitURL,
    ESPGeneralTermsAndConditionsStandardNewInsurerURL,
    ESPGeneralTermsAndConditionsStandardPostIPT2024URL,
    ESPGeneralTermsAndConditionsStandardURL,
} from '../../../espUtils/constants';
import { renewalQuoteDocumentsPublishDetails } from '../../../espUtils/quoteDocumentUtils';
import { DraftQuote } from '../../../useCases/DraftQuote';
import { ReferQuote } from '../../../useCases/ReferQuote';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import { DownloadQuoteButton } from '../../../view/components/DownloadQuoteButton';
import { DownloadSignaturePacketButton } from '../../../view/components/DownloadSignaturePacketButton';
import { EditApplicationButton } from '../../../view/components/EditApplicationButton';
import { MenuItem, Page, QuoteLandingPage } from '../../../view/components/QuoteLandingPage';
import { QuoteOnDemandNavigation } from '../../../view/components/QuoteOnDemandNavigation';
import { withExtraProps } from '../../../view/components/WithExtraProps';
import { ESPRenewalQuote } from '../../entities/ESPRenewalQuote';
import { ESPRenewalQuoteOptions } from '../../types/ESPRenewalQuoteOptions';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';
import { DownloadESPRenewalQuoteDocument } from '../../useCases/DownloadESPRenewalQuoteDocument';
import { GetQuote } from '../../useCases/GetQuote';
import { GetRestriction } from '../../useCases/GetRestriction';
import { PurchaseESPRenewal } from '../../useCases/PurchaseESPRenewal';
import { UpdateQuote } from '../../useCases/UpdateQuote';
import { DownloadESPRenewalGTCButton } from './DownloadESPRenewalGTCButton';
import { ESPRenewalCoveragePage } from './espRenewalCoverages/ESPRenewalCoveragePage';
import { ESPRenewalQuoteBreakdown } from './ESPRenewalQuoteBreakdown';
import { ESPRenewalQuoteSummary } from './ESPRenewalQuoteSummary';
import { EspRenewalReferredNavigation } from './ESPRenewalReferredNavigation';
import { QuoteSignature } from './signature/QuoteSignature';
import { GlobalConfig } from '../../../../config/types/GlobalConfig';
import { EPLIStandaloneInCaliforniaModal } from '../../../view/components/EPLIStandaloneInCaliforniaModal';
import { SubmitSupplementalQuestionnaire } from '../../../../higherLimitsSupplementalQuestionnaire/useCases/SubmitSupplementalQuestionnaire';

export interface ESPRenewalQuoteOptionsFormData {
    // Used for cross-fields validation with it's validation message displayed
    // in specific location of the page.
    // We need any type, other than undefined, to allow custom cross-fields
    // validation to kick in.
    coverage: 'any but undefined';

    isDnoSelected: boolean;
    dnoLevel: 'standard' | 'plus';
    dnoLimit: number;
    dnoRetention: number;

    isEplSelected: boolean;
    eplLevel: 'standard' | 'plus';
    eplLimit: number;
    eplRetention: number;

    isFiduciarySelected: boolean;
    fiduciaryLimit: number;
    fiduciaryRetention: number;

    isEOSelected: boolean;
    eoLevel: 'standard' | 'plus';
    eoLimit: number;
    eoRetention: number;

    isTechSelected?: boolean;
    techLevel: 'standard' | 'plus';
    techLimit?: number;
    techRetention?: number;

    isCyberSelected?: boolean;
    cyberLimit?: number;
    cyberRetention?: number;

    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;

    brokerSignature: boolean;
}

function toNumber(money: Money | undefined): number | undefined {
    if (money === undefined) {
        return undefined;
    }

    return Money.toFloat(money);
}

// NOTE Some quote props are not exposed through form, so we pick those from
// original quote.
const formDataToQuoteOptions = (
    formData: ESPRenewalQuoteOptionsFormData,
    quote: ESPRenewalQuote,
): ESPRenewalQuoteOptions => ({
    effectivePeriodStart: quote.previousPolicyEndDate,
    isDNOSelected: formData.isDnoSelected,
    isEPLSelected: formData.isEplSelected,
    isEOSelected: formData.isEOSelected,
    isFiduciarySelected: formData.isFiduciarySelected,
    DNOLimit: formData.dnoLimit,
    EPLLimit: formData.eplLimit,
    EOLimit: formData.eoLimit,
    DNOLevel: formData.dnoLevel,
    EPLLevel: formData.eplLevel,
    EOLevel: formData.eoLevel,
    DNORetention: formData.dnoRetention,
    EPLRetention: formData.eplRetention,
    EORetention: formData.eoRetention,
    TechLevel: formData.techLevel,
    isTechSelected: formData.isTechSelected,
    TechLimit: formData.techLimit,
    TechRetention: formData.techRetention,
    isCyberSelected: formData.isCyberSelected,
    CyberLimit: formData.cyberLimit,
    CyberRetention: formData.cyberRetention,
});

// NOTE We fill in potentially missing default quote options from form data. If
// not missing, we know they are in sync at the time we call this function.
const fromQuoteToQuoteOptions = (
    quote: ESPRenewalQuote,
    formData: ESPRenewalQuoteOptionsFormData,
): ESPRenewalQuoteOptions => ({
    effectivePeriodStart: quote.previousPolicyEndDate,
    isDNOSelected: formData.isDnoSelected,
    isEPLSelected: formData.isEplSelected,
    isEOSelected: formData.isEOSelected,
    isFiduciarySelected: formData.isFiduciarySelected,
    DNOLimit: formData.dnoLimit,
    EPLLimit: formData.eplLimit,
    EOLimit: formData.eoLimit,
    DNOLevel: formData.dnoLevel,
    EPLLevel: formData.eplLevel,
    EOLevel: formData.eoLevel,
    DNORetention: formData.dnoRetention,
    EPLRetention: formData.eplRetention,
    EORetention: formData.eoRetention,
    TechLevel: formData.techLevel,
    isTechSelected: formData.isTechSelected,
    TechLimit: formData.techLimit,
    TechRetention: formData.techRetention,
    isCyberSelected: formData.isCyberSelected,
    CyberLimit: formData.cyberLimit,
    CyberRetention: formData.cyberRetention,
});

const maxLimitForCoverageRestriction = (
    restrictions: Immutable<InsuranceApplicationRestriction>,
    coverageType: CoverageType,
): Money => {
    const maxLimit = restrictions.coverageRestrictions.find(
        (coverageRestriction) => coverageRestriction.coverageType === coverageType,
    )?.maxLimit;
    return maxLimit ? maxLimit : USD(0);
};

const findHigherLimitRequest = (
    coverageType: CoverageType,
    higherLimitRequests: Immutable<QuoteOptionsExtensionRequest[]>,
): number | undefined => {
    const higherLimitRequest = higherLimitRequests.find((hlr) => {
        const limit = hlr.coverageQuoteOptionsExtensionMap[coverageType];
        return limit ?? 0 > 0;
    });

    return higherLimitRequest !== undefined
        ? higherLimitRequest.coverageQuoteOptionsExtensionMap[coverageType]
        : undefined;
};

const mapAlreadyRequestedHigherLimits = (
    higherLimitRequests: Immutable<QuoteOptionsExtensionRequest[]>,
): Record<CoverageType, number> => {
    const result: Record<string, number> = {};
    [...ESPCoverageTypes].forEach((coverageType) => {
        const limit = findHigherLimitRequest(coverageType, higherLimitRequests);
        if (limit !== undefined) {
            result[coverageType] = limit;
        }
    });

    return result;
};

const isHigherLimitRequested = (
    coverageType: CoverageType,
    selectedLimit: number,
    restriction: Immutable<InsuranceApplicationRestriction>,
): boolean => {
    const maxLimit = maxLimitForCoverageRestriction(restriction, coverageType);
    return Money.isGreaterThan(USD(selectedLimit * 100), maxLimit);
};

const isLimitHigherThan3M = (selectedLimit: number): boolean => {
    return Money.isGreaterThan(USD(selectedLimit * 100), USD(300000000));
};

const isSupplementalQuestionnaireNeeded = (
    formData: ESPRenewalQuoteOptionsFormData,
    restriction: Immutable<InsuranceApplicationRestriction>,
    isCompanyFunded: boolean,
    isBroker: boolean,
): boolean => {
    if (
        formData.isDnoSelected &&
        isHigherLimitRequested(
            'ShoppingCoverageCodeListDirectorsAndOfficers',
            formData.dnoLimit,
            restriction,
        ) &&
        isLimitHigherThan3M(formData.dnoLimit)
    ) {
        return true;
    }

    if (isBroker && !isCompanyFunded) {
        return false;
    }

    if (
        formData.isTechSelected &&
        isHigherLimitRequested(
            'ShoppingCoverageCodeListTechSplit',
            formData.techLimit ?? 0,
            restriction,
        ) &&
        isLimitHigherThan3M(formData.techLimit ?? 0)
    ) {
        return true;
    }

    if (
        formData.isCyberSelected &&
        isHigherLimitRequested(
            'ShoppingCoverageCodeListCyberSplit',
            formData.cyberLimit ?? 0,
            restriction,
        ) &&
        isLimitHigherThan3M(formData.cyberLimit ?? 0)
    ) {
        return true;
    }

    if (
        formData.isEOSelected &&
        isHigherLimitRequested('ShoppingCoverageCodeListCyber', formData.eoLimit, restriction) &&
        isLimitHigherThan3M(formData.eoLimit ?? 0)
    ) {
        return true;
    }

    return false;
};

const createESPRenewalQuoteOptionsForm = (
    quote: ESPRenewalQuote | undefined,
    setQuote: (quote: ESPRenewalQuote) => void,
    navigate: NavigateFunction,
    isBroker: boolean,
    abortSignal: AbortSignal,
    restriction: Immutable<InsuranceApplicationRestriction> | undefined,
    globalConfig?: GlobalConfig,
) => {
    return createForm<ESPRenewalQuoteOptionsFormData>({
        fields: {
            coverage: {
                type: 'hidden',
                validator: Joi.string()
                    .custom((value, { prefs: { context }, error }) => {
                        if (
                            context === undefined ||
                            context.isDnoSelected ||
                            context.isEOSelected ||
                            context.isEplSelected ||
                            context.isFiduciarySelected ||
                            context.isTechSelected ||
                            context.isCyberSelected
                        ) {
                            return value;
                        }

                        return error('custom.noCoverageSelected');
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'custom.noCoverageSelected':
                            return 'Please select your coverage.';
                        default:
                            return error.message;
                    }
                },
            },
            isDnoSelected: {
                type: 'text',
                validator: Joi.boolean().required(),
            },
            dnoLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            dnoRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            dnoLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').optional(),
            },
            isEplSelected: {
                type: 'text',
                validator: Joi.boolean().required(),
            },
            eplLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eplRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eplLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').optional(),
            },
            isFiduciarySelected: {
                type: 'text',
                validator: Joi.boolean().required(),
            },
            fiduciaryLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            fiduciaryRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            isEOSelected: {
                type: 'text',
                validator: Joi.boolean().required(),
            },
            eoLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eoRetention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            eoLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').required(),
            },
            isTechSelected: {
                type: 'text',
                validator: Joi.boolean().optional(),
            },
            techLimit: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            techRetention: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            techLevel: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus').required(),
            },
            isCyberSelected: {
                type: 'text',
                validator: Joi.boolean().optional(),
            },
            cyberLimit: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            cyberRetention: {
                type: 'select',
                validator: Joi.number().optional(),
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return (
                        isBroker || value || helpers.error('agreementToConductSignature.invalid')
                    );
                }),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
                }),
            },
            brokerSignature: {
                type: 'hidden',
                validator: Joi.boolean().custom((value, helpers) => {
                    return !isBroker || value || helpers.error('brokerSignature.invalid');
                }),
            },
        },
        actions: {
            update: {
                action: async (formData: ESPRenewalQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const quoteOptions = formDataToQuoteOptions(formData, quote);

                    return await execute(UpdateQuote, {
                        quote,
                        quoteOptions,
                        abortSignal,
                    });
                },
                fields: [
                    'coverage',
                    'isDnoSelected',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isEplSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEOSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            sign: {
                action: async (formData: ESPRenewalQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    if (
                        (isBroker && formData.brokerSignature) ||
                        (formData.agreementToConductSignature && formData.warrantyAndFraudSignature)
                    ) {
                        return execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: [
                    'coverage',
                    'isDnoSelected',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isEplSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEOSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            downloadQuote: {
                action: async (formData: ESPRenewalQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const quoteOptions = fromQuoteToQuoteOptions(quote, formData);

                    return await execute(DownloadESPRenewalQuoteDocument, {
                        quote: quote,
                        quoteOptions: quoteOptions,
                        abortSignal: abortSignal,
                    });
                },
                fields: [
                    'coverage',
                    'isDnoSelected',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isEplSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEOSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            downloadGTC: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const documentDownload = fromQuoteToESPRenewalDownloadDocument(
                        quote,
                        globalConfig,
                    );
                    const tempLink: HTMLElement = document.createElement('a');
                    tempLink.setAttribute('href', documentDownload.url);
                    tempLink.setAttribute('download', documentDownload.fileName);
                    tempLink.setAttribute('target', '_blank');
                    tempLink.click();
                    return Success();
                },
                fields: ['dnoLevel', 'eplLevel', 'eoLevel', 'techLevel'],
            },
            downloadSignaturePacket: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(GetSignaturePacketFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: ['coverage'],
            },
            editApplication: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(EditApplication, {
                        applicationId: quote.applicationId,
                        isRenewal: true,
                    });
                },
                fields: ['coverage'],
            },
            requestHigherLimits: {
                action: async (formData: ESPRenewalQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    if (restriction === undefined) {
                        return Failure(
                            InvalidArgument({ argument: 'restriction', value: undefined }),
                        );
                    }

                    const isCompanyFunded = quote.userInfo?.isFunded || false;
                    const showSupplementalQuestionnaire = isSupplementalQuestionnaireNeeded(
                        formData,
                        restriction,
                        isCompanyFunded,
                        isBroker,
                    );

                    const espRequestHigherLimitsResult = await execute(ESPRequestHigherLimits, {
                        applicationId: quote.applicationId,
                        isSubmit: !showSupplementalQuestionnaire,
                    });
                    if (isErr(espRequestHigherLimitsResult)) {
                        return espRequestHigherLimitsResult;
                    }

                    return Success({ showSupplementalQuestionnaire });
                },
                fields: ['coverage'],
            },
            default: async (formData: ESPRenewalQuoteOptionsFormData) => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                const quoteOptions = fromQuoteToQuoteOptions(quote, formData);

                return await execute(PurchaseESPRenewal, {
                    quote: quote,
                    quoteOptions: quoteOptions,
                    abortSignal: abortSignal,
                });
            },
        },
        onSuccess: (value, action) => {
            const selectedCoverages: Array<string> = [];

            if (quote?.options.coverage.dno?.isSelected) {
                selectedCoverages.push('Directors and Officers');
            }
            if (quote?.options.coverage.epli?.isSelected) {
                selectedCoverages.push('Employment Practices Liability');
            }
            if (quote?.options.coverage.fiduciary?.isSelected) {
                selectedCoverages.push('Fiduciary');
            }
            if (
                quote?.options.coverage.eo?.isSelected ||
                quote?.options.coverage.techCyber?.isSelected
            ) {
                selectedCoverages.push('Professional Liability / Errors and Omissions');
            }

            const purchasedAppType = PurchasedAppType.create({
                appTypes: selectedCoverages,
            });

            let appTypes: string | undefined;
            if (isOK(purchasedAppType)) {
                const result = PurchasedAppType.encode(purchasedAppType.value);
                if (isOK(result)) {
                    appTypes = result.value;
                }
            }

            switch (action) {
                case 'update':
                    setQuote(value.quote);
                    break;
                case 'sign':
                    setQuote(value.quote as ESPRenewalQuote);
                    break;
                case 'downloadSignaturePacket':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'downloadQuote':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'editApplication':
                    navigate(
                        URI.build('/shopping/application/basic-info', {
                            applicationId: quote?.applicationId,
                        }),
                    );
                    break;
                case 'requestHigherLimits': {
                    const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
                    const releaseDate = globalConfig?.espIptChangesReleaseDate;
                    if (
                        espIptChangesEnabled &&
                        releaseDate &&
                        quote?.submittedAt &&
                        !isDateBefore(quote.submittedAt, releaseDate)
                    ) {
                        const defaultSupplementalQuestionnaireData = {
                            esp_what_dno_limit_currently_purchase: 'NotPurchased',
                            esp_directors_or_officers_company_percentage: 100,
                            'esp_company_owned_parent_organization.value': false,
                            esp_company_own_controlling_interest: false,
                            esp_has_company_merger_reorganization_downsizing: ['None'],
                            esp_what_eo_limit_currently_purchase: 'NotPurchased',
                            esp_how_frequently_securely_back_up_data: ['Daily'],
                        };

                        const applicationId = quote?.applicationId;
                        if (applicationId === undefined) {
                            return Failure(
                                InvalidArgument({ argument: 'applicationId', value: undefined }),
                            );
                        }
                        execute(SubmitSupplementalQuestionnaire, {
                            applicationId: applicationId,
                            questionnaireData: JSON.stringify(defaultSupplementalQuestionnaireData),
                        }).then((submitSupplementalResponse) => {
                            if (isErr(submitSupplementalResponse)) {
                                return handleOperationFailure(submitSupplementalResponse);
                            }
                            if (isBroker) {
                                navigate(URI.build('/broker/dashboard'));
                            } else {
                                navigate(URI.build('/summary'));
                            }
                        });
                    } else {
                        if (value.showSupplementalQuestionnaire) {
                            navigate(
                                URI.build('/shopping/application/supplemental-questionnaire/esp', {
                                    applicationId: quote?.applicationId,
                                }),
                            );
                        } else if (isBroker) {
                            navigate(URI.build('/broker/dashboard'));
                        } else {
                            navigate(URI.build('/summary'));
                        }
                    }
                    break;
                }
                case 'default':
                    if (isBroker) {
                        navigate(
                            URI.build('/broker/dashboard', {
                                applicationId: quote?.applicationId,
                            }),
                        );
                    } else {
                        navigate(
                            URI.build('/summary', {
                                applicationId: quote?.applicationId,
                                appTypes,
                                quotingEngine: QuotingEngineESP,
                                ...value,
                            }),
                        );
                    }
                    break;
                default:
                    break;
            }
        },
    });
};

export interface ESPRenewalQuoteLandingPageProps {
    applicationId: UUID;
}

const isPlus = (quote: ESPRenewalQuote | undefined) => {
    const coverage = quote?.options.coverage || {};
    return !!Object.keys(coverage).find(
        (key) => coverage[key].level === 'plus' && coverage[key].isSelected,
    );
};

const mapToCoverageRestrictionCoverageType = (coverageType: string): CoverageType => {
    switch (coverageType) {
        case 'dno':
            return 'ShoppingCoverageCodeListDirectorsAndOfficers';
        case 'epli':
            return 'ShoppingCoverageCodeListEmploymentPractices';
        case 'fiduciary':
            return 'ShoppingCoverageCodeListFiduciary';
        case 'techCyber':
            return 'ShoppingCoverageCodeListTechSplit';
        default:
            return 'ShoppingCoverageCodeListCyber';
    }
};

const checkHigherLimits = (
    quote: ESPRenewalQuote,
    restrictions?: Immutable<InsuranceApplicationRestriction>,
) => {
    let isHigherLimitsQuote = false;
    const coverageDictionary = quote.options.coverage;
    for (const coverageType in coverageDictionary) {
        const maxLimit = restrictions?.coverageRestrictions.find(
            (coverageRestriction) =>
                coverageRestriction.coverageType ===
                mapToCoverageRestrictionCoverageType(coverageType),
        )?.maxLimit?.amount;
        const maxLimitInDollars = maxLimit ? maxLimit : 300000000;
        if (
            coverageDictionary[coverageType].isSelected &&
            coverageDictionary[coverageType].limit.amount > maxLimitInDollars
        ) {
            isHigherLimitsQuote = true;
        }
    }
    return isHigherLimitsQuote;
};

const fromQuoteToESPRenewalDownloadDocument = (
    quote: ESPRenewalQuote,
    globalConfig?: GlobalConfig,
): { url: string; fileName: string } => {
    const url = getUrlBasedOnSubmittedDate(quote, globalConfig);
    const { [0]: fileName } = url.split('/').slice(-1);

    return {
        url,
        fileName,
    };
};

const getUrlBasedOnSubmittedDate = (
    quote: ESPRenewalQuote,
    globalConfig?: GlobalConfig,
): string => {
    const espDocumentsAfterEoCyberSplitReleaseDate = quote.espDocumentsAfterEoCyberSplitReleaseDate;
    const newInsurerDocumentsReleaseDate = quote.newInsurerDocumentsReleaseDate;
    const submittedAt = quote.submittedAt;
    const isPlusSelected = isPlus(quote);

    const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
    const releaseDate = globalConfig?.espIptChangesReleaseDate;
    if (
        espIptChangesEnabled &&
        releaseDate &&
        quote?.options.effectiveDate &&
        !isDateBefore(quote?.options.effectiveDate, releaseDate)
    ) {
        return isPlusSelected
            ? ESPGeneralTermsAndConditionsPlusPostIPT2024URL
            : ESPGeneralTermsAndConditionsStandardPostIPT2024URL;
    }

    if (
        espDocumentsAfterEoCyberSplitReleaseDate &&
        submittedAt &&
        isDateAfter(submittedAt, espDocumentsAfterEoCyberSplitReleaseDate)
    ) {
        return isPlusSelected
            ? ESPGeneralTermsAndConditionsPlusAfterTechEOCyberSplitURL
            : ESPGeneralTermsAndConditionsStandardAfterTechEOCyberSplitURL;
    }
    if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
        if (
            isSubmissionDateAfterPublishDate(
                submittedAt,
                newInsurerDocumentsReleaseDate,
                quote.userInfo?.usaState,
            )
        ) {
            return isPlusSelected
                ? ESPGeneralTermsAndConditionsPlusNewInsurerURL
                : ESPGeneralTermsAndConditionsStandardNewInsurerURL;
        }
    }
    return isPlusSelected
        ? ESPGeneralTermsAndConditionsPlusURL
        : ESPGeneralTermsAndConditionsStandardURL;
};

const isSubmissionDateAfterPublishDate = (
    submittedAt: Date,
    newInsurerDocumentsReleaseDate: Date,
    state?: Nullable<State>,
): boolean => {
    let daysToPublishByState = 0;

    if (state !== undefined && state !== null) {
        daysToPublishByState = renewalQuoteDocumentsPublishDetails[state].daysToPublish;
    }

    return isDateAfter(submittedAt, addDays(newInsurerDocumentsReleaseDate, daysToPublishByState));
};

export function ESPRenewalQuoteLandingPage({ applicationId }: ESPRenewalQuoteLandingPageProps) {
    const [quote, setQuote] = useState<ESPRenewalQuote>();
    const { result: getQuoteResult } = useUseCase(GetQuote, { applicationId });
    const { activeSession, globalConfig } = useContext(AppContext);
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const isAdmin = hasRole(activeSession, 'admin');
    const isBroker = hasRole(activeSession, 'broker');
    const [restriction, setRestriction] = useState<Immutable<InsuranceApplicationRestriction>>();
    const [higherLimitRequests, setHigherLimitRequests] = useState<
        Immutable<QuoteOptionsExtensionRequest[]>
    >([]);
    const { navigate } = useNavigation();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const higherLimitModal = useModal();
    const referredManualModal = useModal();
    const epliStandaloneInCaliforniaModal = useModal();

    const handleBindCoverageEvent = useCallback(() => {
        if (!quote) {
            return;
        }
        execute(CalculateSKU, {
            event: 'quote',
            applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: quote.totalPayable,
                applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: true,
            };
            eventBus.publish(event);
        });
    }, [applicationId, eventBus, quote]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    useEffect(() => {
        let isCanceled = false;
        execute(GetRestriction, {
            insuranceApplicationId: applicationId,
        })
            .then((result) => {
                if (isCanceled) {
                    return;
                }

                if (isOK(result)) {
                    setRestriction(result.value.restriction);
                } else {
                    setRestriction(undefined);
                }
            })
            .catch(() => {
                if (isCanceled) {
                    return;
                }
            });
        return () => {
            isCanceled = true;
        };
    }, [applicationId]);

    useEffect(() => {
        let isCanceled = false;
        if (quote && quote.status === 'draft') {
            if (checkHigherLimits(quote, restriction)) {
                execute(ReferQuote, { quote }).then((result) => {
                    if (isCanceled) {
                        return;
                    }
                    if (isOK(result)) {
                        setQuote(result.value.quote as ESPRenewalQuote);
                    }
                });
            }
        }
        return () => {
            isCanceled = true;
        };
    }, [quote, restriction]);

    useEffect(() => {
        if (getQuoteResult !== undefined && isOK(getQuoteResult)) {
            setQuote(getQuoteResult.value.quote);
        }
    }, [getQuoteResult]);

    useEffect(() => {
        let isCanceled = false;
        execute(GetHigherLimitRequestList, {
            applicationId: applicationId,
        })
            .then((result) => {
                if (isCanceled) {
                    return;
                }

                if (isOK(result)) {
                    setHigherLimitRequests(result.value);
                } else {
                    setHigherLimitRequests([]);
                }
            })
            .catch(() => {
                if (isCanceled) {
                    return;
                }
            });

        return () => {
            isCanceled = true;
        };
    }, [applicationId]);

    const optionsForm = useMemo(
        () =>
            createESPRenewalQuoteOptionsForm(
                quote,
                setQuote,
                navigate,
                isBroker,
                abortController.signal,
                restriction,
                globalConfig,
            ),
        [quote, navigate, abortController.signal, isBroker, restriction, globalConfig],
    );

    const initialValue: ESPRenewalQuoteOptionsFormData = {
        coverage: 'any but undefined',
        isDnoSelected: quote?.options.coverage.dno?.isSelected ?? false,
        dnoLimit: toNumber(quote?.options.coverage.dno?.limit) ?? 1000000,
        dnoRetention: toNumber(quote?.options.coverage.dno?.retention) ?? 10000,
        dnoLevel: quote?.options.coverage.dno?.level ?? 'standard',
        isEplSelected: quote?.options.coverage.epli?.isSelected ?? false,
        eplLimit: toNumber(quote?.options.coverage.epli?.limit) ?? 1000000,
        eplRetention: toNumber(quote?.options.coverage.epli?.retention) ?? 10000,
        eplLevel: quote?.options.coverage.epli?.level ?? 'standard',
        isFiduciarySelected: quote?.options.coverage.fiduciary?.isSelected ?? false,
        fiduciaryLimit: toNumber(quote?.options.coverage.fiduciary?.limit) ?? 1000000,
        fiduciaryRetention: toNumber(quote?.options.coverage.fiduciary?.retention) ?? 0,
        isEOSelected: quote?.options.coverage.eo?.isSelected ?? false,
        eoLimit: toNumber(quote?.options.coverage.eo?.limit) ?? 1000000,
        eoRetention: toNumber(quote?.options.coverage.eo?.retention) ?? 2500,
        eoLevel: quote?.options.coverage.eo?.level ?? 'plus',
        isTechSelected: quote?.options.coverage.techCyber?.isSelected,
        techLimit: toNumber(quote?.options.coverage.techCyber?.limit),
        techRetention: toNumber(quote?.options.coverage.techCyber?.retention),
        techLevel: quote?.options.coverage.techCyber?.level ?? 'plus',
        isCyberSelected: quote?.options.coverage.techCyber?.isSelected,
        cyberLimit: toNumber(quote?.options.coverage.techCyber?.secondLimit),
        cyberRetention: toNumber(quote?.options.coverage.techCyber?.secondRetention),
        agreementToConductSignature: false,
        warrantyAndFraudSignature: false,
        brokerSignature: false,
    };
    const optionsWizardForm = useWizardForm(optionsForm, {
        pages: [
            {
                name: 'coverage',
                fields: [
                    'coverage',
                    'isDnoSelected',
                    'dnoLimit',
                    'dnoRetention',
                    'dnoLevel',
                    'isEplSelected',
                    'eplLimit',
                    'eplRetention',
                    'eplLevel',
                    'isFiduciarySelected',
                    'fiduciaryLimit',
                    'fiduciaryRetention',
                    'isEOSelected',
                    'eoLimit',
                    'eoRetention',
                    'eoLevel',
                    'isTechSelected',
                    'techLimit',
                    'techRetention',
                    'techLevel',
                    'isCyberSelected',
                    'cyberLimit',
                    'cyberRetention',
                ],
            },
            {
                name: 'signature',
                fields: isBroker
                    ? ['brokerSignature']
                    : ['agreementToConductSignature', 'warrantyAndFraudSignature'],
            },
        ],
        initialValue,
    });

    const pages: Page<ESPRenewalQuoteOptionsFormData, ESPRenewalQuote>[] = [
        {
            name: 'coverage',
            component: ESPRenewalCoveragePage,
        },
        {
            name: 'signature',
            component: isBroker ? BrokerSignaturePage : QuoteSignature,
        },
    ];

    const isFormDirty = optionsWizardForm.status === 'dirty';
    const isQuoteReferred = quote?.status === 'referred';
    useEffect(() => {
        let isCanceled = false;
        if (quote && isFormDirty && isQuoteReferred) {
            execute(DraftQuote, { quote }).then((result) => {
                if (isCanceled) {
                    return;
                }
                if (isOK(result)) {
                    setQuote(result.value.quote as ESPRenewalQuote);
                }
            });
        }
        return () => {
            isCanceled = true;
        };
    }, [quote, isFormDirty, isQuoteReferred]);

    const EditApplicationItem: MenuItem<ESPRenewalQuoteOptionsFormData> = {
        component: EditApplicationButton,
    };

    const DownloadQuoteItem: MenuItem<ESPRenewalQuoteOptionsFormData> = {
        component: DownloadQuoteButton,
        isDisabled: isFormDirty || isQuoteReferred,
    };

    const DownloadSignaturePacket: MenuItem<ESPRenewalQuoteOptionsFormData> = {
        component: DownloadSignaturePacketButton,
        isDisabled: isQuoteReferred || isFormDirty,
    };

    const DownloadESPRenewalGTCItem: MenuItem<ESPRenewalQuoteOptionsFormData> = {
        component: DownloadESPRenewalGTCButton(isPlus(quote)),
        isDisabled: isFormDirty,
    };

    const shouldDisableEditApplication =
        !isAdmin &&
        isBroker &&
        quote &&
        quote.espDocumentsAfterEoCyberSplitReleaseDate &&
        quote.espDocumentsAfterEoCyberSplitReleaseDate < new Date(Date.now()) &&
        quote.options.coverage['eo'];
    const menuItems = shouldDisableEditApplication
        ? [DownloadESPRenewalGTCItem, DownloadQuoteItem, DownloadSignaturePacket]
        : isBroker
        ? [
              DownloadESPRenewalGTCItem,
              DownloadQuoteItem,
              DownloadSignaturePacket,
              EditApplicationItem,
          ]
        : [DownloadESPRenewalGTCItem, DownloadQuoteItem];

    const isHigherLimitRequested = quote && checkHigherLimits(quote, restriction);
    const handleReferredCTAButton = () => {
        if (
            !isHigherLimitRequested &&
            isBroker &&
            hasAnyUWReferralReasons(quote?.referralReasons)
        ) {
            navigate(URI.build('/broker/dashboard', { modal: 'quoteReferred' }));
            return;
        }
        if (
            !isHigherLimitRequested &&
            !hasExistingCoverage(quote?.referralReasons) &&
            !hasAnyUWReferralReasons(quote?.referralReasons)
        ) {
            if (isBroker) {
                navigate(resolveBrokerReferral(quote?.referralReasons));
                return;
            }
            const clientReferralUri = resolveClientReferral(quote?.referralReasons);
            if (clientReferralUri) {
                navigate(clientReferralUri);
            } else {
                referredManualModal.show();
            }
            return;
        }
        isHigherLimitRequested ? higherLimitModal.show() : referredManualModal.show();
    };
    const navigation =
        !isQuoteReferred || isFormDirty
            ? QuoteOnDemandNavigation
            : withExtraProps(EspRenewalReferredNavigation, {
                  onReferredCTAButtonClick: handleReferredCTAButton,
                  referredCTAButtonLabel: isHigherLimitRequested
                      ? 'Request Higher Limit'
                      : 'Submit for Review',
              });

    if (globalConfig === undefined) {
        return null;
    }

    const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
    const releaseDate = globalConfig?.espIptChangesReleaseDate;
    const shouldHaveIptChanges =
        espIptChangesEnabled &&
        releaseDate &&
        quote?.submittedAt &&
        !isDateBefore(quote.submittedAt, releaseDate);

    return (
        <React.Fragment>
            <EPLIStandaloneInCaliforniaModal
                modal={epliStandaloneInCaliforniaModal}
                appId={applicationId}
            />
            <HigherLimitModal
                modal={higherLimitModal}
                trigger={optionsWizardForm.trigger}
                shouldHaveIptChanges={shouldHaveIptChanges}
            />
            <ManualQuotingConfirmationModal modal={referredManualModal} />
            <QuoteLandingPage
                title="ESP Renewal"
                appTypeCode={AppTypeCodeListESP}
                brandName="Summary"
                applicationId={applicationId}
                quote={quote}
                optionsWizardForm={optionsWizardForm}
                pages={pages}
                exitUrl={cast<URI>(isBroker ? '/broker/dashboard' : '/summary')}
                quoteBreakdown={ESPRenewalQuoteBreakdown}
                quoteSummary={ESPRenewalQuoteSummary}
                menuItems={menuItems}
                pageNavigation={navigation}
                handleBindCoverage={handleBindCoverageEvent}
                restriction={restriction}
                higherLimitRequests={mapAlreadyRequestedHigherLimits(
                    higherLimitRequests.filter((request) => {
                        return request.status === 'QuoteOptionsExtensionRequestStatusProcessed';
                    }),
                )}
                showEpliStandaloneInCalifornia={epliStandaloneInCaliforniaModal.show}
            />
        </React.Fragment>
    );
}

function resolveClientReferral(referralReasons?: Readonly<Array<string>>): Nullable<URI> {
    if (referralReasons !== undefined) {
        if (referralReasons.some((reason) => reason == 'Client reservation')) {
            return URI.build('/summary', { modal: 'clientReservation' });
        }
    }

    return null;
}

export function resolveBrokerReferral(referralReasons?: Readonly<Array<string>>): URI {
    if (referralReasons !== undefined) {
        if (referralReasons.some((reason) => reason == 'Broker not licensed in state')) {
            return URI.build('/broker/dashboard', { modal: 'stateUnlicensed' });
        } else if (referralReasons.some((reason) => reason.includes('Client reservation'))) {
            return URI.build('/broker/dashboard', { modal: 'clientReservation' });
        }
    }

    return URI.build('/broker/dashboard', { modal: 'quoteReferred' });
}
