import { commonQuoteProps, Quote } from '../../entities/Quote';
import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { SKU } from '@app/analytics/types/SKU';

export interface BOPHartfordPaymentEvent {
    type: 'Success' | 'Error' | 'Cancel' | 'Start Over' | 'AppLoaded';
    lob: string[];
    tId: string;
    messages: {
        bopErrorMessage: string;
        errorMessage: string;
        wcErrorMessage: string;
    };
}

export interface BOPHartfordPurchased extends DomainEvent {
    readonly origin: 'HartfordBOPQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Nullable<Money>;
    readonly applicationId: UUID;
    sku?: SKU;
    readonly isRenewal: boolean;
}

export interface BOPHartfordQuoteOptions {
    readonly effectiveDate: Date;
    readonly bppLimit: Money;
    readonly bopCoverageType: 'LiabOnly' | 'PropLiab';
}

export interface BOPHartfordQuoteDetails {
    readonly paymentUrl: string;
    readonly coverages: HartfordBopCoverage[];
}

export interface HartfordBopCoverage {
    readonly code: string;
    readonly description: string;
    readonly limits: HartfordBopCoverageLimit[];
}

export interface HartfordBopCoverageLimit {
    readonly code: string;
    readonly description: string;
    readonly limit: Money;
}

export interface BOPHartfordQuote extends Quote {
    readonly options: BOPHartfordQuoteOptions;
    readonly details: BOPHartfordQuoteDetails;
    readonly userInfo?: HartfordUserInfo;
    readonly squareFootageAtLocation: number | undefined;
}

export interface HartfordUserInfo {
    readonly company: string;
    readonly fullName: string;
    readonly title: string;
    readonly usaState: State;
    readonly address: MailingAddress;
}

export const HartfordUserInfo = {
    ...defineValidator<HartfordUserInfo>({
        company: Joi.string().required(),
        fullName: Joi.string().required(),
        title: Joi.string().required(),
        usaState: State.schema.allow(null),
        address: MailingAddress.schema,
    }),
    create(userInfo: HartfordUserInfo) {
        return HartfordUserInfo.validate(userInfo);
    },
};

export const BOPHartfordQuote = entity<BOPHartfordQuote, Quote>({
    validator: defineEntityValidator<BOPHartfordQuote>({
        ...commonQuoteProps,
        userInfo: HartfordUserInfo.schema.optional(),
        fileKey: Joi.string().optional(),
        squareFootageAtLocation: Joi.number().optional(),
    }),
    inherits: Quote,
});

export interface PurchaseResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}
