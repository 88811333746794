import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import {
    Aborted,
    ErrorCode,
    InvalidArgument,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable } from '@embroker/shotwell/core/types';
import { isOK, Result } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import {
    Card,
    ColumnLayout,
    GridLayout,
    Pagination,
    Text,
    TextButton,
    usePagination,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useEffect, useState } from 'react';
import { Application, ApplicationDeleted } from '../../../shopping/entities/Application';
import {
    GetApplicationList,
    GetApplicationListRequest,
    GetApplicationListResponse,
} from '../../../shopping/useCases/GetApplicationList';
import { Session } from '../../../userOrg/entities/Session';
import { InsuranceApplicationCardList } from './InsuranceApplicationCardList';

const APPLICATIONS_PER_PAGE = 9;

interface AllApplicationsProps {
    activeSession: Immutable<EntityProps<Session>>;
    toggleAllApps: () => void;
    sectionType: String;
    title: String;
    isRenewal?: boolean;
}

export function AllApplications({
    activeSession,
    toggleAllApps,
    sectionType,
    title,
    isRenewal = false,
}: AllApplicationsProps) {
    const [canViewLplQuotes, setCanViewLplQuotes] = useState<boolean>(false);
    const [canViewPCoMLQuotes, setCanViewPCoMLQuotes] = useState<boolean>(false);
    const [canViewCnaBopQuotes, setCanViewCnaBopQuotes] = useState<boolean>(false);
    const [canViewCrimeQuotes, setCanViewCrimeQuotes] = useState<boolean>(false);
    const [canViewCyberQuotes, setCanViewCyberQuotes] = useState<boolean>(false);
    const [canViewExcessQuotes, setCanViewExcessQuotes] = useState<boolean>(false);
    const [applicationList, setApplicationList] = useState<EntityProps<Application>[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [hasFailed, setHasFailed] = useState<boolean>(false);
    const pagination = usePagination({
        totalPages: totalCount / APPLICATIONS_PER_PAGE,
    });

    const reloadPage = useCallback(
        (currentPageIndex: number, abortSignal?: AbortSignal) => {
            const requestData: GetApplicationListRequest = {
                listOptions: {
                    paging: {
                        index: currentPageIndex,
                        size: APPLICATIONS_PER_PAGE,
                    },
                    sort_column: null,
                    filters: null,
                },
                abortSignal: abortSignal,
                sectionType: sectionType,
            };
            execute(GetApplicationList, requestData).then(
                (
                    result: Result<
                        GetApplicationListResponse,
                        InvalidArgument | OperationFailed | Aborted | UnknownEntity
                    >,
                ) => {
                    if (isOK(result)) {
                        setApplicationList(
                            result.value.applicationList as EntityProps<Application>[],
                        );
                        setTotalCount(result.value.pageInfo.totalItems);
                        setCanViewLplQuotes(result.value.canViewLplQuotes);
                        setCanViewPCoMLQuotes(result.value.canViewPCoMLQuotes);
                        setCanViewCnaBopQuotes(result.value.canViewCnaBopQuotes);
                        setCanViewCrimeQuotes(result.value.canViewCrimeQuotes);
                        setCanViewCyberQuotes(result.value.canViewCyberQuotes);
                        setCanViewExcessQuotes(result.value.canViewExcessQuotes);
                        setHasFailed(false);
                    } else if (result.errors[0].code !== ErrorCode.Aborted) {
                        setHasFailed(true);
                    }
                },
            );
        },
        [sectionType],
    );

    useDomainEvent<ApplicationDeleted>('Application', 'Deleted', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        reloadPage(pagination.currentPageIndex);
    });

    useEffect(() => {
        const abortController = new AbortController();
        reloadPage(pagination.currentPageIndex, abortController.signal);
        return () => {
            abortController.abort();
        };
    }, [reloadPage, pagination.currentPageIndex]);

    return (
        <GridLayout>
            <TextButton
                size="small"
                icon="bold-caret-left"
                iconPosition="left"
                onClick={toggleAllApps}
                className="u-grid-size-12"
            >
                Back to Dashboard
            </TextButton>
            <ColumnLayout
                split={pagination.totalPages > 1 ? '-1' : undefined}
                className="u-grid-size-12"
            >
                <Text style="heading 4">{title}</Text>
                {pagination.totalPages > 1 && <Pagination {...pagination}></Pagination>}
            </ColumnLayout>
            {hasFailed ? (
                <Card center className="u-grid-size-12">
                    <Text style="body 1">Failed to load page. Please try again.</Text>
                </Card>
            ) : (
                <InsuranceApplicationCardList
                    applicationList={applicationList}
                    activeSession={activeSession}
                    canViewLplQuotes={canViewLplQuotes}
                    canViewPCoMLQuotes={canViewPCoMLQuotes}
                    canViewCnaBopQuotes={canViewCnaBopQuotes}
                    canViewCrimeQuotes={canViewCrimeQuotes}
                    canViewCyberQuotes={canViewCyberQuotes}
                    canViewExcessQuotes={canViewExcessQuotes}
                    maxApplications={APPLICATIONS_PER_PAGE}
                    isRenewal={isRenewal}
                />
            )}
        </GridLayout>
    );
}
