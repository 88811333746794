import React from 'react';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';

type Props = {
    email: EmailAddress;
    onEmailChange: (e: { target: { value: any } }) => void;
    onBackClicked: () => void;
    onContinueClicked: () => void;
    errors: any[];
};

export function EmailInputPage({
    email,
    onEmailChange,
    onBackClicked,
    onContinueClicked,
    errors,
}: Props) {
    return (
        <FormLayout>
            <StackLayout>
                <Text style="heading 3">Great, now let's get your contact details.</Text>
            </StackLayout>
            <StackLayout gap="12">
                <Form.Field
                    data-e2e="sign-up-username"
                    label="Email address"
                    title="What email can we use to save your quote?"
                    type="email"
                    inputProps={{
                        onChange: onEmailChange,
                        value: email,
                        autoComplete: 'email',
                        hasErrors: errors.length > 0,
                    }}
                    messages={errors}
                />
                <StatusMessage status="info">
                    Don't worry, your email is safe with us. We'll only use it to save your quote
                    and to keep you updated
                </StatusMessage>
                <StackLayout gap="24">
                    <div />
                    <ButtonBar
                        split={'-1'}
                        reverse
                        responsive={{ containerWidth: { smallerThan: 400 } }}
                    >
                        <TextButton
                            icon="thin-arrow-left"
                            iconPosition="left"
                            iconSize="medium"
                            onClick={onBackClicked}
                        >
                            Back
                        </TextButton>
                        <Button
                            icon="thin-arrow-right"
                            iconPosition="right"
                            iconSize="medium"
                            onClick={onContinueClicked}
                            disabled={!email}
                        >
                            Next
                        </Button>
                    </ButtonBar>
                </StackLayout>
            </StackLayout>
        </FormLayout>
    );
}
