import React from 'react';
import {
    Immutable,
    StackLayout,
    PageLayout,
    useResponsive,
    FormLayout,
} from '@embroker/ui-toolkit/v2';
import { FormViewLayout } from '@app/view/components/FormViewLayout.view';
import { SignUpBanner } from './SignUpBanner.view';
import { SignUpTermsAndConditions } from './SignUpTermsAndConditions.view';

interface SignUpWrapperProps {
    children: React.ReactNode;
    submitText: string;
    formMessages: Immutable<string[]>;
    disableButton: boolean;
    submit: (event: React.FormEvent) => void;
    handleBack?: () => void;
    handleBackText?: string;
    optionalLink?: React.ReactNode;
}

export const SignUpWrapper = ({
    children,
    submitText,
    disableButton,
    formMessages = [],
    submit,
    handleBack,
    handleBackText,
    optionalLink,
}: SignUpWrapperProps) => {
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'tablet' } });
    return (
        <StackLayout gap={isMobile ? '20' : 'none'}>
            <SignUpBanner />
            <PageLayout.Section>
                <FormViewLayout
                    title="Let's get your quote started!"
                    errorMessages={formMessages}
                    subTitle="Get a quote in minutes! Tell us a bit about your business so we can find the right insurance for you."
                    disableSubmit={disableButton}
                    onFormSubmit={submit}
                    submitText={submitText}
                    handleBack={handleBack}
                    backText={handleBackText}
                    data-e2e-submit="user-org-public-submit-btn"
                    optionalLink={optionalLink}
                >
                    {children}
                </FormViewLayout>
                <FormLayout>
                    <SignUpTermsAndConditions />
                </FormLayout>
            </PageLayout.Section>
        </StackLayout>
    );
};
