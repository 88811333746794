import { Header } from '@embroker/ui-toolkit/v2';
import { NavigationalLogo } from './NavigationalLogo';
import React, { MouseEvent } from 'react';

interface HeaderViewProps {
    children?: React.ReactNode;
    logoHref?: string;
    onLogoClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

export function HeaderView({ children, logoHref, onLogoClick }: HeaderViewProps) {
    return (
        <Header>
            <NavigationalLogo href={logoHref} onLogoClick={onLogoClick} />
            {children}
        </Header>
    );
}
