import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { container } from '@embroker/shotwell/core/di';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { Spinner, useModal } from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { LawBundleQuestionnaireData } from '../../../types/LawBundleQuestionnaireData';
import { FormEventName, PublishFormEvents } from '../../../useCases/PublishFormEvents';
import {
    GetLawBundleApplications,
    UnderlyingApp,
} from '../../../useCases/GetLawBundleApplications';
import { CoverageType } from '../../../types/CoverageType';
import { CoverageRecommendationLayout } from './CoverageRecommendationLayout.view';
import {
    PublishShoppingUserClickEvent,
    ShoppingUserClickEventName,
} from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { LPLDeselectedModal } from './LPLDeselectedModal.view';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Organization } from '@app/userOrg/entities/Organization';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import {
    BOPCoverageRecommendationCard,
    CyberCoverageRecommendationCard,
    LPLCoverageRecommendationCard,
    WCCoverageRecommendationCard,
} from './CoverageRecommendationLayoutCardTemplates';
import { CoverageInformationSlideout } from './CoverageInformationSlideout.view';
import { CreateLawBundleApplication } from '@app/shopping/useCases/CreateLawBundleApplication';
import { URI } from '@embroker/shotwell/core/types/URI';
import { navigateToErrorPage } from '@app/view/errors';
import { PublishPurchaseIntentEvent } from '@app/shopping/useCases/PublishPurchaseIntentEvent';
import { AppTypeCode, AppTypeCodeListLawBundle } from '@app/shopping/types/enums';
import { Vertical } from '@app/userOrg/types/enums';
import { AppContext } from '@app/view/AppContext';

export type AppTypesProps = Record<CoverageType, AppTypeCode>;

const AppTypes: AppTypesProps = {
    bop: 'AppTypeCodeListBOPChubb',
    cyber: 'AppTypeCodeListCyberCowbell',
    lpl: 'AppTypeCodeListEverestLawyersProfessionalLiability',
    wc: 'AppTypeCodeListWCChubb',
};

export function IndicativePremiumPage() {
    const { navigate } = useNavigation();

    const abortController = useMemo(() => new AbortController(), []);
    useEffect(() => () => abortController.abort(), [abortController]);

    const {
        result: getActiveOrganizationProfileResp,
        isLoading: getActiveOrganizationProfileLoading,
    } = useUseCase(GetActiveOrganizationProfile);

    const [radioItems, setRadioItems] = useState<UnderlyingApp[]>([]);
    const { setSlideout } = useContext(AppContext);
    const lawVertical2v4ProductsIsVariant = useMemo(
        () =>
            container
                .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
                .getFeatureValue('law-vertical-product-page-2-vs-4-products', false),
        [],
    );

    const isAllRadioItemsNotSelected = useMemo(
        () => radioItems.reduce((acc, item) => acc && item.isSelected === false, true),
        [radioItems],
    );

    const [isCreateApplicationLoading, setIsCreateApplicationLoading] = useState<boolean>(false);

    const selectedCoverageToggles: CoverageType[] = radioItems
        .filter((info) => info.isSelected)
        .map((info) => info.value);

    const lplDeselectedModal = useModal({ visible: false });

    const { result: getLawBundleApplicationsResult } = useUseCase(GetLawBundleApplications);
    useEffect(() => {
        const underlyingApps =
            getLawBundleApplicationsResult && isOK(getLawBundleApplicationsResult)
                ? getLawBundleApplicationsResult.value.underlyingApps
                : undefined;

        if (underlyingApps) {
            setRadioItems(underlyingApps as UnderlyingApp[]);
        }
    }, [getLawBundleApplicationsResult]);

    useEffect(() => {
        execute(PublishPurchaseIntentEvent, { appTypes: [AppTypeCodeListLawBundle] });
        const vertical: Vertical = 'LawFirm';
        execute(PublishFormEvents, {
            eventName: FormEventName.Viewed,
            formName: vertical,
            formStage: 'product-selection',
            applicationId: null,
            appTypeList: [AppTypeCodeListLawBundle],
            isRenewal: false,
        });
    }, []);

    const startApplication = async (
        coverageToggles: CoverageType[],
        questionnaireData: LawBundleQuestionnaireData = {},
        clickEventName?: ShoppingUserClickEventName,
    ) => {
        setIsCreateApplicationLoading(true);

        if (clickEventName) {
            execute(PublishShoppingUserClickEvent, { clickEventName });
        }

        const result = await execute(CreateLawBundleApplication, {
            abortSignal: abortController.signal,
            selectedCoverageTypes: coverageToggles,
            additionalQuestionnaireData: questionnaireData,
        });

        setIsCreateApplicationLoading(false);

        if (isErr(result)) {
            navigateToErrorPage(navigate, result.errors);
            return;
        }

        navigate(
            URI.build('/shopping/application', {
                applicationId: result.value.applicationIdList[0],
            }),
        );
    };

    const handleGetStarted = (coverageToggles: CoverageType[]) => {
        if (!coverageToggles.includes(CoverageType.LPL)) {
            lplDeselectedModal.show();
            return;
        }

        startApplication(coverageToggles);
    };

    const confirmApplicationWithoutLPLGetAQuote = () => {
        startApplication(
            selectedCoverageToggles.concat(CoverageType.LPL),
            {},
            'LPL Deselected Pop up _ get a quote clicked',
        );
    };

    const confirmApplicationWithoutLPLAlreadyHaveCoverage = () => {
        startApplication(
            selectedCoverageToggles.filter((coverageType) => coverageType !== CoverageType.LPL),
            { current_pl: true },
            'LPL Deselected Pop up _ I have this coverage clicked',
        );
    };

    const confirmApplicationWithoutLPLClosePopup = () => {
        startApplication(
            selectedCoverageToggles.filter((coverageType) => coverageType !== CoverageType.LPL),
            { current_pl: null },
            'LPL Deselected Pop up closed',
        );
    };

    const changeApplicationSelection = useCallback(
        (value: string) => {
            const updatedRadioItems = radioItems.map((item) => {
                if (item.value === value) {
                    return { ...item, isSelected: !item.isSelected };
                }
                return item;
            });
            setRadioItems(updatedRadioItems);
        },
        [radioItems],
    );

    if (
        getActiveOrganizationProfileResp === undefined ||
        getActiveOrganizationProfileLoading ||
        isCreateApplicationLoading
    ) {
        return <Spinner />;
    }

    if (isErr(getActiveOrganizationProfileResp)) {
        return (
            <div>
                <ErrorPage errors={getActiveOrganizationProfileResp.errors} />
            </div>
        );
    }

    const organization = getActiveOrganizationProfileResp.value.organization as Organization;

    const onCoverageShowSlideout = (coverageType: CoverageType, coverage: UnderlyingApp) => {
        setSlideout(
            <CoverageInformationSlideout
                appTypeCode={AppTypes[coverageType]}
                naicsCode={organization.naics ?? undefined}
                onClose={() => setSlideout(null)}
                isSelected={coverage.isSelected}
                onCoverageSelect={() => {
                    changeApplicationSelection(coverageType);
                    setSlideout(null);
                }}
            />,
        );
    };

    const lplCoverage = radioItems.find((item) => item.value === CoverageType.LPL);
    const cyberCoverage = radioItems.find((item) => item.value === CoverageType.Cyber);
    const bopCoverage = radioItems.find((item) => item.value === CoverageType.BOP);
    const wcCoverage = radioItems.find((item) => item.value === CoverageType.WC);

    const bobCard = bopCoverage && (
        <BOPCoverageRecommendationCard
            ineligibilityReasons={bopCoverage.ineligibilityReasons}
            onboardingQuestionnaire={bopCoverage.onboardingQuestionnaire}
            isSelected={bopCoverage.isSelected}
            handleTooltipClick={() => onCoverageShowSlideout(CoverageType.BOP, bopCoverage)}
            onCoverageSelectionChange={() => changeApplicationSelection(CoverageType.BOP)}
        />
    );
    const wcCard = wcCoverage && (
        <WCCoverageRecommendationCard
            ineligibilityReasons={wcCoverage.ineligibilityReasons}
            onboardingQuestionnaire={wcCoverage.onboardingQuestionnaire}
            isSelected={wcCoverage.isSelected}
            handleTooltipClick={() => onCoverageShowSlideout(CoverageType.WC, wcCoverage)}
            onCoverageSelectionChange={() => changeApplicationSelection(CoverageType.WC)}
        />
    );

    const isBopIneligible = Boolean(bopCoverage?.ineligibilityReasons?.length);
    const isWcEssential =
        wcCoverage?.ineligibilityReasons?.length === 0 &&
        wcCoverage?.onboardingQuestionnaire?.has_employees;

    return (
        <React.Fragment>
            <CoverageRecommendationLayout
                isAllProductsNotSelected={isAllRadioItemsNotSelected}
                handleGetStarted={() => handleGetStarted(selectedCoverageToggles)}
            >
                <Fragment>
                    {lplCoverage && (
                        <LPLCoverageRecommendationCard
                            isSelected={lplCoverage.isSelected}
                            handleTooltipClick={() =>
                                onCoverageShowSlideout(CoverageType.LPL, lplCoverage)
                            }
                            onCoverageSelectionChange={() =>
                                changeApplicationSelection(CoverageType.LPL)
                            }
                        />
                    )}
                    {cyberCoverage && (
                        <CyberCoverageRecommendationCard
                            isSelected={cyberCoverage.isSelected}
                            handleTooltipClick={() =>
                                onCoverageShowSlideout(CoverageType.Cyber, cyberCoverage)
                            }
                            onCoverageSelectionChange={() =>
                                changeApplicationSelection(CoverageType.Cyber)
                            }
                        />
                    )}
                    {!lawVertical2v4ProductsIsVariant ? (
                        isBopIneligible || isWcEssential ? (
                            <React.Fragment>
                                {wcCard} {bobCard}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {bobCard} {wcCard}
                            </React.Fragment>
                        )
                    ) : undefined}
                </Fragment>
            </CoverageRecommendationLayout>
            <LPLDeselectedModal
                modal={lplDeselectedModal}
                primaryAction={{
                    label: 'Get a Quote',
                    onClick: confirmApplicationWithoutLPLGetAQuote,
                }}
                secondaryAction={{
                    label: 'I Already Have Coverage',
                    onClick: confirmApplicationWithoutLPLAlreadyHaveCoverage,
                }}
                onClose={confirmApplicationWithoutLPLClosePopup}
                onDismiss={confirmApplicationWithoutLPLClosePopup}
            />
        </React.Fragment>
    );
}
