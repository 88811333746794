import React from 'react';

import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { Card, Icon, ReactProps, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { CoverageList } from '@app/quote/hartford-bop/view/components/quoteCoverage/CoverageList';
import { Money } from '@embroker/shotwell/core/types/Money';
import { WizardForm } from '@app/view/hooks/useWizardForm';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { BOPHartfordQuoteOptionsFormData } from '@app/quote/hartford-bop/view/components/createBOPHartfordOptionsForm';

export interface QuoteCoverageProps extends ReactProps<'div'> {
    quote: BOPHartfordQuote;
    bopCoverageType: string;
    bppLimit: Money;
    setValue: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['value'];
}

export function QuoteCoverage(props: QuoteCoverageProps) {
    const { quote, bopCoverageType, bppLimit, setValue, value } = props;
    return (
        <StackLayout gap="32">
            <Card isSelected={false} locked={false} data-e2e="bop-hartford-card">
                <Card.Header data-e2e="bop-hartford-header">
                    <StackLayout gap="8">
                        <Icon name="shield-in-circle" />
                        <Text style="heading 5">General Liability Policy</Text>
                    </StackLayout>
                </Card.Header>
                <Card.Body data-e2e="bop-hartford-body">
                    <StackLayout>
                        <Text color="ui-500">
                            Protects your business if someone gets hurt on your business property or
                            while using your product, if their property is damaged because of your
                            business, or if they sue you for things like slander, libel, or
                            copyright infringement.
                        </Text>

                        <StackLayout gap="16">
                            <CoverageList
                                coverages={quote.details.coverages}
                                squareFootageAtLocation={quote.squareFootageAtLocation}
                                bopCoverageType={bopCoverageType}
                                bppLimit={bppLimit}
                                setValue={setValue}
                                value={value}
                            />
                        </StackLayout>
                    </StackLayout>
                </Card.Body>
                <Card.Footer />
            </Card>
        </StackLayout>
    );
}
