import { map, route, withData } from 'navi';
import React from 'react';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import {
    OnboardingFlowWrapper,
    onboardingStepComponentMap,
} from '../components/userOnboardingFlow/OnboardingFlowWrapper';
import { UserOnboardingStepType } from '@app/userOrg/types/UserOnboardingDetails';
import { GetOnboardingQuestionnaireData } from '@app/userOrg/useCases/GetOnboardingQuestionnaireData';
import { LoadingThirdPartyData } from '../components/userOnboardingFlow/LoadingThirdPartyData';

export const USER_ONBOARDING_BASE_ROUTE = '/user/onboarding/';
export const LOADING_DATA_ROUTE = `${USER_ONBOARDING_BASE_ROUTE}load`;
// We are using the below to map url friendly versions of UserOnboardingStepType(s)
// We do not want to update the UserOnboardingStepType(s) directly as they are being dispatched via user events and updating them will lead to reporting inconsistencies
export const onboardingStepUrlMap: {
    [key in NonNullable<UserOnboardingStepType>]: string;
} = {
    businessLocation: 'business-location',
    naicsConfirmation: 'naics-confirmation',
    naicsRefinement: 'naics-refinement',
    vettingRefinement: 'vetting-refinement',
    industryRefinement: 'industry-refinement',
} as const;

function getOnboardingStepFromUrl(step: string): NonNullable<UserOnboardingStepType> {
    for (const key in onboardingStepUrlMap) {
        if (assertUserOnboardingStep(key)) {
            const value = onboardingStepUrlMap[key];
            if (step === value) {
                return key;
            }
        }
    }
    return 'businessLocation';
}

function assertUserOnboardingStep(step?: string): step is NonNullable<UserOnboardingStepType> {
    return !!step && Object.keys(onboardingStepComponentMap).includes(step);
}

const onboardingWizardRouteHandler = map(async (request) => {
    const userOrganizationProfile = await execute(GetActiveOrganizationProfile);
    const getOnboardingQuestionnaireDataResp = await execute(GetOnboardingQuestionnaireData);
    const onboardingQuestionnaireData = isOK(getOnboardingQuestionnaireDataResp)
        ? getOnboardingQuestionnaireDataResp.value.questionnaireData
        : {};
    const organization = isOK(userOrganizationProfile)
        ? userOrganizationProfile.value.organization
        : undefined;

    return route({
        title: `User onboarding`,
        view: (
            <OnboardingFlowWrapper
                questionnaireData={onboardingQuestionnaireData}
                organization={organization}
                userOnboardingStepType={getOnboardingStepFromUrl(request.params.page)}
            />
        ),
    });
});

export const onboardingRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            onboardingWizardRouteHandler,
        ),
    ),
    '/:page': map((request) => {
        if (request.mountpath === LOADING_DATA_ROUTE) {
            return route({
                view: <LoadingThirdPartyData />,
                data: { fullscreen: true },
            });
        }
        return withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            onboardingWizardRouteHandler,
        );
    }),
};
