import { ShoppingCoverageCodeList } from '@app/shopping/types/enums';
import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { NotAllowed } from '@embroker/shotwell/core/Error';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { Failure, Result, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../analytics/types/SKU';
import { LimitType, LimitTypes } from '../types/Limits';

export interface QuoteBindCoverage extends DomainEvent {
    readonly origin: 'Quote';
    readonly name: 'BindCoverage';
    totalPremium: Nullable<Money>;
    applicationId: UUID;
    isRenewal: boolean;
    isStreamline?: boolean;
    sku?: SKU;
}

export type QuoteStatus = 'draft' | 'signed' | 'referred' | 'accepted';

export interface QuoteOptions {
    readonly effectiveDate: Date;
    readonly isDeselected?: boolean;
}

export const QuoteOptions = {
    ...defineValidator<QuoteOptions>({
        effectiveDate: Joi.date(),
    }),
};

export type QuoteDetails = object;

export interface PremiumRange {
    min: Money;
    max: Money;
}

export interface Quote extends Entity {
    readonly applicationId: UUID;
    readonly isIndication: boolean;
    readonly isCurrentLimitExceed?: boolean;
    readonly quoteNumber?: string;
    readonly status: QuoteStatus;
    readonly totalPremium: Money;
    readonly premiumRange?: PremiumRange;
    readonly annualTechnologyFee?: Money;
    readonly totalPayable: Money;
    readonly fileKey?: string;
    readonly fees?: Money;
    readonly options: QuoteOptions;
    readonly details?: QuoteDetails;
    readonly higherLimit?: Record<string, Record<LimitType, number>>;
    readonly preBindEndorsements?: Record<string, string>;
    readonly daysToExpire?: number;

    refer(): Result<void, NotAllowed>;

    draft(): Result<void, NotAllowed>;

    sign(): Result<void, NotAllowed>;

    unsign(): Result<void, NotAllowed>;

    accept(): Result<void, NotAllowed>;

    assignFileKey(fileKey: string): void;
}

export const commonQuoteProps = {
    applicationId: UUID.schema.required(),
    isIndication: Joi.boolean().required(),
    isCurrentLimitExceed: Joi.boolean().optional(),
    options: QuoteOptions.schema.required(),
    status: Joi.string().required(),
    totalPayable: Money.schema.required(),
    totalPremium: Money.schema.required(),
    premiumRange: Joi.object({
        min: Money.schema.required(),
        max: Money.schema.required(),
    }).optional(),
    higherLimit: Joi.object()
        .pattern(
            Joi.string().valid(...ShoppingCoverageCodeList),
            Joi.object().pattern(Joi.string().valid(...LimitTypes), Joi.number()),
        )
        .optional(),
    preBindEndorsements: Joi.object().pattern(Joi.string(), Joi.string()).optional(),
    quoteNumber: Joi.string().optional(),
    annualTechnologyFee: Money.schema.optional(),
    fees: Money.schema.optional(),
    fileKey: Joi.string().optional(),
    details: Joi.object(),
    daysToExpire: Joi.number().optional(),
};

export const Quote = entity<Quote>({
    validator: defineEntityValidator<Quote>({
        applicationId: UUID.schema,
        isIndication: Joi.boolean().required(),
        isCurrentLimitExceed: Joi.boolean().optional(),
        quoteNumber: Joi.string().optional(),
        status: Joi.string(),
        totalPremium: Money.schema,
        annualTechnologyFee: Money.schema.optional(),
        totalPayable: Money.schema,
        fees: Money.schema.optional(),
        fileKey: Joi.string().optional(),
        options: Joi.object().optional(),
        details: Joi.object(),
        higherLimit: Joi.object()
            .pattern(
                Joi.string().valid(...ShoppingCoverageCodeList),
                Joi.object().pattern(Joi.string().valid(...LimitTypes), Joi.number()),
            )
            .optional(),
        preBindEndorsements: Joi.object().pattern(Joi.string(), Joi.string()).optional(),
        daysToExpire: Joi.number().optional(),
    }),
    sign() {
        if (this.props.status === 'signed') {
            return Success();
        }
        if (this.props.status !== 'draft') {
            return Failure(NotAllowed({ operation: 'Quote can only be signed while in draft' }));
        }
        this.props.status = 'signed';
        return Success();
    },
    refer() {
        if (this.props.status !== 'draft') {
            return Failure(NotAllowed({ operation: 'Quote can only be referred while in draft' }));
        }

        this.props.status = 'referred';
        return Success();
    },
    draft() {
        if (this.props.status !== 'referred') {
            return Failure(
                NotAllowed({ operation: 'Quote can only be sent to draft while in referred' }),
            );
        }

        this.props.status = 'draft';
        return Success();
    },
    unsign() {
        if (this.props.status !== 'signed') {
            return Failure(NotAllowed({ operation: 'Quote can only be unsigned if signed' }));
        }
        this.props.status = 'draft';
        return Success();
    },
    accept() {
        if (this.props.status !== 'signed') {
            return Failure(NotAllowed({ operation: 'Quote can only be accepted if signed' }));
        }
        this.props.status = 'accepted';
        return Success();
    },
    assignFileKey(fileKey: string) {
        this.props.fileKey = fileKey;
    },
});
