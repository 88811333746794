import React, { useEffect, useMemo, useState } from 'react';
import { Spinner, Modal, useModal, Text, StackLayout, BoxLayout } from '@embroker/ui-toolkit/v2';
import { useNavigation } from '@app/view/hooks/useNavigation';

export function LoadingThirdPartyData() {
    const { navigate } = useNavigation();
    const modal = useModal({ visible: true });
    const [index, setIndex] = useState(0);

    const userMessages = useMemo(
        () => [
            'Checking for public info about your business.',
            'Pre-filling your form with some key business details.',
            'Fine-tuning details for a smoother application process..',
        ],
        [],
    );
    const maxLoadingTime = 5000;

    useEffect(() => {
        const userMessagesDisplayTime = maxLoadingTime / userMessages.length;

        if (index >= userMessages.length) {
            navigate('/user/onboarding');
            return;
        }

        const interval = setInterval(() => {
            setIndex(index + 1);
        }, userMessagesDisplayTime);

        return () => clearInterval(interval);
    }, [index, userMessages, setIndex, navigate]);

    const currentIndex = index < userMessages.length ? index : userMessages.length - 1;

    return (
        <Modal {...modal} dismissable={false}>
            <StackLayout gap="24">
                <Spinner.Wrapper>
                    <Spinner appearance="transparent" />
                    <BoxLayout gap="32" />
                </Spinner.Wrapper>
                <Text style="heading 1" textAlign="center">
                    Personalizing your application...
                </Text>
                <Text textAlign="center">{userMessages[currentIndex]}</Text>
            </StackLayout>
        </Modal>
    );
}
