import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileReportRisksCoveredIncludeToggle } from '@app/public/entities/events';

export interface PublishRiskProfileReportRisksCoveredIncludeToggleEvent extends UseCase {
    execute(): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileReportRisksCoveredIncludeToggleUseCase
    extends UseCase
    implements PublishRiskProfileReportRisksCoveredIncludeToggleEvent
{
    public static type = Symbol(
        'RiskProfile/PublishRiskProfileReportRisksCoveredIncludeToggleEvent',
    );

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(): AsyncResult<void, InvalidArgument | OperationFailed> {
        const RisksCoveredIncludeToggleEvent: RiskProfileReportRisksCoveredIncludeToggle = {
            origin: 'RiskProfile',
            name: 'RisksCoveredIncludeToggle',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };

        await this.eventBus.publish(RisksCoveredIncludeToggleEvent);

        return Success();
    }
}

export const PublishRiskProfileReportRisksCoveredIncludeToggleEvent: UseCaseClass<PublishRiskProfileReportRisksCoveredIncludeToggleEvent> =
    PublishRiskProfileReportRisksCoveredIncludeToggleUseCase;
