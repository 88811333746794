import React, { useEffect, useContext, useMemo, useState } from 'react';
import { StackLayout, Form, Text, StatusMessage } from '@embroker/ui-toolkit/v2';
import { container } from '@embroker/shotwell/core/di';
import { execute } from '@embroker/shotwell/core/UseCase';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useCurrentRoute } from 'react-navi';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { EmailAlreadyInUse, ErrorCode } from '../../../errors';
import { SignUp as SignUpUseCase } from '../../../useCases/SignUp';
import { CheckUsernameAvailability } from '../../../useCases/CheckUsernameAvailability';
import { Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { AppContext } from '../../../../view/AppContext';
import { HeaderView, Link } from '../../../../view/components';
import { PublishSignUpStartedEvent } from '../../../useCases/PublishSignUpStartedEvent';
import { UserOnboardingDetails } from '../../../types/UserOnboardingDetails';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { UnauthenticatedHeaderCtaView } from '../UnauthenticatedHeaderCta.view';
import { SignUpWrapper } from './SignUpWrapper.view';
import { PublishUserOrgUserClickEvent } from '@app/userOrg/useCases/PublishUserOrgUserClickEvent';
import { typeformLink } from '@app/view/components/BrokerBanner.view';
import { PublishLogoClickedEvent } from '@app/userOrg/useCases/PublishLogoClickEvent';
import {
    getLocalStorageErrorMessage,
    LocalStorageError,
} from '@app/userOrg/view/components/LocalStorageErrorMessage';

interface SignUpFormData {
    emailAddress: EmailAddress;
    organizationName: string;
    website: string;
}

const emailSchemaValidationMessages: { [key: string]: string } = {
    'any.required': 'You must enter your email address',
    'any.empty': 'You must enter your email address',
    'string.email': 'You must enter a valid email address',
};

const emailAlreadyInUseMessage = 'This email is already connected to an Embroker account.';
const invalidEmailMessage = 'Please enter a valid email';

const createSignUpForm = (redirectUrl?: string) => {
    return createForm<SignUpFormData>({
        fields: {
            emailAddress: {
                type: 'email',
                validator: Joi.string(),
                formatValidationError(error) {
                    const reason = error.details.validator || '';
                    return emailSchemaValidationMessages[reason] || error.message;
                },
            },
            organizationName: {
                type: 'text',
                validator: Joi.string().required().trim().min(1),
                formatValidationError(error) {
                    return 'You must enter your organization name';
                },
            },
            website: {
                type: 'text',
                validator: Joi.string().optional().allow('').allow(null),
            },
        },
        formatSubmitErrors(errors) {
            for (const error of errors) {
                const errorCode = error.code;
                switch (errorCode) {
                    case ErrorCode.EmailAlreadyInUse:
                        return [emailAlreadyInUseMessage];
                    case ErrorCode.InvalidEmail:
                        return ['Please enter a valid email'];
                    case ErrorCode.MaxNumberOfSignUpAttemptsExceeded:
                        return [
                            'You have tried to use invalid email address 5 times. You cannot create an account for the next 1 hour.',
                        ];
                }
            }

            container
                .get<Logger>(Log)
                .warn(`User onboarding - Signup page formatSubmitErrors: ${errors}`);

            return ['Sorry, something went wrong. Please try again later.'];
        },
        submit: async ({ emailAddress, organizationName, website }) => {
            const emailAvailabilityResponse = await execute(CheckUsernameAvailability, {
                email: emailAddress,
            });
            if (isErr(emailAvailabilityResponse)) {
                return emailAvailabilityResponse;
            }
            if (!emailAvailabilityResponse.value.available) {
                return Failure(EmailAlreadyInUse(emailAddress));
            }

            const signUpUseCaseResponse = await execute(SignUpUseCase, {
                emailAddress,
                organizationName,
                website,
                certificateInviteToken: null,
                signUpInviteToken: null,
            });

            if (isErr(signUpUseCaseResponse)) {
                return signUpUseCaseResponse;
            }

            UserOnboardingDetails.initializeUserOnboardingContext(redirectUrl);

            return signUpUseCaseResponse;
        },
    });
};

export const hasEmptyRequiredField = (
    formValues: { [key: string]: any },
    optionalFileds: string[] = [],
) => {
    for (const key in formValues) {
        if (formValues.hasOwnProperty(key)) {
            const isOptional = optionalFileds.includes(key);
            if (!isOptional && !formValues[key]) {
                return true;
            }
            if (
                typeof formValues[key] === 'object' &&
                hasEmptyRequiredField(formValues[key], optionalFileds)
            ) {
                return true;
            }
        }
    }
    return false;
};

interface SignUpPageProps {
    readonly userEmail?: EmailAddress;
    readonly organizationName?: string;
    readonly redirectUrl?: string;
}

export function SignUpPage({ userEmail, organizationName, redirectUrl }: SignUpPageProps) {
    const { selectedCoverages } = useContext(AppContext);
    const { url: currentRoute } = useCurrentRoute();
    const { setHeader } = useContext(AppContext);
    const localStorageErrorMessage = useMemo(getLocalStorageErrorMessage, []);
    const [hasWebsite, setHasWebsite] = useState<boolean | undefined>(undefined);

    const signUpForm = useMemo(() => {
        return createSignUpForm(redirectUrl);
    }, [redirectUrl]);

    const { fields, value, messages, submit } = useForm(signUpForm, {
        emailAddress: userEmail,
        organizationName: organizationName,
    });

    const signInUrl = URI.build('/login', {
        ...currentRoute.query,
        emailAddress: value.emailAddress,
    });

    useEffect(() => {
        execute(PublishSignUpStartedEvent, selectedCoverages);
    }, [selectedCoverages]);

    const [emailAlreadyRegisteredMessage, formMessages] = useMemo(() => {
        const emailFormSubmitErrorMessages: { [key: string]: React.ReactChild } = {
            [emailAlreadyInUseMessage]: (
                <React.Fragment>
                    This email is already connected to an Embroker account.{' '}
                    <Link href={signInUrl}>Sign in</Link>.
                </React.Fragment>
            ),
            [invalidEmailMessage]: invalidEmailMessage,
        };

        return messages.reduce<[Nullable<React.ReactChild[]>, string[]]>(
            (accumulator, message) => {
                const emailError = emailFormSubmitErrorMessages[message];
                if (emailError) {
                    accumulator[0] =
                        accumulator[0] === null ? [emailError] : [...accumulator[0], emailError];
                } else {
                    accumulator[1].push(message);
                }

                return accumulator;
            },
            [null, []],
        );
    }, [signInUrl, messages]);

    const emailAddressFormMessages = [
        ...fields.emailAddress.messages,
        emailAlreadyRegisteredMessage,
    ].filter(Boolean) as React.ReactChild[];

    const disableButton = useMemo(() => {
        // Determine the optional fields based on the variant and hasWebsite
        const optionalFields = hasWebsite ? [] : ['website'];

        // Check if any required field is empty
        return hasEmptyRequiredField(value, optionalFields) || hasWebsite === undefined;
    }, [value, hasWebsite]);

    const emailInputProps = {
        ...fields.emailAddress.props,
        autoComplete: 'email',
        hasErrors: fields.emailAddress.props.hasErrors || !!emailAlreadyRegisteredMessage,
    };

    const handleBannerClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        execute(PublishUserOrgUserClickEvent, {
            clickEventName: 'Signup Broker Banner Clicked',
        });
        window.open(typeformLink);
    };

    const href = 'https://www.embroker.com';
    const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        execute(PublishLogoClickedEvent, '/signup');

        // async delay to allow the above event to get registered on the dataLayer before user is kicked out of app
        setTimeout(() => {
            window.location.href = href;
        }, 1);
    };

    useEffect(() => {
        setHeader(
            <HeaderView logoHref={href} onLogoClick={handleLogoClick}>
                <UnauthenticatedHeaderCtaView
                    href="/login"
                    content="Sign in now"
                    dataE2e="customer-public-header-login-link"
                    question="Already have an account?"
                />
            </HeaderView>,
        );
    }, [setHeader]);

    return (
        <SignUpWrapper
            formMessages={formMessages}
            submit={submit}
            disableButton={disableButton || !!localStorageErrorMessage}
            submitText="Next"
            optionalLink={
                <Text>
                    Are you a broker? Access the broker portal{' '}
                    <Link onClick={handleBannerClick} href={''}>
                        here
                    </Link>
                </Text>
            }
        >
            <StackLayout gap="12">
                <LocalStorageError />
                <StackLayout gap="32">
                    <StackLayout gap="12">
                        <Text>What is the name of your company?</Text>
                        <Form.Field
                            data-e2e="sign-up-company-name"
                            label="Company name"
                            type={fields.organizationName.type}
                            inputProps={{
                                ...fields.organizationName.props,
                            }}
                            messages={fields.organizationName.messages}
                        />
                    </StackLayout>
                    <StackLayout gap="12">
                        <Text>What is your email address?</Text>
                        <Form.Field
                            data-e2e="sign-up-username"
                            label="Email"
                            type={fields.emailAddress.type}
                            inputProps={{ ...emailInputProps }}
                            messages={emailAddressFormMessages}
                        />
                        <StatusMessage status="info">
                            What are we going to do with your email? We collect this information to
                            save your quote.
                        </StatusMessage>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
            <StackLayout gap="12">
                <Text>Does your company have a website?</Text>
                <StackLayout gap="12">
                    <Form.Field
                        data-e2e="sign-up-has-company-website"
                        type="radioGroup"
                        inputProps={{
                            value: hasWebsite,
                            onChange: (e) => {
                                setHasWebsite(e.target.value);
                                if (e.target.value) {
                                    fields.website.props.onChange({
                                        target: { value: '' },
                                    });
                                }
                            },
                            items: [
                                {
                                    value: true,
                                    title: 'Yes',
                                },
                                {
                                    value: false,
                                    title: 'No',
                                },
                            ],
                        }}
                    />
                    {hasWebsite && (
                        <Form.Field
                            data-e2e="sign-up-company-website"
                            label="Company website"
                            type={fields.website.type}
                            inputProps={{
                                ...fields.website.props,
                            }}
                            messages={fields.website.messages}
                        />
                    )}
                </StackLayout>
            </StackLayout>
        </SignUpWrapper>
    );
}
