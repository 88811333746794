import { inject, injectable, container } from '@embroker/shotwell/core/di';
import { differenceInDays, startOfToday } from 'date-fns';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Success,
    SuccessResult,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { LegacyGrowthBookExperimentationService } from '../../experimentation/services/LegacyGrowthBookExperimentationService';
import { isVariantExperimentTest } from '../../experimentation/types/ExperimentationTest';
import { BundleQuoteRepository } from '../../bundle/repositories';
import { URI } from '@embroker/shotwell/core/types/URI';
import { APIApplicationRepository } from '../../shopping/repositories/ApplicationRepository/APIApplicationRepository';
import {
    ApplicationRepository,
    GetApplicationListResponse,
} from '../../shopping/repositories/ApplicationRepository';
import { BundleQuote } from '../../bundle/entities/BundleQuote';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetActiveOrganizationProfile } from './GetActiveOrganizationProfile';
import { NAICS_CODE_TO_VERTICAL } from '../types/enums';
import { StartApplication } from '../../shopping/useCases/StartApplication';
import { Immutable } from '@embroker/ui-toolkit/v2';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Organization } from '../entities/Organization';
import { getApplicationFlags } from '../../shopping/view/components/applicationFlags';
import { Application } from '../../shopping/entities/Application';
import { resolveQuotesReadyLink } from '../../summary/view/components/ApplicationAction';
import {
    QuestionnaireInProgress,
    AppTypeCode,
    AppTypeLabelMap,
    Bound,
    Purchased,
} from '../../shopping/types/enums';
import { NBRVRedirectGateUseCase } from '../view/components/NBRVRedirectGate';
import { BundleCoverageType } from '@app/bundle/types/BundleQuoteCoverage';
import { MAX_APPLICATION_DAYS } from '@app/bundle/view/components/BundleQuoteLandingPage';
import { GetNBRVFunnelStatus } from './GetNBRVFunnelStatus';

interface SingleExpiredQuoteMapped {
    coverageName?: string;
    applicationId: UUID;
    hasExpiredApp: boolean;
    appType: BundleCoverageType | AppTypeCode;
}

type ComputeSingleExpiredQuoteRedirectionUrlResult = Promise<{
    redirectTo: URI;
    useCase: NBRVRedirectGateUseCase;
} | null>;

export interface ReturnUserLoginRedirection {
    redirectTo: URI | null;
    useCase: NBRVRedirectGateUseCase;
    coverageName?: string;
    appType?: BundleCoverageType | AppTypeCode;
}

export interface GetReturnUserLoginRedirect extends UseCase {
    execute(): AsyncResult<ReturnUserLoginRedirection | null>;
}

const defaultFlags = {
    canViewCnaBopQuotes: true,
    canViewLplQuotes: true,
    canViewPCoMLQuotes: true,
    canViewCrimeQuotes: true,
    canViewCyberQuotes: true,
    canViewExcessQuotes: true,
};

type ValidQuotesMap = {
    [key: UUID | string]: {
        count: number;
        source: 'bundle' | 'monoline';
        coverageName?: string;
        appType: BundleCoverageType | AppTypeCode;
    };
};

const getInProgressApplications = (
    applicationListResponse: SuccessResult<GetApplicationListResponse>,
) => {
    return applicationListResponse.value.applicationList.filter(
        (app) => app.status === QuestionnaireInProgress,
    );
};

const computeSingleExpiredQuoteRedirectionUrl = async ({
    expiredQuote,
    organization,
    applicationList,
    experimentationService,
}: {
    expiredQuote?: SingleExpiredQuoteMapped;
    organization: Immutable<EntityProps<Organization>>;
    applicationList: SuccessResult<GetApplicationListResponse>;
    experimentationService: LegacyGrowthBookExperimentationService;
}): ComputeSingleExpiredQuoteRedirectionUrlResult => {
    const { naics } = organization;
    const shouldRedirectToLawBundle = naics && NAICS_CODE_TO_VERTICAL[naics] === 'LawFirm';
    const shouldRedirectToMPLBundle =
        naics &&
        (NAICS_CODE_TO_VERTICAL[naics] === 'Accounting' ||
            NAICS_CODE_TO_VERTICAL[naics] === 'TaxPreparation' ||
            NAICS_CODE_TO_VERTICAL[naics] === 'RealEstateAgent' ||
            NAICS_CODE_TO_VERTICAL[naics] === 'HomeInspectorAndBuildingInspectionServices' ||
            NAICS_CODE_TO_VERTICAL[naics] === 'NonTechnologyConsultants');
    if (expiredQuote?.hasExpiredApp) {
        if (
            isVariantExperimentTest(
                experimentationService.getExperimentationTest('nb-rv-expired-app-expired-quote'),
            )
        ) {
            if (shouldRedirectToLawBundle) {
                return {
                    redirectTo: URI.build('/shopping/law-bundle'),
                    useCase: 'expiredAppExpiredQuote',
                };
            }

            if (shouldRedirectToMPLBundle) {
                return {
                    redirectTo: URI.build('/shopping/guidance-page'),
                    useCase: 'expiredAppExpiredQuote',
                };
            }

            const previousApplicationType = applicationList.value.applicationList.find(
                (application) => application.id === expiredQuote.applicationId,
            )?.appType;

            const application = await execute(StartApplication, {
                selectedAppTypes: [previousApplicationType as AppTypeCode],
                abortSignal: new AbortController().signal,
            });

            if (isErr(application)) {
                return null;
            }

            return { redirectTo: application.value.uri, useCase: 'expiredAppExpiredQuote' };
        }
    }

    if (shouldRedirectToLawBundle || shouldRedirectToMPLBundle) {
        if (
            isVariantExperimentTest(
                experimentationService.getExperimentationTest('nb-rv-single-expired-quote'),
            )
        ) {
            return {
                redirectTo: URI.build('/shopping/bundle/coverage', {
                    applicationId: expiredQuote?.applicationId,
                }),
                useCase: 'singleExpiredQuote',
            };
        }
    }
    if (
        isVariantExperimentTest(
            experimentationService.getExperimentationTest('nb-rv-single-expired-quote'),
        )
    ) {
        const applicationId = expiredQuote?.applicationId;
        return {
            redirectTo: URI.build('/shopping/bundle', { applicationId }),
            useCase: 'singleExpiredQuote',
        };
    }
    return null;
};

type GetValidQuotesProps = Awaited<ReturnType<typeof getQuotesFromApplicationList>> & {
    organization: Immutable<EntityProps<Organization>>;
};

const getValidQuotes = ({ bundleQuoteList, monolineList, organization }: GetValidQuotesProps) => {
    const totalValidQuotes: ValidQuotesMap = {};
    for (const quote of bundleQuoteList) {
        const quoteValidDate = quote.getQuoteValidityInDays() ?? -1;
        const count = quote.coverageList.reduce((acc, coverage) => {
            if (quoteValidDate >= 0 && coverage.status === 'QuotesReady') {
                return acc + 1;
            } else {
                return acc;
            }
        }, 0);
        if (count > 0) {
            let coverageName: string | undefined;
            if (quote.coverageList[0].type === 'MPLCoverage') {
                const { naics } = organization;
                if (naics) {
                    if (NAICS_CODE_TO_VERTICAL[naics] === 'Accounting')
                        coverageName = 'Accountants Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'TaxPreparation')
                        coverageName = 'Tax Preparers Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'RealEstateAgent')
                        coverageName = 'Real Estate Agents Package';
                    else if (
                        NAICS_CODE_TO_VERTICAL[naics] ===
                        'HomeInspectorAndBuildingInspectionServices'
                    )
                        coverageName = 'Home Inspector Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'NonTechnologyConsultants')
                        coverageName = 'Non-Technology Consultants Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'TechCompanies')
                        coverageName = 'Technology Package';
                }
            } else if (quote.coverageList[0].type === 'LPLBundleCoverage')
                coverageName = 'Law Firm Package';
            totalValidQuotes[quote.applicationId] = {
                count,
                source: 'bundle',
                coverageName,
                appType: quote.coverageList[0].type,
            };
        }
    }
    for (const monoline of monolineList) {
        if (monoline.daysToQuoteExpiration && monoline.daysToQuoteExpiration >= 0) {
            totalValidQuotes[monoline.id] = {
                count: 1,
                source: 'monoline',
                coverageName: AppTypeLabelMap[monoline.appType],
                appType: monoline.appType,
            };
        }
    }
    return totalValidQuotes;
};

const hasSingleExpiredQuote = ({
    bundleQuoteList,
    monolineList,
    organization,
}: GetValidQuotesProps) => {
    let expiredCount = 0;
    for (const quote of bundleQuoteList) {
        const quoteValidDate = quote.getQuoteValidityInDays() ?? -1;
        if (quoteValidDate === -1) {
            expiredCount++;
        }
    }

    for (const monoline of monolineList) {
        if (monoline.daysToQuoteExpiration === -1) {
            expiredCount++;
        }
    }

    const validQuotesMap = getValidQuotes({ bundleQuoteList, monolineList, organization });
    const hasNoValidQuotes = Object.keys(validQuotesMap).length === 0;

    return expiredCount === 1 && hasNoValidQuotes;
};

const isApplicationExpired = (application: Immutable<Application>) =>
    differenceInDays(startOfToday(), application.submittedDate as Date) > MAX_APPLICATION_DAYS;

interface GetSingleExpiredQuoteProps extends GetValidQuotesProps {
    applicationListResponse: SuccessResult<GetApplicationListResponse>;
}

const getSingleExpiredQuote = ({
    bundleQuoteList,
    monolineList,
    organization,
    applicationListResponse,
}: GetSingleExpiredQuoteProps) => {
    for (const quote of bundleQuoteList) {
        const quoteValidDate = quote.getQuoteValidityInDays() ?? -1;
        if (quoteValidDate === -1) {
            let coverageName: string | undefined;
            if (quote.coverageList[0].type === 'MPLCoverage') {
                const { naics } = organization;
                if (naics) {
                    if (NAICS_CODE_TO_VERTICAL[naics] === 'Accounting')
                        coverageName = 'Accountants Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'TaxPreparation')
                        coverageName = 'Tax Preparers Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'RealEstateAgent')
                        coverageName = 'Real Estate Agents Package';
                    else if (
                        NAICS_CODE_TO_VERTICAL[naics] ===
                        'HomeInspectorAndBuildingInspectionServices'
                    )
                        coverageName = 'Home Inspector Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'NonTechnologyConsultants')
                        coverageName = 'Non-Technology Consultants Package';
                    else if (NAICS_CODE_TO_VERTICAL[naics] === 'TechCompanies')
                        coverageName = 'Technology Package';
                }
            } else if (
                quote.coverageList[0].type === 'LPLBundleCoverage' ||
                quote.coverageList[0].type === 'LawCyberBundleCoverageCowbell' ||
                quote.coverageList[0].type === 'LawCyberBundleCoverage'
            )
                coverageName = 'Law Firm Package';

            const applicationList = applicationListResponse.value.applicationList;

            const application = applicationList.find((app) => app.id === quote.applicationId);

            if (!application) {
                return;
            }

            const hasExpiredApp =
                quote.isAnyCoverageAppExpired() && isApplicationExpired(application);

            return {
                applicationId: quote.applicationId,
                coverageName,
                hasExpiredApp,
                appType: quote.coverageList[0].type,
            };
        }
    }

    for (const monoline of monolineList) {
        if (monoline.daysToQuoteExpiration === -1) {
            return {
                applicationId: monoline.id,
                coverageName: AppTypeLabelMap[monoline.appType],
                hasExpiredApp: isApplicationExpired(monoline),
                appType: monoline.appType,
            };
        }
    }
    return undefined;
};

const getQuotesFromApplicationList = async (
    applicationList: SuccessResult<GetApplicationListResponse>,
    getLastBundleQuote: (
        applicationId: UUID,
    ) => AsyncResult<BundleQuote, InvalidArgument | OperationFailed>,
) => {
    const bundleQuoteList = [];
    const monolineList = [];
    for (const item of applicationList.value.applicationList) {
        if (item.bundleDetails === undefined) {
            monolineList.push(item);
        } else {
            const bundleQuote = await getLastBundleQuote(item.id);

            if (!isErr(bundleQuote)) {
                bundleQuoteList.push(bundleQuote.value);
            }
        }
    }
    return {
        bundleQuoteList,
        monolineList,
    };
};

type RedirectionForSingleQuoteValidProps = {
    validQuotes: ValidQuotesMap;
    monolineList: GetValidQuotesProps['monolineList'];
};

const redirectionForSingleQuoteValid = ({
    validQuotes,
    monolineList,
}: RedirectionForSingleQuoteValidProps) => {
    const validQuote = getSingleValidQuoteApplicationIdAndSource(validQuotes);
    if (validQuote && validQuote.source === 'bundle') {
        return URI.build('/shopping/bundle', { applicationId: validQuote.applicationId });
    } else if (validQuote && validQuote.source === 'monoline') {
        const app = monolineList.find((monoline) => monoline.id === validQuote.applicationId);
        if (app) {
            const flags = getApplicationFlags(app as Application, defaultFlags);
            return resolveQuotesReadyLink(app.id, flags);
        } else {
            return null;
        }
    } else {
        return null;
    }
};

const redirectionForNoAppsInProgressAndNoQuotes = () => {
    return URI.build('/user/onboarding');
};

const redirectionForApplicationQuestionnaire = (applicationId: UUID) => {
    return URI.build('/shopping/application', { applicationId });
};

const hasInProgressApps = (inProgressApps: ReturnType<typeof getInProgressApplications>) =>
    inProgressApps.length > 0;
const hasNumberOfInProgressApps = (
    num: number,
    inProgressApps: ReturnType<typeof getInProgressApplications>,
) => inProgressApps.length === num;

const hasNumberOfValidQuotes = (num: number, validQuotes: ValidQuotesMap) =>
    Object.keys(validQuotes).length === num;
const getSingleValidQuoteApplicationIdAndSource = (validQuotes: ValidQuotesMap) =>
    hasNumberOfValidQuotes(1, validQuotes) && {
        applicationId: Object.keys(validQuotes)[0],
        source: validQuotes[Object.keys(validQuotes)[0]].source,
    };

const hasActivePolicy = (applicationListResponse: SuccessResult<GetApplicationListResponse>) => {
    return applicationListResponse.value.applicationList.some((app) =>
        [Bound, Purchased].includes(app.status),
    );
};

@injectable()
export class GetReturnUserLoginRedirectUseCase
    extends UseCase
    implements GetReturnUserLoginRedirect
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetReturnUserLoginRedirect');
    /**
     * Constructor for GetReturnUserLoginRedirect use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
        @inject(ApplicationRepository) private applicationRepository: APIApplicationRepository,
        @inject(GetNBRVFunnelStatus.type) private getNBRVFunnelStatus: GetNBRVFunnelStatus,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetReturnUserLoginRedirect use case
     * @returns Nothing if execution was successful
     */
    public async execute(): AsyncResult<ReturnUserLoginRedirection | null> {
        const funnelStatus = await this.getNBRVFunnelStatus.execute();

        if (isErr(funnelStatus)) {
            return handleOperationFailure(funnelStatus);
        }

        // check if user already went through the funnel experience
        if (!funnelStatus.value.shouldShowPopup) {
            return Success(null);
        }

        const applicationList = await this.applicationRepository.getApplicationList();

        if (isErr(applicationList)) {
            return applicationList;
        }

        if (hasActivePolicy(applicationList)) {
            return Success(null);
        }

        const { bundleQuoteList, monolineList } = await getQuotesFromApplicationList(
            applicationList,
            (applicationId: UUID) => this.bundleQuoteRepository.getLastBundleQuote(applicationId),
        );

        const inProgressApps = getInProgressApplications(applicationList);

        const getActiveOrganizationProfileResponse = await execute(GetActiveOrganizationProfile);

        if (isErr(getActiveOrganizationProfileResponse)) {
            return Success(null);
        }

        const organization = getActiveOrganizationProfileResponse.value.organization;

        const validQuotes = getValidQuotes({
            bundleQuoteList,
            monolineList,
            organization,
        });
        const experimentationService = container.get<LegacyGrowthBookExperimentationService>(
            LegacyGrowthBookExperimentationService,
        );

        if (hasSingleExpiredQuote({ bundleQuoteList, monolineList, organization })) {
            const expiredQuote = getSingleExpiredQuote({
                bundleQuoteList,
                monolineList,
                organization,
                applicationListResponse: applicationList,
            });
            const result = await computeSingleExpiredQuoteRedirectionUrl({
                expiredQuote,
                organization,
                applicationList,
                experimentationService,
            });

            if (result === null) {
                return Success(null);
            }

            return Success({
                redirectTo: result.redirectTo,
                useCase: result.useCase,
                coverageName: expiredQuote?.coverageName,
                appType: expiredQuote?.appType,
            });
        }

        // no valid quote
        if (hasNumberOfValidQuotes(0, validQuotes)) {
            // no application in progress
            if (hasNumberOfInProgressApps(0, inProgressApps)) {
                if (
                    isVariantExperimentTest(
                        experimentationService.getExperimentationTest(
                            'nb-rv-no-app-in-progress-or-quote',
                        ),
                    )
                ) {
                    return Success({
                        redirectTo: redirectionForNoAppsInProgressAndNoQuotes(),
                        useCase: 'noAppInProgressAndNoQuote',
                    });
                }
            }

            // a single application in progress
            if (hasNumberOfInProgressApps(1, inProgressApps)) {
                if (
                    isVariantExperimentTest(
                        experimentationService.getExperimentationTest(
                            'nb-rv-single-app-in-progress',
                        ),
                    )
                ) {
                    const [application] = inProgressApps;
                    return Success({
                        redirectTo: redirectionForApplicationQuestionnaire(application.id),
                        useCase: 'singleAppInProgress',
                    });
                }
            }
        }

        // a single valid quote
        if (hasNumberOfValidQuotes(1, validQuotes) && !hasInProgressApps(inProgressApps)) {
            return Success({
                redirectTo: redirectionForSingleQuoteValid({ validQuotes, monolineList }),
                useCase: 'singleValidQuote',
                coverageName: validQuotes[Object.keys(validQuotes)[0]].coverageName,
                appType: validQuotes[Object.keys(validQuotes)[0]].appType,
            });
        }

        return Success(null);
    }
}

export const GetReturnUserLoginRedirect: UseCaseClass<GetReturnUserLoginRedirect> =
    GetReturnUserLoginRedirectUseCase;
