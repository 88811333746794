import {
    ClaimsExpenseType,
    LPLQuoteOptions,
    DeductibleType,
} from '../../../quote/lpl/types/LPLQuoteOptions';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { QuestionnaireData } from '@app/bundle/types/BundleQuoteQuestionnaireData';
import { CoveragePackageType } from '@app/bundle/useCases/GetRecommendedCoverageQuotes';

export const getRecommendedQuotingOptionsLpl = (
    bundleQuote: BundleQuote,
    packageType: CoveragePackageType,
    questionnaireData: QuestionnaireData,
): LPLQuoteOptions | undefined => {
    const revenueRange = QuestionnaireData.getRevenueRangeFromQuestionnaire(questionnaireData);
    const isDeselected = !bundleQuote.isCoverageSelected('LPLBundleCoverage');

    let starterClaimsExpenseType: ClaimsExpenseType = 'ClaimsExpenseTypeInsideOfLimits';
    let deluxeClaimsExpenseType: ClaimsExpenseType = 'ClaimsExpenseTypeSeparateClaimsExpenses';
    let starterAggregateLimit:
        | 500000
        | 750000
        | 1000000
        | 2000000
        | 3000000
        | 4000000
        | 5000000
        | 300000
        | 600000
        | 1500000 = 1000000;
    let defaultDeductibleType: DeductibleType = 'DeductibleTypeLossAndClaimsExpense';

    // NY has different ClaimsExpanse, PerClaimLimit and AggregateLimit values combinations in rater
    if (questionnaireData.state === 'NY') {
        starterClaimsExpenseType = 'ClaimsExpenseTypeInsideOfLimitsWithOffset';
        deluxeClaimsExpenseType = 'ClaimsExpenseTypeInsideOfLimitsWithOffset';
        starterAggregateLimit = revenueRange === 'LOW' ? 1500000 : 1000000;
    }

    if (questionnaireData.state === 'NJ') {
        starterClaimsExpenseType = 'ClaimsExpenseTypeInAdditionToLimits';
        defaultDeductibleType = 'DeductibleTypeLossOnly';
    }

    switch (packageType) {
        case 'starter': {
            const perClaimLimit = revenueRange === 'LOW' ? 500000 : 1000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perClaimLimit,
                aggregateLimit: starterAggregateLimit,
                perClaimDeductible: 2500,
                deductibleType: defaultDeductibleType,
                claimsExpenseType: starterClaimsExpenseType,
                separateClaimExpenseLimit: null,
                isDeselected,
            };
        }
        case 'enhanced': {
            const perClaimLimit = revenueRange === 'LOW' ? 1000000 : 2000000;
            const aggregateLimit = revenueRange === 'LOW' ? 1000000 : 4000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perClaimLimit,
                aggregateLimit,
                perClaimDeductible: 2500,
                deductibleType: defaultDeductibleType,
                claimsExpenseType: starterClaimsExpenseType,
                separateClaimExpenseLimit: null,
                isDeselected,
            };
        }
        case 'deluxe': {
            const aggregateLimit = revenueRange === 'LOW' ? 1000000 : 4000000;
            const perClaimLimit = revenueRange === 'LOW' ? 1000000 : 4000000;
            const claimsExpenseType = ['LOW', 'MEDIUM'].includes(revenueRange)
                ? deluxeClaimsExpenseType
                : 'ClaimsExpenseTypeInAdditionToLimits';

            const separateClaimExpenseLimit =
                revenueRange === 'LOW' ? 250000 : revenueRange === 'MEDIUM' ? 500000 : null;

            return {
                effectiveDate: bundleQuote.effectiveDate,
                perClaimLimit,
                aggregateLimit,
                perClaimDeductible: 2500,
                deductibleType: defaultDeductibleType,
                claimsExpenseType,
                separateClaimExpenseLimit,
                isDeselected,
            };
        }
    }
};
