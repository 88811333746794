import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Success } from '@embroker/shotwell/core/types/Result';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { IssueCertificateProvisionsStepGuidelines } from './IssueCertificateProvisionsStepGuidelines';

export interface IssueCertificateProvisionsStepData {
    isAdditionalInsuredDisabled?: boolean;
    isWaiverOfSubrogationDisabled?: boolean;
    isPrimaryNonContributoryDisabled?: boolean;
}

function createIssueCertificateProvisionsStepForm(
    onIssueCertificate: (data: IssueCertificateProvisionsStepData) => void,
) {
    return createForm<IssueCertificateProvisionsStepData>({
        fields: {
            isAdditionalInsuredDisabled: {
                type: 'checkbox',
                validator: Joi.boolean().optional(),
            },
            isWaiverOfSubrogationDisabled: {
                type: 'checkbox',
                validator: Joi.boolean().optional(),
            },
            isPrimaryNonContributoryDisabled: {
                type: 'checkbox',
                validator: Joi.boolean().optional(),
            },
        },
        async submit(data) {
            onIssueCertificate(data);
            return Success();
        },
    });
}

interface IssueCertificateProvisionsStepProps {
    initialData?: Partial<IssueCertificateProvisionsStepData>;
    hasWOS: boolean;
    hasAdditional: boolean;
    hasPrimaryNonContributory: boolean;
    onIssueCertificate(data: IssueCertificateProvisionsStepData): void;
    onPreviewCertificate(data: IssueCertificateProvisionsStepData): void;
    onClick(): void;
    onBack(): void;
}

export function IssueCertificateProvisionsStep({
    initialData,
    hasWOS,
    hasAdditional,
    hasPrimaryNonContributory,
    onIssueCertificate,
    onPreviewCertificate,
    onClick,
    onBack,
}: IssueCertificateProvisionsStepProps) {
    const issueCertificateProvisionsStepForm = useMemo(
        () => createIssueCertificateProvisionsStepForm(onIssueCertificate),
        [onIssueCertificate],
    );
    const { fields, submit, setValue, value } = useForm(
        issueCertificateProvisionsStepForm,
        initialData,
    );

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <IssueCertificateProvisionsStepGuidelines></IssueCertificateProvisionsStepGuidelines>
            <StackLayout split="-1">
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <Text style={'heading 5'}>
                                We have defaulted to show the wordings and provisions that are most
                                commonly requested, but you have the option to check the ones you
                                want removed, if they aren’t required.
                            </Text>
                            <Form>
                                <StackLayout gap="12">
                                    <Form.Field
                                        inputProps={{
                                            onChange: (
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setValue({
                                                    ...value,
                                                    isAdditionalInsuredDisabled:
                                                        event.target.checked,
                                                });
                                            },
                                            checked:
                                                fields.isAdditionalInsuredDisabled.props.value ||
                                                false,
                                            tooltipText: (
                                                <React.Fragment>
                                                    <b>Additional Insured</b> provisions add another
                                                    insured party to the policy. If you don’t need
                                                    to add anyone else, just remove this provision.
                                                    Simple as that..
                                                    <br />
                                                    <br />
                                                    Pro-tip, if the party doesn’t specifically
                                                    require they must be additional insured in a
                                                    written contract, don’t select this for them on
                                                    the cert.
                                                </React.Fragment>
                                            ),
                                            appearance: 'border',
                                            children: (
                                                <Text style="Label 1">
                                                    Remove Additional Insured
                                                </Text>
                                            ),
                                            disabled: !hasAdditional,
                                        }}
                                        messages={fields.isAdditionalInsuredDisabled.messages}
                                        type={fields.isAdditionalInsuredDisabled.type}
                                    />
                                    <Form.Field
                                        inputProps={{
                                            onChange: (
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setValue({
                                                    ...value,
                                                    isPrimaryNonContributoryDisabled:
                                                        event.target.checked,
                                                });
                                            },
                                            checked:
                                                fields.isPrimaryNonContributoryDisabled.props
                                                    .value || false,
                                            tooltipText: (
                                                <React.Fragment>
                                                    “Primary” provisions of{' '}
                                                    <b>Primary and Noncontributory</b> language
                                                    designates that the insurance policy will
                                                    respond first in the event of a claim when
                                                    multiple policies may be triggered. The
                                                    “Noncontributory” provision means that the
                                                    Insured's policy will respond without seeking
                                                    contribution from other triggered policies.
                                                    <br />
                                                    <br />
                                                    Insureds who provide this endorsement to
                                                    certificate holders/additional insureds should
                                                    understand that the Insured's policy will be
                                                    “first in line” to respond if there is a claim
                                                    triggering the policy and only after the
                                                    Insured's policy is exhausted might other
                                                    policies of the additional insureds/parties be
                                                    applicable.
                                                    <br />
                                                    <br />
                                                    Pro-tip, if the party doesn't specifically
                                                    require primary and/or noncontributory in a
                                                    written contract, don't select this for them on
                                                    the cert.
                                                </React.Fragment>
                                            ),
                                            appearance: 'border',
                                            children: (
                                                <Text style="Label 1">
                                                    Remove Blanket Primary Non-contributory
                                                </Text>
                                            ),
                                            disabled: !hasPrimaryNonContributory,
                                        }}
                                        messages={fields.isPrimaryNonContributoryDisabled.messages}
                                        type={fields.isPrimaryNonContributoryDisabled.type}
                                    />
                                    <Form.Field
                                        inputProps={{
                                            onChange: (
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                setValue({
                                                    ...value,
                                                    isWaiverOfSubrogationDisabled:
                                                        event.target.checked,
                                                });
                                            },
                                            checked:
                                                fields.isWaiverOfSubrogationDisabled.props.value ||
                                                false,
                                            tooltipText: (
                                                <React.Fragment>
                                                    A <b>Waiver Of Subrogation</b> prevents your
                                                    insurer from seeking compensation for damages by
                                                    pursuing action against parties that caused the
                                                    loss or damages. When a Waiver of Subrogation is
                                                    required in a contract or if a party
                                                    specifically wants their name on a customized
                                                    endorsement, this provision puts the “blocker”
                                                    on the insurer going back against that party to
                                                    recover and offset the loss.
                                                    <br />
                                                    <br />
                                                    Pro-tip, if the party doesn’t specifically
                                                    require a waiver of subrogation for a line of
                                                    coverage in a written contract, don’t select
                                                    this for them on the cert.
                                                </React.Fragment>
                                            ),
                                            appearance: 'border',
                                            children: (
                                                <Text style="Label 1">
                                                    Remove Waiver Of Subrogation
                                                </Text>
                                            ),
                                            disabled: !hasWOS,
                                        }}
                                        messages={fields.isWaiverOfSubrogationDisabled.messages}
                                        type={fields.isWaiverOfSubrogationDisabled.type}
                                    />
                                </StackLayout>
                            </Form>
                        </StackLayout>
                        <ButtonBar
                            split={'-2'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack}>Back</TextButton>
                            <Button
                                onClick={() => {
                                    onPreviewCertificate(value);
                                }}
                                appearance="secondary"
                            >
                                Preview the Certificate
                            </Button>
                            <Button onClick={submit}>Issue the Certificate</Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <FormLayout appearance="wide">
                    <SidebarLayout.MobileFooter split="-1">
                        <Text>What does this mean?</Text>
                        <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                            Let us explain
                        </SidebarLayout.Link>
                    </SidebarLayout.MobileFooter>
                    <StatusMessage status="helptext">
                        Not what you had in mind? Please{' '}
                        <TextButton onClick={onClick}>get in touch</TextButton> with our Customer
                        Success Team.
                    </StatusMessage>
                </FormLayout>
            </StackLayout>
        </SidebarLayout>
    );
}
