import React from 'react';
import { State } from '@embroker/shotwell/core/types/StateList';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ColumnLayout, InsetBox, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import { Link } from '@app/view/components/Link/Link';
import { Nullable } from '@embroker/shotwell/core/types';

export interface StateDisclosureModalProps {
    state?: Nullable<State>;
    companyName: string;
    fullName: string;
}

const ADMITTED_AND_NOT_ADMITTED_BLOG_POST_LINK =
    'https://www.embroker.com/blog/admitted-vs-non-admitted-insurance-carriers/';
const NJ_SL_DISCLOSURE_DOCUMENT_PREVIEW =
    'https://embroker-public.s3.amazonaws.com/SL-documentation/New+Jersey+-+SL+Insured+Acknowledgment.pdf';
const MD_SL_DISCLOSURE_DOCUMENT_PREVIEW =
    'https://embroker-public.s3.amazonaws.com/SL-documentation/Maryland+-+Disclosure+Regarding+Surplus+Lines+Insurance.pdf';
const FL_SL_DISCLOSURE_DOCUMENT_PREVIEW =
    'https://embroker-public.s3.amazonaws.com/SL-documentation/Florida+-+Surplus+Lines+Disclosure.pdf';

const NY_SL_DISCLOSURE_DOCUMENT_PREVIEW =
    'https://embroker-public.s3.amazonaws.com/SL-documentation/New+York+-+Notice+Of+Excess+Line+Placement.pdf';

const MA_SL_DISCLOSURE_DOCUMENT_PREVIEW =
    'https://embroker-public.s3.amazonaws.com/SL-documentation/Massachusetts+-+Form+BR7+Affidavit+By+Assured+.pdf';

function CAState() {
    return (
        <InsetBox>
            <StackLayout gap="24">
                <Text style="heading 3">IMPORTANT NOTICE:</Text>
                <Text style="heading 5">
                    1. The insurance policy that you are applying to purchase is being issued by an
                    insurer that is not licensed by the State of California. These companies are
                    called “nonadmitted” or “surplus line” insurers.
                </Text>

                <Text style="heading 5">
                    2. The insurer is not subject to the financial solvency regulation and
                    enforcement that apply to California licensed insurers.{' '}
                </Text>

                <Text style="heading 5">
                    3. The insurer does not participate in any of the insurance guarantee funds
                    created by California law. Therefore, these funds will not pay your claims or
                    protect your assets if the insurer becomes insolvent and is unable to make
                    payments as promised.
                </Text>

                <Text style="heading 5">
                    4. The insurer should be licensed either as a foreign insurer in another state
                    in the United States or as a non-United States (alien) insurer. You should ask
                    questions of your insurance agent, broker, or “surplus line” broker or contact
                    the California Department of Insurance at the toll-free number 1-800-927-4357 or
                    internet website www.insurance.ca.gov. Ask whether or not the insurer is
                    licensed as a foreign or non-United States (alien) insurer and for additional
                    information about the insurer. You may also visit the NAIC’s internet website at
                    www.naic.org. The NAIC—the National Association of Insurance Commissioners—is
                    the regulatory support organization created and governed by the chief insurance
                    regulators in the United States.
                </Text>

                <Text style="heading 5">
                    5. Foreign insurers should be licensed by a state in the United States and you
                    may contact that state’s department of insurance to obtain more information
                    about that insurer. You can find a link to each state from this NAIC internet
                    website:{' '}
                    <TextButton href="https://naic.org/state_web_map.htm" target="blank">
                        https://naic.org/state_web_map.htm
                    </TextButton>
                    .
                </Text>

                <Text style="heading 5">
                    6. For non-United States (alien) insurers, the insurer should be licensed by a
                    country outside of the United States and should be on the NAIC’s International
                    Insurers Department (IID) listing of approved nonadmitted non-United States
                    insurers. Ask your agent, broker, or “surplus line” broker to obtain more
                    information about that insurer.
                </Text>

                <Text style="heading 5">
                    7. California maintains a “List of Approved Surplus Line Insurers (LASLI).” Ask
                    your agent or broker if the insurer is on that list, or view that list at the
                    internet website of the California Department of Insurance:
                    <TextButton
                        href="www.insurance.ca.gov/01-consumers/120-company/07-lasli/lasli.cfm"
                        target="blank"
                    >
                        www.insurance.ca.gov/01-consumers/120-company/07-lasli/lasli.cfm
                    </TextButton>
                    .{' '}
                </Text>

                <Text style="heading 5">
                    8. If you, as the applicant, required that the insurance policy you have
                    purchased be effective immediately, either because existing coverage was going
                    to lapse within two business days or because you were required to have coverage
                    within two business days, and you did not receive this disclosure form and a
                    request for your signature until after coverage became effective, you have the
                    right to cancel this policy within five days of receiving this disclosure. If
                    you cancel coverage, the premium will be prorated and any broker’s fee charged
                    for this insurance will be returned to you.
                </Text>

                <ColumnLayout split="-1">
                    <Text>D-1 (Effective January 1, 2020)</Text>
                </ColumnLayout>
            </StackLayout>
        </InsetBox>
    );
}

interface MAStateProps {
    companyName: string;
    fullName: string;
}

function MAState({ fullName, companyName }: MAStateProps) {
    const today = new Date(Date.now());
    return (
        <StackLayout gap="24">
            <Text style="label 1">
                The surplus lines insurer with whom the insurance was placed is not licensed in
                Massachusetts and is not subject to Massachusetts regulations; and in the event of
                the insolvency of the surplus lines insurer, losses will not be paid by the state
                insurance guaranty fund.
            </Text>
            <Text>
                I/We <Text style="label 1">{fullName}</Text> of{' '}
                <Text style="label 1">{companyName}</Text> do hereby state that in{' '}
                <DateDisplay format="MMMM, yyyy\" value={today} />, I/We directed{' '}
                <Text style="label 1">Julie Noonan</Text> my/our Insurance Broker to obtain
                insurance against certain risks as described herein. My/Our Insurance Broker
                informed us that the required insurance could not be obtained from, or would not be
                written by, companies licensed or admitted to transact business in the Commonwealth
                of Massachusetts. I/We, the Assured, was/were informed that the type and amount of
                insurance shown below could be obtained from certain insurers not admitted to
                transact business in the Commonwealth. I/We was/were further informed: A. The
                surplus lines insurer with whom the insurance was placed is not licensed in this
                state and is not subject to Massachusetts regulations. B. In the event of the
                insolvency of the surplus lines insurer, losses will not be paid by the state
                insurance guaranty fund.
            </Text>
            <br />
            <Text style="label 1">
                <Link href={MA_SL_DISCLOSURE_DOCUMENT_PREVIEW} target="_blank">
                    This is the document
                </Link>{' '}
                needed for our records SL tax filing purposes with the state of MA and your
                signature is required solely for that purpose.
            </Text>
            <br />
            <Text style="label 1">
                If you want to learn more on Non-admitted placements, please follow{' '}
                <Link href={ADMITTED_AND_NOT_ADMITTED_BLOG_POST_LINK} target="_blank">
                    this link
                </Link>
            </Text>
        </StackLayout>
    );
}

function NJState() {
    return (
        <StackLayout gap="24">
            <Text>
                The undersigned applicant has been advised by the undersigned originating insurance
                producer and understands that an insurance policy written by a surplus lines insurer
                is not subject to the filing or approval requirements of the New Jersey Department
                of Banking and Insurance. Such a policy may contain conditions, limitations,
                exclusions and different terms than a policy issued by an insurer granted a
                Certificate of Authority by the New Jersey Department of Banking and Insurance.
            </Text>
            <br />
            <Text style="label 1">
                <Link href={NJ_SL_DISCLOSURE_DOCUMENT_PREVIEW} target="_blank">
                    This is the document
                </Link>{' '}
                needed for our records and your signature is required solely for that purpose.
            </Text>
            <br />
            <Text style="label 1">
                If you want to learn more on Non-admitted placements, please follow{' '}
                <Link href={ADMITTED_AND_NOT_ADMITTED_BLOG_POST_LINK} target="_blank">
                    this link
                </Link>
            </Text>
        </StackLayout>
    );
}

function NYState() {
    return (
        <StackLayout gap="24">
            <Text>
                <Text style="label 1">(1) </Text>
                The unauthorized insurer with which the coverage would be placed is not authorized
                to do an insurance business in this State and is not subject to supervision by this
                State;
            </Text>
            <Text>
                <Text style="label 1">(2) </Text>
                In the event of the insolvency of the unauthorized insurer, losses will not be
                covered by any New York State security fund; and
            </Text>
            <Text>
                <Text style="label 1">(3) </Text>
                The policy may not be subject to all of the regulations of the superintendent
                pertaining to policy forms. A copy of the written notice provided to the insured
                shall be attached to the affirming broker’s affidavit.
            </Text>
            <br />
            <Text style="label 1">
                <Link href={NY_SL_DISCLOSURE_DOCUMENT_PREVIEW} target="_blank">
                    This is the document
                </Link>{' '}
                needed for our records and your signature is required solely for that purpose.
            </Text>
            <br />
            <Text style="label 1">
                If you want to learn more on Non-admitted placements, please follow{' '}
                <Link href={ADMITTED_AND_NOT_ADMITTED_BLOG_POST_LINK} target="_blank">
                    this link
                </Link>
            </Text>
        </StackLayout>
    );
}
function NDState() {
    return (
        <StackLayout gap="24">
            <Text style="body 1">
                “Notice: 1. an insurer that is not licensed in this state is issuing the insurance
                policy that you have applied to purchase. These companies are called “nonadmitted”
                or “surplus lines” insurers. 2. The insurer is not subject to the financial solvency
                regulation and enforcement that applies to licensed insurers in this state. 3. These
                insurers generally do not participate in insurance guaranty funds created by state
                law.
            </Text>
            <Text style="body 1">
                These guaranty funds will not pay your claims or protect your assets if the insurer
                becomes insolvent and is unable to make payments as promised. 4. Some states
                maintain lists of approved or eligible surplus lines insurers and surplus lines
                producers may use only insurers on the lists. Some states issue orders that
                particular surplus lines insurers cannot be used. 5. For additional information
                about the above matters and about the insurer, you should ask questions of your
                insurance producer or surplus lines producer. You may also contact your insurance
                department consumer help line.”
            </Text>
        </StackLayout>
    );
}

function WVState() {
    return (
        <StackLayout gap="24">
            <Text style="label 1">
                “Notice: 1. An insurer that is not licensed in this state is issuing the insurance
                policy that you have applied to purchase. These companies are called “nonadmitted”
                or “surplus lines” insurers. 2. The insurer is not subject to the financial solvency
                regulation and enforcement that applies to licensed insurers in this state. 3. These
                insurers generally do not participate in insurance guaranty funds created by state
                law. These guaranty funds will not pay your claims or protect your assets if the
                insurer becomes insolvent and is unable to make payments as promised. 4. Some states
                maintain lists of approved or eligible surplus lines insurers and surplus lines
                brokers may use only insurers on the lists. Some states issue orders that particular
                surplus lines insurers cannot be used. 5. For additional information about the above
                matters and about the insurer, you should ask questions of your insurance agent or
                surplus lines licensee. You may also contact your insurance commission consumer help
                line.”
            </Text>
        </StackLayout>
    );
}

function NMState() {
    return (
        <StackLayout gap="24">
            <Text style="label 1">
                (1) the surplus lines insurer with which the insurance was to be placed is not an
                authorized insurer in this state and is not subject to the superintendent's
                supervision; and
            </Text>
            <Text style="label 1">
                (2) in the event the surplus lines insurer becomes insolvent, claims will not be
                paid nor will unearned premiums be returned by any New Mexico insurance guaranty
                fund.
            </Text>
        </StackLayout>
    );
}

function RIState() {
    return (
        <StackLayout gap="24">
            <Text style="heading 3">NOTICE</Text>
            <Text>
                THIS INSURANCE CONTRACT HAS BEEN PLACED WITH AN INSURER NOT LICENSED TO DO BUSINESS
                IN THE STATE OF RHODE ISLAND BUT APPROVED AS A SURPLUS LINES INSURER. THE INSURER IS
                NOT A MEMBER OF THE RHODE ISLAND INSURERS INSOLVENCY FUND. SHOULD THE INSURER BECOME
                INSOLVENT, THE PROTECTION AND BENEFITS OF THE RHODE ISLAND INSURERS INSOLVENCY FUND
                ARE NOT AVAILABLE.
            </Text>
        </StackLayout>
    );
}

function MDState() {
    return (
        <StackLayout gap="24">
            <Text style="label 1">
                ”I have agreed to the placement of coverage in the surplus lines market. This
                contract is registered and delivered as a surplus line coverage under the Surplus
                Lines Insurance Law, and it may in some respects be different from contracts issued
                by insurers in the admitted markets, and, accordingly, it may, depending upon the
                circumstances, be more or less favorable to an insured than a contract from an
                admitted carrier might be.”
            </Text>
            <br />
            <Text style="label 1">
                <Link href={MD_SL_DISCLOSURE_DOCUMENT_PREVIEW} target="_blank">
                    This is the document
                </Link>{' '}
                needed for our records SL tax filing purposes with the state of MD and your
                signature is required solely for that purpose.
            </Text>
            <br />
            <Text style="label 1">
                If you want to learn more on Non-admitted placements, please follow{' '}
                <Link href={ADMITTED_AND_NOT_ADMITTED_BLOG_POST_LINK} target="_blank">
                    this link
                </Link>
            </Text>
        </StackLayout>
    );
}

function FLState() {
    return (
        <StackLayout gap="24">
            <Text style="label 1">
                ”I have agreed to the placement of coverage in the surplus lines market. This
                contract is registered and delivered as a surplus line coverage under the Surplus
                Lines Insurance Law, and it may in some respects be different from contracts issued
                by insurers in the admitted markets, and, accordingly, it may, depending upon the
                circumstances, be more or less favorable to an insured than a contract from an
                admitted carrier might be.”
            </Text>
            <br />
            <Text style="label 1">
                <Link href={FL_SL_DISCLOSURE_DOCUMENT_PREVIEW} target="_blank">
                    This is the document
                </Link>{' '}
                needed for our records SL tax filing purposes with the state of FL and your
                signature is required solely for that purpose.
            </Text>
            <br />
            <Text style="label 1">
                If you want to learn more on Non-admitted placements, please follow{' '}
                <Link href={ADMITTED_AND_NOT_ADMITTED_BLOG_POST_LINK} target="_blank">
                    this link
                </Link>
            </Text>
        </StackLayout>
    );
}

function OtherState() {
    return (
        <StackLayout gap="24">
            <Text style="heading 3">Coverage placement agreement</Text>
            <Text>
                I have agreed to the placement of coverage in the surplus lines market. This
                contract is registered and delivered as a surplus line coverage under the Surplus
                Lines Insurance Law, and it may in some respects be different from contracts issued
                by insurers in the admitted markets, and, accordingly, it may, depending upon the
                circumstances, be more or less favorable to an insured than a contract from an
                admitted carrier might be.
            </Text>
        </StackLayout>
    );
}

export function StateDisclosureModal({ state, fullName, companyName }: StateDisclosureModalProps) {
    switch (state) {
        case 'CA':
            return <CAState />;
        case 'FL':
            return <FLState />;
        case 'MA':
            return <MAState companyName={companyName} fullName={fullName} />;
        case 'MD':
            return <MDState />;
        case 'NJ':
            return <NJState />;
        case 'NY':
            return <NYState />;
        case 'ND':
            return <NDState />;
        case 'WV':
            return <WVState />;
        case 'NM':
            return <NMState />;
        case 'RI':
            return <RIState />;
        default:
            return <OtherState />;
    }
}
