import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileReportClickCoverageLearnMore } from '@app/public/entities/events';
import { AppTypeCode } from '@app/shopping/types/enums';

export interface PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent extends UseCase {
    execute(request: {
        appTypeCode: AppTypeCode;
    }): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileReportClickLearnMoreAboutInsuranceEventUseCase
    extends UseCase
    implements PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent
{
    public static type = Symbol(
        'RiskProfile/PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent',
    );

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        appTypeCode,
    }: {
        appTypeCode: AppTypeCode;
    }): AsyncResult<void, InvalidArgument | OperationFailed> {
        const clickCoverageLearnMoreEvent: RiskProfileReportClickCoverageLearnMore = {
            origin: 'RiskProfile',
            name: 'ClickCoverageLearnMore',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            appTypeCode,
        };

        await this.eventBus.publish(clickCoverageLearnMoreEvent);

        return Success();
    }
}

export const PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent: UseCaseClass<PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent> =
    PublishRiskProfileReportClickLearnMoreAboutInsuranceEventUseCase;
