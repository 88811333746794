import type * as APIType from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { StructuralComponentTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { isAfter, isSameDay, isValid, startOfDay } from 'date-fns';
import { ESPQuoteRepository } from '../../../../quote/esp/repositories/ESPQuoteRepository';
import { Application } from '../../types/ESPEndorsementApplication';
import {
    AddESPEndorsementLiabilityCoverageType,
    EditESPEndorsementLiabilityCoverageType,
    ESPEndorsementCoverageSectionLiabilityMap,
    ESPEndorsementLiabilityCoverageType,
} from '../../types/ESPEndorsementLiabilityCoverageType';
import {
    ESPEndorsementLiabilityCoverage,
    ESPEndorsementPolicy,
    ESPEndorsementPolicyAddressData,
} from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementRate } from '../../types/ESPEndorsementRate';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import {
    CreateAddCoverageEndorsementParam,
    CreateAddressEndorsementParam,
    CreateEditCoverageEndorsementParam,
    CreateEditMultipleCoverageEndorsementParam,
    CreateNamedInsuredEndorsement,
    ESPEndorsementRepository,
    RateAddCoverageEndorsementParam,
    RateEditCoverageEndorsementParam,
    RateEditMultipleCoverageEndorsementParam,
} from './index';
import { QuoteList } from '../../../../quote/types/QuoteList';

interface EndorsementChangeNameRequest extends APIType.EndorsementCreateEndorsementRequest {
    policy_id: UUID;
    endorsement_type: 'change_name';
    effective_date: Date;
    named_insured_endorsement: {
        named_insured: string;
    };
}

interface EndorsementChangeAddressRequest extends APIType.EndorsementCreateEndorsementRequest {
    policy_id: UUID;
    endorsement_type: 'change_address';
    effective_date: Date;
    address_endorsement: {
        address_line1: string;
        address_line2: string;
        city: string;
        zip_code: ZipCode;
        state?: State;
    };
}

interface EndorsementSaveAddCoverageRequest extends APIType.EndorsementRateRequest {
    policy_id: UUID;
    endorsement_type: 'add_coverage';
    effective_date: Date;
    coverage_endorsement: {
        coverage: AddESPEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
        enhanced: boolean;
    };
}

interface EndorsementSaveEditCoverageRequest extends APIType.EndorsementRateRequest {
    policy_id: UUID;
    endorsement_type: 'change_limit';
    effective_date: Date;
    limit_endorsement: {
        coverage: EditESPEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
    };
}

type CreateEndorsementRequest =
    | EndorsementChangeNameRequest
    | EndorsementChangeAddressRequest
    | EndorsementSaveAddCoverageRequest
    | EndorsementSaveEditCoverageRequest;

interface EndorsementRateAddCoverageRequest extends APIType.EndorsementRateRequest {
    policy_id: UUID;
    endorsement_type: 'add_coverage';
    effective_date: Date;
    coverage_endorsement: {
        coverage: AddESPEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
        enhanced: boolean;
    };
}

interface EndorsementRateEditCoverageRequest extends APIType.EndorsementRateRequest {
    policy_id: UUID;
    endorsement_type: 'change_limit';
    effective_date: Date;
    limit_endorsement: {
        coverage: EditESPEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
    };
}

type EndorsementRateRequest =
    | EndorsementRateAddCoverageRequest
    | EndorsementRateEditCoverageRequest;

function isEligibleMlPolicy(policy: Immutable<APIType.Policy>, serverTime: Date) {
    return (
        policy.solartis_policy_number !== '' &&
        policy.view_mode === 'PolicyViewStatusCodeListPublished' &&
        (isSameDay(policy.effective_period_end, serverTime) ||
            isAfter(policy.effective_period_end, serverTime)) &&
        policy.cancellation_date == null
    );
}

function mapToESPEndorsementLiabilityCoverage(
    section: Immutable<APIType.PolicySection>,
): ESPEndorsementLiabilityCoverage {
    const mainLiability = section.liability_list.find(
        (liability) =>
            ESPEndorsementCoverageSectionLiabilityMap.get(
                section.type as StructuralComponentTypeCodeListItem,
            ) === liability.type_code,
    );

    return {
        typeCode: mainLiability?.type_code as ESPEndorsementLiabilityCoverageType,
        limit: mainLiability?.limit1_amount ? mainLiability?.limit1_amount : USD(0),
        retention: mainLiability?.s_i_r_1_amount ? mainLiability?.s_i_r_1_amount : USD(0),
        premium: section.premium.amount ? section.premium.amount : USD(0),
    };
}

function mapToESPEndorsementCyberLiabilityCoverage(
    section: Immutable<APIType.PolicySection>,
): ESPEndorsementLiabilityCoverage {
    const mainLiability = section.liability_list.find(
        (liability) =>
            ESPEndorsementCoverageSectionLiabilityMap.get(
                section.type as StructuralComponentTypeCodeListItem,
            ) === liability.type_code,
    );

    const cyberLiabilitySection = section.liability_list.find(
        (liability) => liability.type_code === 'LiabilityCoverageCodeListCyberExtortion',
    );

    return {
        typeCode: 'LiabilityCoverageCodeListCyberSplit' as ESPEndorsementLiabilityCoverageType,
        limit: cyberLiabilitySection?.limit1_amount ? cyberLiabilitySection?.limit1_amount : USD(0),
        retention: mainLiability?.s_i_r_1_amount ? mainLiability?.s_i_r_1_amount : USD(0),
        premium: section.premium.amount ? section.premium.amount : USD(0),
    };
}

function getESPEndorsementAvailableLiabilities(
    policy: Immutable<APIType.Policy>,
    isTechCyberSplit: boolean,
): Array<ESPEndorsementLiabilityCoverage> {
    const coverageSectionList = policy?.coverage_section_list;
    if (!coverageSectionList) {
        return [];
    }
    let coverageList = coverageSectionList.map(mapToESPEndorsementLiabilityCoverage);
    if (isTechCyberSplit) {
        const cyberCoverageSectionList = coverageSectionList.filter(
            (section) =>
                section.type === 'StructuralComponentTypeCodeListTechEOCyberLiabilitySection',
        );
        const cyberCoverage = cyberCoverageSectionList.map(
            mapToESPEndorsementCyberLiabilityCoverage,
        );
        coverageList = coverageList.concat(cyberCoverage);
    }
    return coverageList;
}

const isNamedInsuredPredicate = (insured: { id: UUID; type_code: string; name: string }) =>
    insured.type_code === 'InsuredTypeCodeListFirstNamed';

function getESPEndorsementNamedInsured(policy: Immutable<APIType.Policy>): string {
    const namedInsured = policy.insured_list.find(isNamedInsuredPredicate);
    // api workaround: ESP policy must contain namedInsured; use empty "" as sentinel value for validation
    return namedInsured !== null && namedInsured !== undefined ? namedInsured.name : '';
}

function getESPEndorsementAddressData(
    policy: Immutable<APIType.Policy>,
): ESPEndorsementPolicyAddressData {
    // api workaround: ESP policy must contain address data; use empty "" as sentinel for validation
    let state: State | undefined;
    const result = State.validate(policy.state);
    if (isOK(result)) {
        state = result.value;
    }
    return {
        addressLine1: policy.address ? policy.address : '',
        addressLine2: policy.suite_number ? policy.suite_number : '',
        city: policy.city ? policy.city : '',
        state: state ? state : undefined,
        zipCode: policy.zip_code ? (policy.zip_code as ZipCode) : undefined,
    };
}

function isEPLIEligible(quotableShoppingCoverages: Immutable<Array<string>>): boolean {
    return quotableShoppingCoverages.includes('ShoppingCoverageCodeListEmploymentPractices');
}

function isDnOEligible(quotableShoppingCoverages: Immutable<Array<string>>): boolean {
    return quotableShoppingCoverages.includes('ShoppingCoverageCodeListDirectorsAndOfficers');
}

function isFiduciaryEligible(quotableShoppingCoverages: Immutable<Array<string>>): boolean {
    return quotableShoppingCoverages.includes('ShoppingCoverageCodeListFiduciary');
}

@injectable()
export class APIESPEndorsementRepository implements ESPEndorsementRepository {
    constructor(
        @inject(ESPQuoteRepository)
        private espQuoteRepository: ESPQuoteRepository,
    ) {}

    async loadApplication(
        applicationId: UUID,
    ): AsyncResult<Application, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        return Application.create({
            questionnaireData: applicationResponse.value.questionnaire_data,
        });
    }

    async loadPolicy(
        policyId: UUID,
    ): AsyncResult<ESPEndorsementPolicy, InvalidArgument | OperationFailed> {
        const userPolicyResponse = await API.request('policy/user_policy', {
            id: policyId,
        });
        if (isErr(userPolicyResponse)) {
            return handleOperationFailure(userPolicyResponse);
        }

        let epliEligible = true;
        let dnoEligible = true;
        let fiduciaryEligible = true;

        const applicationResponse = await API.request('shopping/application', {
            id: userPolicyResponse.value.insurance_application_id as UUID,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const purchasedQuote = applicationResponse.value.quote_list
            ? QuoteList.getLastQuote(applicationResponse.value.quote_list)
            : null;

        // old policy has quote.options.esp.errors_and_omissions, new policy has technology and possible cyber
        const isTechCyberSplit = !!purchasedQuote?.options?.esp?.technology;

        if (!isErr(applicationResponse) && applicationResponse.value.questionnaire_data != null) {
            const epliEligibility = isEPLIEligible(
                applicationResponse.value.quotable_shopping_coverage_list ?? [],
            );
            epliEligible = epliEligibility;
            const dnoEligibility = isDnOEligible(
                applicationResponse.value.quotable_shopping_coverage_list ?? [],
            );
            dnoEligible = dnoEligibility;
            const fiduciaryEligibility = isFiduciaryEligible(
                applicationResponse.value.quotable_shopping_coverage_list ?? [],
            );
            fiduciaryEligible = fiduciaryEligibility;
        }

        const serverDateResult = await this.getServerDate();
        if (isErr(serverDateResult)) {
            return serverDateResult;
        }

        if (!isEligibleMlPolicy(userPolicyResponse.value, serverDateResult.value)) {
            return Failure(
                InvalidArgument({ argument: 'user_policy', value: userPolicyResponse.value }),
            );
        }
        const availableLiabilitiesResult = getESPEndorsementAvailableLiabilities(
            userPolicyResponse.value,
            isTechCyberSplit,
        );
        const namedInsured = getESPEndorsementNamedInsured(userPolicyResponse.value);
        const addressData = getESPEndorsementAddressData(userPolicyResponse.value);
        return ESPEndorsementPolicy.create({
            applicationId: userPolicyResponse.value.insurance_application_id as UUID,
            addressData: addressData,
            availableLiabilities: availableLiabilitiesResult,
            submittedAt: applicationResponse.value.submitted_at,
            effectivePeriodEnd: userPolicyResponse.value.effective_period_end,
            effectivePeriodStart: userPolicyResponse.value.effective_period_start,
            namedInsured: namedInsured,
            isEPLIEligible: epliEligible,
            isDNOEligible: dnoEligible,
            isFiduciaryEligible: fiduciaryEligible,
            inRunoff: userPolicyResponse.value.in_runoff,
            isReferred:
                userPolicyResponse.value.referred != null
                    ? userPolicyResponse.value.referred
                    : undefined,
            hasRenewalApplication: userPolicyResponse.value.has_renewal_application,
        });
    }

    async loadUserData(): AsyncResult<ESPEndorsementUserData, InvalidArgument | OperationFailed> {
        const userDetailsResponse = await API.request('user/details');
        if (isErr(userDetailsResponse)) {
            return handleOperationFailure(userDetailsResponse);
        }
        // api workaround: ESP UserAccount must contain prefix_title; use empty "" as sentinel value for validation
        const title = userDetailsResponse.value.user.prefix_title
            ? userDetailsResponse.value.user.prefix_title
            : '';
        let state: State | undefined;
        const result = State.validate(userDetailsResponse.value.org.headquarters.state);
        if (isOK(result)) {
            state = result.value;
        }
        return ESPEndorsementUserData.create({
            company: userDetailsResponse.value.org.name,
            fullName: `${userDetailsResponse.value.user.first_name} ${userDetailsResponse.value.user.last_name}`,
            title: title,
            usaState: state ?? undefined,
        });
    }

    async createNamedInsuredEndorsement(
        createNamedInsuredEndorsement: CreateNamedInsuredEndorsement,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        return this.createEndorsement({
            effective_date: createNamedInsuredEndorsement.effectiveDate,
            named_insured_endorsement: {
                named_insured: createNamedInsuredEndorsement.namedInsured,
            },
            policy_id: createNamedInsuredEndorsement.policyId,
            endorsement_type: 'change_name',
            reinstate_reason: null,
            runoff_length: null,
            extend_policy_endorsement: null,
            address_endorsement: null,
            coverage_endorsement: null,
            limit_endorsement: null,
            delete_coverage: null,
        });
    }

    async createAddressEndorsement(
        createAddressEndorsementParam: CreateAddressEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        return this.createEndorsement({
            effective_date: createAddressEndorsementParam.effectiveDate,
            named_insured_endorsement: null,
            policy_id: createAddressEndorsementParam.policyId,
            endorsement_type: 'change_address',
            reinstate_reason: null,
            runoff_length: null,
            extend_policy_endorsement: null,
            address_endorsement: {
                address_line1: createAddressEndorsementParam.addressLine1,
                address_line2: createAddressEndorsementParam.addressLine2,
                city: createAddressEndorsementParam.city,
                zip_code: createAddressEndorsementParam.zipCode,
                state: createAddressEndorsementParam.state,
            },
            coverage_endorsement: null,
            limit_endorsement: null,
            delete_coverage: null,
        });
    }

    async createAddCoverageEndorsement(
        createAddCoverageEndorsementParam: CreateAddCoverageEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        return this.createEndorsement({
            effective_date: createAddCoverageEndorsementParam.effectiveDate,
            named_insured_endorsement: null,
            policy_id: createAddCoverageEndorsementParam.policyId,
            endorsement_type: 'add_coverage',
            reinstate_reason: null,
            runoff_length: null,
            extend_policy_endorsement: null,
            address_endorsement: null,
            limit_endorsement: null,
            coverage_endorsement: {
                coverage: createAddCoverageEndorsementParam.coverageTypeCode,
                limit: createAddCoverageEndorsementParam.limit,
                retention: createAddCoverageEndorsementParam.retention,
                enhanced: createAddCoverageEndorsementParam.enhanced,
            },
            delete_coverage: null,
        });
    }

    async createEditCoverageEndorsement(
        createEditCoverageEndorsementParam: CreateEditCoverageEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        return this.createEndorsement({
            effective_date: createEditCoverageEndorsementParam.effectiveDate,
            named_insured_endorsement: null,
            policy_id: createEditCoverageEndorsementParam.policyId,
            endorsement_type: 'change_limit',
            reinstate_reason: null,
            runoff_length: null,
            extend_policy_endorsement: null,
            address_endorsement: null,
            limit_endorsement: {
                coverage: createEditCoverageEndorsementParam.coverageTypeCode,
                limit: createEditCoverageEndorsementParam.limit,
                retention: createEditCoverageEndorsementParam.retention,
            },
            coverage_endorsement: null,
            delete_coverage: null,
        });
    }

    private async createEndorsement(
        createEndorsementRequest: CreateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createEndorsementResponse = await API.request(
            'endorsement/create_endorsement',
            createEndorsementRequest,
        );
        if (isErr(createEndorsementResponse)) {
            return handleOperationFailure(createEndorsementResponse);
        }
        return Success(createEndorsementResponse.value.task_id);
    }

    async createEditMultipleCoverageEndorsement(
        createEditMultipleCoverageEndorsementParam: CreateEditMultipleCoverageEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const coverages = createEditMultipleCoverageEndorsementParam.coverages.map((coverage) => ({
            coverage_type: coverage.coverageTypeCode,
            limit: coverage.limit,
            retention: coverage.retention,
        }));
        return this.createMultipleCoverageEndorsement({
            agreement_id: createEditMultipleCoverageEndorsementParam.agreementId,
            effective_date: createEditMultipleCoverageEndorsementParam.effectiveDate,
            coverages: coverages,
        });
    }

    private async createMultipleCoverageEndorsement(
        createEndorsementRequest: APIType.EndorsementChangeEspLimitsTaskRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createEndorsementResponse = await API.request(
            'endorsement/change_esp_limits_task',
            createEndorsementRequest,
        );
        if (isErr(createEndorsementResponse)) {
            return handleOperationFailure(createEndorsementResponse);
        }
        return Success(createEndorsementResponse.value.task_id);
    }

    async rateAddCoverageEndorsement(
        rateAddCoverageEndorsementParam: RateAddCoverageEndorsementParam,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed> {
        return this.rateEndorsement({
            effective_date: rateAddCoverageEndorsementParam.effectiveDate,
            named_insured_endorsement: null,
            policy_id: rateAddCoverageEndorsementParam.policyId,
            endorsement_type: 'add_coverage',
            reinstate_reason: null,
            runoff_length: null,
            extend_policy_endorsement: null,
            address_endorsement: null,
            limit_endorsement: null,
            coverage_endorsement: {
                coverage: rateAddCoverageEndorsementParam.coverageTypeCode,
                limit: rateAddCoverageEndorsementParam.limit,
                retention: rateAddCoverageEndorsementParam.retention,
                enhanced: rateAddCoverageEndorsementParam.enhanced,
            },
            delete_coverage: null,
        });
    }

    async rateEditCoverageEndorsement(
        rateEditCoverageEndorsementParam: RateEditCoverageEndorsementParam,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed> {
        return this.rateEndorsement({
            effective_date: rateEditCoverageEndorsementParam.effectiveDate,
            named_insured_endorsement: null,
            policy_id: rateEditCoverageEndorsementParam.policyId,
            endorsement_type: 'change_limit',
            reinstate_reason: null,
            runoff_length: null,
            extend_policy_endorsement: null,
            address_endorsement: null,
            limit_endorsement: {
                coverage: rateEditCoverageEndorsementParam.coverageTypeCode,
                limit: rateEditCoverageEndorsementParam.limit,
                retention: rateEditCoverageEndorsementParam.retention,
            },
            coverage_endorsement: null,
            delete_coverage: null,
        });
    }

    private async rateEndorsement(
        endorsementRateRequest: EndorsementRateRequest,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed> {
        const rateResponse = await API.request('endorsement/rate', endorsementRateRequest);
        if (isErr(rateResponse)) {
            return handleOperationFailure(rateResponse);
        }
        return ESPEndorsementRate.create({
            annualPremium: rateResponse.value.annual_premium,
            prorate: rateResponse.value.prorate,
            taxes: rateResponse.value.taxes,
            fees: rateResponse.value.fees,
            total: rateResponse.value.total,
        });
    }

    async rateEditMultipleCoverageEndorsement(
        rateEditMultipleCoverageEndorsementParam: RateEditMultipleCoverageEndorsementParam,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed> {
        return this.rateMultipleCoverageEndorsement({
            agreement_id: rateEditMultipleCoverageEndorsementParam.agreementId,
            effective_date: rateEditMultipleCoverageEndorsementParam.effectiveDate,
            coverages: rateEditMultipleCoverageEndorsementParam.coverages.map((coverage) => ({
                coverage_type: coverage.coverageTypeCode,
                limit: coverage.limit,
                retention: coverage.retention,
            })),
        });
    }

    private async rateMultipleCoverageEndorsement(
        endorsementRateRequest: APIType.EndorsementRateEspLimitsRequest,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed> {
        const rateResponse = await API.request(
            'endorsement/rate_esp_limits',
            endorsementRateRequest,
        );
        if (isErr(rateResponse)) {
            return handleOperationFailure(rateResponse);
        }
        return ESPEndorsementRate.create({
            annualPremium: rateResponse.value.annual_premium,
            prorate: rateResponse.value.prorate,
            taxes: rateResponse.value.taxes,
            fees: rateResponse.value.fees,
            total: rateResponse.value.total,
        });
    }

    async getServerDate(): AsyncResult<Date, InvalidArgument | OperationFailed> {
        const serverTimeResponse = await API.request('global/get_server_time');
        if (isErr(serverTimeResponse)) {
            return handleOperationFailure(serverTimeResponse);
        }
        const serverDate = startOfDay(serverTimeResponse.value.time);
        if (!isValid(serverDate)) {
            return Failure(
                OperationFailed({
                    message: 'Invalid server time',
                }),
            );
        }
        return Success(serverDate);
    }
}
