import React from 'react';
import { StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import { ComplexFormFieldViewProps } from '../../types/ComplexFieldTypes';

export function StaticFieldView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps } = complexFormFieldViewProps;
    if (questionProps.staticOptions?.statusMessageProps) {
        return <StatusMessage {...questionProps.staticOptions.statusMessageProps} />;
    }
    if (questionProps.staticOptions?.textElementProps) {
        return <Text {...questionProps.staticOptions?.textElementProps} />;
    }
    return null;
}
