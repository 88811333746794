import { ColumnLayout, PageLayout, StackLayout, Text, Icon } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { InformationWithIcon } from './InformationWithIcon.view';

export function SignUpBanner() {
    return (
        <PageLayout.Banner>
            <StackLayout gap="24">
                <Text style="heading 4" textAlign="center">
                    Top rated and trusted by thousands of businesses
                </Text>
                <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
                    <InformationWithIcon
                        title="16k+"
                        description="Policies secured"
                        iconName="policy-secured"
                        iconColor="blue-300"
                    />
                    <InformationWithIcon
                        title="9.5k+"
                        description="Businesses protected"
                        iconName="business-protected"
                        iconColor="blue-300"
                    />
                    <InformationWithIcon
                        title="9 years"
                        description="Serving customers"
                        iconName="serving-customer"
                        iconColor="blue-300"
                    />
                    <InformationWithIcon
                        title={
                            <ColumnLayout center gap="4">
                                <Text style="heading 4">4.5</Text>
                                <Icon name="full-star-rating" size="small" />
                                <Icon name="full-star-rating" size="small" />
                                <Icon name="full-star-rating" size="small" />
                                <Icon name="full-star-rating" size="small" />
                                <Icon name="half-star-rating" size="small" />
                            </ColumnLayout>
                        }
                        description="AdvisorSmith rating"
                        iconName="advisor-rating"
                        iconColor="blue-300"
                    />
                </ColumnLayout>
            </StackLayout>
        </PageLayout.Banner>
    );
}
