import React, { Fragment } from 'react';
import { TechVerticalCoverageType } from './TechVerticalProductSelectionPage';
import { ColumnLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import {
    CoverageRecommendationCard,
    RecommendationType,
} from '../lawBundle/CoverageRecommendationCard.view';
import { ColumnList } from './ColumnList.view';

export interface TechVerticalProductSelectionCardProps {
    isSelected: boolean;
    onSelectionChange: (appType: TechVerticalCoverageType) => void;
    className?: string;
    handleTooltipClick: () => void;
    canSelect?: boolean;
    naicsCode?: string | null;
    appearance?: 'single-column-body' | 'two-column-body';
}

export function TechEOCard({
    isSelected,
    onSelectionChange,
    className,
    handleTooltipClick,
    canSelect,
    appearance = 'single-column-body',
}: TechVerticalProductSelectionCardProps) {
    const lists = (
        <ColumnList
            columnLists={{
                topList: {
                    title: 'Common coverages:',
                    list: [
                        'Errors, omissions, or misstatements',
                        'Negligence',
                        'Computer fraud',
                        'Privacy breaches',
                    ],
                },
                bottomList: {
                    title: 'Why you need this:',
                    list: [
                        'It’s comprehensive — two full policies in one',
                        'Contractual compliance',
                        'It covers independent contractors and any non-U.S. employees',
                    ],
                },
            }}
        />
    );

    return (
        <CoverageRecommendationCard
            showToggle={canSelect}
            className={className}
            recommendationType={RecommendationType.none}
            iconName="chip"
            toggleAppearance="button"
            title="Technology Errors and Omissions / Cyber"
            isSelected={isSelected}
            onCoverageSelectionChange={() => onSelectionChange(TechVerticalCoverageType.techEO)}
        >
            <Fragment>
                <Text style="body 1">
                    Covers liability claims that allege financial losses arising from your tech
                    products or services, as well as costs arising from a security breach
                    (ransomware, system damage, etc.).{' '}
                    <TextButton onClick={handleTooltipClick}>Learn more</TextButton>
                </Text>
                {appearance === 'single-column-body' ? (
                    lists
                ) : (
                    <ColumnLayout gap="24" grow="fixed">
                        {lists}
                    </ColumnLayout>
                )}
            </Fragment>
        </CoverageRecommendationCard>
    );
}

export function DirectorsAndOfficersCard({
    className,
    isSelected,
    canSelect,
    onSelectionChange,
    handleTooltipClick,
    appearance = 'single-column-body',
}: TechVerticalProductSelectionCardProps) {
    const lists = (
        <Fragment>
            <Text style="body 1">
                Protects your company’s decision makers against claims and lawsuits alleging certain
                wrongful acts in managing the business.{' '}
                <TextButton onClick={handleTooltipClick}>Learn more</TextButton>
            </Text>
            <ColumnList
                columnLists={{
                    topList: {
                        title: 'Common coverages:',
                        list: [
                            'Shareholder claims',
                            'Contract disputes',
                            'Regulatory gaps',
                            'Allegations of financial loss',
                        ],
                    },
                    bottomList: {
                        title: 'Why you need this:',
                        list: [
                            'Typically required by executives/investors to protect personal assets',
                            'It shows investors you’re serious and prepared',
                        ],
                    },
                }}
            />
        </Fragment>
    );
    return (
        <CoverageRecommendationCard
            showToggle={canSelect}
            className={className}
            recommendationType={RecommendationType.none}
            iconName="meeting"
            toggleAppearance="button"
            title="Directors and Officers"
            isSelected={isSelected}
            onCoverageSelectionChange={() =>
                onSelectionChange(TechVerticalCoverageType.directorsAndOfficers)
            }
        >
            {appearance === 'single-column-body' ? (
                lists
            ) : (
                <ColumnLayout gap="24" grow="fixed">
                    {lists}
                </ColumnLayout>
            )}
        </CoverageRecommendationCard>
    );
}

export function EPLCard({
    className,
    isSelected,
    canSelect,
    onSelectionChange,
    handleTooltipClick,
    appearance = 'single-column-body',
}: TechVerticalProductSelectionCardProps) {
    const lists = (
        <Fragment>
            <Text style="body 1">
                Provides financial protection from employee lawsuits alleging either unfair or
                inappropriate acts were committed against them by someone who represents your
                company. <TextButton onClick={handleTooltipClick}>Learn more</TextButton>
            </Text>
            <ColumnList
                columnLists={{
                    topList: {
                        title: 'Common coverages:',
                        list: [
                            'Wrongful termination',
                            'Failure to promote',
                            'Harassment and retaliation',
                            'Breach of employment contract',
                        ],
                    },
                    bottomList: {
                        title: 'Why you need this:',
                        list: [
                            'Employee claims are on the rise: EEOC received 81,055 discrimination charges in 2023',
                            'Covers costs associated with reputation repair',
                        ],
                    },
                }}
            />
        </Fragment>
    );
    return (
        <CoverageRecommendationCard
            showToggle={canSelect}
            className={className}
            recommendationType={RecommendationType.none}
            iconName="suitcase"
            toggleAppearance="button"
            title="Employment Practices Liability"
            isSelected={isSelected}
            onCoverageSelectionChange={() =>
                onSelectionChange(TechVerticalCoverageType.employmentPractices)
            }
        >
            {appearance === 'single-column-body' ? (
                lists
            ) : (
                <ColumnLayout gap="24" grow="fixed">
                    {lists}
                </ColumnLayout>
            )}
        </CoverageRecommendationCard>
    );
}

export function FiduciaryCard({
    className,
    isSelected,
    canSelect,
    onSelectionChange,
    handleTooltipClick,
    appearance = 'single-column-body',
}: TechVerticalProductSelectionCardProps) {
    const lists = (
        <Fragment>
            <Text style="body 1">
                Protects your company and the people administering your benefits plans against
                financial losses in lawsuits alleging mismanagement of funds and benefit programs.{' '}
                <TextButton onClick={handleTooltipClick}>Learn more</TextButton>
            </Text>
            <ColumnList
                columnLists={{
                    topList: {
                        title: 'Common coverages:',
                        list: [
                            'Errors or omissions in the administration of ERISA-regulated benefit plans',
                            'Imprudent investment decisions',
                        ],
                    },
                    bottomList: {
                        title: 'Why you need this:',
                        list: [
                            'Your company grants stock options',
                            'You offer an employee benefit plan',
                        ],
                    },
                }}
            />
        </Fragment>
    );

    return (
        <CoverageRecommendationCard
            showToggle={canSelect}
            className={className}
            recommendationType={RecommendationType.none}
            iconName="handshake"
            toggleAppearance="button"
            title="Fiduciary Liability"
            isSelected={isSelected}
            onCoverageSelectionChange={() => onSelectionChange(TechVerticalCoverageType.fiduciary)}
        >
            {appearance === 'single-column-body' ? (
                lists
            ) : (
                <ColumnLayout gap="24" grow="fixed">
                    {lists}
                </ColumnLayout>
            )}
        </CoverageRecommendationCard>
    );
}
