import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { ColumnLayout, GridLayout, Nullable, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Application } from '../../../shopping/entities/Application';
import { Session } from '../../../userOrg/entities/Session';
import { InsuranceApplicationCardList } from './InsuranceApplicationCardList';

interface DashboardSectionProps {
    applicationList: Immutable<Array<EntityProps<Application>>>;
    activeSession: Immutable<EntityProps<Session>>;
    canViewLplQuotes: boolean;
    canViewPCoMLQuotes: boolean;
    canViewCnaBopQuotes: boolean;
    canViewCrimeQuotes: boolean;
    canViewCyberQuotes: boolean;
    canViewExcessQuotes: boolean;
    allApplicationsLink: (items: number) => Nullable<JSX.Element>;
    title: String;
    itemsPerSection: number;
    isRenewal?: boolean;
}

export function DashboardSection({
    applicationList,
    activeSession,
    canViewLplQuotes,
    canViewPCoMLQuotes,
    canViewCnaBopQuotes,
    canViewCrimeQuotes,
    canViewCyberQuotes,
    canViewExcessQuotes,
    allApplicationsLink,
    title,
    itemsPerSection,
    isRenewal = false,
}: DashboardSectionProps) {
    return (
        <GridLayout>
            <ColumnLayout className="u-grid-size-12">
                <Text style="heading 4">{title}</Text>
                {allApplicationsLink(itemsPerSection)}
            </ColumnLayout>
            <InsuranceApplicationCardList
                applicationList={applicationList}
                activeSession={activeSession}
                canViewLplQuotes={canViewLplQuotes}
                canViewPCoMLQuotes={canViewPCoMLQuotes}
                canViewCnaBopQuotes={canViewCnaBopQuotes}
                canViewCrimeQuotes={canViewCrimeQuotes}
                canViewCyberQuotes={canViewCyberQuotes}
                canViewExcessQuotes={canViewExcessQuotes}
                maxApplications={itemsPerSection}
                isRenewal={isRenewal}
            />
        </GridLayout>
    );
}
