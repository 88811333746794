import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Location {
    readonly id: Nullable<UUID>;
    readonly city: Nullable<string>;
    readonly state: Nullable<State>;
    readonly location: Nullable<string>;
    readonly county: Nullable<string>;
    readonly mailing_address: Nullable<string>;
    readonly suite: string;
    readonly zip: Nullable<ZipCode>;
    readonly square_footage: Nullable<Number>;
}

export const Location = {
    ...defineValidator<Location>({
        id: UUID.schema.allow(null).required(),
        city: Joi.string().allow(null).required(),
        state: State.schema.allow(null).required(),
        location: Joi.string().allow(null).required(),
        county: Joi.string().allow(null).required(),
        mailing_address: Joi.string().allow(null).required(),
        suite: Joi.string().allow(''),
        zip: ZipCode.schema.allow(null).required(),
        square_footage: Joi.number().allow(null),
    }),
    create(location: Location) {
        return Location.validate(location);
    },
};

export interface Locations {
    readonly location: Location[];
}

export const Locations = {
    ...defineValidator<Locations>({
        location: Joi.array().items(Location.schema),
    }),
};
