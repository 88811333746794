import React, { SyntheticEvent } from 'react';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Immutable } from '@embroker/shotwell/core/types';
import { Button } from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { DisplayPolicy } from '../../../types/DisplayPolicy';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishPolicyCardClickedEvent } from '@app/policy/useCases/PublishPolicyCardClickedEvent';

interface PayPolicyButtonProps {
    policy: Immutable<DisplayPolicy>;
}

export const PayPolicyButton = ({ policy }: PayPolicyButtonProps) => {
    const navigation = useNavigation();

    async function handleOnClick(event: SyntheticEvent) {
        event.stopPropagation();
        await execute(PublishPolicyCardClickedEvent, {
            eventName: 'CardPayNowClicked',
            policyData: policy,
        });

        navigation.navigate(URI.build('/payments'));
    }

    return (
        <Button appearance="secondary" onClick={handleOnClick}>
            Pay Now
        </Button>
    );
};
