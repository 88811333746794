import { RiskProfile } from '@app/public/types/RiskProfile';
import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileIneligibleModalShow } from '@app/public/entities/events';

export interface PublishRiskProfileIneligibilityModalShowEvent extends UseCase {
    execute(request: RiskProfile): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileIneligibilityModalShowEventUseCase
    extends UseCase
    implements PublishRiskProfileIneligibilityModalShowEvent
{
    public static type = Symbol('RiskProfile/PublishRiskProfileIneligibilityModalShowEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(
        request: RiskProfile,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const ineligibilityModalShowEvent: RiskProfileIneligibleModalShow = {
            origin: 'RiskProfile',
            name: 'IneligibleKickoutModalShow',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            ...request,
        };

        await this.eventBus.publish(ineligibilityModalShowEvent);

        return Success();
    }
}

export const PublishRiskProfileIneligibilityModalShowEvent: UseCaseClass<PublishRiskProfileIneligibilityModalShowEvent> =
    PublishRiskProfileIneligibilityModalShowEventUseCase;
