import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, isErr, Failure, AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    APIQuestionerRepository,
    GetQuestionerFormRepositoryResponse,
} from '../repositories/APIQuestionerRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToGetQuestionerForm } from '../errors';
import { QuestionerPageDefinition } from '../types/QuestionerPageDefinition';
import { FormQuestionDefinition } from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import {
    getOrderedQuestions,
    QuestionerQuestion,
    StaticQuestionConfigs,
} from '../types/QuestionerQuestionType';
import { Section } from '../types/QuestionerSectionType';
import { deepClone } from '@embroker/shotwell/core/object';
import { GetApplicationLastPage } from '@app/shopping/useCases/GetApplicationLastPage';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    GetStaticConditionalActions,
    GetStaticConditionalActionsResponse,
} from './GetStaticConditionalActions';

export type GetQuestionerFormRequest = {
    questionnaireIds: string[];
    initialPage?: string;
};

export interface GetQuestionerFormResponse {
    questionerPageDefinitions?: QuestionerPageDefinition[];
    formQuestionDefinitions: FormQuestionDefinition[];
    questionerQuestions: QuestionerQuestion[];
    requestQuestionnaireIds: string[];
}
export interface GetQuestionerForm extends UseCase {
    execute(
        request: GetQuestionerFormRequest,
    ): AsyncResult<
        GetQuestionerFormResponse,
        Unauthenticated | FailedToGetQuestionerForm | InvalidArgument | OperationFailed
    >;
}

class GetQuestionerFormUseCase extends UseCase implements GetQuestionerForm {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/GetQuestionerFormUseCase');
    /**
     * Constructor for GetQuestionerFormUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user Questioner
     */
    constructor(
        @inject(APIQuestionerRepository) private questionerRepository: APIQuestionerRepository,
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(GetApplicationLastPage.type) private getApplicationLastPage: GetApplicationLastPage,
        @inject(GetStaticConditionalActions.type)
        private getStaticConditionalActions: GetStaticConditionalActions,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetQuestionerFormRequest,
    ): AsyncResult<
        GetQuestionerFormResponse,
        Unauthenticated | FailedToGetQuestionerForm | InvalidArgument | OperationFailed
    > {
        const { questionnaireIds, initialPage } = request;

        const activeSession = await this.sessionRepository.getActiveSession();

        if (!isAuthenticated(activeSession.value)) {
            return Failure(Unauthenticated());
        }

        const getQuestionerFormResp = await this.questionerRepository.getQuestionerForm({
            questionnaireIds,
            includeAnswers: true,
        });

        if (isErr(getQuestionerFormResp)) {
            return getQuestionerFormResp;
        }

        const { sections, questions, externalQuestions } =
            getQuestionerFormResp.value as GetQuestionerFormRepositoryResponse;

        const questionerPageDefinitions = Section.getPageDefinitionsForQuestionnaire(
            questions,
            sections,
            initialPage,
        );

        const staticConditionalActionsResp = await this.getStaticConditionalActions.execute();

        if (isErr(staticConditionalActionsResp)) {
            return staticConditionalActionsResp;
        }

        const { staticConditionalActions } =
            staticConditionalActionsResp.value as GetStaticConditionalActionsResponse;

        const staticQuestionConfigs: StaticQuestionConfigs = {
            staticConditionalActions,
        };

        const fieldSets = Section.getFieldSetDefinitions(questions, sections);
        const formQuestionDefinitions = QuestionerQuestion.getDataDrivenFormQuestions(
            questions,
            staticQuestionConfigs,
        );
        const externalQuestionsDefinitions = QuestionerQuestion.getDataDrivenFormQuestions(
            externalQuestions,
            staticQuestionConfigs,
        );

        const formElements = [
            ...fieldSets,
            ...formQuestionDefinitions,
            ...externalQuestionsDefinitions,
        ];

        const orderedElements = getOrderedQuestions(deepClone(formElements));

        return Success<GetQuestionerFormResponse>({
            formQuestionDefinitions: orderedElements,
            questionerQuestions: questions,
            questionerPageDefinitions,
            requestQuestionnaireIds: questionnaireIds,
        });
    }
}

export const GetQuestionerForm: UseCaseClass<GetQuestionerForm> = GetQuestionerFormUseCase;
