import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable, StatusMessage } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { espCoverageDetails, minimumPremiumAmount } from '../../../espUtils/quoteDocumentUtils';
import { ESPQuote } from '../../entities/ESPQuote';
import { ESPCoverageRateItem } from '../../types/ESPRate';

export interface ESPQuoteBreakdownProps {
    quote: ESPQuote;
    activePageName: string;
}

const isKentuckyState = (quote: ESPQuote): boolean => {
    return quote.quoteInfo?.userInfo.usaState == 'KY';
};

const isMississippiState = (quote: ESPQuote): boolean => {
    return quote.quoteInfo?.userInfo.usaState == 'MS';
};

const buildTitle = (coverage: ESPCoverageRateItem) => {
    if (coverage.level == 'plus') {
        return espCoverageDetails[coverage.coverageType].title + ' Plus';
    }
    return espCoverageDetails[coverage.coverageType].title;
};

const buildWarningMessage = (quote: ESPQuote, activePage: string) => {
    let warningText = ``;
    if (quote.quoteInfo?.isReferredBecauseExistingCoverage && activePage === 'signature') {
        warningText = `Thank you for your submission. We’re reviewing the uploaded loss runs to ensure the client meets Embroker’s underwriting criteria.`;
    }
    return warningText && <StatusMessage status="warning">{warningText}</StatusMessage>;
};

const displayReferredWarningMessage = () => {
    return (
        <StatusMessage status="warning">
            Your quote requires underwriting review. Please select your requested coverage, limits,
            and retentions. If applicable, you will be asked a few additional questions before being
            submitted to underwriting. We will contact you as soon as possible.
        </StatusMessage>
    );
};

export function ESPQuoteBreakdown({ quote, activePageName }: ESPQuoteBreakdownProps) {
    const selectedCoverages = quote.details.coverages.filter((coverage) => coverage.selected);
    const showMinimumPremium =
        Money.isLessThan(quote.details.rates.totalCoverages, minimumPremiumAmount) &&
        Money.isGreaterThan(quote.totalPayable, minimumPremiumAmount);
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const isKentucky = isKentuckyState(quote);
    const isMississippi = isMississippiState(quote);
    const sociusEndorsementPremium = quote.quoteInfo?.sociusEndorsementPremium || USD(0);
    const sociusEndorsementPremiumVisible = sociusEndorsementPremium.amount > 0;
    const showAnnualTechFee = (quote.annualTechnologyFee?.amount ?? 0) > 0;
    const isQuoteReferred = quote?.status === 'referred';
    const isPremiumVisible = quote && !isQuoteReferred;

    if (isQuoteReferred) {
        return displayReferredWarningMessage();
    }
    return (
        <InvoiceTable>
            {buildWarningMessage(quote, activePageName)}
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                {selectedCoverages.map((coverage) => {
                    return (
                        <InvoiceTable.Item key={coverage.coverageType} title={buildTitle(coverage)}>
                            {isPremiumVisible && <MoneyDisplay value={coverage.premium} />}
                        </InvoiceTable.Item>
                    );
                })}
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Total coverages">
                    {isPremiumVisible && (
                        <MoneyDisplay value={quote.details.rates.totalCoverages} />
                    )}
                </InvoiceTable.Subtotal>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Subtotal">
                    {isPremiumVisible && <MoneyDisplay value={quote.details.rates.subTotal} />}
                </InvoiceTable.Subtotal>
                {showMinimumPremium && (
                    <InvoiceTable.Item
                        title="Minimum Premium"
                        tooltip="The minimum we can charge for a policy premium for Embroker’s Startup Package is $3,500. Your policy does not meet the minimum premium, so we have to adjust the cost of your policy accordingly."
                    >
                        {isPremiumVisible && <MoneyDisplay value={minimumPremiumAmount} />}
                    </InvoiceTable.Item>
                )}
                {isMississippi && (
                    <InvoiceTable.Item
                        title="MWUA Fee"
                        tooltip="Mississippi Windstorm Underwriting Agency State Policy Fee - a 3% fee on gross premiums plus any policy fee charged for surplus
						lines policies written in Mississippi. "
                    >
                        {isPremiumVisible && (
                            <MoneyDisplay value={quote.details.rates.mwuaFee ?? USD(0)} />
                        )}
                    </InvoiceTable.Item>
                )}
                <InvoiceTable.Item
                    title={isKentucky ? 'Surplus Lines Tax' : 'Taxes'}
                    tooltip={
                        !isKentucky &&
                        'Surplus Line Tax is required on all surplus line insurance transactions and vary by state.'
                    }
                >
                    {isPremiumVisible && <MoneyDisplay value={quote.details.rates.taxes} />}
                </InvoiceTable.Item>
                <InvoiceTable.Item
                    title={
                        isKentucky
                            ? 'Stamping Fee'
                            : 'Fees' +
                              ' (excluding transaction fees based on your choice of payment)'
                    }
                    tooltip={
                        !isKentucky &&
                        'Often referred to as “Stamping Fees”, Surplus Lines Fees are only required by certain states. In the applicable states Surplus Line Fees are charged on all insurance transactions.'
                    }
                >
                    {isPremiumVisible && (
                        <MoneyDisplay value={quote.details.rates.feesAndSurcharges} />
                    )}
                </InvoiceTable.Item>
                {isKentucky && (
                    <InvoiceTable.Item title="Kentucky-Specific taxes and fees">
                        See note
                    </InvoiceTable.Item>
                )}
                {isBroker && (
                    <InvoiceTable.Item title="Embroker Access Fee" data-e2e="access-fee">
                        {isPremiumVisible && <MoneyDisplay value={quote.details.rates.policyFee} />}
                    </InvoiceTable.Item>
                )}
                {showAnnualTechFee && (
                    <InvoiceTable.Item
                        title="Annual technology fee"
                        tooltip="The Annual technology fee is applied once a year with the first policy purchase of the year."
                    >
                        {isPremiumVisible && (
                            <MoneyDisplay value={quote.annualTechnologyFee ?? USD(0)} />
                        )}
                    </InvoiceTable.Item>
                )}
                <InvoiceTable.Total
                    data-e2e="total-amount"
                    title={isKentucky ? 'Estimated Total: ' : 'Total: '}
                >
                    {isKentucky
                        ? 'Forthcoming'
                        : isPremiumVisible && <MoneyDisplay value={quote.totalPayable} />}
                </InvoiceTable.Total>
            </InvoiceTable.Section>
            {sociusEndorsementPremiumVisible ? (
                <InvoiceTable.Section>
                    <StatusMessage status="helptext">
                        <React.Fragment>
                            Additional premium of{' '}
                            {isPremiumVisible && (
                                <MoneyDisplay value={sociusEndorsementPremium} fractionDigits={0} />
                            )}{' '}
                            applied for inclusion of the Socius Amendatory Endorsement.
                        </React.Fragment>
                    </StatusMessage>
                </InvoiceTable.Section>
            ) : undefined}
            {isKentucky && (
                <StatusMessage status="helptext">
                    Various municipalities (towns, counties, etc.) in the state of Kentucky are
                    permitted to charge taxes on insurance premium per state law. These tax amounts
                    are typically between 2-16% of premium and are in addition to any other
                    applicable taxes, fees or surcharges. Your policy and invoice will have these
                    taxes included.
                </StatusMessage>
            )}
        </InvoiceTable>
    );
}
