import React from 'react';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Applicant } from '../../../entities/Applicant';
import { Coverage } from '../../../types/Coverage';
import { AppTypeCode } from '../../../types/enums';
import { CoverageCard } from '../CoverageCard.view';

interface ShoppingCoverageListProps {
    allCoverages: Coverage[];
    applicant: EntityProps<Applicant>;
    selectedCoverages?: Coverage[];
    handleAddAndRemoveCoverage: (appType: AppTypeCode, isSelected: boolean) => void;
    handleShowDetails: (appType: AppTypeCode, isSelected?: boolean) => void;
}

export function ShoppingCoverageList({
    allCoverages,
    applicant,
    selectedCoverages,
    handleAddAndRemoveCoverage,
    handleShowDetails,
}: ShoppingCoverageListProps) {
    const checkIsCoverageSelected = (appType: AppTypeCode) => {
        if (!selectedCoverages) {
            return;
        }
        return selectedCoverages.some(
            (element) => element.appType === appType && element.isSelected,
        );
    };

    return (
        <React.Fragment>
            {allCoverages.map((coverage: Coverage) => {
                return (
                    <CoverageCard
                        key={`all${coverage.appType}`}
                        appType={coverage.appType}
                        naicsCode={applicant?.naicsIndustry}
                        isSelected={checkIsCoverageSelected(coverage.appType)}
                        handleAddAndRemoveCoverage={handleAddAndRemoveCoverage}
                        handleShowDetails={handleShowDetails}
                    />
                );
            })}
        </React.Fragment>
    );
}
