import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Nullable, Spinner, StatusMessage, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { QuotingEngine, QuotingEngineExcess } from '../../../../shopping/types/enums';
import { IneligibilityReasons } from '../../../../shopping/types/IneligibilityReasons';
import { ViewQuestionnaireModal } from '../../../../shopping/view/components/ViewQuestionnaireModal';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { BrokerRenewalStatus } from '../../../types/BrokerRenewalRecord';
import {
    isLPLIndication,
    isUnderLossRunsReview,
    resolveBrokerViewQuotesLink,
} from '../BrokerQuoteActionButton';
import { RenewNowModal } from '../modals/RenewNowModal';
import { GetGlobalConfig } from '@app/config/useCases/GetGlobalConfigUseCase';
import { container } from '@embroker/shotwell/core/di';
import { Logger, Log } from '@embroker/shotwell/core/logging/Logger';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

export interface RenewalsTableActionButtonProps {
    applicationId: UUID;
    organizationId: UUID;
    status: BrokerRenewalStatus;
    hasQuotes: boolean;
    quotingEngine?: QuotingEngine;
    ineligibilityReasons?: Immutable<IneligibilityReasons>;
    isSubmittedExternally?: boolean;
    isStreamline?: boolean;
}

export function RenewalsTableActionButton({
    applicationId,
    organizationId,
    status,
    hasQuotes,
    quotingEngine,
    ineligibilityReasons,
    isSubmittedExternally,
    isStreamline,
}: RenewalsTableActionButtonProps): Nullable<JSX.Element> {
    const viewQuestionnaireModal = useModal();
    const renewNowModal = useModal();
    const { navigate } = useNavigation();

    const { result: globalConfigResult, isLoading: isLoadingGlobalConfig } =
        useUseCase(GetGlobalConfig);

    if (isLoadingGlobalConfig || globalConfigResult === undefined) {
        return <Spinner />;
    }

    if (globalConfigResult && isErr(globalConfigResult)) {
        const errorId = container.get<Logger>(Log).whoops(globalConfigResult?.errors);
        const whoopsErrorMessage = errorId ? `Whoops error id: ${errorId}` : '';

        return (
            <StatusMessage status="error">{`We failed to load the global configuration. Try again or report an error! ${whoopsErrorMessage}`}</StatusMessage>
        );
    }

    const handleRenewPolicy = async () => {
        if (isStreamline) await handleSubmitStreamlineApp();
        else await handleStartContinue();
    };

    const handleSubmitStreamlineApp = async () => {
        await execute(SelectOrganization, { organizationId });

        if (globalConfigResult.value.config.streamlineRenewalsEnhanced) {
            navigate(
                URI.build('/shopping/review-renewal-data', {
                    applicationId: applicationId,
                }),
            );
        } else {
            navigate(
                URI.build('/shopping/submit-streamline-renewal', {
                    applicationId: applicationId,
                }),
            );
        }
    };

    const handleStartContinue = async () => {
        if (isSubmittedExternally || (quotingEngine && quotingEngine === QuotingEngineExcess)) {
            await execute(SelectOrganization, { organizationId });
            navigate(
                URI.build('/shopping/application/basic-info', {
                    applicationId,
                }),
            );
        } else {
            renewNowModal.show();
        }
    };

    const handleViewQuotes = async () => {
        if (hasQuotes) {
            const link = resolveBrokerViewQuotesLink({
                applicationId,
                quotingEngine,
                isRenewal: true,
            });
            if (link) {
                await execute(SelectOrganization, { organizationId });
                return navigate(link);
            }
            return navigateToErrorPage(navigate, [
                OperationFailed({
                    message: 'Failed to resolve view quotes link',
                }),
            ]);
        }
    };
    const handleViewEstimate = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application/quote/lpl/referred-estimate', {
                applicationId,
            }),
        );
    };
    const handleViewQuestionnaire = async () => {
        await execute(SelectOrganization, { organizationId });
        viewQuestionnaireModal.show();
    };

    switch (status) {
        case 'declined':
            return (
                <React.Fragment>
                    <TextButton onClick={handleViewQuestionnaire}>View questionnaire</TextButton>
                    <ViewQuestionnaireModal
                        applicationId={applicationId}
                        modal={viewQuestionnaireModal}
                    />
                </React.Fragment>
            );
        case 'not_submitted':
            return (
                <React.Fragment>
                    <TextButton onClick={handleRenewPolicy}>Renew policy</TextButton>
                    <RenewNowModal
                        modal={renewNowModal}
                        applicationId={applicationId}
                        organizationId={organizationId}
                    />
                </React.Fragment>
            );
        case 'quotes_ready':
            return <TextButton onClick={handleViewQuotes}>View Quotes</TextButton>;
        case 'referred':
            // there is an action button only if it is:
            // 1) ESP/PCoML loss runs review
            // 2) LPL indication
            if (isUnderLossRunsReview(quotingEngine, ineligibilityReasons)) {
                return <TextButton onClick={handleViewQuotes}>View Quotes</TextButton>;
            }
            if (isLPLIndication(quotingEngine, ineligibilityReasons)) {
                return <TextButton onClick={handleViewEstimate}>View Estimate</TextButton>;
            }
            return null;
    }
    return null;
}
