import { WorkspaceOwnership } from '@app/userOrg/types/enums';
import type * as APITypes from '@embroker/shotwell-api/app';
import { API, isIdFromAPI } from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotImplemented,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { findDomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { RevenueList } from '@embroker/shotwell/core/types/RevenueList';
import { State } from '@embroker/shotwell/core/types/StateList';
import { equalUUID, UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { Location } from '../../../locations/entities/Location';
import { Organization } from '../../entities/Organization';
import { OperationNotAllowedError, OrganizationNotFoundError, Unauthenticated } from '../../errors';
import { MailingAddress } from '../../types/MailingAddress';
import { UserRole, UserRoleType } from '../../types/UserRole';
import { SessionRepository } from '../SessionRepository';
import { OrganizationRepository } from './index';

interface Invitation {
    token: UUID;
    accepted: boolean;
}

interface OrganizationData {
    organization: Organization;
    invitation: Nullable<Invitation>;
}

interface GetDnBNAICsCodeResponse {
    industry_code?: string;
}

interface OrganizationParseResponse {
    organizationEntity: Organization;
    organizationResponse: APITypes.Organization;
}

interface SignUpMailingAddressAPIType {
    address_lines?: string;
    city?: string;
    state?: string;
    zip_code?: string;
    county?: string;
}

@injectable()
export class APIOrganizationRepository implements OrganizationRepository {
    private organizations: Map<UUID, OrganizationData>;
    private serverOrganizations: Map<UUID, APITypes.Organization>;

    constructor(
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(Log) private logger: Logger,
    ) {
        this.organizations = new Map();
        this.serverOrganizations = new Map();
    }

    async getDnBNAICsCode(organizationId: UUID): AsyncResult<string | undefined, never> {
        const naicsCodeResult = await API.request('organization/industry_code_lookup', {
            organization_id: organizationId,
        });

        if (isErr(naicsCodeResult)) {
            return Success('');
        }

        const response: GetDnBNAICsCodeResponse = naicsCodeResult.value;

        return Success(response.industry_code);
    }

    async getOrganization(
        organizationId: UUID,
    ): AsyncResult<Organization, InvalidArgument | OperationFailed | UnknownEntity> {
        const organizationResult = await API.request('user/organization', {
            organization_id: organizationId,
        });
        if (isErr(organizationResult)) {
            const error = organizationResult.errors[0];
            if (isAPIError(error) && error.details.name === 'not_found') {
                return Failure(UnknownEntity('Organization', organizationId));
            }
            return Failure(OperationFailed({ errors: organizationResult.errors }));
        }

        const organization = organizationResult.value as APITypes.Organization;

        // Cast organization to entity
        const organizationEntityResult = await this.toOrganization(organization);
        if (isErr(organizationEntityResult)) {
            return organizationEntityResult;
        }

        const organizationEntity = organizationEntityResult.value as OrganizationData;

        this.storeOrganizationEntity(organizationEntity.organization);
        this.serverOrganizations.set(organizationEntity.organization.id, organization);

        return Success(organizationEntity.organization);
    }

    async getOrganizationsForUser(
        userId: UUID,
        userRoles?: UserRoleType[],
    ): AsyncResult<Organization[], InvalidArgument | OperationFailed | Unauthenticated> {
        const activeSessionResult = await this.sessionRepository.getActiveSession();

        if (
            isErr(activeSessionResult) ||
            activeSessionResult.value === null ||
            activeSessionResult.value.userId === null
        ) {
            return Failure(Unauthenticated());
        }

        if (!equalUUID(userId, activeSessionResult.value.userId)) {
            return Failure(OperationFailed({ message: 'Authenticated user id mismatch.' }));
        }

        const result = await API.request('user/get');

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        // cast organizations to entities
        const organizationEntitiesResult = await this.toOrganizations(
            result.value.organizations as APITypes.OrganizationList,
        );

        if (isErr(organizationEntitiesResult)) {
            return organizationEntitiesResult;
        }

        let organizations = organizationEntitiesResult.value.map(
            (item) => item.organizationEntity as Organization,
        );
        if (userRoles === undefined) {
            return Success(organizations);
        }

        organizations = organizations.filter((org: Organization) => {
            return org.userRoleList.some((role: UserRole) => {
                return userRoles.includes(role.role);
            });
        });

        return Success(organizations);
    }

    async save(
        organization: Organization,
    ): AsyncResult<Organization, InvalidArgument | OperationFailed | UnknownEntity> {
        // cache existing organization
        if (organization.events.length === 0) {
            this.organizations.set(organization.id, {
                organization: organization,
                invitation: null,
            });
            return Success(organization);
        }
        const organizationCreatedEvent = findDomainEvent(
            organization.events,
            'Organization',
            'Created',
        );
        // create new organization
        if (organizationCreatedEvent !== undefined) {
            const addNewOrganizationResult = await this.addNewOrganization(organization);

            if (isErr(addNewOrganizationResult)) {
                return addNewOrganizationResult;
            }

            return Success(addNewOrganizationResult.value);
        }
        // update existing organization
        const updateExistingOrganizationResult = await this.updateExistingOrganization(
            organization,
        );
        if (isErr(updateExistingOrganizationResult)) {
            return updateExistingOrganizationResult;
        }

        return Success(updateExistingOrganizationResult.value);
    }

    async getOrganizationByInviteToken(
        inviteToken: UUID,
    ): AsyncResult<Organization, UnknownEntity | NotImplemented> {
        return Failure(NotImplemented());
    }

    async acceptInvite(inviteToken: UUID): AsyncResult<void, InvalidArgument | NotImplemented> {
        return Failure(NotImplemented());
    }

    async getClientOrganization(
        token: string,
    ): AsyncResult<
        Organization,
        OrganizationNotFoundError | OperationNotAllowedError | OperationFailed
    > {
        const organizationResult = await API.request('shopping/get_client_organization', {
            token,
        });
        if (isErr(organizationResult)) {
            const error = organizationResult.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(OrganizationNotFoundError());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(OperationNotAllowedError());
                }
            }
            return Failure(OperationFailed({ errors: organizationResult.errors }));
        }

        const organization = organizationResult.value as APITypes.Organization;

        const organizationDataResult = await this.toOrganization(organization);
        if (isErr(organizationDataResult)) {
            return Failure(OperationFailed({ errors: organizationDataResult.errors }));
        }

        const organizationData = organizationDataResult.value as OrganizationData;

        this.storeOrganizationEntity(organizationData.organization);
        this.serverOrganizations.set(organizationData.organization.id, organization);

        return Success(organizationData.organization);
    }

    async updateClientOrganization(
        token: string,
        organization: Organization,
    ): AsyncResult<
        void,
        InvalidArgument | OrganizationNotFoundError | OperationNotAllowedError | OperationFailed
    > {
        const storedOrganization = this.serverOrganizations.get(organization.id);
        if (!storedOrganization) {
            return Failure(InvalidArgument({ argument: 'organization', value: organization.id }));
        }

        const updatedOrganization: APITypes.Organization = {
            ...storedOrganization,
            name: organization.companyLegalName,
            email: organization.email,
            employee_number: organization.totalNumberOfEmployees,
            revenue_list: (organization.revenues || []).map((revenue) => {
                return {
                    fiscal_year: revenue.fiscalYear.toString(),
                    gross_revenue_total: revenue.grossTotal,
                };
            }),
            naics_code: organization.naics,
            total_payroll_of_employees: organization.totalAnnualPayroll,
            year_started: organization.yearStarted !== undefined ? organization.yearStarted : null,
            provided_service: organization.howDoesYourCompanyGenerateRevenue,
            headquarters: APIOrganizationRepository.marshalHeadquarterLocation(
                organization.headquarters,
            ),
            location_list:
                APIOrganizationRepository.toApiLocations(organization.otherLocations) ?? [],
            website: organization.website,
            raised_funding: organization.raisedFunding,
            tech_area_of_focus: organization.techAreaOfFocus,
            higher_limits_approved: organization.higherLimitsApproved,
        };

        const organizationUpdateResult = await API.request('shopping/update_client_organization', {
            token,
            organization: updatedOrganization,
        });
        if (isErr(organizationUpdateResult)) {
            const error = organizationUpdateResult.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(OrganizationNotFoundError());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(OperationNotAllowedError());
                }
            }

            return handleOperationFailure(organizationUpdateResult);
        }

        return Success();
    }

    private async updateExistingOrganization(
        organization: Organization,
    ): AsyncResult<Organization, OperationFailed | InvalidArgument | UnknownEntity> {
        const organizationFromServer = this.serverOrganizations.get(organization.id);
        if (!organizationFromServer) {
            return Failure(UnknownEntity('Organization', organization.id));
        }
        const nextYear = new Date(Date.now()).getFullYear() + 1;
        const requestData: APITypes.UserOrganizationUpdateRequest = {
            ...organizationFromServer,
            id: organization.id,
            name: organization.companyLegalName,
            email: organization.email,
            employee_number: organization.totalNumberOfEmployees,
            revenue_list: (organization.revenues || []).map((revenue) => {
                return {
                    fiscal_year: revenue.fiscalYear.toString(),
                    gross_revenue_total: revenue.grossTotal,
                };
            }),
            naics_code: organization.naics,
            entity_type_id: organization.entityType,
            total_payroll_of_employees: organization.totalAnnualPayroll,
            year_started:
                organization.yearStarted !== null &&
                organization.yearStarted >= 1000 &&
                organization.yearStarted < nextYear
                    ? organization.yearStarted
                    : null,
            provided_service: organization.howDoesYourCompanyGenerateRevenue,
            headquarters: APIOrganizationRepository.marshalHeadquarterLocation(
                organization.headquarters,
            ),
            location_list:
                APIOrganizationRepository.toApiLocations(organization.otherLocations) ?? [],
            website: organization.website,
            raised_funding: organization.raisedFunding,
            tech_area_of_focus: organization.techAreaOfFocus,
            higher_limits_approved: organization.higherLimitsApproved,
            phone_number: organization.phoneNumber,
            workspace_ownership: organization.workspaceOwnership,
            has_automobiles: organization.hasAutomobiles ?? null,
            has_employees: organization.hasEmployees,
            provides_tech_service_for_fee: organization.providesTechServiceForFee,
        };

        const organizationUpdateResult = await API.request('user/organization_update', requestData);
        if (isErr(organizationUpdateResult)) {
            return handleOperationFailure(organizationUpdateResult);
        }

        const updatedOrganizationResult = await this.getOrganization(organization.id);
        if (isErr(updatedOrganizationResult)) {
            return updatedOrganizationResult;
        }

        const updatedOrganization = updatedOrganizationResult.value as Organization;
        updatedOrganization.retainEventsOf(organization);

        this.storeOrganizationEntity(updatedOrganization);
        return Success(updatedOrganization);
    }

    private async addNewOrganization(
        organization: Organization,
    ): AsyncResult<Organization, InvalidArgument | OperationFailed> {
        const result = await API.request('user/add_organization', {
            name: organization.companyLegalName,
            raised_funding: organization.raisedFunding,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const orgEntityResult = await this.toOrganization(
            result.value.organization as APITypes.Organization,
        );

        if (isErr(orgEntityResult)) {
            return handleOperationFailure(orgEntityResult);
        }

        this.storeOrganizationEntity(orgEntityResult.value.organization as Organization);
        return Success(orgEntityResult.value.organization);
    }

    // We need two differnt headquarter marshaling functions due to differences in how the
    // 'user/sign_up' & 'user/organization_update' expect the address data to be formatted.
    //
    // Endpoint `user/organization_update` uses APITypes.MailingAddress for headquarters
    // this requires string or null for all address attributes
    //
    // Endpoint `user/sign_up` has request body of type APITypes.UserSignUpRequest for headquarters
    // this requires string or undefined for all address attributes
    static marshalHeadquarterLocationForSignup(
        location: MailingAddress,
    ): SignUpMailingAddressAPIType {
        const addressLines = APIOrganizationRepository.toApiAddress(
            location.addressLine1,
            location.addressLine2,
        );

        const { county, city, state, zip } = location;

        return {
            address_lines: addressLines ? addressLines : undefined,
            city: city ? city : undefined,
            state: state ? state : undefined,
            zip_code: zip ? zip : undefined,
            county: county ? county : undefined,
        };
    }
    static marshalHeadquarterLocation(location: MailingAddress): APITypes.MailingAddress {
        const addressLines = APIOrganizationRepository.toApiAddress(
            location.addressLine1,
            location.addressLine2,
        );

        const { county = null } = location;

        return {
            address_lines: addressLines,
            city: location.city,
            state: location.state,
            zip_code: location.zip,
            county,
        };
    }

    private async toOrganization(
        organizationResponse: APITypes.Organization,
    ): AsyncResult<OrganizationData, InvalidArgument | OperationFailed> {
        const revenueResult = await this.toRevenues(organizationResponse.revenue_list);
        if (isErr(revenueResult)) {
            return revenueResult;
        }

        const locationsResult = await APIOrganizationRepository.toLocations(organizationResponse);
        if (isErr(locationsResult)) {
            return locationsResult;
        }

        const headquartersResult = APIOrganizationRepository.toMailingAddress(
            organizationResponse.headquarters,
        );
        if (isErr(headquartersResult)) {
            return headquartersResult;
        }
        const result = await Organization.create({
            id: organizationResponse.id as UUID,
            website: organizationResponse.website || null,
            email: organizationResponse.email as EmailAddress,
            companyLegalName: organizationResponse.name,
            naics: organizationResponse.naics_code || null,
            entityType: organizationResponse.entity_type_id,
            yearStarted: Year.check(organizationResponse.year_started)
                ? organizationResponse.year_started
                : null,
            revenues: revenueResult.value as Nullable<Revenue[]>,
            headquarters: headquartersResult.value,
            otherLocations: locationsResult.value as Location[],
            howDoesYourCompanyGenerateRevenue: organizationResponse.provided_service || null,
            totalNumberOfEmployees: organizationResponse.employee_number,
            totalAnnualPayroll: organizationResponse.total_payroll_of_employees,
            userRoleList: [],
            isTestOrganization: organizationResponse.is_test_organization,
            raisedFunding: organizationResponse.raised_funding,
            techAreaOfFocus: organizationResponse.tech_area_of_focus,
            higherLimitsApproved: organizationResponse.higher_limits_approved || false,
            forceNotEligible: organizationResponse.force_not_eligible,
            phoneNumber: organizationResponse.phone_number,
            workspaceOwnership: this.toWorkspaceOwnership(organizationResponse.workspace_ownership),
            hasAutomobiles: organizationResponse.has_automobiles ?? undefined,
            hasEmployees: organizationResponse.has_employees,
            providesTechServiceForFee: organizationResponse.provides_tech_service_for_fee,
        });

        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        return Success({
            organization: result.value,
            invitation: null,
        } as OrganizationData);
    }

    private async toRevenues(
        revenueList: Nullable<APITypes.RevenueList>,
    ): AsyncResult<Nullable<Revenue[]>, InvalidArgument> {
        const revenues: Revenue[] = [];
        if (revenueList !== null && revenueList.length > 0) {
            for (const revenue of revenueList) {
                const parsedYear = Number.parseInt(revenue.fiscal_year);

                if (isNaN(parsedYear)) {
                    if (process.env.NODE_ENV !== 'production') {
                        this.logger.warn('Invalid year value detected', revenue.fiscal_year);
                    }
                    continue;
                }

                revenues.push({
                    fiscalYear: cast<Year>(parsedYear),
                    grossTotal: revenue.gross_revenue_total,
                });
            }
        }

        const revenueListResult = RevenueList.create(revenues as any);
        if (isErr(revenueListResult)) {
            return revenueListResult;
        }

        const yearsOfInterest = this.getYearsOfInterest();
        return Success(RevenueList.addYears(revenueListResult.value, yearsOfInterest));
    }

    private toWorkspaceOwnership(input: string | undefined): WorkspaceOwnership | undefined {
        if (!input) {
            return undefined;
        }

        if (['home_office', 'office_rented', 'office_owned', 'coworking'].includes(input)) {
            return input as WorkspaceOwnership;
        }

        return undefined;
    }

    private getYearsOfInterest(): Year[] {
        const DECEMBER = 11;
        const currentTime = new Date(Date.now());
        const currentYear = currentTime.getFullYear();
        const currentMonth = currentTime.getMonth();

        if (currentMonth === DECEMBER) {
            return [cast<Year>(currentYear), cast<Year>(currentYear + 1)];
        }

        return [cast<Year>(currentYear - 1), cast<Year>(currentYear)];
    }

    private async toOrganizations(
        organizationsResponse: APITypes.OrganizationList,
    ): AsyncResult<OrganizationParseResponse[], InvalidArgument | OperationFailed> {
        const organizations: OrganizationParseResponse[] = [];
        for (const organizationResponseItem of organizationsResponse) {
            const result = await this.toOrganization(organizationResponseItem);
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
            organizations.push({
                organizationEntity: result.value.organization as Organization,
                organizationResponse: organizationResponseItem,
            });
        }
        return Success(organizations);
    }

    private static toMailingAddress(
        location: APITypes.MailingAddress,
    ): Result<MailingAddress, OperationFailed | InvalidArgument> {
        const locationResult = MailingAddress.create({
            addressLine1: APIOrganizationRepository.parseAddressLines(location.address_lines, 1),
            addressLine2: APIOrganizationRepository.parseAddressLines(location.address_lines, 2),
            city: location.city === '' ? null : location.city,
            state: State.check(location.state)
                ? location.state
                : State.getCodeByName(location.state) ?? null,
            zip: ZipCode.check(location.zip_code) ? location.zip_code : null,
            county: location.county === '' ? null : location.county,
        } as any);
        if (isErr(locationResult)) {
            return handleOperationFailure(locationResult);
        }

        return Success(locationResult.value);
    }

    private static async toLocations(
        data: APITypes.Organization,
    ): AsyncResult<Location[], OperationFailed | InvalidArgument> {
        const locations: Location[] = [];

        if (Array.isArray(data.location_list)) {
            for (const address of data.location_list) {
                const locationResult = await Location.create({
                    id: address.id ? address.id : undefined,
                    addressLine1: APIOrganizationRepository.parseAddressLines(
                        address.address_lines,
                        1,
                    ),
                    addressLine2: APIOrganizationRepository.parseAddressLines(
                        address.address_lines,
                        2,
                    ),
                    city: address.city === '' ? null : address.city,
                    state: State.check(address.state)
                        ? address.state
                        : State.getCodeByName(address.state) ?? null,
                    zip: ZipCode.check(address.zip_code) ? address.zip_code : null,
                    valueOfProperty: address.value_of_property,
                    squareFootageOccupied: address.square_footage_occupied,
                    county: address.county === '' ? null : address.county,
                } as any);

                if (isErr(locationResult)) {
                    return handleOperationFailure(locationResult);
                }

                locations.push(locationResult.value);
            }
        }
        return Success(locations);
    }

    /**
     * Marshal location. Location id is not sent because assumption is that all other locations
     * will be overwritten by new ones FIXME TODO: Check this
     *
     * @param locations Entity Locations
     * @return Return null or array of places
     */
    private static toApiLocations(locations: Nullable<Location[]>): Nullable<APITypes.Location[]> {
        if (locations === null) {
            return null;
        }

        const places: APITypes.Location[] = [];
        for (const location of locations) {
            places.push({
                id: isIdFromAPI(location.id) ? location.id : null,
                address_lines: APIOrganizationRepository.toApiAddress(
                    location.addressLine1,
                    location.addressLine2,
                ),
                city: location.city,
                zip_code: location.zip,
                state: location.state,
                square_footage_occupied: location.squareFootageOccupied,
                county: location.county,
                value_of_property: location.valueOfProperty,
            });
        }

        return places;
    }

    private static toApiAddress(
        addressLine1: string | null | undefined,
        addressLine2: string | null | undefined,
    ): string | null {
        let addressLine;
        if (typeof addressLine1 === 'string' && addressLine1.trim().length !== 0) {
            addressLine = addressLine1;
        }
        if (typeof addressLine2 === 'string' && addressLine2.trim().length !== 0) {
            if (addressLine) {
                addressLine += `\n${addressLine2}`;
            } else {
                addressLine = addressLine2;
            }
        }
        if (addressLine === undefined) {
            return null;
        }
        return addressLine;
    }

    private static parseAddressLines(
        addressLines: string | null | undefined,
        index = 1,
    ): Nullable<string> {
        if (typeof addressLines !== 'string') {
            return null;
        }
        const parts = addressLines.split('\n');
        index = index - 1;
        if (parts[index] === undefined || parts[index] === '') {
            return null;
        }
        return parts[index].trim();
    }

    private storeOrganizationEntity(
        organization: Organization,
        invitation: Nullable<Invitation> = null,
    ): void {
        const organizationData = this.organizations.get(organization.id);
        if (organizationData !== undefined && invitation === null) {
            invitation = organizationData.invitation;
        }
        this.organizations.set(organization.id, {
            organization,
            invitation,
        });
    }

    async selectOrganization(payload: {
        organization_id: string;
        user_id: string;
    }): AsyncResult<void, never> {
        const selectOrganizationResponse = await API.request(
            'organization/select_organization',
            payload,
        );

        if (isOK(selectOrganizationResponse)) {
            const { access_token: accessToken, refresh_token: refreshToken } =
                selectOrganizationResponse.value as {
                    access_token: string;
                    refresh_token: string;
                };

            API.setAuthorization({
                accessToken,
                refreshToken,
            });
        }

        return Success();
    }
}
