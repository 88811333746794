import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxCard,
    Button,
    ColumnLayout,
    Icon,
    Image,
    InvoiceTable,
    Label,
    PageLayout,
    SidebarLayout,
    Spinner,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { useLoadingRoute } from 'react-navi';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { Invoice } from '../../../entities/Invoice';
import { isBundlePayment, Payment, BundlePayment } from '../../../types/Payment';
import { SumInvoicesToPay, SumInvoicesToPayRequest } from '../../../useCases/SumInvoicesToPay';
import { BundleModalInvoiceDetail } from '../modals/BundleInvoiceDetailsModal';
import { ModalInvoiceDetail } from '../modals/InvoiceDetailsModal';
import { buildInvoiceIdList } from '../PaymentsDashboard';
import { coverageIconMap } from '../InvoiceListItem.view';

export interface EligibilityPageInput {
    eligibleInvoices: Immutable<Payment[]>;
    notEligibleInvoices: Immutable<Payment[]>;
    selectedInvoiceIdList: string;
}

interface EligibleListItem {
    readonly invoice: Immutable<Payment>;
    showModal(invoice: Immutable<EntityProps<Invoice>>): void;
    showBundleModal(invoice: Immutable<BundlePayment>): void;
}

interface EligibilityBundleInvoiceListItem {
    readonly invoice: Immutable<BundlePayment>;
    showBundleModal(invoice: Immutable<BundlePayment>): void;
}

function EligibilityBundleInvoiceListItem({
    invoice,
    showBundleModal,
}: EligibilityBundleInvoiceListItem) {
    const moneyDisplay = <MoneyDisplay value={invoice.balance} />;
    let iconName = coverageIconMap.get(invoice.lineOfBusiness);
    if (iconName == undefined) {
        iconName = 'product-default';
    }

    const bundlePolicyListData = invoice.invoiceList.map((invoice) => {
        return (
            <ColumnLayout key={invoice.id}>
                <Text style="body 2" color="ui-500">
                    {invoice.lineOfBusiness} • Invoice no. {invoice.invoiceNumber}
                </Text>
            </ColumnLayout>
        );
    });

    return (
        <BoxCard>
            <ColumnLayout top>
                <Icon name={iconName} />
                <ColumnLayout
                    gap="16"
                    split="3"
                    responsive={{ containerWidth: { smallerThan: 400 } }}
                >
                    <StackLayout gap="8">
                        <Label style="heading 5">{invoice.lineOfBusiness}</Label>
                        {bundlePolicyListData}
                        <br />
                        <ColumnLayout gap="8">
                            <ColumnLayout>
                                <TextButton
                                    color="primary-500"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        showBundleModal(invoice);
                                    }}
                                >
                                    View details
                                </TextButton>
                            </ColumnLayout>
                            <Text style="heading 5">{moneyDisplay}</Text>
                        </ColumnLayout>
                    </StackLayout>
                </ColumnLayout>
            </ColumnLayout>
        </BoxCard>
    );
}

function EligibilityListItem({ invoice, showModal, showBundleModal }: EligibleListItem) {
    const moneyDisplay = <MoneyDisplay value={invoice.balance} />;
    let iconName = coverageIconMap.get(invoice.lineOfBusiness);
    if (iconName == undefined) iconName = 'multicoverage';
    if (isBundlePayment(invoice)) {
        return (
            <EligibilityBundleInvoiceListItem invoice={invoice} showBundleModal={showBundleModal} />
        );
    }
    return (
        <BoxCard>
            <ColumnLayout gap="16" split="3">
                <Icon name={iconName} />
                <StackLayout gap="8">
                    <Text style="heading 5">{invoice.lineOfBusiness}</Text>
                    <ColumnLayout gap="24">
                        <Text style="body 2" color="ui-500">
                            Invoice no. {invoice.invoiceNumber}
                        </Text>
                        <TextButton
                            color="primary-500"
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                showModal(invoice);
                            }}
                        >
                            View details
                        </TextButton>
                    </ColumnLayout>
                </StackLayout>
                <Text style="heading 5">{moneyDisplay}</Text>
            </ColumnLayout>
        </BoxCard>
    );
}

export function EligibilityPage(props: EligibilityPageInput) {
    const navigation = useNavigation();
    const loading = useLoadingRoute();
    const modal = useModal();
    const bundleModal = useModal();

    const eligibleInvoiceIdList = props.eligibleInvoices.reduce(
        (idList, invoice) => buildInvoiceIdList(idList, invoice),
        new Array<UUID>(),
    );

    const eligibleInvoicesIDs: SumInvoicesToPayRequest = {
        invoiceIds: eligibleInvoiceIdList,
    };

    const [currentInvoiceDetail, setCurrentInvoiceDetail] =
        useState<Immutable<EntityProps<Invoice>>>();
    const [currentBundleInvoiceDetail, setCurrentBundleInvoiceDetail] =
        useState<Immutable<BundlePayment>>();

    const { result } = useUseCase(SumInvoicesToPay, eligibleInvoicesIDs);

    if (result === undefined || !isOK(result)) {
        return null;
    }

    if (loading) {
        return <Spinner />;
    }

    function hideModal() {
        modal.hide();
    }

    function showModal(invoice: Immutable<EntityProps<Invoice>>) {
        modal.show();
        setCurrentInvoiceDetail(invoice);
    }

    function showBundleModal(invoiceList: Immutable<BundlePayment>) {
        bundleModal.show();
        setCurrentBundleInvoiceDetail(invoiceList);
    }

    function hideBundleModal() {
        bundleModal.hide();
    }

    function handleOnBack() {
        navigation.navigate('/payments');
    }

    function handleOnContinue() {
        navigation.navigate(
            URI.build('/payments/finance/', {
                selectedInvoiceIdList: props.selectedInvoiceIdList,
            }),
        );
    }

    return (
        <PageLayout.Section>
            <StackLayout gap="24">
                <ColumnLayout>
                    <TextButton size="small" icon="bold-caret-left" onClick={handleOnBack}>
                        Back to Payments
                    </TextButton>
                </ColumnLayout>

                <StackLayout gap="32">
                    <SidebarLayout
                        sidebar="right"
                        gap={props.eligibleInvoices.length != 0 ? '32' : 'none'}
                    >
                        <React.Fragment>
                            <StackLayout gap="24">
                                <ColumnLayout split="1">
                                    <Text style="heading 4">Financing eligibility</Text>
                                </ColumnLayout>
                                {props.eligibleInvoices.length > 0 ? (
                                    <Text style="body 1">
                                        The following invoice(s) are eligible for financing with
                                        First Insurance Financing and are included in the total to
                                        the right:
                                    </Text>
                                ) : null}
                                {props.eligibleInvoices.map((invoice) => {
                                    return (
                                        <EligibilityListItem
                                            key={invoice.id}
                                            invoice={invoice}
                                            showModal={showModal}
                                            showBundleModal={showBundleModal}
                                        />
                                    );
                                })}
                                <Text style="body 1">
                                    Embroker utilizes a third party bank for premium financing
                                    arrangements. Our team is on top of this request and will get
                                    back to you momentarily with your options. If you have questions
                                    in the meantime, please reach out to your Embroker contact or{' '}
                                    <TextButton href="mailto:success@embroker.com">
                                        success@embroker.com
                                    </TextButton>
                                    . Thanks for choosing to work with us.
                                </Text>
                                <Text style="body 1">
                                    The following invoice(s) are currently ineligible for financing:
                                </Text>
                                {props.notEligibleInvoices.map((invoice) => {
                                    return (
                                        <EligibilityListItem
                                            key={invoice.id}
                                            invoice={invoice}
                                            showModal={showModal}
                                            showBundleModal={showBundleModal}
                                        />
                                    );
                                })}
                            </StackLayout>
                            <SidebarLayout.MobileFooter>
                                <ColumnLayout>
                                    <StackLayout gap="8">
                                        <Text>Total amount:</Text>
                                        <Text style="heading 5">
                                            {' '}
                                            <MoneyDisplay value={result.value} />
                                        </Text>
                                    </StackLayout>
                                    <SidebarLayout.Link panelIndex={1}>
                                        Show Quote
                                    </SidebarLayout.Link>
                                </ColumnLayout>
                            </SidebarLayout.MobileFooter>
                        </React.Fragment>
                        <StackLayout center={props.eligibleInvoices.length == 0} gap="32">
                            {props.eligibleInvoices.length > 0 ? (
                                <React.Fragment>
                                    <InvoiceTable>
                                        <InvoiceTable.Section>
                                            <InvoiceTable.Subtotal title="Balances included in total" />
                                            {props.eligibleInvoices.map((invoice) => (
                                                <InvoiceTable.Item
                                                    title={invoice.lineOfBusiness}
                                                    key={invoice.id}
                                                >
                                                    <MoneyDisplay value={invoice.balance} />
                                                </InvoiceTable.Item>
                                            ))}
                                            <InvoiceTable.Section>
                                                <InvoiceTable.Total title="Total Amount:">
                                                    <MoneyDisplay value={result.value} />
                                                </InvoiceTable.Total>
                                            </InvoiceTable.Section>
                                        </InvoiceTable.Section>
                                        <InvoiceTable.Section>
                                            <InvoiceTable.Subtotal title="Financing options" />
                                            <InvoiceTable.Item title="Monthly payments (10 installments)">
                                                15% down
                                            </InvoiceTable.Item>
                                            <InvoiceTable.Item title="Quarterly payments (3 installments)">
                                                30% down
                                            </InvoiceTable.Item>
                                        </InvoiceTable.Section>
                                    </InvoiceTable>
                                    <Button appearance="primary" onClick={handleOnContinue}>
                                        Get a quote
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Image width={178} name="shotwell-business-2" />
                            )}
                        </StackLayout>
                    </SidebarLayout>
                </StackLayout>
            </StackLayout>
            <ModalInvoiceDetail
                hideModal={hideModal}
                invoice={currentInvoiceDetail}
                modal={modal}
            />
            <BundleModalInvoiceDetail
                hideModal={hideBundleModal}
                modal={bundleModal}
                bundlePayment={currentBundleInvoiceDetail}
            />
        </PageLayout.Section>
    );
}
