import React, { useEffect, useState } from 'react';
import {
    ColumnLayout,
    Form,
    FormFieldProps as FormFieldPropsToolkit,
    Immutable,
    InputStatusMessage,
    StackLayout,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { FormFieldView, FormFieldViewProps } from './FormFieldView.view';
import {
    ANY_REQUIRED,
    EMPTY_REQUIRED_ARRAY,
    MULTI_FIELD_INPUT_DELIMITER,
} from '../types/validationObject';
import innerText from 'react-innertext';
import { QuestionType } from '../hooks/useDataDrivenForm';

export const DEFAULT_EMPTY_VALUE = undefined;

const questionTypeMessageMap: Partial<{
    [key in QuestionType]: {
        addItemLabel: string;
        defaultValidationMessage: string;
        defaultValidationMessageMany: string;
    };
}> = {
    addressField: {
        addItemLabel: 'Add another location',
        defaultValidationMessage: `Please enter at least one valid address that's a physical location (no P.O. boxes, etc.)`,
        defaultValidationMessageMany: `Please ensure all locations are complete and valid`,
    },
    fundraisingRound: {
        addItemLabel: 'Add new fundraising round',
        defaultValidationMessage:
            'Please enter at least one fundraising round with complete date, amount and investor name',
        defaultValidationMessageMany: `Please ensure all fundraising rounds are complete and valid`,
    },
};

interface FieldMessageMap {
    [key: string]: string[] | null;
}

function parseMessage(messageString: string) {
    const firstChar = messageString[0];
    const index = !isNaN(parseInt(firstChar, 10)) ? parseInt(firstChar, 10) : undefined;
    if (index === undefined) {
        return;
    }
    const messageText = messageString.slice(2);
    return { index, messageText };
}

const getFieldMessageMap = (
    messages: FormFieldPropsToolkit['messages'],
): { [key: string]: string[] } => {
    return (messages || []).reduce((acc, message) => {
        const messageString = innerText(message);

        const parsedMessage = parseMessage(messageString);
        if (!parsedMessage) {
            return acc;
        }
        const { index, messageText } = parsedMessage;

        if (!acc[index]) {
            acc[index] = [];
        }

        acc[index].push(messageText);

        return acc;
    }, {} as { [key: string]: string[] });
};

const reorderErrors = (errorObj: FieldMessageMap, removedIndex: number): FieldMessageMap => {
    const newErrorObj: FieldMessageMap = {};

    for (const key in errorObj) {
        if (errorObj.hasOwnProperty(key)) {
            const index = parseInt(key, 10);
            if (index < removedIndex) {
                newErrorObj[index] = errorObj[key];
            } else if (index > removedIndex) {
                newErrorObj[index - 1] = errorObj[key];
            }
        }
    }

    return newErrorObj;
};

function getEmptyArrayMessage(
    questionKey: string,
    questionType: QuestionType,
    fieldMessageMap: FieldMessageMap,
    listValues: unknown[],
    messages?: Immutable<InputStatusMessage[]>,
): Immutable<InputStatusMessage[]> {
    const emptyInputMessages = [
        `${questionKey}${MULTI_FIELD_INPUT_DELIMITER}${ANY_REQUIRED}`,
        `${questionKey}${MULTI_FIELD_INPUT_DELIMITER}${EMPTY_REQUIRED_ARRAY}`,
    ];

    const isEmptyRequiredList = messages
        ?.map(innerText)
        .some((message) => emptyInputMessages.includes(message));

    const fieldMessageMapValues = Object.values(fieldMessageMap);

    const allSparesMessages =
        fieldMessageMapValues.length > 0 &&
        fieldMessageMapValues.every((messages) => messages && messages[0] === 'sparse');

    const emptyArrayMessage =
        questionTypeMessageMap[questionType]?.defaultValidationMessage ||
        'Please ensure all values are complete and valid';
    // If messages includes ANY_REQUIRED for the whole input or if all the messages are 'sparse' messages, then return the emptyArrayMessage
    if (isEmptyRequiredList || allSparesMessages) {
        return [emptyArrayMessage];
    }

    // If the list is longer than the messages, then we have incomplete items
    if (fieldMessageMapValues.length && listValues.length > fieldMessageMapValues.length) {
        return ['Please remove all incomplete items'];
    }
    const hasInvalidOptionalArrayMessage = messages
        ?.map(innerText)
        .some((message) => message.includes('INVALID_OPTIONAL_ARRAY'));

    if (hasInvalidOptionalArrayMessage) {
        return ['Please remove all incomplete items'];
    }

    return [];
}

export interface MultipleFormFieldViewProps
    extends Omit<FormFieldViewProps, 'onComplexFieldChange'> {
    onFieldChange: (questionKey: string, value: unknown) => void;
}

export function assertInputValueAsUnknownArray(input: unknown): input is any[] {
    return Array.isArray(input);
}

export function MultipleFormFieldView(formFieldViewProps: MultipleFormFieldViewProps) {
    const { questionProps, inputFieldProps, onFieldChange } = formFieldViewProps;
    const { messages } = inputFieldProps;

    const [fieldMessageMap, setFieldMessageMap] = useState<FieldMessageMap>({});

    useEffect(() => {
        setFieldMessageMap(getFieldMessageMap(messages || []));
    }, [messages]);

    const listValues: unknown[] = assertInputValueAsUnknownArray(inputFieldProps.inputProps.value)
        ? inputFieldProps.inputProps.value
        : [DEFAULT_EMPTY_VALUE];

    const formFieldProps = {
        title: questionProps.title,
        tooltip: questionProps.tooltip,
    };

    const handleChange = (value: unknown, index: number) => {
        const updatedFieldValue = [...listValues];
        updatedFieldValue[index] = value;
        onFieldChange(questionProps.key, updatedFieldValue);
    };

    const handleAddItem = () => {
        const updatedFieldValue = [...listValues];
        updatedFieldValue.push(DEFAULT_EMPTY_VALUE);
        onFieldChange(questionProps.key, updatedFieldValue);

        // Update fieldMessageMap to keep messages inline with inputs
        const newFieldMessageMap = { ...fieldMessageMap, [updatedFieldValue.length - 1]: null };
        setFieldMessageMap(newFieldMessageMap);
    };

    const handleRemoveItem = (index: number) => {
        const updatedFieldValue = [...listValues.slice(0, index), ...listValues.slice(index + 1)];
        onFieldChange(questionProps.key, updatedFieldValue);

        // Update fieldMessageMap to keep messages inline with inputs
        const newFieldMessageMap = reorderErrors(fieldMessageMap, index);
        setFieldMessageMap(newFieldMessageMap);
    };

    const emptyArrayMessage = getEmptyArrayMessage(
        questionProps.key,
        questionProps.questionType,
        fieldMessageMap,
        listValues,
        messages,
    );
    return (
        <StackLayout gap="24">
            <Form.Field
                data-testid={`question-key-${questionProps.key}`}
                {...formFieldProps}
                messages={emptyArrayMessage}
            >
                <StackLayout gap="24">
                    {listValues.map((listItemValue: unknown, index: number) => {
                        const itemMessages = fieldMessageMap[index] || [];
                        return (
                            <StackLayout key={index} gap="none">
                                <StackLayout gap="24">
                                    <FormFieldView
                                        questionProps={{
                                            ...formFieldViewProps.questionProps,
                                            isMultiple: false, // This ensures we do not enter a continuios loop of rendering MultipleFormFieldViews
                                            title: undefined, // This ensures we do not render a title for each list item
                                            statusMessage: undefined, // Prevent rendering of stauts message for each input
                                            tooltip: undefined,
                                        }}
                                        inputFieldProps={{
                                            ...formFieldViewProps.inputFieldProps,
                                            inputProps: {
                                                ...inputFieldProps.inputProps,
                                                items: questionProps.selectOptions,
                                                value: listItemValue,
                                                hasErrors: itemMessages.length > 0,
                                                onChange: (e) =>
                                                    handleChange(e.target.value, index),
                                            },
                                            messages: itemMessages,
                                        }}
                                        onComplexFieldChange={(key, e) => handleChange(e, index)}
                                    />
                                    {listValues.length > 1 && (
                                        <ColumnLayout split="1">
                                            <TextButton
                                                icon="trash"
                                                color="negative-500"
                                                onClick={() => handleRemoveItem(index)}
                                            >
                                                Remove
                                            </TextButton>
                                        </ColumnLayout>
                                    )}
                                    <span />
                                </StackLayout>
                                <div className="c-list-view__item--border-solid-ui-300" />
                            </StackLayout>
                        );
                    })}
                </StackLayout>
            </Form.Field>
            <TextButton
                icon="plus"
                iconSize="small"
                data-testid={`add-another-${questionProps.key}`}
                onClick={handleAddItem}
            >
                {questionTypeMessageMap[questionProps.questionType]?.addItemLabel || 'Add another'}
            </TextButton>
        </StackLayout>
    );
}
