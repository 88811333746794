import * as React from 'react';
import { map, redirect, route } from 'navi';
import { IndicativePremiumPage } from '../components/lawBundle/IndicativePremiumPage';
import { EMBROKER } from '.';

export const lawBundleWizardRoutes = {
    '/': map(() => {
        return redirect('/shopping/law-bundle/coverage-selection');
    }),
    '/coverage-selection': map((request) => {
        return route({
            title: EMBROKER,
            view: <IndicativePremiumPage />,
            data: { fullscreen: true },
        });
    }),
};
