import React, { MouseEvent } from 'react';
import { Image } from '@embroker/ui-toolkit/v2';
import { Link } from './Link/Link';

interface NavigationalLogoProps {
    href?: string;
    onLogoClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

export function NavigationalLogo({ href = '/', onLogoClick }: NavigationalLogoProps) {
    return (
        <Link href={href} onClick={onLogoClick}>
            <Image name="logotype" height={20} />
        </Link>
    );
}
