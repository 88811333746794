import { CenterLayout, ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface ContactNavigationProps {
    hideChat?: boolean;
}

export function ContactNavigation({ hideChat = false }: ContactNavigationProps) {
    return (
        <ColumnLayout
            as={CenterLayout}
            responsive={{ containerWidth: { smallerThan: 500 } }}
            center
            gap="48"
        >
            <TextButton
                iconPosition="left"
                color="primary-500"
                href="tel:844.436.2765"
                icon="phone"
            >
                Call 844.436.2765
            </TextButton>
            {!hideChat && (
                <TextButton
                    iconPosition="left"
                    icon="live-chat"
                    color="primary-500"
                    href="#chat-now"
                >
                    Live Chat
                </TextButton>
            )}
        </ColumnLayout>
    );
}
