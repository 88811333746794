import React from 'react';
import { StackLayout, Text, List } from '@embroker/ui-toolkit/v2';
import {
    AppTypeCode,
    AppTypeCodeListTechEO,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListHartfordBOP,
    AppTypeCodeListWCChubb,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualTravel,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListEverestLawyersProfessionalLiability,
} from '@app/shopping/types/enums';

export const getCoverageDetailsContent = (
    appTypeCode: AppTypeCode,
): { header: React.ReactNode; body: React.ReactNode } => {
    switch (appTypeCode) {
        case AppTypeCodeListTechEO: {
            return {
                header: (
                    <Text style="heading 3">
                        Comprehensive protection for tech and data-related risks
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need Technology Errors and Omissions / Cyber insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Data breaches, cyberattacks, or claims of a software or coding
                                    error — each of these can be devastating for tech pros like you.
                                </Text>
                                <Text style="body 2">
                                    Tech E&O / Cyber insurance is specially designed to address such
                                    risks. The E&O part covers liabilities related to products or
                                    services — like your software failing to perform as intended —
                                    while Cyber can cover financial losses following a cyber event.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Lawsuits alleging mistakes, omissions, or negligence in
                                        providing your professional services
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Claims you missed a deadline or went over budget
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Costs to restore your company’s damaged security system
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Software copyright code infringement</Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListManualDirectorsAndOfficers: {
            return {
                header: (
                    <Text style="heading 3">
                        Protection for your leadership team against costly lawsuits
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need Directors and Officers insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Directors and officers of tech companies face risks — from
                                    shareholders disputes and claims alleging mismanagement to
                                    regulatory investigations. The stakes can be high.
                                </Text>
                                <Text style="body 2">
                                    Directors and officers (D&O) liability insurance protects
                                    covered executives and company assets if they’re sued for
                                    actions they take on behalf of the company. It pays legal fees,
                                    settlements and judgements, safeguarding them from financial
                                    loss.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Claims alleging misleading financial statements
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Claims arising from mergers, acquisitions, or divestitures
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Defense costs/settlements for covered regulatory
                                        investigations
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Lawsuits brought on by shareholders on behalf of the company
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListManualEmploymentPractices: {
            return {
                header: (
                    <Text style="heading 3">
                        Comprehensive protection against employment-related risks
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need Employment Practices Liability insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Wrongful termination, discrimination, failure to promote, wage
                                    and hour disputes — these are just some of the
                                    employment-related risks tech companies like yours can face. A
                                    single claim or lawsuit can cause irreparable financial or
                                    reputational damage.
                                </Text>
                                <Text style="body 2">
                                    Employment Practices Liability Insurance (EPLI) protects your
                                    business by covering legal defense costs, and settlements or
                                    judgements required to be paid under a covered claim.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Claims alleging unfair promotion practices or unjust
                                        dismissal
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Financial loss due to covered settlements or court
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Claims of sexual, racial, or other forms of harassment
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Covered attorney fees and other legal defense expenses
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListBOPChubb: {
            return {
                header: (
                    <Text style="heading 3">
                        Cost-effective, tailored coverage for liability and property risks
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">Why do you need a Business Owners Policy?</Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Damaged equipment in your office space. An injury in your
                                    workspace. A car accident while driving for work. These are just
                                    some of the common liability and property risks faced by
                                    businesses like yours.
                                </Text>
                                <Text style="body 2">
                                    A Business Owners Policy (BOP) combines multiple coverages in
                                    one policy. The General Liability part can cover such
                                    liabilities as third-party injuries, while Commercial Property
                                    insurance protects your office and its contents.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Legal defense costs, settlements, and medical and other
                                        expenses resulting from third-party bodily injury cases
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Theft or damage of your property by a third party
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Financial losses in case of business interruption
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Hired/Non-Owned Auto Coverage</Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListHartfordBOP: {
            return {
                header: <Text style="heading 3">Essential protection against everyday risks</Text>,
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need General Liability insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    A customer slips in your office and gets hurt. Someone claims
                                    your product damaged their property. A competitor says your
                                    advertising harmed their reputation. Any one of these common
                                    scenarios could lead to a costly lawsuit — whether you’re at
                                    fault or not.
                                </Text>
                                <Text style="body 2">
                                    General Liability insurance protects your business from the
                                    costs associated with the bodily injury or property damage of a
                                    customer, vendor, or others who interact with your business.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Bodily injury, if clients, vendors, or visitors get hurt at
                                        your business premises
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Property damage, if your business accidentally damages
                                        someone else’s property
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Legal defense costs, settlements, and judgements
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Advertising mistakes that lead to claims of copyright
                                        infringement, slander, or libel
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListWCChubb: {
            return {
                header: (
                    <Text style="heading 3">
                        Covers your employees and your business in the event of a work-related
                        injury/illness
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">Why do you need Workers Compensation?</Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Did you know Workers Compensation is not just for high-risk
                                    jobs? With Workers Comp, you can care for your employees,
                                    protect your business, and meet state requirements with one
                                    policy.
                                </Text>
                                <Text style="body 2">
                                    Employees’ slip-and-fall workplace injuries and repetitive
                                    stress injuries from everyday computer tasks can also result in
                                    costly claims for your firm.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">Hospital bills and medication</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Physical therapy</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Lost wages</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Death benefits</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Claims that your negligence led to employee injury/illness
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListManualFiduciary: {
            return {
                header: (
                    <Text style="heading 3">
                        Coverage against claims of benefit plan mismanagement
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need Fiduciary Liability insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Managing employee benefits can come with significant risks.
                                    Participants could claim the fiduciaries administering the plan
                                    underfunded the plan, failed to protect their private data or
                                    aren’t compliant with regulations — any one of these could
                                    result in a costly lawsuit.
                                </Text>
                                <Text style="body 2">
                                    Fiduciary Liability insurance covers legal defense costs and
                                    financial losses from an alleged breach of fiduciary duty,
                                    including failure to administer the benefits plan according to
                                    plan documents.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Mistakes in enrollment, eligibility, or benefit calculations
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Legal defense costs, settlements, and damages awarded by the
                                        court in findings of wrongdoing
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Imprudent choice of investments or third-party vendors
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Costs associated with investigating a claim
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListManualProductLiability: {
            return {
                header: (
                    <Text style="heading 3">
                        Comprehensive protection against product-related risks
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need Product Liability insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Even with the best quality control, accidents can still happen.
                                    If a customer gets hurt using your product, or it causes damage,
                                    you could face expensive legal bills — and harm to your
                                    reputation.
                                </Text>
                                <Text style="body 2">
                                    Product Liability Insurance protects your business against
                                    financial losses due to product-related lawsuits by covering
                                    legal defense costs as well as any court settlements or
                                    judgements.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Bodily injury or property damage caused by a faulty product
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Inadequate warnings or instructions</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Legal defense costs</Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
        case AppTypeCodeListManualTravel: {
            return {
                header: (
                    <Text style="heading 3">
                        Essential protection for international business travel
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need International Package insurance?
                            </Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    Business travel may be essential, but it comes with unique
                                    risks. International package insurance is the best way to
                                    protect your business from risks that you and your employees may
                                    face while operating abroad.
                                </Text>
                                <Text style="body 2">
                                    International Package insurance expands your General Liability,
                                    Workers Compensation, and Auto Liability coverages outside of
                                    the U.S. and Canada.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Legal defense costs, settlements, and judgements associated
                                        with third-party injury or property damage
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Liability for operating vehicles internationally
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Accidental death or dismemberment</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Kidnap and ransom events</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Medical expenses and emergency evacuation
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }

        case AppTypeCodeListCyberCowbell: {
            return {
                header: (
                    <Text style="heading 3">
                        Covers expenses that can arise from a security breach or cybercrime
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">Why do you need Cyber Insurance?</Text>
                            <StackLayout gap="24">
                                <Text style="body 2">
                                    If you store client data (names, addresses, SSN, credit card
                                    info, etc.) or transfer funds electronically, your firm is
                                    vulnerable to cybercrime — simple as that. Even if you don’t,
                                    your paper files could be at risk if they’re not adequately
                                    secured.
                                </Text>
                                <Text style="body 2">
                                    Still not convinced? According to the U.S. Government
                                    Accountability Office, in 2021, the average cost of a data
                                    breach incident was $118,000.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                Common Cyber Coverages include:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">
                                        Phishing: Scammers using fake emails/messages/websites to
                                        try to trick you into giving up your personal info.
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Network/data breaches and privacy liability claims
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Extortion and ransomware events</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Loss of business income</Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }

        case AppTypeCodeListEverestLawyersProfessionalLiability: {
            return {
                header: (
                    <Text style="heading 3">
                        Essential protection against common risks for law firms
                    </Text>
                ),
                body: (
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style="heading 4">
                                Why do you need Lawyers Professional Liability Insurance?
                            </Text>
                            <Text style="body 2">
                                You’re in one state and practice in another? LPL protects you
                                against costly claims of alleged errors and omissions no matter
                                where in the world you provide your professional services.
                            </Text>
                        </StackLayout>
                        <StackLayout gap="12">
                            <Text style="overline" color="ui-500">
                                LPL commonly includes coverage for claims of:
                            </Text>
                            <List>
                                <List.Item>
                                    <Text style="body 2">Negligence or errors</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">Actual or alleged misrepresentation</Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Conflicts of interest or breach of duty
                                    </Text>
                                </List.Item>
                                <List.Item>
                                    <Text style="body 2">
                                        Rendering or failing to render professional services
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                        <StackLayout gap="16">
                            <Text style="body 1">This doesn’t includes coverage for:</Text>
                            <List>
                                <List.Item icon="close" iconColor="negative-500">
                                    <Text style="body 1" as="div">
                                        Phishing: Scammers using fake emails/messages/websites to
                                        try to trick you into giving up your personal info.
                                    </Text>
                                </List.Item>
                            </List>
                        </StackLayout>
                    </StackLayout>
                ),
            };
        }
    }

    return {
        header: null,
        body: null,
    };
};
