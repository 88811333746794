import { LegacyGrowthBookExperimentationService } from '@app/experimentation/services/LegacyGrowthBookExperimentationService';
import { CreateGenericBundleApplication } from '@app/shopping/useCases/CreateGenericBundleApplication';
import { StartApplicationIsRenewal } from '@app/shopping/useCases/StartApplicationIsRenewal';
import { SaveProductSelectionAnswers } from '@app/shoppingQuestioner/useCases/SaveProductSelectionAnswers';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { navigateToErrorPage } from '@app/view/errors';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Nullable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppContextStore } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { AppTypeCode, AppTypeCodeListESP } from '../../types/enums';
import { CreateMplBundleApplication } from '../../useCases/CreateMplBundleApplication';
import { PublishPurchaseIntentEvent } from '../../useCases/PublishPurchaseIntentEvent';
import { StartApplication, StartApplicationRequest } from '../../useCases/StartApplication';
import { TechCoverageTypes } from '../components/techVerticalProductSelection/TechVerticalProductSelectionPage';

const START_WITH_ONBOARDING_APP_TYPES: AppTypeCode[] = [AppTypeCodeListESP];

export const getApplicationEntrypointUrl = (
    appTypes: AppTypeCode[],
    defaultAppUrl: URI,
    naics?: Nullable<string>,
) => {
    if (
        naics &&
        NAICS_CODE_TO_VERTICAL[naics] === 'TechCompanies' &&
        container
            .get<LegacyGrowthBookExperimentationService>(LegacyGrowthBookExperimentationService)
            .getFeatureValue('tech-vertical-one-by-embroker', false) &&
        appTypes.some((appType) => START_WITH_ONBOARDING_APP_TYPES.includes(appType))
    ) {
        return URI.build('/user/onboarding');
    }
    return defaultAppUrl;
};

export type StartGenericBundleRequest = TechCoverageTypes[];

export function useStartApplication() {
    const [isLoading, setIsLoading] = useState(false);
    const { navigate } = useNavigation();

    const abortController = useMemo(() => new AbortController(), []);
    useEffect(() => () => abortController.abort(), [abortController]);

    const publishPurchaseIntentEvent = useCallback((appTypes: AppTypeCode[]) => {
        execute(PublishPurchaseIntentEvent, { appTypes }).then((publishResult) => {
            if (isErr(publishResult)) {
                container.get<Logger>(Log).error(publishResult.errors);
                return;
            }
        });
    }, []);

    const getLPLRenewalApplicationId = useCallback(
        async (selectedAppTypes: AppTypeCode[], stopLoading = true) => {
            setIsLoading(true);

            const isRenewalResp = await execute(StartApplicationIsRenewal, {
                selectedAppTypes,
            });

            const lplRenewalApplicationId =
                isOK(isRenewalResp) && isRenewalResp.value.lplRenewalApplicationId;

            if (stopLoading) {
                setIsLoading(false);
            }

            return lplRenewalApplicationId;
        },
        [],
    );

    const startGenericBundleApplication = useCallback(
        async (selectedCoverages: StartGenericBundleRequest) => {
            setIsLoading(true);
            publishPurchaseIntentEvent(['AppTypeCodeListGenericBundle']);

            const saveProductSelectionAnswersResp = await execute(SaveProductSelectionAnswers, {
                coverages: selectedCoverages,
            });

            if (!isOK(saveProductSelectionAnswersResp)) {
                setIsLoading(false);
                navigateToErrorPage(navigate, saveProductSelectionAnswersResp.errors);
                return;
            }

            const action = await execute(CreateGenericBundleApplication, {
                abortSignal: abortController.signal,
                selectedCoverages,
            });

            if (isErr(action)) {
                setIsLoading(false);
                navigateToErrorPage(navigate, action.errors);
                return;
            }

            const uri = URI.build('/shopping/application', {
                applicationId: action.value.bundleApplicationId,
            });

            navigate(uri);

            AppContextStore.update({ selectedCoverages: [] });
            setIsLoading(false);
        },
        [abortController, publishPurchaseIntentEvent, navigate],
    );

    const startApplication = useCallback(
        async (
            startApplicationRequest: Omit<StartApplicationRequest, 'abortSignal'>,
            organizationNaics?: Nullable<string>,
        ) => {
            setIsLoading(true);
            publishPurchaseIntentEvent(startApplicationRequest.selectedAppTypes);

            const request: StartApplicationRequest = {
                ...startApplicationRequest,
                abortSignal: abortController.signal,
            };
            const action = await execute(StartApplication, request);

            if (isErr(action)) {
                return;
            }

            navigate(
                getApplicationEntrypointUrl(
                    startApplicationRequest.selectedAppTypes,
                    action.value.uri,
                    organizationNaics,
                ),
            );

            AppContextStore.update({ selectedCoverages: [] });
            setIsLoading(false);
        },
        [abortController, publishPurchaseIntentEvent, navigate],
    );

    // TODO: As we extened the bundle offering we should extend this function to accept a somethignlike a `BundleAppType`
    // and execute each bundles corresponding useCase
    const startMPLBundleApplication = useCallback(async () => {
        setIsLoading(true);
        publishPurchaseIntentEvent(['AppTypeCodeListMPL']);

        const request = { abortSignal: abortController.signal };
        const action = await execute(CreateMplBundleApplication, request);

        if (isErr(action)) {
            return;
        }

        const uri = URI.build('/shopping/application', {
            applicationId: action.value.bundleApplicationId,
        });

        navigate(uri);

        AppContextStore.update({ selectedCoverages: [] });
        setIsLoading(false);
    }, [abortController, publishPurchaseIntentEvent, navigate]);

    return {
        getLPLRenewalApplicationId,
        startApplication,
        startMPLBundleApplication,
        startGenericBundleApplication,
        isLoading,
    };
}

export type StartApplicationFunction = ReturnType<typeof useStartApplication>['startApplication'];
export type StartMPLBundleApplication = ReturnType<
    typeof useStartApplication
>['startMPLBundleApplication'];
export type StartGenericBundleApplication = ReturnType<
    typeof useStartApplication
>['startGenericBundleApplication'];
