import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UserOnboardingDetails } from '../../types/UserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { OnboardingPrefillQuestionnaireData } from '../../types/OnboardingPrefillQuestionnaireData';
import { SignUpRequest } from '@app/userOrg/useCases/SignUp';

export interface UserOnboardingRepository {
    setUserOnboardingDetails(
        userOnboardingDetails: UserOnboardingDetails,
    ): AsyncResult<void, never>;

    removeUserOnboardingDetails(): AsyncResult<void, never>;

    getUserOnboardingDetails(): AsyncResult<Nullable<UserOnboardingDetails>, never>;

    setUserOnboardingQuestionnaireData(
        questionnaireData: OnboardingPrefillQuestionnaireData,
    ): AsyncResult<void, never>;

    removeUserOnboardingQuestionnaireData(): AsyncResult<void, never>;

    getUserOnboardingQuestionnaireData(): AsyncResult<
        Nullable<OnboardingPrefillQuestionnaireData>,
        never
    >;

    setUnauthorizedOnboardingSignUpData(signupRequest: SignUpRequest): AsyncResult<any, never>;

    getUnauthorizedOnboardingSignUpData(): AsyncResult<any, never>;

    removeUnauthorizedOnboardingSignUpData(): AsyncResult<any, never>;
}

export const UserOnboardingRepository = Symbol('UserOnboardingRepository');
