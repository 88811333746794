import { ColumnLayout, IconPhosphor, IconPhosphorProps } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface InformationItemProps extends React.HTMLAttributes<HTMLDivElement> {
    iconName: IconPhosphorProps['name'];
}
export function InformationItem(props: InformationItemProps) {
    const { iconName, children } = props;
    return (
        <ColumnLayout gap="8" center className="u-1/1">
            <IconPhosphor name={iconName} size="xsmall" />
            {children}
        </ColumnLayout>
    );
}
