import { SinglePayment } from '@app/payments/types/Payment';
import { PaymentsPurchaseTypeCodeListMap } from '@embroker/shotwell-api/enums';
import { Immutable } from '@embroker/shotwell/core/types';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, Icon, Label, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { coverageIconMap } from './InvoiceListItem.view';
import { resolveLobByDescription } from './PaymentsDue';

interface SingleInvoiceListItemProps {
    invoice: Immutable<SinglePayment>;
    dataE2e?: string;
    onViewDetailsClicked(): void;
}

export function InvoiceListItemSingle(props: SingleInvoiceListItemProps) {
    const { invoice, dataE2e, onViewDetailsClicked } = props;

    const lob = resolveLobByDescription(invoice.lineOfBusiness, invoice.description);
    let iconName = coverageIconMap.get(lob);
    if (iconName == undefined) {
        iconName = 'product-default';
    }

    return (
        <ColumnLayout top data-e2e={dataE2e}>
            <Icon name={iconName} />
            <ColumnLayout gap="16" split="3" responsive={{ containerWidth: { smallerThan: 400 } }}>
                <StackLayout gap="8">
                    <Label style="heading 5">{lob}</Label>
                    <ColumnLayout gap="8">
                        <Text style="body 2" color="ui-500">
                            Invoice no. {invoice.invoiceNumber}
                            {invoice.purchaseType != undefined
                                ? ` • ${PaymentsPurchaseTypeCodeListMap[invoice.purchaseType]} `
                                : null}
                        </Text>
                        <TextButton color="primary-500" onClick={onViewDetailsClicked}>
                            View Details
                        </TextButton>
                    </ColumnLayout>
                </StackLayout>
                <Text style="heading 5">
                    <MoneyDisplay value={invoice.balance} />
                </Text>
            </ColumnLayout>
        </ColumnLayout>
    );
}
