import React, { Fragment } from 'react';
import { List, StackLayout, Text } from '@embroker/ui-toolkit/v2';

interface CardColumn {
    title: string;
    list: string[];
}

interface ColumnListProps {
    columnLists: {
        topList: CardColumn;
        bottomList: CardColumn;
    };
}

export function ColumnList({ columnLists }: ColumnListProps) {
    return (
        <Fragment>
            {columnLists.topList ? (
                <StackLayout gap="16">
                    <Text style="body 1">{columnLists.topList.title}</Text>
                    <List>
                        {columnLists.topList.list.map((item) => (
                            <List.Item key={item} icon="check">
                                <Text style="body 1" as="div">
                                    {item}
                                </Text>
                            </List.Item>
                        ))}
                    </List>
                </StackLayout>
            ) : null}
            {columnLists.bottomList ? (
                <StackLayout gap="16">
                    <Text style="body 1">{columnLists.bottomList.title}</Text>
                    <List>
                        {columnLists.bottomList.list.map((item) => (
                            <List.Item key={item} icon="shield-check" iconColor="primary-500">
                                <Text style="body 1" as="div">
                                    {item}
                                </Text>
                            </List.Item>
                        ))}
                    </List>
                </StackLayout>
            ) : null}
        </Fragment>
    );
}
