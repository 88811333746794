import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { routes } from './view/routes';
import { PublishRiskProfileIneligibilityModalActionClickEvent } from './useCases/PublishRiskProfileIneligibilityModalActionClickEvent';
import { PublishRiskProfileIneligibilityModalShowEvent } from './useCases/PublishRiskProfileIneligibilityModalShowEvent';
import { PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent } from '@app/public/useCases/PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent';
import { PublishRiskProfileReportToggleAdditionalRisksEvent } from '@app/public/useCases/PublishRiskProfileReportToggleAdditionalRisksEvent';
import { PublishRiskProfileReportBackToEmbrokerClickEvent } from '@app/public/useCases/PublishRiskProfileReportBackToEmbrokerClickEvent';
import { PublishRiskProfileReportGetAQuoteClickEvent } from '@app/public/useCases/PublishRiskProfileReportGetAQuoteClickEvent';
import { PublishRiskProfileReportRisksCoveredIncludeToggleEvent } from '@app/public/useCases/PublishRiskProfileReportRisksCoveredIncludeToggleEvent';

/**
 * The Public module.
 */
export const PublicModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<PublishRiskProfileIneligibilityModalShowEvent>(
            PublishRiskProfileIneligibilityModalShowEvent.type,
        )
            .to(PublishRiskProfileIneligibilityModalShowEvent)
            .inSingletonScope();
        bind<PublishRiskProfileIneligibilityModalActionClickEvent>(
            PublishRiskProfileIneligibilityModalActionClickEvent.type,
        )
            .to(PublishRiskProfileIneligibilityModalActionClickEvent)
            .inSingletonScope();
        bind<PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent>(
            PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent.type,
        )
            .to(PublishRiskProfileReportClickLearnMoreAboutInsuranceEvent)
            .inSingletonScope();
        bind<PublishRiskProfileReportToggleAdditionalRisksEvent>(
            PublishRiskProfileReportToggleAdditionalRisksEvent.type,
        )
            .to(PublishRiskProfileReportToggleAdditionalRisksEvent)
            .inSingletonScope();
        bind<PublishRiskProfileReportBackToEmbrokerClickEvent>(
            PublishRiskProfileReportBackToEmbrokerClickEvent.type,
        )
            .to(PublishRiskProfileReportBackToEmbrokerClickEvent)
            .inSingletonScope();
        bind<PublishRiskProfileReportGetAQuoteClickEvent>(
            PublishRiskProfileReportGetAQuoteClickEvent.type,
        )
            .to(PublishRiskProfileReportGetAQuoteClickEvent)
            .inSingletonScope();
        bind<PublishRiskProfileReportRisksCoveredIncludeToggleEvent>(
            PublishRiskProfileReportRisksCoveredIncludeToggleEvent.type,
        )
            .to(PublishRiskProfileReportRisksCoveredIncludeToggleEvent)
            .inSingletonScope();
    }),
};
