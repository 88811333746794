import React from 'react';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Text, TextButton, useResponsive } from '@embroker/ui-toolkit/v2';
import { Link, useCurrentRoute } from 'react-navi';

interface UnauthenticatedHeaderCtaViewProps {
    content: string;
    href: string;
    dataE2e: string;
    question: string;
}

export const UnauthenticatedHeaderCtaView = ({
    content,
    href,
    dataE2e,
    question,
}: UnauthenticatedHeaderCtaViewProps) => {
    const isSmallScreen = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    const { url } = useCurrentRoute();
    const queryFilter = { emailAddress: undefined };

    return (
        <Text>
            {!isSmallScreen && <span>{question}&nbsp;</span>}
            <TextButton
                data-e2e={dataE2e}
                as={Link}
                href={URI.build(href, url.search, queryFilter)}
            >
                {content}
            </TextButton>
            {!isSmallScreen && '.'}
        </Text>
    );
};
