import { CreateApplicationUnauthOnboarding } from '@app/shopping/useCases/CreateApplicationUnauthOnboarding';
import { useNavigation } from '@app/view/hooks/useNavigation';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';

export function CreateApplicationPage() {
    const { navigate } = useNavigation();

    useEffect(() => {
        (async () => {
            const createApplicationUnauthOnboardingResult = await execute(
                CreateApplicationUnauthOnboarding,
            );
            if (isOK(createApplicationUnauthOnboardingResult)) {
                navigate(createApplicationUnauthOnboardingResult.value.uri);
            } else {
                navigate(URI.build('/'));
            }
        })();
    }, [navigate]);

    return <Spinner />;
}
