import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    List,
    StackLayout,
    Text,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PrecreateApplication } from '../../../../brokerDashboard/useCases/PrecreateApplication';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AddNewOrganization } from '../../../../userOrg/useCases/AddNewOrganization';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import {
    AppTypeCodeListEmbrokerExcess,
    ShoppingCoverageCodeListCyber,
    ShoppingCoverageCodeListProfessionalLiability,
} from '@app/shopping/types/enums';
import { GetApplication } from '../../../useCases/GetApplication';
import { navigateToErrorPage } from '../../../../view/errors';

interface ExcessBeforeYouBeginProps {
    applicationId?: UUID;
    qd?: string;
}

export function ExcessBeforeYouBegin({ applicationId, qd }: ExcessBeforeYouBeginProps) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const [isIntakeApplication, setIsIntakeApplication] = useState(false);
    const isBroker = hasRole(activeSession, 'broker');

    const isApplicationCreatedThroughIntake = useCallback(async () => {
        if (applicationId !== undefined) {
            const result = await execute(GetApplication, {
                applicationId: applicationId,
            });
            if (isErr(result)) {
                container.get<Logger>(Log).error(result.errors);
            }
            if (result !== undefined && isOK(result)) {
                const isIntakeApplication =
                    result.value.application.creationType ==
                        'InsuranceApplicationCreationTypeCodeListIntake' &&
                    result.value.application.intakeTaskId != null;
                setIsIntakeApplication(isIntakeApplication);
            }
        }
    }, [applicationId]);

    useEffect(() => {
        isApplicationCreatedThroughIntake().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    }, [isApplicationCreatedThroughIntake, navigate]);

    const handleGetStarted = useCallback(async () => {
        let baseURL = '/shopping/questionnaire/';
        let builtURI = URI.build(baseURL, { qd });
        if (applicationId !== undefined) {
            // If the application is created successfully through intake we redirect to the shopping route
            baseURL = isIntakeApplication
                ? '/shopping/application/basic-info'
                : '/broker/application/basic-info';
            builtURI = URI.build(baseURL, {
                applicationId,
            });
        } else {
            const shouldCreateOrganizationAndApplication =
                isBroker || activeSession.organizationId == null;
            if (shouldCreateOrganizationAndApplication) {
                const addOrganizationResult = await execute(AddNewOrganization, {
                    companyLegalName: 'Enter Company Legal Name',
                });
                if (isErr(addOrganizationResult)) {
                    return addOrganizationResult;
                }

                const organizationId = addOrganizationResult.value.organizationId;
                const selectOrganizationResult = await execute(SelectOrganization, {
                    organizationId,
                });
                if (isErr(selectOrganizationResult)) {
                    return selectOrganizationResult;
                }

                const precreateApplicationsResponse = await execute(PrecreateApplication, {
                    appType: AppTypeCodeListEmbrokerExcess,
                    shoppingCoverageCodeList: [
                        ShoppingCoverageCodeListProfessionalLiability,
                        ShoppingCoverageCodeListCyber,
                    ],
                    questionnaireData: {},
                });
                if (isErr(precreateApplicationsResponse)) {
                    container.get<Logger>(Log).error(precreateApplicationsResponse.errors);
                    return precreateApplicationsResponse;
                }

                const applicationId = precreateApplicationsResponse.value.applicationId;
                builtURI = URI.build(baseURL, {
                    applicationId,
                    qd,
                });
            }
        }

        await navigate(builtURI);
    }, [navigate, activeSession.organizationId, applicationId, isBroker, qd, isIntakeApplication]);

    const onDismiss = useCallback(() => {
        isBroker ? navigate('/broker/dashboard') : navigate('/summary');
    }, [navigate, isBroker]);

    return (
        <WizardLayout onDismiss={onDismiss}>
            <WizardLayout.Cover>
                <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
                    <BoxLayout className="u-1/2@large-tablet u-1/1">
                        <StackLayout gap="32">
                            <Text style="heading 1">Before you begin...</Text>

                            <StackLayout data-e2e="body-text">
                                <Text style="body 1">
                                    Be sure to have the following information handy so you can
                                    complete the application quickly and easily:
                                </Text>
                                <List>
                                    <List.Item>Primary Quote Letter</List.Item>
                                    <List.Item>Primary Application</List.Item>
                                    <List.Item>
                                        Underlying Excess Quote Letters (if applicable)
                                    </List.Item>
                                </List>

                                <Text style="body 1">
                                    If you need assistance at any point, simply click on the
                                    messaging app (at the bottom right corner of your screen) to
                                    start a conversation with us.
                                </Text>
                            </StackLayout>

                            <Button
                                onClick={handleGetStarted}
                                appearance="primary"
                                type="submit"
                                data-e2e="get-started"
                            >
                                Get Started
                            </Button>
                        </StackLayout>
                    </BoxLayout>
                </ColumnLayout>
            </WizardLayout.Cover>
        </WizardLayout>
    );
}
