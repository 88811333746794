import { isDateAfter } from '@embroker/service-app-engine';
import { ShoppingCoverageCodeListMap } from '@embroker/shotwell-api/enums';
import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument, isError } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { ErrorLike, Failure, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { useModal } from '@embroker/ui-toolkit/v2';
import { addDays, startOfToday } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { EditApplication } from '../../../../brokerDashboard/useCases/EditApplication';
import {
    AppTypeCode,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListESP,
    AppTypeCodeListManualDirectorsAndOfficers,
    QuotingEnginePCoML,
} from '../../../../shopping/types/enums';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { hasRole } from '../../../../userOrg/entities/Session';
import { GetActiveOrganizationProfile } from '../../../../userOrg/useCases/GetActiveOrganizationProfile';
import { AppContext } from '../../../../view/AppContext';
import { navigateToErrorPage } from '../../../../view/errors';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm, WizardPages } from '../../../../view/hooks/useWizardForm';
import { GetSignaturePacketFileUrl } from '../../../brokerage/useCases/GetSignaturePacketFileUrl';
import { RequestHigherLimits } from '../../../brokerage/useCases/RequestHigherLimits';
import { BrokerSignaturePage } from '../../../brokerage/view/components/BrokerSignaturePage';
import { QuoteBindCoverage } from '../../../entities/Quote';
import { ErrorCode as QuoteErrorCode, isDocGenError } from '../../../errors';
import {
    PCoGeneralTermsAndConditionsName,
    PCoGeneralTermsAndConditionsNewInsurerURL,
    PCoGeneralTermsAndConditionsURL,
} from '../../../pcoUtils/constants';
import { DocumentType } from '../../../types/Document';
import { ReferQuote } from '../../../useCases/ReferQuote';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import { DocGenErrorModal } from '../../../view/components/DocGenErrorModal';
import { DocumentModal } from '../../../view/components/DocumentModal';
import { DownloadDocumentButton } from '../../../view/components/DownloadDocumentButton';
import { DownloadGeneralTermsAndConditionsButton } from '../../../view/components/DownloadGeneralTermsAndConditionsButton';
import { DownloadSignaturePacketButton } from '../../../view/components/DownloadSignaturePacketButton';
import { EditApplicationButton } from '../../../view/components/EditApplicationButton';
import { validateEffectiveDate } from '../../../view/components/formValidators';
import {
    MenuItem,
    Page,
    PageNavigationComponent,
    QuoteLandingPage,
} from '../../../view/components/QuoteLandingPage';
import { ReferredQuoteOnDemandNavigation } from '../../../view/components/ReferredQuoteOnDemandNavigation';
import { PCoMLQuote, PCoMLQuoteOptions } from '../../entities/PCoMLQuote';
import { ErrorCode as PCoMLErrorCode, isStateUnlicensedError } from '../../errors';
import { GenerateQuoteFileUrl } from '../../useCases/GenerateQuoteFileUrl';
import { PurchasePCoML } from '../../useCases/PurchasePCoML';
import { UpdateQuote } from '../../useCases/UpdateQuote';
import { renewalQuoteDocumentsPublishDetails } from '../config';
import { BrokerPcomlReferredNavigation } from './BrokerPCoMLReferredNavigation';
import { PCoMLCoveragePage } from './coveragePage/PCoMLCoveragePage';
import { PCOMLQuoteSummary } from './PCoMLQuoteSummary';
import { QuoteBreakdown } from './QuoteBreakdown';
import { QuoteSignature } from './signature/QuoteSignature';
import { GlobalConfig } from '../../../../config/types/GlobalConfig';
import { EPLIStandaloneInCaliforniaModal } from '../../../view/components/EPLIStandaloneInCaliforniaModal';

export interface PCoMLQuoteOptionsFormData {
    // Used for cross-fields validation with it's validation message displayed
    // in specific location of the page.
    // We need any type, other than undefined, to allow custom cross-fields
    // validation to kick in.
    coverage: 'any but undefined';

    isDnoSelected: boolean;
    dnoLimit: number;
    dnoRetention: number;

    isEplSelected: boolean;
    eplLimit: number;
    eplRetention: number;

    startDate: Date;

    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;

    brokerSignature: boolean;
}

const formDataToQuoteOptions = (formData: PCoMLQuoteOptionsFormData): PCoMLQuoteOptions => ({
    effectiveDate: formData.startDate,
    dno: {
        limit: USD(formData.dnoLimit),
        retention: USD(formData.dnoRetention),
        isSelected: formData.isDnoSelected,
        inShoppingCoverageList: true,
    },
    epl: {
        limit: USD(formData.eplLimit),
        retention: USD(formData.eplRetention),
        isSelected: formData.isEplSelected,
        inShoppingCoverageList: true,
    },
});

const isQuoteOKToSign = (quote: PCoMLQuote | undefined): boolean => {
    return quote?.status !== 'referred' && !quote?.applicationInfo?.existingCoverageReferral;
};

const makeTitle = (quote: PCoMLQuote | undefined): string => {
    const defaultTitle = 'Business Management Insurance';
    const defaultRenewalsTitle = 'Business Management Insurance Renewal';

    if (!quote) {
        return '';
    }

    const isRenewal = quote.applicationInfo?.isRenewal;

    if (quote.options.dno.inShoppingCoverageList && quote.options.epl.inShoppingCoverageList) {
        if (isRenewal) {
            return defaultRenewalsTitle;
        }
        return defaultTitle;
    }

    if (quote.options.dno.inShoppingCoverageList && !quote.options.epl.inShoppingCoverageList) {
        if (isRenewal) {
            return (
                ShoppingCoverageCodeListMap['ShoppingCoverageCodeListDirectorsAndOfficers'] +
                ' Renewal'
            );
        }
        return ShoppingCoverageCodeListMap['ShoppingCoverageCodeListDirectorsAndOfficers'];
    }

    return isRenewal
        ? ShoppingCoverageCodeListMap['ShoppingCoverageCodeListEmploymentPractices'] + ' Renewal'
        : ShoppingCoverageCodeListMap['ShoppingCoverageCodeListEmploymentPractices'];
};

const makeAppTypeCode = (quote: PCoMLQuote | undefined): AppTypeCode | undefined => {
    if (!quote) {
        return undefined;
    }

    if (quote.options.dno.inShoppingCoverageList && quote.options.epl.inShoppingCoverageList) {
        return AppTypeCodeListESP;
    }

    if (quote.options.dno.inShoppingCoverageList && !quote.options.epl.inShoppingCoverageList) {
        return AppTypeCodeListManualDirectorsAndOfficers;
    }

    return AppTypeCodeListManualEmploymentPractices;
};

const checkHigherLimits = (quote: PCoMLQuote) => {
    const { dno, epl } = quote.options;
    return (
        (dno.isSelected && dno.limit.amount > 3000000) ||
        (epl.isSelected && epl.limit.amount > 3000000)
    );
};

const embrokerOneVerticalsMap: { [key: string]: boolean } = {
    '541211': true,
    '541350': true,
    '541110': true,
    '541613': true,
    '541620': true,
    '541612': true,
    '541611': true,
    '541618': true,
    '541614': true,
    '531210': true,
    '541213': true,
    '511210': true,
    '425110': true,
    '519130': true,
    '611430': true,
    '334418': true,
    '541714': true,
    '511212': true,
    '541513': true,
    '541519': true,
    '518210': true,
    '519190': true,
    '541430': true,
    '517311': true,
    '517312': true,
    '541420': true,
    '541511': true,
    '334419': true,
    '541715': true,
    '522320': true,
    '611699': true,
    '334413': true,
    '541515': true,
    '334417': true,
    '611610': true,
    '541330': true,
    '517410': true,
    '541512': true,
    '511140': true,
    '541490': true,
    '541713': true,
    '454110': true,
    '517919': true,
    '517911': true,
    '611710': true,
    '541690': true,
    '611691': true,
    '611620': true,
};

const isEligibleForMonolineEPL = (globalConfig?: GlobalConfig, quote?: PCoMLQuote): boolean => {
    const featureEnabled = globalConfig?.pcomlIptChangesEnabled;
    const state = quote?.applicationInfo?.userInfo.usaState;
    const naics = quote?.applicationInfo?.naics;

    if (!featureEnabled || state !== 'CA') {
        return true;
    }

    if (!naics) {
        return false;
    }

    return naics in embrokerOneVerticalsMap;
};

export const MAX_FUTURE_DAYS_ALLOWED = 90;
export interface PCoMLQuoteOptionsFormProps {
    quote: PCoMLQuote;
    abortSignal: AbortSignal;
    isBroker: boolean;
    isAdmin: boolean;
    globalConfig?: GlobalConfig;
    onUpdateQuote: (quote: PCoMLQuote) => void;
    setQuoteSummaryUrl: (url: URI) => void;
    navigate: NavigateFunction;
    onGenerateDocument: () => void;
    onDocGenError: () => void;
}

const createPCoMLQuoteOptionsForm = ({
    quote,
    abortSignal,
    isBroker,
    isAdmin,
    globalConfig,
    onUpdateQuote,
    setQuoteSummaryUrl,
    navigate,
    onGenerateDocument,
    onDocGenError,
}: PCoMLQuoteOptionsFormProps) => {
    return createForm<PCoMLQuoteOptionsFormData>({
        fields: {
            coverage: {
                type: 'hidden',
                validator: Joi.string()
                    .custom((value, { prefs: { context }, error }) => {
                        if (
                            context?.isEplSelected &&
                            !context?.isDnoSelected &&
                            !isEligibleForMonolineEPL(globalConfig, quote)
                        ) {
                            return error('custom.ineligibleMonolineEPL');
                        }

                        if (
                            context === undefined ||
                            context.isDnoSelected ||
                            context.isEplSelected
                        ) {
                            return value;
                        }

                        return error('custom.noCoverageSelected');
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'custom.noCoverageSelected':
                            return 'Please select your coverage.';
                        case 'custom.ineligibleMonolineEPL':
                            return 'EPLI coverage can only be purchased with another coverage.';
                        default:
                            return error.message;
                    }
                },
            },
            isDnoSelected: {
                type: 'text',
                validator: Joi.boolean().required(),
                triggers: {
                    change: 'update',
                },
            },
            dnoLimit: {
                type: 'select',
                validator: Joi.number().required(),
                triggers: {
                    change: 'update',
                },
            },
            dnoRetention: {
                type: 'select',
                validator: Joi.number().required(),
                triggers: {
                    change: 'update',
                },
            },
            isEplSelected: {
                type: 'text',
                validator: Joi.boolean().required(),
                triggers: {
                    change: 'update',
                },
            },
            eplLimit: {
                type: 'select',
                validator: Joi.number().required(),
                triggers: {
                    change: 'update',
                },
            },
            eplRetention: {
                type: 'select',
                validator: Joi.number().required(),
                triggers: {
                    change: 'update',
                },
            },
            startDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        if (quote?.applicationInfo?.isRenewal) {
                            return value;
                        }
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            isAdmin,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';

                        default:
                            return error.message;
                    }
                },
                triggers: {
                    change: 'update',
                },
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return (
                        isBroker || value || helpers.error('agreementToConductSignature.invalid')
                    );
                }),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
                }),
            },
            brokerSignature: {
                type: 'hidden',
                validator: Joi.boolean().custom((value, helpers) => {
                    return !isBroker || value || helpers.error('brokerSignature.invalid');
                }),
            },
        },
        actions: {
            downloadSignaturePacket: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }
                    return await execute(GetSignaturePacketFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: ['startDate'],
            },
            downloadGTC: {
                action: async () => {
                    const documentDownload = fromQuoteToPCoMLDownloadDocument(quote);
                    const tempLink: HTMLElement = document.createElement('a');
                    tempLink.setAttribute('href', documentDownload.url);
                    tempLink.setAttribute('download', documentDownload.fileName);
                    tempLink.setAttribute('target', '_blank');
                    tempLink.click();
                    return Success();
                },
                fields: ['startDate'],
            },
            update: {
                action: async (formData: PCoMLQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    const quoteOptions = formDataToQuoteOptions(formData);

                    return await execute(UpdateQuote, {
                        quote,
                        quoteOptions,
                        abortSignal,
                    });
                },
                fields: [
                    'coverage',
                    'startDate',
                    'dnoLimit',
                    'dnoRetention',
                    'isDnoSelected',
                    'eplLimit',
                    'eplRetention',
                    'isEplSelected',
                ],
            },
            sign: {
                action: async (formData: PCoMLQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    if (
                        (isBroker && formData.brokerSignature) ||
                        (formData.agreementToConductSignature && formData.warrantyAndFraudSignature)
                    ) {
                        return await execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: [
                    'coverage',
                    'startDate',
                    'dnoLimit',
                    'dnoRetention',
                    'isDnoSelected',
                    'eplLimit',
                    'eplRetention',
                    'isEplSelected',
                ],
            },
            requestHigherLimits: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(RequestHigherLimits, {
                        applicationId: quote.applicationId,
                        isSubmit: true,
                    });
                },
                fields: ['startDate'],
            },
            downloadQuote: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(GenerateQuoteFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: [
                    'coverage',
                    'startDate',
                    'dnoLimit',
                    'dnoRetention',
                    'isDnoSelected',
                    'eplLimit',
                    'eplRetention',
                    'isEplSelected',
                ],
            },
            editApplication: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(EditApplication, {
                        applicationId: quote.applicationId,
                        isRenewal: quote.applicationInfo?.isRenewal,
                    });
                },
                fields: ['startDate'],
            },
            default: async () => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                return await execute(PurchasePCoML, { quote });
            },
        },
        onSuccess: async (value, action) => {
            switch (action) {
                case 'downloadSignaturePacket':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'update':
                    onUpdateQuote(value.quote);
                    break;
                case 'sign':
                    onUpdateQuote(value.quote);
                    break;
                case 'downloadQuote':
                    setQuoteSummaryUrl(value.documentUrl);
                    onGenerateDocument();
                    break;
                case 'editApplication':
                    navigate(
                        URI.build('/shopping/application/basic-info', {
                            applicationId: quote?.applicationId,
                        }),
                    );
                    break;
                case 'default': {
                    if (isBroker) {
                        navigate(
                            URI.build('/broker/dashboard', {
                                applicationId: quote?.applicationId,
                            }),
                        );
                    } else {
                        const selectedCoverages: Array<string> = [];

                        if (quote?.options.dno.isSelected) {
                            selectedCoverages.push('Directors and Officers');
                        }

                        if (quote?.options.epl.isSelected) {
                            selectedCoverages.push('Employment Practices Liability');
                        }

                        const purchasedAppType = PurchasedAppType.create({
                            appTypes: selectedCoverages,
                        });

                        let appTypes: string | undefined;

                        if (isOK(purchasedAppType)) {
                            const result = PurchasedAppType.encode(purchasedAppType.value);
                            if (isOK(result)) {
                                appTypes = result.value;
                            }
                        }

                        if (!quote) {
                            return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                        }

                        navigate(
                            URI.build('/summary', {
                                applicationId: quote?.applicationId,
                                appTypes,
                                quotingEngine: QuotingEnginePCoML,
                                ...value,
                            }),
                        );
                    }
                    break;
                }
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            if (!errors.every(isRecoverableError)) {
                navigateToErrorPage(navigate, errors);
            } else if (isBroker && errors.some(isStateUnlicensedError)) {
                navigateToBrokerStateUnlicensed();
            } else if (errors.some(isDocGenError)) {
                onDocGenError();
            }
            return;
        },
    });

    function navigateToBrokerStateUnlicensed() {
        navigate(
            URI.build('/broker/dashboard', {
                modal: 'stateUnlicensed',
            }),
        );
    }
};

const fromQuoteToPCoMLDownloadDocument = (quote: PCoMLQuote): { url: string; fileName: string } => {
    const url = getUrlBasedOnSubmittedDate(quote);
    const fileName = PCoGeneralTermsAndConditionsName;

    return {
        url,
        fileName,
    };
};

const getUrlBasedOnSubmittedDate = (quote: PCoMLQuote): string => {
    const newInsurerDocumentsReleaseDate = quote.applicationInfo?.newInsurerDocumentsReleaseDate;
    const submittedAt = quote.applicationInfo?.submittedAt;

    if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
        if (
            isSubmissionDateAfterPublishDate(
                submittedAt,
                newInsurerDocumentsReleaseDate,
                quote.applicationInfo?.userInfo.usaState,
                quote.applicationInfo?.isRenewal,
            )
        ) {
            return PCoGeneralTermsAndConditionsNewInsurerURL;
        }
    }
    return PCoGeneralTermsAndConditionsURL;
};

const isSubmissionDateAfterPublishDate = (
    submittedAt: Date,
    newInsurerDocumentsReleaseDate: Date,
    state?: Nullable<State>,
    isRenewal?: boolean,
): boolean => {
    let daysToPublishByState = 0;

    if (state !== undefined && state !== null && isRenewal) {
        daysToPublishByState = renewalQuoteDocumentsPublishDetails[state].daysToPublish;
    }

    return isDateAfter(submittedAt, addDays(newInsurerDocumentsReleaseDate, daysToPublishByState));
};

function isRecoverableError(error: ErrorLike) {
    return (
        isError(PCoMLErrorCode.StateAgentLicenseNotFound, error) ||
        isError(QuoteErrorCode.DocGenFailed, error)
    );
}

export interface PCoMLQuoteLandingPageProps {
    applicationId: UUID;
    initialQuote: PCoMLQuote;
}

interface PCoMLQuoteLandingPageState {
    quote: PCoMLQuote;
    quoteFileUrl?: URI;
}

export function PCoMLQuoteLandingPage({ applicationId, initialQuote }: PCoMLQuoteLandingPageProps) {
    const [state, setState] = useState<PCoMLQuoteLandingPageState>({
        quote: initialQuote,
        quoteFileUrl: undefined,
    });
    const [higherLimitsApproved, setHigherLimitsApproved] = useState<boolean | undefined>(
        undefined,
    );

    const { activeSession, globalConfig } = useContext(AppContext);
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const isBroker = hasRole(activeSession, 'broker');
    const isAdmin = hasRole(activeSession, 'admin');

    const { result: activeOrganization } = useUseCase(GetActiveOrganizationProfile);
    const documentModal = useModal();
    const docGenErrorModal = useModal();
    const epliStandaloneInCaliforniaModal = useModal();

    useEffect(() => {
        if (isBroker && activeOrganization && isOK(activeOrganization)) {
            setHigherLimitsApproved(
                activeOrganization.value.organization.higherLimitsApproved || false,
            );
        }
    }, [activeOrganization, isBroker]);

    const handleBindCoverageEvent = useCallback(() => {
        execute(CalculateSKU, {
            event: 'quote',
            applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: state.quote.totalPayable,
                applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: state.quote.applicationInfo?.isRenewal ?? false,
            };
            eventBus.publish(event);
        });
    }, [applicationId, eventBus, state.quote]);

    const handleUpdateQuote = useCallback((quote: PCoMLQuote) => {
        setState((prevState: PCoMLQuoteLandingPageState) => ({
            ...prevState,
            quote: quote,
            quoteFileUrl: undefined,
        }));
    }, []);

    const handleSetQuoteSummaryUrl = useCallback((url: URI) => {
        setState((prevState: PCoMLQuoteLandingPageState) => ({
            ...prevState,
            quoteFileUrl: url,
        }));
    }, []);

    useEffect(() => {
        let isCanceled = false;
        if (
            isBroker &&
            higherLimitsApproved === false &&
            state.quote &&
            checkHigherLimits(state.quote)
        ) {
            execute(ReferQuote, { quote: state.quote }).then((result) => {
                if (isCanceled) {
                    return;
                }
                if (isOK(result)) {
                    handleUpdateQuote(result.value.quote as PCoMLQuote);
                }
            });
        }
        return () => {
            isCanceled = true;
        };
    }, [isBroker, higherLimitsApproved, state.quote, handleUpdateQuote]);

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const { navigate } = useNavigation();
    const optionsForm = useMemo(
        () =>
            createPCoMLQuoteOptionsForm({
                quote: state.quote,
                abortSignal: abortController.signal,
                isBroker,
                isAdmin,
                globalConfig,
                onUpdateQuote: handleUpdateQuote,
                setQuoteSummaryUrl: handleSetQuoteSummaryUrl,
                navigate,
                onGenerateDocument: documentModal.show,
                onDocGenError: docGenErrorModal.show,
            }),
        [
            state.quote,
            isBroker,
            isAdmin,
            globalConfig,
            navigate,
            abortController.signal,
            handleUpdateQuote,
            handleSetQuoteSummaryUrl,
            documentModal.show,
            docGenErrorModal.show,
        ],
    );

    const quotePage = {
        name: 'coverage',
        fields: [
            'coverage',
            'startDate',
            'dnoLimit',
            'dnoRetention',
            'isDnoSelected',
            'eplLimit',
            'eplRetention',
            'isEplSelected',
        ],
    };
    const signPage = {
        name: 'signature',
        fields: isBroker
            ? ['brokerSignature']
            : ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };

    const isQuoteExpired = state?.quote?.daysToExpire === -1 && !isAdmin;
    const isQuoteBindable = !isQuoteExpired;
    const formPages = isQuoteBindable ? [quotePage, signPage] : [quotePage];

    const optionsWizardForm = useWizardForm(optionsForm, {
        pages: formPages as WizardPages<OpaqueForm<PCoMLQuoteOptionsFormData>>,
        initialValue: {
            coverage: 'any but undefined',
            isDnoSelected: state.quote?.options.dno.isSelected,
            dnoLimit: state.quote?.options.dno.limit.amount,
            dnoRetention: state.quote?.options.dno.retention.amount,
            isEplSelected: state.quote?.options.epl.isSelected,
            eplLimit: state.quote?.options.epl.limit.amount,
            eplRetention: state.quote?.options.epl.retention.amount,
            startDate: state.quote?.options.effectiveDate,
            agreementToConductSignature: false,
            warrantyAndFraudSignature: false,
            brokerSignature: false,
        },
    });

    const quoteStepPage: Page<PCoMLQuoteOptionsFormData, PCoMLQuote> = {
        name: 'coverage',
        component: PCoMLCoveragePage,
    };

    const quoteSignPage: Page<PCoMLQuoteOptionsFormData, PCoMLQuote> = {
        name: 'signature',
        component: isBroker ? BrokerSignaturePage : QuoteSignature,
    };

    const pages: Page<PCoMLQuoteOptionsFormData, PCoMLQuote>[] = isQuoteBindable
        ? [quoteStepPage, quoteSignPage]
        : [quoteStepPage];

    const DownloadPCoGTCItem: MenuItem<PCoMLQuoteOptionsFormData> = {
        component: DownloadGeneralTermsAndConditionsButton,
        isDisabled: optionsWizardForm.status == 'dirty',
    };

    const DownloadQuoteItem: MenuItem<PCoMLQuoteOptionsFormData> = {
        component: DownloadDocumentButton,
        fileUrl: state.quoteFileUrl,
        documentType: DocumentType.QuoteSummary,
        onGenerateFileClick: () => optionsWizardForm.trigger('downloadQuote'),
    };

    const DownloadSignaturePacket: MenuItem<PCoMLQuoteOptionsFormData> = {
        component: DownloadSignaturePacketButton,
    };

    const EditApplicationItem: MenuItem<PCoMLQuoteOptionsFormData> = {
        component: EditApplicationButton,
    };

    const menuButtons = isBroker
        ? state.quote?.status === 'referred'
            ? [EditApplicationItem]
            : [DownloadPCoGTCItem, DownloadSignaturePacket, DownloadQuoteItem, EditApplicationItem]
        : [DownloadQuoteItem];

    const navigation =
        isBroker && !isQuoteOKToSign(state.quote) ? BrokerPcomlReferredNavigation : undefined;
    const referredNavigation: PageNavigationComponent<PCoMLQuoteOptionsFormData, PCoMLQuote> =
        ReferredQuoteOnDemandNavigation;

    return (
        <React.Fragment>
            <EPLIStandaloneInCaliforniaModal
                modal={epliStandaloneInCaliforniaModal}
                appId={applicationId}
            />
            <DocGenErrorModal modal={docGenErrorModal} />
            <DocumentModal
                modal={documentModal}
                fileUrl={state.quoteFileUrl}
                documentType={DocumentType.QuoteSummary}
            />
            <QuoteLandingPage
                title={makeTitle(state.quote)}
                appTypeCode={makeAppTypeCode(state.quote)}
                applicationId={applicationId}
                quote={state.quote}
                optionsWizardForm={optionsWizardForm}
                pages={pages}
                menuItems={menuButtons}
                quoteBreakdown={QuoteBreakdown}
                quoteSummary={PCOMLQuoteSummary}
                exitUrl={cast<URI>(isBroker ? '/broker/dashboard' : '/summary')}
                pageNavigation={isQuoteBindable ? navigation : referredNavigation}
                daysToExpire={state.quote?.daysToExpire}
                handleBindCoverage={handleBindCoverageEvent}
                showEpliStandaloneInCalifornia={epliStandaloneInCaliforniaModal.show}
            />
        </React.Fragment>
    );
}
