import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, route, withData } from 'navi';
import React from 'react';
import { redirectToWhoopsPage } from '../../../view/errors';
import { ESPQuoteLandingPage } from '../../esp/view/components/ESPQuoteLandingPage';

const espWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return redirectToWhoopsPage([
            InvalidArgument({ argument: 'applicationId', value: applicationId, validator: 'UUID' }),
        ]);
    }

    return route({
        title: 'ESP quotes',
        view: <ESPQuoteLandingPage applicationId={applicationId} />,
    });
});

export const espRoutes = {
    '/': map((request) => {
        return withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            espWizardRouteHandler,
        );
    }),

    '/:page': map((request) => {
        return withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            espWizardRouteHandler,
        );
    }),
};
