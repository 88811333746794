import { map, mount, route } from 'navi';
import * as React from 'react';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions } from '../../../view/routes/Route';
import { EMBROKER } from '@app/shopping/view/routes';
import { OptimalTestQuestionnaire } from '../components/OptimalTestQuestionnaire';
import { PageNotFound } from '@embroker/shotwell/view/components/PageNotFound';

export const routes: RouteDefinitions<AppContext> = {
    '/sandbox': map(() => {
        if (process.env.NODE_ENV === 'production') {
            return route({ view: <PageNotFound /> });
        }
        return mount(subRoutes);
    }),
};

export const subRoutes = {
    '/optimal': mount({
        '/test_questionnaire': map(async (request) => {
            const questionnaireIds = request.params.questionnaireIds
                ? request.params.questionnaireIds.split(',')
                : [];

            return route({
                title: EMBROKER,
                view: <OptimalTestQuestionnaire questionnaireIds={questionnaireIds} />,
                data: { fullscreen: true },
            });
        }),
    }),
};
