import type * as APIType from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { container, injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { QuoteIdMap } from '../../quote/types/QuoteList';
import { InsuranceApplicationStatusCode } from '../../shopping/types/enums';
import { CoverageCatalog } from '../CoverageCatalog';
import { BundleQuote } from '../entities/BundleQuote';
import {
    ApplicationNotFound,
    DocumentCreationBundleTaskError,
    EnqueueReQuoteBundleTaskError,
    InvalidAnnualTechFee,
    OperationNotAllowed,
    PurchaseBundleQuoteError,
    QuoteIdMissing,
    QuoteNotFound,
    QuoteOptionsNotAllowed,
    RenewalBeforeLastEndorsementDateNotAllowed,
} from '../errors';
import { BundleCoverageType, BundleQuoteCoverageType } from '../types/BundleQuoteCoverage';
import { DocumentType } from '../types/BundleQuoteDocument';
import { BundleQuoteOptions } from '../types/BundleQuoteOptions';
import { QuestionnaireData } from '../types/BundleQuoteQuestionnaireData';
import { BundleQuoteRepository } from './index';

interface DocumentTask {
    task_id: UUID;
}

const getConfig = async (): AsyncResult<Config, InvalidArgument | OperationFailed> => {
    const globalConfigResult = await API.request('global/get_config');
    if (isErr(globalConfigResult)) {
        return handleOperationFailure(globalConfigResult);
    }

    return Success({
        omitEffectiveDateValidation: globalConfigResult.value.omit_effective_date_validation,
    });
};

// TODO - inject bundle definition on use???
@injectable()
export class APIBundleQuoteRepository implements BundleQuoteRepository {
    async getLastBundleQuote(
        applicationId: UUID,
    ): AsyncResult<BundleQuote, InvalidArgument | OperationFailed> {
        let omitEffectiveDateValidation = false;
        const getConfigResult = await getConfig();
        if (isErr(getConfigResult)) {
            container.get<Logger>(Log).error(getConfigResult);
        } else {
            omitEffectiveDateValidation = getConfigResult.value.omitEffectiveDateValidation;
        }

        const bundleApplicationResponse = await API.request('shopping/get_quote_extended', {
            app_id: applicationId,
        });
        if (isErr(bundleApplicationResponse)) {
            return Failure(OperationFailed({ errors: bundleApplicationResponse.errors }));
        }
        const bundleApplication = bundleApplicationResponse.value;
        const isBroker = bundleApplication.brokerage_id !== undefined;

        const bundleQuoteCoverageList = await buildBundleQuoteCoverageList(
            bundleApplication,
            isBroker,
            omitEffectiveDateValidation,
        );

        if (isErr(bundleQuoteCoverageList)) {
            return bundleQuoteCoverageList;
        }

        const questionnaireDataResult = JSONSerdes.deserialize(
            bundleApplication.questionnaire_data,
        );
        if (isErr(questionnaireDataResult)) {
            return Failure(
                InvalidArgument({
                    argument: 'questionnaire_data',
                    value: bundleApplication.questionnaire_data,
                }),
            );
        }

        return await buildBundleQuoteEntity(
            applicationId,
            bundleQuoteCoverageList.value.isRenewal,
            bundleQuoteCoverageList.value.coverageList,
            questionnaireDataResult.value as QuestionnaireData,
        );
    }

    async enqueueReQuoteBundleTask(
        applicationId: UUID,
        quoteOptionsMap: Map<BundleCoverageType, BundleQuoteOptions>,
    ): AsyncResult<
        UUID,
        InvalidArgument | ApplicationNotFound | OperationNotAllowed | EnqueueReQuoteBundleTaskError
    > {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const apiQuoteOptions = await toApiQuoteOptions(quoteOptionsMap);

        if (isErr(apiQuoteOptions)) {
            return apiQuoteOptions;
        }

        const enqueueReQuoteBundleTaskResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: apiQuoteOptions.value,
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });

        if (isErr(enqueueReQuoteBundleTaskResponse)) {
            const error = enqueueReQuoteBundleTaskResponse.errors[0];

            if (isAPIError(error)) {
                switch (error.details.name) {
                    case 'application_not_found':
                        return Failure(ApplicationNotFound(applicationId, error));
                    case 'operation_not_allowed':
                        return Failure(OperationNotAllowed(error));
                }
            }
            return Failure(EnqueueReQuoteBundleTaskError(error));
        }

        return Success(enqueueReQuoteBundleTaskResponse.value.task_id);
    }

    async purchaseBundleQuote(
        applicationId: UUID,
        coverageTypeQuoteIdMap: Map<BundleCoverageType, UUID>,
    ): AsyncResult<
        void,
        | ApplicationNotFound
        | OperationNotAllowed
        | QuoteOptionsNotAllowed
        | InvalidAnnualTechFee
        | QuoteIdMissing
        | QuoteNotFound
        | RenewalBeforeLastEndorsementDateNotAllowed
        | PurchaseBundleQuoteError
        | InvalidArgument
    > {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const quoteIdMap = toApiQuoteIdMap(coverageTypeQuoteIdMap);

        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });

        if (isErr(purchaseResponse)) {
            const error = purchaseResponse.errors[0];
            if (isAPIError(error)) {
                switch (error.details.name) {
                    case 'application_not_found':
                        return Failure(ApplicationNotFound(applicationId, error));
                    case 'operation_not_allowed':
                        return Failure(OperationNotAllowed(error));
                    case 'quote_options_not_allowed':
                        return Failure(QuoteOptionsNotAllowed(error));
                    case 'invalid_annual_tech_fee':
                        return Failure(InvalidAnnualTechFee(error));
                    case 'quote_id_missing':
                        return Failure(QuoteIdMissing(error));
                    case 'quote_not_found':
                        return Failure(QuoteNotFound(error));
                    case 'renewal_before_last_endorsement_date_not_allowed':
                        return Failure(RenewalBeforeLastEndorsementDateNotAllowed(error));
                }
            }
            return Failure(PurchaseBundleQuoteError(error));
        }

        return Success();
    }

    async createDocumentAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
        documentType: DocumentType,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DocumentCreationBundleTaskError> {
        const endpoint = this.getEndpointForDocumentType(documentType);
        if (!endpoint) {
            return Failure(InvalidArgument({ argument: 'documentType', value: documentType }));
        }
        const createDocumentResult = await API.request(endpoint, {
            application_id: applicationId,
            quote_id: quoteId,
        });

        if (isErr(createDocumentResult)) {
            return Failure(DocumentCreationBundleTaskError(createDocumentResult.errors[0]));
        }

        return Success((createDocumentResult.value as DocumentTask).task_id);
    }

    async submitForReview(
        applicationId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/submit_for_review', {
            application_id: applicationId,
        });

        if (isErr(response)) {
            return Failure(
                OperationFailed({
                    message: 'Submit for review request failed',
                    errors: response.errors,
                }),
            );
        }

        return Success();
    }

    private getEndpointForDocumentType(documentType: DocumentType): string | undefined {
        switch (documentType) {
            case DocumentType.QuoteDocument: {
                return 'shopping/create_quote_summary_task';
            }
            case DocumentType.ApplicationAttestation: {
                return 'shopping/create_application_document_task';
            }
            case DocumentType.ApplicationAttestationWithPapyrus: {
                return 'shopping/create_application_document_with_papyrus_task';
            }
            case DocumentType.CoverageDetails: {
                return 'shopping/create_specimen_policy_task';
            }
            default:
                return undefined;
        }
    }

    public async requestHigherLimit(
        applicationId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('shopping/request_higher_limits', {
            application_id: applicationId,
            is_submit: true,
        });

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Request higher limits failed',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }

    public async setApplicationStatus(
        applicationId: UUID,
        applicationStatus: InsuranceApplicationStatusCode,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('shopping/set_application_status', {
            id: applicationId,
            app_status: applicationStatus,
        });

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Set application status request failed',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }

    async getBundleApplicationFromBundleId(
        applicationId: UUID,
    ): AsyncResult<UUID, OperationFailed> {
        const bundleApplicationIdResponse = await API.request(
            'shopping/get_bundle_application_id',
            {
                bundle_id: applicationId,
            },
        );

        if (isErr(bundleApplicationIdResponse)) {
            return Failure(OperationFailed({ errors: bundleApplicationIdResponse.errors }));
        }

        const bundleApplicationInfo = bundleApplicationIdResponse.value;
        if (bundleApplicationInfo.application_id === undefined) {
            return Failure(OperationFailed({ message: 'application ID not found for bundle' }));
        }

        return Success(bundleApplicationIdResponse.value.application_id);
    }
}

interface Config {
    readonly omitEffectiveDateValidation: boolean;
}

export async function buildBundleQuoteEntity(
    applicationId: UUID,
    isRenewal: boolean,
    bundleQuoteCoverageList: Immutable<BundleQuoteCoverageType[]>,
    questionnaireData: QuestionnaireData,
): AsyncResult<BundleQuote, OperationFailed | InvalidArgument> {
    const effectiveDate = bundleQuoteCoverageList.reduce((accumulated, current) => {
        if (current.quote?.options.effectiveDate) {
            return current.quote?.options.effectiveDate;
        }
        return accumulated;
    }, startOfToday());

    const policyEndDateParsed = questionnaireData.current_policy_details?.start_and_end_date
        ?.policy_end_date
        ? parseISO(questionnaireData.current_policy_details.start_and_end_date.policy_end_date)
        : undefined;
    const currentPolicyEndDate =
        policyEndDateParsed && isValid(policyEndDateParsed)
            ? startOfDay(policyEndDateParsed)
            : undefined;

    const bundleQuote = await BundleQuote.create({
        applicationId: applicationId,
        effectiveDate: effectiveDate,
        organizationInfo: {
            companyName: questionnaireData.company_name,
            address: {
                addressLine1: questionnaireData.mailing_address,
                addressLine2: questionnaireData.suite ? questionnaireData.suite : null,
                city: questionnaireData.city,
                state: questionnaireData.state,
                county: questionnaireData.county ? questionnaireData.county : null,
                zip: questionnaireData.zip as ZipCode,
            },
            userInfo: {
                fullName: `${questionnaireData.first_name} ${questionnaireData.last_name}`,
                title: questionnaireData?.title ?? 'placeholder',
            },
            currentPolicyEndDate: currentPolicyEndDate,
        },
        coverageList: [...bundleQuoteCoverageList],
        isRenewal: isRenewal,
    });

    if (isErr(bundleQuote)) {
        return Failure(
            InvalidArgument({ argument: 'Bundle quote coverage', value: bundleQuote.errors }),
        );
    }
    return Success(bundleQuote.value);
}

async function toApiQuoteOptions(
    quoteOptionsMap: Map<BundleCoverageType, BundleQuoteOptions>,
): AsyncResult<APIType.QuoteOptions, InvalidArgument> {
    let apiQuoteOptions: APIType.QuoteOptions = {};

    for (const [coverageType, quoteOptions] of quoteOptionsMap.entries()) {
        const coverageDefinition = CoverageCatalog.findCoverageDefinitionByType(coverageType);
        if (!coverageDefinition) {
            return Failure(
                InvalidArgument({
                    argument: 'coverageType',
                    value: coverageType,
                }),
            );
        }

        if (CoverageCatalog.isOptionsValidType(quoteOptions, coverageType)) {
            const apiProductQuoteOptions = coverageDefinition.mapQuoteOptionsToAPI(quoteOptions);
            apiQuoteOptions = {
                ...apiQuoteOptions,
                [coverageDefinition.apiProductDesignation]: apiProductQuoteOptions,
            };
        }
    }

    return Success(apiQuoteOptions);
}

function toApiQuoteIdMap(coverageTypeQuoteIdMap: Map<BundleCoverageType, UUID>): QuoteIdMap {
    return CoverageCatalog.coverages.reduce((previous, curr) => {
        const quoteId = coverageTypeQuoteIdMap.get(curr.type);
        if (!quoteId) {
            return previous;
        }
        return {
            ...previous,
            [curr.quotingEngine]: quoteId,
        };
    }, {} as QuoteIdMap);
}
interface BundleQuoteCoverageListResult {
    coverageList: BundleQuoteCoverageType[];
    isRenewal: boolean;
}

async function buildBundleQuoteCoverageList(
    bundleApplication: Immutable<APIType.ShoppingGetQuoteExtendedResponse>,
    isBroker: boolean,
    omitEffectiveDateValidation: boolean,
): AsyncResult<BundleQuoteCoverageListResult, OperationFailed | InvalidArgument> {
    const list: Immutable<BundleQuoteCoverageType>[] = [];
    let isRenewal = false;
    for (const currentCoverage of CoverageCatalog.coverages) {
        const quoteExtended = bundleApplication.quote_extended_map[currentCoverage.quotingEngine];
        if (!quoteExtended) {
            continue;
        }

        if (quoteExtended.is_renewal) {
            isRenewal = true;
        }

        const coverage = await currentCoverage.buildCoverage(
            quoteExtended,
            isBroker,
            omitEffectiveDateValidation,
        );
        if (isErr<BundleQuoteCoverageType, OperationFailed | InvalidArgument>(coverage)) {
            return Failure(OperationFailed({ errors: coverage.errors }));
        }
        list.push(coverage.value);
    }

    return Success({
        coverageList: list,
        isRenewal: isRenewal,
    } as BundleQuoteCoverageListResult);
}
