import { ErrorCode, hasError, isErrorLike, toNativeError } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike, isOK } from '@embroker/shotwell/core/types/Result';
import { AccessDenied } from '@embroker/shotwell/view/components/AccessDenied';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { PageNotFound } from '@embroker/shotwell/view/components/PageNotFound';
import { PageNotFoundLoggedOut } from '@embroker/shotwell/view/components/PageNotFoundLoggedOut';
import { WebserviceUnavailable } from '@embroker/shotwell/view/components/WebserviceUnavailable';
import { ErrorContextStore } from '@embroker/shotwell/view/ErrorContext';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { PageLayout, Spinner, usePageLayout, DndProvider } from '@embroker/ui-toolkit/v2';
import * as Sentry from '@sentry/browser';
import { Matcher, NotFoundError } from 'navi';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Router, useCurrentRoute, View } from 'react-navi';
import { HelmetProvider } from 'react-navi-helmet-async';
import { bootstrapApplication } from '../bootstrap';
import {
    NotificationsContextStore,
    NotificationsContext,
} from '../notifications/NotificationsContext';
import { GetServerEnums } from '../serverEnums/useCases/GetServerEnums';
import { AppContext, AppContextStore } from './AppContext';
import { Header } from './components/Header';
import { Navigation } from './components/Navigation';
import { NavigationalLogo } from './components/NavigationalLogo';
import { GetGlobalConfig } from '../config/useCases/GetGlobalConfigUseCase';
import { useSetupAdminMessageSubscriptions } from './hooks/useSetupAdminMessageSubscription';

export interface AppProps {
    context: AppContext;
    routes: Matcher<object>;
}

function RootView() {
    const pageLayoutState = usePageLayout({
        isSlideoutDismissable: true,
        logo: <NavigationalLogo />,
    });

    const pageLayoutContext = useMemo(() => {
        const {
            setHeader,
            setSidebar,
            setNavigation,
            toggleNavigationState,
            setSlideout,
            closeSlideout,
            setFullscreen,
            setIndicationCount,
            setBackgroundColor,
            resetBackgroundColor,
            navigationState,
        } = pageLayoutState;
        return {
            setHeader,
            setSidebar,
            setNavigation,
            toggleNavigationState,
            setSlideout,
            closeSlideout,
            setFullscreen,
            setIndicationCount,
            setBackgroundColor,
            resetBackgroundColor,
            navigationState,
        };
    }, [pageLayoutState]);
    const { setIndicationCount } = pageLayoutContext;

    const route = useCurrentRoute();
    const { activeSession } = useContext(AppContext);
    const { unreadNotificationCount } = useContext(NotificationsContext);
    const { result: globalConfigResult } = useUseCase(GetGlobalConfig);

    useEffect(() => {
        setIndicationCount(unreadNotificationCount);
    }, [unreadNotificationCount, setIndicationCount]);

    useEffect(() => {
        pageLayoutContext.setFullscreen(route.data.fullscreen === true);
    }, [route.data.fullscreen, pageLayoutContext]);

    useEffect(() => {
        AppContextStore.update(pageLayoutContext);
        if (globalConfigResult !== undefined && isOK(globalConfigResult)) {
            AppContextStore.update({
                globalConfig: globalConfigResult.value.config,
            });
        }
    }, [pageLayoutContext, globalConfigResult]);

    return (
        <React.Fragment>
            <Header />
            <Navigation />
            <PageLayout {...pageLayoutState}>
                {route?.error?.status === 404 ? (
                    activeSession.isAuthenticated ? (
                        <PageNotFound />
                    ) : (
                        <PageNotFoundLoggedOut />
                    )
                ) : (
                    <View />
                )}
            </PageLayout>
        </React.Fragment>
    );
}

class ErrorBoundary extends React.Component<{}, { error: Nullable<Error> }> {
    public static getDerivedStateFromError(error: Error) {
        if (!(error instanceof NotFoundError)) {
            Sentry.withScope((scope) => {
                let nativeError;
                if (!(error instanceof Error) && isErrorLike(error)) {
                    nativeError = toNativeError(error);
                } else {
                    nativeError = error;
                }
                Sentry.captureException(nativeError);
            });
        }
        return { error };
    }
    public constructor(props: {}) {
        super(props);
        this.state = { error: null };
    }
    public render() {
        if (this.state.error == null) {
            return this.props.children;
        }

        if (this.state.error.hasOwnProperty('code')) {
            if (hasError([this.state.error as ErrorLike], [ErrorCode.NetworkFailure])) {
                return <Spinner />;
            }

            if (hasError([this.state.error as ErrorLike], [ErrorCode.ServerError])) {
                return <WebserviceUnavailable />;
            }

            if (hasError([this.state.error as ErrorLike], [ErrorCode.NotAllowed])) {
                return <AccessDenied />;
            }
        }

        return <ErrorPage errors={[this.state.error as ErrorLike]} />;
    }
}

function App({ routes, context: initialContext }: AppProps) {
    const [appContext, setAppContext] = useState(initialContext);
    useSetupAdminMessageSubscriptions();
    useEffect(() => AppContextStore.subscribe(setAppContext), []);

    const [notificationContext, setNotificationContext] = useState({
        ...NotificationsContextStore.context,
    });
    useEffect(() => NotificationsContextStore.subscribe(setNotificationContext), []);

    const [errorContext, setErrorContext] = useState({ ...ErrorContextStore.context });
    useEffect(() => ErrorContextStore.subscribe(setErrorContext), []);
    useEffect(() => {
        if (errorContext.error) {
            throw errorContext.error;
        }
    }, [errorContext.error]);

    // We must fetch enums once before application is even loaded because it is a very data heavy operation
    const { isLoading } = useUseCase(GetServerEnums);

    if (isLoading) {
        return null;
    }

    return (
        <HelmetProvider>
            <Router context={appContext} routes={routes}>
                <Suspense fallback={null}>
                    <AppContext.Provider value={appContext}>
                        <NotificationsContext.Provider value={notificationContext}>
                            <DndProvider>
                                <RootView />
                            </DndProvider>
                        </NotificationsContext.Provider>
                    </AppContext.Provider>
                </Suspense>
            </Router>
        </HelmetProvider>
    );
}

function renderApplication({ routes, context }: AppProps) {
    ReactDOM.render(
        <ErrorBoundary>
            <App context={context} routes={routes} />
        </ErrorBoundary>,
        document.getElementById('root'),
    );
}

bootstrapApplication().then(renderApplication);
