import React from 'react';
import { HeroBanner, HeroBannerProps, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { AppTypeCode } from '@app/shopping/types/enums';
import { BrokerOrganizationName } from '@app/quote/view/brokerQuotePages/BrokerOrganizationNameSection';
import { CoverageIcon } from '@app/policy/view/components/CoverageIcon.view';
import { QuoteExpirationLabel } from './QuoteExpirationLabel.view';

export interface CoverageHeroBannerProps {
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    appTypeCode?: AppTypeCode;
    showBrokerOrganizationName: boolean;
    daysToExpire?: number;
    children?: React.ReactNode;
}

export type CoverageHeroBannerComponentProps = CoverageHeroBannerProps &
    Omit<HeroBannerProps, 'children' | 'message'>;

export const CoverageHeroBanner = ({
    title = 'Your Customizable Quote',
    subTitle,
    appTypeCode,
    showBrokerOrganizationName = false,
    daysToExpire,
    ...props
}: CoverageHeroBannerComponentProps) => {
    return (
        <HeroBanner
            {...props}
            message={
                daysToExpire !== undefined ? (
                    <QuoteExpirationLabel daysToExpire={daysToExpire} />
                ) : null
            }
        >
            <StackLayout>
                {appTypeCode && (
                    <div>
                        <CoverageIcon appTypeCode={appTypeCode} size="large" />
                    </div>
                )}
                <Text as="h1" style="heading 1">
                    {title}
                </Text>
                {subTitle && (
                    <Text as="span" style="heading 5" color="ui-400">
                        {subTitle}
                    </Text>
                )}
                {showBrokerOrganizationName && <BrokerOrganizationName />}
            </StackLayout>
        </HeroBanner>
    );
};
