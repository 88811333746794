import { Card, CardLayout, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { PaymentsUpdateBillingInfo } from '@app/payments/view/components/PaymentsUpdateBilling';

export function NoPaymentsDuePage() {
    return (
        <StackLayout gap="24">
            <Card center>
                <Card.Header singleColumn />
                <Card.Body singleColumn>
                    <Text style="body 1">You have no payments due at this time.</Text>
                </Card.Body>
                <Card.Footer singleColumn />
            </Card>
            <CardLayout>
                <CardLayout.Header>
                    <Text>
                        Don't see your invoice? If it was paid before 10/01/2018 we will not have a
                        record of it here. <TextButton href="/support">Contact us</TextButton> for
                        the record or for more information.
                    </Text>
                </CardLayout.Header>
            </CardLayout>
            <hr />
            <PaymentsUpdateBillingInfo />
        </StackLayout>
    );
}
