import { container, inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingStepType, UserOnboardingSubStepType } from '../types/UserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { NAICS_CODE_TO_VERTICAL, TECH_NAICS_CODES_REQUIRE_REFINEMENT } from '../types/enums';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';
import { LegacyGrowthBookExperimentationService } from '@app/experimentation/services/LegacyGrowthBookExperimentationService';
import { GetUserOnboardingDetails } from './GetUserOnboardingDetails';
import { isDeepLinkCoverageInTechBundle } from './GetOnboardingRedirect';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

export const NaicsRefinementTypes = ['nonTechConsultant', 'accounting'] as const;
type NaicsRefinementTypesListType = typeof NaicsRefinementTypes;
export type RefinementQuestionType = NaicsRefinementTypesListType[number];

interface OnboardingStepDefinition {
    stepType: UserOnboardingStepType;
    isNotRequired?: (data: OnboardingPrefillQuestionnaireData, redirectUrl?: string) => boolean;
}

export interface GetUserOnboardingStepRequest {
    currentStep: Nullable<UserOnboardingStepType>;
    questionnaireData?: OnboardingPrefillQuestionnaireData;
    progessType: 'forward' | 'backward';
}

export type GetUserOnboardingStepResponse = Nullable<{
    stepType: NonNullable<UserOnboardingStepType>;
}>;
export interface GetUserOnboardingStep extends UseCase {
    execute(
        request: GetUserOnboardingStepRequest,
    ): AsyncResult<GetUserOnboardingStepResponse, never>;
}

const NON_TECH_NAICS_CODES_REQUIRE_REFINEMENT = [
    '541611',
    '541612',
    '541613',
    '541614',
    '541618',
    '541620',
];

const ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT = ['541219', '541214'];

const ACCOUNTING_DNB_CODES_REQUIRE_REFINEMENT = [
    ...ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT,
    '541211',
    '541213',
];

@injectable()
class GetUserOnboardingStepUseCase extends UseCase implements GetUserOnboardingStep {
    public static type = Symbol('Global/GetUserOnboardingStep');

    /**
     * constructor for the GetUserOnboardingStep use case
     * @param eventBus An event bus this Use Case will publish events to.
     * @param entityTypeRepository is the repository used to fetch globaly available data
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetUserOnboardingDetails.type)
        private getUserOnboardingDetails: GetUserOnboardingDetails,
    ) {
        super(eventBus);
    }

    public async execute(
        request?: GetUserOnboardingStepRequest,
    ): AsyncResult<GetUserOnboardingStepResponse, never> {
        const { currentStep, progessType = 'forward', questionnaireData } = request || {};

        const getUserOnboardingDetailsResp = await this.getUserOnboardingDetails.execute();
        const redirectUrl = isOK(getUserOnboardingDetailsResp)
            ? getUserOnboardingDetailsResp.value?.redirectUrl
            : undefined;

        const userOnboardingQuestionnaireData = questionnaireData || {};
        const nextStep = this.findNextEligibleOnboardingStep({
            userOnboardingQuestionnaireData,
            progessType,
            currentStep,
            redirectUrl,
        });

        if (nextStep) {
            return Success({ stepType: nextStep });
        }

        return Success(null);
    }

    private findNextEligibleOnboardingStep(props: {
        userOnboardingQuestionnaireData: OnboardingPrefillQuestionnaireData;
        progessType: 'forward' | 'backward';
        currentStep?: UserOnboardingStepType;
        redirectUrl?: string;
    }): UserOnboardingStepType | null {
        const {
            userOnboardingQuestionnaireData,
            progessType = 'forward',
            currentStep,
            redirectUrl,
        } = props;
        const orderedOnboardingStepDefinition =
            progessType === 'forward'
                ? OnboardingStepDefinition
                : OnboardingStepDefinition.slice().reverse();

        const requiredSteps = orderedOnboardingStepDefinition.filter((step) => {
            if (!step.isNotRequired) return true;
            return !step.isNotRequired(userOnboardingQuestionnaireData, redirectUrl);
        });

        const currentIndex = requiredSteps.findIndex((step) => step.stepType === currentStep);

        if (currentIndex === -1) {
            return requiredSteps[0].stepType;
        }

        if (currentIndex === requiredSteps.length - 1) {
            return null;
        }

        return requiredSteps[currentIndex + 1].stepType;
    }
}

export const GetUserOnboardingStep: UseCaseClass<GetUserOnboardingStepUseCase> =
    GetUserOnboardingStepUseCase;

function getVerticalRefinementType(
    questionnaireData: OnboardingPrefillQuestionnaireData,
    redirectUrl?: string,
): UserOnboardingSubStepType | undefined {
    const { onb_naics_code, provides_tech_service_for_fee } = questionnaireData;
    const vertical = onb_naics_code && NAICS_CODE_TO_VERTICAL[onb_naics_code];
    if (
        vertical === 'LawFirm' &&
        !container
            .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
            .getFeatureValue('law-vertical-product-page-2-vs-4-products', false)
    ) {
        return 'lawVerticalRefinement';
    }

    const vettingRequired = isVettingRequired(onb_naics_code);
    const isOneByEmbrokerEligable =
        vertical === 'TechCompanies' &&
        (!vettingRequired || (vettingRequired && provides_tech_service_for_fee)) &&
        (!redirectUrl || isDeepLinkCoverageInTechBundle(redirectUrl));

    if (
        vertical === 'TechCompanies' &&
        (!vettingRequired || (vettingRequired && provides_tech_service_for_fee)) &&
        isOneByEmbrokerEligable &&
        container
            .get<LegacyGrowthBookExperimentationService>(LegacyGrowthBookExperimentationService)
            .getFeatureValue('tech-vertical-one-by-embroker', false)
    ) {
        return 'staffDetailsPage';
    }
}

export function getNaicsRefinementType(
    questionnaireData: OnboardingPrefillQuestionnaireData,
): RefinementQuestionType | undefined {
    const { onb_naics_code = null } = questionnaireData;

    if (isNonTechRefinementRequired(onb_naics_code)) {
        return 'nonTechConsultant';
    }

    if (isAccountingRefinementRequired(questionnaireData)) {
        return 'accounting';
    }
    return undefined;
}

export function isVettingRequired(naicsCode?: string) {
    return typeof naicsCode === 'string' && TECH_NAICS_CODES_REQUIRE_REFINEMENT.includes(naicsCode);
}

export function isNonTechRefinementRequired(naics: Nullable<string>): boolean {
    const nonTechRefinementRequired = Boolean(
        NON_TECH_NAICS_CODES_REQUIRE_REFINEMENT.find((naicsCode) => naicsCode === naics),
    );
    return nonTechRefinementRequired;
}

export function isAccountingRefinementRequired(
    questionnaireData: OnboardingPrefillQuestionnaireData,
): boolean {
    const { onb_naics_code, dnb_naics_code } = questionnaireData;

    // If the submitted naics value is one of the codes in the NAICS_CODES_REQUIRE_REFINEMENT
    // We ask the user a refinement question
    const naicsRequiresRefinement = Boolean(
        ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT.find((naicsCode) => naicsCode === onb_naics_code),
    );

    // If the submitted naics value is one of the codes in the DNB_CODES_REQUIRE_REFINEMENT
    // ie if the naics input is prepopulated by DnB and the user submits the same code
    // We ask the user a refinement question
    const dnbNaicsRequiresRefinement =
        onb_naics_code === dnb_naics_code &&
        ACCOUNTING_DNB_CODES_REQUIRE_REFINEMENT.some((naicsCode) => naicsCode === onb_naics_code);

    return naicsRequiresRefinement || dnbNaicsRequiresRefinement;
}

export const OnboardingStepDefinition: OnboardingStepDefinition[] = [
    { stepType: 'businessLocation' },
    { stepType: 'naicsConfirmation' },
    {
        stepType: 'naicsRefinement',
        isNotRequired: (data) => getNaicsRefinementType(data) === undefined,
    },
    {
        stepType: 'vettingRefinement',
        isNotRequired: (data) => !isVettingRequired(data.onb_naics_code),
    },
    {
        stepType: 'industryRefinement',
        isNotRequired: (data, redirectUrl) =>
            getVerticalRefinementType(data, redirectUrl) === undefined,
    },
];
