import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { Failure, Success, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { OpaqueForm, createForm } from '@embroker/shotwell/view/hooks/useForm';
import { useModal } from '@embroker/ui-toolkit/v2';
import { startOfToday } from 'date-fns/esm';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { AppTypeCodeListCNABOP, QuotingEngineCNABOP } from '../../../../shopping/types/enums';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { navigateToErrorPage } from '../../../../view/errors';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { WizardPages, useWizardForm } from '../../../../view/hooks/useWizardForm';
import { QuoteBindCoverage } from '../../../entities/Quote';
import { Signature } from '../../../types/Signature';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import {
    Page,
    PageNavigationComponent,
    QuoteLandingPage,
} from '../../../view/components/QuoteLandingPage';
import { QuoteOnDemandNavigation } from '../../../view/components/QuoteOnDemandNavigation';
import { ReferredQuoteOnDemandNavigation } from '../../../view/components/ReferredQuoteOnDemandNavigation';
import { validateEffectiveDate } from '../../../view/components/formValidators';
import { CNAQuote } from '../../entities/CNAQuote';
import { GeneralLiabilityLimit, StopGapLiabilityLimit } from '../../types/CNAQuoteOptions';
import { PurchaseCNA } from '../../useCases/PurchaseCNA';
import { QuoteCNA } from '../../useCases/QuoteCNA';
import { SubmitCNAQuoteForReview } from '../../useCases/SubmitCNAQuoteForReview';
import { CNACoveragePage } from './CNACoveragePage';
import { CNASignaturePage } from './CNASignaturePage';
import { QuoteDetails } from './QuoteDetails';
import { SuccessModal } from './SuccessModal';

export interface CNAQuoteOptionsFormData {
    buildingValue: number;
    contentsValue: number;
    propertyDeductible: number;
    windHailDeductible: number;
    generalLiabilityLimit: string;
    stopGapLiabilityLimit: string;
    hiredAndNonOwnedAutoLimit: number;
    enhancedCoverage: boolean;
    startDate: Date;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
}

const MAX_FUTURE_DAYS_ALLOWED = 90;

const createCNAQuoteOptionsForm = (
    quote: CNAQuote | undefined,
    setQuote: (quote: CNAQuote) => void,
    setSignature: (signature: Signature) => void,
    navigate: NavigateFunction,
    isAdmin: boolean,
    showSuccessModal: () => void,
) => {
    return createForm<CNAQuoteOptionsFormData>({
        fields: {
            enhancedCoverage: {
                type: 'checkbox',
                validator: Joi.boolean().required(),
            },
            stopGapLiabilityLimit: {
                type: 'select',
                validator: Joi.string().required(),
            },
            generalLiabilityLimit: {
                type: 'select',
                validator: Joi.string().required(),
            },
            hiredAndNonOwnedAutoLimit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            windHailDeductible: {
                type: 'select',
                validator: Joi.number().required(),
            },
            propertyDeductible: {
                type: 'select',
                validator: Joi.number().required(),
            },
            contentsValue: {
                type: 'select',
                validator: Joi.number().required(),
            },
            buildingValue: {
                type: 'select',
                validator: Joi.number().required(),
            },
            startDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            isAdmin,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        default:
                            return error.message;
                    }
                },
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().equal(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().equal(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
        },
        actions: {
            update: {
                action: async (formData: CNAQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(QuoteCNA, {
                        applicationId: quote.applicationId,
                        cnaQuoteOptions: {
                            effectiveDate: formData.startDate,
                            buildingLimit: Money.tryFromFloat(formData.buildingValue),
                            contentsLimit: Money.tryFromFloat(formData.contentsValue),
                            tenantsAndBettermentsLimit: quote.options.tenantsAndBettermentsLimit,
                            propertyDeductible: Money.tryFromFloat(formData.propertyDeductible),
                            windHailDeductible: Money.tryFromFloat(formData.windHailDeductible),
                            generalLiabilityLimit:
                                formData.generalLiabilityLimit as GeneralLiabilityLimit,
                            hiredNonOwnedAutoLimit: Money.tryFromFloat(
                                formData.hiredAndNonOwnedAutoLimit,
                            ),
                            stopGapLiabilityLimit:
                                formData.stopGapLiabilityLimit as StopGapLiabilityLimit,
                            enhanced: formData.enhancedCoverage,
                            cnaWarnings: quote.options.cnaWarnings,
                        },
                        abortSignal: new AbortController().signal,

                        // We've increased timeout of a request to CNA to 90
                        // seconds, and there are two requests for a quote, so
                        // 180 seconds at minimum
                        maxPollingRetries: 190,
                        pollingRetryIntervalInMilliseconds: 1000,
                    });
                },
                fields: [
                    'startDate',
                    'buildingValue',
                    'contentsValue',
                    'propertyDeductible',
                    'windHailDeductible',
                    'hiredAndNonOwnedAutoLimit',
                    'generalLiabilityLimit',
                    'stopGapLiabilityLimit',
                    'enhancedCoverage',
                ],
            },
            sign: {
                action: async (formData: CNAQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    setSignature({
                        transfers: formData.agreementToConductSignature,
                        warranty: formData.warrantyAndFraudSignature,
                    });

                    if (
                        formData.agreementToConductSignature &&
                        formData.warrantyAndFraudSignature
                    ) {
                        return execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: [
                    'startDate',
                    'buildingValue',
                    'contentsValue',
                    'propertyDeductible',
                    'windHailDeductible',
                    'hiredAndNonOwnedAutoLimit',
                    'generalLiabilityLimit',
                    'stopGapLiabilityLimit',
                    'enhancedCoverage',
                ],
            },
            requestBind: async () => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                return await execute(SubmitCNAQuoteForReview, {
                    applicationId: quote.applicationId,
                });
            },
            default: async () => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                return await execute(PurchaseCNA, {
                    applicationId: quote.applicationId,
                    quoteId: quote.id,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'update':
                    setQuote(value);
                    setSignature({
                        transfers: false,
                        warranty: false,
                    });
                    break;
                case 'sign':
                    setQuote(value.quote as CNAQuote);
                    break;
                case 'requestBind':
                    showSuccessModal();
                    break;
                case 'default': {
                    const selectedCoverages: Array<string> = ['Business Owners Policy'];
                    const purchasedAppType = PurchasedAppType.create({
                        appTypes: selectedCoverages,
                    });

                    let appTypes: string | undefined;

                    if (isOK(purchasedAppType)) {
                        const result = PurchasedAppType.encode(purchasedAppType.value);
                        if (isOK(result)) {
                            appTypes = result.value;
                        }
                    }

                    navigate(
                        URI.build('/summary', {
                            applicationId: quote?.applicationId,
                            quotingEngine: QuotingEngineCNABOP,
                            appTypes,
                            ...value,
                        }),
                    );
                    break;
                }
                default:
                    break;
            }
        },
        onFailure(errors, action) {
            switch (action) {
                case 'sign':
                case 'requestBind':
                    navigateToErrorPage(navigate, errors);
                    break;
                default:
                    // Update and default are handled by displaying error messages on the same page
                    break;
            }
        },
    });
};

export interface CNAQuoteLandingPageProps {
    applicationId: UUID;
    cnaQuote: CNAQuote;
}

export function CNAQuoteLandingPage({ applicationId, cnaQuote }: CNAQuoteLandingPageProps) {
    const [quote, setQuote] = useState<CNAQuote>(cnaQuote);
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const [signature, setSignature] = useState<Signature>({
        transfers: cnaQuote.status === 'signed',
        warranty: cnaQuote.status === 'signed',
    });
    const { navigate } = useNavigation();

    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');

    const handleBindCoverageEvent = useCallback(() => {
        if (isAdmin === true) {
            execute(CalculateSKU, {
                event: 'quote',
                applicationId,
            }).then((skuResult) => {
                const event: QuoteBindCoverage = {
                    origin: 'Quote',
                    name: 'BindCoverage',
                    totalPremium: quote.totalPayable,
                    applicationId,
                    createdAt: new Date(Date.now()),
                    id: UUID.create(),
                    isRenewal: quote.isRenewal,
                    sku: isOK(skuResult) ? skuResult.value : undefined,
                };
                eventBus.publish(event);
            });
        }
    }, [applicationId, eventBus, quote, isAdmin]);

    const successModal = useModal();

    const optionsForm = useMemo(() => {
        return createCNAQuoteOptionsForm(
            quote,
            setQuote,
            setSignature,
            navigate,
            isAdmin,
            successModal.show,
        );
        // We want to reinitiate form when signature changes so that the signature
        // matches current quote, that is, the signature reflects quote state and
        // vice versa. Otherwise, changing checking signature checkboxes will turn
        // form 'dirty' while in fact, the quote it is initiated with is signed,
        // or at least partially signed.
        // Another way this could have been solved is by making signature part of
        // the quote.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quote, signature, navigate, isAdmin, successModal.show]);

    const quotePage = {
        name: 'coverage',
        fields: [
            'startDate',
            'buildingValue',
            'contentsValue',
            'propertyDeductible',
            'windHailDeductible',
            'hiredAndNonOwnedAutoLimit',
            'generalLiabilityLimit',
            'stopGapLiabilityLimit',
            'enhancedCoverage',
        ],
    };
    const signPage = {
        name: 'signature',
        fields: ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };
    const isQuoteExpired = quote?.daysToExpire === -1 && !isAdmin;
    const isQuoteBindable = !isQuoteExpired;
    const formPages = isQuoteBindable ? [quotePage, signPage] : [quotePage];

    const optionsWizardForm = useWizardForm(optionsForm, {
        pages: formPages as WizardPages<OpaqueForm<CNAQuoteOptionsFormData>>,
        initialValue: {
            startDate: quote.options.effectiveDate,
            buildingValue: Money.toFloat(quote.options.buildingLimit),
            contentsValue: Money.toFloat(quote.options.contentsLimit),
            propertyDeductible: Money.toFloat(quote.options.propertyDeductible),
            windHailDeductible: Money.toFloat(quote.options.windHailDeductible),
            hiredAndNonOwnedAutoLimit: Money.toFloat(quote.options.hiredNonOwnedAutoLimit),
            generalLiabilityLimit: quote.options.generalLiabilityLimit,
            stopGapLiabilityLimit: quote.options.stopGapLiabilityLimit,
            enhancedCoverage: quote.options.enhanced,
            agreementToConductSignature: signature.transfers,
            warrantyAndFraudSignature: signature.warranty,
        },
    });

    const quoteStepPage: Page<CNAQuoteOptionsFormData, CNAQuote> = {
        name: 'coverage',
        component: CNACoveragePage,
    };

    const quoteSignPage: Page<CNAQuoteOptionsFormData, CNAQuote> = {
        name: 'signature',
        component: CNASignaturePage,
    };

    const pages: Page<CNAQuoteOptionsFormData, CNAQuote>[] = isQuoteBindable
        ? [quoteStepPage, quoteSignPage]
        : [quoteStepPage];

    const navigation: PageNavigationComponent<CNAQuoteOptionsFormData, CNAQuote> =
        QuoteOnDemandNavigation;
    const referredNavigation: PageNavigationComponent<CNAQuoteOptionsFormData, CNAQuote> =
        ReferredQuoteOnDemandNavigation;

    return (
        <React.Fragment>
            <QuoteLandingPage
                title="CNA BOP"
                appTypeCode={AppTypeCodeListCNABOP}
                applicationId={applicationId}
                quote={quote}
                optionsWizardForm={optionsWizardForm}
                pages={pages}
                quoteBreakdown={QuoteDetails}
                pageNavigation={isQuoteBindable ? navigation : referredNavigation}
                handleBindCoverage={handleBindCoverageEvent}
                bindCoverageButtonLabel={isAdmin ? 'Bind coverage' : 'Request bind'}
                showPurchaseConfirmationModal={isAdmin}
                purchaseConfirmationTitle="Confirm Bind"
                purchaseConfirmationText="Continue to bind or go back to your quote."
                submitAction={isAdmin ? 'default' : 'requestBind'}
            />
            <SuccessModal modal={successModal} />
        </React.Fragment>
    );
}
