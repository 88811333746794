import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { InvoiceRepository } from '../../payments/repositories/InvoiceRepository';
import { QuoteRepository as PCoMLQuoteRepository } from '../../quote/pcoml/repositories/QuoteRepository';
import { DisplayPolicy } from '@app/policy/types/DisplayPolicy';
import { PCoMLQuote } from '@app/quote/pcoml/entities/PCoMLQuote';
import { ESPQuote } from '../../quote/esp/entities/ESPQuote';
import { CrimeQuote } from '@app/quote/crime/entities/CrimeQuote';
import { ESPRenewalQuote } from '@app/quote/espRenewal/entities/ESPRenewalQuote';
import { BundleQuoteRepository } from '@app/bundle/repositories';
import { BundleQuote } from '../../bundle/entities/BundleQuote';
import { ESPQuoteRepository } from '@app/quote/esp/repositories/ESPQuoteRepository';
import { Invoice } from '@app/payments/entities/Invoice';
import { CrimeQuoteRepository } from '@app/quote/crime/repositories/CrimeQuoteRepository';
import { ManualQuote } from '@app/quote/manual/view/components/ManualQuoteModalSummary';
import { USD } from '@embroker/shotwell/core/types/Money';

export interface GetQuoteSummaryByPaymentIdsResponse {
    quote: QuoteInfo;
}

export interface QuoteInfo {
    pCoMLQuote?: PCoMLQuote;
    espQuote?: ESPQuote;
    crimeQuote?: CrimeQuote;
    espRenewalQuote?: ESPRenewalQuote;
    cyberQuote?: BundleQuote; // Monoline cyber cowbell is a part of BundleQuote
    bundleQuote?: BundleQuote;
    manualQuotes?: ManualQuote[];
}

export interface GetQuoteSummaryByPaymentIdsRequest {
    paymentIdList?: string;
    policyList: Nullable<DisplayPolicy[]>;
}

export interface InvoiceDetails {
    singlePolicyIds: UUID[];
    bundleIds: UUID[];
}

export interface GetQuoteSummaryByPaymentIds extends UseCase {
    execute({
        paymentIdList,
    }: GetQuoteSummaryByPaymentIdsRequest): AsyncResult<
        GetQuoteSummaryByPaymentIdsResponse,
        InvalidArgument | OperationFailed
    >;
}

export function splitPaymentIdList(paymentIdsString?: string): UUID[] {
    if (paymentIdsString != '' && paymentIdsString != undefined) {
        return paymentIdsString.split(',') as UUID[];
    }
    return [];
}

export function splitPayments(result: Immutable<Invoice[]>): InvoiceDetails {
    const singlePolicyIds = result
        .filter((invoice) => invoice.bundleId === undefined)
        .map((invoice) => invoice.policyId)
        .filter((policyId) => policyId !== undefined) as UUID[];

    const bundleIds = result
        .filter((invoice) => invoice.bundleId !== undefined)
        .map((invoice) => invoice.bundleId)
        .filter((bundleId) => bundleId !== undefined) as UUID[];

    return { singlePolicyIds, bundleIds };
}

class GetQuoteSummaryByPaymentIdsUseCase extends UseCase implements GetQuoteSummaryByPaymentIds {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetQuoteSummaryByPaymentIds');
    /**
     * Constructor for GetQuoteSummaryByPaymentIds use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param applicantRepository is applicant repository used to store applicant entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepository: InvoiceRepository,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
        @inject(CrimeQuoteRepository) private crimeQuoteRepository: CrimeQuoteRepository,
        @inject(PCoMLQuoteRepository) private pcomlQuoteRepository: PCoMLQuoteRepository,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        paymentIdList,
        policyList,
    }: GetQuoteSummaryByPaymentIdsRequest): AsyncResult<
        GetQuoteSummaryByPaymentIdsResponse,
        InvalidArgument | OperationFailed
    > {
        if (paymentIdList == '') {
            return Success<GetQuoteSummaryByPaymentIdsResponse>({
                quote: {},
            });
        }

        const invoiceIdList = splitPaymentIdList(paymentIdList);
        const invoiceResult = await this.invoiceRepository.getByIds(invoiceIdList);

        if (isErr(invoiceResult)) {
            return handleOperationFailure(invoiceResult);
        }

        const { singlePolicyIds, bundleIds } = splitPayments(invoiceResult.value);

        const quoteInfoResult = await this.handleBuildQuoteInfo(
            bundleIds,
            singlePolicyIds,
            policyList,
        );
        if (isErr(quoteInfoResult)) {
            return handleOperationFailure(quoteInfoResult);
        }

        return Success<GetQuoteSummaryByPaymentIdsResponse>({
            quote: quoteInfoResult.value as QuoteInfo,
        });
    }

    private async handleBuildQuoteInfo(
        bundleIdList: UUID[],
        singlePolicyIdList: UUID[],
        policyDetailList: Nullable<DisplayPolicy[]>,
    ): AsyncResult<QuoteInfo, InvalidArgument | OperationFailed> {
        const quoteInfo = {} as QuoteInfo;
        quoteInfo.manualQuotes = [] as ManualQuote[];

        if (policyDetailList == null && singlePolicyIdList.length === 0) {
            return Success<QuoteInfo>(quoteInfo);
        }

        // Handle bundle invoices
        for (const bundleId of bundleIdList) {
            const applicationIdResult =
                await this.bundleQuoteRepository.getBundleApplicationFromBundleId(bundleId);
            if (isErr(applicationIdResult)) {
                return handleOperationFailure(applicationIdResult);
            }

            const bundleQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
                applicationIdResult.value,
            );

            if (isErr(bundleQuoteResult)) {
                // continue to the next bundle if there's an error
                continue;
            }

            quoteInfo.bundleQuote = bundleQuoteResult.value as BundleQuote;
        }

        // Handle single invoices
        for (const policyId of singlePolicyIdList) {
            const policy: DisplayPolicy | undefined = policyDetailList?.find(
                (policy) => policy.id === policyId,
            );

            if (policy && policy.insuranceApplication) {
                // PCoML policy
                if (policy.lineOfBusiness === 'LineOfBusinessCodeListPCoML') {
                    const lastQuoteResult = await this.pcomlQuoteRepository.getQuoteByApplicationId(
                        policy.insuranceApplication,
                    );
                    if (isErr(lastQuoteResult)) {
                        // continue to the next policy if there's an error
                        continue;
                    }
                    quoteInfo.pCoMLQuote = lastQuoteResult.value as PCoMLQuote;

                    // Crime policy
                } else if (
                    policy.lineOfBusiness === 'LineOfBusinessCodeListOther' &&
                    policy.subLineOfBusiness === 'LineOfBusinessSubtypeCodeListCrimeDigital'
                ) {
                    const lastQuoteResult = await this.crimeQuoteRepository.getLastCrimeQuote(
                        policy.insuranceApplication,
                    );
                    if (isErr(lastQuoteResult)) {
                        // continue to the next policy if there's an error
                        continue;
                    }

                    quoteInfo.crimeQuote = lastQuoteResult.value as CrimeQuote;

                    // ESP policy
                } else if (policy.lineOfBusiness === 'LineOfBusinessCodeListESP') {
                    const lastQuoteResult = await this.espQuoteRepository.getLastQuote(
                        policy.insuranceApplication,
                    );
                    if (isErr(lastQuoteResult)) {
                        // continue to the next policy if there's an error
                        continue;
                    }

                    quoteInfo.espQuote = lastQuoteResult.value as ESPQuote;

                    // Cyber policy, we only offer Cyber Cowbell as a part of a digital policy
                } else if (policy.lineOfBusiness === 'LineOfBusinessCodeListCyber') {
                    const lastQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
                        policy.insuranceApplication,
                    );
                    if (isErr(lastQuoteResult)) {
                        // continue to the next policy if there's an error
                        continue;
                    }
                    quoteInfo.cyberQuote = lastQuoteResult.value as BundleQuote;
                }
                // Non-digital policy
            } else if (policy && !policy.insuranceApplication) {
                quoteInfo.manualQuotes.push({
                    name: policy.displayName,
                    effectiveDate: policy.startDate,
                    totalPremium: policy.premiumPerYear ?? USD(0),
                } as ManualQuote);
            }
        }

        return Success<QuoteInfo>(quoteInfo);
    }
}

export const GetQuoteSummaryByPaymentIds: UseCaseClass<GetQuoteSummaryByPaymentIds> =
    GetQuoteSummaryByPaymentIdsUseCase;
