import { AllowedCoverageQuoteOptions } from '@embroker/shotwell-api/app.spec';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Immutable } from '@embroker/shotwell/core/types';
import { Failure, Result } from '@embroker/shotwell/core/types/Result';
import { Quote } from '../../quote/entities/Quote';
import { LimitType, isLimitType } from '../../quote/types/Limits';
import {
    CanceledByAdmin,
    ClearanceFailed,
    DeclinedByCarrier,
    Deleted,
    NotEligible,
    Purchased,
    QuotesReady,
    Referred,
    ReferredAwaitingReview,
    ShoppingCoverage,
} from '../../shopping/types/enums';
import { BundleQuote } from '../entities/BundleQuote';
import {
    BundleCoverageAppStatus,
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleQuestionnaireDataType,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../types/BundleQuoteCoverage';
import { DocumentType } from '../types/BundleQuoteDocument';
import { GetDocumentUrl } from '../useCases/GetDocumentUrl';
import { GetStaticDocumentUrl } from '../useCases/GetStaticDocumentUrl';

export function toBundleQuoteCoverage<T extends Quote>(
    type: BundleCoverageType,
    appStatus: string,
    coverageMetadata: BundleQuoteCoverageMetadata,
    questionnaireData: BundleQuestionnaireDataType,
    appValidUntil?: Date,
    quote?: T,
): Result<BundleQuoteCoverage<T>, InvalidArgument> {
    return BundleQuoteCoverage.create({
        type: type,
        status: appToCoverageStatus(appStatus),
        quote: quote?.toDTO(),
        appValidUntil: appValidUntil,
        coverageMetadata: coverageMetadata,
        questionnaireData: questionnaireData,
    });
}

export function appToCoverageStatus(status: string): BundleCoverageAppStatus {
    switch (status) {
        case QuotesReady:
            return BundleCoverageAppStatusEnum.QuotesReady;
        case Referred:
            return BundleCoverageAppStatusEnum.Referred;
        case ReferredAwaitingReview:
            return BundleCoverageAppStatusEnum.ReferredAwaitingReview;
        case DeclinedByCarrier:
        case NotEligible:
            return BundleCoverageAppStatusEnum.NotEligible;
        case ClearanceFailed:
            return BundleCoverageAppStatusEnum.ClearanceFailed;
        case Deleted:
            return BundleCoverageAppStatusEnum.UserDeclined;
        case Purchased:
            return BundleCoverageAppStatusEnum.Purchased;
        case CanceledByAdmin:
            return BundleCoverageAppStatusEnum.CanceledByAdmin;
        default:
            return BundleCoverageAppStatusEnum.QuotesReady;
    }
}

export const getDocumentUrl = async (
    bundleQuote: BundleQuote,
    bundleCoverageType: BundleCoverageType,
    documentType: DocumentType,
    abortSignal: AbortSignal,
) => {
    const coverage = bundleQuote.getCoverageByCoverageType(bundleCoverageType);
    const quote = coverage?.quote;
    if (!quote) {
        return Failure(InvalidArgument({ argument: bundleCoverageType, value: quote }));
    }

    return await execute(GetDocumentUrl, {
        bundleAppId: bundleQuote.applicationId,
        quote,
        documentType: documentType,
        abortSignal: abortSignal,
        bundleCoverageType: bundleCoverageType,
    });
};

export const getStaticDocumentUrl = async (
    documentType: DocumentType,
    bundleCoverageType: BundleCoverageType,
    bundleQuote: BundleQuote,
) => {
    return await execute(GetStaticDocumentUrl, {
        documentType: documentType,
        bundleCoverageType: bundleCoverageType,
        bundleQuote: bundleQuote,
    });
};

export function getAllowedHigherLimit(
    coverageType: ShoppingCoverage,
    allowedQuoteOptionsList?: Immutable<AllowedCoverageQuoteOptions[]>,
): Record<string, Record<LimitType, number>> | undefined {
    const allowedQuoteOptions = allowedQuoteOptionsList?.find(
        (allowedCoverageQuoteOptions) => allowedCoverageQuoteOptions.coverage_type === coverageType,
    );
    if (!allowedQuoteOptions?.limit.max) {
        return;
    }
    if (isLimitType(allowedQuoteOptions.limit.max)) {
        const result: Record<string, Record<LimitType, number>> = {};
        result[coverageType] = allowedQuoteOptions?.limit.max;

        return result;
    }
}
