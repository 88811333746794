import React, { useEffect, useContext } from 'react';
import { AppContext } from '@app/view/AppContext';
import { HeaderView } from '@app/view/components';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Button, ElementProps, PageLayout } from '@embroker/ui-toolkit/v2';
import { PublishRiskProfileReportBackToEmbrokerClickEvent } from '@app/public/useCases/PublishRiskProfileReportBackToEmbrokerClickEvent';

interface RiskProfilePageLayoutProps extends ElementProps<'div'> {
    children: React.ReactNode;
    showExitButton?: boolean;
}

export function RiskProfilePageLayout({
    children,
    showExitButton = false,
}: RiskProfilePageLayoutProps) {
    const { setHeader } = useContext(AppContext);

    useEffect(() => {
        setHeader(
            <HeaderView logoHref="https://www.embroker.com">
                {showExitButton && (
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            execute(PublishRiskProfileReportBackToEmbrokerClickEvent);

                            // async delay to allow the above event to get registered on the dataLayer before user is kicked out of app
                            setTimeout(() => {
                                window.location.href = 'http://www.embroker.com';
                            }, 1);
                        }}
                    >
                        Back to Embroker
                    </Button>
                )}
            </HeaderView>,
        );
    }, [setHeader, showExitButton]);

    return <PageLayout.Section>{children}</PageLayout.Section>;
}
