import { isDateAfter } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { addDays } from 'date-fns';
import React, { useContext } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { LineItemDetails, LineItemOptions } from '../../../entities/PCoMLQuote';
import { CoverageRestriction } from '../../../types/CoverageRestriction';
import {
    getLimitRetentionOptions,
    getRestrictedLimitRetentionOptions,
    pcomlQuotesCoverageDetailsMap,
    renewalQuoteDocumentsPublishDetails,
} from '../../config';
import { PCoMLQuoteOptionsFormData } from '../PCoMLQuoteLandingPage';
import { AppContext } from '../../../../../view/AppContext';

const embrokerOneVerticalsMap: { [key: string]: boolean } = {
    '541211': true,
    '541350': true,
    '541110': true,
    '541613': true,
    '541620': true,
    '541612': true,
    '541611': true,
    '541618': true,
    '541614': true,
    '531210': true,
    '541213': true,
    '511210': true,
    '425110': true,
    '519130': true,
    '611430': true,
    '334418': true,
    '541714': true,
    '511212': true,
    '541513': true,
    '541519': true,
    '518210': true,
    '519190': true,
    '541430': true,
    '517311': true,
    '517312': true,
    '541420': true,
    '541511': true,
    '334419': true,
    '541715': true,
    '522320': true,
    '611699': true,
    '334413': true,
    '541515': true,
    '334417': true,
    '611610': true,
    '541330': true,
    '517410': true,
    '541512': true,
    '511140': true,
    '541490': true,
    '541713': true,
    '454110': true,
    '517919': true,
    '517911': true,
    '611710': true,
    '541690': true,
    '611691': true,
    '611620': true,
};

interface PCoMLSelectedCoverageProps {
    isRenewal: boolean;
    details: LineItemDetails;
    options: LineItemOptions;
    type: string;
    isSubmitting: boolean;
    newInsurerDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'];
    coverageRestriction?: CoverageRestriction;
    handleIsSelected: () => void;
    showEpliStandaloneInCalifornia?: () => void;
    naics?: string;
}

export const PCoMLSelectedCoverage = ({
    isRenewal,
    details,
    options,
    fields,
    type,
    isSubmitting,
    newInsurerDocumentsReleaseDate,
    submittedAt,
    state,
    coverageRestriction,
    handleIsSelected,
    showEpliStandaloneInCalifornia,
    naics,
}: PCoMLSelectedCoverageProps) => {
    const limitField = type == 'dno' ? fields.dnoLimit : fields.eplLimit;
    const retentionField = type == 'dno' ? fields.dnoRetention : fields.eplRetention;
    const premium = details.premium;

    const { globalConfig } = useContext(AppContext);

    const getUrlBasedOnSubmittedDate = (
        isRenewal: boolean,
        newInsurerDocumentsReleaseDate?: Date,
        submittedAt?: Date,
        state?: Nullable<State>,
    ): string => {
        if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
            if (
                isSubmissionDateAfterPublishDate(
                    isRenewal,
                    submittedAt,
                    newInsurerDocumentsReleaseDate,
                    state,
                )
            ) {
                return pcomlQuotesCoverageDetailsMap[type].newDocumentUrl;
            }
        }
        return pcomlQuotesCoverageDetailsMap[type].documentUrl;
    };

    const isSubmissionDateAfterPublishDate = (
        isRenewal: boolean,
        submittedAt: Date,
        newInsurerDocumentsReleaseDate: Date,
        state?: Nullable<State>,
    ): boolean => {
        let daysToPublishByState = 0;

        if (state !== undefined && state !== null && isRenewal) {
            daysToPublishByState = renewalQuoteDocumentsPublishDetails[state].daysToPublish;
        }

        return isDateAfter(
            submittedAt,
            addDays(newInsurerDocumentsReleaseDate, daysToPublishByState),
        );
    };

    if (globalConfig === undefined) {
        return null;
    }

    const isEligibleForMonolineEPL = (): boolean => {
        const featureEnabled = globalConfig?.pcomlIptChangesEnabled;

        if (!featureEnabled || state !== 'CA') {
            return true;
        }

        if (!naics) {
            return false;
        }

        return naics in embrokerOneVerticalsMap;
    };

    const showEPLIStandaloneModal = () => {
        if (
            globalConfig?.pcomlIptChangesEnabled &&
            isEpliStandalone(fields, type, state) &&
            showEpliStandaloneInCalifornia &&
            !isEligibleForMonolineEPL()
        ) {
            showEpliStandaloneInCalifornia();
        }
    };

    const showEpliWarning = type === 'epl' && !isEligibleForMonolineEPL();

    return (
        <Card
            onDismiss={
                !isSubmitting
                    ? () => {
                          handleIsSelected();
                          showEPLIStandaloneModal();
                      }
                    : undefined
            }
            menuItems={[
                <TextButton
                    target="_blank"
                    key={'download' + type + 'Coverage'}
                    href={getUrlBasedOnSubmittedDate(
                        isRenewal,
                        newInsurerDocumentsReleaseDate,
                        submittedAt,
                        state,
                    )}
                >
                    Download coverage details
                </TextButton>,
            ]}
            data-e2e={`${type}-card`}
        >
            <Card.Header>
                <Icon name={pcomlQuotesCoverageDetailsMap[type].icon} />
                <Text style="heading 5">{pcomlQuotesCoverageDetailsMap[type].title}</Text>
            </Card.Header>
            <Card.Body>
                <StackLayout>
                    <Text>{pcomlQuotesCoverageDetailsMap[type].text}</Text>
                    {showEpliWarning && (
                        <StatusMessage status="helptext">
                            To purchase Employment Practices Liability digitally, you must include
                            at least one other coverage as part of your quote
                        </StatusMessage>
                    )}
                    <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                        <Form.Field
                            type={limitField.type}
                            inputProps={{
                                ...limitField.props,
                                filterable: false,
                                items: getRestrictedLimitRetentionOptions(
                                    type,
                                    coverageRestriction,
                                    options.previousLimit,
                                    state,
                                ).limits,
                                label: 'Limit',
                                disabled:
                                    getLimitRetentionOptions(type, options.previousLimit, state)
                                        .limits.length == 1 || isSubmitting,
                            }}
                        />
                        <Form.Field
                            type={retentionField.type}
                            inputProps={{
                                ...retentionField.props,
                                filterable: false,
                                items: getRestrictedLimitRetentionOptions(
                                    type,
                                    coverageRestriction,
                                    options.previousLimit,
                                    state,
                                ).retentions,
                                label: 'Retention',
                                disabled: isSubmitting,
                            }}
                        />
                    </ColumnLayout>
                    {isRenewal && (
                        <ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Limit:</Text>
                                    <Text style="body 1">
                                        {options.previousLimit ? (
                                            <MoneyDisplay
                                                value={options.previousLimit}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Retention</Text>
                                    <Text style="body 1">
                                        {options.previousRetention ? (
                                            <MoneyDisplay
                                                value={options.previousRetention}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                        </ColumnLayout>
                    )}
                </StackLayout>
            </Card.Body>
            <Card.Footer>
                <StackLayout gap="12">
                    <Text as="span" style="body 1">
                        Premium:{' '}
                        <Text as="span" style="heading 5">
                            <MoneyDisplay value={premium} />
                        </Text>
                    </Text>
                    {details.previousPremium && (
                        <Text style="body 1">
                            Previous Premium:{' '}
                            <Text as="span" style="heading 5">
                                <MoneyDisplay value={details.previousPremium} />
                            </Text>
                        </Text>
                    )}
                </StackLayout>
            </Card.Footer>
        </Card>
    );
};

function isEpliStandalone(
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'],
    coverageToBeDeselected: 'dno' | 'epl' | string,
    state?: Nullable<State>,
): boolean {
    if (coverageToBeDeselected === 'epl') {
        return false;
    }

    const isEplSelected = fields.isEplSelected?.props?.value;

    if (!isEplSelected) {
        return false;
    }

    if (state !== 'CA' && isEplSelected) {
        return false;
    }

    return true;
}
