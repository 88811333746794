import { ForceNotEligible } from '@embroker/shotwell-api/app.spec';
import {
    CreateEntityProps,
    defineEntityValidator,
    entity,
    Entity,
    EntityProps,
    UpdateEntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { Failure, Result, Success } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { equalUUID, UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Location } from '../../locations/entities/Location';
import { WorkspaceOwnership, WorkspaceOwnershipList } from '../types/enums';
import { MailingAddress } from '../types/MailingAddress';
import { UserRole } from '../types/UserRole';
import { User } from './User';

export interface OrganizationUpdated extends DomainEvent {
    readonly origin: 'Organization';
    readonly name: 'Updated';
}

export interface OrganizationCreated extends DomainEvent {
    readonly origin: 'Organization';
    readonly name: 'Created';
}

export interface OrganizationAdded extends DomainEvent {
    readonly origin: 'Organization';
    readonly name: 'Added';
    readonly organizationId: UUID;
}

export interface OrganizationSwitched extends DomainEvent {
    readonly origin: 'Organization';
    readonly name: 'Switched';
    readonly organizationId: UUID;
}

export interface Organization extends Entity {
    /**
     * Organization's email
     */
    readonly email: Nullable<EmailAddress>;
    /**
     * Organization's web site
     */
    readonly website: Nullable<string>;
    /**
     * Full legal name of the organization
     */
    readonly companyLegalName: string;
    /**
     * North American industry classification system company code
     */
    readonly naics: Nullable<string>;
    /**
     * Organization's entity type
     */
    readonly entityType: Nullable<UUID>;
    /**
     * Year on which organization was founded
     */
    readonly yearStarted: Nullable<Year>;
    /**
     * Yearly revenues
     */
    readonly revenues: Nullable<Revenue[]>;
    /**
     * Headquarters of an organization
     */
    readonly headquarters: MailingAddress;
    /**
     * List of other locations in which organization has business building
     */
    readonly otherLocations: Nullable<Location[]>;
    /**
     * Description of how the organization generates revenue
     */
    readonly howDoesYourCompanyGenerateRevenue: Nullable<string>;
    /**
     * Total number of employees in organization
     */
    readonly totalNumberOfEmployees: Nullable<number>;
    /**
     * Total annual payroll budget for organization
     */
    readonly totalAnnualPayroll: Nullable<Money>;
    /**
     * List of user's which are either owners or collaborators in the organization
     */
    readonly userRoleList: UserRole[];
    /**
     * True if organization is test organization
     */
    readonly isTestOrganization: Nullable<boolean>;
    /**
     * True if company has raised venture funding
     */
    readonly raisedFunding: Nullable<boolean>;
    /**
     * Technology Area of focus
     */
    readonly techAreaOfFocus: Nullable<string>;
    /**
     * True if admin has approved higher limits
     */
    readonly higherLimitsApproved: boolean;
    /**
     * Organization's phone number
     */
    readonly phoneNumber?: PhoneNumber;
    /**
     * Object specifying policy types for which the organization is not eligible
     */
    readonly forceNotEligible?: ForceNotEligible;

    readonly workspaceOwnership?: WorkspaceOwnership;

    readonly hasAutomobiles?: boolean;

    readonly hasEmployees?: boolean;

    readonly providesTechServiceForFee: Nullable<boolean>;

    /**
     * Adds a user to the list of invited users to the organization.
     * Invites will be sent out once the entity is saved in the repo layer
     * @param userRole
     */
    addUser(userRole: UserRole): void;

    /**
     * Removes a user from the list of members in the organization
     * @param userRole
     */
    removeUser(userRole: UserRole): void;

    /**
     * Updates basic information about the organization.
     * Basic information is all excepts user role list.
     * @param organization
     */
    update(
        organization: Omit<UpdateEntityProps<Organization>, 'otherLocations'> & {
            otherLocations?: Immutable<EntityProps<Location>>[];
        },
    ): void;

    /**
     * Creates new organization.
     * Basic information is all excepts user role list.
     * @param organization
     */
    create(organization: CreateEntityProps<Organization>): void;

    /**
     * Returns a role which user has in organization
     * @param user
     * @returns User role for the user is user is a member of the organization
     * @returns UnknownEntity error if user is not a member of the organization
     */
    getUserRole(user: User): Result<UserRole, UnknownEntity>;
}

const ForceNotEligibleSchema = {
    ...defineValidator<ForceNotEligible>({
        esp: Joi.bool(),
        wcga: Joi.bool(),
        crime: Joi.bool(),
        cyber: Joi.bool(),
        lpl: Joi.bool(),
        pcoml: Joi.bool(),
        cna_bop: Joi.bool(),
        streamline_lpl: Joi.bool(),
    }),
};

export const Organization = entity<Organization>({
    validator: defineEntityValidator<Organization>({
        email: EmailAddress.schema.allow(null),
        website: Joi.string().allow(null),
        companyLegalName: Joi.string(),
        naics: Joi.string().allow(null),
        entityType: UUID.schema.allow(null),
        yearStarted: Year.schema.allow(null),
        revenues: Joi.array().items(Revenue.schema).allow(null),
        headquarters: MailingAddress.schema,
        otherLocations: Joi.array().items(Location.schema).allow(null),
        howDoesYourCompanyGenerateRevenue: Joi.string().allow(null),
        totalNumberOfEmployees: Joi.number().strict().allow(null),
        totalAnnualPayroll: Money.schema.allow(null),
        userRoleList: Joi.array().items(UserRole.schema).allow(null),
        isTestOrganization: Joi.boolean().allow(null),
        raisedFunding: Joi.boolean().allow(null),
        techAreaOfFocus: Joi.string().allow(null),
        higherLimitsApproved: Joi.boolean(),
        phoneNumber: PhoneNumber.schema.optional(),
        forceNotEligible: ForceNotEligibleSchema.schema.optional(),
        workspaceOwnership: Joi.string()
            .valid(...WorkspaceOwnershipList)
            .optional(),
        hasAutomobiles: Joi.boolean().optional(),
        hasEmployees: Joi.boolean().optional(),
        providesTechServiceForFee: Joi.boolean().allow(null),
    }),
    addUser(userRole) {
        this.props.userRoleList.push(userRole);
    },
    removeUser(userRole) {
        this.props.userRoleList = this.props.userRoleList.filter((item: UserRole) => {
            return item.userId !== userRole.userId && item.role !== userRole.role;
        });
    },
    update(newOrganization) {
        Object.assign(this.props, newOrganization);
        this.createEvent<OrganizationUpdated>('Updated');
    },
    create(newOrganization) {
        Object.assign(this.props, newOrganization);
        this.createEvent<OrganizationCreated>('Created');
    },
    getUserRole(user: User): Result<UserRole, UnknownEntity> {
        const result = this.props.userRoleList.find((item: UserRole) => {
            return equalUUID(item.userId, user.id);
        });

        if (result !== undefined) {
            return Success(result);
        }

        return Failure(UnknownEntity('User', user.id));
    },
});
