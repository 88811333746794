import { AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    AppTypeNotAllowedForBroker: 0x6000,
    ApplicationNotFound: 0x6010,
    OperationNotAllowed: 0x6011,
    EnricherAPIError: 0x6012,
    OrganizationNotFound: 0x6013,
    ShoppingCoverageNotSupportedForAppType: 0x6014,
    PrecreateApplicationError: 0x6015,
    ShoppingCoverageNotProvided: 0x6016,
    AppTypeNotSupported: 0x6017,
    NameClearanceError: 0x6018,
    BundleAppTypeNotSupportedError: 0x6019,
    NoEligibleAppTypeFound: 0x601a,
    AnswerMissing: 0x601b,
    StreamlineRenewalNotEligible: 0x6020,
    StreamlineRenewalAlreadyPurchased: 0x6021,
    StreamlineRenewalAlreadyQuoted: 0x6022,
    NoAppTypeForGenericBundle: 0x6023,
    QuestionnaireNotFound: 0x6024,
});

export type NoEligibleAppTypeFound = ErrorObject<typeof ErrorCode.NoEligibleAppTypeFound>;
export function NoEligibleAppTypeFound(): Immutable<NoEligibleAppTypeFound> {
    return {
        name: 'NoEligibleAppTypeFound',
        code: ErrorCode.NoEligibleAppTypeFound,
        message: 'Application is ineligible and no manual application type found',
    };
}

export type AppTypeNotAllowedForBroker = ErrorObject<typeof ErrorCode.AppTypeNotAllowedForBroker>;
export function AppTypeNotAllowedForBroker(): Immutable<AppTypeNotAllowedForBroker> {
    return {
        name: 'AppTypeNotAllowedForBroker',
        code: ErrorCode.AppTypeNotAllowedForBroker,
        message: 'Broker application resolved to manual application type.',
    };
}

export type ApplicationNotFound = ErrorObject<typeof ErrorCode.ApplicationNotFound>;
export function ApplicationNotFound(): Immutable<ApplicationNotFound> {
    return {
        name: 'ApplicationNotFound',
        code: ErrorCode.ApplicationNotFound,
        message: 'Application not found for the given token.',
    };
}

export type OperationNotAllowed = ErrorObject<typeof ErrorCode.OperationNotAllowed>;
export function OperationNotAllowed(
    message = 'Operation is not allowed.',
): Immutable<OperationNotAllowed> {
    return {
        name: 'OperationNotAllowed',
        code: ErrorCode.OperationNotAllowed,
        message,
    };
}

export type EnricherAPIError = ErrorObject<typeof ErrorCode.EnricherAPIError>;
export function EnricherAPIError(): Immutable<EnricherAPIError> {
    return {
        name: 'EnricherAPIError',
        code: ErrorCode.EnricherAPIError,
        message: 'Enricher API call failed.',
    };
}

export type OrganizationNotFound = ErrorObject<typeof ErrorCode.OrganizationNotFound>;
export function OrganizationNotFound(): Immutable<OrganizationNotFound> {
    return {
        name: 'OrganizationNotFound',
        code: ErrorCode.OrganizationNotFound,
        message: 'Organization not found.',
    };
}

export type ShoppingCoverageNotSupportedForAppType = ErrorObject<
    typeof ErrorCode.ShoppingCoverageNotSupportedForAppType
>;
export function ShoppingCoverageNotSupportedForAppType(
    shoppingCoverage: string,
): Immutable<ShoppingCoverageNotSupportedForAppType> {
    return {
        name: 'ShoppingCoverageNotSupportedForAppType',
        code: ErrorCode.ShoppingCoverageNotSupportedForAppType,
        message: shoppingCoverage + ' not supported for selected app type.',
    };
}

export type ShoppingCoverageNotProvided = ErrorObject<typeof ErrorCode.ShoppingCoverageNotProvided>;
export function ShoppingCoverageNotProvided(): Immutable<ShoppingCoverageNotProvided> {
    return {
        name: 'ShoppingCoverageNotProvided',
        code: ErrorCode.ShoppingCoverageNotProvided,
        message: 'Shopping coverage not provided',
    };
}

export type PrecreateApplicationError = ErrorObject<
    typeof ErrorCode.PrecreateApplicationError,
    {
        appType: Immutable<AppTypeCodeListItem>;
        innerErrorList: Immutable<ErrorLike[]>;
    }
>;
export function PrecreateApplicationError(
    appType: Immutable<AppTypeCodeListItem>,
    innerErrorList: Immutable<ErrorLike[]>,
): Immutable<PrecreateApplicationError> {
    return {
        name: 'PrecreateApplicationError',
        code: ErrorCode.PrecreateApplicationError,
        message: 'Precreate application failed.',
        details: { appType, innerErrorList },
    };
}

export type AppTypeNotSupported = ErrorObject<typeof ErrorCode.AppTypeNotSupported>;
export function AppTypeNotSupported(): Immutable<AppTypeNotSupported> {
    return {
        name: 'AppTypeNotSupported',
        code: ErrorCode.AppTypeNotSupported,
        message: 'Application type is not supported',
    };
}

export type NameClearanceError = ErrorObject<typeof ErrorCode.NameClearanceError>;
export function NameClearanceError(): Immutable<NameClearanceError> {
    return {
        name: 'NameClearanceError',
        code: ErrorCode.NameClearanceError,
        message: 'Enricher API call failed.',
    };
}

export type BundleAppTypeNotSupportedError = ErrorObject<
    typeof ErrorCode.BundleAppTypeNotSupportedError,
    { appType: Immutable<AppTypeCodeListItem> }
>;
export function BundleAppTypeNotSupportedError(
    appType: Immutable<AppTypeCodeListItem>,
): Immutable<BundleAppTypeNotSupportedError> {
    return {
        name: 'BundleAppTypeNotSupportedError',
        code: ErrorCode.BundleAppTypeNotSupportedError,
        message: 'Bundle application type is not supported.',
        details: { appType },
    };
}

export type AnswerMissing = ErrorObject<typeof ErrorCode.AnswerMissing>;
export function AnswerMissing(): Immutable<AnswerMissing> {
    return {
        name: 'AnswerMissing',
        code: ErrorCode.AnswerMissing,
        message: 'Answer missing for one or more fields',
    };
}

export type StreamlineRenewalNotEligible = ErrorObject<
    typeof ErrorCode.StreamlineRenewalNotEligible
>;
export function StreamlineRenewalNotEligible(): Immutable<StreamlineRenewalNotEligible> {
    return {
        name: 'StreamlineRenewalNotEligible',
        code: ErrorCode.StreamlineRenewalNotEligible,
        message: 'Not eligible for streamline renewal',
    };
}

export type StreamlineRenewalAlreadyPurchased = ErrorObject<
    typeof ErrorCode.StreamlineRenewalAlreadyPurchased
>;
export function StreamlineRenewalAlreadyPurchased(): Immutable<StreamlineRenewalAlreadyPurchased> {
    return {
        name: 'StreamlineRenewalAlreadyPurchased',
        code: ErrorCode.StreamlineRenewalAlreadyPurchased,
        message: 'Stramline renewal already purchased',
    };
}

export type StreamlineRenewalAlreadyQuoted = ErrorObject<
    typeof ErrorCode.StreamlineRenewalAlreadyQuoted
>;
export function StreamlineRenewalAlreadyQuoted(): Immutable<StreamlineRenewalAlreadyQuoted> {
    return {
        name: 'StreamlineRenewalAlreadyQuoted',
        code: ErrorCode.StreamlineRenewalAlreadyQuoted,
        message: 'Stramline renewal already quoted',
    };
}

export type NoAppTypeForGenericBundle = ErrorObject<typeof ErrorCode.NoAppTypeForGenericBundle>;
export function NoAppTypeForGenericBundle(): Immutable<NoAppTypeForGenericBundle> {
    return {
        name: 'NoAppTypeForGenericBundle',
        code: ErrorCode.NoAppTypeForGenericBundle,
        message: 'Cannot find app type for generic bundle',
    };
}

export type QuestionnaireNotFound = ErrorObject<typeof ErrorCode.QuestionnaireNotFound>;
export function QuestionnaireNotFound(): Immutable<QuestionnaireNotFound> {
    return {
        name: 'QuestionnaireMissing',
        code: ErrorCode.QuestionnaireNotFound,
        message: 'Could not find questionnaire',
    };
}
