import React, { useState } from 'react';
import {
    usePageLayout,
    PageLayout,
    Text,
    StackLayout,
    ProgressBar,
    Box,
    ColumnLayout,
    Icon,
    useResponsive,
    useInterval,
    Transition,
} from '@embroker/ui-toolkit/v2';

export function YouAreAllDone() {
    const state = usePageLayout({
        header: null,
        isFullscreen: true,
    });
    const [progress, setProgress] = useState(1);
    const isMobileView = useResponsive({ screenWidth: { smallerThan: 'tablet' } });
    const containerSectionClassName = isMobileView ? 'u-1/1' : 'u-2/3';
    const minHeight = isMobileView ? '376px' : '224px';

    useInterval(() => {
        const nextProgress = Math.min(progress + 1 / (progress / 2), 100);
        setProgress(nextProgress);
    }, 50);

    return (
        <PageLayout {...state}>
            <PageLayout.Section>
                <StackLayout center gap="64">
                    <StackLayout center gap="32" className={containerSectionClassName}>
                        <Text style="heading 3" textAlign="center">
                            You’re all done! Give us a second while we process your information.
                        </Text>
                        <div className="u-1/1" data-chromatic="ignore">
                            <ProgressBar percent={progress} />
                        </div>
                        <Box
                            backgroundColor="primary-100"
                            borderRadius="12"
                            gap="32 32"
                            style={{ minHeight: minHeight, width: '100%' }}
                            border={false}
                        >
                            <StackLayout gap="24">
                                <Text style="heading 3" textAlign="center">
                                    Why choose Embroker?
                                </Text>
                                <ColumnLayout
                                    responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                                    top
                                    className="u-1/1"
                                >
                                    <Transition.Fade opacity={progress > 0 ? 100 : 0}>
                                        <StackLayout gap="12" center>
                                            <Icon name="globe" size="regular" />
                                            <Text as="body" textAlign="center">
                                                Online policy management. Real people support.
                                            </Text>
                                        </StackLayout>
                                    </Transition.Fade>
                                    <Transition.Fade opacity={progress > 20 ? 100 : 0}>
                                        <StackLayout gap="12" center>
                                            <Icon name="gem" size="regular" />
                                            <Text as="body" textAlign="center">
                                                Best-in-class coverages from top-rated carriers.
                                            </Text>
                                        </StackLayout>
                                    </Transition.Fade>
                                    <Transition.Fade opacity={progress > 35 ? 100 : 0}>
                                        <StackLayout gap="12" center>
                                            <Icon name="simple-handshake" size="regular" />
                                            <Text as="body" textAlign="center">
                                                Trusted by thousands of small businesses like yours.
                                            </Text>
                                        </StackLayout>
                                    </Transition.Fade>
                                </ColumnLayout>
                            </StackLayout>
                        </Box>
                    </StackLayout>
                </StackLayout>
            </PageLayout.Section>
        </PageLayout>
    );
}
