import React from 'react';
import { ComplexFormFieldViewProps } from '../../types/ComplexFieldTypes';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { ErrorMsgObject } from '../../types/validationObject';
import { RevenueFieldView } from './RevenueFieldView.view';

export const revenueFieldFormatValidationError = (error: InvalidArgument) => {
    return ErrorMsgObject.getValidationMessage(error);
};

export function PreviousYearRevenueView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    return <RevenueFieldView {...complexFormFieldViewProps} revenueType="previousYear" />;
}
