import { Immutable, Props } from '@embroker/shotwell/core/types';
import {
    CardLayout,
    ColumnLayout,
    Loader,
    Pagination,
    StackLayout,
    Table,
    Text,
    TextButton,
    useModal,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { Link } from '../../../view/components';
import { PolicyClaimContactInfo } from '../../types/PolicyClaimContactInfo';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { CarrierContactModal } from './CarrierContactModal';

const TABLE_ITEMS_LIMIT_PER_PAGE = 10;

interface PolicyTableItem extends Record<string, unknown> {
    id: string;
    policyName: string;
    policyNumber: string;
    policyStartDate: Date;
    claimContactInfo: React.ReactNode;
    fileClaimActionInfo: React.ReactNode;
}
interface FileClaimListProps {
    claimContactInfoList: Immutable<Props<PolicyClaimContactInfo>[]>;
}

export function FileClaimList({ claimContactInfoList }: FileClaimListProps) {
    const policyTableList = useMemo(() => {
        return [...parseRawPolicyListForTableView(claimContactInfoList)];
    }, [claimContactInfoList]);
    const { visibleItems, pagination } = useStaticTable<PolicyTableItem>({
        items: policyTableList,
        itemsPerPage: TABLE_ITEMS_LIMIT_PER_PAGE,
    });
    const showPagination = pagination.totalPages > 1;

    const { result: globalConfig, isLoading: isGlobalConfigLoading } = useUseCase(GetGlobalConfig);

    if (isGlobalConfigLoading || globalConfig == undefined) {
        return <Loader />;
    }

    if (isErr(globalConfig)) {
        return <ErrorPage errors={globalConfig.errors} />;
    }

    const isClaimRequestEnabled = globalConfig.value.config.isClaimRequestEnabled;

    return (
        <CardLayout>
            <CardLayout.Header>
                <ColumnLayout split="1">
                    <Text data-e2e="file-claim-list-header" style="heading 4">
                        How to file a claim
                    </Text>
                    {showPagination && (
                        <Pagination {...pagination} maximumVisibleButtons={4}></Pagination>
                    )}
                </ColumnLayout>
            </CardLayout.Header>
            <CardLayout.Body>
                <StackLayout>
                    <Text>
                        <b>Important:</b> Please check your policy for reporting instructions.
                    </Text>
                    <Text>
                        For policies issued by Everspan or Clear Blue, click below to get started.
                        For all other policies, please contact the insurer directly. Visit{' '}
                        <TextButton
                            data-e2e="file-claim-list-faq-link"
                            as={Link}
                            href="/support/faq"
                        >
                            Claims FAQs
                        </TextButton>{' '}
                        for further assistance.
                    </Text>
                    <Table>
                        <Table.Header>
                            <Table.Column data-e2e="file-claim-policy-column-header">
                                Policy
                            </Table.Column>
                            <Table.Column data-e2e="file-claim-policy-num-header">
                                Policy No.
                            </Table.Column>
                            <Table.Column data-e2e="file-claim-carrier-contact-header">
                                Carrier Claim Contact Info
                            </Table.Column>
                            {isClaimRequestEnabled && (
                                <Table.Column data-e2e="file-claim-action-header">
                                    Action
                                </Table.Column>
                            )}
                            <Table.Column />
                        </Table.Header>
                        <Table.Body>
                            {visibleItems.map((row) => (
                                <Table.Row key={row.id}>
                                    <Table.Cell>
                                        <Text
                                            data-e2e={
                                                `file-claim-policy-` +
                                                row.policyName.replace(/[#/&_ ]/g, '').toLowerCase()
                                            }
                                            style="label 1"
                                        >
                                            {row.policyName}
                                        </Text>
                                    </Table.Cell>
                                    <Table.Cell
                                        data-e2e={`file-claim-policy-num-` + row.policyNumber}
                                    >
                                        {row.policyNumber}
                                    </Table.Cell>
                                    <Table.Cell data-e2e="file-claims-contact-info">
                                        {row.claimContactInfo}
                                    </Table.Cell>
                                    {isClaimRequestEnabled && (
                                        <Table.Cell data-e2e="file-claim-action">
                                            {row.fileClaimActionInfo}
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </StackLayout>
            </CardLayout.Body>
        </CardLayout>
    );
}

function parseRawPolicyListForTableView(
    inputPolicyList: Immutable<Props<PolicyClaimContactInfo>[]>,
) {
    const tableList: PolicyTableItem[] =
        inputPolicyList
            .map((element) => {
                const claimAction = getClaimAction(element);
                return {
                    id: element.policyId,
                    policyName: element.policyDisplayName,
                    policyNumber: element.policyNumber,
                    policyStartDate: element.policyStartDate,
                    claimContactInfo: element.insurerName,
                    fileClaimActionInfo: (
                        <FileClaimActionInfo policy={element} claimAction={claimAction} />
                    ),
                };
            })
            .sort(sortPoliciesByStartDate) || [];

    return tableList;
}

const allowedClaimLinesOfBusiness = [
    'LineOfBusinessCodeListESP',
    'LineOfBusinessCodeListPCoML',
    'LineOfBusinessCodeListCyber',
];

type ClaimActionType = 'make-cliam' | 'contact-insurer';
function getClaimAction(policyInfo: Immutable<Props<PolicyClaimContactInfo>>): ClaimActionType {
    const isMakeClaim =
        policyInfo.applicationId !== undefined &&
        (allowedClaimLinesOfBusiness.includes(policyInfo.lineOfBusiness) ||
            policyInfo.lineOfBusinessSubtype === 'LineOfBusinessSubtypeCodeListCrimeDigital');

    return isMakeClaim ? 'make-cliam' : 'contact-insurer';
}

function sortPoliciesByStartDate(a: PolicyTableItem, b: PolicyTableItem) {
    return b.policyStartDate.getTime() - a.policyStartDate.getTime();
}

interface FileClaimActionInfoProps {
    policy: Immutable<Props<PolicyClaimContactInfo>>;
    claimAction: ClaimActionType;
}

function FileClaimActionInfo({ policy, claimAction }: FileClaimActionInfoProps) {
    const fileClaimUrl = URI.build('/claims/claim-request', {
        policyId: policy.policyId,
    });

    const carrierContactIntakeModal = useModal();

    return (
        <React.Fragment>
            <StackLayout gap="none">
                {claimAction === 'make-cliam' && (
                    <TextButton data-e2e="contact-embroker" as={Link} href={fileClaimUrl}>
                        File a Claim
                    </TextButton>
                )}
                {claimAction === 'contact-insurer' && (
                    <TextButton
                        data-e2e="contact-embroker"
                        onClick={carrierContactIntakeModal.show}
                    >
                        Contact Carrier
                    </TextButton>
                )}
            </StackLayout>
            <CarrierContactModal
                modal={carrierContactIntakeModal}
                policyClaimContactInfo={policy}
            />
        </React.Fragment>
    );
}
