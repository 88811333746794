import {
    BoxLayout,
    Button,
    ButtonBar,
    ColumnLayout,
    List,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface WelcomePageProps {
    onGetStarted(): void;
}

export function WelcomePage({ onGetStarted }: WelcomePageProps) {
    return (
        <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
            <BoxLayout className="u-1/2@large-tablet u-1/1">
                <StackLayout gap="48">
                    <StackLayout gap="32">
                        <Text style="heading light 1" data-e2e="welcome-page-header">
                            Welcome back!
                        </Text>
                        <StackLayout gap="16">
                            <Text style="body 1">
                                You can download a standard certificate for yourself that will
                                include all your Embroker policies instantaneously, or:
                            </Text>

                            <StackLayout gap="8">
                                <List>
                                    <List.Item> Send it to a third party</List.Item>
                                    <List.Item>Choose which policies you want to include</List.Item>
                                    <List.Item>Customize provisions</List.Item>
                                </List>

                                <Text style="body 1">
                                    Keep in mind that if you need to further customize your
                                    certificate or add endorsements, you can forward this
                                    questionnaire to our Customer Success team at the end of the
                                    process.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                    </StackLayout>
                    <ButtonBar reverse responsive={{ containerWidth: { smallerThan: 400 } }}>
                        <Button onClick={onGetStarted} data-e2e="certs-get-started">
                            Get Started
                        </Button>
                    </ButtonBar>
                </StackLayout>
            </BoxLayout>
        </ColumnLayout>
    );
}
