import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    CenterLayout,
    Filter,
    FilterOption,
    ListLayout,
    Pagination,
    Placeholder,
    SortOrder,
    StackLayout,
    StatusMessage,
    useAsyncTable,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { GetEmbrokerExcessEnabled } from '../../../../quote/embrokerExcess/useCases/GetEmbrokerExcessEnabled';
import { ActivityItemRecord } from '../../../types/BrokerActivityItem';
import { ProductType, ProductTypeFilter } from '../../../types/ProductType';
import { ActivityTableColumn } from '../../../useCases/GetBrokerActivity';
import { useTableDataSource } from '../../hooks/useTableDataSource';
import { BrokerActivityTableRow, getActivityTablePageData } from './BrokerActivityTableData';

type ActivityFilter = ActivityItemRecord | ProductType;

interface SortInfo {
    order: 'ascending' | 'descending';
    column: ActivityTableColumn;
}

interface FilterInfo {
    target: 'product_type' | 'record';
    value: ActivityFilter;
}

interface BrokerActivityProps {
    isNewBroker: boolean;
}

export function BrokerActivity({ isNewBroker }: BrokerActivityProps) {
    const itemsPerPage = 5;
    const initialSortBy = { column: 'expiry_date', order: 'ASC' as SortOrder };

    const [selectedSort, setSelectedSort] = useState<string>('closestDeadline');
    const [selectedRecordTypeFilters, setSelectedRecordTypeFilters] = useState<
        ActivityItemRecord[]
    >([]);
    const [selectedProductTypeFilters, setSelectedProductTypeFilters] = useState<ProductType[]>([]);

    const { result: getEmbrokerExcessEnabledResult } = useUseCase(GetEmbrokerExcessEnabled);
    const isEmbrokerExcessEnabled =
        getEmbrokerExcessEnabledResult !== undefined &&
        isOK(getEmbrokerExcessEnabledResult) &&
        getEmbrokerExcessEnabledResult.value;

    const { additionalData, fetchPageData } = useTableDataSource(getActivityTablePageData);

    const { isLoading, visibleItems, sortBy, filterBy, pagination } =
        useAsyncTable<BrokerActivityTableRow>({
            fetchPageData,
            itemsPerPage,
            initialSortBy,
            prefetch: true,
        });

    const noItems = visibleItems.length === 0;

    const onRecordTypeFilterChange = (event: {
        readonly target: { readonly value: ActivityItemRecord[] };
    }) => {
        setSelectedRecordTypeFilters(event.target.value);

        const productFilterInfo = selectedProductTypeFilters.map(mapProductFilterToSortInfo);
        const recordFilterInfo = event.target.value.map(mapRecordFilterToSortInfo);

        filterBy([...productFilterInfo, ...recordFilterInfo]);
    };

    const onProductTypeFilterChange = (event: {
        readonly target: { readonly value: ProductType[] };
    }) => {
        setSelectedProductTypeFilters(event.target.value);

        const productFilterInfo = event.target.value.map(mapProductFilterToSortInfo);
        const recordFilterInfo = selectedRecordTypeFilters.map(mapRecordFilterToSortInfo);

        filterBy([...productFilterInfo, ...recordFilterInfo]);
    };

    function onSortChange(event: { readonly target: { readonly value: string } }) {
        const selectedOption = event.target.value;
        setSelectedSort(selectedOption);

        const sortInfo = mapActivitySortValueToSortInfo(selectedOption);

        sortBy(sortInfo.column, sortInfo.order === 'ascending' ? 'ASC' : 'DESC');
    }

    const noTasksYet = isNewBroker && noItems;
    const noTasksLeft = !isNewBroker && noItems;

    const areFiltersApplied =
        selectedProductTypeFilters.length > 0 || selectedRecordTypeFilters.length > 0;

    const productFilters = ProductTypeFilter.getFilters(isEmbrokerExcessEnabled);

    return (
        <StackLayout>
            {additionalData?.partialResults ? (
                <StatusMessage status="warning">
                    Sorry for the inconvenience, but we were unable to collect all your activity
                    items.
                </StatusMessage>
            ) : null}
            <ListLayout
                overlay={
                    isLoading
                        ? 'Loading your to-do items'
                        : noTasksYet
                        ? 'Start a quote to see a list of your to-do items here'
                        : undefined
                }
            >
                <ListLayout.Header
                    split="-3"
                    responsive={{ screenWidth: { smallerThan: 'mobile' } }}
                    title="To-do"
                >
                    <Filter
                        className="access_todo_sort"
                        title="Sort"
                        options={ActivitySorts}
                        value={selectedSort}
                        onChange={onSortChange}
                    />
                    <Filter
                        className="access_todo_task_filter"
                        title="Filter by task"
                        options={RecordTypeFilters}
                        value={selectedRecordTypeFilters}
                        onChange={onRecordTypeFilterChange}
                        multiple
                    />
                    <Filter
                        className="access_todo_product_filter"
                        title="Filter by product"
                        options={productFilters}
                        value={selectedProductTypeFilters}
                        onChange={onProductTypeFilterChange}
                        multiple
                    />
                </ListLayout.Header>
                {!isLoading && noTasksLeft ? (
                    <ListLayout.Body>
                        <Placeholder
                            title={
                                areFiltersApplied
                                    ? 'No records match applied filter criteria.'
                                    : 'You’re all caught up with your tasks!'
                            }
                            titleColor="dark"
                        />
                    </ListLayout.Body>
                ) : (
                    <React.Fragment>
                        <ListLayout.Body>
                            {visibleItems.map((row) => (
                                <ListLayout.Item key={row.id as UUID} data-e2e="dashboard-item">
                                    {row.message} {row.action}
                                </ListLayout.Item>
                            ))}
                        </ListLayout.Body>
                        <ListLayout.Footer>
                            <CenterLayout>
                                {pagination && pagination.totalPages > 1 ? (
                                    <Pagination
                                        className="access_todo_pagination_buttons"
                                        {...pagination}
                                    />
                                ) : null}
                            </CenterLayout>
                        </ListLayout.Footer>
                    </React.Fragment>
                )}
            </ListLayout>
        </StackLayout>
    );
}

const RecordTypeFilters: FilterOption<ActivityItemRecord>[] = [
    {
        value: 'quote',
        title: 'Quotes',
    },
    {
        value: 'renewal',
        title: 'Renewals',
    },
    {
        value: 'payment',
        title: 'Payments',
    },
    {
        value: 'application',
        title: 'Unsubmitted apps',
    },
];

const ActivitySorts: FilterOption<string>[] = [
    {
        value: 'closestDeadline',
        title: 'Closest Deadline',
    },
    {
        value: 'clientNameAsc',
        title: 'Client name (A-Z)',
    },
    {
        value: 'clientNameDesc',
        title: 'Client name (Z-A)',
    },
];

function mapActivitySortValueToSortInfo(value: string): SortInfo {
    switch (value) {
        case 'clientNameAsc':
            return {
                order: 'ascending',
                column: 'client_name',
            };
        case 'clientNameDesc':
            return {
                order: 'descending',
                column: 'client_name',
            };
    }
    // default sort
    return {
        order: 'ascending',
        column: 'expiry_date',
    };
}

function mapRecordFilterToSortInfo(filter: ActivityItemRecord): FilterInfo {
    return { target: 'record', value: filter };
}
function mapProductFilterToSortInfo(filter: ProductType): FilterInfo {
    return { target: 'product_type', value: filter };
}
