import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { isOK, isErr } from '@embroker/shotwell/core/types/Result';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ButtonBar,
    CardLayout,
    ColumnLayout,
    InvoiceTable,
    Loader,
    RadioGroup,
    SidebarLayout,
    StackLayout,
    StatusMessage,
    Text,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { Payment, BundlePayment, SinglePayment, isBundlePayment } from '../../types/Payment';
import { SumInvoicesToPay, SumInvoicesToPayRequest } from '../../useCases/SumInvoicesToPay';
import { BANK_VALUE, CREDIT_CARD_VALUE, PREMIUM_FINANCE_VALUE } from './PaymentsDashboard';
import { PayByAscend } from '../../useCases/PayByAscend';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetConfig } from '../../useCases/GetConfig';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { URI } from '@embroker/shotwell/core/types/URI';
import { PublishPaymentCTAClickEvent } from '../../useCases/PaymentCTAClickEvent';
import { useDisablePayments } from '../hooks/useDisablePayments';
import { AscendPaymentsModal } from '../../../policy/view/modals/AscendPaymentsModal';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { PaymentsUpdateBillingInfo } from '@app/payments/view/components/PaymentsUpdateBilling';
import { InvoiceListItem } from './InvoiceListItem.view';

export const resolveLobByDescription = (lineOfBusiness: string, description?: string): string => {
    if (!description || lineOfBusiness !== 'Other') {
        return lineOfBusiness;
    }

    if (description.toLowerCase().includes('crime')) {
        return 'Crime';
    }

    return lineOfBusiness;
};

interface PaymentsDueProps {
    invoiceDueList: Immutable<Payment[]>;
    activeInvoices: UUID[];
    UIActiveInvoices: UUID[];
    showModal(invoice: Immutable<SinglePayment>): void;
    showBundleModal(invoice: Immutable<BundlePayment>): void;
    showACHModal(total: Money): void;
    showCCModal(total: Money): void;
    onOptionChange(option: string): void;
    toggleActive(UIPaymentId: UUID, invoiceIdList: UUID[]): void;
    onPayWithPremiumFinance(): void;
    option: string;
    userId: UUID;
    organizationId: UUID;
}

const CREDIT_CARD_FEE = 2.9;

interface RenderPaymentMethodProps {
    option: string;
    showACHModal(total: Money): void;
    showCCModal(total: Money): void;
    onPayWithPremiumFinance(): void;
    value: Money;
    ccValue: Money;
    ccFee: Money;
}

function RenderPaymentMethod(props: RenderPaymentMethodProps) {
    switch (props.option) {
        case BANK_VALUE:
            return (
                <React.Fragment>
                    <InvoiceTable.Section>
                        <InvoiceTable.Total title="Total Amount:">
                            <MoneyDisplay value={props.value} />
                        </InvoiceTable.Total>
                    </InvoiceTable.Section>
                    <Button
                        data-e2e="pay-with-ach-button"
                        appearance="primary"
                        onClick={() => props.showACHModal(props.value)}
                    >
                        Pay Via Your Bank
                    </Button>
                </React.Fragment>
            );
        case CREDIT_CARD_VALUE:
            return (
                <React.Fragment>
                    <InvoiceTable.Section>
                        <InvoiceTable.Subtotal title="Subtotal">
                            <MoneyDisplay value={props.value} />
                        </InvoiceTable.Subtotal>
                        <InvoiceTable.Item title={CREDIT_CARD_FEE + '% credit card processing fee'}>
                            <MoneyDisplay value={props.ccFee} />
                        </InvoiceTable.Item>
                    </InvoiceTable.Section>
                    <InvoiceTable.Section>
                        <InvoiceTable.Total title="Total Amount:">
                            <MoneyDisplay value={props.ccValue} />
                        </InvoiceTable.Total>
                    </InvoiceTable.Section>
                    <Button
                        data-e2e="pay-with-cc-button"
                        appearance="primary"
                        onClick={() => props.showCCModal(props.value)}
                    >
                        Pay With a Credit Card
                    </Button>
                </React.Fragment>
            );
        case PREMIUM_FINANCE_VALUE:
            return (
                <React.Fragment>
                    <Text style="body 2">
                        We'll calculate your down payment and you can choose the loan plan that best
                        suits your needs.
                    </Text>
                    <Text style="body 2"> Click 'Pay monthly' below to get started.</Text>
                    <hr />
                    <InvoiceTable.Section>
                        <InvoiceTable.Subtotal title="Financing options" />
                        <InvoiceTable.Item title="Monthly payments (10 installments)">
                            15% down
                        </InvoiceTable.Item>
                        <InvoiceTable.Item title="Quarterly payments (3 installments)">
                            30% down
                        </InvoiceTable.Item>
                    </InvoiceTable.Section>
                    <Button
                        data-e2e="pay-monthly-button"
                        appearance="primary"
                        onClick={() => props.onPayWithPremiumFinance()}
                    >
                        Pay Monthly
                    </Button>
                </React.Fragment>
            );
        default:
            return null;
    }
}

interface SidebarInvoicesInfo {
    balance: Money;
    lineOfBusiness: string;
    id: UUID;
}

function buildSidebarInvoicesInfo(
    invoice: Immutable<SinglePayment> | Immutable<BundlePayment>,
): SidebarInvoicesInfo {
    const description = isBundlePayment(invoice) ? undefined : invoice.description;
    const lob = resolveLobByDescription(invoice.lineOfBusiness, description);
    return {
        balance: invoice.balance,
        id: invoice.id,
        lineOfBusiness: lob,
    };
}

export interface ContinueToAscendButtonProps {
    invoiceIds: UUID[];
    userId?: UUID;
    organizationId: UUID;
    disabled?: boolean;
}

export function ContinueToAscendButton(props: ContinueToAscendButtonProps) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { navigate } = useNavigation();
    const navigateToFaq = function () {
        execute(PublishPaymentCTAClickEvent, { type: 'PaperCheck' });
        navigate(URI.build('/payments/faqs'));
    };
    const ascendPaymentsModal = useModal();
    const onButtonClick = async function () {
        execute(PublishPaymentCTAClickEvent, { type: 'Electronically' });
        setLoading(true);
        setError(false);
        const programUrlResult = await execute(PayByAscend, {
            invoiceIds: props.invoiceIds,
            userId: props.userId,
            organizationId: props.organizationId,
        });
        setLoading(false);
        if (isOK(programUrlResult)) {
            // If the program URL is empty, that means that the payment was already paid.
            // The user should be redirected to the dashboard rather than ascend.
            if (programUrlResult.value === '') {
                ascendPaymentsModal.show();
            } else {
                window.open(programUrlResult.value, '_self');
            }
        } else {
            setError(true);
            if (isErr(programUrlResult) && programUrlResult.errors.length > 0) {
                const err: OperationFailed = programUrlResult.errors[0] as OperationFailed;
                const subErr = err.details.errors[0];
                if (isAPIError(subErr) && subErr.details.name === 'premium_not_found_error') {
                    navigate(URI.build('/payments/invoice-error-page'));
                } else {
                    navigate(URI.build('/payments/error-page'));
                }
            }
        }
    };

    return (
        <React.Fragment>
            {loading && (
                <Loader
                    variant="finalizing"
                    heading="You're being redirected"
                    subheading="You are being redirected to Ascend, our electronic payments processing vendor."
                />
            )}
            {error && (
                <StatusMessage status="error">
                    Unknown error has occurred. Please contact Embroker team.{' '}
                </StatusMessage>
            )}
            {!props.disabled && (
                <StatusMessage status="helptext">
                    By clicking the button below, you'll be transferred to Ascend, Embroker's
                    electronic payment and premium financing vendor. You'll then have the option to
                    pay in full now or request premium financing options. Ascend's Terms of Use,
                    Privacy Policy and other terms, conditions, fees and disclosures will apply.
                </StatusMessage>
            )}
            <ButtonBar responsive={{ containerWidth: { smallerThan: 380 } }}>
                <Button
                    appearance="primary"
                    onClick={() => onButtonClick()}
                    disabled={props.disabled || loading}
                >
                    Pay Electronically
                </Button>
                <Button appearance="ghost" onClick={navigateToFaq} disabled={props.disabled}>
                    Pay By Paper Check
                </Button>
            </ButtonBar>
            <AscendPaymentsModal modal={ascendPaymentsModal} isRedirectedToPolicy />
        </React.Fragment>
    );
}

export function PaymentsDue(props: PaymentsDueProps) {
    const request: SumInvoicesToPayRequest = {
        invoiceIds: props.activeInvoices,
    };

    const { result: config } = useUseCase(GetConfig);
    const { result, isLoading } = useUseCase(SumInvoicesToPay, request);
    const { isPaymentDisabled, paymentDisabledMessage } = useDisablePayments();
    const { activeSession } = useContext(AppContext);

    const isBroker = hasRole(activeSession, 'broker');

    const sidebarInvoicesData = props.invoiceDueList.filter((invoice) =>
        props.UIActiveInvoices.includes(invoice.id),
    );

    const sidebarInvoices = sidebarInvoicesData.map((invoice) => {
        return buildSidebarInvoicesInfo(invoice);
    });

    let useAscend = false;
    if (!isBroker && config !== undefined && isOK(config)) {
        useAscend = config.value?.useAscend;
    }

    if (result === undefined || !isOK(result)) {
        return null;
    }

    const creditCardFee = Money.percentage(result.value, CREDIT_CARD_FEE);
    const creditCardValue = Money.percentage(result.value, 100 + CREDIT_CARD_FEE);

    const handleViewDetailsClicked = (invoice: Payment) => {
        if (isBundlePayment(invoice)) {
            props.showBundleModal(invoice);
        } else {
            props.showModal(invoice);
        }
    };
    const handleToggleActive = (invoice: Payment) => {
        const uiPaymentId = invoice.id;
        const invoiceIdList = isBundlePayment(invoice)
            ? invoice.invoiceList.map((invoice) => invoice.id)
            : [invoice.id];

        props.toggleActive(uiPaymentId, invoiceIdList);
    };
    return (
        <React.Fragment>
            <SidebarLayout sidebar="right" gap="32">
                <StackLayout gap="24">
                    {props.invoiceDueList.map((invoice) => {
                        return (
                            <InvoiceListItem
                                key={invoice.id}
                                readonly={isLoading}
                                cardType={useAscend ? 'card-layout' : 'check-box-card'}
                                invoice={invoice}
                                active={props.UIActiveInvoices.includes(invoice.id)}
                                dataE2e="payment-details-card"
                                onViewDetailsClicked={() => handleViewDetailsClicked(invoice)}
                                onToggleActive={() => handleToggleActive(invoice)}
                            />
                        );
                    })}
                    {useAscend && (
                        <CardLayout>
                            <CardLayout.Body>
                                <Text style="body 2">
                                    Questions about paying for your policies? Select {'"'}Pay
                                    Electronically{'"'} to view the payment options we offer through
                                    Ascend. For other questions about your policy or payment,{' '}
                                    <a href="#chat-now">contact us</a> for support.
                                </Text>
                            </CardLayout.Body>
                        </CardLayout>
                    )}
                    {result.value.amount !== 0 && !useAscend && (
                        <React.Fragment>
                            <Text style="heading 4">Select your payment method</Text>
                            <RadioGroup
                                onChange={(e) => props.onOptionChange(e.target.value)}
                                value={props.option}
                                id="group-2"
                                items={[
                                    {
                                        title: 'Pay via your bank',
                                        note: "Safe, secure, and no additional fees. You'll see the money withdrawn from your bank account within 3-7 business days.",
                                        value: BANK_VALUE,
                                    },
                                    {
                                        title: 'Pay with a credit card',
                                        note: 'We accept all major credit cards, so use your favorite card to pay your balance and get those frequent flyer miles. Some fees may apply.',
                                        value: CREDIT_CARD_VALUE,
                                    },
                                    ...(!isBroker
                                        ? [
                                              {
                                                  title: 'Get financing',
                                                  note: "We've partnered with First Insurance Financing to offer you a great option for financing your insurance coverage.",
                                                  value: PREMIUM_FINANCE_VALUE,
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        </React.Fragment>
                    )}
                    <SidebarLayout.MobileFooter>
                        <ColumnLayout>
                            {props.option !== PREMIUM_FINANCE_VALUE && (
                                <StackLayout gap="8">
                                    <Text>Total amount: </Text>
                                    <Text style="heading 5">
                                        <MoneyDisplay
                                            value={
                                                props.option === BANK_VALUE
                                                    ? result.value
                                                    : creditCardValue
                                            }
                                        />
                                    </Text>
                                </StackLayout>
                            )}
                            <SidebarLayout.Link panelIndex={1}>Payment Methods</SidebarLayout.Link>
                        </ColumnLayout>
                    </SidebarLayout.MobileFooter>
                </StackLayout>
                <StackLayout gap="32">
                    {useAscend && paymentDisabledMessage && (
                        <StatusMessage status="helptext">{paymentDisabledMessage}</StatusMessage>
                    )}
                    {result.value.amount === 0 ? (
                        <Text style="body 1">
                            Please select which invoice(s) you want to pay now.
                        </Text>
                    ) : (
                        <InvoiceTable>
                            {props.option !== PREMIUM_FINANCE_VALUE ? (
                                <React.Fragment>
                                    <InvoiceTable.Section>
                                        <InvoiceTable.Subtotal title="Balances included in total" />
                                        {sidebarInvoices.map((invoice) => {
                                            const moneyDisplay = (
                                                <MoneyDisplay value={invoice.balance} />
                                            );
                                            return (
                                                <InvoiceTable.Item
                                                    title={invoice.lineOfBusiness}
                                                    key={invoice.id}
                                                >
                                                    {moneyDisplay}
                                                </InvoiceTable.Item>
                                            );
                                        })}
                                    </InvoiceTable.Section>
                                    <InvoiceTable.Section>
                                        <InvoiceTable.Total title={'Total Premium:'}>
                                            <MoneyDisplay value={result.value} />
                                        </InvoiceTable.Total>
                                    </InvoiceTable.Section>
                                </React.Fragment>
                            ) : null}

                            {!useAscend && (
                                <RenderPaymentMethod
                                    value={result.value}
                                    ccValue={creditCardValue}
                                    ccFee={creditCardFee}
                                    option={props.option}
                                    onPayWithPremiumFinance={props.onPayWithPremiumFinance}
                                    showACHModal={props.showACHModal}
                                    showCCModal={props.showCCModal}
                                />
                            )}
                            {useAscend && (
                                <ContinueToAscendButton
                                    invoiceIds={props.activeInvoices}
                                    userId={props.userId}
                                    organizationId={props.organizationId}
                                    disabled={isPaymentDisabled}
                                />
                            )}
                        </InvoiceTable>
                    )}
                </StackLayout>
            </SidebarLayout>
            <hr />
            <PaymentsUpdateBillingInfo />
        </React.Fragment>
    );
}
