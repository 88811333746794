import {
    LineOfBusinessCodeList,
    LineOfBusinessCodeListItem,
    PolicyViewStatusCodeList,
    PolicyViewStatusCodeListItem,
    PolicyBookingType,
    PolicyBookingTypeItem,
} from '@embroker/shotwell-api/enums';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { BorStatus } from './BorStatus';
import { Payment } from '../../payments/types/Payment';
import {
    DisplayPolicyStatus,
    DisplayPolicyStatusList,
    QuotingEngine,
    QuotingEngineList,
} from '../../shopping/types/enums';
import { PolicySection } from './PolicySection';
import { InsuredParty } from './InsuredParty';

export interface DisplayPolicy {
    id: UUID;
    displayName: string;
    policyNumber: string;
    policyNumbersInSeries: string[];
    insurerName: string;
    insurerId: UUID;
    bundleId: Nullable<UUID>;
    cancellationDate: Nullable<Date>;
    premiumPerYear: Nullable<Money>;
    basePremiumWithEmbrokerAccessFee: Nullable<Money>;
    startDate: Date;
    endDate: Date;
    borStatus: BorStatus;
    hasRenewal: boolean;
    hasMultiplePolicies: boolean;
    isReferred?: boolean;
    isDirectBill: boolean;
    solartisPolicyNumber: Nullable<string>;
    manifestId: Nullable<string>;
    viewMode: PolicyViewStatusCodeListItem;
    lineOfBusiness: LineOfBusinessCodeListItem;
    subLineOfBusiness: Nullable<string>;
    policyHistoryDate: Nullable<Date>;
    quotingEngine?: QuotingEngine;
    policyStatus: DisplayPolicyStatus;
    invoiceDue?: Payment;
    bookingType: PolicyBookingTypeItem;
    inRunoff: boolean;
    insuranceApplication?: Nullable<UUID>;
    coverageSectionList: PolicySection[];
    insuredPartiesList: InsuredParty[];
}

export const DisplayPolicy = {
    ...defineValidator<DisplayPolicy>({
        id: UUID.schema,
        displayName: Joi.string(),
        policyNumber: Joi.string().allow(''), // we allow empty string because purchased policies can have empty policy number while their processing is still ongoing
        policyNumbersInSeries: Joi.array().items(Joi.string().allow('')),
        cancellationDate: Joi.date().allow(null),
        insurerName: Joi.string(),
        insurerId: UUID.schema,
        bundleId: UUID.schema.allow(null),
        premiumPerYear: Money.schema.allow(null),
        basePremiumWithEmbrokerAccessFee: Money.schema.allow(null),
        startDate: Joi.date(),
        endDate: Joi.date(),
        borStatus: BorStatus.schema,
        hasRenewal: Joi.boolean(),
        policyHistoryDate: Joi.date().allow(null),
        hasMultiplePolicies: Joi.boolean(),
        solartisPolicyNumber: Joi.string().allow(null, ''),
        manifestId: Joi.any().optional().allow(null, ''),
        isReferred: Joi.boolean().optional(),
        isDirectBill: Joi.boolean().optional(),
        viewMode: Joi.string().valid(...PolicyViewStatusCodeList),
        lineOfBusiness: Joi.string().valid(...LineOfBusinessCodeList),
        subLineOfBusiness: Joi.string().allow(null, ''),
        quotingEngine: Joi.string()
            .valid(...QuotingEngineList)
            .optional(),
        policyStatus: Joi.string().valid(...DisplayPolicyStatusList),
        invoiceDue: Payment.schema.optional(),
        bookingType: Joi.string().valid(...PolicyBookingType),
        inRunoff: Joi.boolean(),
        insuranceApplication: UUID.schema.optional().allow(null),
        coverageSectionList: Joi.array().items(PolicySection.schema),
        insuredPartiesList: Joi.array().items(InsuredParty.schema),
    }),
    create(displayPolicy: DisplayPolicy) {
        return DisplayPolicy.validate(displayPolicy);
    },
};
