import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ClaimRepository, CreateClaimRequest } from '.';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { InvalidClaimRequestSave } from '../../errors';

@injectable()
export class APIClaimRepository implements ClaimRepository {
    public async createClaimRequest(
        claimRequest: CreateClaimRequest,
    ): AsyncResult<UUID, InvalidClaimRequestSave | InvalidArgument> {
        const phoneNumberResult = PhoneNumber.create(claimRequest.contactPersonPhoneNumber);
        if (isErr(phoneNumberResult)) {
            return phoneNumberResult;
        }

        const claimRequestResult = await API.request('policy/user_create_claim_request', {
            policy_id: claimRequest.policyId,
            submitter_name: claimRequest.submitterName,
            contact_person_name: claimRequest.contactPersonName,
            contact_person_email: claimRequest.contactPersonEmail,
            contact_person_phone: phoneNumberResult.value,
            description: claimRequest.description,
            document_list: claimRequest.documentList.map((doc) => {
                return {
                    name: doc.name,
                    file_key: doc.file_key,
                };
            }),
        });
        if (isErr(claimRequestResult)) {
            return Failure(InvalidClaimRequestSave(claimRequest.policyId));
        }
        return Success(claimRequestResult.value.claim_request_id);
    }
}
