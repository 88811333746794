import React, { useCallback, useContext, useRef } from 'react';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { AppTypeCode } from '../../../shopping/types/enums';
import { Immutable, Spinner } from '@embroker/ui-toolkit/v2';
import { ExpandedApplicationView } from '../../../shopping/useCases/GetApplication';
import { QuestionerRenderer, QuestionerRendererRefrence } from './QuestionerRenderer';
import { navigateToErrorPage } from '../../../view/errors';
import { execute } from '@embroker/shotwell/core/UseCase';
import { SaveQuestionerAnswers } from '@app/shoppingQuestioner/useCases/SaveQuestionerAnswers';
import { SubmitApplication } from '@app/shopping/useCases/SubmitApplication';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { URI } from '@embroker/shotwell/core/types/URI';
import { QuestionerPageDefinition } from '@app/shoppingQuestioner/types/QuestionerPageDefinition';
import { AppContext } from '@app/view/AppContext';
import { SaveApplicationLastPage } from '@app/shopping/useCases/SaveApplicationLastPage';
import {
    GetApplicationQuestionerForm,
    GetApplicationQuestionerFormResponse,
} from '@app/shoppingQuestioner/useCases/GetApplicationQuestionerForm';
import { FormEventName, PublishFormEvents } from '@app/shopping/useCases/PublishFormEvents';

export function getLastPageAnswers(
    formData: { [key: string]: unknown },
    questionerPageDefinitions: QuestionerPageDefinition[] | undefined,
): { [key: string]: unknown } {
    if (!questionerPageDefinitions || questionerPageDefinitions.length === 0) {
        return formData;
    }

    function findLastPage(pages: QuestionerPageDefinition[]): QuestionerPageDefinition {
        const lastPage = pages[pages.length - 1];
        if (!lastPage.fields) {
            return findLastPage(lastPage.subPages);
        }
        return lastPage;
    }

    const lastPage = findLastPage(questionerPageDefinitions);
    const fieldKeys = lastPage.fields ? lastPage.fields : [];

    return fieldKeys.reduce((acc, key) => {
        acc[key] = formData[key];
        return acc;
    }, {} as { [key: string]: unknown });
}
interface QuestionerRendererProps {
    appTypeCode?: AppTypeCode[];
    application: Immutable<ExpandedApplicationView>;
}
export function QuestionerApplicationQuestionnaire({ application }: QuestionerRendererProps) {
    const { navigate } = useNavigation();

    const applicationId = application.id as UUID;

    const { activeSession } = useContext(AppContext);
    const userId = activeSession.userId as UUID;
    const questionerRendererRef = useRef<QuestionerRendererRefrence>(null);

    const handleFirstIncompletePageChange = useCallback(
        (pageName: string) => {
            execute(SaveApplicationLastPage, {
                applicationId,
                userId,
                lastPage: pageName,
            });
        },
        [applicationId, userId],
    );

    const getQuestionerFormResp = useUseCase(GetApplicationQuestionerForm, { application });
    const { isLoading, result: getQuestionerFormResult } = getQuestionerFormResp;

    if (isLoading || !getQuestionerFormResult) {
        return <Spinner />;
    }

    if (getQuestionerFormResult && isErr(getQuestionerFormResult)) {
        navigateToErrorPage(navigate, getQuestionerFormResult.errors);
        return null;
    }

    const {
        questionerPageDefinitions,
        formQuestionDefinitions,
        questionerQuestions,
        requestQuestionnaireIds,
    } = getQuestionerFormResult.value as GetApplicationQuestionerFormResponse;

    const handleSaveAndExit = () => {
        // TODO: Do save and exit things here
        navigate('/');
    };

    const handleFormSubmit = async (formData: { [key: string]: unknown }, activePage: string) => {
        const lastPageFormData = getLastPageAnswers(formData, questionerPageDefinitions);
        const saveAnswersResult = await execute(SaveQuestionerAnswers, {
            formData: lastPageFormData,
            questionerQuestions,
            completedPageKeys: [activePage],
            questionnaireIds: requestQuestionnaireIds,
        });
        if (isErr(saveAnswersResult)) {
            navigateToErrorPage(navigate, saveAnswersResult.errors);
        }

        execute(PublishFormEvents, {
            eventName: FormEventName.Submitted,
            formStage: activePage,
            formName: 'Generic Bundle Form',
            applicationId,
            isRenewal: application.isRenewal ?? false,
            shoppingCoverageList: application.shoppingCoverageList,
            appTypeList: [application.appType],
            questionnaireData: application.questionnaireData ?? undefined,
        });

        const submitApplicationResponse = await execute(SubmitApplication, {
            applicationId,
        });
        if (isErr(submitApplicationResponse)) {
            navigateToErrorPage(navigate, submitApplicationResponse.errors);
            return;
        }
        const url = URI.build('/shopping/application/submission', {
            taskId: submitApplicationResponse.value.taskId,
        });
        navigate(url);
    };

    const handlePageComplete = async (formData: { [key: string]: unknown }, activePage: string) => {
        const saveAnswersResult = await execute(SaveQuestionerAnswers, {
            formData,
            questionerQuestions,
            completedPageKeys: [activePage],
            questionnaireIds: requestQuestionnaireIds,
        });
        if (isErr(saveAnswersResult)) {
            navigateToErrorPage(navigate, saveAnswersResult.errors);
            return;
        }

        const questionerQuestionsResult = await execute(GetApplicationQuestionerForm, {
            application,
        });
        if (isErr(questionerQuestionsResult)) {
            navigateToErrorPage(navigate, questionerQuestionsResult.errors);
            return;
        }

        const { formQuestionDefinitions: updatedFormQuestions } =
            questionerQuestionsResult.value as GetApplicationQuestionerFormResponse;
        const formValues = updatedFormQuestions.reduce((acc, question) => {
            if (question.initialValue !== undefined) {
                acc[question.key] = question.initialValue;
            }
            return acc;
        }, {} as { [key: string]: unknown });

        questionerRendererRef.current?.setUnansweredFormValues(formValues);
    };

    const handlePageShow = (activePage: string) => {
        execute(PublishFormEvents, {
            eventName: FormEventName.Viewed,
            formStage: activePage,
            formName: 'Generic Bundle Form',
            applicationId,
            isRenewal: application.isRenewal ?? false,
            shoppingCoverageList: application.shoppingCoverageList,
            appTypeList: [application.appType],
            questionnaireData: application.questionnaireData ?? undefined,
        });
    };

    return (
        <QuestionerRenderer
            pages={questionerPageDefinitions}
            questions={formQuestionDefinitions}
            ref={questionerRendererRef}
            onFormSubmit={handleFormSubmit}
            onSaveAndExit={handleSaveAndExit}
            onPageComplete={handlePageComplete}
            onFirstIncompletePageChange={handleFirstIncompletePageChange}
            onPageShow={handlePageShow}
        />
    );
}
