import { Modal, ModalActions, ModalState, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '@app/view/components/ModalLayout.view';
import { PolicyClaimContactInfo } from '@app/claims/types/PolicyClaimContactInfo';
import { CarrierContactView } from './CarrierContact.view';

interface CarrierContactModalProps {
    modal: ModalState & ModalActions;
    policyClaimContactInfo: Partial<PolicyClaimContactInfo>;
}
export function CarrierContactModal({ modal, policyClaimContactInfo }: CarrierContactModalProps) {
    const { navigate } = useNavigation();

    return (
        <Modal {...modal} size="small" dismissable>
            <ModalLayout
                title="Contact your carrier"
                primaryAction={{
                    label: 'Back to Claims',
                    onClick: () => {
                        modal.hide();
                        navigate('/claims');
                    },
                }}
            >
                <StackLayout gap="24">
                    <Text>
                        To file a claim, please reach out to your carrier from one of the methods
                        below with your claims details.
                    </Text>
                    <CarrierContactView policyClaimContactInfo={policyClaimContactInfo} />
                </StackLayout>
            </ModalLayout>
        </Modal>
    );
}
