import { assertNever } from '@embroker/shotwell/core/assert';

const DocumentTypeEnum = {
    QuoteDocument: 'QuoteDocument',
    CoverageDetails: 'CoverageDetails',
    GeneralTerms: 'GeneralTerms&Conditions',
    BundleApplication: 'BundleApplication',
    ApplicationAttestation: 'ApplicationAttestation',
    ApplicationAttestationWithPapyrus: 'ApplicationAttestationWithPapyrus',
} as const;

export type DocumentType = (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];

export const DocumentType = Object.assign(DocumentTypeEnum, {
    toDisplayName(documentType: DocumentType): string {
        switch (documentType) {
            case DocumentType.QuoteDocument:
                return 'Quote Document';
            case DocumentType.CoverageDetails:
            case DocumentType.GeneralTerms:
                return 'Coverage Details';
            case DocumentType.BundleApplication:
                return 'Combined Application';
            case DocumentType.ApplicationAttestation:
                return 'Application';
            case DocumentType.ApplicationAttestationWithPapyrus:
                return 'Application';
            default:
                assertNever(documentType);
        }
    },
});
