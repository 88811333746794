import React from 'react';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    QuestionType as DataDrivenFormQuestionType,
    DynamicTextProps,
    FormQuestionDefinition,
    FormQuestionStatusMessageProps,
    SelectOption,
} from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { getInterValidations, InterValidation, InterValidationSchema } from './InterValidation';
import { getIntraValidations, IntraValidation, IntraValidationSchema } from './IntraValidation';
import { ValidationTypeProps } from '@app/view/components/DataDrivenForm/types/fieldValidationFactory';
import {
    ConditionalAction,
    ConditionalActionSchema,
    getConditionalEnablementDefinition,
    getConditionalDisplayDefinition,
} from './ConditionalAction';
import {
    AnswerKeysType,
    answerTypeDefinitionMap,
    ARRAY_TYPE_QUESTIONS,
    deserializeOracleAnswer,
} from './OracleAnswerType';
import { RequireAtLeastOne } from '@embroker/ui-toolkit/v2';

// Some questions must be single input questions, regardless of their multiplicity
export const SINGLE_INPUT_QUESTIONS: QuestionType[] = [
    'REVENUE_PROJECTED_YEAR',
    'REVENUE_PREVIOUS_YEAR',
    'NAICS',
];

//
// AnswerSourceTypes definition
export const AnswerSourceTypes = [
    'UNKNOWN',
    'USER',
    'DOCUMENT',
    'CRUNCHBASE',
    'DNB',
    'DEFAULT',
    'TOMBSTONE',
    'WEB_SCRAPING',
] as const;
export type AnswerSourceType = (typeof AnswerSourceTypes)[number];

export const ThirdPartyAnswerSourceTypes: AnswerSourceType[] = ['CRUNCHBASE', 'DNB'] as const;

//
// AnswerSource definition
// https://github.com/embroker/oracle/blob/main/proto/oracle_model.proto#L23-L37
export interface AnswerSource {
    type: AnswerSourceType;
    id: string;
}
const AnswerSourceSchema = Joi.object({
    type: Joi.string().required(),
    id: Joi.string().required().allow(''),
});

//
// AnswerTypes definition
export const AnswerTypes = [
    'TEXT',
    'NUMBER',
    'DATE',
    'BOOLEAN',
    'INTEGER',
    'ADDRESS',
    'FUNDING_ROUND',
    'REVENUE',
    'CURRENCY',
    'DECIMAL',
] as const;
export type AnswerType = (typeof AnswerTypes)[number];

export type OracleAnswerType = RequireAtLeastOne<{
    [key in AnswerKeysType]: unknown[];
}>;
export const OracleAnswerTypeSchema = Joi.object().pattern(
    Joi.string(),
    Joi.array().items(Joi.any()),
);

//
// Answer definition
export interface Answer {
    key: string;
    source: AnswerSource;
    provided_at: string;
    received_at: string;
    type: AnswerType;
    multiplicity: number;
    value: OracleAnswerType;
}
export const AnswerSchema = Joi.object({
    key: Joi.string().required(),
    source: AnswerSourceSchema.required(),
    provided_at: Joi.string().isoDate().required(),
    received_at: Joi.string().isoDate().required(),
    type: Joi.string()
        .required()
        .valid(...AnswerTypes),
    multiplicity: Joi.number().integer().required(),
    value: OracleAnswerTypeSchema.required(),
});

export interface AnswerOptions {
    [key: string]: OracleAnswerType;
}

//
// QuestionTypes definition
export const QuestionTypes = [
    'TEXT',
    'TEXTAREA',
    'NUMBER',
    'DATE',
    'BOOLEAN',
    'BOOLEAN_CHECKBOX',
    'SELECT',
    'FILE',
    'ADDRESS',
    'NAICS',
    'MULTISELECT',
    'PERCENTAGE',
    'CURRENCY',
    'YEAR',
    'ADDRESS',
    'FUNDING_ROUND',
    'REVENUE_PREVIOUS_YEAR',
    'REVENUE_PROJECTED_YEAR',
    'TELEPHONE_NUMBER',
    'EXTERNAL',
    'WEBSITE',
    'EMAIL',
] as const;
export type QuestionType = (typeof QuestionTypes)[number];

export const StatusMessageTypes = ['HELP_TEXT', 'WARNING', 'SUCCESS', 'ERROR', 'INFO'] as const;

type ReadonlyStatusMessageType = typeof StatusMessageTypes;

export type StatusMessageType = ReadonlyStatusMessageType[number];

export const TextTypes = ['WORDING', 'TOOLTIP', 'TITLE', 'LABEL', 'TITLE_PREFILLED'] as const;
export type TextType = (typeof TextTypes)[number] | StatusMessageType;

const TextTypeSchema = [...TextTypes, ...StatusMessageTypes];

const DynamicTextReducerTypes = [
    'SUM',
    'FIRST',
    'LAST',
    'COUNT',
    'MIN',
    'MAX',
    'AVERAGE',
    'CONCAT',
    'PRODUCT',
] as const;
export type DynamicTextReducerType = (typeof DynamicTextReducerTypes)[number];

export interface DynamicText {
    key: string;
    answer_key: string;
    answer_field?: string;
    reducer?: DynamicTextReducerType;
    answer?: Answer;
}

const DynamicTextSchema = Joi.object({
    key: Joi.string().required(),
    answer_key: Joi.string().required(),
    answer_field: Joi.string().optional(),
    reducer: Joi.string()
        .valid(...DynamicTextReducerTypes)
        .optional(),
    answer: AnswerSchema.optional(),
});

//
// Text definition
export interface Text {
    dismissible: boolean;
    type: TextType;
    value: string;
    dynamic_text?: DynamicText[];
}
export const TextSchema = Joi.object({
    dismissible: Joi.boolean().required(),
    type: Joi.string()
        .required()
        .valid(...TextTypeSchema),
    value: Joi.string().required(),
    dynamic_text: Joi.array().items(DynamicTextSchema).optional(),
});

export const AnswerOptionsSchema = Joi.array().items(
    Joi.object({
        text: Joi.array().items(TextSchema).required(),
        value: Joi.object().pattern(Joi.string(), Joi.any()),
    }),
);

//
// OperatorTypes definition
export const OperatorTypes = ['AND', 'OR'] as const;
export type OperatorType = (typeof OperatorTypes)[number];

//
// Question definition
const QuestionerQuestionSchema = Joi.object({
    key: Joi.string().required(),
    type: Joi.string().required(), //.valid(...QuestionTypes)
    multiplicity: Joi.number().integer().required(),
    text: Joi.array().items(TextSchema).required(),
    answer_type: Joi.string().required(),
    current_answer: AnswerSchema.optional(),
    placeholder: Joi.object().pattern(Joi.string(), Joi.any()).optional().allow(null),
    intra_validation: IntraValidationSchema,
    inter_validation: InterValidationSchema,
    answer_options: AnswerOptionsSchema,
    section_key: Joi.string().optional(),
    conditional_actions: Joi.array().items(ConditionalActionSchema).optional().allow(null),
    order: Joi.number().optional(),
});
export interface QuestionerQuestion {
    key: string;
    section_key?: string;
    type: QuestionType;
    multiplicity: number;
    text: Text[];
    answer_type: AnswerType;
    current_answer?: Answer;
    placeholder?: { [key: string]: any } | null;
    intra_validation: IntraValidation;
    inter_validation: InterValidation[][];
    answer_options?: { text: Text[]; value: OracleAnswerType }[];
    categories?: string[]; // TODO: implement categories
    conditional_actions?: ConditionalAction[] | null;
    order?: number;
}

export const getOrderedQuestions = (
    elementDefinitions: FormQuestionDefinition[],
): FormQuestionDefinition[] => {
    return elementDefinitions.sort((a, b) => {
        // If both questions have sort_order, compare them
        if (a.sortOrder !== undefined && b.sortOrder !== undefined) {
            return a.sortOrder - b.sortOrder;
        }

        // If only a has sort_order, it should come first
        if (a.sortOrder !== undefined) {
            return -1;
        }

        // If only b has sort_order, it should come first
        if (b.sortOrder !== undefined) {
            return 1;
        }

        // If neither has sort_order, maintain original order
        return 0;
    });
};

export interface StaticQuestionConfigs {
    staticConditionalActions?: { [key: string]: ConditionalAction[] };
}

export const QuestionerQuestion = {
    ...defineValidator<QuestionerQuestion>(QuestionerQuestionSchema),
    create(question: unknown) {
        return QuestionerQuestion.validate(question);
    },
    getDataDrivenFormQuestion(
        question: QuestionerQuestion,
        staticQuestionConfigs?: StaticQuestionConfigs,
    ): FormQuestionDefinition {
        const hasPrefiledAnswer = isPrefilledAnswer(question.current_answer);
        const hasPrefilledTitle = findTextByType(question.text, 'TITLE_PREFILLED')?.value;
        return {
            key: question.key,
            questionType: getQuestionType(question),
            title: getTextOrHTMLValueByType(
                question,
                hasPrefiledAnswer && hasPrefilledTitle ? 'TITLE_PREFILLED' : 'TITLE',
            ),
            tooltip: getTextValueByType(question, 'TOOLTIP'),
            label: getTextValueByType(question, 'LABEL'),
            placeholder: getPlaceholderValue(question),
            initialValue: getInitialValue(question),
            isRequired: getIsRequired(question),
            validate: getValidation(question),
            isMultiple: getIsMultiple(question),
            selectOptions: getSelectOptions(question),
            conditionalDisplay: getConditionalDisplayDefinition(
                question,
                staticQuestionConfigs?.staticConditionalActions,
            ),
            staticOptions: undefined,
            statusMessage: getStatusMessageByType(question, StatusMessageTypes),
            sortOrder: question.order,
            conditionalEnablement: getConditionalEnablementDefinition(question),
        };
    },
    getDataDrivenFormQuestions(
        questions: QuestionerQuestion[],
        staticQuestionConfigs?: StaticQuestionConfigs,
    ): FormQuestionDefinition[] {
        return questions.map((question) =>
            QuestionerQuestion.getDataDrivenFormQuestion(question, staticQuestionConfigs),
        );
    },
};

export const answerTypeFallbackQuestion: Record<AnswerType, DataDrivenFormQuestionType> = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    BOOLEAN: 'radioGroup',
    INTEGER: 'number',
    ADDRESS: 'addressField',
    FUNDING_ROUND: 'fundraisingRound',
    REVENUE: 'previousYearRevenue',
    CURRENCY: 'currency',
    DECIMAL: 'percentage',
};

const questionTypeToFormFieldType: Record<QuestionType, DataDrivenFormQuestionType> = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    BOOLEAN: 'radioGroup',
    BOOLEAN_CHECKBOX: 'booleanCheckbox',
    SELECT: 'select',
    FILE: 'fileUpload',
    ADDRESS: 'addressField',
    FUNDING_ROUND: 'fundraisingRound',
    NAICS: 'naicsCode',
    TEXTAREA: 'textarea',
    MULTISELECT: 'multiSelect',
    PERCENTAGE: 'percentage',
    CURRENCY: 'currency',
    YEAR: 'yearField',
    REVENUE_PREVIOUS_YEAR: 'previousYearRevenue',
    REVENUE_PROJECTED_YEAR: 'projectedYearRevenue',
    TELEPHONE_NUMBER: 'tel',
    WEBSITE: 'text',
    EMAIL: 'text',
    EXTERNAL: 'hidden',
};

// Refine back-end data contract.
// https://embroker.atlassian.net/browse/EM-43445
export const getIsMultiple = (question: QuestionerQuestion): boolean => {
    const singleInputQuestionTypes = [...SINGLE_INPUT_QUESTIONS, ...ARRAY_TYPE_QUESTIONS];
    if (singleInputQuestionTypes.includes(question.type)) {
        return false;
    }
    return question.multiplicity === -1;
};

export const getTextValueByType = (
    question: QuestionerQuestion,
    type: TextType,
): string | undefined => {
    return question.text.find((text) => text.type === type)?.value;
};

export const getInitialValue = (question: QuestionerQuestion): unknown => {
    return deserializeOracleAnswer(question);
};

// TODO - logic to extract placeholder
// Key is unknown at the moment
// Refine back-end data contract.
// https://embroker.atlassian.net/browse/EM-43445
export const PLACEHOLDER_KEY = 'PLACEHOLDER_KEY';
export const getPlaceholderValue = (question: QuestionerQuestion): string | undefined => {
    if (question.placeholder) {
        return question.placeholder[PLACEHOLDER_KEY]
            ? question.placeholder[PLACEHOLDER_KEY].value
            : undefined;
    }
    return undefined;
};

export const getQuestionType = (question: QuestionerQuestion): DataDrivenFormQuestionType => {
    const questionType = questionTypeToFormFieldType[question.type];
    if (!questionType) {
        const errorMsg = `Data driven questionnaire question type:${questionType} not found. answer_type: ${question.answer_type}.`;
        if (process.env.NODE_ENV === 'production') {
            // This scenario should never happen we are being very defensive here
            // If question type does not return a questionType then use fallback based on `answer_type`
            //
            // We still want to surface an error in the logs to catch this scenario
            container.get<Logger>(Log).error(errorMsg);
        } else {
            throw new Error(errorMsg);
        }

        const fallback = answerTypeFallbackQuestion[question.answer_type];
        if (fallback) {
            // If answer_type also does not return a questionType then return `textarea` as catch all fall back
            return fallback;
        }
        return 'textarea';
    }
    return questionType;
};

export const getStatusMessageByType = (
    question: QuestionerQuestion,
    type: ReadonlyStatusMessageType,
): FormQuestionStatusMessageProps | undefined => {
    const parseTypeToStatus = (type?: StatusMessageType) => {
        if (!type) {
            return 'info';
        }
        switch (type) {
            case 'ERROR':
                return 'error';
            case 'WARNING':
                return 'warning';
            case 'SUCCESS':
                return 'success';
            case 'INFO':
                return 'info';
            case 'HELP_TEXT':
                // TODO: https://embroker.atlassian.net/browse/EM-49871
                // This is a temporary solution until the data is updated
                return 'info';
            default:
                return 'helptext';
        }
    };
    const content = parseTextOrHTMLValue(
        question.text.find((text) => type.find((item) => item === text.type))?.value || undefined,
    );
    return content
        ? {
              content,
              status: parseTypeToStatus(
                  question.text.find((text) => type.find((item) => item === text.type))?.type as
                      | StatusMessageType
                      | undefined,
              ),
          }
        : undefined;
};

export const parseTextOrHTMLValue = (value: string | undefined) => {
    const isStringifiedHTML = (value: string) =>
        value.trim().startsWith('<') && value.trim().endsWith('>');

    const parseStringToHTML = (value: string) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
    };

    const handleValue = (value: string | undefined) =>
        value && isStringifiedHTML(value) ? parseStringToHTML(value) : value;

    return handleValue(value);
};

export const hasDynamicText = (text?: Text) => {
    return Boolean(text && text.dynamic_text && text.dynamic_text.length > 0);
};

// TODO: to be implemented/extended in https://embroker.atlassian.net/browse/EM-45509
export const reduceAnswerValues = (
    answer: Answer,
    reducer: DynamicTextReducerType = 'FIRST',
): unknown | undefined => {
    const answerTypeDefinition = answerTypeDefinitionMap[answer.type];
    const answerKeyType = answerTypeDefinition.answerKeyType;
    const answerValue = answer.value[answerKeyType];
    if (answerValue === undefined) {
        return;
    }
    if (answer.multiplicity === 1) {
        return answerValue[0];
    }
    switch (reducer) {
        case 'FIRST': {
            return answerValue[0];
        }
    }
};

export const getFallbackFromAnswer = (
    answer: Answer,
    reducer?: DynamicTextReducerType,
    valueObjField?: string,
): string | undefined => {
    const answerValue = reduceAnswerValues(answer, reducer);
    if (['number', 'string'].includes(typeof answerValue)) {
        return String(answerValue);
    }
    if (answerValue && typeof answerValue === 'object' && valueObjField) {
        return String(answerValue[valueObjField as keyof typeof answerValue]);
    }
};

export const QUENTIN_FIELD_MAP: Record<string, string> = {
    // fundraising round fields
    date: 'fundraiseDate',
    amount_raised: 'moneyRaised',
    'amount_raised.unit_amount': 'moneyRaised',
    lead_investor: 'leadInvestor',
    // location fields
    address_line1: 'addressLine1',
    address_line2: 'addressLine2',
    city: 'city',
    state_or_province: 'state',
    postal_code: 'zip',
    county: 'county',
};

export const parseDynamicText = (text: Text | undefined): DynamicTextProps | undefined => {
    if (!text) {
        return;
    }
    const dynamicText = text.dynamic_text;
    if (dynamicText && dynamicText.length > 0) {
        return dynamicText.reduce(
            (acc, dynamicText) => {
                const key = dynamicText.key;
                const answer = dynamicText.answer;
                const interpolations: DynamicTextProps['interpolations'] = {
                    ...acc.interpolations,
                    [key]: {
                        questionKey: dynamicText.answer_key,
                        questionField: dynamicText.answer_field
                            ? QUENTIN_FIELD_MAP[dynamicText.answer_field]
                            : undefined,
                        reducer: dynamicText.reducer,
                    },
                };
                // build a fallback text based on the provided answer
                if (answer) {
                    interpolations[key] = {
                        ...interpolations[key],
                        fallbackText: getFallbackFromAnswer(
                            answer,
                            dynamicText.reducer,
                            dynamicText.answer_field,
                        ),
                    };
                }
                return { ...acc, interpolations };
            },
            {
                template: text.value,
                interpolations: {},
            },
        );
    }
};

const findTextByType = (texts: Text[], type: TextType) => texts.find((text) => text.type === type);

export const getTextOrHTMLValueByType = (question: QuestionerQuestion, type: TextType) => {
    const text = findTextByType(question.text, type);
    if (hasDynamicText(text)) {
        return parseDynamicText(text);
    }
    return parseTextOrHTMLValue(text?.value);
};

export const getIsRequired = (question: QuestionerQuestion): boolean => {
    return Boolean(question.intra_validation?.required);
};

type SelectOptionsType = SelectOption<string | number | boolean>[];
export const getSelectOptions = (question: QuestionerQuestion): SelectOptionsType | undefined => {
    if (question.answer_options) {
        const answerOptions = question.answer_options;

        const answerTypeDefinition = answerTypeDefinitionMap[question.answer_type];
        const answerKeyType = answerTypeDefinition.answerKeyType;

        return answerOptions.map((option) => {
            // unwrap oracle answer and select the first element in the value array
            const value = option.value[answerKeyType] && option.value[answerKeyType][0];
            return {
                title:
                    option.text.find((text) => text.type === 'TITLE')?.value ||
                    option.text[0].value,
                value,
                tooltipText: option.text.find((text) => text.type === 'TOOLTIP')?.value,
            };
        }) as SelectOptionsType;
    }

    // If there are no answer options then we have some fallback logic to handle this for BOOLEAN questionType
    if (question.type === 'BOOLEAN') {
        return [
            { title: 'Yes', value: true },
            { title: 'No', value: false },
        ];
    }
};

export function getValidation(question: QuestionerQuestion): ValidationTypeProps[] | undefined {
    const intraValidations: ValidationTypeProps[] = getIntraValidations(question) || [];
    const interValidations = getInterValidations(question) || [];

    const validations = [...intraValidations, ...interValidations];
    if (validations.length) {
        return validations;
    }
}

export function isPrefilledAnswer(answer?: Answer): boolean {
    return (
        answer?.source.type.toUpperCase() === 'CRUNCHBASE' ||
        answer?.source.type.toUpperCase() === 'DNB'
    );
}
