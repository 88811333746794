import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { Form, StackLayout, InputStatusMessage } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Location as LocationType } from '../../entities/Location';
import {
    LocationFieldSet,
    locationFieldFormatValidationError,
    locationFieldValidator,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';
import { InvalidArgument } from '@embroker/shotwell/core/Error';

export type LocationData = Immutable<EntityProps<LocationType>>;
export type LocationErrors = Immutable<{
    [K in keyof LocationData as string]?: InputStatusMessage[];
}>;

export interface LocationProps {
    data: LocationData;
    onChange(newValue: LocationData): void;
    readOnly?: boolean;
    readonly displaySquareFootage?: boolean;
    readonly preventStateChange?: boolean;
    errors?: LocationErrors;
}

const hasErrors = (errors: LocationErrors) => {
    for (const field in errors) {
        const error = errors[field];
        if (Array.isArray(error) && error.length > 0) {
            return true;
        }
    }
    return false;
};

type ValidatorResult = {
    errors?: InvalidArgument[];
};

const formatMessages = (validatorResult: ValidatorResult) => {
    const { errors } = validatorResult;
    if (!errors) {
        return [];
    }

    return errors.map((error) => locationFieldFormatValidationError(error));
};

export const Location = ({
    data,
    onChange,
    readOnly,
    displaySquareFootage,
    preventStateChange = false,
    errors = {},
}: LocationProps) => {
    const updateSquareFootage = (event: { target: { value: string } }) => {
        if (event.target.value) {
            const parsedValue = Number.parseFloat(event.target.value);
            onChange({
                ...data,
                squareFootageOccupied: !Number.isNaN(parsedValue) ? parsedValue : null,
            });
        }
    };

    const handleChange = (newValue: Partial<MailingAddress>) => {
        onChange({
            ...data,
            ...newValue,
        });
    };

    const validateMailingAddress = (data: LocationData) => {
        const result = MailingAddress.validate(data) as ValidatorResult;
        const { error } = locationFieldValidator.validate(data, { abortEarly: false });
        if (error?.details.find((detail) => detail.path.some((item) => item === 'zip'))) {
            if (result.errors === undefined) {
                result.errors = [];
            }
            result?.errors?.push(
                InvalidArgument({
                    argument: 'zip',
                    validator: 'zipCode.fromState',
                    value: data.zip,
                }),
            );
        }
        return result;
    };

    const shouldDisplayErrors = hasErrors(errors);
    const messages = shouldDisplayErrors ? formatMessages(validateMailingAddress(data)) : [];

    return (
        <StackLayout>
            <LocationFieldSet
                fieldValue={data}
                onChange={handleChange}
                messages={messages}
                disableState={preventStateChange}
                readOnly={readOnly}
            />
            {displaySquareFootage ? (
                <Form.Field
                    data-e2e="square-footage"
                    label="Square Footage"
                    type="number"
                    messages={errors.squareFootageOccupied}
                    inputProps={{
                        onChange: updateSquareFootage,
                        value: data.squareFootageOccupied ?? undefined,
                    }}
                    readOnly={readOnly}
                />
            ) : null}
        </StackLayout>
    );
};
