import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileReportGetAQuoteClick } from '@app/public/entities/events';
import { AppTypeCode } from '@app/shopping/types/enums';

export interface PublishRiskProfileReportGetAQuoteClickEvent extends UseCase {
    execute(request: {
        appTypeCode?: AppTypeCode;
    }): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileReportGetAQuoteClickUseCase
    extends UseCase
    implements PublishRiskProfileReportGetAQuoteClickEvent
{
    public static type = Symbol('RiskProfile/PublishRiskProfileReportGetAQuoteClickEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        appTypeCode,
    }: {
        appTypeCode?: AppTypeCode;
    }): AsyncResult<void, InvalidArgument | OperationFailed> {
        const getAQuoteClickEvent: RiskProfileReportGetAQuoteClick = {
            origin: 'RiskProfile',
            name: 'GetAQuoteClick',
            appTypeCode,
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };

        await this.eventBus.publish(getAQuoteClickEvent);

        return Success();
    }
}

export const PublishRiskProfileReportGetAQuoteClickEvent: UseCaseClass<PublishRiskProfileReportGetAQuoteClickEvent> =
    PublishRiskProfileReportGetAQuoteClickUseCase;
