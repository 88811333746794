import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Result, Success, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { BundleQuote } from '../entities/BundleQuote';
import { BundleCoverageType } from '../types/BundleQuoteCoverage';
import { BundleQuoteOptions } from '../types/BundleQuoteOptions';
import { ReQuoteBundle } from './ReQuoteBundle';
import { CoverageCatalog } from '../CoverageCatalog';
import { GetApplication } from '@app/shopping/useCases/GetApplication';
import { QuestionnaireData } from '../types/BundleQuoteQuestionnaireData';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { URI } from '@embroker/shotwell/core/types/URI';
import { MutuallyExclusiveProps } from '@embroker/ui-toolkit/v2';

export interface GetRecommendedCoverageQuotesRequest {
    abortSignal: AbortSignal;
    bundleQuote: BundleQuote;
}

export interface GetRecommendedCoverageQuotes extends UseCase {
    execute(
        request: GetRecommendedCoverageQuotesRequest,
    ): AsyncResult<GetRecommendedCoverageQuotesResponse, never>;
}

export const CoveragePackageTypeEnum = {
    starter: 'starter',
    enhanced: 'enhanced',
    deluxe: 'deluxe',
} as const;

export const CoveragePackageTypeList = [
    CoveragePackageTypeEnum.starter,
    CoveragePackageTypeEnum.enhanced,
    CoveragePackageTypeEnum.deluxe,
] as const;
export type CoveragePackageType = (typeof CoveragePackageTypeList)[number];

export type RecommendCoverageQuotes = Partial<{
    [key in CoveragePackageType]: BundleQuote;
}>;

export type GetRecommendedCoverageQuotesResponse = MutuallyExclusiveProps<{
    recommendCoverageQuotes: RecommendCoverageQuotes;
    redirectUrl: URI;
}>;

export interface GetRecommendedCoverageQuotesErrorResponse {
    redirectUrl: URI;
}

class GetRecommendedCoverageQuotesUseCase extends UseCase implements GetRecommendedCoverageQuotes {
    public static type = Symbol('BundleQuote/GetRecommendedCoverageQuotes');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute({
        abortSignal,
        bundleQuote,
    }: GetRecommendedCoverageQuotesRequest): AsyncResult<
        GetRecommendedCoverageQuotesResponse,
        never
    > {
        const applicationId = bundleQuote.applicationId;
        const quotePageUrl = URI.build('/shopping/bundle/coverage', {
            applicationId: bundleQuote.applicationId,
        });

        const getApplicationResult = await execute(GetApplication, {
            applicationId,
        });
        if (!isOK(getApplicationResult)) {
            return Success({ redirectUrl: quotePageUrl });
        }

        const questionnaireDataResp = QuestionnaireData.deserializeQuestionnaireData(
            getApplicationResult.value.application.questionnaireData,
        );

        if (!isOK(questionnaireDataResp)) {
            this.logger.error(
                'GetRecommendedCoverageQuotes: failed to deserializeQuestionnaireData',
                questionnaireDataResp.errors,
            );
            return Success({ redirectUrl: quotePageUrl });
        }

        const questionnaireData = questionnaireDataResp.value;
        const recommendCoverageQuotes = {} as RecommendCoverageQuotes;

        const starterCoverage = await getRequoteForPackageType({
            abortSignal,
            bundleQuote,
            packageType: CoveragePackageTypeEnum.starter,
            questionnaireData,
        });

        if (isOK(starterCoverage)) {
            recommendCoverageQuotes.starter = starterCoverage.value;
        } else {
            this.logger.error(
                'GetRecommendedCoverageQuotes: failed to get starter coverage',
                starterCoverage.errors,
            );
        }

        const enhancedCoverage = await getRequoteForPackageType({
            abortSignal,
            bundleQuote,
            packageType: CoveragePackageTypeEnum.enhanced,
            questionnaireData,
        });

        if (isOK(enhancedCoverage)) {
            recommendCoverageQuotes.enhanced = enhancedCoverage.value;
        } else {
            this.logger.error(
                'GetRecommendedCoverageQuotes: failed to get enhanced coverage',
                enhancedCoverage.errors,
            );
        }

        const deluxeCoverage = await getRequoteForPackageType({
            abortSignal,
            bundleQuote,
            packageType: CoveragePackageTypeEnum.deluxe,
            questionnaireData,
        });

        if (isOK(deluxeCoverage)) {
            recommendCoverageQuotes.deluxe = deluxeCoverage.value;
        } else {
            this.logger.error(
                'GetRecommendedCoverageQuotes: failed to get deluxe coverage',
                deluxeCoverage.errors,
            );
        }

        if (Object.keys(recommendCoverageQuotes).length > 1) {
            return Success({ recommendCoverageQuotes });
        }

        return Success({ redirectUrl: quotePageUrl });
    }
}

export const GetRecommendedCoverageQuotes: UseCaseClass<GetRecommendedCoverageQuotes> =
    GetRecommendedCoverageQuotesUseCase;

interface GetRequoteProps extends GetRecommendedCoverageQuotesRequest {
    packageType: CoveragePackageType;
    bundleQuote: BundleQuote;
    questionnaireData: QuestionnaireData;
}

export const getRequoteForPackageType = async ({
    bundleQuote,
    abortSignal,
    packageType,
    questionnaireData,
}: GetRequoteProps): AsyncResult<BundleQuote, InvalidArgument | OperationFailed> => {
    const quoteOptionsMapRespTierThree = getQuoteOptionsMap(
        bundleQuote,
        packageType,
        questionnaireData,
    );

    if (!isOK(quoteOptionsMapRespTierThree)) {
        return quoteOptionsMapRespTierThree;
    }

    const reQuoteBundleResp = await execute(ReQuoteBundle, {
        applicationId: bundleQuote.applicationId,
        quoteOptionsMap: quoteOptionsMapRespTierThree.value,
        abortSignal,
    });

    if (!isOK(reQuoteBundleResp)) {
        return Failure(OperationFailed({ message: 'ReQuoteBundle useCase failed' }));
    }

    return Success(reQuoteBundleResp.value.bundleQuote.value);
};

const getQuoteOptionsMap = (
    bundleQuote: BundleQuote,
    packageType: CoveragePackageType,
    questionnaireData: QuestionnaireData,
): Result<Map<BundleCoverageType, BundleQuoteOptions>, InvalidArgument | OperationFailed> => {
    const quoteOptionsMap = new Map<BundleCoverageType, BundleQuoteOptions>();

    const enabledCoverages = bundleQuote.getEnabledCoverages();
    for (const coverage of enabledCoverages) {
        const coverageDefinition = CoverageCatalog.findCoverageDefinitionByType(coverage.type);
        if (!coverageDefinition) {
            return Failure(
                InvalidArgument({ argument: 'Coverage definition', value: coverage.type }),
            );
        }

        if (coverageDefinition.getRecomendedQuoteOptions) {
            const quoteOptions = coverageDefinition.getRecomendedQuoteOptions(
                bundleQuote,
                packageType,
                questionnaireData,
            );
            if (!quoteOptions) {
                return Failure(
                    OperationFailed({
                        message: `Cannot find quoteOptions for coverage: ${coverage.type} and packageType: ${packageType}`,
                    }),
                );
            }
            quoteOptionsMap.set(coverage.type, quoteOptions);
        }
    }
    return Success(quoteOptionsMap);
};
