import { StackLayout, Text, Label } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { PolicyClaimContactInfo } from '@app/claims/types/PolicyClaimContactInfo';
import { PhoneNumberDisplay } from '@embroker/shotwell/view/components/PhoneNumberDisplay';
import { Link } from '@app/view/components';
import { InformationItem } from './InformationItem.view';

interface CarrierContactViewProps {
    policyClaimContactInfo: Partial<PolicyClaimContactInfo>;
}
export function CarrierContactView({ policyClaimContactInfo }: CarrierContactViewProps) {
    const { claimWebsite, claimEmail, claimPhoneNumber, claimPhoneExtension, insurerName } =
        policyClaimContactInfo;
    return (
        <StackLayout gap="8">
            <Label>CARRIER CLAIM CONTACT INFO</Label>
            <Text>{insurerName}</Text>
            {claimWebsite && (
                <InformationItem iconName="browser">
                    <Link
                        data-e2e="file-claim-carrier-website-wrapper"
                        href={'https://' + claimWebsite}
                        target="_blank"
                    >
                        {claimWebsite}
                    </Link>
                </InformationItem>
            )}
            {claimEmail && (
                <InformationItem iconName="envelope">
                    <Link data-e2e="file-claim-carrier-email-wrapper" href={'mailto:' + claimEmail}>
                        {claimEmail}
                    </Link>
                </InformationItem>
            )}
            {claimPhoneNumber && (
                <InformationItem iconName="phone">
                    <Link
                        className="u-text" // u-text is needed here to prevent Link from getting flex-grow: 1
                        data-e2e="file-claim-phone-number"
                        href={'tel:' + claimPhoneNumber}
                    >
                        <PhoneNumberDisplay value={claimPhoneNumber} />
                    </Link>
                    {claimPhoneExtension && <Text style="default">etx. {claimPhoneExtension}</Text>}
                </InformationItem>
            )}
        </StackLayout>
    );
}
