import {
    Button,
    ButtonBar,
    ColumnLayout,
    StackLayout,
    Text,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import React, { PropsWithChildren } from 'react';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';

export interface BundleQuoteRateItem {
    label: string;
    value: React.ReactNode;
}

export interface BundleQuoteTotalCardProps {
    buttonContent: string;
    label: React.ReactNode;
    onClick: () => void;
    title: React.ReactNode;
    titleTooltip: React.ReactNode;
    disableButton?: boolean;
    rates?: BundleQuoteRateItem[];
}

export function BundleQuoteTotalCard({
    children,
    buttonContent,
    label,
    onClick,
    title = 'Quote Total',
    titleTooltip,
    disableButton,
    rates,
}: PropsWithChildren<BundleQuoteTotalCardProps>) {
    const removeGap = !title && !titleTooltip && !rates && !label;
    return (
        <BundleQuoteCardLayout>
            <StackLayout gap={removeGap ? 'none' : '24'}>
                <StackLayout gap="12">
                    <StackLayout gap="none">
                        <ColumnLayout gap="4" center>
                            <Text style="heading 4" data-e2e="quote-total">
                                {title}

                                {titleTooltip ? (
                                    <Tooltip
                                        iconSize="small"
                                        iconColor="ui-500"
                                        placement="top"
                                        text={titleTooltip}
                                    />
                                ) : null}
                            </Text>
                        </ColumnLayout>
                        {children ? (
                            <Text style="heading 1" as="p" data-e2e="total-premium-value">
                                {children}
                            </Text>
                        ) : null}
                        {rates && rates.length > 0 && (
                            <StackLayout gap="20">
                                <span />
                                <StackLayout gap="4">
                                    {rates.map(({ label, value }, i) => (
                                        <ColumnLayout key={i} split="-1">
                                            <Text style="body 1">{label}</Text>
                                            <Text style="body 1">{value}</Text>
                                        </ColumnLayout>
                                    ))}
                                </StackLayout>
                            </StackLayout>
                        )}
                    </StackLayout>
                    {label ? (
                        <Text style="body 1" as="p" data-e2="quote-expiration">
                            {label}
                        </Text>
                    ) : null}
                </StackLayout>
                <ButtonBar
                    responsive={{ screenWidth: { smallerThan: Infinity } }}
                    gap={removeGap ? 'none' : '16'}
                >
                    <Button data-e2e="cta-button" onClick={onClick} disabled={disableButton}>
                        {buttonContent}
                    </Button>
                </ButtonBar>
            </StackLayout>
        </BundleQuoteCardLayout>
    );
}
