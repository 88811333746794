import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import { State } from '@embroker/shotwell/core/types/StateList';
import { Nullable } from '@embroker/shotwell/core/types';
import React from 'react';
import {
    StateDisclosureModal as StateDisclosureModalView,
    StateDisclosureModalProps as StateDisclosureModalViewProps,
} from './StateDisclosureModal.view';

export interface StateDisclosureModalProps extends StateDisclosureModalViewProps {
    modal: ModalState & ModalActions;
    state?: Nullable<State>;
    companyName: string;
    fullName: string;
}

export function StateDisclosureModal({
    modal,
    state,
    companyName,
    fullName,
}: StateDisclosureModalProps) {
    return (
        <Modal {...modal} size="medium">
            <StateDisclosureModalView state={state} companyName={companyName} fullName={fullName} />
        </Modal>
    );
}
