import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Field,
    FieldMap,
    FormDataType,
    OpaqueForm,
    createForm,
    useForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { FormFieldViewDefinition } from '../components/DataDrivenForm';
import {
    Immutable,
    InputType,
    StatusMessageProps,
    TextProps,
    usePrevious,
    useStepper,
} from '@embroker/ui-toolkit/v2';
import { Success } from '@embroker/shotwell/core/types/Result';
import { WizardForm, WizardPageDefinition, WizardPages } from '../../../hooks/useWizardForm';
import { FormFields, formFieldsFactory } from '../types/formFieldsFactory';
import { ComplexFormFieldType } from '../types/ComplexFieldTypes';
import { ValidationTypeProps, buildFieldValidator } from '../types/fieldValidationFactory';
import { DynamicTextReducerType } from '@app/shoppingQuestioner/types/QuestionerQuestionType';

type InitialValueType = unknown;

export type FormPageDefinition = {
    name: string;
    title?: string;
    fields: string[];
    isInitial?: boolean;
};

export interface DataDrivenFormProps {
    formQuestions: FormQuestionDefinition[];
    formPages?: FormPageDefinition[];
    onPageChange?: (page: WizardPageDefinition<OpaqueForm<{ [key: string]: any }>>) => void;
    onHiddenPagesChange?: (index: string[]) => void;
    onFormSubmit: (formValue: { [key: string]: unknown }) => void;
    onPageComplete: (formValue: { [key: string]: unknown }) => void;
    beforeNext?: (
        wizardForm: Pick<WizardForm<any>, 'activePageIndex' | 'value' | 'setValue'>,
    ) => Promise<void>;
    submitText?: string;
    onFirstPageBackButtonClicked?: () => void;
}

export type QuestionType = InputType | ComplexFormFieldType;

export interface SelectOption<T> {
    title: string;
    value: T;
    tooltipText?: string;
}

export type ConditionalDisplayOptions = ConditionalDisplayOption[][];
export type ConditionalDisplayOption = {
    displayWhen: {
        questionKey: string;
        condition: Omit<ValidationTypeProps, 'conditional'>;
        questionField?: string;
        questionAggregator?: string;
    };
};

export type ConditionalEnablementOptions = ConditionalEnablementOption[][];
export type ConditionalEnablementOption = {
    enableWhen: { questionKey: string; condition: Omit<ValidationTypeProps, 'conditional'> };
};

export interface DdFormFieldSetProps {
    title: React.ReactNode;
    questions: string[];
}

export type StaticFieldOptions = {
    statusMessageProps?: StatusMessageProps;
    fieldSetProps?: DdFormFieldSetProps;
    textElementProps?: TextProps;
};

export type FormQuestionStatusMessageProps = {
    status: StatusMessageProps['status'];
    content: string | React.ReactNode;
};

export interface DynamicTextInterpolation {
    questionKey: string;
    reducer?: DynamicTextReducerType;
    questionField?: string;
    fallbackText?: string;
}

export type DynamicTextProps = {
    template: string;
    interpolations: {
        [key: string]: DynamicTextInterpolation;
    };
};

export function isDynamicTextProps(obj: unknown): obj is DynamicTextProps {
    if (!obj || typeof obj !== 'object') {
        return false;
    }
    return 'template' in obj;
}

export interface FormQuestionDefinition {
    key: string;
    questionType: QuestionType;
    title?: string | React.ReactNode | DynamicTextProps;
    tooltip?: string;
    label?: string;
    placeholder?: string;
    initialValue?: InitialValueType;
    isRequired?: boolean;
    validate?: ValidationTypeProps[];
    selectOptions?: SelectOption<string | boolean | number>[];
    conditionalDisplay?: ConditionalDisplayOptions;
    staticOptions?: StaticFieldOptions;
    isMultiple?: boolean;
    statusMessage?: FormQuestionStatusMessageProps;
    sortOrder?: number;
    conditionalEnablement?: ConditionalEnablementOptions;
}

export const getInitialData = (
    formQuestionDefinitions: FormQuestionDefinition[],
): { [key: string]: unknown } => {
    return formQuestionDefinitions.reduce((acc, { key, initialValue }) => {
        acc[key] = initialValue;
        return acc;
    }, {} as { [key: string]: unknown });
};

export const createDataDrivenForm = (
    fields: FormFields,
    onFormSubmit: (formValue: { [key: string]: unknown }) => void,
) => {
    return createForm({
        fields,
        formatSubmitErrors(errors: any) {
            return ['Sorry, something went wrong. Please try again later.'];
        },
        submit: async (formData: { [key: string]: unknown }) => {
            onFormSubmit(formData);
            return Success({ formData });
        },
    });
};

export const getHiddenConditionFromDependencyChain = (
    values: { readonly [x: string]: unknown },
    conditionalDisplayOptions: ConditionalDisplayOption[] = [],
): boolean => {
    if (!conditionalDisplayOptions.length) {
        return false;
    }

    return conditionalDisplayOptions.some((conditionalDisplay) => {
        const { questionKey, condition, questionField, questionAggregator } =
            conditionalDisplay.displayWhen;

        const fieldValue = values[questionKey];

        const isDependentFieldPresent = questionKey in values;

        const baseValidator = buildFieldValidator(condition);

        const displayValidator = isDependentFieldPresent
            ? baseValidator.required()
            : baseValidator.optional();

        // likely the provided answer is multiplicitiy -1
        if (Array.isArray(fieldValue)) {
            // TODO: The logic here is a short term solution to handle conditional display derived from funding rounds
            // Will be cleaned out in https://embroker.atlassian.net/browse/EM-47474
            if (questionAggregator === 'SUM') {
                if (questionField === 'amount_raised.unit_amount') {
                    const amountValue = fieldValue.reduce(
                        (accumulator: unknown, currentValue: unknown): number => {
                            if (typeof accumulator !== 'number') {
                                return 0;
                            }

                            if (
                                typeof currentValue === 'object' &&
                                currentValue !== null &&
                                'moneyRaised' in currentValue
                            ) {
                                return accumulator + Number(currentValue.moneyRaised);
                            }

                            return 0;
                        },
                        0,
                    );
                    const { error } = displayValidator.validate(amountValue);
                    return Boolean(error);
                }
            }

            return fieldValue.every((value) => {
                if (questionField === 'date.year') {
                    const yearValue = (value as any)?.fundraiseDate?.getFullYear
                        ? (value as any)?.fundraiseDate?.getFullYear()
                        : -1;
                    const { error } = displayValidator.validate(yearValue);
                    return Boolean(error);
                }

                const { error } = displayValidator.validate(value);
                return Boolean(error);
            });
        }

        const { error } = displayValidator.validate(fieldValue);
        return Boolean(error);
    });
};

export const isFieldHidden = (
    formQuestionDefinitions: FormQuestionDefinition[],
    values: { readonly [x: string]: unknown },
    question: FormQuestionDefinition,
): boolean => {
    const conditionalDisplayOptions = question.conditionalDisplay || [];

    const staticQuestionKeys =
        (question.questionType === 'static' && question.staticOptions?.fieldSetProps?.questions) ||
        [];
    const staticQuestions = staticQuestionKeys.map((field) => {
        return formQuestionDefinitions.find(({ key }) => field === key) as FormQuestionDefinition;
    });

    const hasStaticQuestions = staticQuestions.length > 0;

    if (!conditionalDisplayOptions.length && !hasStaticQuestions) {
        return false;
    }

    const isHidden = conditionalDisplayOptions.every((conditionals) =>
        getHiddenConditionFromDependencyChain(values, conditionals),
    );

    if (hasStaticQuestions) {
        return (
            isHidden &&
            staticQuestions.every((staticQuestion) =>
                isFieldHidden(formQuestionDefinitions, values, staticQuestion),
            )
        );
    }

    return isHidden;
};

export const getEnabledConditionFromDependencyChain = (
    values: { readonly [x: string]: unknown },
    conditionalEnablementOptions: ConditionalEnablementOption[] = [],
): boolean => {
    if (!conditionalEnablementOptions.length) {
        return true;
    }

    return conditionalEnablementOptions.some((conditionalEnablement) => {
        const { questionKey, condition } = conditionalEnablement.enableWhen;
        const fieldValue = values[questionKey];
        const isDependentFieldPresent = questionKey in values;

        const baseValidator = buildFieldValidator(condition);
        const displayValidator = isDependentFieldPresent
            ? baseValidator.required()
            : baseValidator.optional();

        const { error } = displayValidator.validate(!!fieldValue);

        return !error;
    });
};

export const isFieldEnabled = (
    values: { readonly [x: string]: unknown },
    question: FormQuestionDefinition,
): boolean => {
    const conditionalDisplayOptions = question.conditionalEnablement || [];
    if (!conditionalDisplayOptions.length) {
        return true;
    }

    const isEnabled = conditionalDisplayOptions.every((conditionals) =>
        getEnabledConditionFromDependencyChain(values, conditionals),
    );

    return isEnabled;
};

export const isValidInputValueForDynamicText = (
    inputValue: unknown,
): inputValue is string | number => {
    try {
        const isValidStr = typeof inputValue === 'string' && inputValue.length > 0;
        const isValidNum = typeof inputValue === 'number';
        return isValidStr || isValidNum;
    } catch {
        return false;
    }
};

// TODO: to be implemented/extended in https://embroker.atlassian.net/browse/EM-45509
export const reduceFormQuestionValues = (
    fieldValue: unknown[],
    reducer: DynamicTextReducerType = 'FIRST',
): unknown => {
    if (fieldValue.length === 1) {
        return fieldValue[0];
    }
    switch (reducer) {
        case 'FIRST': {
            return fieldValue[0];
        }
        default: {
            return;
        }
    }
};

export const getTextValueFromQuestion = (
    formValue: { [key: string]: unknown },
    questionKey: string,
    reducer?: DynamicTextReducerType,
    questionField?: string,
) => {
    let fieldValue = formValue[questionKey];
    if (Array.isArray(fieldValue)) {
        fieldValue = reduceFormQuestionValues(fieldValue as unknown[], reducer);
    }
    if (questionField && fieldValue && typeof fieldValue === 'object') {
        return fieldValue[questionField as keyof typeof fieldValue];
    }
    return fieldValue;
};

export const formatDynamicText = (
    dynamicText: DynamicTextProps,
    formValue: { [key: string]: unknown },
) => {
    const { template, interpolations } = dynamicText;
    // regex to match interpolations like {{fieldA.keyB}} or {{fieldB}}
    const regex = /\{\{([^}]+)\}\}/g;
    let resultText = template;
    let matches: RegExpExecArray | null = null;
    while ((matches = regex.exec(template)) !== null) {
        // matches[1] contains the key inside the curly braces
        // e.g.
        // fieldA.keyB
        // fieldB
        const key = matches[1];
        const { questionKey, reducer, questionField, fallbackText } = interpolations[key];
        // get the text that will replace all matches with similar interpolation
        if (questionKey) {
            const fieldValue = getTextValueFromQuestion(
                formValue,
                questionKey,
                reducer,
                questionField,
            );
            if (isValidInputValueForDynamicText(fieldValue)) {
                resultText = resultText.replaceAll(`{{${key}}}`, String(fieldValue));
                continue;
            }
        }
        if (fallbackText) {
            resultText = resultText.replaceAll(`{{${key}}}`, fallbackText);
        }
    }
    // if there still is an interpolation left in the result text
    // it means:
    // 1) interpolation could not be resolved from the formValue
    // 2) interpolation does not have a fallback text
    // in that case, we return undefined
    if (resultText.match(regex) !== null) {
        return;
    }
    return resultText;
};

export const getTitleAsReactNode = (
    title: string | React.ReactNode | DynamicTextProps,
    formValue: { [key: string]: unknown },
): React.ReactNode => {
    if (isDynamicTextProps(title)) {
        return formatDynamicText(title, formValue);
    }
    return title;
};

export const resolvePages = (
    formQuestions: FormQuestionDefinition[],
    formPages?: FormPageDefinition[],
): WizardPages<OpaqueForm<{ [key: string]: any }>> => {
    if (formPages && formPages.length) {
        return formPages;
    }
    return [{ name: 'Form', fields: formQuestions.map(({ key }) => key) }];
};
export interface GetOnCompletePageValuesProps {
    pages: WizardPages<OpaqueForm<{ [key: string]: any }>>;
    activePageIndex: number;
    formQuestionDefinitions: FormQuestionDefinition[];
    value: WizardForm<OpaqueForm<{ [key: string]: unknown }>>['value'];
}
export const getOnCompletePageValues = (props: GetOnCompletePageValuesProps) => {
    const { pages, activePageIndex, formQuestionDefinitions, value } = props;

    const initPageValues: { [key: string | number]: unknown } = {};

    return pages[activePageIndex].fields.reduce((acc, field) => {
        const questionDefinition = formQuestionDefinitions.find(({ key }) => field === key);
        if (!questionDefinition) {
            return acc;
        }

        if (isFieldHidden(formQuestionDefinitions, value, questionDefinition)) {
            return acc;
        }

        acc[field] = value[field];

        return acc;
    }, initPageValues);
};

export const getOnSubmitPageValues = (
    formQuestionDefinitions: FormQuestionDefinition[],
    value: WizardForm<OpaqueForm<{ [key: string]: unknown }>>['value'],
) => {
    const initPageValues: { [key: string | number]: unknown } = {};
    return Object.keys(value).reduce((acc, fieldKey) => {
        const questionDefinition = formQuestionDefinitions.find(({ key }) => fieldKey === key);
        if (!questionDefinition) {
            return acc;
        }

        if (isFieldHidden(formQuestionDefinitions, value, questionDefinition)) {
            return acc;
        }

        acc[fieldKey] = value[fieldKey];

        return acc;
    }, initPageValues);
};

type BeforeNextFn = (
    wizardForm: Pick<WizardForm<any>, 'activePageIndex' | 'value' | 'setValue'>,
) => Promise<void>;

export function useDataDrivenForm({
    formQuestions,
    formPages,
    onPageComplete,
    onFormSubmit,
    beforeNext = () => Promise.resolve(),
    onPageChange,
    onHiddenPagesChange,
    submitText,
}: DataDrivenFormProps) {
    const [pages, setPages] = useState(resolvePages(formQuestions, formPages));

    const formQuestionDefinitions: FormQuestionDefinition[] = useMemo(() => {
        return formQuestions.map((question) => ({
            ...question,
        }));
    }, [formQuestions]);

    const handleOnFormSubmit = useCallback(
        (formValue: { [key: string]: unknown }) => {
            const onSubmitValues = getOnSubmitPageValues(formQuestionDefinitions, formValue);
            onFormSubmit(onSubmitValues);
        },
        [formQuestionDefinitions, onFormSubmit],
    );

    const { formInstance, initialData } = useMemo(() => {
        const formFields = formFieldsFactory(formQuestionDefinitions);
        const formInstance = createDataDrivenForm(formFields, handleOnFormSubmit);
        const initialData = getInitialData(formQuestionDefinitions);

        return { formInstance, initialData };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formQuestionDefinitions]);

    const initialPage = Math.max(
        0,
        pages.findIndex((page) => page.isInitial),
    );

    const {
        hasNext,
        activeStepIndex: activePageIndex,
        hasPrevious,
        next: nextPage,
        previous,
        setActiveStep,
    } = useStepper({
        steps: pages.map(({ name, isEnabled }) => ({ name, enabled: isEnabled !== false })),
        initialStep: initialPage,
    });
    // Including this type setting to ensure type safety as sometimes currentPage is undefined
    const currentPage = pages[activePageIndex] as FormPageDefinition | undefined;

    useEffect(() => {
        if (onPageChange && currentPage) {
            onPageChange(currentPage);
        }
    }, [currentPage, onPageChange]);

    const handleBeforeNext: BeforeNextFn = useCallback(
        async (props: Pick<WizardForm<any>, 'activePageIndex' | 'value' | 'setValue'>) => {
            const { activePageIndex, value } = props;
            const onCompletePageValues = getOnCompletePageValues({
                pages,
                activePageIndex,
                formQuestionDefinitions,
                value,
            });

            if (hasNext) {
                onPageComplete(onCompletePageValues);
            } else {
                onFormSubmit(onCompletePageValues);
            }

            await beforeNext(props);
        },
        [pages, formQuestionDefinitions, hasNext, onPageComplete, onFormSubmit, beforeNext],
    );

    const [isValidating, setIsValidating] = useState<'next' | 'navigation' | ''>('');

    const { validate, fields, value, setValue, setFieldValue } = useForm(formInstance, initialData);

    // It is not guaranteed that pages[activePageIndex] will defined
    const activePageName = currentPage?.name ?? '';

    const popstateHandler = useCallback(() => {
        if (hasPrevious) {
            previous();
        }
    }, [hasPrevious, previous]);
    const prevPopstateHandler = usePrevious(popstateHandler);

    useEffect(() => {
        if (prevPopstateHandler !== undefined) {
            window.removeEventListener('popstate', prevPopstateHandler);
        }
        window.addEventListener('popstate', popstateHandler);
        return () => {
            window.removeEventListener('popstate', popstateHandler);
        };
    }, [popstateHandler, prevPopstateHandler]);

    useEffect(() => {
        if (isValidating === '') {
            return;
        }
        if (isValidating === 'next') {
            setIsValidating('');
            const activePageFields = currentPage?.fields ?? [];

            const isPageInvalid = isPageInValid(
                value,
                formQuestionDefinitions,
                activePageFields,
                fields,
            );

            if (isPageInvalid) {
                return;
            }
            handleBeforeNext({
                activePageIndex,
                value,
                setValue,
            }).then(nextPage);
        }
    }, [
        currentPage,
        fields,
        isValidating,
        activePageIndex,
        formQuestionDefinitions,
        value,
        handleBeforeNext,
        nextPage,
        setValue,
        setActiveStep,
        beforeNext,
    ]);

    const next = useCallback(() => {
        const activePageFields = currentPage?.fields ?? [];

        validate(...activePageFields);
        setIsValidating('next');
    }, [currentPage, validate]);

    const setUnansweredFormValues = useCallback(
        (formValues: { [key: string]: unknown }) => {
            const unansweredQuestions = pages.reduce((acc, page, index) => {
                // if the page is after the active page, then consider it an unanswered question
                return index > activePageIndex ? acc.concat(page.fields) : acc;
            }, [] as (string | number)[]);

            Object.keys(formValues).forEach((key) => {
                if (unansweredQuestions.includes(key)) {
                    setFieldValue(key, formValues[key]);
                }
            });
        },
        [pages, activePageIndex, setFieldValue],
    );

    const currentPageQuestions: FormFieldViewDefinition[] = formQuestionDefinitions
        .filter(
            (formQuestion: FormQuestionDefinition) =>
                !isFieldHidden(formQuestionDefinitions, value, formQuestion) &&
                currentPage?.fields.includes(formQuestion.key),
        )
        .map((formQuestion: FormQuestionDefinition) => {
            return {
                questionProps: {
                    key: formQuestion.key,
                    questionType: formQuestion.questionType,
                    title: getTitleAsReactNode(formQuestion.title, value),
                    tooltip: formQuestion.tooltip,
                    label: formQuestion.label,
                    selectOptions: formQuestion.selectOptions,
                    staticOptions: formQuestion.staticOptions,
                    isMultiple: formQuestion.isMultiple,
                    statusMessage: formQuestion.statusMessage,
                },
                inputFieldProps: {
                    type: fields[formQuestion.key].type,
                    inputProps: {
                        ...fields[formQuestion.key].props,
                        placeholder: formQuestion.placeholder,
                    },
                    messages: fields[formQuestion.key].messages,
                    disabled: !isFieldEnabled(value, formQuestion),
                },
            };
        });

    const initializePages = useRef(true);
    useEffect(() => {
        if (!initializePages.current) {
            return;
        }
        const allFormPages = resolvePages(formQuestions, formPages);

        const hiddenPages = allFormPages.filter((page) =>
            page.fields.every((field) => {
                const questionDefinition = formQuestionDefinitions.find(
                    ({ key }) => field === key,
                ) as FormQuestionDefinition;
                return isFieldHidden(formQuestionDefinitions, value, questionDefinition);
            }),
        );

        const updatedPages = pages.map((page) => ({
            ...page,
            isEnabled: !hiddenPages.some((hiddenPage) => page.name === hiddenPage.name),
        }));
        initializePages.current = false;
        setPages(updatedPages);
        if (onHiddenPagesChange) {
            onHiddenPagesChange(hiddenPages.map(({ name }) => name));
        }
    }, [value, formPages, formQuestionDefinitions, formQuestions, pages, onHiddenPagesChange]);

    return {
        hasNext,
        hasPrevious,
        currentPage: {
            name: activePageName,
            questions: currentPageQuestions,
            title: currentPage?.title,
        },
        next,
        previous,
        setFieldValue,
        setActiveStep,
        setUnansweredFormValues,
        submitText,
    };
}

export function isPageInValid(
    formValue: { readonly [x: string]: unknown },
    formQuestionDefinitions: FormQuestionDefinition[],
    pageFields: readonly (string | number)[],
    fields: Immutable<FieldMap<FormDataType<any>, any>>,
): boolean {
    const isPageInvalid = pageFields.some((name) => {
        const questionDefinition = formQuestionDefinitions.find(({ key }) => `${name}` === key);
        if (!questionDefinition) {
            return false;
        }
        const isFieldHiddenValue = isFieldHidden(
            formQuestionDefinitions,
            formValue,
            questionDefinition,
        );
        if (isFieldHiddenValue) {
            return false;
        }

        if (!isFieldEnabled(formValue, questionDefinition)) {
            return false;
        }

        return (fields[name] as Field<FormDataType<any>>).status == 'invalid';
    });
    return isPageInvalid;
}
