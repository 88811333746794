import React, { SyntheticEvent } from 'react';
import { BlockButton, Icon, Text } from '@embroker/ui-toolkit/v2';

export const typeformLink = `https://embroker.typeform.com/to/n6AVP9DW#link_location=xxxxx`;

interface BrokerBannerProps {
    onClick: (event: SyntheticEvent) => void;
}

export function openBrokerLink() {
    window.open(typeformLink);
}

export function BrokerBanner({ onClick }: BrokerBannerProps) {
    const handleClick = (e: SyntheticEvent): void => {
        onClick(e);
        openBrokerLink();
    };

    return (
        <BlockButton as="span" onClick={handleClick} backgroundColor="primary-100">
            <Icon name="handshake" />
            <Text as="span" style="heading 5">
                You’re a broker?{' '}
                <Text as="span" style="body 1">
                    Access our broker portal here.
                </Text>
            </Text>
        </BlockButton>
    );
}
