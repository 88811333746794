import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { deepClone } from '@embroker/shotwell/core/object';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { PageLayout, Spinner, StackLayout, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import { addMonths } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { PolicyTransfered } from '../../../policy/entities/Policy';
import { DisplayPolicy } from '../../../policy/types/DisplayPolicy';
import { PolicySortByStartDate } from '../../../policy/types/PolicySortBy';
import { GetPolicies, GetPoliciesRequest } from '../../../policy/useCases/GetPolicies';
import { ThankYouModal } from '../../../quote/view/components/ThankYouModal';
import { GetRecommendedCoverages } from '../../../recommendations/useCases/GetRecommendedCoverages';
import { Application, ApplicationDeleted } from '../../../shopping/entities/Application';
import { Coverage } from '../../../shopping/types/Coverage';
import * as enums from '../../../shopping/types/enums';
import {
    GetApplicationList,
    GetApplicationListRequest,
} from '../../../shopping/useCases/GetApplicationList';
import { AppTypeCodeListOther } from '@app/shopping/types/enums';
import { ClientReservationModal } from '../../../shopping/view/components/ClientReservationModal';
import { GapBetweenPoliciesModal } from '../../../shopping/view/components/GapBetweenPoliciesModal';
import { getShoppableCoverages } from '../../../shopping/view/components/dashboard/ShoppingDashboard';
import { Session, SessionOrganizationSelected } from '../../../userOrg/entities/Session';
import { GetActiveSession } from '../../../userOrg/useCases/GetActiveSession';
import { GetActiveUserProfile } from '../../../userOrg/useCases/GetActiveUserProfile';
import { AllApplications } from './AllApplications';
import { DashboardSection } from './DashboardSection';
import { DashboardWelcomeBanner } from './DashboardWelcomeBanner';
import { EmptyInsuranceApplicationList } from './EmptyInsuranceApplicationList';
import { FilterPeriod, InsuranceApplicationFilter } from './InsuranceApplicationFilter';
import { PoliciesSection } from './PoliciesSection';
import { PurchaseToastMessage } from './PurchaseToastMessage';
import { QuickLinks } from './QuickLinks';
import { RecommendedCoverages } from './RecommendedCoverages';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { Organization } from '@app/userOrg/entities/Organization';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';

enum SectionTitle {
    ApplicationsInProgress = 'Your Applications in Progress',
    Renewals = 'Your Upcoming Policy Renewals',
    Quotes = 'Your Quotes',
}

enum SectionTypes {
    ApplicationsInProgress = 'new_policy_purchases',
    Renewals = 'renewals',
    Quotes = 'quotes',
}

const RenewalsPerSection = 3;
const QuotesPerSection = 6;
const ApplicationsInProgressPerSection = 9;

export interface DashboardSummaryProps {
    quotedApplicationId?: UUID;
    modalType?: string;
    quotedApplicationQuotingEngine?: enums.QuotingEngine;
    policyId?: UUID;
}

export function DashboardSummary({
    quotedApplicationId,
    modalType,
    quotedApplicationQuotingEngine,
    policyId,
}: DashboardSummaryProps) {
    const { result: applicationsInProgressListResult, reload: reloadApplicationsInProgress } =
        useUseCase(GetApplicationList, {
            sectionType: SectionTypes.ApplicationsInProgress,
        } as GetApplicationListRequest);

    const { result: recommendedCoverages, reload: reloadRecommendedCoverages } =
        useUseCase(GetRecommendedCoverages);

    const { result: renewalsListResult, reload: reloadRenewals } = useUseCase(GetApplicationList, {
        sectionType: SectionTypes.Renewals,
    } as GetApplicationListRequest);

    const { result: quotesListResult, reload: reloadQuotes } = useUseCase(GetApplicationList, {
        sectionType: SectionTypes.Quotes,
    } as GetApplicationListRequest);

    const { result: globalConfigResult } = useUseCase(GetGlobalConfig);

    const { result: activeUserResult } = useUseCase(GetActiveUserProfile);
    const { result: policiesResult, reload: reloadPolicies } = useUseCase(GetPolicies, {
        filter: {
            sortBy: PolicySortByStartDate,
            showActive: true,
            showInactive: false,
        },
    } as GetPoliciesRequest);
    const { result: activeSession, reload: reloadActiveSession } = useUseCase(
        GetActiveSession,
        null,
    );

    const { result: getActiveOrganizationProfileResponse } = useUseCase(
        GetActiveOrganizationProfile,
    );

    useDomainEvent<SessionOrganizationSelected>(
        'Session',
        'OrganizationSelected',
        async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            if (activeSession && isOK(activeSession)) {
                reloadRenewals();
                reloadApplicationsInProgress();
                reloadQuotes();
                reloadRecommendedCoverages();
                reloadPolicies();
                reloadActiveSession();
            }
        },
    );

    useDomainEvent<ApplicationDeleted>('Application', 'Deleted', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        if (activeSession && isOK(activeSession)) {
            reloadRenewals();
            reloadApplicationsInProgress();
            reloadQuotes();
        }
    });

    useDomainEvent<PolicyTransfered>('Policy', 'Transferred', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        reloadPolicies();
    });

    if (
        renewalsListResult === undefined ||
        applicationsInProgressListResult === undefined ||
        quotesListResult === undefined ||
        recommendedCoverages === undefined ||
        activeUserResult === undefined ||
        policiesResult === undefined ||
        activeSession === undefined ||
        globalConfigResult === undefined ||
        getActiveOrganizationProfileResponse === undefined
    ) {
        return <Spinner />;
    }

    if (isErr(renewalsListResult)) {
        return <ErrorPage errors={renewalsListResult.errors} />;
    }
    if (isErr(applicationsInProgressListResult)) {
        return <ErrorPage errors={applicationsInProgressListResult.errors} />;
    }
    if (isErr(quotesListResult)) {
        return <ErrorPage errors={quotesListResult.errors} />;
    }
    if (isErr(recommendedCoverages)) {
        return <ErrorPage errors={recommendedCoverages.errors} />;
    }
    if (isErr(policiesResult)) {
        return <ErrorPage errors={policiesResult.errors} />;
    }
    if (isErr(activeSession)) {
        return <ErrorPage errors={activeSession.errors} />;
    }
    if (globalConfigResult === null || isErr(globalConfigResult)) {
        return <ErrorPage errors={globalConfigResult.errors} />;
    }
    if (
        getActiveOrganizationProfileResponse === null ||
        isErr(getActiveOrganizationProfileResponse)
    ) {
        return <ErrorPage errors={getActiveOrganizationProfileResponse.errors} />;
    }

    let userFirstName = null;
    if (isOK(activeUserResult)) {
        userFirstName = activeUserResult.value.firstName;
    } else {
        container.get<Logger>(Log).error(activeUserResult.errors);
    }

    const {
        applicationList,
        canViewLplQuotes,
        canViewCnaBopQuotes,
        canViewCrimeQuotes,
        canViewCyberQuotes,
        canViewPCoMLQuotes,
        canViewExcessQuotes,
    } = applicationsInProgressListResult.value;

    // We don't want to load/display  not eligible applications nor applications with unspecified app type
    const apiApplicationList = applicationList.filter((app) => {
        return (
            app.appType !== AppTypeCodeListOther &&
            ![enums.NotEligible, enums.DeclinedByCarrier].includes(app.status)
        );
    });

    const activePolicyList = policiesResult.value.policyList.filter((policy) => {
        return policy.viewMode !== 'PolicyViewStatusCodeListDraft';
    });

    const bundleRepresentationEnabled =
        globalConfigResult.value.config.isBundleRepresentationEnabled;

    const organization = getActiveOrganizationProfileResponse.value.organization;

    return (
        <DashboardSummaryView
            renewalsList={renewalsListResult.value.applicationList}
            applicationList={apiApplicationList}
            quotesList={quotesListResult.value.applicationList}
            recommendedCoverages={recommendedCoverages.value.recommendedCoverages}
            canViewLplQuotes={canViewLplQuotes}
            canViewPCoMLQuotes={canViewPCoMLQuotes}
            canViewCnaBopQuotes={canViewCnaBopQuotes}
            canViewCrimeQuotes={canViewCrimeQuotes}
            canViewCyberQuotes={canViewCyberQuotes}
            canViewExcessQuotes={canViewExcessQuotes}
            hasAnyPolicy={policiesResult.value.hasAnyPolicy}
            policyList={activePolicyList}
            activeSession={activeSession.value.session}
            userFirstName={userFirstName}
            quotedApplicationId={quotedApplicationId}
            modalType={modalType}
            quotedApplicationQuotingEngine={quotedApplicationQuotingEngine}
            purchasedPolicyId={policyId}
            bundleRepresentationEnabled={bundleRepresentationEnabled}
            organization={organization}
        />
    );
}

interface DashboardSummaryViewProps {
    renewalsList: Immutable<Array<EntityProps<Application>>>;
    applicationList: Immutable<Array<EntityProps<Application>>>;
    quotesList: Immutable<Array<EntityProps<Application>>>;
    recommendedCoverages: Immutable<Coverage[]>;
    canViewLplQuotes: boolean;
    canViewPCoMLQuotes: boolean;
    canViewCnaBopQuotes: boolean;
    canViewCrimeQuotes: boolean;
    canViewCyberQuotes: boolean;
    canViewExcessQuotes: boolean;
    hasAnyPolicy: boolean;
    policyList: Immutable<DisplayPolicy[]>;
    activeSession: Immutable<EntityProps<Session>>;
    userFirstName: Nullable<string>;
    quotedApplicationId?: UUID;
    modalType?: string;
    quotedApplicationQuotingEngine?: enums.QuotingEngine;
    purchasedPolicyId?: Nullable<UUID>;
    bundleRepresentationEnabled: boolean;
    organization: Immutable<EntityProps<Organization>>;
}

function DashboardSummaryView({
    renewalsList,
    applicationList,
    quotesList,
    recommendedCoverages,
    canViewLplQuotes,
    canViewPCoMLQuotes,
    canViewCnaBopQuotes,
    canViewCrimeQuotes,
    canViewCyberQuotes,
    canViewExcessQuotes,
    hasAnyPolicy,
    policyList,
    activeSession,
    bundleRepresentationEnabled,
    userFirstName,
    quotedApplicationId,
    modalType,
    quotedApplicationQuotingEngine,
    purchasedPolicyId,
    organization,
}: DashboardSummaryViewProps) {
    const [displayApplicationsInProgressList, setDisplayApplicationsInProgressList] =
        useState<Immutable<Array<EntityProps<Application>>>>(applicationList);
    const [displayQuotesApplicationList, setDisplayQuotesApplicationList] =
        useState<Immutable<Array<EntityProps<Application>>>>(quotesList);
    const [displayRenewalsApplicationList, setDisplayRenewalsApplicationList] =
        useState<Immutable<Array<EntityProps<Application>>>>(renewalsList);

    const filteredRecommendedCoverages = useMemo(() => {
        return getShoppableCoverages(
            recommendedCoverages,
            displayApplicationsInProgressList,
            displayQuotesApplicationList,
        );
    }, [displayApplicationsInProgressList, displayQuotesApplicationList, recommendedCoverages]);

    const [allApplications, setAllApplications] = useState<boolean>(false);
    const [allRenewals, setAllRenewals] = useState<boolean>(false);
    const [allQuotes, setAllQuotes] = useState<boolean>(false);

    const thankYouModal = useModal();
    const clientReservationModal = useModal();
    const gapBetweenPoliciesModal = useModal();
    const showThankYouModal = thankYouModal.show;
    const showClientReservationModal = clientReservationModal.show;
    const showGapBetweenPoliciesModal = gapBetweenPoliciesModal.show;

    useEffect(() => {
        if (quotedApplicationId != null) {
            showThankYouModal();
        }
    }, [quotedApplicationId, showThankYouModal]);

    useEffect(() => {
        if (modalType === 'clientReservation') {
            showClientReservationModal();
        }
        if (modalType === 'gapBetweenPolicies') {
            showGapBetweenPoliciesModal();
        }
    }, [showClientReservationModal, showGapBetweenPoliciesModal, modalType]);

    let applicationQuotingEngine: Nullable<enums.QuotingEngine>;
    if (quotedApplicationId != null && quotedApplicationQuotingEngine != null) {
        applicationQuotingEngine = quotedApplicationQuotingEngine;
    } else {
        applicationQuotingEngine = null;
    }

    const isApplicationInPeriod = (
        application: Immutable<EntityProps<Application>>,
        months: number,
    ) => {
        const cutOffDate = addMonths(Date.now(), -months);
        return (
            months === FilterPeriod.All ||
            new Date(application.startedDate || Date.now()) > cutOffDate
        );
    };

    const handleFilterChange = useCallback(
        (months: FilterPeriod) => {
            const newList = applicationList.filter((application) =>
                isApplicationInPeriod(application, months),
            );
            const newQuotesList = quotesList.filter((quote) =>
                isApplicationInPeriod(quote, months),
            );
            const newRenewalsList = renewalsList.filter((renewalApp) =>
                isApplicationInPeriod(renewalApp, months),
            );
            setDisplayApplicationsInProgressList(newList);
            setDisplayQuotesApplicationList(newQuotesList);
            setDisplayRenewalsApplicationList(newRenewalsList);
        },
        [applicationList, quotesList, renewalsList],
    );

    const handleToggleAllApplications = () => {
        setAllApplications(!allApplications);
    };

    const handleToggleAllRenewals = () => {
        setAllRenewals(!allRenewals);
    };

    const handleToggleAllQuotes = () => {
        setAllQuotes(!allQuotes);
    };

    useEffect(() => {
        handleFilterChange(FilterPeriod.All);
    }, [applicationList, handleFilterChange]);

    const allApplicationsLink = (items: number): Nullable<JSX.Element> => {
        return applicationList.length > items ? (
            <TextButton
                size="small"
                icon="bold-caret-right"
                iconPosition="right"
                onClick={handleToggleAllApplications}
            >
                View All Applications
            </TextButton>
        ) : null;
    };

    const allRenewalsLink = (items: number): Nullable<JSX.Element> => {
        return renewalsList.length > items ? (
            <TextButton
                size="small"
                icon="bold-caret-right"
                iconPosition="right"
                onClick={handleToggleAllRenewals}
            >
                View All Renewals
            </TextButton>
        ) : null;
    };

    const allQuotesLink = (items: number): Nullable<JSX.Element> => {
        return quotesList.length > items ? (
            <TextButton
                size="small"
                icon="bold-caret-right"
                iconPosition="right"
                onClick={handleToggleAllQuotes}
            >
                View All Quotes
            </TextButton>
        ) : null;
    };

    const hasAnyApplicationOrPolicy: boolean =
        applicationList.length > 0 ||
        renewalsList.length > 0 ||
        quotesList.length > 0 ||
        policyList.length > 0;

    const policies: DisplayPolicy[] = [];
    const bundlePolicies: DisplayPolicy[] = [];
    const fullPolicies = deepClone(policyList);

    for (const policy of fullPolicies) {
        if (bundleRepresentationEnabled && policy.bundleId != null) {
            bundlePolicies.push(policy as DisplayPolicy);
        } else {
            policies.push(policy as DisplayPolicy);
        }
    }

    const isLawFirm =
        organization.naics && NAICS_CODE_TO_VERTICAL[organization.naics] === 'LawFirm';

    return allApplications ? (
        <PageLayout.Section>
            <AllApplications
                activeSession={activeSession}
                toggleAllApps={handleToggleAllApplications}
                sectionType={SectionTypes.ApplicationsInProgress}
                title={SectionTitle.ApplicationsInProgress}
            />
        </PageLayout.Section>
    ) : allRenewals ? (
        <PageLayout.Section>
            <AllApplications
                activeSession={activeSession}
                toggleAllApps={handleToggleAllRenewals}
                sectionType={SectionTypes.Renewals}
                title={SectionTitle.Renewals}
                isRenewal
            />
        </PageLayout.Section>
    ) : allQuotes ? (
        <PageLayout.Section>
            <AllApplications
                activeSession={activeSession}
                toggleAllApps={handleToggleAllQuotes}
                sectionType={SectionTypes.Quotes}
                title={SectionTitle.Quotes}
            />
        </PageLayout.Section>
    ) : (
        <React.Fragment>
            {applicationQuotingEngine && (
                <ThankYouModal
                    modal={thankYouModal}
                    applicationQuotingEngine={applicationQuotingEngine}
                />
            )}
            {modalType === 'clientReservation' && (
                <ClientReservationModal modal={clientReservationModal} />
            )}
            {modalType === 'gapBetweenPolicies' && (
                <GapBetweenPoliciesModal modal={gapBetweenPoliciesModal} />
            )}
            <DashboardWelcomeBanner
                userFirstName={userFirstName}
                policyList={policyList}
                renewalsList={renewalsList}
                applicationList={applicationList}
                quotesList={quotesList}
            />
            {quotedApplicationId && purchasedPolicyId ? (
                <PurchaseToastMessage
                    policyId={purchasedPolicyId}
                    applicationId={quotedApplicationId}
                    renewalsLeft={renewalsList.length}
                    applicationsLeft={applicationList.length + quotesList.length}
                />
            ) : null}
            {!hasAnyApplicationOrPolicy ? (
                <PageLayout.Section>
                    <EmptyInsuranceApplicationList />
                </PageLayout.Section>
            ) : (
                <React.Fragment>
                    {renewalsList.length > 0 ? (
                        <PageLayout.Section>
                            <StackLayout gap="32">
                                <InsuranceApplicationFilter onChange={handleFilterChange} />
                                <DashboardSection
                                    applicationList={displayRenewalsApplicationList}
                                    activeSession={activeSession}
                                    canViewLplQuotes={canViewLplQuotes}
                                    canViewPCoMLQuotes={canViewPCoMLQuotes}
                                    canViewCnaBopQuotes={canViewCnaBopQuotes}
                                    canViewCrimeQuotes={canViewCrimeQuotes}
                                    canViewCyberQuotes={canViewCyberQuotes}
                                    canViewExcessQuotes={canViewExcessQuotes}
                                    allApplicationsLink={allRenewalsLink}
                                    title={SectionTitle.Renewals}
                                    itemsPerSection={RenewalsPerSection}
                                    isRenewal
                                />
                            </StackLayout>
                        </PageLayout.Section>
                    ) : null}
                    {applicationList.length > 0 ? (
                        <PageLayout.Section>
                            <StackLayout gap="32">
                                {renewalsList.length === 0 ? (
                                    <InsuranceApplicationFilter onChange={handleFilterChange} />
                                ) : null}
                                <DashboardSection
                                    applicationList={displayApplicationsInProgressList}
                                    activeSession={activeSession}
                                    canViewLplQuotes={canViewLplQuotes}
                                    canViewPCoMLQuotes={canViewPCoMLQuotes}
                                    canViewCnaBopQuotes={canViewCnaBopQuotes}
                                    canViewCrimeQuotes={canViewCrimeQuotes}
                                    canViewCyberQuotes={canViewCyberQuotes}
                                    canViewExcessQuotes={canViewExcessQuotes}
                                    allApplicationsLink={allApplicationsLink}
                                    title={SectionTitle.ApplicationsInProgress}
                                    itemsPerSection={ApplicationsInProgressPerSection}
                                />
                            </StackLayout>
                        </PageLayout.Section>
                    ) : null}
                    {quotesList.length > 0 ? (
                        <PageLayout.Section>
                            <StackLayout gap="32">
                                {renewalsList.length === 0 && applicationList.length === 0 ? (
                                    <InsuranceApplicationFilter onChange={handleFilterChange} />
                                ) : null}
                                <DashboardSection
                                    applicationList={displayQuotesApplicationList}
                                    activeSession={activeSession}
                                    canViewLplQuotes={canViewLplQuotes}
                                    canViewPCoMLQuotes={canViewPCoMLQuotes}
                                    canViewCnaBopQuotes={canViewCnaBopQuotes}
                                    canViewCrimeQuotes={canViewCrimeQuotes}
                                    canViewCyberQuotes={canViewCyberQuotes}
                                    canViewExcessQuotes={canViewExcessQuotes}
                                    allApplicationsLink={allQuotesLink}
                                    title={SectionTitle.Quotes}
                                    itemsPerSection={QuotesPerSection}
                                />
                            </StackLayout>
                        </PageLayout.Section>
                    ) : null}
                    {bundleRepresentationEnabled && bundlePolicies.length > 0 ? (
                        <PageLayout.Section>
                            <PoliciesSection
                                policyList={bundlePolicies}
                                organizationId={activeSession.organizationId}
                                isBundlePolicies
                            />
                        </PageLayout.Section>
                    ) : null}
                    {policies.length > 0 ? (
                        <PageLayout.Section>
                            <PoliciesSection
                                policyList={policies}
                                organizationId={activeSession.organizationId}
                                isBundlePolicies={false}
                            />
                        </PageLayout.Section>
                    ) : null}
                </React.Fragment>
            )}
            {hasAnyPolicy && (
                <PageLayout.Section>
                    <QuickLinks />
                </PageLayout.Section>
            )}
            {!isLawFirm && filteredRecommendedCoverages.length > 0 && (
                <PageLayout.Section>
                    <RecommendedCoverages
                        coverageList={filteredRecommendedCoverages}
                        organization={organization}
                    />
                </PageLayout.Section>
            )}
        </React.Fragment>
    );
}
