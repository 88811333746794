import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { API, GlobalGetConfigResponse } from '@embroker/shotwell-api/app';
import {
    AsyncResult,
    Success,
    isErr,
    handleOperationFailure,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Coverage } from '../types/Coverage';
import {
    AppTypeCode,
    AppTypeCodeListCNABOP,
    AppTypeCodeListESP,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListManualAuto,
    AppTypeCodeListManualDefaultPL,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualGL,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualProperty,
    AppTypeCodeListManualTravel,
    AppTypeCodeListManualUmbrella,
    AppTypeCodeListPCoML,
    AppTypeCodeListTechEO,
    AppTypeCodeListManualHomeownersAssociation,
    AppTypeCodeListManualVentureCapitalAssetProtection,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListMPL,
    AppTypeCodeListMPLBundle,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListWCChubb,
    AppTypeCodeListHartfordBOP,
    AppTypeCodeListManualWorkersCompensation,
} from '@app/shopping/types/enums';
import {
    NAICS_CODE_TO_VERTICAL,
    TECH_NAICS_CODES_REQUIRE_REFINEMENT,
    Vertical,
} from '../../userOrg/types/enums';
import { MPLValidNaicsCodes } from '../../userOrg/types/MPLValidNaicsCodes';
import { GetActiveOrganizationProfile } from '../../userOrg/useCases/GetActiveOrganizationProfile';
import { CoverageEligibility } from '../types/CoverageEligibility';
import { Organization } from '@app/userOrg/entities/Organization';

const VERTICAL_TO_APP_TYPES: { [key in Vertical]: AppTypeCode[] } = {
    LawFirm: [
        AppTypeCodeListEverestLawyersProfessionalLiability,
        AppTypeCodeListCNABOP,
        AppTypeCodeListCyberCowbell,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListTechEO,
        AppTypeCodeListEmbrokerExcess,
        AppTypeCodeListPCoML,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
        AppTypeCodeListManualHomeownersAssociation,
        AppTypeCodeListManualVentureCapitalAssetProtection,
    ],
    Accounting: [
        AppTypeCodeListMPL,
        AppTypeCodeListBOPChubb,
        AppTypeCodeListCNABOP,
        AppTypeCodeListCyberCowbell,
        AppTypeCodeListWCChubb,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListManualDefaultPL,
        AppTypeCodeListTechEO,
        AppTypeCodeListEmbrokerExcess,
        AppTypeCodeListPCoML,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
        AppTypeCodeListManualHomeownersAssociation,
        AppTypeCodeListManualVentureCapitalAssetProtection,
    ],
    TaxPreparation: [
        AppTypeCodeListMPL,
        AppTypeCodeListBOPChubb,
        AppTypeCodeListCNABOP,
        AppTypeCodeListCyberCowbell,
        AppTypeCodeListWCChubb,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListManualDefaultPL,
        AppTypeCodeListTechEO,
        AppTypeCodeListEmbrokerExcess,
        AppTypeCodeListPCoML,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
        AppTypeCodeListManualHomeownersAssociation,
        AppTypeCodeListManualVentureCapitalAssetProtection,
    ],
    RealEstateAgent: [
        AppTypeCodeListMPL,
        AppTypeCodeListBOPChubb,
        AppTypeCodeListCNABOP,
        AppTypeCodeListCyberCowbell,
        AppTypeCodeListWCChubb,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListManualDefaultPL,
        AppTypeCodeListTechEO,
        AppTypeCodeListEmbrokerExcess,
        AppTypeCodeListPCoML,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
        AppTypeCodeListManualHomeownersAssociation,
        AppTypeCodeListManualVentureCapitalAssetProtection,
    ],
    HomeInspectorAndBuildingInspectionServices: [
        AppTypeCodeListMPL,
        AppTypeCodeListBOPChubb,
        AppTypeCodeListCNABOP,
        AppTypeCodeListCyberCowbell,
        AppTypeCodeListWCChubb,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListManualDefaultPL,
        AppTypeCodeListTechEO,
        AppTypeCodeListEmbrokerExcess,
        AppTypeCodeListPCoML,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
        AppTypeCodeListManualHomeownersAssociation,
        AppTypeCodeListManualVentureCapitalAssetProtection,
    ],
    NonTechnologyConsultants: [
        AppTypeCodeListMPL,
        AppTypeCodeListBOPChubb,
        AppTypeCodeListCNABOP,
        AppTypeCodeListCyberCowbell,
        AppTypeCodeListWCChubb,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListManualDefaultPL,
        AppTypeCodeListPCoML,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
        AppTypeCodeListManualHomeownersAssociation,
        AppTypeCodeListManualVentureCapitalAssetProtection,
    ],
    TechCompanies: [
        AppTypeCodeListESP,
        AppTypeCodeListTechEO,
        AppTypeCodeListHartfordBOP,
        AppTypeCodeListManualWorkersCompensation,
        AppTypeCodeListPCoML,
        AppTypeCodeListEmbrokerCrime,
        AppTypeCodeListManualEmploymentPractices,
        AppTypeCodeListEmbrokerExcess,
        AppTypeCodeListManualDirectorsAndOfficers,
        AppTypeCodeListManualFiduciary,
        AppTypeCodeListManualProperty,
        AppTypeCodeListManualUmbrella,
        AppTypeCodeListManualTravel,
        AppTypeCodeListManualAuto,
        AppTypeCodeListManualProductLiability,
    ],
};

const ALL_APP_TYPES: AppTypeCode[] = [
    AppTypeCodeListManualDefaultPL,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListCNABOP,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListPCoML,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualProperty,
    AppTypeCodeListManualGL,
    AppTypeCodeListManualUmbrella,
    AppTypeCodeListManualTravel,
    AppTypeCodeListManualAuto,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualHomeownersAssociation,
    AppTypeCodeListManualVentureCapitalAssetProtection,
];

export interface GetVerticalRecommendedCoveragesResponse {
    readonly verticalCoverages: Coverage[];
}

export interface GetVerticalRecommendedCoverages extends UseCase {
    execute(): AsyncResult<
        GetVerticalRecommendedCoveragesResponse,
        InvalidArgument | OperationFailed
    >;
}

@injectable()
class GetVerticalRecommendedCoveragesUseCase
    extends UseCase
    implements GetVerticalRecommendedCoverages
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Recommend/GetVerticalRecommendedCoverages');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetActiveOrganizationProfile.type)
        private getActiveOrganizationProfile: GetActiveOrganizationProfile,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetVerticalRecommendedCoveragesResponse,
        InvalidArgument | OperationFailed
    > {
        const configResponse = await API.request('global/get_config', {});
        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }
        const config = configResponse.value as GlobalGetConfigResponse;

        const getActiveOrganizationProfileResp = await this.getActiveOrganizationProfile.execute();
        if (isErr(getActiveOrganizationProfileResp)) {
            return handleOperationFailure(getActiveOrganizationProfileResp);
        }

        const organization = getActiveOrganizationProfileResp.value.organization as Organization;
        const naicsCode = organization?.naics;
        const headquartersState = organization.headquarters.state;

        // choose vertical by naics code.
        // if naics code is undefined, we'll use the default list of all app types
        let appTypes: AppTypeCode[] = ALL_APP_TYPES;

        const vertical = getVerticalForOrganization(organization);
        if (vertical) {
            appTypes = VERTICAL_TO_APP_TYPES[vertical];

            const isMPLVerticalEnabled = MPLValidNaicsCodes.isMPLVerticalEnabled(naicsCode);

            if (!isMPLVerticalEnabled) {
                appTypes = appTypes.filter(
                    (appType) => ![AppTypeCodeListMPL, AppTypeCodeListMPLBundle].includes(appType),
                );
            }
            if (
                CoverageEligibility.isManualProductLiabilityEligibile(headquartersState, naicsCode)
            ) {
                appTypes = appTypes.filter(
                    (appType) => ![AppTypeCodeListManualDefaultPL].includes(appType),
                );
            }
            const isMPLVertical = MPLValidNaicsCodes.isNaicCodeValid(naicsCode);
            if (isMPLVertical && isMPLVerticalEnabled) {
                appTypes = appTypes.filter((appType) => appType !== 'AppTypeCodeListCNABOP');
            } else {
                appTypes = appTypes.filter((appType) => appType !== 'AppTypeCodeListBOPChubb');
            }
        }

        if (!config.is_embroker_cyber_enabled) {
            appTypes = appTypes.filter((item) => item !== AppTypeCodeListCyberCowbell);
        }

        if (!config.is_hartford_enabled) {
            appTypes = appTypes.filter((item) => item !== AppTypeCodeListHartfordBOP);
        }

        appTypes = appTypes.filter((appType) =>
            CoverageEligibility.isStateEligabile(appType, headquartersState),
        );

        const verticalCoverages: Coverage[] = appTypes.map((appType) => ({
            appType,
            isSelected: false,
        }));

        return Success<GetVerticalRecommendedCoveragesResponse>({
            verticalCoverages,
        });
    }
}

function getVerticalForOrganization(organization: Organization): Vertical | undefined {
    const { naics, providesTechServiceForFee } = organization;
    if (!naics) {
        return;
    }

    switch (NAICS_CODE_TO_VERTICAL[naics]) {
        case 'TechCompanies': {
            if (TECH_NAICS_CODES_REQUIRE_REFINEMENT.includes(naics)) {
                return providesTechServiceForFee ? 'TechCompanies' : undefined;
            }
        }
    }

    return NAICS_CODE_TO_VERTICAL[naics];
}

export const GetVerticalRecommendedCoverages: UseCaseClass<GetVerticalRecommendedCoveragesUseCase> =
    GetVerticalRecommendedCoveragesUseCase;
