import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { startOfToday } from 'date-fns';
import { ExcessQuoteRepository } from '.';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteList } from '../../../types/QuoteList';
import { ExcessQuote } from '../../entities/ExcessQuote';
import { CarrierLayer } from '../../types/CarrierLayer';
import { ExcessQuestionnaire } from '../../types/ExcessQuestionnaire';
import { ExcessQuoteOptions } from '../../types/ExcessQuoteOptions';

@injectable()
export class APIEmbrokerExcessQuoteRepository implements ExcessQuoteRepository {
    async getLastExcessQuote(
        applicationId: UUID,
    ): AsyncResult<ExcessQuote, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const application = applicationResponse.value;

        const lastQuote = QuoteList.getLastQuote(application.quote_list);
        if (lastQuote == null || lastQuote.taxes == null || lastQuote.fees == null) {
            return Failure(
                InvalidArgument({
                    argument: 'application.quote_list',
                    value: application.quote_list,
                }),
            );
        }

        const excessQuoteDetails = lastQuote.details.excess;

        const excessQuoteOptions = lastQuote.options.excess;

        if (excessQuoteDetails === undefined) {
            return Failure(
                InvalidArgument({ argument: 'excessQuoteDetails', value: excessQuoteDetails }),
            );
        }

        if (excessQuoteOptions === undefined) {
            return Failure(
                InvalidArgument({ argument: 'excessQuoteOptions', value: excessQuoteOptions }),
            );
        }

        const questionnaireData = application.questionnaire_data;
        if (questionnaireData === null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }

        const questionnaireDataResult = JSONSerdes.deserialize(questionnaireData);
        if (isErr(questionnaireDataResult)) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }
        const questionnaire = questionnaireDataResult.value as ExcessQuestionnaire;

        const excessQuoteEntityResult = await ExcessQuote.create({
            isIndication: lastQuote.is_indication,
            id: lastQuote.id,
            totalPremium: lastQuote.total_premium,
            totalPayable: lastQuote.total_payable,
            taxes: lastQuote.taxes,
            fees: lastQuote.fees,
            applicationId: applicationId,
            status: lastQuote.accepted_at ? 'accepted' : 'draft',
            fileKey: lastQuote.file_key ?? undefined,
            options: {
                effectiveDate: excessQuoteOptions.effective_date ?? startOfToday(),
                expirationDate: excessQuoteOptions.expiration_date!,
                limit: excessQuoteOptions.limit,
                quoteCyber: excessQuoteOptions.quote_cyber,
            },
            details: {
                technologyPremium: excessQuoteDetails.technology_premium,
                cyberPremium: excessQuoteDetails.cyber_premium,
                surplusLinesTax: excessQuoteDetails.surplus_line_tax,
                fireMarshalTax: excessQuoteDetails.fire_marshal_tax,
                stampingFee: excessQuoteDetails.stamping_fee,
                mississippiWindstormUnderwritingAssociationFee:
                    excessQuoteDetails.mississippi_windstorm_underwriting_association_fee,
                surplusLinesServiceChargeFee: excessQuoteDetails.surplus_lines_service_charge_fee,
                clearingHouseFee: excessQuoteDetails.clearing_house_fee,
                embrokerAccessFee: excessQuoteDetails.embroker_access_fee,
            },
            questionnaire: questionnaire,
            primaryCarrierLayer: {
                layer_number: 0,
                carrier: questionnaire.primary_carrier,
                tech_eo_limit: questionnaire.tech_eo_limit,
                cyber_limit: questionnaire.cyber_limit,
                policy_aggregate: questionnaire.policy_aggregate,
                premium: questionnaire.primary_premium,
            } as CarrierLayer,
            daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
                quotingEngine: application.quoting_engine || undefined,
                applicationStatus: application.status,
                validUntil: application.valid_until,
                quoteEffectiveDate: excessQuoteOptions.effective_date ?? startOfToday(),
                today: startOfToday(),
                isBroker: application.brokerage_id !== null,
            }),
        });

        if (isErr(excessQuoteEntityResult)) {
            return handleOperationFailure(excessQuoteEntityResult);
        }
        return Success(excessQuoteEntityResult.value);
    }

    async reQuoteExcess(
        applicationId: UUID,
        excessQuoteOptions: ExcessQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const reQuoteExcessResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                excess: {
                    effective_date: excessQuoteOptions.effectiveDate,
                    expiration_date: excessQuoteOptions.expirationDate,
                    limit: excessQuoteOptions.limit,
                    quote_cyber: excessQuoteOptions.quoteCyber,
                },
            },
        });
        if (isErr(reQuoteExcessResponse)) {
            return handleOperationFailure(reQuoteExcessResponse);
        }
        return Success(reQuoteExcessResponse.value.task_id);
    }

    public async createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteSummaryResult = await API.request('shopping/create_quote_summary_task', {
            application_id: applicationId,
            quote_id: quoteId,
        });
        if (isErr(createQuoteSummaryResult)) {
            return handleOperationFailure(createQuoteSummaryResult);
        }

        return Success(createQuoteSummaryResult.value.task_id);
    }

    public async createSpecimenPolicyAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createSpecimenPolicyResult = await API.request(
            'shopping/create_specimen_policy_task',
            {
                application_id: applicationId,
                quote_id: quoteId,
            },
        );
        if (isErr(createSpecimenPolicyResult)) {
            return handleOperationFailure(createSpecimenPolicyResult);
        }

        return Success(createSpecimenPolicyResult.value.task_id);
    }

    public async submitForReview(
        applicationId: UUID,
        supplementalQuestionnaireData: string,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/submit_for_review', {
            application_id: applicationId,
            supplemental_questionnaire_data: supplementalQuestionnaireData,
        });
        if (isErr(response)) {
            return Failure(
                OperationFailed({
                    message: 'Submit for review request failed',
                    errors: response.errors,
                }),
            );
        }

        return Success();
    }
}
