import { CoverLayout, Placeholder, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function BusinessInfoStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout gap="56">
                <StackLayout split="-1" gap="24">
                    <StackLayout gap="4">
                        <Text style="overline">Step 1</Text>
                        <Text style="heading 3">Where is your certificate going?</Text>
                    </StackLayout>
                    <Text style="body 1">
                        In this step, let us know which business you’re requesting the certificate
                        for. If you need certificates for multiple businesses, please let us know at
                        the end of the questionnaire.
                    </Text>
                </StackLayout>
                <Placeholder backgroundColor="ui-50" appearance="compact" imageMaxWidth={270} />
            </StackLayout>
        </CoverLayout>
    );
}
