import { CheckBox, ColumnLayout, StackLayout, StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import { Money } from '@embroker/shotwell/core/types/Money';
import React from 'react';
import {
    CurrencyInput,
    CurrencyInputEvent,
} from '@embroker/shotwell/view/components/CurrencyInput';
import { WizardForm } from '@app/view/hooks/useWizardForm';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { BOPHartfordQuoteOptionsFormData } from '@app/quote/hartford-bop/view/components/createBOPHartfordOptionsForm';

interface Props {
    squareFootageAtLocation: number | undefined;
    bopCoverageType: string;
    setValue: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['value'];
    bppLimit: Money;
}

export function CoverageProperty({
    squareFootageAtLocation,
    bopCoverageType,
    setValue,
    bppLimit,
    value,
}: Props) {
    const safeSquareFootage = squareFootageAtLocation ?? 0;
    const initialLimit =
        bopCoverageType == 'LiabOnly' ? Money.tryFromFloat(safeSquareFootage * 100) : bppLimit;
    const [localBppLimit, setLocalBppLimit] = React.useState(initialLimit);

    if (squareFootageAtLocation === undefined) {
        return null;
    }

    const handleTextInputChange = (event: CurrencyInputEvent) => {
        const limit = event.target.value;
        if (!limit) {
            setLocalBppLimit(Money.tryFromFloat(0));
            return;
        }

        setLocalBppLimit(limit);
        if (bopCoverageType === 'PropLiab') {
            setValue({
                ...value,
                bppLimit: limit,
            });
        }
    };

    return (
        <StackLayout gap="12">
            <ColumnLayout style={{ justifyContent: 'space-between' }}>
                <Text style="heading 5">Property Coverage</Text>
                <CheckBox
                    appearance="toggle"
                    checked={bopCoverageType != 'LiabOnly'}
                    onChange={(event) => {
                        if (!event.target.checked) {
                            setValue({
                                ...value,
                                bopCoverageType: 'LiabOnly',
                                bppLimit: Money.tryFromFloat(0),
                            });
                            return;
                        }
                        setValue({
                            ...value,
                            bopCoverageType: 'PropLiab',
                            bppLimit: localBppLimit,
                        });
                    }}
                    data-e2e="coverage-toggle"
                    inline
                />
            </ColumnLayout>

            <StackLayout>
                <Text color="ui-500">
                    Property covers the cost of replacing your business's property (computers,
                    furniture, inventory, etc.) in case of damage or loss.
                </Text>

                <StackLayout gap="16">
                    <CurrencyInput
                        inputMode="decimal"
                        label="Coverage"
                        value={localBppLimit}
                        onChange={handleTextInputChange}
                    />
                </StackLayout>
            </StackLayout>
            <StatusMessage status="info">
                We estimated the base amount at $100 per square foot for a typical professional
                services office. Please adjust if necessary.
            </StatusMessage>
        </StackLayout>
    );
}
