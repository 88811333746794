import {
    ConditionalDisplayOption,
    FormPageDefinition,
    FormQuestionDefinition,
} from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { MainAreaOfFocusList, NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { RiskProfileKeys, CompanyDimension } from '@app/public/types/enums';

const TECH_VERTICAL_NAICS_CODES = Object.keys(NAICS_CODE_TO_VERTICAL).reduce((acc, naicsCode) => {
    if (NAICS_CODE_TO_VERTICAL[naicsCode] === 'TechCompanies') {
        acc.push(naicsCode);
    }

    return acc;
}, [] as string[]);

const conditions: { [key: string]: ConditionalDisplayOption } = {
    isNaicsTechVertical: {
        displayWhen: {
            questionKey: RiskProfileKeys.NaicsCode,
            condition: { string: { includes: TECH_VERTICAL_NAICS_CODES } },
        },
    },
};

export const formName = 'RiskProfile';

export const pages: FormPageDefinition[] = [
    {
        name: 'basic-info',
        title: "Let's start building your risk profile",
        fields: [
            RiskProfileKeys.NaicsCode,
            RiskProfileKeys.AreaOfFocus,
            RiskProfileKeys.TechnologyServicesOrProducts,
        ],
    },
    {
        name: 'additional-details',
        title: 'Just a few more details about your company',
        fields: [RiskProfileKeys.NumberOfEmployees, RiskProfileKeys.CompanyDimensions],
    },
];

export const questions: FormQuestionDefinition[] = [
    {
        key: RiskProfileKeys.NaicsCode,
        questionType: 'naicsCode',
        title: 'What industry best describes the work you do?',
        isRequired: true,
    },
    {
        key: RiskProfileKeys.AreaOfFocus,
        questionType: 'select',
        title: `What is the company’s main area of focus?`,
        selectOptions: MainAreaOfFocusList,
        isRequired: true,
        conditionalDisplay: [[conditions.isNaicsTechVertical]],
    },
    {
        key: RiskProfileKeys.TechnologyServicesOrProducts,
        questionType: 'radioGroup',
        title: 'Does your company provide technology services or products, such as software development, IT consulting, or technology-related support services to others for a fee?',
        selectOptions: [
            { title: 'Yes', value: true },
            { title: 'No', value: false },
        ],
        isRequired: true,
        conditionalDisplay: [[conditions.isNaicsTechVertical]],
    },
    {
        key: RiskProfileKeys.NumberOfEmployees,
        questionType: 'number',
        title: 'How many employees do you have?',
        label: 'Number of employees',
        statusMessage: {
            status: 'info',
            content:
                '"Employee" refers to any staff on payroll and any independent contractors you hire.',
        },
        isRequired: true,
    },
    {
        key: RiskProfileKeys.CompanyDimensions,
        questionType: 'multiSelect',
        title: 'Which of the following apply to your company? Please select all that apply:',
        selectOptions: [
            {
                title: 'Has raised funds or is in the process of raising funds',
                value: CompanyDimension.RaisedFunds,
            },
            {
                title: 'Has staff working remotely/virtually',
                value: CompanyDimension.AllEmployeesRemoteWork,
            },
            {
                title: 'Rents or owns an office location',
                value: CompanyDimension.OfficeLocationRentOrOwn,
            },
            {
                title: 'Manufactures, sells, or distributes physical product(s)',
                value: CompanyDimension.PhysicalProducts,
            },
            {
                title: 'Has international customers, travels internationally, or has operations abroad',
                value: CompanyDimension.InternationalOperations,
            },
            {
                title: 'Uses a preferred employer organization (PEO) ',
                value: CompanyDimension.PreferredEmployerOrganization,
            },
            {
                title: 'Offers employee benefit plans (e.g., 401(k), health insurance)',
                value: CompanyDimension.EmployeeBenefitPlans,
            },
        ],
        isRequired: true,
    },
];
