import {
    Box,
    Button,
    ButtonBar,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export function ApplicationProcessingSuccessModal({
    modal,
    triggerHandleApplicationProcessingSuccess,
}: {
    modal: ModalActions & ModalState;
    triggerHandleApplicationProcessingSuccess: () => void;
}) {
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout center gap="24">
                <ColumnLayout>
                    <Text style="heading 2">Success!</Text>
                </ColumnLayout>
                <StackLayout center gap="24">
                    <Text style="body 1">
                        We’ve partially pre-filled your application based on data pulled from the
                        uploaded document.
                    </Text>
                    <Text style="body 1">
                        Please note that we may have made some assumptions in this analysis of your
                        data.
                    </Text>
                    <Text style="body 1">
                        Review the application details for accuracy and make updates to any answers
                        that are either incorrect or blank.
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <ButtonBar>
                        <Box border={false} />
                        <Button onClick={triggerHandleApplicationProcessingSuccess}>
                            Review Application
                        </Button>
                    </ButtonBar>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
