import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AgreementToConductElectronicTransactionsModal as AgreementToConductElectronicTransactionsModalView } from './AgreementToConductElectronicTransactionsModal.view';

export interface AgreementToConductElectronicTransactionsProps {
    modal: ModalState & ModalActions;
}

export function AgreementToConductElectronicTransactionsModal({
    modal,
}: AgreementToConductElectronicTransactionsProps) {
    return (
        <Modal {...modal} size="medium">
            <AgreementToConductElectronicTransactionsModalView />
        </Modal>
    );
}
