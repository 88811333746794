import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { validateEffectiveDate } from '@app/quote/view/components/formValidators';
import { ErrorLike, Failure, Success } from '@embroker/shotwell/core/types/Result';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { EditApplication } from '@app/brokerDashboard/useCases/EditApplication';
import { SignQuote } from '@app/quote/useCases/SignQuote';
import { UnsignQuote } from '@app/quote/useCases/UnsignQuote';

import { GenerateAppFileUrl } from '@app/documents/useCases/GenerateAppFileUrl';
import { GetSignaturePacketFileUrl } from '@app/quote/brokerage/useCases/GetSignaturePacketFileUrl';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Immutable } from '@embroker/shotwell/core/types';
import { navigateToErrorPage } from '@app/view/errors';

import { NavigateFunction } from '@app/view/hooks/useNavigation';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { startOfToday } from 'date-fns';
import { ReQuote } from '@app/quote/hartford-bop/useCases/ReQuote';
import { Signature } from '@app/quote/types/Signature';
import { GenerateQuoteFileUrl } from '@app/quote/hartford-bop/useCases/GetQuoteDocument';
import { Money } from '@embroker/shotwell/core/types/Money';

export const MAX_FUTURE_DAYS_ALLOWED = 90;

export interface BOPHartfordQuoteOptionsFormData {
    effectiveDate: Date;
    bopCoverageType: string;
    bppLimit: Money;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
    brokerSignature: boolean;
}

export interface QuoteOptionsFormProps {
    quote: BOPHartfordQuote;
    abortSignal: AbortSignal;
    isBroker: boolean;
    isAdmin: boolean;
    onUpdateQuote: (newQuote: BOPHartfordQuote) => void;
    navigate: NavigateFunction;
    setSignature: (signature: Signature) => void;
}

export function createBOPHartfordQuoteOptionsFormData({
    quote,
    navigate,
    onUpdateQuote,
    setSignature,
    abortSignal,
    isBroker,
}: QuoteOptionsFormProps) {
    return createForm<BOPHartfordQuoteOptionsFormData>({
        fields: {
            effectiveDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            false,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    console.log(error);
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        case 'date.fixed':
                            return 'Effective date can not be changed for renewal application.';
                        default:
                            return error.message;
                    }
                },
            },
            bopCoverageType: {
                type: 'checkbox',
                validator: Joi.string().required(),
            },
            bppLimit: {
                type: 'currency',
                validator: Money.schema.optional(),
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return (
                        isBroker || value || helpers.error('agreementToConductSignature.invalid')
                    );
                }),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
                }),
            },
            brokerSignature: {
                type: 'hidden',
                validator: Joi.boolean().custom((value, helpers) => {
                    return !isBroker || value || helpers.error('brokerSignature.invalid');
                }),
            },
        },
        actions: {
            editApplication: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }
                    return await execute(EditApplication, {
                        applicationId: quote.applicationId,
                        isRenewal: false,
                    });
                },
                fields: ['effectiveDate', 'bopCoverageType', 'bppLimit'],
            },
            update: {
                action: async (formData: BOPHartfordQuoteOptionsFormData) => {
                    return await execute(ReQuote, {
                        applicationId: quote.applicationId,
                        effectiveDate: formData.effectiveDate,
                        bopCoverageType: formData.bopCoverageType,
                        bppLimit: formData.bppLimit,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', 'bopCoverageType', 'bppLimit'],
            },
            sign: {
                action: async (formData: BOPHartfordQuoteOptionsFormData) => {
                    setSignature({
                        transfers: formData.agreementToConductSignature,
                        warranty: formData.warrantyAndFraudSignature,
                    });

                    const userHasSigned =
                        formData.agreementToConductSignature && formData.warrantyAndFraudSignature;

                    if ((isBroker && formData.brokerSignature) || userHasSigned) {
                        return execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: ['effectiveDate', 'bopCoverageType', 'bppLimit'],
            },
            downloadApp: {
                action: async () => {
                    return await execute(GenerateAppFileUrl, {
                        applicationId: quote.applicationId,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', 'bopCoverageType', 'bppLimit'],
            },
            downloadQuote: {
                action: async () => {
                    return await execute(GenerateQuoteFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', 'bopCoverageType', 'bppLimit'],
            },
            downloadSignaturePacket: {
                action: async () => {
                    return await execute(GetSignaturePacketFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: ['effectiveDate', 'bopCoverageType', 'bppLimit'],
            },
            default: async () => {
                return Success({});
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'downloadSignaturePacket':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'downloadApp':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'downloadQuote':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'update':
                    onUpdateQuote(value);
                    setSignature({
                        transfers: false,
                        warranty: false,
                    });
                    break;
                case 'editApplication':
                    navigate(
                        URI.build('/shopping/application/basic-info', {
                            applicationId: quote?.applicationId,
                        }),
                    );
                    break;
                case 'sign':
                    onUpdateQuote(value.quote);
                    break;
                case 'default': {
                    break;
                }
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            navigateToErrorPage(navigate, errors);
            return;
        },
    });
}
