import React, { useMemo, useRef, useCallback, useState } from 'react';
import { useNavigation } from '@app/view/hooks/useNavigation';
import { URI } from '@embroker/shotwell/core/types/URI';
import { StackLayout, FormLayout, useModal } from '@embroker/ui-toolkit/v2';
import {
    DataDrivenForm,
    DataDrivenFormRefrence,
} from '@app/view/components/DataDrivenForm/components/DataDrivenForm';
import { RiskProfileLegalDisclosure } from './RiskProfileLegalDisclosure.view';
import { RiskProfileIneligibilityModal } from './RiskProfileIneligibilityModal';
import { RiskProfilePageLayout } from './RiskProfilePageLayout.view';
import { RiskProfile } from '../../types/RiskProfile';
import { questions, pages, formName } from '../../entities/questionnaire';
import { deepClone } from '@embroker/shotwell/core/object';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    PublishFormEvent,
    FormEventName,
} from '@app/view/components/DataDrivenForm/useCases/PublishFormEvent';
import { WizardPageDefinition } from '@app/view/hooks/useWizardForm';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { RiskProfileKeys } from '@app/public/types/enums';
import { Nullable } from '@embroker/shotwell/core/types';

interface RiskProfileQuestionnaireProps {
    initialValues: Partial<RiskProfile>;
    /**
     * The index of the page to start the questionnaire on. Supports both positive and negative values (inverse indexing).
     */
    initialPageIndex?: number;
}

export function RiskProfileQuestionnaire({
    initialValues = {},
    initialPageIndex = 0,
}: RiskProfileQuestionnaireProps) {
    const dataDrivenFormRef = useRef<DataDrivenFormRefrence>(null);
    const [formState, setFormState] = useState<Partial<RiskProfile>>(initialValues);
    const [currentPageIndex, setCurrentPageIndex] = useState<Nullable<number>>(null);
    const ineligibilityModal = useModal();
    const { navigate } = useNavigation();

    const handleBeforeNext = useCallback(
        async ({ value }: { value: RiskProfile }): Promise<unknown> => {
            return new Promise((resolve, reject) => {
                const isValidTechnologyNaics = RiskProfile.isValidTechnologyNaics(value.naics);

                if (isValidTechnologyNaics && !!value.technologyServicesOrProducts) {
                    resolve(value);
                } else {
                    reject(value);
                }
            }).catch(() => {
                ineligibilityModal.show();
            });
        },
        [ineligibilityModal],
    );

    function handlePageComplete(partialFormValues: {}): {} {
        const nextFormState = {
            ...formState,
            ...partialFormValues,
        };

        setFormState(nextFormState);
        return nextFormState;
    }

    function handleFormSubmit(partialFormValues: {}) {
        if (currentPageIndex === null) return;

        const currentPage = pages[currentPageIndex];

        execute(PublishFormEvent, {
            name: FormEventName.Submitted,
            formStage: currentPage.name,
            formName,
        });

        const formValues = handlePageComplete(partialFormValues);

        const serializedFormValues = RiskProfile.serialize(formValues as RiskProfile);
        navigate(URI.build('/explore/risk-profile/analyzing', serializedFormValues as any));
    }

    const handlePageChange = (
        currentPage: WizardPageDefinition<
            OpaqueForm<{
                [key: string]: any;
            }>
        >,
    ) => {
        const newPageIndex = pages.findIndex((page) => page.name === currentPage.name);

        if (newPageIndex !== currentPageIndex) {
            if (currentPageIndex !== null) {
                if (newPageIndex > currentPageIndex) {
                    execute(PublishFormEvent, {
                        name: FormEventName.Next,
                        formStage: currentPage.name,
                        formName,
                    });
                } else if (newPageIndex < currentPageIndex) {
                    execute(PublishFormEvent, {
                        name: FormEventName.Previous,
                        formStage: currentPage.name,
                        formName,
                    });
                }
            }

            execute(PublishFormEvent, {
                name: FormEventName.Viewed,
                formStage: currentPage.name,
                formName,
            });
        }

        setCurrentPageIndex(newPageIndex);
    };

    const initializedQuestions = useMemo(() => {
        const clonedQuestions = deepClone(questions);

        return clonedQuestions.map((question) => {
            const initialValue: any = initialValues[question.key as RiskProfileKeys];

            if (initialValue) {
                question.initialValue = initialValue;
            }

            return question;
        });
    }, [initialValues]);

    const initializedPages = useMemo(() => {
        const clonedPages = deepClone(pages);

        if (Math.abs(initialPageIndex) <= clonedPages.length - 1) {
            if (initialPageIndex >= 0) {
                clonedPages[initialPageIndex].isInitial = true;
            } else {
                clonedPages[clonedPages.length + initialPageIndex].isInitial = true;
            }
        }

        return clonedPages;
    }, [initialPageIndex]);

    return (
        <RiskProfilePageLayout>
            <StackLayout gap="64">
                <DataDrivenForm
                    formQuestions={initializedQuestions}
                    formPages={initializedPages}
                    ref={dataDrivenFormRef}
                    onFormSubmit={handleFormSubmit}
                    onPageComplete={handlePageComplete}
                    beforeNext={handleBeforeNext}
                    onPageChange={handlePageChange}
                    submitText="View My Risk Profile"
                />
                <FormLayout>
                    <RiskProfileLegalDisclosure />
                </FormLayout>
                <RiskProfileIneligibilityModal modal={ineligibilityModal} />
            </StackLayout>
        </RiskProfilePageLayout>
    );
}
