import React, { useEffect, useMemo } from 'react';
import { Form } from '@embroker/ui-toolkit/v2';
import { ComplexFormFieldViewProps, getFormFieldProps } from '../../types/ComplexFieldTypes';
import {
    CurrencyInput,
    CurrencyInputEvent,
} from '@embroker/shotwell/view/components/CurrencyInput';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { getMonth, getYear } from 'date-fns';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { Year } from '@embroker/shotwell/core/types/Year';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { ErrorMsgObject } from '../../types/validationObject';

export function assertRevenueType(input: unknown): input is Revenue {
    if (typeof input !== 'object' || input === null) {
        return false;
    }
    return 'grossTotal' in input || 'fiscalYear' in input;
}

export const revenueFieldFormatValidationError = (error: InvalidArgument) => {
    return ErrorMsgObject.getValidationMessage(error);
};

export const getRevenueYear = (revenueType: RevenueFieldViewProps['revenueType']) => {
    const isDecember = getMonth(Date.now()) === 11;

    const yearOffset = revenueType === 'projectedYear' ? 0 : -1;
    const decemberYearOffset = isDecember ? 1 + yearOffset : 0;

    const year = isDecember
        ? Year.create(getYear(Date.now()) + decemberYearOffset)
        : Year.create(getYear(Date.now()) + yearOffset);

    if (isOK(year)) {
        return year.value;
    }
};

type RevenueType = 'projectedYear' | 'previousYear';
interface RevenueFieldViewProps extends ComplexFormFieldViewProps {
    revenueType: RevenueType;
}

const revenueLabelMap: {
    [key in RevenueType]: string;
} = {
    projectedYear: 'Current year annual revenue (projected)',
    previousYear: 'Prior year annual revenue',
};

export function RevenueFieldView(complexFormFieldViewProps: RevenueFieldViewProps) {
    const { questionProps, inputFieldProps, revenueType, onComplexFieldChange } =
        complexFormFieldViewProps;
    const { key } = questionProps;
    const formFieldProps = getFormFieldProps(questionProps);
    const year = getRevenueYear(revenueType);

    const inputProps = useMemo(
        () => ({
            ...inputFieldProps.inputProps,
            placeholder: inputFieldProps.inputProps.placeholder,
            items: questionProps.selectOptions,
        }),
        [inputFieldProps, questionProps],
    );

    useEffect(() => {
        if (inputProps.value && (inputProps.value as Revenue).fiscalYear !== year) {
            // If we pass a value that is not for the previous year, we reset the value
            onComplexFieldChange(key, undefined);
        }
    }, [inputProps, key, year, onComplexFieldChange]);

    const handleChange = ({ target: { value } }: CurrencyInputEvent) => {
        if (!value || !year) {
            return;
        }
        const revenue: Revenue = { fiscalYear: year, grossTotal: value };
        onComplexFieldChange(key, revenue);
    };

    const inputValue = assertRevenueType(inputProps.value)
        ? inputProps.value.grossTotal
        : undefined;

    return (
        <Form.Field {...formFieldProps} messages={inputFieldProps.messages}>
            <CurrencyInput
                label={revenueLabelMap[revenueType]}
                allowDecimal={false}
                {...{ ...inputProps, value: inputValue }}
                onChange={handleChange}
            />
        </Form.Field>
    );
}
