import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { CreateGenericBundleApplication } from './CreateGenericBundleApplication';
import { URI } from '@embroker/shotwell/core/types/URI';
import { CreateLawBundleApplication } from './CreateLawBundleApplication';
import { UserOnboardingRepository } from '@app/userOrg/repositories/UserOnboardingRepository';
import { SaveProductSelectionAnswers } from '@app/shoppingQuestioner/useCases/SaveProductSelectionAnswers';
import { calculateOnboardingRedirectURLForUnauthenticatedSignup } from '@app/userOrg/useCases/GetOnboardingRedirect';

export type CreateApplicationUnauthOnboardingRequest = {};

export interface CreateApplicationUnauthOnboardingResponse {
    uri: URI;
}

export interface CreateApplicationUnauthOnboarding extends UseCase {
    execute(
        request: CreateApplicationUnauthOnboardingRequest,
    ): AsyncResult<CreateApplicationUnauthOnboardingResponse, OperationFailed>;
}

export class CreateApplicationUnauthOnboardingUseCase
    extends UseCase
    implements CreateApplicationUnauthOnboarding
{
    public static type = Symbol('Shopping/CreateApplicationUnauthOnboarding');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        CreateApplicationUnauthOnboardingResponse,
        OperationFailed
    > {
        let uri = URI.build('/');
        const UnauthorizedOnboardingSignUpDataResp =
            await this.userOnboardingRepository.getUnauthorizedOnboardingSignUpData();
        if (!isOK(UnauthorizedOnboardingSignUpDataResp)) {
            this.userOnboardingRepository.removeUnauthorizedOnboardingSignUpData();
            return Success<CreateApplicationUnauthOnboardingResponse>({
                uri: uri,
            });
        }
        const UnauthorizedOnboardingSignUpData = UnauthorizedOnboardingSignUpDataResp.value;
        const { naicsCode, coverageSelection, redirectUrl, questionnaireData } =
            UnauthorizedOnboardingSignUpData;
        const vertical = NAICS_CODE_TO_VERTICAL[naicsCode];

        const onboardingRedirectURL = calculateOnboardingRedirectURLForUnauthenticatedSignup({
            naicsCode,
            redirectUrl,
            questionnaireData,
            vertical,
        });

        if (onboardingRedirectURL) {
            this.userOnboardingRepository.removeUnauthorizedOnboardingSignUpData();
            return Success<CreateApplicationUnauthOnboardingResponse>({
                uri: onboardingRedirectURL as URI,
            });
        }

        if (vertical === 'TechCompanies') {
            const saveProductSelectionAnswersResp = await execute(SaveProductSelectionAnswers, {
                coverages: coverageSelection,
            });

            if (!isOK(saveProductSelectionAnswersResp)) {
                return Failure(
                    OperationFailed({ message: 'SaveProductSelectionAnswers useCase failed' }),
                );
            }

            const createGenericBundleApplicationResp = await execute(
                CreateGenericBundleApplication,
                {
                    abortSignal: new AbortController().signal,
                    selectedCoverages: coverageSelection,
                },
            );

            if (isOK(createGenericBundleApplicationResp)) {
                const saveProductSelectionAnswersResp = await execute(SaveProductSelectionAnswers, {
                    coverages: coverageSelection,
                });

                if (isOK(saveProductSelectionAnswersResp)) {
                    uri = URI.build('/shopping/application', {
                        applicationId: createGenericBundleApplicationResp.value.bundleApplicationId,
                    });
                }
            }
        } else if (vertical === 'LawFirm') {
            const additionalQuestionnaireData = {
                naics_code: UnauthorizedOnboardingSignUpData.naicsCode,
                state_with_most_attorneys: questionnaireData?.headquarters?.state,
                areas_of_practice: {
                    areas_of_practice_rows: [],
                },
                coverage_toggles: UnauthorizedOnboardingSignUpData.coverageSelection,
            };

            const createLawBundleApplicationResp = await execute(CreateLawBundleApplication, {
                abortSignal: new AbortController().signal,
                selectedCoverageTypes: coverageSelection,
                additionalQuestionnaireData,
            });

            if (isOK(createLawBundleApplicationResp)) {
                const applicationId = createLawBundleApplicationResp.value.applicationIdList[0];
                uri = URI.build('/shopping/application', {
                    applicationId,
                });
            }
        }

        this.userOnboardingRepository.removeUnauthorizedOnboardingSignUpData();
        return Success<CreateApplicationUnauthOnboardingResponse>({
            uri: uri,
        });
    }
}

export const CreateApplicationUnauthOnboarding: UseCaseClass<CreateApplicationUnauthOnboarding> =
    CreateApplicationUnauthOnboardingUseCase;
