import { injectable } from '@embroker/shotwell/core/di';
import { Nullable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import * as cookie from 'cookie';
import cookiejs from 'cookiejs';
import * as Sentry from '@sentry/browser';
import { GrowthBook } from '@growthbook/growthbook';
import { AppContextStore, AppContext } from '../../view/AppContext';
import { ExperimentationServicePlatforms } from '../types/enums';
import { Location, LocationState } from '@embroker/shotwell/core/Location';

type AppContextChangeCallback = (context: AppContext) => void;
type LocationChangeCallback = (url: URL) => void;

export type HeapUserId = string | undefined;
export type HeapSessionId = string | undefined;
export type GAUserId = string | undefined;

interface AppContextChangeSubscription {
    callback: AppContextChangeCallback;
}

@injectable()
export class BaseExperimentationService {
    private appContextChangeSubcription: Nullable<AppContextChangeSubscription> = null;
    private prevContext: Nullable<AppContext> = null;
    protected growthbookClient?: GrowthBook<Record<string, any>>;
    protected platform: Nullable<ExperimentationServicePlatforms>;
    protected platformName: Nullable<string>;

    constructor() {
        this.platform = null;
        this.platformName = null;

        AppContextStore.subscribe((context) => {
            this.handleAppContextStoreChange(context);
        });
    }

    private handleAppContextStoreChange(nextContext: AppContext): void {
        const nextContextOrganizationId = nextContext.activeSession.organizationId ?? null;
        const prevContentOrganizationId = this.prevContext?.activeSession.organizationId ?? null;

        if (nextContextOrganizationId !== prevContentOrganizationId) {
            this.appContextChangeSubcription?.callback(nextContext);
        }

        this.prevContext = nextContext;
    }

    /**
     * Subscribe to AppContext change. Triggers callback when change to organizationId is detected
     *
     * @param callback Called when application context has been changed. Provided `appContext`.
     * @returns Unsubscription function
     */
    protected subscribeToAppContextChange(callback: AppContextChangeCallback): () => void {
        this.appContextChangeSubcription = { callback };

        return () => {
            this.appContextChangeSubcription = null;
        };
    }

    protected getCurrentLocation(): URL {
        return new URL(globalThis.location.href);
    }

    protected getLocalStorageDeviceId(): UUID | undefined {
        try {
            let deviceId = localStorage.getItem('_emb_experimentation_device_Id') as UUID;

            if (!deviceId) {
                deviceId = UUID.create();
                localStorage.setItem('_emb_experimentation_device_Id', deviceId);
            }

            return deviceId;
        } catch (e) {
            if (e instanceof Error) {
                this.printError(e.message);
            }
            return undefined;
        }
    }

    protected getCookieDeviceId(): UUID | undefined {
        try {
            let deviceId = cookiejs.get('_emb_device_id') as UUID;

            if (!deviceId) {
                deviceId = UUID.create();
                cookiejs.set('_emb_device_id', deviceId, {
                    path: '/',
                    expires: 100,
                    domain: '.embroker.com',
                });
            }

            return deviceId;
        } catch (e) {
            if (e instanceof Error) {
                this.printError(e.message);
            }

            return undefined;
        }
    }

    protected getSessionStorageDeviceId(): UUID | undefined {
        try {
            let deviceId = sessionStorage.getItem('_emb_experimentation_device_Id') as UUID;

            if (!deviceId) {
                deviceId = UUID.create();
                sessionStorage.setItem('_emb_experimentation_device_Id', deviceId);
            }

            return deviceId;
        } catch (e) {
            if (e instanceof Error) {
                this.printError(e.message);
            }

            return undefined;
        }
    }

    protected getHeapSesssionId(): HeapSessionId {
        try {
            return window.heap.getSessionId();
        } catch (e) {
            this.printWarning('Heap session id not found.');
            return undefined;
        }
    }

    protected getHeapUserId(): HeapUserId {
        try {
            return window.heap.userId;
        } catch (e) {
            this.printWarning('Heap user id not found.');
            return undefined;
        }
    }

    protected getGaCookieId(): GAUserId {
        try {
            return cookie.parse(document.cookie)._ga.substring(6);
        } catch (e) {
            this.printWarning('GA id not found.');
            return undefined;
        }
    }

    /**
     * Subscribe to window URL change events. Triggers callback when `history.pushState()` is called.
     * @param callback Called when `history.pushState()` is triggered
     * @returns Unsubscription function
     */
    protected subscribeToLocationChange(callback: LocationChangeCallback): () => void {
        return Location.subscribe((locationObserver: LocationState) => {
            callback(locationObserver.url);
        }, []);
    }

    private printPlatformName(): string {
        return this.platformName || this.platform || '';
    }

    protected printLog(message: string): void {
        if (!this.growthbookClient?.debug) return;

        Sentry.captureMessage(`${this.printPlatformName()}: ${message}`, Sentry.Severity.Log);
        console.log(`[${this.printPlatformName()}]`, message);
    }

    protected printInfo(message: string): void {
        if (!this.growthbookClient?.debug) return;

        Sentry.captureMessage(`${this.printPlatformName()}: ${message}`, Sentry.Severity.Warning);
        console.info(`[${this.printPlatformName()}]`, message);
    }

    protected printWarning(message: string): void {
        if (!this.growthbookClient?.debug) return;

        Sentry.captureMessage(`${this.printPlatformName()}: ${message}`, Sentry.Severity.Warning);
        console.warn(`[${this.printPlatformName()}]`, message);
    }

    protected printError(message: string): void {
        Sentry.captureMessage(`${this.printPlatformName()}: ${message}`, Sentry.Severity.Critical);
        console.error(`[${this.printPlatformName()}]`, message);
    }
}
