import { Data, Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { ErrorLike, isErr, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Grid,
    Modal,
    ModalActions,
    ModalState,
    RadioGroup,
    Spinner,
    Text,
    Button,
    TextButton,
    useModal,
    StatusMessage,
    SelectInput,
    ButtonBar,
    Form,
    BoxLayout,
    StackLayout,
    UseResponsiveScreenQuery,
    ColumnLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { format, isAfter, isSameDay, isValid, startOfDay } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { getEnvVar } from '../../../../env';
import { CoverageRestriction } from '../../../../quote/esp/types/CoverageRestriction';
import { ESPDoDifferenceModal } from '../../../../quote/esp/view/components/ESPCoveragesPage/ESPDoDifferenceModal';
import { ESPEoCyberDifferenceModal } from '../../../../quote/esp/view/components/ESPCoveragesPage/ESPEoCyberDifferenceModal';
import { ESPEplDifferenceModal } from '../../../../quote/esp/view/components/ESPCoveragesPage/ESPEplDifferenceModal';
import {
    CreateOtherEndorsement,
    CreateOtherEndorsementRequest,
} from '../../../intake/useCases/CreateOtherEndorsement';
import {
    AddESPEndorsementLiabilityCoverageType,
    ESPEndorsementCoverageTypeMap,
} from '../../types/ESPEndorsementLiabilityCoverageType';
import { ESPEndorsementPolicy } from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementRate } from '../../types/ESPEndorsementRate';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { AddCoverageESPEndorsement } from '../../useCases/AddCoverageESPEndorsement';
import { RateAddCoverageESPEndorsement } from '../../useCases/RateAddCoverageESPEndorsement';
import { ESPEndorsementSignature } from './ESPEndorsementSignature';
import { getCoverageDisplayName } from './getCoverageDisplayName';
import {
    getAddCoverageLimitOptions,
    getAddCoverageRetentionOptions,
} from './getESPLimitAndRetentionOptions';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';
import { ESPEndorsementPremiumCostBreakdown } from '@app/endorsement/esp/view/components/ESPEndorsementPremiumCostBreakdown.view';

const DATE_FORMAT = 'MM/dd/yyyy';
const responsiveTablet: UseResponsiveScreenQuery = { screenWidth: { smallerThan: 'tablet' } };

function formatRequestHigherLimitMessage(
    coverageTypeCode: string,
    level: string,
    limit: number,
    retention: number,
): string {
    const coverage = ESPEndorsementCoverageTypeMap[coverageTypeCode];
    // limit is in dollars
    const requestedLimit = Money.tryFromFloat(limit);
    const newLimit: string = Money.toString(requestedLimit);
    // retention is in dollars
    const requestedRetention = Money.tryFromFloat(retention);
    const newRetention: string = Money.toString(requestedRetention);

    const requestMessage: string =
        `Add ${coverage} ${level} coverage\n` +
        `Limit: ${newLimit}\n` +
        `Retention: ${newRetention}`;

    return requestMessage;
}

interface ESPEndorsementAddCoverageFormData {
    limit: number;
    retention: number;
    effectiveDate: Nullable<Date>;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
    level: 'standard' | 'plus';
}

interface CreateEspEndorsementAddCoverageFormParams {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    abortSignal: AbortSignal;
    onRequestHigherLimitSuccess: () => void;
}

function createEspEndorsementAddCoverageForm({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverageTypeCode,
    onPurchaseEndorsementSuccess,
    onPurchaseEndorsementFailure,
    abortSignal,
    onRequestHigherLimitSuccess,
}: CreateEspEndorsementAddCoverageFormParams) {
    return createForm<ESPEndorsementAddCoverageFormData>({
        fields: {
            limit: {
                type: 'text',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Limit is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            retention: {
                type: 'text',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Retention is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
                formatValidationError: (error) => {
                    if (
                        error.details.validator == 'date.min' ||
                        error.details.validator == 'date.max'
                    ) {
                        return 'The effective date does not coincide with the policy period.';
                    }
                    if (error.details.validator == 'any.required') {
                        return 'You must set an effective date.';
                    }
                    return error.message;
                },
            },
            agreementToConductSignature: {
                // FIXME bool as hidden ?
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
            warrantyAndFraudSignature: {
                // FIXME bool as hidden ?
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
            level: {
                type: 'radioGroup',
                validator: Joi.string().valid('standard', 'plus'),
            },
        },
        actions: {
            requestHigherLimit: async (addCoverageFormData: ESPEndorsementAddCoverageFormData) => {
                const effectiveDate = addCoverageFormData.effectiveDate as Date;
                const requestedChangeMessage = formatRequestHigherLimitMessage(
                    coverageTypeCode,
                    addCoverageFormData.level,
                    addCoverageFormData.limit,
                    addCoverageFormData.retention,
                );

                const request: CreateOtherEndorsementRequest = {
                    effectiveDate,
                    requestedChangeMessage,
                    policyId,
                };
                return await execute(CreateOtherEndorsement, request);
            },
            purchase: async (addCoverageFormData: ESPEndorsementAddCoverageFormData) => {
                const effectiveDate = addCoverageFormData.effectiveDate as Date;
                return await execute(AddCoverageESPEndorsement, {
                    policyId: policyId,
                    coverageTypeCode: coverageTypeCode,
                    limit: Money.tryFromFloat(addCoverageFormData.limit),
                    retention: Money.tryFromFloat(addCoverageFormData.retention),
                    effectiveDate: effectiveDate,
                    enhanced: addCoverageFormData.level == 'plus',
                    abortSignal,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'purchase':
                    onPurchaseEndorsementSuccess(value);
                    break;
                case 'requestHigherLimit':
                    onRequestHigherLimitSuccess();
                    break;
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => onPurchaseEndorsementFailure(errors),
    });
}

function getDefaultAddCoverageFormLimit(
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType,
): Partial<ESPEndorsementAddCoverageFormData> {
    const isFiduciary = coverageTypeCode === 'LiabilityCoverageCodeListFiduciaryLiability';
    const isEPLi = coverageTypeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability';
    const initialLimit = isFiduciary || isEPLi ? 1000000 : undefined;
    const initialRetention = isFiduciary ? 0 : undefined;
    const initalLevel = 'standard';

    return {
        effectiveDate: null,
        limit: initialLimit,
        retention: initialRetention,
        agreementToConductSignature: false,
        warrantyAndFraudSignature: false,
        level: initalLevel,
    };
}

const defaultAddCoverageRate: ESPEndorsementRate = {
    annualPremium: USD(0),
    prorate: USD(0),
    taxes: USD(0),
    fees: USD(0),
    total: USD(0),
};

interface ESPEndorsementAddCoverageProps {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    submittedAt: Nullable<string>;
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType;
    userData: ESPEndorsementUserData;
    hasRenewalApplication?: boolean;
    restriction?: Immutable<CoverageRestriction>;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    onClose: () => void;
    onRequestHigherLimitSuccess: () => void;
}

function offerStandardAndPlus(
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType,
    submittedAt: Nullable<string>,
    restriction?: Immutable<CoverageRestriction>,
): boolean {
    if (submittedAt == null) {
        return false;
    }

    if (restriction && !restriction.allowPlus) {
        return false;
    }

    if (coverageTypeCode == 'LiabilityCoverageCodeListFiduciaryLiability') {
        return false;
    }

    const releaseDateEnvVar = getEnvVar('DNO_AND_EPLI_STANDARD_AND_PLUS_RELEASE_DATE');

    if (typeof releaseDateEnvVar === 'string') {
        const solartisReleaseDateString = releaseDateEnvVar;

        const submissionDate = new Date(submittedAt);
        const solartisReleaseDate = new Date(solartisReleaseDateString);
        return isAfter(submissionDate, solartisReleaseDate);
    }

    return false;
}

export function ESPEndorsementAddCoverage({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverageTypeCode,
    submittedAt,
    userData,
    hasRenewalApplication,
    restriction,
    onPurchaseEndorsementSuccess,
    onPurchaseEndorsementFailure,
    onClose,
    onRequestHigherLimitSuccess,
}: ESPEndorsementAddCoverageProps) {
    const [addCoverageRate, setAddCoverageRate] = useState(defaultAddCoverageRate);
    const [isQuoteLoading, setIsQuoteLoading] = useState(false);
    const isMobile = useResponsive(responsiveTablet);

    const doModal = useModal();
    const epliModal = useModal();
    const eoCyberModal = useModal();
    const modals: Data<ModalState & ModalActions> = {
        LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability: doModal,
        LiabilityCoverageCodeListEmploymentPracticesLiability: epliModal,
        LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions: eoCyberModal,
    };

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const espAddCoverageForm = useMemo(
        () =>
            createEspEndorsementAddCoverageForm({
                policyId: policyId,
                effectivePeriodStart: effectivePeriodStart,
                effectivePeriodEnd: effectivePeriodEnd,
                coverageTypeCode: coverageTypeCode,
                onPurchaseEndorsementSuccess: onPurchaseEndorsementSuccess,
                onPurchaseEndorsementFailure: onPurchaseEndorsementFailure,
                abortSignal: abortController.signal,
                onRequestHigherLimitSuccess: onRequestHigherLimitSuccess,
            }),
        [
            policyId,
            effectivePeriodStart,
            effectivePeriodEnd,
            coverageTypeCode,
            onPurchaseEndorsementSuccess,
            onPurchaseEndorsementFailure,
            abortController.signal,
            onRequestHigherLimitSuccess,
        ],
    );

    const { value, setValue, status, fields, trigger } = useForm(espAddCoverageForm, () =>
        getDefaultAddCoverageFormLimit(coverageTypeCode),
    );

    useEffect(() => {
        const endorsementAddCoverageSchema = Joi.object({
            limit: Joi.number().required(),
            retention: Joi.number().required(),
            effectiveDate: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
            level: Joi.string().valid('plus', 'standard'),
        });
        const validationResult = endorsementAddCoverageSchema.validate({
            limit: value.limit,
            retention: value.retention,
            effectiveDate: value.effectiveDate,
            level: value.level,
        });
        if (validationResult.error) {
            return;
        }
        setIsQuoteLoading(true);
        execute(RateAddCoverageESPEndorsement, {
            policyId,
            coverageTypeCode: coverageTypeCode,
            limit: Money.tryFromFloat(value.limit),
            retention: Money.tryFromFloat(value.retention),
            effectiveDate: value.effectiveDate as Date,
            enhanced: value.level == 'plus',
        })
            .then((rateResult) => {
                if (isErr(rateResult)) {
                    onPurchaseEndorsementFailure(rateResult.errors);
                }
                setAddCoverageRate((rateResult as SuccessResult<ESPEndorsementRate>).value);
            })
            .finally(() => {
                setIsQuoteLoading(false);
            });
    }, [
        coverageTypeCode,
        policyId,
        effectivePeriodEnd,
        effectivePeriodStart,
        onPurchaseEndorsementFailure,
        value.effectiveDate,
        value.limit,
        value.retention,
        value.level,
    ]);

    const handleLimitChange = (event: { target: { value: string } }) => {
        setValue({
            ...value,
            limit: Number(event.target.value),
        });
    };

    const handleRetentionChange = (event: { target: { value: string } }) => {
        setValue({
            ...value,
            retention: Number(event.target.value),
        });
    };

    const handleRequestHigherLimit = async () => {
        trigger('requestHigherLimit');
    };

    const handlePurchase = async () => {
        trigger('purchase');
    };

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleAgreementToConductSignatureChange = () => {
        setValue({
            ...value,
            agreementToConductSignature: !value.agreementToConductSignature,
        });
    };

    const handleWarrantyAndFraudSignatureChange = () => {
        setValue({
            ...value,
            warrantyAndFraudSignature: !value.warrantyAndFraudSignature,
        });
    };

    const isFormSubmitting = status === 'submitting';
    const isLoading = isQuoteLoading || isFormSubmitting;

    const isFormInvalid = status === 'invalid';

    const coverageDisplayName = useMemo(
        () => getCoverageDisplayName(coverageTypeCode),
        [coverageTypeCode],
    );

    const limitOptions = useMemo(
        () => getAddCoverageLimitOptions(coverageTypeCode),
        [coverageTypeCode],
    );
    const retentionOptions = useMemo(
        () => getAddCoverageRetentionOptions(coverageTypeCode, restriction),
        [coverageTypeCode, restriction],
    );

    const maxAllowedLimit = restriction?.maxLimit || Money.tryFromFloat(3000000);
    const isHigherLimitEndorsement = Money.isGreaterThan(
        Money.tryFromFloat(value.limit),
        maxAllowedLimit,
    );

    const hideAmounts = isHigherLimitEndorsement || isLoading;

    return (
        <BoxLayout gap="24">
            <Form>
                {isLoading && <Spinner appearance="transparent" />}
                <StackLayout gap="24">
                    <Text style="heading 3">Add {coverageDisplayName} Coverage</Text>
                    <ColumnLayout grow="fixed" responsive={responsiveTablet}>
                        <Form.Field title="Limit" messages={fields.limit.messages}>
                            <SelectInput
                                items={limitOptions.map(toSelectCurrencyOption)}
                                readOnly={isLoading || limitOptions.length <= 1}
                                value={value.limit}
                                onChange={handleLimitChange}
                            />
                        </Form.Field>
                        <Form.Field title="Retention" messages={fields.retention.messages}>
                            <SelectInput
                                items={retentionOptions.map(toSelectCurrencyOption)}
                                readOnly={isLoading || retentionOptions.length <= 1}
                                value={value.retention}
                                onChange={handleRetentionChange}
                            />
                        </Form.Field>
                        {hideAmounts ? null : (
                            <StackLayout gap={isMobile ? '20' : '32'}>
                                <Text style="body 1">Additional premium</Text>
                                <Text style="heading 5">
                                    <MoneyDisplay value={addCoverageRate.annualPremium} />
                                </Text>
                            </StackLayout>
                        )}
                    </ColumnLayout>
                    <Grid gap="small">
                        {offerStandardAndPlus(coverageTypeCode, submittedAt, restriction) && (
                            <React.Fragment>
                                <Grid.Row>
                                    <Grid.Cell>
                                        <Text style="heading 5">Select your coverage </Text>
                                    </Grid.Cell>
                                    <TextButton onClick={modals[coverageTypeCode].show}>
                                        What's the difference?
                                    </TextButton>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Cell>
                                        <RadioGroup
                                            id="level"
                                            items={[
                                                {
                                                    title: 'Standard',
                                                    value: 'standard',
                                                },
                                                {
                                                    title: 'Plus',
                                                    value: 'plus',
                                                },
                                            ]}
                                            {...fields.level.props}
                                        />
                                    </Grid.Cell>
                                </Grid.Row>
                            </React.Fragment>
                        )}
                    </Grid>
                    <ESPEndorsementPremiumCostBreakdown
                        prorate={hideAmounts ? null : addCoverageRate.prorate}
                        taxes={hideAmounts ? null : addCoverageRate.taxes}
                        fees={hideAmounts ? null : addCoverageRate.fees}
                        total={hideAmounts ? null : addCoverageRate.total}
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Cell width="1/1" md="1/2">
                                <Form.Field
                                    inputProps={{
                                        value: value.effectiveDate
                                            ? format(value.effectiveDate, DATE_FORMAT)
                                            : undefined,
                                        onChange: handleEffectiveDateChange,
                                        disabled: isLoading,
                                        note: (
                                            <Fragment>
                                                Current policy period:&nbsp;
                                                <DateDisplay value={effectivePeriodStart} />
                                                &nbsp;-&nbsp;
                                                <DateDisplay value={effectivePeriodEnd} />
                                            </Fragment>
                                        ),
                                    }}
                                    title="Effective date"
                                    messages={fields.effectiveDate.messages}
                                    type={fields.effectiveDate.type}
                                />
                            </Grid.Cell>
                        </Grid.Row>
                        {hasRenewalApplication && (
                            <Grid.Row>
                                <Grid.Cell width="1/1">
                                    <StatusMessage status="warning">
                                        The renewal application started prior to this change.
                                        Processing the request will result in the renewal
                                        application being reset.
                                    </StatusMessage>
                                </Grid.Cell>
                            </Grid.Row>
                        )}
                        <ESPEndorsementSignature
                            agreementToConductSignature={value.agreementToConductSignature}
                            onAgreementToConductSignatureChange={
                                handleAgreementToConductSignatureChange
                            }
                            agreementToConductSignatureMessages={
                                fields.agreementToConductSignature.messages
                            }
                            userData={userData}
                            isFormInvalid={isFormInvalid}
                            warrantyAndFraudSignature={value.warrantyAndFraudSignature}
                            onWarrantyAndFraudSignatureChange={
                                handleWarrantyAndFraudSignatureChange
                            }
                            warrantyAndFraudSignatureMessages={
                                fields.warrantyAndFraudSignature.messages
                            }
                        />
                        <Grid.Row>
                            <Grid.Cell width="1/1">
                                <ButtonBar>
                                    {isHigherLimitEndorsement ? (
                                        <Button
                                            onClick={handleRequestHigherLimit}
                                            disabled={isLoading}
                                            data-e2e="request-higher-limit"
                                        >
                                            Request higher limit
                                        </Button>
                                    ) : (
                                        <Button onClick={handlePurchase} disabled={isLoading}>
                                            Purchase
                                        </Button>
                                    )}
                                    <TextButton disabled={isLoading} onClick={onClose}>
                                        Cancel
                                    </TextButton>
                                </ButtonBar>
                            </Grid.Cell>
                        </Grid.Row>
                    </Grid>
                </StackLayout>
                <Modal size="large" {...eoCyberModal}>
                    <ESPEoCyberDifferenceModal />
                </Modal>
                <Modal size="large" {...doModal}>
                    <ESPDoDifferenceModal />
                </Modal>
                <Modal size="large" {...epliModal}>
                    <ESPEplDifferenceModal />
                </Modal>
            </Form>
        </BoxLayout>
    );
}
