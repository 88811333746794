import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Failure, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { RevenueList } from '@embroker/shotwell/core/types/RevenueList';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicantRepository } from '../repositories/ApplicantRepository';

export interface GetLawBundlePrefillResponse {
    gross_revenue_total?: Money;
    state_with_most_attorneys?: State;
    naics_code?: string;
}

export interface GetLawBundlePrefill extends UseCase {
    execute(): AsyncResult<GetLawBundlePrefillResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLawBundlePrefillUseCase extends UseCase implements GetLawBundlePrefill {
    public static type = Symbol('Shopping/GetLawBundlePrefill');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetLawBundlePrefillResponse,
        InvalidArgument | OperationFailed
    > {
        const result: GetLawBundlePrefillResponse = {};

        const applicantResult = await this.applicantRepository.getApplicant();
        if (isErr(applicantResult)) {
            return Failure(
                OperationFailed({
                    errors: applicantResult.errors,
                    message: 'Failed to fetch applicant details',
                }),
            );
        }
        const { revenueList } = applicantResult.value;
        if (revenueList && revenueList.length > 0) {
            const revenueListResult = RevenueList.validate(revenueList);
            if (isOK(revenueListResult)) {
                const currentRevenue = RevenueList.getLastFinancialYearRevenue(
                    revenueListResult.value,
                );
                result.gross_revenue_total = currentRevenue?.grossTotal ?? undefined;
            }
        }

        result.state_with_most_attorneys = applicantResult.value.headquarters.state ?? undefined;
        result.naics_code = applicantResult.value.naicsIndustry;

        return Success(result);
    }
}

export const GetLawBundlePrefill: UseCaseClass<GetLawBundlePrefill> = GetLawBundlePrefillUseCase;
