export const MedicalExpenses = 'MEDEX';
export const DamageToPremisesRentedToYou = 'FIRDM';
export const AdditionalInsuredByContract = 'AICON';
export const GeneralAggregateLimit = 'GENAG';
export const ProductsCompletedOperationsAggLimit = 'PRDCO';
export const PersonalAndAdvertisingInjury = 'PIADV';
export const BusinessLiabilityLimit = 'EAOCC';
export const EmploymentPracticesLiability = 'EPLI';

const PerOccurrenceLimit = 'Per Occurrence Limit';
const AggregateLimit = 'Aggregate Limit';

const limitCodeMap = {
    [MedicalExpenses]: PerOccurrenceLimit,
    [DamageToPremisesRentedToYou]: PerOccurrenceLimit,
    [PersonalAndAdvertisingInjury]: PerOccurrenceLimit,
    [ProductsCompletedOperationsAggLimit]: PerOccurrenceLimit,
    [GeneralAggregateLimit]: AggregateLimit,
    [BusinessLiabilityLimit]: PerOccurrenceLimit,
    [AdditionalInsuredByContract]: PerOccurrenceLimit,
};

export function GetCoverageLimitCode(coverage: string): string {
    return limitCodeMap[coverage as keyof typeof limitCodeMap] || '';
}
