import { RiskProfile } from '@app/public/types/RiskProfile';
import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileIneligibleModalActionClick } from '@app/public/entities/events';

export interface PublishRiskProfileIneligibilityModalActionClickEvent extends UseCase {
    execute(request: RiskProfile): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileIneligibilityModalActionClickEventUseCase
    extends UseCase
    implements PublishRiskProfileIneligibilityModalActionClickEvent
{
    public static type = Symbol('RiskProfile/PublishRiskProfileIneligibilityModalActionClickEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(
        request: RiskProfile,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const ineligibilityModalActionClickEvent: RiskProfileIneligibleModalActionClick = {
            origin: 'RiskProfile',
            name: 'IneligibleKickoutActionClick',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            ...request,
        };

        await this.eventBus.publish(ineligibilityModalActionClickEvent);

        return Success();
    }
}

export const PublishRiskProfileIneligibilityModalActionClickEvent: UseCaseClass<PublishRiskProfileIneligibilityModalActionClickEvent> =
    PublishRiskProfileIneligibilityModalActionClickEventUseCase;
