import React from 'react';
import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import { ModalLayout } from '@app/view/components/ModalLayout.view';

export interface RiskProfileIneligibilityModalProps {
    modal: ModalState & ModalActions;
    onActionClick: () => void;
}

export function RiskProfileIneligibilityModal({
    modal,
    onActionClick,
}: RiskProfileIneligibilityModalProps) {
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Find the right coverage today!"
                primaryAction={{
                    label: 'Explore Our Coverages',
                    onClick: onActionClick,
                }}
            >
                <Text>
                    We're working hard to expand our risk profiling tool to include businesses like
                    yours.
                </Text>
                <Text>
                    While this feature is under development, we encourage you to explore our
                    comprehensive insurance solutions to find the right coverage for your business.
                </Text>
            </ModalLayout>
        </Modal>
    );
}
