import { isOK } from '@embroker/shotwell/core/types/Result';
import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';

export const PrefillMailingAddress = {
    ...defineValidator<MailingAddress>({
        addressLine1: Joi.string().allow('').allow(null).optional(),
        addressLine2: Joi.string().allow('').allow(null).optional(),
        city: Joi.string().allow('').allow(null).optional(),
        state: State.schema.allow('').allow(null).optional(),
        county: Joi.string().allow('').allow(null).optional(),
        zip: ZipCode.schema.allow('').allow(null).optional(),
    }),
    create(mailingAddress: Partial<MailingAddress>) {
        return PrefillMailingAddress.validate(mailingAddress);
    },
};

export type OracleLocation = {
    address_line1: string;
    address_line2?: string;
    city: string;
    state_or_province: string;
    postal_code: string;
    country?: string;
    county: string;
};

export const OracleLocation = {
    ...defineValidator<OracleLocation>({
        address_line1: Joi.string().required(),
        address_line2: Joi.string().optional().allow(''),
        city: Joi.string().required(),
        state_or_province: Joi.string().required(),
        postal_code: Joi.string().required(),
        country: Joi.string().optional().allow(''),
        county: Joi.string().optional().allow(''),
    }),
    create(oracleLocation: OracleLocation) {
        return OracleLocation.validate(oracleLocation);
    },
};

export function assertMailingAddressType(input: any): input is MailingAddress {
    return 'addressLine1' in input && 'city' in input && 'state' in input && 'zip' in input;
}
export const serializeRevenueAnswer = (mailingAddress: unknown): OracleLocation | undefined => {
    if (!assertMailingAddressType(mailingAddress)) {
        return undefined;
    }
    const location = OracleLocation.create({
        address_line1: mailingAddress.addressLine1 || '',
        address_line2: mailingAddress.addressLine2 || '',
        city: mailingAddress.city || '',
        state_or_province: mailingAddress.state || '',
        postal_code: mailingAddress.zip || '',
        county: mailingAddress.county || '',
    });
    if (isOK(location)) {
        return location.value;
    }
};

export const deserializeLocationAnswers = (currentValue: any): unknown[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }

    return (currentValue as OracleLocation[]).reduce((accumulator, current) => {
        const prefillMailingAddress = PrefillMailingAddress.create({
            addressLine1: current.address_line1,
            addressLine2: current.address_line2,
            city: current.city,
            state: current.state_or_province as State,
            county: current.county,
            zip: current.postal_code as ZipCode,
        });

        if (isOK(prefillMailingAddress)) {
            accumulator.push(prefillMailingAddress.value);
        }

        return accumulator;
    }, [] as MailingAddress[]);
};

export const LocationDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'address',
    schemaFunctions: {
        validator: OracleLocation.schema,
        serializeAnswer: serializeRevenueAnswer,
        deserializeAnswer: deserializeLocationAnswers,
    },
};
