import React, { useContext } from 'react';
import { AppContext } from '../../../view/AppContext';
import { hasRole } from '../../../userOrg/entities/Session';
import { ContactNavigation as ContactNavigationView } from './ContactNavigation.view';

export function ContactNavigation() {
    const { activeSession } = useContext(AppContext);
    const isUser = hasRole(activeSession, 'user');

    return <ContactNavigationView hideChat={!isUser} />;
}
