import React from 'react';
import {
    StackLayout,
    CardLayout,
    Text,
    TextButton,
    IconPhosphor,
    IconPhosphorName,
    ColumnLayout,
    Icon,
} from '@embroker/ui-toolkit/v2';
import { AppTypeCode } from '@app/shopping/types/enums';

export interface RiskProfileRiskCardProps {
    title: string;
    icon: IconPhosphorName;
    tagline: string;
    appTypeCode: AppTypeCode;
    coverageLabel: string;
    children: React.ReactNode;
    onCoverageLearnMoreClick: () => void;
}

export function RiskProfileRiskCard({
    title,
    icon,
    tagline,
    appTypeCode,
    coverageLabel = 'insurance',
    onCoverageLearnMoreClick,
    children,
}: RiskProfileRiskCardProps) {
    return (
        <CardLayout>
            <CardLayout.Header backgroundColor="warning-500">
                <ColumnLayout center>
                    {icon && <IconPhosphor name={icon} />}
                    <Text style="heading 4" textAlign="center">
                        {title}
                    </Text>
                </ColumnLayout>
            </CardLayout.Header>
            <CardLayout.Body>
                <StackLayout gap="16">
                    <Text>
                        <strong>{tagline}</strong>
                    </Text>
                    {children}
                    {appTypeCode && (
                        <Text>
                            Learn how our&nbsp;
                            <TextButton onClick={onCoverageLearnMoreClick}>
                                <ColumnLayout center>
                                    {coverageLabel}
                                    <Icon name="info-outline" />
                                </ColumnLayout>
                            </TextButton>
                            &nbsp;solution protects against these risks.
                        </Text>
                    )}
                </StackLayout>
            </CardLayout.Body>
        </CardLayout>
    );
}
