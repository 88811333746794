import React, { useContext } from 'react';
import {
    CheckBox,
    ColumnLayout,
    Form,
    Icon,
    IconName,
    StackLayout,
    Text,
    TextButton,
    useModal,
    ModalActions,
    ModalState,
    Modal,
    StatusMessage,
    Tooltip,
    StatusLabel,
} from '@embroker/ui-toolkit/v2';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { ESPQuote } from '../entities/ESPQuote';
import { BundleQuoteCardLayout } from '@app/bundle/view/components/BundleQuoteCardLayout.view';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    ESPCoverageType,
    getCyberSplitLimitRetentionOptions,
    getLimitRetentionOptions,
    getRestrictedLimitRetentionOptions,
} from '@app/quote/esp/view/components/ESPCoverage/limitRetentionOptions';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Data, Immutable } from '@embroker/shotwell/core/types';
import { ESPDoDifferenceModal } from '@app/quote/esp/view/components/ESPCoveragesPage/ESPDoDifferenceModal';
import { ESPEplDifferenceModal } from '@app/quote/esp/view/components/ESPCoveragesPage/ESPEplDifferenceModal';
import { ESPEoBundleCyberDifferenceModal } from './ESPEoBundleCyberDifferenceModal';
import { ESPCoverageRateItem } from '@app/quote/esp/types/ESPRate';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetRestriction, GetRestrictionResponse } from '@app/quote/esp/useCases/GetRestriction';
import { Result, isOK } from '@embroker/shotwell/core/types/Result';
import { AnswersContext } from '@app/quote/esp/view/components/ESPCoveragesPage/ESPCoveragesPage';
import { CoverageRestriction } from '@app/quote/esp/types/CoverageRestriction';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

const coverageTypeToFieldNameMap = {
    do: 'espDirectorsAndOfficers',
    fiduciary: 'espFiduciary',
    techCyber: 'espTechnology',
    eoCyber: 'espCyber',
    epli: 'espEmploymentPracticesLiability',
};

type FieldName =
    | 'espDirectorsAndOfficers'
    | 'espFiduciary'
    | 'espTechnology'
    | 'espCyber'
    | 'espEmploymentPracticesLiability';

const coverageMetadataMap = {
    do: {
        title: 'Directors and Officers',
        icon: 'meeting',
        description:
            'Shield your company and its board members from litigation by disgruntled shareholders, customers, investors and regulatory bodies.',
    },
    fiduciary: {
        title: 'Fiduciary Liability',
        icon: 'handshake',
        description:
            'Protects against claims of errors or negligence on your company’s benefits plans under guidelines set by the Employee Retirement Income Security Act (ERISA).',
    },
    techCyber: {
        title: 'Technology E&O/Cyber',
        icon: 'thief',
        description:
            'Tech E&O provides coverage for a failure of technology services and 3rd party liabilities. 1st Party Expenses refers to coverage for expenses that arise from a cyber event, including ransomware, notification expenses, and system damage.',
    },
    eoCyber: {
        title: 'Technology E&O/Cyber',
        icon: 'thief',
        description:
            'Tech E&O provides coverage for a failure of technology services and 3rd party liabilities. 1st Party Expenses refers to coverage for expenses that arise from a cyber event, including ransomware, notification expenses, and system damage.',
    },
    epli: {
        title: 'Employment Practices Liability',
        icon: 'suitcase',
        description:
            'EPLI provides coverage for claims alleging discrimination, wrongful termination, harassment and other employment related issues.',
    },
};

type FieldKeys = keyof UnderlyingCoverageProps<ESPQuote>['fields'];

const calculateShouldDisableUntoggle = (fields: UnderlyingCoverageProps<ESPQuote>['fields']) => {
    const keys = Object.keys(fields) as FieldKeys[];
    const selectedOptions = keys
        .map((key) => fields[key])
        .filter(({ props: { value } }) => {
            if (value) {
                return typeof value === 'object' && 'selected' in value && value.selected;
            }
        });

    if (
        selectedOptions.length === 2 &&
        selectedOptions[0].props.name === 'espTechnology' &&
        selectedOptions[1].props.name === 'espCyber'
    ) {
        return true;
    }

    if (selectedOptions.length === 1) {
        return true;
    }

    return false;
};

const getCardInfoMessage = (
    underlyingCoverageProps: UnderlyingCoverageProps<ESPQuote>,
    coverage: ESPCoverageRateItem,
) => {
    const isInCalifornia = underlyingCoverageProps?.organizationInfo?.address?.state === 'CA';

    if (isInCalifornia && coverage.coverageType === 'epli') {
        return 'Employment Practices Liability (EPLI) can only be purchased with another coverage.';
    }

    if (coverage.coverageType === 'fiduciary') {
        return 'Fiduciary Liability can only be purchased with another coverage.';
    }
};

const getIneligibleCoverages = (
    coverages: Readonly<ESPCoverageRateItem[]>,
    questionnaireData: any,
) => {
    // this function compares all the possible coverages with the available coverages to figure out which ones are ineligible
    const allCoverages = Object.keys(coverageMetadataMap) as Array<
        keyof typeof coverageMetadataMap
    >;
    const ineligibleCoverages = allCoverages.filter(
        (coverageType) => !coverages.find((coverage) => coverage.coverageType === coverageType),
    );
    return ineligibleCoverages
        .filter((coverage) => coverage !== 'eoCyber')
        .filter((coverage) => {
            if (coverage === 'do') {
                return questionnaireData.coverage_selected_do;
            }

            if (coverage === 'epli') {
                return questionnaireData.coverage_selected_epl;
            }

            if (coverage === 'fiduciary') {
                return questionnaireData.coverage_selected_fiduciary;
            }

            if (coverage === 'techCyber') {
                return questionnaireData.coverage_selected_tech_eo_cyber;
            }

            return true;
        }); // we remove eoCyber to avoid rendering the tech e&o card twice, and we remove all cards that were not selected in the product selection screen
};

// removes all cards that were not selected in the product selection screen
const filterNonSelectedCoverages = (
    coverages: Readonly<ESPCoverageRateItem[]>,
    questionnaireData: any,
) =>
    coverages.filter((coverage) => {
        if (coverage.coverageType === 'do') {
            return questionnaireData.coverage_selected_do;
        }

        if (coverage.coverageType === 'epli') {
            return questionnaireData.coverage_selected_epl;
        }

        if (coverage.coverageType === 'fiduciary') {
            return questionnaireData.coverage_selected_fiduciary;
        }

        if (coverage.coverageType === 'eoCyber' || coverage.coverageType === 'techCyber') {
            return questionnaireData.coverage_selected_tech_eo_cyber;
        }

        return true;
    });

const getEndorsementList = (result: Result<GetRestrictionResponse, never> | undefined) => {
    if (result && isOK(result)) {
        return result.value.restriction?.endorsementList || [];
    }

    return [];
};

const getCoverageRestrictionList = (
    result: Result<GetRestrictionResponse, never> | undefined,
): CoverageRestriction[] => {
    if (result && isOK(result)) {
        return (result.value.restriction?.coverageRestrictions as CoverageRestriction[]) || [];
    }

    return [];
};

export function getCoverageRestriction(
    coverageType: ESPCoverageType,
    coverageRestrictionList: CoverageRestriction[],
): CoverageRestriction | undefined {
    const coverageRestriction = coverageRestrictionList.find((cr) => {
        switch (coverageType) {
            case 'do':
                return cr.coverageType === 'ShoppingCoverageCodeListDirectorsAndOfficers';
            case 'epli':
                return cr.coverageType === 'ShoppingCoverageCodeListEmploymentPractices';
            case 'fiduciary':
                return cr.coverageType === 'ShoppingCoverageCodeListFiduciary';
            case 'eoCyber':
                return cr.coverageType === 'ShoppingCoverageCodeListCyber';
            case 'techCyber':
                return cr.coverageType === 'ShoppingCoverageCodeListTechSplit';
            case 'techSplit':
                return cr.coverageType === 'ShoppingCoverageCodeListTechSplit';
            case 'cyberSplit':
                return cr.coverageType === 'ShoppingCoverageCodeListCyberSplit';
            default:
                return false;
        }
    });

    return coverageRestriction;
}

function orderESPBundleCoverageOptions(coverages: Immutable<Array<ESPCoverageRateItem>>) {
    const COVERAGE_TYPE_ORDER = ['techCyber', 'eoCyber', 'do', 'epli', 'fiduciary'];
    return COVERAGE_TYPE_ORDER.map(
        (coverageType) =>
            coverages.find(
                (coverage) => coverage.coverageType === coverageType,
            ) as ESPCoverageRateItem,
    ).filter((coverage) => coverage);
}

export function ESPBundleCoverageOptions(
    underlyingCoverageProps: UnderlyingCoverageProps<ESPQuote>,
) {
    const questionnaireData = underlyingCoverageProps.questionnaireData;
    const coverages = underlyingCoverageProps?.quote?.details?.coverages;
    const fields = underlyingCoverageProps?.fields;
    const isReferral = underlyingCoverageProps?.quote?.status === 'referred';

    const shouldDisableUntoggle = calculateShouldDisableUntoggle(fields); // user should not be able to toggle off a card if it's the last remaining card

    const ineligibleCoverages = getIneligibleCoverages(coverages, questionnaireData);

    const { result: getRestrictionResult } = useUseCase(GetRestriction, {
        insuranceApplicationId: underlyingCoverageProps.quote.applicationId,
    });

    const endorsementList = getEndorsementList(getRestrictionResult);
    const coverageRestrictionList = getCoverageRestrictionList(getRestrictionResult);

    return (
        <StackLayout>
            {filterNonSelectedCoverages(
                orderESPBundleCoverageOptions(coverages),
                questionnaireData,
            ).map((coverage) => {
                return (
                    <ESPCoverageCard
                        coverage={coverage}
                        fields={fields}
                        shouldDisableUntoggle={shouldDisableUntoggle}
                        cardInfoMessage={getCardInfoMessage(underlyingCoverageProps, coverage)}
                        key={coverage.coverageType}
                        isReferral={isReferral}
                        coverageRestrictionList={coverageRestrictionList}
                    />
                );
            })}
            {ineligibleCoverages.length > 0 && (
                <Text style="heading 4" data-e2e="coverageList-title">
                    Ineligible Policies
                    <Tooltip
                        iconColor="text"
                        iconSize="small"
                        placement="top"
                        text={
                            "Unfortunately, based on some of your responses, your company isn't eligible for this coverage at this time. We'll be in touch if our guidelines change."
                        }
                    />
                </Text>
            )}
            {ineligibleCoverages.map((coverage) => {
                return <IneligibleCoverageCard coverage={coverage} key={coverage} />;
            })}
            {endorsementList.length > 0 && (
                <StackLayout>
                    <Text style="heading 4" data-e2e="coverageList-title">
                        Endorsements
                    </Text>
                    <Text>
                        Based on your application, quoted coverage may have endorsements (changes to
                        your coverage) added that will affect terms and conditions. Please read and
                        review the complete quote, including all attachments and specimen documents
                        which contain these modifications.
                    </Text>
                    <ul>
                        {endorsementList.map((endorsement) => {
                            return <li key={endorsement.id}>{endorsement.displayName}</li>;
                        })}
                    </ul>
                </StackLayout>
            )}
        </StackLayout>
    );
}

const IneligibleCoverageCard = ({ coverage }: { coverage: keyof typeof coverageMetadataMap }) => {
    const coverageMetadata = coverageMetadataMap[coverage];
    return (
        <BundleQuoteCardLayout gap="24">
            <StackLayout gap="8">
                <ColumnLayout gap="24" split="1" top>
                    <Icon
                        name={coverageMetadata.icon as IconName}
                        data-e2e="coverage-icon"
                        color="ui-300"
                    />
                    <Text color="ui-400" style="heading 5">
                        Ineligible
                    </Text>
                </ColumnLayout>
                <Text style="heading 5" data-e2e="coverage-name">
                    {coverageMetadata.title}
                </Text>
                <StackLayout gap="12">
                    <div />
                    <StackLayout gap="24">
                        <Text style="body 2">{coverageMetadata.description}</Text>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </BundleQuoteCardLayout>
    );
};

const ESPCoverageCard = ({
    coverage,
    fields,
    shouldDisableUntoggle,
    cardInfoMessage,
    isReferral,
    coverageRestrictionList,
}: {
    coverage: ESPCoverageRateItem;
    fields: any;
    shouldDisableUntoggle: boolean;
    cardInfoMessage: string | undefined;
    isReferral: boolean;
    coverageRestrictionList: CoverageRestriction[];
}) => {
    const fieldName = coverageTypeToFieldNameMap[coverage.coverageType];
    const field = fields[fieldName as FieldName];
    const { props = {} } = field;
    const coverageMetadata = coverageMetadataMap[coverage.coverageType];
    const cyberProps = fields['espCyber'].props;
    const techProps = fields['espTechnology'].props;
    const coverageRestriction = getCoverageRestriction(
        coverage.coverageType,
        coverageRestrictionList,
    );

    const onCheckBoxToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (shouldDisableUntoggle && !event.target.checked) {
            return;
        }

        const payload = {
            target: {
                value: {
                    ...props.value,
                    selected: event.target.checked,
                },
            },
        };

        if (coverage.coverageType == 'techCyber') {
            cyberProps.onChange(payload);
            techProps.onChange(payload);
            return;
        }

        props.onChange(payload);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const payload = {
            target: {
                value: {
                    ...props.value,
                    limit: event.target.value,
                },
            },
        };
        props.onChange(payload);
    };

    const handleRetentionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const payload = {
            target: {
                value: {
                    ...props.value,
                    retention: event.target.value,
                },
            },
        };
        props.onChange(payload);
    };

    const handleEnhancedChange = (event: { target: { value: boolean } }) => {
        const payload = {
            target: {
                value: {
                    ...props.value,
                    enhanced: event.target.value,
                },
            },
        };

        if (coverage.coverageType == 'techCyber') {
            cyberProps.onChange(payload);
            techProps.onChange(payload);
            return;
        }

        props.onChange(payload);
    };

    const handleTechLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const payload = {
            target: {
                value: {
                    ...techProps.value,
                    limit: event.target.value,
                },
            },
        };
        techProps.onChange(payload);
    };

    const handleTechRetentionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const techPayload = {
            target: {
                value: {
                    ...techProps.value,
                    retention: event.target.value,
                },
            },
        };
        techProps.onChange(techPayload);

        const cyberPayload = {
            target: {
                value: {
                    ...cyberProps.value,
                    retention: event.target.value,
                },
            },
        };
        cyberProps.onChange(cyberPayload);
    };

    const handleCyberLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const payload = {
            target: {
                value: {
                    ...cyberProps.value,
                    limit: event.target.value,
                },
            },
        };
        cyberProps.onChange(payload);
    };

    const getCoverageRestrictionsFieldInputProps = () => {
        const value = coverageRestriction?.allowPlus ? props.value?.enhanced : false;
        const items = [
            {
                value: false,
                title: 'Standard',
            },
        ];

        if (coverageRestriction?.allowPlus) {
            items.push({
                value: true,
                title: 'Plus',
            });
        }

        return { value, items, onChange: handleEnhancedChange };
    };

    const doModal = useModal();
    const epliModal = useModal();
    const eoCyberModal = useModal();
    const modals: Data<ModalState & ModalActions> = {
        do: doModal,
        epli: epliModal,
        eo: eoCyberModal,
        techCyber: eoCyberModal,
    };

    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );

    return (
        <BundleQuoteCardLayout gap="24">
            <StackLayout gap="8">
                <ColumnLayout gap="24" split="1" top>
                    <Icon name={coverageMetadata.icon as IconName} data-e2e="coverage-icon" />
                    <Text>
                        <StackLayout>
                            <ColumnLayout gap="24" as="span">
                                {!isReferral && (
                                    <Text style="heading 5">
                                        <MoneyDisplay value={coverage.premium} />
                                    </Text>
                                )}
                                {!isReferral &&
                                coverage.preDiscountPrice &&
                                !experimentationService.getFeatureValue(
                                    'security-scorecard-credit-tech-vertical',
                                    false,
                                ) ? (
                                    <Text style="heading 5">
                                        <s>${coverage.preDiscountPrice}</s>
                                    </Text>
                                ) : null}
                                <CheckBox
                                    appearance="toggle"
                                    checked={props.value?.selected}
                                    onChange={onCheckBoxToggle}
                                    inline
                                />
                            </ColumnLayout>
                            {!isReferral &&
                            coverage.preDiscountPrice &&
                            !experimentationService.getFeatureValue(
                                'security-scorecard-credit-tech-vertical',
                                false,
                            ) ? (
                                <StatusLabel color="primary-100">
                                    security scorecard CREDIT applied*
                                </StatusLabel>
                            ) : null}
                        </StackLayout>
                    </Text>
                </ColumnLayout>
                <Text style="heading 5" data-e2e="coverage-name">
                    {coverageMetadata.title}
                </Text>
                <StackLayout gap="12">
                    <div />
                    <StackLayout gap="24">
                        <Text style="body 2">{coverageMetadata.description}</Text>
                    </StackLayout>
                    {cardInfoMessage && (
                        <StatusMessage status="helptext">{cardInfoMessage}</StatusMessage>
                    )}
                    <StackLayout gap="12">
                        <LimitAndRetentionFields
                            coverage={coverage}
                            techProps={techProps}
                            cyberProps={cyberProps}
                            props={props}
                            handleTechLimitChange={handleTechLimitChange}
                            handleTechRetentionChange={handleTechRetentionChange}
                            handleCyberLimitChange={handleCyberLimitChange}
                            handleLimitChange={handleLimitChange}
                            handleRetentionChange={handleRetentionChange}
                            coverageRestriction={coverageRestriction}
                        />
                        {props.value && props.value.hasOwnProperty('enhanced') && (
                            <React.Fragment>
                                <ColumnLayout split="-1">
                                    <Text style="heading 5">Select your coverage </Text>
                                    <TextButton onClick={modals[coverage.coverageType]?.show}>
                                        What's the difference?
                                    </TextButton>
                                </ColumnLayout>
                                <Form.Field
                                    type="radioGroup"
                                    inputProps={{
                                        ...getCoverageRestrictionsFieldInputProps(),
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </StackLayout>
                </StackLayout>
            </StackLayout>
            <Modal size="large" {...eoCyberModal}>
                <ESPEoBundleCyberDifferenceModal onHide={eoCyberModal.hide} />
            </Modal>
            <Modal size="large" {...doModal}>
                <ESPDoDifferenceModal />
            </Modal>
            <Modal size="large" {...epliModal}>
                <ESPEplDifferenceModal />
            </Modal>
        </BundleQuoteCardLayout>
    );
};

type LimitAndRetentionFieldsProps = {
    coverage: ESPCoverageRateItem;
    techProps: any;
    cyberProps: any;
    props: any;
    handleTechLimitChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTechRetentionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCyberLimitChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLimitChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRetentionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    coverageRestriction: CoverageRestriction | undefined;
};

const LimitAndRetentionFields = ({
    coverage,
    techProps,
    cyberProps,
    props,
    handleTechLimitChange,
    handleTechRetentionChange,
    handleCyberLimitChange,
    handleLimitChange,
    handleRetentionChange,
    coverageRestriction,
}: LimitAndRetentionFieldsProps) => {
    const { answers } = useContext(AnswersContext);
    if (coverage.coverageType == 'techCyber') {
        return (
            <React.Fragment>
                <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                    <Form.Field
                        type="select"
                        inputProps={{
                            filterable: false,
                            items: getLimitRetentionOptions(coverage.coverageType, undefined, true)
                                .limits,
                            label: 'Limit',
                            disabled:
                                getRestrictedLimitRetentionOptions(coverage.coverageType).limits
                                    .length == 1,
                            value:
                                techProps.value?.limit === undefined
                                    ? undefined
                                    : Money.toFloat(Money.tryFromFloat(techProps.value?.limit)),
                            onChange: handleTechLimitChange,
                        }}
                    />
                    <Form.Field
                        type="select"
                        inputProps={{
                            value:
                                techProps.value?.retention === undefined
                                    ? undefined
                                    : Money.toFloat(Money.tryFromFloat(techProps.value?.retention)),
                            filterable: false,
                            items: getRestrictedLimitRetentionOptions(
                                coverage.coverageType,
                                coverageRestriction,
                            ).retentions,
                            label: 'Retention',
                            disabled:
                                getRestrictedLimitRetentionOptions(coverage.coverageType).retentions
                                    .length == 1,
                            onChange: handleTechRetentionChange,
                        }}
                    />
                </ColumnLayout>
                <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                    <Form.Field
                        type="select"
                        inputProps={{
                            filterable: false,
                            items: getCyberSplitLimitRetentionOptions(true).limits.filter(
                                (item) =>
                                    techProps.value.limit !== undefined &&
                                    item.value <= techProps.value.limit,
                            ),
                            label: '1st Party Expenses Limit',
                            disabled:
                                getRestrictedLimitRetentionOptions(coverage.coverageType).limits
                                    .length == 1,
                            value:
                                cyberProps.value?.limit === undefined
                                    ? undefined
                                    : Money.toFloat(Money.tryFromFloat(cyberProps.value?.limit)),
                            onChange: handleCyberLimitChange,
                        }}
                    />
                    <Form.Field
                        type="select"
                        inputProps={{
                            value:
                                cyberProps.value?.retention === undefined
                                    ? undefined
                                    : Money.toFloat(
                                          Money.tryFromFloat(cyberProps.value?.retention),
                                      ),
                            filterable: false,
                            items: getLimitRetentionOptions(coverage.coverageType, undefined, true)
                                .retentions,
                            label: '1st Party Expenses Retention',
                            disabled: true,
                        }}
                    />
                </ColumnLayout>
            </React.Fragment>
        );
    }
    return (
        <ColumnLayout gap="16" responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}>
            <Form.Field
                type="select"
                inputProps={{
                    value:
                        props.value?.limit === undefined
                            ? undefined
                            : Money.toFloat(Money.tryFromFloat(props.value.limit)),
                    filterable: false,
                    items: getLimitRetentionOptions(
                        coverage.coverageType,
                        undefined,
                        true,
                        coverageRestriction,
                    ).limits,
                    label: 'Limit',
                    disabled:
                        getRestrictedLimitRetentionOptions(coverage.coverageType).limits.length ==
                        1,
                    onChange: handleLimitChange,
                }}
            />
            <Form.Field
                type="select"
                inputProps={{
                    value:
                        props.value?.retention === undefined
                            ? undefined
                            : Money.toFloat(Money.tryFromFloat(props.value.retention)),
                    filterable: false,
                    items: getRestrictedLimitRetentionOptions(
                        coverage.coverageType,
                        coverageRestriction,
                        answers,
                    ).retentions,
                    label: 'Retention',
                    disabled:
                        getRestrictedLimitRetentionOptions(coverage.coverageType).retentions
                            .length == 1,
                    onChange: handleRetentionChange,
                }}
            />
        </ColumnLayout>
    );
};
