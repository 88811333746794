import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { ErrorLike, isErr, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Text,
    Button,
    ButtonBar,
    TextButton,
    Form,
    Grid,
    Spinner,
    StatusMessage,
    SelectInput,
    StackLayout,
    ColumnLayout,
    UseResponsiveScreenQuery,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { format, isSameDay, isValid, startOfDay } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { CoverageRestriction } from '../../../../quote/esp/types/CoverageRestriction';
import {
    CreateOtherEndorsement,
    CreateOtherEndorsementRequest,
} from '../../../intake/useCases/CreateOtherEndorsement';
import {
    EditESPEndorsementLiabilityCoverageType,
    ESPEndorsementCoverageTypeMap,
} from '../../types/ESPEndorsementLiabilityCoverageType';
import {
    ESPEndorsementLiabilityCoverage,
    ESPEndorsementPolicy,
} from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementRate } from '../../types/ESPEndorsementRate';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { EditCoverageESPEndorsement } from '../../useCases/EditCoverageESPEndorsement';
import { RateEditCoverageESPEndorsement } from '../../useCases/RateEditCoverageESPEndorsement';
import { ESPEndorsementSignature } from './ESPEndorsementSignature';
import { getCoverageDisplayName } from './getCoverageDisplayName';
import { getEditCoverageLimitOptions } from './getESPLimitAndRetentionOptions';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';
import { ESPEndorsementPremiumCostBreakdown } from '@app/endorsement/esp/view/components/ESPEndorsementPremiumCostBreakdown.view';

const responsiveTablet: UseResponsiveScreenQuery = { screenWidth: { smallerThan: 'tablet' } };

function formatRequestHigherLimitMessage(
    coverageTypeCode: string,
    oldLimit: Money,
    newLimit: number,
): string {
    const coverage = ESPEndorsementCoverageTypeMap[coverageTypeCode];
    const limitBefore: string = Money.toString(oldLimit);
    // newLimit is an integer in dollars, so we multiply it by 100 to convert to cents
    const requestedLimit = USD(newLimit * 100);
    const limitAfter: string = Money.toString(requestedLimit);

    return `Increase ${coverage} limit from ${limitBefore} to ${limitAfter}`;
}

const DATE_FORMAT = 'MM/dd/yyyy';

interface ESPEndorsementEditCoverageFormData {
    limit: number;
    effectiveDate: Nullable<Date>;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
}

interface CreateEspEndorsementEditCoverageFormParams {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    retention: Money;
    techLimit: Money;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    abortSignal: AbortSignal;
    onRequestHigherLimitSuccess: () => void;
}

function createEspEndorsementEditCoverageForm({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverageTypeCode,
    retention,
    techLimit,
    onPurchaseEndorsementSuccess,
    onPurchaseEndorsementFailure,
    abortSignal,
    onRequestHigherLimitSuccess,
}: CreateEspEndorsementEditCoverageFormParams) {
    return createForm<ESPEndorsementEditCoverageFormData>({
        fields: {
            limit: {
                type: 'text',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Limit is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
                formatValidationError: (error) => {
                    if (
                        error.details.validator == 'date.min' ||
                        error.details.validator == 'date.max'
                    ) {
                        return 'The effective date does not coincide with the policy period.';
                    }
                    if (error.details.validator == 'any.required') {
                        return 'You must set an effective date.';
                    }
                    return error.message;
                },
            },
            agreementToConductSignature: {
                // FIXME bool as hidden ?
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
            warrantyAndFraudSignature: {
                // FIXME bool as hidden ?
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
        },
        actions: {
            requestHigherLimit: async (
                editCoverageFormData: ESPEndorsementEditCoverageFormData,
            ) => {
                const effectiveDate = editCoverageFormData.effectiveDate as Date;
                const requestedChangeMessage = formatRequestHigherLimitMessage(
                    coverageTypeCode,
                    techLimit,
                    editCoverageFormData.limit,
                );

                const request: CreateOtherEndorsementRequest = {
                    effectiveDate,
                    requestedChangeMessage,
                    policyId,
                };
                return await execute(CreateOtherEndorsement, request);
            },
            purchase: async (editCoverageFormData: ESPEndorsementEditCoverageFormData) => {
                const effectiveDate = editCoverageFormData.effectiveDate as Date;

                return await execute(EditCoverageESPEndorsement, {
                    policyId: policyId,
                    coverageTypeCode: coverageTypeCode,
                    limit: Money.tryFromFloat(editCoverageFormData.limit),
                    retention: retention,
                    effectiveDate: effectiveDate,
                    abortSignal,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'purchase':
                    onPurchaseEndorsementSuccess(value);
                    break;
                case 'requestHigherLimit':
                    onRequestHigherLimitSuccess();
                    break;
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => onPurchaseEndorsementFailure(errors),
    });
}

const defaultEditCoverageRate: ESPEndorsementRate = {
    annualPremium: USD(0),
    prorate: USD(0),
    taxes: USD(0),
    fees: USD(0),
    total: USD(0),
};

interface ESPEndorsementEditCoverageProps {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    coverage: ESPEndorsementLiabilityCoverage;
    userData: ESPEndorsementUserData;
    hasRenewalApplication?: boolean;
    restriction?: Immutable<CoverageRestriction>;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onClose: () => void;
    onRequestHigherLimitSuccess: () => void;
}

export function ESPEndorsementEditCoverage({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverage,
    userData,
    hasRenewalApplication,
    onPurchaseEndorsementFailure,
    onPurchaseEndorsementSuccess,
    onClose,
    restriction,
    onRequestHigherLimitSuccess,
}: ESPEndorsementEditCoverageProps) {
    const [editCoverageRate, setEditCoverageRate] = useState(defaultEditCoverageRate);
    const [isQuoteLoading, setIsQuoteLoading] = useState(false);
    const isMobile = useResponsive(responsiveTablet);

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const espEditCoverageForm = useMemo(
        () =>
            createEspEndorsementEditCoverageForm({
                policyId: policyId,
                coverageTypeCode: coverage.typeCode as EditESPEndorsementLiabilityCoverageType,
                effectivePeriodStart: effectivePeriodStart,
                effectivePeriodEnd: effectivePeriodEnd,
                retention: coverage.retention,
                techLimit: coverage.limit,
                onPurchaseEndorsementSuccess: onPurchaseEndorsementSuccess,
                onPurchaseEndorsementFailure: onPurchaseEndorsementFailure,
                abortSignal: abortController.signal,
                onRequestHigherLimitSuccess: onRequestHigherLimitSuccess,
            }),
        [
            policyId,
            effectivePeriodStart,
            effectivePeriodEnd,
            coverage.typeCode,
            coverage.retention,
            coverage.limit,
            onPurchaseEndorsementSuccess,
            onPurchaseEndorsementFailure,
            abortController.signal,
            onRequestHigherLimitSuccess,
        ],
    );

    const { value, setValue, status, fields, trigger } = useForm(espEditCoverageForm, {
        effectiveDate: null,
        limit: undefined,
        agreementToConductSignature: false,
        warrantyAndFraudSignature: false,
    });

    useEffect(() => {
        const endorsementEditCoverageSchema = Joi.object({
            limit: Joi.number().greater(Money.toFloat(coverage.limit)).required(),
            effectiveDate: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
        });
        const validationResult = endorsementEditCoverageSchema.validate({
            limit: value.limit,
            effectiveDate: value.effectiveDate,
        });
        if (validationResult.error) {
            return;
        }
        setIsQuoteLoading(true);
        execute(RateEditCoverageESPEndorsement, {
            policyId,
            coverageTypeCode: coverage.typeCode as EditESPEndorsementLiabilityCoverageType,
            limit: Money.tryFromFloat(value.limit),
            retention: coverage.retention,
            effectiveDate: value.effectiveDate as Date,
        })
            .then((rateResult) => {
                if (isErr(rateResult)) {
                    onPurchaseEndorsementFailure(rateResult.errors);
                }
                setEditCoverageRate((rateResult as SuccessResult<ESPEndorsementRate>).value);
            })
            .finally(() => {
                setIsQuoteLoading(false);
            });
    }, [
        effectivePeriodEnd,
        effectivePeriodStart,
        onPurchaseEndorsementFailure,
        value.effectiveDate,
        value.limit,
        coverage.limit,
        coverage.retention,
        coverage.typeCode,
        policyId,
    ]);

    const handleLimitChange = (event: { target: { value: string } }) => {
        if (value.limit === Number(event.target.value)) {
            return;
        }
        setValue({
            ...value,
            limit: Number(event.target.value),
        });
    };

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            // const newDate = parse(event.target.value, DATE_FORMAT, new Date(Date.now()));
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleAgreementToConductSignatureChange = () => {
        setValue({
            ...value,
            agreementToConductSignature: !value.agreementToConductSignature,
        });
    };

    const handleWarrantyAndFraudSignatureChange = () => {
        setValue({
            ...value,
            warrantyAndFraudSignature: !value.warrantyAndFraudSignature,
        });
    };

    const handleRequestHigherLimit = async () => {
        trigger('requestHigherLimit');
    };

    const handlePurchase = async () => {
        trigger('purchase');
    };

    const isFormSubmitting = status === 'submitting';
    const isLoading = isQuoteLoading || isFormSubmitting;

    const isFormInvalid = status === 'invalid';

    const coverageDisplayName = useMemo(
        () => getCoverageDisplayName(coverage.typeCode),
        [coverage.typeCode],
    );

    const limitOptions = useMemo(
        () =>
            getEditCoverageLimitOptions(
                coverage.typeCode as EditESPEndorsementLiabilityCoverageType,
                coverage.limit,
            ),
        [coverage.typeCode, coverage.limit],
    );

    const maxAllowedLimit = restriction?.maxLimit || Money.tryFromFloat(3000000);
    const isHigherLimitEndorsement = Money.isGreaterThan(
        Money.tryFromFloat(value.limit),
        maxAllowedLimit,
    );

    const hideAmounts = isHigherLimitEndorsement || isLoading;

    return (
        <BoxLayout gap="24">
            <Form>
                {isLoading && <Spinner appearance="transparent" />}
                <StackLayout gap="24">
                    <Text style="heading 3">Edit {coverageDisplayName} Coverage</Text>
                    <ColumnLayout grow="fixed" responsive={responsiveTablet}>
                        <Form.Field title="Limit" messages={fields.limit.messages}>
                            <SelectInput
                                items={limitOptions.map(toSelectCurrencyOption)}
                                readOnly={isLoading}
                                value={value.limit}
                                onChange={handleLimitChange}
                            />
                        </Form.Field>
                        <Form.Field
                            inputProps={{
                                value: coverage.retention
                                    ? Money.toFloat(coverage.retention)
                                    : undefined,
                                readOnly: true,
                            }}
                            title="Retention"
                            type="currency"
                        />
                        {hideAmounts ? null : (
                            <StackLayout gap={isMobile ? '20' : '32'}>
                                <Text style="body 1">Additional premium</Text>
                                <Text style="heading 5">
                                    <MoneyDisplay value={editCoverageRate.annualPremium} />
                                </Text>
                            </StackLayout>
                        )}
                    </ColumnLayout>
                    <ESPEndorsementPremiumCostBreakdown
                        prorate={hideAmounts ? null : editCoverageRate.prorate}
                        taxes={hideAmounts ? null : editCoverageRate.taxes}
                        fees={hideAmounts ? null : editCoverageRate.fees}
                        total={hideAmounts ? null : editCoverageRate.total}
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Cell width="1/1" md="1/2">
                                <Form.Field
                                    inputProps={{
                                        value: value.effectiveDate
                                            ? format(value.effectiveDate, DATE_FORMAT)
                                            : undefined,
                                        onChange: handleEffectiveDateChange,
                                        disabled: isLoading,
                                        note: (
                                            <Fragment>
                                                Current policy period:&nbsp;
                                                <DateDisplay value={effectivePeriodStart} />
                                                &nbsp;-&nbsp;
                                                <DateDisplay value={effectivePeriodEnd} />
                                            </Fragment>
                                        ),
                                    }}
                                    title="Effective date"
                                    messages={fields.effectiveDate.messages}
                                    type={fields.effectiveDate.type}
                                    data-e2e="effective-date"
                                />
                            </Grid.Cell>
                        </Grid.Row>
                        {hasRenewalApplication && (
                            <Grid.Row>
                                <Grid.Cell width="1/1">
                                    <StatusMessage status="warning">
                                        The renewal application started prior to this change.
                                        Processing the request will result in the renewal
                                        application being reset.
                                    </StatusMessage>
                                </Grid.Cell>
                            </Grid.Row>
                        )}
                        <ESPEndorsementSignature
                            agreementToConductSignature={value.agreementToConductSignature}
                            onAgreementToConductSignatureChange={
                                handleAgreementToConductSignatureChange
                            }
                            agreementToConductSignatureMessages={
                                fields.agreementToConductSignature.messages
                            }
                            userData={userData}
                            isFormInvalid={isFormInvalid}
                            warrantyAndFraudSignature={value.warrantyAndFraudSignature}
                            onWarrantyAndFraudSignatureChange={
                                handleWarrantyAndFraudSignatureChange
                            }
                            warrantyAndFraudSignatureMessages={
                                fields.warrantyAndFraudSignature.messages
                            }
                        />
                        <Grid.Row>
                            <Grid.Cell>
                                <ButtonBar>
                                    {isHigherLimitEndorsement ? (
                                        <Button
                                            onClick={handleRequestHigherLimit}
                                            disabled={isLoading}
                                            data-e2e="request-higher-limit"
                                        >
                                            Request higher limit
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={handlePurchase}
                                            disabled={isLoading}
                                            data-e2e="purchase"
                                        >
                                            Purchase
                                        </Button>
                                    )}
                                    <TextButton
                                        disabled={isLoading}
                                        onClick={onClose}
                                        data-e2e="cancel"
                                    >
                                        Cancel
                                    </TextButton>
                                </ButtonBar>
                            </Grid.Cell>
                        </Grid.Row>
                    </Grid>
                </StackLayout>
            </Form>
        </BoxLayout>
    );
}
