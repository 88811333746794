import { Quote } from '@app/quote/entities/Quote';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPQuote } from '../entities/ESPQuote';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';
import { ESPQuoteOptions } from '../types/ESPQuoteOptions';

type ESPBundleFooterProps = {
    quote: Quote | undefined;
};

const generateHideSecurityScorecardFooterText = (options: ESPQuoteOptions) => {
    const selectedCoverages = [
        options?.cyber?.selected && options?.technology?.selected && 'Tech E&O/Cyber',
        options?.directorsAndOfficers?.selected && 'D&O',
        options?.employmentPracticesLiability?.selected && 'EPL',
        options?.fiduciary?.selected && 'Fiduciary',
    ].filter(Boolean);

    if (selectedCoverages.length > 1) {
        selectedCoverages[selectedCoverages.length - 1] =
            'and ' + selectedCoverages[selectedCoverages.length - 1];
    }

    return selectedCoverages.join('; ');
};

export function ESPBundleFooter(props: ESPBundleFooterProps) {
    const { quote } = props;
    const options = (quote as ESPQuote)?.options;
    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );

    if (!experimentationService.getFeatureValue('security-scorecard-credit-tech-vertical', false))
        return (
            <StackLayout gap="32">
                {generateHideSecurityScorecardFooterText(options)}: Underwritten by Everspan
                Indemnity Insurance Company Everspan Indemnity Insurance Company is currently rated
                AM Best A- (Excellent) with a Financial Size Category of VIII.
            </StackLayout>
        );

    return (
        <StackLayout gap="32">
            {options?.cyber?.selected && options?.technology?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        Tech E&O / Cyber: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
            {options?.directorsAndOfficers?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        D&O carrier: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
            {options?.employmentPracticesLiability?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        EPL carrier: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
            {options?.fiduciary?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        Fiduciary carrier: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
        </StackLayout>
    );
}
