import { Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface QuoteExpirationLabelProps {
    daysToExpire?: number;
}

export const quoteExpirationLabel = function (
    daysToExpire: QuoteExpirationLabelProps['daysToExpire'],
) {
    if (daysToExpire === undefined || daysToExpire === null) {
        return null;
    }

    return daysToExpire < 0
        ? 'Quote expired'
        : daysToExpire === 0
        ? 'Quote expires today'
        : daysToExpire === 1
        ? 'Quote expires in 1 day'
        : 'Quote expires in ' + daysToExpire + ' days';
};

export const QuoteExpirationLabel = function ({ daysToExpire }: QuoteExpirationLabelProps) {
    const label = quoteExpirationLabel(daysToExpire);

    if (!label) return null;

    return (
        <Text as="h5" style="heading 5" color="ui-400">
            {label}
        </Text>
    );
};
