import {
    Card,
    CardLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface PaymentEmptyHistoryPageProps {
    isBroker: boolean;
}

export function PaymentEmptyHistoryPage({ isBroker }: PaymentEmptyHistoryPageProps) {
    return (
        <StackLayout gap="20">
            {!isBroker && (
                <StatusMessage status="helptext">
                    This section displays the payments submitted to Embroker. Please note that the
                    payments submitted directly to carriers will not be listed here.
                </StatusMessage>
            )}
            <Card center>
                <Card.Header singleColumn />
                <Card.Body singleColumn>
                    <Text style="body 1">You haven’t made any payments yet.</Text>
                </Card.Body>
                <Card.Footer singleColumn />
            </Card>
            {!isBroker && (
                <CardLayout>
                    <CardLayout.Header>
                        <Text>
                            Don't see your invoice? If it was paid before 10/01/2018 we will not
                            have a record of it here.{' '}
                            <TextButton href="/support/contact">Contact us</TextButton> for the
                            record or for more information.
                        </Text>
                    </CardLayout.Header>
                </CardLayout>
            )}
        </StackLayout>
    );
}
