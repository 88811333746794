import { FormElement, PageElement } from '@embroker/service-app-engine';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Nullable } from '@embroker/shotwell/core/types';
import {
    ErrorLike,
    Failure,
    isErr,
    isOK,
    Success,
    AsyncResult,
    FailureResult,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    BoxLayout,
    FloatingLayout,
    StatusMessage,
    Text,
    useResponsive,
    useModal,
} from '@embroker/ui-toolkit/v2';
import {
    ErrorCode,
    ShareableApplicationNotSaved,
    InvalidQuestionnaire,
    ShareableApplicationNotCompleted,
    ShareableApplicationNotPromoted,
    OrganizationUpdateFailure,
    ApplicationUpdateFailure,
} from '../../../errors';
import React, { useMemo, useState } from 'react';
import { FilledQuestionnaireData } from '../../../../shopping/types/QuestionnaireData';
import { questionnaireToClientOrganizationData } from '../../../../shopping/useCases/UpdateApplicantFromBusinessProfile';
import { MissingInformationModal } from '../../../../shopping/view/components/MissingInformationModal';
import { FormEngine } from '../../../../view/components';
import { ProgressBarRangeType } from '../../../../view/components/FormEngine/components/ProgressBar';
import { useFormEngineEvent } from '../../../../view/components/FormEngine/hooks/useFormEngineEvent';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { CompleteShareableApplication } from '../../../useCases/CompleteShareableApplication';
import { PromoteShareableApplication } from '../../../useCases/PromoteShareableApplication';
import { UpdateClientApplication } from '../../../useCases/UpdateClientApplication';
import { UpdateClientOrganization } from '../../../useCases/UpdateClientOrganization';
import { getFormPagesInvalidIdList } from './clientApplicationEdit/EditApplication';
import { ExitConfirmationModal } from './ExitConfirmationModal';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';

interface ShareableApplicationFormProps {
    token: string;
    formEngine: FormElement;
    isInCreation: boolean;
    reloadApplication: () => void;
    pageId?: string;
    isRenewal?: boolean;
}

const PROGRESS_BAR_RANGE: ProgressBarRangeType = { min: 10 };
const guidanceTextBoxStyle = { maxWidth: '424px' };

export function ShareableApplicationForm({
    token,
    formEngine,
    isInCreation,
    reloadApplication,
    pageId,
    isRenewal,
}: ShareableApplicationFormProps): JSX.Element {
    const { navigate } = useNavigation();
    const missingInformationModal = useModal();
    const exitConfirmationModal = useModal();
    const [displayStatusMessage, setDisplayStatusMessage] = useState<boolean>(true);

    const saveProgress = async (): AsyncResult<
        void,
        InvalidQuestionnaire | ApplicationUpdateFailure
    > => {
        const questionnaireData = formEngine.getSnapshot().value;

        const questionnaireDataResult = JSONSerdes.serialize(questionnaireData);
        if (isErr(questionnaireDataResult)) {
            return Failure(InvalidQuestionnaire(questionnaireDataResult.errors));
        }

        const updateClientApplicationResponse = await execute(UpdateClientApplication, {
            token,
            questionnaireData: questionnaireDataResult.value,
        });
        if (isErr(updateClientApplicationResponse)) {
            return Failure(ApplicationUpdateFailure(updateClientApplicationResponse.errors));
        }

        return Success();
    };

    const completeApplication = async (): AsyncResult<void, ShareableApplicationNotCompleted> => {
        const completeApplicationResponse = await execute(CompleteShareableApplication, {
            token,
        });
        if (isErr(completeApplicationResponse)) {
            return Failure(ShareableApplicationNotCompleted(completeApplicationResponse.errors));
        }

        return Success();
    };

    const promoteApplication = async (): AsyncResult<
        void,
        InvalidQuestionnaire | ShareableApplicationNotPromoted
    > => {
        const questionnaireData = formEngine.getSnapshot().value;
        const questionnaireDataResult = JSONSerdes.serialize(questionnaireData);
        if (isErr(questionnaireDataResult)) {
            return Failure(InvalidQuestionnaire(questionnaireDataResult.errors));
        }

        const promoteApplicationResponse = await execute(PromoteShareableApplication, {
            token,
            questionnaireData: questionnaireData as unknown as FilledQuestionnaireData,
        });
        if (isErr(promoteApplicationResponse)) {
            return Failure(ShareableApplicationNotPromoted(promoteApplicationResponse.errors));
        }
        return Success();
    };

    const submitApplication = async (): AsyncResult<
        void,
        | InvalidQuestionnaire
        | ShareableApplicationNotPromoted
        | ShareableApplicationNotCompleted
        | OrganizationUpdateFailure
    > => {
        const questionnaireData = formEngine.getSnapshot().value;
        const updateClientOrganizationRequest = questionnaireToClientOrganizationData(
            token,
            questionnaireData as unknown as FilledQuestionnaireData,
        );

        const updateClientOrganizationResponse = await execute(
            UpdateClientOrganization,
            updateClientOrganizationRequest,
        );
        if (isErr(updateClientOrganizationResponse)) {
            return Failure(OrganizationUpdateFailure(updateClientOrganizationResponse.errors));
        }

        if (isInCreation) {
            return await promoteApplication();
        }
        return await completeApplication();
    };

    const onSuccess = isInCreation
        ? () => reloadApplication()
        : () =>
              navigate(
                  URI.build('/shareable/application/thank-you', {
                      isRenewal: isRenewal ?? false,
                  }),
              );

    const onConfirmExit = async () => {
        const progressSaved = await saveProgress();
        if (isOK(progressSaved)) {
            navigate(URI.build('/shareable/application/saved'));
        } else {
            handleShareableApplicationErrors(progressSaved);
        }
    };

    const handleShareableApplicationErrors = (
        result: FailureResult<
            | InvalidQuestionnaire
            | ShareableApplicationNotPromoted
            | ShareableApplicationNotCompleted
            | ShareableApplicationNotSaved
            | OrganizationUpdateFailure
            | ApplicationUpdateFailure
        >,
    ): void => {
        const defaultExceptionalErrorMsg = 'Unexpected shareable application error';
        const logger = container.get<Logger>(Log);

        if (result.errors.length === 0 || result.errors.length > 1) {
            logger.error(result.errors.length === 0 ? defaultExceptionalErrorMsg : result.errors);
            navigate(URI.build('/shareable/application/error'));
        }

        const err = result.errors[0];

        switch (err.code) {
            case ErrorCode.ShareableApplicationNotPromoted: {
                logger.error(err);
                navigate(URI.build('/shareable/application/info-error'));
                break;
            }
            case ErrorCode.ShareableApplicationNotSaved:
            case ErrorCode.ShareableApplicationNotCompleted:
            case ErrorCode.OrganizationUpdateFailure:
            case ErrorCode.ApplicationUpdateFailure:
            case ErrorCode.InvalidQuestionnaire: {
                logger.error(err);
                navigate(URI.build('/shareable/application/error'));
                break;
            }
        }
    };

    const [firstInvalidPageId, setFirstInvalidPageId] = useState<Nullable<string>>(null);

    const defaultPage = useMemo(() => {
        if (pageId) {
            return null;
        }
        const {
            machine: {
                state: { pageList },
            },
        } = formEngine;

        return pageList.filter((page: PageElement) => page.canNavigateTo())[0] ?? null;
    }, [formEngine, pageId]);

    const navigateAfterModalConfirmation = () => {
        if (!firstInvalidPageId) {
            return;
        }

        formEngine.gotoPage(firstInvalidPageId);
    };

    useFormEngineEvent(formEngine, 'onbeforesubmit', () => {
        const formPagesInvalidIdList = getFormPagesInvalidIdList(formEngine);
        if (formPagesInvalidIdList.length === 0) {
            return;
        }

        setFirstInvalidPageId(formPagesInvalidIdList[0]);
        missingInformationModal.show();
    });

    useFormEngineEvent(formEngine, 'submit', () => {
        async function submitHandler() {
            const progressSaved = await saveProgress();
            if (isErr(progressSaved)) {
                handleShareableApplicationErrors(progressSaved);
                return;
            }

            const applicationSubmitted = await submitApplication();

            if (isErr(applicationSubmitted)) {
                handleShareableApplicationErrors(applicationSubmitted);
                return;
            }
            onSuccess();
        }

        submitHandler().catch((error: ErrorLike) => {
            navigateToErrorPage(navigate, [error]);
        });
    });

    // Temporary fix until small screen design for sharing applications can be finalized
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });

    return (
        <div>
            <ExitConfirmationModal modal={exitConfirmationModal} onConfirm={onConfirmExit} />
            <MissingInformationModal
                modal={missingInformationModal}
                navigateAfterModalConformation={navigateAfterModalConfirmation}
            />
            <FormEngine
                instance={formEngine}
                navigation
                page={pageId || defaultPage}
                onExitFullScreen={exitConfirmationModal.show}
                progressBarRange={PROGRESS_BAR_RANGE}
            />
            {!isMobile && displayStatusMessage && (
                <FloatingLayout position="bottom">
                    <BoxLayout gap="56" style={guidanceTextBoxStyle}>
                        <StatusMessage
                            status="helptext"
                            onDismiss={() => {
                                setDisplayStatusMessage(false);
                            }}
                        >
                            {isRenewal ? (
                                <Text style="microcopy">
                                    Please review the pre-filled answers to questions asked in the
                                    application (and edit if anything needs to be changed), fill out
                                    the missing answers and finally click on 'Finish' on the last
                                    page of the questionnaire.
                                </Text>
                            ) : (
                                <Text style="microcopy">
                                    Please review the questions asked in the application, answer
                                    them to the best of your knowledge and finally click on 'Finish'
                                    on the last page of the questionnaire.
                                </Text>
                            )}
                        </StatusMessage>
                    </BoxLayout>
                </FloatingLayout>
            )}
        </div>
    );
}
