import { ColumnLayout, PageLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useCurrentRoute } from 'react-navi';

export interface ClaimsLandingPageProps {
    children: React.ReactNode;
    withSubNavigation?: boolean;
}

export function ClaimsLandingPage({ children }: ClaimsLandingPageProps) {
    const route = useCurrentRoute();
    return (
        <PageLayout.Section>
            <StackLayout gap="32">
                <ColumnLayout gap="16">
                    <StackLayout>
                        <Text data-e2e="claims-page-header" as="span" style="heading 2">
                            {route.title}
                        </Text>
                        <Text>Need to file a new claim? Get started below.</Text>
                    </StackLayout>
                </ColumnLayout>
                <hr></hr>
                {children}
            </StackLayout>
        </PageLayout.Section>
    );
}
