import React, { useEffect } from 'react';
import {
    RiskProfileIneligibilityModal as RiskProfileIneligibilityModalView,
    RiskProfileIneligibilityModalProps as RiskProfileIneligibilityModalViewProps,
} from './RiskProfileIneligibilityModal.view';
import { PublishRiskProfileIneligibilityModalShowEvent } from '@app/public/useCases/PublishRiskProfileIneligibilityModalShowEvent';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishRiskProfileIneligibilityModalActionClickEvent } from '@app/public/useCases/PublishRiskProfileIneligibilityModalActionClickEvent';

export type RiskProfileIneligibilityModalProps = Omit<
    RiskProfileIneligibilityModalViewProps,
    'onActionClick'
>;

export function RiskProfileIneligibilityModal(props: RiskProfileIneligibilityModalProps) {
    useEffect(() => {
        if (props.modal.visible) {
            execute(PublishRiskProfileIneligibilityModalShowEvent);
        }
    }, [props.modal]);

    const handleActionClick = () => {
        execute(PublishRiskProfileIneligibilityModalActionClickEvent);
        // async delay to allow the above event to get registered on the dataLayer before user is kicked out of app
        setTimeout(() => {
            window.location.href = 'https://www.embroker.com/coverage';
        }, 1);
    };

    return <RiskProfileIneligibilityModalView {...props} onActionClick={handleActionClick} />;
}
