import { State } from '@embroker/shotwell/core/types/StateList';
import { CheckBox, Hyperlink, Modal, Text, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AgreementToConductElectronicTransactionsModal } from '@app/view/components/modals/AgreementToConductElectronicTransactionsModal';
import { StateDisclosureModal } from '@app/view/components/modals/StateDisclosureModal';
import { WarrantyAndFraudModal } from './WarantyAndFraudModal';

export interface SignatureFieldProps {
    readOnly: boolean;
    fullName: string;
    titleValue: string;
    companyName: string;
    state: State;
    onChange: any;
    value: ('transfers' | 'warranty')[];
}

const CompensationDisclosureURL = 'https://www.embroker.com/disclosure/';

export const UsaRepresentationStates = ['AK', 'FL', 'GA', 'KS', 'KY', 'ME', 'NE', 'NH', 'NC', 'OK'];

export const SignatureField = React.forwardRef(function SignatureField(
    {
        readOnly,
        fullName,
        titleValue,
        state,
        companyName,
        onChange,
        value = [],
    }: SignatureFieldProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const warrantyAndFraudModal = useModal();
    const agreementModal = useModal();
    const stateDisclosureModal = useModal();

    const handleChange =
        (checkbox: 'transfers' | 'warranty') =>
        ({ target: { checked } }: any) => {
            const newValue = [...value];
            if (checked) {
                newValue.push(checkbox);
            } else {
                const index = value.indexOf(checkbox);
                newValue.splice(index, 1);
            }
            onChange(newValue);
        };

    return (
        <div ref={ref} tabIndex={-1}>
            <CheckBox
                data-e2e="agreement-to-conduct-signature"
                readOnly={readOnly}
                checked={value.includes('transfers')}
                onChange={handleChange('transfers')}
            >
                <Text as="span" style="body 2">
                    By checking this box, you are electronically signing an insurance application
                    and confirm that you have read and consent to our{' '}
                    <Hyperlink onClick={agreementModal.show}>
                        Agreement to Conduct Electronic Transactions
                    </Hyperlink>
                    {', '}
                    <Hyperlink href={CompensationDisclosureURL} target="_blank">
                        Compensation Disclosure
                    </Hyperlink>
                    {' and '}
                    <Hyperlink onClick={stateDisclosureModal.show}>
                        Surplus Lines Disclosure
                    </Hyperlink>
                    .
                </Text>
                <AgreementToConductElectronicTransactionsModal modal={agreementModal} />
                <StateDisclosureModal
                    modal={stateDisclosureModal}
                    companyName={companyName}
                    fullName={fullName}
                    state={state}
                />
            </CheckBox>
            <br />
            <br />
            <CheckBox
                data-e2e="warranty-and-fraud-signature"
                readOnly={readOnly}
                checked={value.includes('warranty')}
                onChange={handleChange('warranty')}
            >
                <Text as="span" style="body 2">
                    By checking this box, you certify all information provided in the insurance
                    application is true and correct and acknowledge that you have read and agree to
                    the{' '}
                    <Hyperlink onClick={warrantyAndFraudModal.show}>
                        {UsaRepresentationStates.includes(state)
                            ? 'Representation/Statement of Facts and Fraud Prevention/Warning'
                            : 'Warranty/Statement of Facts and Fraud Prevention/Warning'}
                    </Hyperlink>
                    .
                </Text>
                <Modal {...warrantyAndFraudModal} size="large">
                    <WarrantyAndFraudModal fullName={fullName} title={titleValue} state={state} />
                </Modal>
            </CheckBox>
            <br />
            <br />
            <hr />
            <br />
        </div>
    );
});
