import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetActiveOrganizationProfile } from './GetActiveOrganizationProfile';
import { GetQuestionerForm } from '@app/shoppingQuestioner/useCases/GetQuestionerForm';
import {
    AnswerSourceType,
    getInitialValue,
    QuestionerQuestion,
} from '@app/shoppingQuestioner/types/QuestionerQuestionType';

export type OnboardingPrefillData = {
    value: unknown;
    key: string;
    source?: AnswerSourceType;
    id?: string;
};
export type GetOnboardingQuestionnaireDataFromOPTIMALResponse = OnboardingPrefillData[];

export interface GetOnboardingQuestionnaireDataFromOPTIMAL extends UseCase {
    execute(): AsyncResult<GetOnboardingQuestionnaireDataFromOPTIMALResponse, never>;
}

@injectable()
class GetOnboardingQuestionnaireDataFromOPTIMALUseCase
    extends UseCase
    implements GetOnboardingQuestionnaireDataFromOPTIMAL
{
    public static type = Symbol('Global/GetOnboardingQuestionnaireDataFromOPTIMAL');

    /**
     * constructor for the GetOnboardingQuestionnaireDataFromOPTIMAL use case
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetActiveOrganizationProfile.type)
        private getActiveOrganizationProfile: GetActiveOrganizationProfile,
        @inject(GetQuestionerForm.type) private getQuestionerForm: GetQuestionerForm,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<GetOnboardingQuestionnaireDataFromOPTIMALResponse, never> {
        const getActiveOrganizationProfileResponse =
            await this.getActiveOrganizationProfile.execute();

        if (isErr(getActiveOrganizationProfileResponse)) {
            return Success<GetOnboardingQuestionnaireDataFromOPTIMALResponse>([]);
        }

        const onboardingQuestionnaireResp = await this.getQuestionerForm.execute({
            questionnaireIds: ['onboarding_1'],
        });

        if (!isOK(onboardingQuestionnaireResp)) {
            return Success<GetOnboardingQuestionnaireDataFromOPTIMALResponse>([]);
        }

        const prefillData = this.getOnboardingQuestionnaireData(
            onboardingQuestionnaireResp.value.questionerQuestions as QuestionerQuestion[],
        );

        return Success<GetOnboardingQuestionnaireDataFromOPTIMALResponse>(prefillData);
    }

    private getOnboardingQuestionnaireData(
        questions: QuestionerQuestion[],
    ): OnboardingPrefillData[] {
        return questions.reduce((acc, question) => {
            const value = getInitialValue(question);
            acc.push({
                key: question.key,
                value,
                source: question.current_answer?.source.type,
                id: question.current_answer?.source.id,
            });
            return acc;
        }, [] as OnboardingPrefillData[]);
    }
}

export const GetOnboardingQuestionnaireDataFromOPTIMAL: UseCaseClass<GetOnboardingQuestionnaireDataFromOPTIMAL> =
    GetOnboardingQuestionnaireDataFromOPTIMALUseCase;
