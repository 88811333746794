import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { RiskProfileReportBackToEmbrokerClick } from '@app/public/entities/events';

export interface PublishRiskProfileReportBackToEmbrokerClickEvent extends UseCase {
    execute(): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishRiskProfileReportBackToEmbrokerClickUseCase
    extends UseCase
    implements PublishRiskProfileReportBackToEmbrokerClickEvent
{
    public static type = Symbol('RiskProfile/PublishRiskProfileReportBackToEmbrokerClickEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(): AsyncResult<void, InvalidArgument | OperationFailed> {
        const backToEmbrokerClick: RiskProfileReportBackToEmbrokerClick = {
            origin: 'RiskProfile',
            name: 'BackToEmbrokerClick',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };

        await this.eventBus.publish(backToEmbrokerClick);

        return Success();
    }
}

export const PublishRiskProfileReportBackToEmbrokerClickEvent: UseCaseClass<PublishRiskProfileReportBackToEmbrokerClickEvent> =
    PublishRiskProfileReportBackToEmbrokerClickUseCase;
