import { Money } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { execute } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { getYear, getMonth } from 'date-fns';
import { GetActiveOrganizationProfileResponse } from '../useCases/GetActiveOrganizationProfile';
import { RemoveUserOnboardingQuestionnaireData } from '../useCases/RemoveUserOnboardingQuestionnaireData';
import { UpdateOrganizationProfileRequest } from '../useCases/UpdateOrganizationProfile';
import { UpdateUserOnboardingQuestionnaireData } from '../useCases/UpdateUserOnboardingQuestionnaireData';
import { locationFieldValidator } from '../view/components/LocationFieldSet.view';
import { WorkspaceOwnership } from './enums';
import { MailingAddress } from './MailingAddress';
import { ZipCodeAddress } from './ZipCodeAddress';

function removeUndefinedAttributes<T extends object>(obj: T): Partial<T> {
    const result: Partial<T> = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
            result[key] = obj[key];
        }
    }
    return result;
}

export interface OnboardingPrefillQuestionnaireData {
    tech_consulting_services?: boolean;
    main_business_location?: MailingAddress;
    onb_naics_code?: string;
    refined_naics_code?: string;
    dnb_naics_code?: string;
    working_space_type?: WorkspaceOwnership;
    has_employees?: boolean;
    uses_employee_or_rental_vehicles?: boolean;
    total_revenue?: Money;
    onb_raised_funding?: boolean;
    total_number_employees?: number;
    onb_area_of_focus?: string;
    zip_code?: ZipCodeAddress;
    provides_tech_service_for_fee?: boolean;
}
export type OnboardingPrefillQuestionnaireKeys = keyof OnboardingPrefillQuestionnaireData;

export const getCurrentYear = () => {
    const isDecember = getMonth(Date.now()) === 11;
    const year = isDecember
        ? Year.create(getYear(Date.now()) + 1)
        : Year.create(getYear(Date.now()));
    if (isOK(year)) {
        return year.value;
    }
};

export const getOrganizationProfileFromQuestionnaireData = (
    questionnaireData: OnboardingPrefillQuestionnaireData,
    organizationId: UUID,
): UpdateOrganizationProfileRequest | undefined => {
    const revenue = Revenue.create({
        fiscalYear: getCurrentYear() as Year,
        grossTotal: questionnaireData.total_revenue as Money,
    });
    const organizationProfile = removeUndefinedAttributes({
        headquarters: questionnaireData.main_business_location,
        naics: questionnaireData.onb_naics_code,
        raisedFunding: questionnaireData.onb_raised_funding,
        techAreaOfFocus: questionnaireData.onb_area_of_focus,
        totalNumberOfEmployees: questionnaireData.total_number_employees,
        revenues: isOK(revenue) ? [revenue.value] : undefined,
        workspaceOwnership: questionnaireData.working_space_type,
        hasAutomobiles: questionnaireData.uses_employee_or_rental_vehicles,
        hasEmployees: questionnaireData.has_employees,
        providesTechServiceForFee: questionnaireData.provides_tech_service_for_fee,
    });

    if (Object.keys(organizationProfile).length === 0) {
        return;
    }

    return {
        id: organizationId,
        ...organizationProfile,
    };
};

export const getOnboardingQuestionnaireDataFromUserProfile = (
    organization: GetActiveOrganizationProfileResponse['organization'],
): OnboardingPrefillQuestionnaireData => {
    const currentYearRevenue = organization.revenues?.find(
        (revenue) => revenue.fiscalYear === getCurrentYear(),
    );
    // organization.headquarters will be an empty object if there is no data, so check if any of the values are truthy
    const headquarters = Object.values(organization.headquarters).some(Boolean)
        ? organization.headquarters
        : undefined;
    return {
        main_business_location: headquarters,
        onb_naics_code: organization.naics || undefined,
        has_employees: organization.hasEmployees,
        onb_raised_funding:
            organization.raisedFunding === null ? undefined : Boolean(organization.raisedFunding),
        onb_area_of_focus: organization.techAreaOfFocus || undefined,
        total_revenue: currentYearRevenue?.grossTotal || undefined,
        total_number_employees: organization.totalNumberOfEmployees
            ? Number(organization.totalNumberOfEmployees)
            : undefined,
        working_space_type: organization.workspaceOwnership,
        uses_employee_or_rental_vehicles: organization.hasAutomobiles,
        provides_tech_service_for_fee:
            organization.providesTechServiceForFee === null
                ? undefined
                : organization.providesTechServiceForFee,
    };
};

export const OnboardingPrefillQuestionnaireData = {
    ...defineValidator<OnboardingPrefillQuestionnaireData>({
        tech_consulting_services: Joi.boolean().optional(),
        main_business_location: locationFieldValidator,
    }),
    create(userOnboardingDetails: OnboardingPrefillQuestionnaireData) {
        return OnboardingPrefillQuestionnaireData.validate(userOnboardingDetails);
    },
    update(onboardingPrefillQuestionnaireData: OnboardingPrefillQuestionnaireData): void {
        execute(UpdateUserOnboardingQuestionnaireData, onboardingPrefillQuestionnaireData);
    },
    clear(): void {
        execute(RemoveUserOnboardingQuestionnaireData);
    },
};
