import {
    Button,
    DetailsSidebar,
    Hyperlink,
    List,
    StackLayout,
    Text,
    ColumnLayout,
    Icon,
} from '@embroker/ui-toolkit/v2';
import React, { Fragment, useContext } from 'react';
import { AppContext } from '../../../view/AppContext';
import { Coverage } from '../../types/Coverage';
import { AppTypeCode } from '../../types/enums';
import { getCoverageDetails } from './coverageDetails';
import { getCoverageDetailsContent } from './coverageContentDetails';
import { CoverageIcon } from '@app/policy/view/components/CoverageIcon.view';

interface LearnMoreSlideoutProps {
    /**
     * Insurance coverage application code for which "learn more" provides description
     */
    appTypeCode: AppTypeCode;
    /**
     * Applicant's naics code to which coverage is being applied for
     */
    naicsCode?: string;
    /**
     * Indicator if coverage is selected. Affects "select" button text
     */
    isSelected?: boolean;
    /**
     * Callback when coverage is selected/unselected
     */
    onCoverageSelect?: (code: AppTypeCode, isSelected: boolean) => void;
    /**
     * Slideout variants. The standard version is set by default.
     */
    variant?: 'standard' | 'new';
    /**
     * Use this Action text to display on the CTA
     */
    actionText?: string;
}

export function LearnMoreSlideout({
    appTypeCode,
    naicsCode,
    isSelected = false,
    onCoverageSelect,
    variant = 'standard',
    actionText = 'Get a Quote',
}: LearnMoreSlideoutProps) {
    Coverage;
    const { closeSlideout } = useContext(AppContext);

    // Favour new content registry, we will be deprecating `getCoverageDetails`.
    const { header, body } = getCoverageDetailsContent(appTypeCode);

    // TODO: DEPRECIATE getCoverageDetails
    const {
        sliderTitle,
        sliderDescription,
        sliderWhoNeedsTitle,
        sliderWhoNeedsDescription,
        sliderWhatIsTitle,
        sliderWhatIsDescription,
        sliderWhyNeedItTitle,
        sliderWhyNeedItDescription,
        coverages,
        coveragesTitle,
        nonIncludedCoverages,
    } = getCoverageDetails(appTypeCode, naicsCode);

    return (
        <DetailsSidebar>
            <DetailsSidebar.Header onDismiss={closeSlideout}>
                <StackLayout>
                    <div>
                        <CoverageIcon appTypeCode={appTypeCode} />
                    </div>
                    {header ? (
                        <StackLayout gap="24">
                            <div>{header}</div>
                            {onCoverageSelect && (
                                <Button
                                    appearance="secondary"
                                    size="large"
                                    onClick={() => {
                                        onCoverageSelect(appTypeCode, !isSelected);
                                    }}
                                >
                                    {isSelected ? 'Remove' : actionText}
                                </Button>
                            )}
                        </StackLayout>
                    ) : (
                        <Fragment>
                            <Text style="heading 3">{sliderTitle}</Text>
                            {variant === 'standard' && (
                                <StackLayout gap="24">
                                    <Text>{sliderDescription}</Text>
                                    {onCoverageSelect && (
                                        <Button
                                            appearance="secondary"
                                            size="large"
                                            onClick={() => {
                                                onCoverageSelect(appTypeCode, !isSelected);
                                            }}
                                        >
                                            {isSelected ? 'Remove' : actionText}
                                        </Button>
                                    )}
                                </StackLayout>
                            )}
                        </Fragment>
                    )}
                </StackLayout>
            </DetailsSidebar.Header>
            <DetailsSidebar.Body gap="32">
                {body ? (
                    body
                ) : (
                    <Fragment>
                        {sliderWhatIsDescription && (
                            <StackLayout gap="12">
                                <Text style="heading 4">{sliderWhatIsTitle}</Text>
                                <Text style="body 1" whiteSpace>
                                    {sliderWhatIsDescription}
                                </Text>
                            </StackLayout>
                        )}
                        {coverages.length > 0 && (
                            <List title={coveragesTitle || 'This includes coverage for:'}>
                                {coverages.map((coverage, index) => (
                                    <List.Item key={index}>
                                        <Text style="body 1">{coverage}</Text>
                                    </List.Item>
                                ))}
                            </List>
                        )}
                        {nonIncludedCoverages.length > 0 && (
                            <List title={'This doesn’t includes coverage for:'}>
                                {nonIncludedCoverages.map((coverage) => (
                                    <List.Item icon="status-error" iconColor="red" key={coverage}>
                                        <Text style="body 1">{coverage}</Text>
                                    </List.Item>
                                ))}
                            </List>
                        )}
                        {sliderWhoNeedsDescription && (
                            <React.Fragment>
                                <Text style="heading 4">{sliderWhoNeedsTitle}</Text>
                                <Text style="body 1">{sliderWhoNeedsDescription}</Text>
                            </React.Fragment>
                        )}
                        {sliderWhyNeedItDescription ? (
                            <StackLayout gap="24">
                                <Text style="heading 4">{sliderWhyNeedItTitle}</Text>
                                <Text>{sliderWhyNeedItDescription}</Text>
                            </StackLayout>
                        ) : null}
                    </Fragment>
                )}

                <StackLayout gap="24">
                    <Text style="heading 4"> Why get it with Embroker?</Text>
                    <ColumnLayout key="content" top>
                        <StackLayout center className="u-1/3">
                            <Icon name="star" />
                            <Text textAlign="center">Excellent or Better Rating by AM Best</Text>
                        </StackLayout>
                        <StackLayout center className="u-1/3">
                            <Icon name="chat" />
                            <Text textAlign="center">Expert Insurance Support</Text>
                        </StackLayout>
                        <StackLayout center className="u-1/3">
                            <Icon name="certificate" />
                            <Text textAlign="center">Online Certificate of Insurance</Text>
                        </StackLayout>
                    </ColumnLayout>
                </StackLayout>

                <StackLayout gap="12">
                    <Text style="microcopy">
                        A.M. Best ratings are believed to be current, however they are subject to
                        change and subject to{' '}
                        <Hyperlink href="http://www.embroker.com/terms" target="_blank">
                            Embroker's terms
                        </Hyperlink>
                        .
                    </Text>
                    <Text style="microcopy">
                        The above summaries are not guarantees of coverage or a quote. Coverage is
                        subject to the terms, conditions and exclusions of issued policies only.
                    </Text>
                </StackLayout>
            </DetailsSidebar.Body>
        </DetailsSidebar>
    );
}
