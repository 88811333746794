import { isDateAfter, isDateBefore } from '@embroker/service-app-engine';
import { Data, Immutable, isNumber, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    Tooltip,
    useModal,
} from '@embroker/ui-toolkit/v2';
import { addDays } from 'date-fns';
import React, { useContext } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { getMinimumEpliRetention } from '../../../../esp/view/components/ESPCoverage/limitRetentionOptions';
import { ESPDoDifferenceModal } from '../../../../esp/view/components/ESPCoveragesPage/ESPDoDifferenceModal';
import { ESPEplDifferenceModal } from '../../../../esp/view/components/ESPCoveragesPage/ESPEplDifferenceModal';
import {
    fiduciaryQuoteDocumentsPublishDetails,
    renewalQuoteDocumentsPublishDetails,
} from '../../../../espUtils/quoteDocumentUtils';
import { Coverage } from '../../../types/Coverage';
import {
    CoverageRestriction,
    ShoppingCoverageCodeListCyberSplit,
} from '../../../types/CoverageRestriction';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import {
    espRenewalCoverageDetailsMap,
    getCyberLimitRetentionOptions,
    getLimitRetentionOptions,
    getRestrictedLimitRetentionOptions,
    Option,
} from '../../config';
import { ESPRenewalQuoteOptionsFormData } from '../ESPRenewalQuoteLandingPage';
import { ESPEoCyberDifferenceModal } from './ESPEoCyberDifferenceModal';
import {
    coverageIsSelectedField,
    coverageLevelField,
    coverageLimitField,
    coverageRetentionField,
    QuestionnaireData,
} from './ESPRenewalCoveragePage';
import { AppContext } from '../../../../../view/AppContext';

function getLimitValue(limit: number): Money {
    return Money.tryFromFloat(limit);
}

function getRetentionValue(retention: number, coverageRestriction?: CoverageRestriction): Money {
    const retentionMoney = Money.tryFromFloat(retention);

    if (!coverageRestriction) {
        return retentionMoney;
    }

    return Money.isLessThan(retentionMoney, coverageRestriction.minRetention)
        ? coverageRestriction.minRetention
        : retentionMoney;
}

function getLevel(
    value?: string,
    coverageRestriction?: CoverageRestriction,
): { value?: string; values: { value: string; title: string }[] } {
    if (coverageRestriction && !coverageRestriction.allowPlus) {
        return {
            value: 'standard',
            values: [
                {
                    value: 'standard',
                    title: 'Standard',
                },
            ],
        };
    }

    return {
        value: value,
        values: [
            {
                value: 'plus',
                title: 'Plus',
            },
            {
                value: 'standard',
                title: 'Standard',
            },
        ],
    };
}

function isHigherLimitRequested(higherLimitRequest: number | undefined): boolean {
    return (higherLimitRequest ?? 0) > 0;
}

interface ESPRenewalSelectedCoverageProps {
    coverage: Coverage;
    revenue: Money;
    isSubmitting: boolean;
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'];
    answers?: QuestionnaireData;
    coverageRestriction?: CoverageRestriction;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    isQuoteReferred: boolean;
    restrictions?: Immutable<InsuranceApplicationRestriction>;
    higherLimitRequest?: number;
    effectiveDate?: Date;
    showEpliStandaloneInCalifornia?: () => void;
}

export const ESPRenewalSelectedCoverage = ({
    coverage,
    revenue,
    fields,
    isSubmitting,
    answers,
    coverageRestriction,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    state,
    isQuoteReferred,
    restrictions,
    higherLimitRequest,
    effectiveDate,
    showEpliStandaloneInCalifornia,
}: ESPRenewalSelectedCoverageProps) => {
    const limitField = coverageLimitField(coverage.type, fields);
    const retentionField = coverageRetentionField(coverage.type, fields);
    const levelField = coverage.level && coverageLevelField(coverage.type, fields);
    const isSelectedField = coverageIsSelectedField(coverage.type, fields);
    const doModal = useModal();
    const epliModal = useModal();
    const eoCyberModal = useModal();
    const modals: Data<ModalState & ModalActions> = {
        dno: doModal,
        epli: epliModal,
        eo: eoCyberModal,
        techCyber: eoCyberModal,
    };

    const { globalConfig } = useContext(AppContext);

    if (!limitField || !retentionField) {
        return null;
    }

    function onDismissUpdate() {
        isSelectedField?.props?.onChange?.({
            target: {
                value: false,
            },
        });

        showEPLIStandaloneModal();
    }

    function filterRetentions(
        retentions: Option[],
        answers?: QuestionnaireData,
        coverageRestriction?: CoverageRestriction,
    ) {
        const minimumEpliRetention = getMinimumEpliRetention(answers, coverageRestriction);
        return retentions.filter((option) => option.value >= minimumEpliRetention);
    }

    const getUrlBasedOnSubmittedDate = (
        coverageType: 'dno' | 'epli' | 'eo' | 'fiduciary' | 'techCyber',
        newInsurerDocumentsReleaseDate?: Date,
        fiduciaryDocumentsReleaseDate?: Date,
        submittedAt?: Date,
        state?: Nullable<State>,
        effectiveDate?: Date,
    ): string => {
        const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
        const releaseDate = globalConfig?.espIptChangesReleaseDate;
        if (
            espIptChangesEnabled &&
            releaseDate &&
            effectiveDate &&
            !isDateBefore(effectiveDate, releaseDate)
        ) {
            switch (coverageType) {
                case 'dno':
                case 'epli':
                case 'techCyber':
                    return espRenewalCoverageDetailsMap[coverageType].document[
                        coverage.level == 'standard'
                            ? 'post_ipt_2024_standard'
                            : 'post_ipt_2024_plus'
                    ];
            }
        }

        if (coverageType == 'fiduciary') {
            if (fiduciaryDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
                if (
                    isSubmissionDateAfterPublishDate(
                        submittedAt,
                        fiduciaryDocumentsReleaseDate,
                        state,
                    )
                ) {
                    return espRenewalCoverageDetailsMap['fiduciary'].document.new_standard;
                }
            }
        }

        if (coverageType == 'dno' || coverageType == 'epli') {
            if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
                if (
                    isSubmissionDateAfterPublishDate(
                        submittedAt,
                        newInsurerDocumentsReleaseDate,
                        state,
                    )
                ) {
                    return espRenewalCoverageDetailsMap[coverageType].document[
                        coverage.level == 'standard' ? 'new_standard' : 'new_plus'
                    ];
                }
            }
        }

        return coverageType != 'fiduciary'
            ? espRenewalCoverageDetailsMap[coverageType].document[coverage.level ?? 'old']
            : espRenewalCoverageDetailsMap['fiduciary'].document.standard;
    };

    const isSubmissionDateAfterPublishDate = (
        submittedAt: Date,
        newInsurerDocumentsReleaseDate: Date,
        state?: Nullable<State>,
    ): boolean => {
        let daysToPublishByState = 0;

        if (state !== undefined && state !== null) {
            daysToPublishByState =
                coverage.type == 'fiduciary'
                    ? fiduciaryQuoteDocumentsPublishDetails[state].daysToPublish
                    : renewalQuoteDocumentsPublishDetails[state].daysToPublish;
        }

        return isDateAfter(
            submittedAt,
            addDays(newInsurerDocumentsReleaseDate, daysToPublishByState),
        );
    };

    if (globalConfig === undefined) {
        return null;
    }

    const areManualRestrictionsApplied = restrictions?.areManualRestrictionsApplied ?? false;
    const coverageRestrictionForCalculation = areManualRestrictionsApplied
        ? coverageRestriction
        : undefined;

    const showEPLIStandaloneModal = () => {
        if (
            globalConfig?.espIptChangesEnabled &&
            isEpliStandalone(fields, coverage.type, state) &&
            showEpliStandaloneInCalifornia
        ) {
            showEpliStandaloneInCalifornia();
        }
    };

    if (coverage.type == 'techCyber') {
        const cyberRestriction = restrictions?.coverageRestrictions.find(
            (cr) => cr.coverageType === ShoppingCoverageCodeListCyberSplit,
        );
        const cyberRestrictionForCalculation = areManualRestrictionsApplied
            ? cyberRestriction
            : undefined;

        return (
            <Card
                onDismiss={() => {
                    fields.isCyberSelected.props.onChange({
                        target: {
                            value: false,
                        },
                    });

                    fields.isTechSelected.props.onChange({
                        target: {
                            value: false,
                        },
                    });

                    showEPLIStandaloneModal();
                }}
                menuItems={[
                    <TextButton
                        target="_blank"
                        key={'download' + coverage.type + 'Coverage'}
                        href={getUrlBasedOnSubmittedDate(
                            coverage.type,
                            newInsurerDocumentsReleaseDate,
                            fiduciaryDocumentsReleaseDate,
                            submittedAt,
                            state,
                            effectiveDate,
                        )}
                    >
                        Download coverage details
                    </TextButton>,
                ]}
            >
                <Card.Header>
                    <Icon name={espRenewalCoverageDetailsMap[coverage.type].icon} />
                    <Text style="heading 5">
                        {espRenewalCoverageDetailsMap[coverage.type].title}
                    </Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>{espRenewalCoverageDetailsMap[coverage.type].text}</Text>
                        <Text style="heading 5">
                            Tech E&O
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text={
                                    levelField?.props.value === 'plus'
                                        ? 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1, 2, 3, and 4 on the "PLUS" form.'
                                        : 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1a, 1b, and 2a on the "Standard" form.'
                                }
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.techLimit.type}
                                inputProps={{
                                    ...fields.techLimit.props,
                                    value:
                                        fields.techLimit.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getLimitValue(fields.techLimit.props.value),
                                              ),
                                    filterable: false,
                                    items: (isHigherLimitRequested(higherLimitRequest)
                                        ? getRestrictedLimitRetentionOptions(
                                              coverage.type,
                                              coverage.previousLimit,
                                              state,
                                              coverageRestrictionForCalculation,
                                              revenue,
                                              true,
                                          )
                                        : getLimitRetentionOptions(
                                              coverage.type,
                                              coverage.previousLimit,
                                              state,
                                              revenue,
                                              coverageRestrictionForCalculation,
                                          )
                                    ).limits,
                                    label: 'Limit',
                                    disabled:
                                        getRestrictedLimitRetentionOptions(
                                            coverage.type,
                                            coverage.previousLimit,
                                            state,
                                            coverageRestrictionForCalculation,
                                            revenue,
                                        ).limits.length == 1 || isSubmitting,
                                    onChange: (e: { target: { value: number } }) => {
                                        fields.techLimit.props.onChange(e);
                                        if (
                                            fields.cyberLimit.props.value &&
                                            e.target.value &&
                                            isNumber(e.target.value)
                                        ) {
                                            if (fields.cyberLimit.props.value > e.target.value) {
                                                fields.cyberLimit.props.onChange(e);
                                            }
                                        }
                                    },
                                }}
                            />
                            <Form.Field
                                type={fields.techRetention.type}
                                inputProps={{
                                    ...fields.techRetention.props,
                                    value:
                                        fields.techRetention.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getRetentionValue(
                                                      fields.techRetention.props.value,
                                                      coverageRestrictionForCalculation,
                                                  ),
                                              ),
                                    filterable: false,
                                    items: filterRetentions(
                                        getRestrictedLimitRetentionOptions(
                                            coverage.type,
                                            coverage.previousLimit,
                                            state,
                                            coverageRestrictionForCalculation,
                                            revenue,
                                        ).retentions,
                                        answers,
                                        coverageRestrictionForCalculation,
                                    ),
                                    label: 'Retention',
                                    disabled:
                                        getRestrictedLimitRetentionOptions(
                                            coverage.type,
                                            coverage.previousLimit,
                                            state,
                                            coverageRestrictionForCalculation,
                                            revenue,
                                        ).retentions.length == 1 || isSubmitting,
                                    onChange: (e: { target: { value: number } }) => {
                                        fields.techRetention.props.onChange(e);
                                        fields.cyberRetention.props.onChange(e);
                                    },
                                }}
                            />
                        </ColumnLayout>
                        <ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Limit:</Text>
                                    <Text style="body 1">
                                        <MoneyDisplay
                                            value={coverage.previousLimit}
                                            fractionDigits={0}
                                        />
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Retention</Text>
                                    <Text style="body 1">
                                        <MoneyDisplay
                                            value={coverage.previousRetention}
                                            fractionDigits={0}
                                        />
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                        </ColumnLayout>
                        <Text style="heading 5">
                            1st Party Expenses
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text="These cover 1st party expenses after a network security event. These limits are rarely contractually required, so you’re unlikely to need higher coverage limits — plus, lowering the limit is a good way to reduce your premium!"
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.cyberLimit.type}
                                inputProps={{
                                    ...fields.cyberLimit.props,
                                    value:
                                        fields.cyberLimit.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getLimitValue(fields.cyberLimit.props.value),
                                              ),
                                    filterable: false,
                                    items: (isHigherLimitRequested(higherLimitRequest)
                                        ? getRestrictedLimitRetentionOptions(
                                              coverage.type,
                                              coverage.previousLimit,
                                              state,
                                              cyberRestrictionForCalculation,
                                              revenue,
                                              true,
                                          )
                                        : cyberRestrictionForCalculation
                                        ? getLimitRetentionOptions(
                                              coverage.type,
                                              coverage.previousLimit,
                                              state,
                                              revenue,
                                              cyberRestrictionForCalculation,
                                          )
                                        : getCyberLimitRetentionOptions(revenue)
                                    ).limits.filter(
                                        (item) =>
                                            fields.techLimit.props.value !== undefined &&
                                            item.value <= fields.techLimit.props.value,
                                    ),
                                    label: 'Limit',
                                    disabled:
                                        getRestrictedLimitRetentionOptions(
                                            coverage.type,
                                            coverage.previousLimit,
                                            state,
                                            cyberRestrictionForCalculation,
                                            revenue,
                                        ).limits.length == 1 || isSubmitting,
                                }}
                            />
                            <Form.Field
                                type={fields.cyberRetention.type}
                                inputProps={{
                                    ...fields.techRetention.props,
                                    value:
                                        fields.techRetention.props.value === undefined
                                            ? undefined
                                            : Money.toFloat(
                                                  getRetentionValue(
                                                      fields.techRetention.props.value,
                                                      cyberRestrictionForCalculation,
                                                  ),
                                              ),
                                    filterable: false,
                                    items: getRestrictedLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousLimit,
                                        state,
                                        cyberRestrictionForCalculation,
                                        revenue,
                                    ).retentions,
                                    label: 'Retention',
                                    readOnly: true,
                                }}
                            />
                        </ColumnLayout>
                        <ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Limit:</Text>
                                    <Text style="body 1">
                                        <MoneyDisplay
                                            value={coverage.previousSecondLimit ?? USD(0)}
                                            fractionDigits={0}
                                        />
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Retention</Text>
                                    <Text style="body 1">
                                        <MoneyDisplay
                                            value={coverage.previousSecondRetention ?? USD(0)}
                                            fractionDigits={0}
                                        />
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                        </ColumnLayout>
                        {levelField && !isQuoteReferred ? (
                            <React.Fragment>
                                <ColumnLayout split="-1">
                                    <Text style="heading 5">Select your coverage </Text>
                                    <TextButton onClick={modals[coverage.type].show}>
                                        What's the difference?
                                    </TextButton>
                                </ColumnLayout>
                                <Form.Field
                                    type={levelField.type}
                                    inputProps={{
                                        ...levelField.props,
                                        value: getLevel(levelField.props.value, coverageRestriction)
                                            .value,
                                        items: getLevel(levelField.props.value, coverageRestriction)
                                            .values,
                                        disabled: isSubmitting,
                                    }}
                                    messages={levelField.messages}
                                />
                            </React.Fragment>
                        ) : null}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    {!isQuoteReferred ? (
                        <Text as="span" style="body 1">
                            Premium:{' '}
                            <Text as="span" style="heading 5">
                                <MoneyDisplay value={coverage.premium} />
                            </Text>
                        </Text>
                    ) : null}
                    <Text style="body 1">
                        Previous Premium:{' '}
                        <Text as="span" style="heading 5">
                            <MoneyDisplay value={coverage.previousPremium} />
                        </Text>
                    </Text>
                </Card.Footer>
                <Modal size="large" {...eoCyberModal}>
                    <ESPEoCyberDifferenceModal />
                </Modal>
            </Card>
        );
    }

    const showEpliWarning =
        globalConfig?.espIptChangesEnabled && state === 'CA' && coverage.type === 'epli';

    return (
        <Card
            onDismiss={onDismissUpdate}
            menuItems={[
                <TextButton
                    target="_blank"
                    key={coverage.type}
                    href={getUrlBasedOnSubmittedDate(
                        coverage.type,
                        newInsurerDocumentsReleaseDate,
                        fiduciaryDocumentsReleaseDate,
                        submittedAt,
                        state,
                        effectiveDate,
                    )}
                >
                    Download coverage details
                </TextButton>,
            ]}
        >
            <Card.Header>
                <Icon name={espRenewalCoverageDetailsMap[coverage.type].icon} />
                <Text style="heading 5">{espRenewalCoverageDetailsMap[coverage.type].title}</Text>
            </Card.Header>
            <Card.Body>
                <StackLayout>
                    <Text>{espRenewalCoverageDetailsMap[coverage.type].text}</Text>
                    {showEpliWarning && (
                        <StatusMessage status="helptext">
                            To purchase Employment Practices Liability digitally, you must include
                            at least one other coverage as part of your quote
                        </StatusMessage>
                    )}
                    <ColumnLayout>
                        <Form.Field
                            type={limitField.type}
                            className="u-1/2"
                            inputProps={{
                                ...limitField.props,
                                value:
                                    limitField.props.value === undefined
                                        ? undefined
                                        : Money.toFloat(getLimitValue(limitField.props.value)),
                                filterable: false,
                                items: (isHigherLimitRequested(higherLimitRequest)
                                    ? getRestrictedLimitRetentionOptions(
                                          coverage.type,
                                          coverage.previousLimit,
                                          state,
                                          coverageRestrictionForCalculation,
                                          revenue,
                                          true,
                                      )
                                    : getLimitRetentionOptions(
                                          coverage.type,
                                          coverage.previousLimit,
                                          state,
                                          revenue,
                                          coverageRestrictionForCalculation,
                                      )
                                ).limits,
                                label: 'Limit',
                                disabled:
                                    getRestrictedLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousLimit,
                                        state,
                                        coverageRestrictionForCalculation,
                                        revenue,
                                    ).limits.length == 1 || isSubmitting,
                            }}
                        />
                        <Form.Field
                            type={retentionField.type}
                            className="u-1/2"
                            inputProps={{
                                ...retentionField.props,
                                value:
                                    retentionField.props.value === undefined
                                        ? undefined
                                        : Money.toFloat(
                                              getRetentionValue(
                                                  retentionField.props.value,
                                                  coverageRestrictionForCalculation,
                                              ),
                                          ),
                                filterable: false,
                                items: filterRetentions(
                                    getRestrictedLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousLimit,
                                        state,
                                        coverageRestrictionForCalculation,
                                        revenue,
                                    ).retentions,
                                    answers,
                                    coverageRestrictionForCalculation,
                                ),
                                label: 'Retention',
                                disabled:
                                    getRestrictedLimitRetentionOptions(
                                        coverage.type,
                                        coverage.previousLimit,
                                        state,
                                        coverageRestrictionForCalculation,
                                        revenue,
                                    ).retentions.length == 1 || isSubmitting,
                            }}
                        />
                    </ColumnLayout>
                    <ColumnLayout>
                        <BoxLayout gap="12" className="u-1/2">
                            <StackLayout gap="none">
                                <Text style="microcopy">Previous Limit:</Text>
                                <Text style="body 1">
                                    <MoneyDisplay
                                        value={coverage.previousLimit}
                                        fractionDigits={0}
                                    />
                                </Text>
                            </StackLayout>
                        </BoxLayout>
                        <BoxLayout gap="12" className="u-1/2">
                            <StackLayout gap="none">
                                <Text style="microcopy">Previous Retention</Text>
                                <Text style="body 1">
                                    <MoneyDisplay
                                        value={coverage.previousRetention}
                                        fractionDigits={0}
                                    />
                                </Text>
                            </StackLayout>
                        </BoxLayout>
                    </ColumnLayout>
                    {levelField && !isQuoteReferred ? (
                        <React.Fragment>
                            <ColumnLayout split="-1">
                                <Text style="heading 5">Select your coverage </Text>
                                <TextButton onClick={modals[coverage.type].show}>
                                    What's the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type={levelField.type}
                                inputProps={{
                                    ...levelField.props,
                                    value: getLevel(levelField.props.value, coverageRestriction)
                                        .value,
                                    items: getLevel(levelField.props.value, coverageRestriction)
                                        .values,
                                }}
                                messages={levelField.messages}
                            />
                        </React.Fragment>
                    ) : null}
                </StackLayout>
            </Card.Body>
            <Card.Footer>
                <StackLayout gap="8">
                    {!isQuoteReferred ? (
                        <Text as="span" style="body 1">
                            Premium:{' '}
                            <Text as="span" style="heading 5">
                                <MoneyDisplay value={coverage.premium} />
                            </Text>
                        </Text>
                    ) : null}
                    <Text style="body 1">
                        Previous Premium:{' '}
                        <Text as="span" style="heading 5">
                            <MoneyDisplay value={coverage.previousPremium} />
                        </Text>
                    </Text>
                </StackLayout>
            </Card.Footer>
            <Modal size="large" {...eoCyberModal}>
                <ESPEoCyberDifferenceModal />
            </Modal>
            <Modal size="large" {...doModal}>
                <ESPDoDifferenceModal />
            </Modal>
            <Modal size="large" {...epliModal}>
                <ESPEplDifferenceModal />
            </Modal>
        </Card>
    );
};

function isEpliStandalone(
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'],
    coverageToBeDeselected: 'dno' | 'epli' | 'fiduciary' | 'eo' | 'techCyber',
    state?: Nullable<State>,
): boolean {
    if (coverageToBeDeselected === 'epli') {
        return false;
    }

    const isEplSelected = fields.isEplSelected?.props?.value;

    if (!isEplSelected) {
        return false;
    }

    if (state !== 'CA' && isEplSelected) {
        return false;
    }

    const fieldsNameMap: { [key: string]: string } = {
        ['dno']: 'isDnoSelected',
        ['fiduciary']: 'isFiduciarySelected',
        ['eo']: 'isEOSelected',
        ['techCyber']: 'isTechSelected',
    };

    const coveragesFields = Object.values(fieldsNameMap).filter((item) => {
        return item !== fieldsNameMap[coverageToBeDeselected];
    });

    for (const field of coveragesFields) {
        const isFieldSelected = (fields as any)[field]?.props?.value;
        if (isFieldSelected) {
            return false;
        }
    }

    return true;
}
