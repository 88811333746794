import { OperationFailed } from '@embroker/shotwell/core/Error';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Spinner, StatusMessage, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ActivityAppStatus, ActivityItemRecord } from '../../../types/BrokerActivityItem';
import { ProductType } from '../../../types/ProductType';
import { RenewNowModal } from '../modals/RenewNowModal';
import { GetGlobalConfig } from '@app/config/useCases/GetGlobalConfigUseCase';
import { container } from '@embroker/shotwell/core/di';
import { Logger, Log } from '@embroker/shotwell/core/logging/Logger';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

interface BrokerActivityActionProps {
    record: ActivityItemRecord;
    organizationId: UUID;
    applicationId: UUID;
    productType: ProductType;
    hasQuotes?: boolean;
    isSubmittedExternally?: boolean;
    isStreamline?: boolean;
    activityAppStatus?: ActivityAppStatus;
    hasSupplementalQuestionnaireInProgress?: boolean;
}

export function BrokerActivityActionButton({
    record,
    organizationId,
    applicationId,
    productType,
    hasQuotes,
    isSubmittedExternally,
    activityAppStatus,
    isStreamline,
    hasSupplementalQuestionnaireInProgress,
}: BrokerActivityActionProps) {
    const { navigate } = useNavigation();
    const renewNowModal = useModal();

    const handleContinue = async () => {
        await execute(SelectOrganization, { organizationId });
        const applicationUrl = `${
            activityAppStatus === 'inCreation' ? '/broker' : '/shopping'
        }/application`;

        navigate(
            URI.build(applicationUrl, {
                applicationId,
            }),
        );
    };

    const handleViewQuotes = async () => {
        const link = resolveViewQuotesLink(applicationId, productType, record);
        if (link) {
            await execute(SelectOrganization, { organizationId });
            return navigate(link);
        }
        return navigateToErrorPage(navigate, [
            OperationFailed({
                message: 'Failed to resolve quote now link',
            }),
        ]);
    };

    const { result: globalConfigResult, isLoading: isLoadingGlobalConfig } =
        useUseCase(GetGlobalConfig);

    if (isLoadingGlobalConfig || globalConfigResult === undefined) {
        return <Spinner />;
    }

    if (globalConfigResult && isErr(globalConfigResult)) {
        const errorId = container.get<Logger>(Log).whoops(globalConfigResult?.errors);
        const whoopsErrorMessage = errorId ? `Whoops error id: ${errorId}` : '';

        return (
            <StatusMessage status="error">{`We failed to load the global configuration. Try again or report an error! ${whoopsErrorMessage}`}</StatusMessage>
        );
    }

    const handleRenewNow = async () => {
        if (hasQuotes) {
            return handleViewQuotes();
        }

        if (isStreamline) {
            await execute(SelectOrganization, { organizationId });

            if (globalConfigResult.value.config.streamlineRenewalsEnhanced) {
                navigate(
                    URI.build('/shopping/review-renewal-data', {
                        applicationId,
                    }),
                );
            } else {
                navigate(
                    URI.build('/shopping/submit-streamline-renewal', {
                        applicationId: applicationId,
                    }),
                );
            }

            return;
        }

        if (isSubmittedExternally || productType === 'Excess') {
            await execute(SelectOrganization, { organizationId });

            navigate(
                URI.build('/shopping/application/basic-info', {
                    applicationId,
                }),
            );
        } else {
            renewNowModal.show();
        }
    };

    const handlePayNow = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate('/payments');
    };

    const handleContinueSupplementalQuestionnaire = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application/supplemental-questionnaire/esp', {
                applicationId: applicationId,
            }),
        );
    };

    switch (record) {
        case 'application':
            return (
                <TextButton
                    className="access_todo_action"
                    data-e2e="application-todo-action"
                    onClick={handleContinue}
                >
                    {'Continue'}
                </TextButton>
            );
        case 'quote':
            return (
                <TextButton
                    className="access_todo_action"
                    data-e2e="quote-todo-action"
                    onClick={
                        hasSupplementalQuestionnaireInProgress
                            ? handleContinueSupplementalQuestionnaire
                            : handleViewQuotes
                    }
                >
                    {hasSupplementalQuestionnaireInProgress ? 'Continue' : 'View Quotes'}
                </TextButton>
            );
        case 'renewal':
            return (
                <React.Fragment>
                    <TextButton
                        className="access_todo_action"
                        data-e2e="renewal-todo-action"
                        onClick={
                            hasSupplementalQuestionnaireInProgress
                                ? handleContinueSupplementalQuestionnaire
                                : handleRenewNow
                        }
                    >
                        {hasSupplementalQuestionnaireInProgress
                            ? 'Continue'
                            : hasQuotes
                            ? 'View Quotes'
                            : 'Renew now'}
                    </TextButton>
                    <RenewNowModal
                        modal={renewNowModal}
                        applicationId={applicationId}
                        organizationId={organizationId}
                    />
                </React.Fragment>
            );
        case 'payment':
            return (
                <TextButton
                    className="access_todo_action"
                    data-e2e="payment-todo-action"
                    onClick={handlePayNow}
                >
                    {'Pay now'}
                </TextButton>
            );
    }
}

function resolveViewQuotesLink(
    applicationId: UUID,
    productType: ProductType,
    record: ActivityItemRecord,
) {
    const baseURI = '/shopping/application/quote/';
    if (productType === 'ESP') {
        if (record === 'renewal') {
            return URI.build(`${baseURI}esp-renewals`, { applicationId });
        }
        return URI.build(`${baseURI}esp`, { applicationId });
    }
    if (productType === 'PCoML') {
        return URI.build(`${baseURI}pcoml`, { applicationId });
    }
    if (productType === 'LPL') {
        return URI.build(`${baseURI}lpl`, { applicationId });
    }
    if (productType === 'Crime') {
        return URI.build(`${baseURI}crime`, { applicationId });
    }
    if (productType === 'Cyber') {
        return URI.build(`${baseURI}cyber`, { applicationId });
    }

    if (productType === 'Excess') {
        return URI.build(`${baseURI}excess`, { applicationId });
    }
}
