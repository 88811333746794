export enum RiskProfileSerializedKeys {
    NaicsCode = 'naics_code',
    AreaOfFocus = 'area_of_focus',
    TechnologyServicesOrProducts = 'technology_services_or_products',
    NumberOfEmployees = 'number_of_employees',
    CompanyDimensions = 'company_dimensions',
}

export enum RiskProfileKeys {
    NaicsCode = 'naics',
    AreaOfFocus = 'areaOfFocus',
    TechnologyServicesOrProducts = 'technologyServicesOrProducts',
    NumberOfEmployees = 'numberOfEmployees',
    CompanyDimensions = 'companyDimensions',
}

export enum CoverageRisk {
    TechnologyServices = 'technology-services',
    Cybersecurity = 'cybersecurity',
    DirectorsAndOfficersLiability = 'directors-and-officers-liability',
    EmploymentPractices = 'employment-practices',
    GeneralLiability = 'general-liability',
    GeneralLiabilityProperty = 'general-liability-property',
    PropertyDamage = 'property-damage',
    BusinessInterruption = 'business-interruption',
    WorkersCompensation = 'workers-compensation',
    ProductLiability = 'product-liability',
    InternationalLiabilityAndTravel = 'international-liability-and-travel',
    EmployeeBenefitsAndFiduciaryLiability = 'employee-benefits-and-fiduciary-liability',
}

export enum CompanyDimension {
    RaisedFunds = 'raised_funds',
    AllEmployeesRemoteWork = 'all_employees_remote_work',
    OfficeLocationRentOrOwn = 'office_location_rent_or_own',
    PhysicalProducts = 'physical_products',
    InternationalOperations = 'international_operations',
    PreferredEmployerOrganization = 'preferred_employer_organization',
    EmployeeBenefitPlans = 'employee_benefit_plans',
}
