import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Button,
    ButtonBar,
    ColumnLayout,
    CopyToClipboard,
    InputStatus,
    Nullable,
    RadioGroup,
    Spinner,
    Stack,
    StackLayout,
    Text,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { UploadFile } from '../../../../documents/view/UploadFile/UploadFile';
import { AppTypeCodeListEmbrokerExcess } from '@app/shopping/types/enums';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm } from '../../../../view/hooks/useWizardForm';
import { OrganizationSearch } from '../organizations/OrganizationSearch';
import { AdditionalInfoSuccessModal } from './additionalInfo/AdditionalInfoSuccessModal';
import { CyberCrimeAdditionalInfo } from './additionalInfo/CyberCrimeAdditionalInfo';
import { LPLAdditionalInfo } from './additionalInfo/LPLAdditionalInfo';
import { MlAdditionalInfo } from './additionalInfo/MlAdditionalInfo';
import { ApplicationProcessing } from './ApplicationProcessing';
import { BrokerApplicationUpload } from './BrokerApplicationUpload';
import {
    BrokerCoverageType,
    BrokerOrganizationItem,
    BrokerSelectProductFormData,
    CreateApplicationIntakeResult,
    createBrokerSelectProductForm,
    SelectProductResult,
} from './createBrokerSelectProductForm';
import {
    SupportedApplicationsListCrime,
    SupportedApplicationsListDefault,
    SupportedApplicationsListESP,
    SupportedApplicationsListExcess,
    SupportedApplicationsListLPL,
    SupportedApplicationsListPco,
} from './SupportedApplicationList';
import { getShareUrl } from '../shareableApplications/ShareableAppLink';
import { container } from '@embroker/shotwell/core/di';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

export const EPL = 'Employment Practices Liability';
export const DO = 'Directors and Officers';
export const DOAndEPL = 'both';

const wizardTitle = 'Get a Quote';
const startButtonText = 'Continue';
const nextButtonText = 'Continue';
const submitButtonText = 'Continue';

export interface CoverageItem {
    title: string;
    value: BrokerCoverageType;
    statusLabel?: string;
}

export interface BrokerSelectProductProps {
    naicsCode?: string;
    initialData?: any;
    hideCompanyPage?: boolean;
    isPreselected?: boolean;
    coverages: CoverageItem[];
}

export function BrokerSelectProduct(props: BrokerSelectProductProps) {
    const { isPreselected, coverages } = props;
    const { closeSlideout, setSlideout } = useContext(AppContext);
    const [submissionFiles, setSubmissionFiles] = useState<FileUpload[]>([]);
    const { navigate } = useNavigation();
    const [intakeTaskId, setIntakeTaskId] = useState<Nullable<UUID>>();
    const [intakeAppId, setIntakeAppId] = useState<Nullable<UUID>>();
    const [shareToken, setShareToken] = useState<string>();
    const [intakeQuoteNowUri, setIntakeQuoteNowUri] = useState<URI>();
    const [folded, setFolded] = useState(true);
    const [additionalInfoSubmissionFinished, setAdditionalInfoSubmissionFinished] = useState(false);
    const [isExcessSelected, setIsExcessSelected] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const appIntakeAbortController = useMemo(() => new AbortController(), [intakeTaskId]);
    const selectProductAbortController = useMemo(() => new AbortController(), []);
    useEffect(() => {
        return () => {
            appIntakeAbortController.abort();
            selectProductAbortController.abort();
        };
    }, [appIntakeAbortController, selectProductAbortController]);

    const fieldsPerPage: { [key: number]: (keyof BrokerSelectProductFormData)[] } = {
        0: ['company'],
        1: ['coverageType'],
        2: ['cyberAndTechEO'],
        3: ['pcomlCoverage'],
        4: ['applicationIntake'],
        5: ['submissionFiles'],
        6: ['additionalData'],
    };

    const pages = Object.entries(fieldsPerPage).map(([name, fields]) => ({
        name,
        fields,
        isInitial: props.hideCompanyPage && name === '1',
    }));

    const BrokerSelectProductForm = useMemo(() => {
        return createBrokerSelectProductForm({
            abortController: selectProductAbortController,
        });
    }, [selectProductAbortController]);

    const {
        value,
        setValue,
        next,
        previous,
        activePageIndex,
        setActiveStep,
        fields,
        status,
        result,
        action,
        trigger,
    } = useWizardForm(BrokerSelectProductForm, {
        pages,
        initialValue: {
            submissionFiles: false,
            ...props.initialData,
        },
        keepUrl: true,
    });

    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );

    const documentIngestionFeature = experimentationService.getFeatureValue(
        'document-ingestion',
        {},
    );

    const shouldShowLPLDocumentIngestion =
        documentIngestionFeature?.coverages?.includes('lpl') &&
        value.coverageType === 'Lawyers Professional Liability';

    const shouldShowESPDocumentIngestion =
        documentIngestionFeature?.coverages?.includes('esp') &&
        value.coverageType === 'Startup Package';

    const goToApplication = useCallback(
        (applicationId: UUID | undefined, isExcess?: boolean) => {
            if (applicationId === undefined) {
                return;
            }
            closeSlideout();
            setSlideout(null);

            const baseURL = !isExcessSelected
                ? '/broker/application/basic-info'
                : '/shopping/excess-before-you-begin';
            navigate(URI.build(baseURL, { applicationId }));
        },
        [closeSlideout, setSlideout, navigate, isExcessSelected],
    );

    useEffect(() => {
        if (status === 'submitted' && action === 'selectProduct' && result !== undefined) {
            const { applicationId, applicationType, applicationToken, shoppingCoverageList } =
                result as SelectProductResult;
            const isExcess = applicationType === AppTypeCodeListEmbrokerExcess;
            setIsExcessSelected(isExcess);
            if (shouldShowLPLDocumentIngestion) {
                setValue({
                    ...value,
                    shoppingCoverageList,
                    applicationId,
                });
                setShareToken(applicationToken);
                setActiveStep(4);
            } else if (shouldShowESPDocumentIngestion) {
                setValue({
                    ...value,
                    shoppingCoverageList,
                    applicationId,
                });
                setShareToken(applicationToken);
                setActiveStep(4);
            } else {
                goToApplication(applicationId, isExcess);
            }
        }
    }, [
        status,
        action,
        result,
        value,
        setValue,
        goToApplication,
        setActiveStep,
        shouldShowLPLDocumentIngestion,
        shouldShowESPDocumentIngestion,
    ]);

    useEffect(() => {
        if (
            status === 'submitted' &&
            action === 'createApplicationIntake' &&
            result !== undefined
        ) {
            const { applicationId, taskId } = result as CreateApplicationIntakeResult;
            const baseURL = !isExcessSelected
                ? '/broker/application/basic-info'
                : '/shopping/excess-before-you-begin';
            setIntakeAppId(applicationId);
            setIntakeTaskId(taskId);
            setIntakeQuoteNowUri(
                URI.build(baseURL, {
                    applicationId,
                }),
            );
        }
    }, [status, action, result, isExcessSelected]);

    const goToAdditionalInfoWizard = () => setActiveStep(5);
    const coverageLastAction = () => {
        trigger('selectProduct');
    };
    const coverageLastActionText = submitButtonText;

    let SupportedApplicationList = SupportedApplicationsListDefault;
    let coverageNextAction = next;
    let coverageNextText = nextButtonText;

    switch (value.coverageType) {
        case BrokerCoverageType.LPL:
            coverageNextAction = coverageLastAction;
            coverageNextText = coverageLastActionText;
            SupportedApplicationList = SupportedApplicationsListLPL;
            break;
        case BrokerCoverageType.Crime:
            coverageNextAction = coverageLastAction;
            coverageNextText = coverageLastActionText;
            SupportedApplicationList = SupportedApplicationsListCrime;
            break;
        case BrokerCoverageType.PCOML:
            coverageNextAction = () => setActiveStep(3);
            SupportedApplicationList = SupportedApplicationsListPco;
            break;
        case BrokerCoverageType.ESP:
            coverageNextAction = () => setActiveStep(2);
            SupportedApplicationList = SupportedApplicationsListESP;
            break;
        case BrokerCoverageType.EmbrokerExcess:
            coverageNextAction = coverageLastAction;
            coverageNextText = coverageLastActionText;
            SupportedApplicationList = SupportedApplicationsListExcess;
    }

    const handleStartApplication = () => {
        if (isPreselected) {
            coverageNextAction();
        } else {
            next();
        }
    };

    const backToCoverage = () => {
        if (isPreselected) {
            setActiveStep(0);
        } else {
            setActiveStep(1);
        }
    };

    const intakeNextAction = () =>
        value.applicationIntake
            ? trigger('createApplicationIntake')
            : goToApplication(value.applicationId, isExcessSelected);

    const handleSelected = (selectedFiles: FileUpload[], newFiles: FileUpload[]) => {
        setSubmissionFiles(newFiles);
    };

    const handleRemoved = (removedFile: FileUpload, newFiles: FileUpload[]) => {
        setSubmissionFiles(newFiles);
        setValue({
            ...value,
            submissionFiles: newFiles.length > 0,
        });
    };

    const handleUploaded = (uploadedFile: FileUpload, newFiles: FileUpload[]) => {
        setSubmissionFiles(newFiles);
        setValue({
            ...value,
            submissionFiles: newFiles.length > 0,
        });
    };

    function handleCompanyChange(item: { value: Nullable<UUID>; label: string }) {
        const newValue: BrokerOrganizationItem = {
            id: item.value,
            name: item.label,
        };
        setValue({
            ...value,
            company: newValue,
        });
    }

    function handleApplicationUpload(fileKey: Nullable<string>) {
        if (fileKey !== null) {
            setValue({
                ...value,
                applicationIntake: fileKey,
            });
        }
    }

    function handleApplicationRemove() {
        setValue({
            ...value,
            applicationIntake: undefined,
        });
    }

    const toggleFolded = () => setFolded(!folded);

    const handleClose = () => {
        closeSlideout();
        setSlideout(null);
    };

    const setAdditionalInfoSubmissionFinishedFlagTrue = () => {
        setAdditionalInfoSubmissionFinished(true);
    };

    const additionalInfoComponent =
        value.coverageType === BrokerCoverageType.ESP ||
        value.coverageType === BrokerCoverageType.PCOML ? (
            <MlAdditionalInfo
                appId={intakeAppId as UUID}
                filesUploaded={submissionFiles}
                pcomlCoverage={value.pcomlCoverage}
                firstBackAction={goToAdditionalInfoWizard}
                cyberAndTechEO={value.cyberAndTechEO}
                coverageType={value.coverageType}
                openSuccessModal={setAdditionalInfoSubmissionFinishedFlagTrue}
            />
        ) : value.coverageType === BrokerCoverageType.LPL ? (
            <LPLAdditionalInfo
                appId={intakeAppId as UUID}
                filesUploaded={submissionFiles}
                firstBackAction={goToAdditionalInfoWizard}
                openSuccessModal={setAdditionalInfoSubmissionFinishedFlagTrue}
            />
        ) : (
            <CyberCrimeAdditionalInfo
                appId={intakeAppId as UUID}
                filesUploaded={submissionFiles}
                coverageType={value.coverageType}
                firstBackAction={goToAdditionalInfoWizard}
                openSuccessModal={setAdditionalInfoSubmissionFinishedFlagTrue}
            />
        );

    const isLoading = status === 'submitting';

    const shouldShowShareUrl = !isExcessSelected && !value.applicationIntake;

    return (
        <WizardLayout title={wizardTitle} onDismiss={handleClose}>
            {isLoading ? (
                <Spinner appearance="transparent" />
            ) : (
                <StackLayout gap="24">
                    <Stack activeIndex={activePageIndex}>
                        {/* Company */}
                        <StackLayout title="Company website">
                            <Text style="heading 3">
                                What's the name of the company you need coverage for?
                            </Text>
                            <StackLayout gap="8" />
                            <OrganizationSearch
                                {...fields.company.props}
                                minimumTextLength={1}
                                onSelect={handleCompanyChange}
                                placeholder="Company Name"
                            />
                            <InputStatus messages={fields.company.messages} role="alert" />
                            <StackLayout gap="8" />
                            <Button
                                onClick={handleStartApplication}
                                data-e2e="continue-button-company"
                            >
                                {startButtonText}
                            </Button>
                        </StackLayout>
                        {/* Coverage Type */}
                        <StackLayout>
                            <Text style="heading 3">
                                What coverages do you need for your client?
                            </Text>
                            <Text style="body 1">
                                Please select the coverage type that best fits your client.
                            </Text>
                            <StackLayout gap="8" />
                            <RadioGroup
                                id="coverage-type"
                                items={coverages}
                                {...fields.coverageType.props}
                            />
                            <InputStatus messages={fields.coverageType.messages} role="alert" />
                            <StackLayout gap="8" />
                            <WizardLayout.Actions>
                                <Button
                                    onClick={coverageNextAction}
                                    data-e2e="continue-button-coverage"
                                >
                                    {coverageNextText}
                                </Button>
                                {props.hideCompanyPage ? null : (
                                    <TextButton onClick={previous}>Back</TextButton>
                                )}
                            </WizardLayout.Actions>
                        </StackLayout>
                        {/* Cyber and Tech E&O */}
                        <StackLayout>
                            <Text style="heading 3">
                                Include a technology E&O and Cyber Liability quote in the Startup
                                Package?
                            </Text>
                            <StackLayout gap="8" />
                            <RadioGroup
                                id="cyber"
                                items={[
                                    {
                                        title: 'Yes',
                                        value: true,
                                    },
                                    {
                                        title: 'No',
                                        value: false,
                                    },
                                ]}
                                {...fields.cyberAndTechEO.props}
                            />
                            <InputStatus messages={fields.cyberAndTechEO.messages} role="alert" />
                            <StackLayout gap="8" />
                            <WizardLayout.Actions>
                                <Button onClick={coverageLastAction} data-e2e="continue-button-esp">
                                    {coverageLastActionText}
                                </Button>
                                <TextButton onClick={backToCoverage}>Back</TextButton>
                            </WizardLayout.Actions>
                        </StackLayout>
                        {/* PCoML Coverage */}
                        <StackLayout>
                            <Text style="heading 3">
                                What types of coverages are you seeking a quote for?
                            </Text>
                            <Text style="body 1">
                                Please select the Private Company Management Liability coverages
                                which best fit your client.
                            </Text>
                            <StackLayout gap="8" />
                            <RadioGroup
                                id="coverage-types"
                                items={[
                                    {
                                        title: 'Directors and Officers',
                                        value: DO,
                                    },
                                    {
                                        title: 'Employment Practices Liability',
                                        value: EPL,
                                    },
                                    {
                                        title: 'Both',
                                        value: DOAndEPL,
                                    },
                                ]}
                                {...fields.pcomlCoverage.props}
                            />
                            <InputStatus messages={fields.pcomlCoverage.messages} role="alert" />
                            <StackLayout gap="8" />
                            <WizardLayout.Actions>
                                <Button
                                    onClick={coverageLastAction}
                                    data-e2e="continue-button-pcoml"
                                >
                                    {coverageLastActionText}
                                </Button>
                                <TextButton onClick={backToCoverage}>Back</TextButton>
                            </WizardLayout.Actions>
                        </StackLayout>
                        {/* Application intake */}
                        <StackLayout gap="32">
                            <StackLayout>
                                <Text style="heading 3">
                                    Upload a previously completed application to save time.
                                </Text>
                                <Text style="body 1">
                                    Don’t have one? See the below list for other documents we can
                                    process. After you upload your PDF, our platform will
                                    automatically populate the relevant fields for you.
                                </Text>
                                <ColumnLayout>
                                    <Text style="label 1">Types of documents to upload</Text>
                                    <TextButton
                                        icon={folded ? 'caret-down' : 'caret-up'}
                                        onClick={toggleFolded}
                                    />
                                </ColumnLayout>
                                {folded ? null : SupportedApplicationList()}
                                <StackLayout gap="8" />
                                {activePageIndex === 4 ? (
                                    <BrokerApplicationUpload
                                        onFileUpload={handleApplicationUpload}
                                        onFileRemove={handleApplicationRemove}
                                    />
                                ) : null}
                                <InputStatus
                                    messages={fields.applicationIntake.messages}
                                    role="alert"
                                />
                                <StackLayout gap="8" />
                                {shouldShowShareUrl && shareToken ? (
                                    <CopyToClipboard
                                        title="Share application (URL) with your client."
                                        tooltip="You can share this application (URL) with your client (at any time), and they can work on the application. We'll notify you via email when your client completes the application."
                                        value={getShareUrl(shareToken)}
                                    />
                                ) : null}
                            </StackLayout>
                            <WizardLayout.Actions>
                                <ButtonBar split="1">
                                    <div />
                                    <TextButton onClick={backToCoverage}>Back</TextButton>
                                    <Button
                                        onClick={intakeNextAction}
                                        data-e2e="continue-button-intake"
                                    >
                                        {value.applicationIntake ? 'Continue' : 'Skip and Continue'}
                                    </Button>
                                </ButtonBar>
                            </WizardLayout.Actions>
                        </StackLayout>
                        {/* Submission files */}
                        <StackLayout>
                            <Text style="heading 3">Upload additional files.</Text>
                            <Text style="body 1">
                                Upload any additional documents that will allow our team to review
                                and resolve your application faster. You can upload files such as
                                financial statements, loss runs etc..
                            </Text>

                            <StackLayout gap="8" />
                            <UploadFile
                                fileTypeFilters={['application/pdf']}
                                onSelected={handleSelected}
                                onUploaded={handleUploaded}
                                onRemoved={handleRemoved}
                                showButton
                                isMultiple
                                buttonText="Choose files"
                                placeholder="or drag files here."
                                files={submissionFiles}
                            />

                            <InputStatus messages={fields.submissionFiles.messages} role="alert" />
                            <StackLayout gap="8" />
                            <WizardLayout.Actions>
                                <Button
                                    onClick={next}
                                    data-e2e="continue-button-intake-additional-files"
                                >
                                    {value.submissionFiles ? 'Continue' : 'Skip'}
                                </Button>
                            </WizardLayout.Actions>
                        </StackLayout>
                        {/* Additional info */}
                        {additionalInfoComponent}
                    </Stack>
                    {intakeTaskId && (
                        <ApplicationProcessing
                            taskId={intakeTaskId}
                            abortController={appIntakeAbortController}
                            quoteNowUri={intakeQuoteNowUri}
                            isExcess={isExcessSelected}
                            setIntakeTaskId={setIntakeTaskId}
                        />
                    )}
                    {additionalInfoSubmissionFinished && <AdditionalInfoSuccessModal />}
                </StackLayout>
            )}
        </WizardLayout>
    );
}
