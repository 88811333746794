import React, { useMemo, useEffect, Fragment } from 'react';
import { StatusMessage, TextButton } from '@embroker/ui-toolkit/v2';
import { isLocalStorageEnabled } from '@embroker/shotwell/core/storage';
import { container } from '@embroker/shotwell/core/di';
import * as Sentry from '@sentry/browser';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

function getDeviceCurrentURL() {
    if (globalThis.navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        return `intent://${globalThis.location.host}${globalThis.location.pathname}#Intent;scheme=https;action=android.intent.action.VIEW;end;`;
    } else {
        return globalThis.location.href;
    }
}

export const getLocalStorageErrorMessage = () => {
    const experimentationService = container.get<GrowthBookExperimentationService>(
        GrowthBookExperimentationService,
    );

    const isLocalStorageAvailable = isLocalStorageEnabled();

    if (!isLocalStorageAvailable && experimentationService.isOn('local-storage-check'))
        return (
            <Fragment>
                We need access to your device's browser storage to give you the best experience.
                Please open the settings in your web browser and enable local storage, then{' '}
                <TextButton href={getDeviceCurrentURL()} target="_blank">
                    reload the page
                </TextButton>
                .
            </Fragment>
        );

    return null;
};

export const LocalStorageError = () => {
    const errorMessage = useMemo(getLocalStorageErrorMessage, []);

    useEffect(() => {
        if (errorMessage) {
            Sentry.captureMessage('Device localStorage unavailable', Sentry.Severity.Critical);
        }
    }, [errorMessage]);

    if (errorMessage) {
        return <StatusMessage status="error">{errorMessage}</StatusMessage>;
    }

    return null;
};
