import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    Card,
    ColumnLayout,
    StackLayout,
    StatusLabel,
    StatusType,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { differenceInDays, isFuture, isPast, startOfToday } from 'date-fns';
import React, { SyntheticEvent } from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { BorStatusIsBor, BorStatusPendingBor } from '../../types/BorStatus';
import { DisplayPolicy } from '../../types/DisplayPolicy';
import { PolicyStatusInSeriesCurrent } from '../../types/PolicyStatusInSeries';
import { ViewPolicyButton } from './PolicyTileComponents/ViewPolicyButton';
import { PayPolicyButton } from './PolicyTileComponents/PayPolicyButton';
import { TransferToEmbrokerButton } from './PolicyTileComponents/TransferToEmbrokerButton';
import { PolicyIcon } from './PolicyIcon.view';
import {
    LineOfBusinessCodeListItem,
    LineOfBusinessSubtypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { TransferRequestSignedDate } from './PolicyTileComponents/TransferRequestSignedDate';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishPolicyCardClickedEvent } from '@app/policy/useCases/PublishPolicyCardClickedEvent';
import { ModifyPolicyButton } from './PolicyTileComponents/ModifyPolicyButton';

export interface PolicyTileProps {
    policyData: Immutable<DisplayPolicy>;
    showActionButtons: boolean;
}

enum PolicyTilePrimaryActionType {
    TransferToEmbroker = 'TransferToEmbroker',
    PayNow = 'PayNow',
    ViewPolicy = 'ViewPolicy',
    PaymentDueToCarrier = 'PaymentDueToCarrier',
}

export function PolicyTile({ policyData, showActionButtons }: PolicyTileProps) {
    const maximumDaysBeforeExpirationToShow = 120;
    const currentDate = startOfToday();
    const daysBeforeExpiration = differenceInDays(policyData.endDate, currentDate);

    const policyStatusCancelled = 'CANCELLED';
    const policyStatusExpired = 'EXPIRED';
    const policyStatusRenewed = 'RENEWED';
    const policyStatusExpiresIn =
        'EXPIRES IN ' + daysBeforeExpiration + ' DAY' + (daysBeforeExpiration > 1 ? 's' : '');
    const policyStatusInRunoff =
        isPolicyMPL(policyData.lineOfBusiness) || isPolicyLPL(policyData.lineOfBusiness)
            ? 'In ERP'
            : 'In Runoff';

    function getStatusText(): string {
        if (policyData.inRunoff) {
            return policyStatusInRunoff;
        }
        if (policyData.cancellationDate !== null && !isFuture(policyData.cancellationDate)) {
            return policyStatusCancelled;
        }
        if (isPast(policyData.endDate)) {
            return policyStatusExpired;
        }
        if (policyData.hasRenewal) {
            return policyStatusRenewed;
        }
        if (daysBeforeExpiration > 0 && daysBeforeExpiration <= maximumDaysBeforeExpirationToShow) {
            return policyStatusExpiresIn;
        }
        return '';
    }

    const statusText = getStatusText();

    const policyStatusToStatusTypeMap = new Map<string, StatusType>([
        [policyStatusCancelled, 'red'],
        [policyStatusExpired, 'red'],
        [policyStatusRenewed, 'green-outline'],
        [policyStatusExpiresIn, 'red-outline'],
    ]);
    const statusType = policyStatusToStatusTypeMap.get(statusText) || 'gray';

    const statusComponent =
        statusText.length === 0 ? null : (
            <StackLayout split="-1">
                <ColumnLayout split="-1">
                    <StatusLabel type={statusType} data-e2e="status-label">
                        {statusText}
                    </StatusLabel>
                </ColumnLayout>
            </StackLayout>
        );

    async function redirectToPolicyDetails(event: SyntheticEvent, tabToOpen?: string) {
        event.stopPropagation();
        await execute(PublishPolicyCardClickedEvent, {
            eventName: 'CardViewPolicyClicked',
            policyData,
        });
        navigation.navigate(
            URI.build('/policies/detail/', {
                policyId: policyData.id,
                tabToOpen,
            }),
        );
    }

    function redirectToCarrierPayments(event: SyntheticEvent) {
        event.stopPropagation();
        navigation.navigate(URI.build('/payments/carriers'));
    }

    function getPrimaryActionType(): PolicyTilePrimaryActionType {
        if (policyData.borStatus === BorStatusIsBor) {
            if (policyData.isDirectBill) {
                return PolicyTilePrimaryActionType.PaymentDueToCarrier;
            } else if (policyData.invoiceDue) {
                return PolicyTilePrimaryActionType.PayNow;
            } else {
                return PolicyTilePrimaryActionType.ViewPolicy;
            }
        } else {
            return PolicyTilePrimaryActionType.TransferToEmbroker;
        }
    }

    function getPrimaryActionComponent(
        primaryActionType: PolicyTilePrimaryActionType,
    ): JSX.Element {
        switch (primaryActionType) {
            case PolicyTilePrimaryActionType.TransferToEmbroker: {
                return (
                    <TransferToEmbrokerButton
                        insurerId={policyData.insurerId}
                        transferRequested={policyData.borStatus === BorStatusPendingBor}
                        disabled={
                            policyData.cancellationDate !== policyData.cancellationDate ||
                            isPast(policyData.endDate)
                        }
                        policyId={policyData.id}
                    />
                );
            }
            case PolicyTilePrimaryActionType.PayNow: {
                return <PayPolicyButton policy={policyData} />;
            }
            case PolicyTilePrimaryActionType.PaymentDueToCarrier: {
                return (
                    <Button
                        appearance="secondary"
                        onClick={(event: SyntheticEvent) => {
                            redirectToCarrierPayments(event);
                        }}
                    >
                        Payment due to Carrier
                    </Button>
                );
            }
            case PolicyTilePrimaryActionType.ViewPolicy: {
                return (
                    <ViewPolicyButton
                        onClick={(event: SyntheticEvent) => {
                            redirectToPolicyDetails(event, PolicyStatusInSeriesCurrent);
                        }}
                    />
                );
            }
        }
    }

    const primaryActionButtonType = getPrimaryActionType();
    const primaryActionComponent = getPrimaryActionComponent(primaryActionButtonType);

    const viewPolicyComponent =
        primaryActionButtonType === PolicyTilePrimaryActionType.ViewPolicy ? (
            <ModifyPolicyButton
                disabled={
                    isPast(new Date(policyData.endDate)) || policyData.cancellationDate !== null
                }
                policyId={policyData.id}
                lob={policyData.lineOfBusiness}
                quotingEngine={policyData.quotingEngine}
                referred={policyData.isReferred}
                appearance="textbutton"
            />
        ) : (
            <Text nowrap>
                <TextButton
                    size="small"
                    data-e2e="view-policy"
                    onClick={(event: SyntheticEvent) => {
                        redirectToPolicyDetails(event, PolicyStatusInSeriesCurrent);
                    }}
                >
                    View policy
                </TextButton>
            </Text>
        );

    const navigation = useNavigation();

    const tooltipText =
        policyData.borStatus != BorStatusIsBor
            ? `Since Embroker is not your broker for this policy, we can't help you modify it. Please contact your broker for help, or click "Transfer to Embroker" button to give Embroker the right to manage your policy.`
            : undefined;

    return (
        <Card
            size="medium"
            locked={policyData.borStatus != BorStatusIsBor}
            lockTooltip={tooltipText}
            data-e2e="policy-tile"
        >
            <Card.Header singleColumn>
                <PolicyIcon
                    lineOfBusiness={policyData.lineOfBusiness}
                    subLineOfBusiness={
                        (policyData.subLineOfBusiness as LineOfBusinessSubtypeCodeListItem) ??
                        undefined
                    }
                />
                <Text style="heading 5" data-e2e={policyData.displayName}>
                    {policyData.displayName}
                </Text>
            </Card.Header>
            <Card.Body singleColumn>
                <ColumnLayout>
                    <StackLayout gap="4">
                        <Text data-e2e="insurer-name" style="heading 5">
                            {policyData.insurerName}
                        </Text>
                        <Text data-e2e="policy-number" style="body 2" color="ui-400">
                            Policy no: {policyData.policyNumber}
                        </Text>
                        <Text data-e2e="policy-period" style="body 2" color="ui-400">
                            Policy Period: <DateDisplay value={policyData.startDate} />-
                            <DateDisplay value={policyData.endDate} />
                        </Text>
                        <Text data-e2e="policy-premium" style="body 2" color="ui-400">
                            Premium:{' '}
                            <MoneyDisplay
                                value={
                                    policyData.premiumPerYear ? policyData.premiumPerYear : USD(0)
                                }
                            />
                        </Text>
                    </StackLayout>
                    {statusComponent}
                </ColumnLayout>
            </Card.Body>
            <Card.Footer singleColumn spacingTop="16">
                <ColumnLayout responsive={{ containerWidth: { smallerThan: 'mobile' } }} split="1">
                    {showActionButtons ? primaryActionComponent : null}
                    <StackLayout gap="none">
                        <ColumnLayout split="-1">{viewPolicyComponent}</ColumnLayout>
                        <ColumnLayout split="-1">
                            {policyData.borStatus === BorStatusPendingBor ? (
                                <TransferRequestSignedDate policyId={policyData.id} />
                            ) : null}
                        </ColumnLayout>
                    </StackLayout>
                </ColumnLayout>
            </Card.Footer>
        </Card>
    );
}

function isPolicyMPL(lineOfBusiness: LineOfBusinessCodeListItem) {
    return (
        lineOfBusiness === 'LineOfBusinessCodeListAccountantsProfessionalLiability' ||
        lineOfBusiness ===
            'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability' ||
        lineOfBusiness ===
            'LineOfBusinessCodeListTaxPreparersAndBookkeepersProfessionalLiability' ||
        lineOfBusiness === 'LineOfBusinessCodeListRealEstateAgentsProfessionalLiability' ||
        lineOfBusiness === 'LineOfBusinessCodeListHomeInspectorsProfessionalLiability'
    );
}

function isPolicyLPL(lineOfBusiness: LineOfBusinessCodeListItem) {
    return lineOfBusiness === 'LineOfBusinessCodeListProfessionalLiability';
}
