import React, { useEffect, useState } from 'react';
import {
    Banner,
    Button,
    CenterLayout,
    ColumnLayout,
    PageLayout,
    StackLayout,
    StatusMessage,
    Text,
    usePageLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { HeaderView } from '@app/view/components';
import { Link } from 'react-navi';

export interface CoverageRecommendationLayoutProps {
    handleGetStarted?: () => void;
    isAllProductsNotSelected: boolean;
    banner?: JSX.Element | React.ReactNode;
    children?: JSX.Element | React.ReactNode;
    footer?: JSX.Element | React.ReactNode;
}

export function CoverageRecommendationLayout({
    isAllProductsNotSelected,
    handleGetStarted,
    banner,
    children,
    footer,
}: CoverageRecommendationLayoutProps) {
    const [showError, setShowError] = useState(false);

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });
    const pageLayout = usePageLayout();
    const { setHeader } = pageLayout;

    useEffect(() => {
        setHeader(<HeaderView />);
    }, [setHeader]);

    const onCTAClick = () => {
        if (isAllProductsNotSelected) {
            setShowError(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        } else {
            if (!handleGetStarted) return;
            handleGetStarted();
        }
    };

    return (
        <PageLayout {...pageLayout}>
            <PageLayout.Section style={{ maxWidth: '1348px' }}>
                <StackLayout gap="48" center>
                    <StackLayout gap="32" center>
                        <StackLayout gap="24" center>
                            {showError && (
                                <StatusMessage status="error">
                                    Please select at least one coverage to continue with your quote.
                                </StatusMessage>
                            )}
                            <Text style="heading 3" color="brand-400">
                                Coverage Selection
                            </Text>
                        </StackLayout>
                        <Text style="heading 4" color="brand-400">
                            Review our most essential coverage(s) below and select at least one to
                            continue.
                        </Text>
                        {banner}
                    </StackLayout>
                    <StackLayout gap="32">
                        <ColumnLayout
                            gap="16"
                            className="o-center-layout"
                            responsive={{ screenWidth: { smallerThan: 'large-tablet' } }}
                            stretch
                        >
                            {children}
                        </ColumnLayout>
                        <Banner backgroundColor="primary-50">
                            <CenterLayout>
                                <div>
                                    Click <Link href="/shopping">here</Link> to explore all
                                    coverages and find the right fit for your business.
                                </div>
                            </CenterLayout>
                        </Banner>
                    </StackLayout>
                    {handleGetStarted && (
                        <StackLayout className={isMobile ? 'u-1/1' : ''} center>
                            <Button onClick={onCTAClick} className="u-1/1">
                                Continue
                            </Button>
                        </StackLayout>
                    )}
                    {footer}
                </StackLayout>
            </PageLayout.Section>
        </PageLayout>
    );
}
