import React from 'react';
import { useModal, Text } from '@embroker/ui-toolkit/v2';
import { Link } from '@app/view/components/Link/Link';
import { AgreementToConductElectronicTransactionsModal } from '@app/view/components/modals/AgreementToConductElectronicTransactionsModal';

export const SignUpTermsAndConditions = () => {
    const agreementModal = useModal();
    return (
        <React.Fragment>
            <Text style="microcopy">
                By clicking &quot;Next,&quot; you acknowledge that you understand and agree to
                Embroker's&nbsp;
                <Link href="https://www.embroker.com/terms/" target="_blank">
                    Terms
                </Link>
                ,&nbsp;
                <Link href="https://www.embroker.com/privacy/" target="_blank">
                    Privacy Policy
                </Link>
                ,&nbsp;and&nbsp;
                <Link href="https://www.embroker.com/disclosure/" target="_blank">
                    Compensation Disclosure
                </Link>
                ,&nbsp;and&nbsp;
                <Link href="" display="inline" onClick={agreementModal.show}>
                    Agreement to Conduct Electronic Transactions
                </Link>
            </Text>
            <AgreementToConductElectronicTransactionsModal modal={agreementModal} />
        </React.Fragment>
    );
};
