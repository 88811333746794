import React, { useCallback, useEffect, useState } from 'react';
import { Modal, useModal } from '@embroker/ui-toolkit/v2';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { ESPFiduciaryWarningModalLayout } from './ESPFiduciaryWarningModalLayout';

type ESPFiduciaryWarningModalProps = {
    isESPWithOnlyFiduciarySelected: boolean;
};

export function ESPFiduciaryWarningModal({
    isESPWithOnlyFiduciarySelected,
}: ESPFiduciaryWarningModalProps) {
    const [hasESPFiduciaryModalBeenShown, setHasESPFiduciaryModalBeenShown] = useState(false);

    const modal = useModal({ visible: false });
    const showModal = useCallback(() => {
        modal.show();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page Fiduciary Pop up Displayed',
        });
    }, [modal]);

    useEffect(() => {
        if (!hasESPFiduciaryModalBeenShown && isESPWithOnlyFiduciarySelected) {
            showModal();
            setHasESPFiduciaryModalBeenShown(true);
        }
    }, [hasESPFiduciaryModalBeenShown, isESPWithOnlyFiduciarySelected, showModal]);

    const onModalDismiss = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page Fiduciary Pop up closed',
        });
    };
    const onModalClose = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page Fiduciary Pop up closed',
        });
        setHasESPFiduciaryModalBeenShown(true);
    };
    const handlePrimaryCTAClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page Fiduciary Pop up _ add more coverage clicked',
        });
        setHasESPFiduciaryModalBeenShown(true);
        modal.hide();
    };
    return (
        <Modal
            {...modal}
            onDismiss={onModalDismiss}
            onClose={onModalClose}
            size="small"
            dismissable
        >
            <ESPFiduciaryWarningModalLayout handlePrimaryCTAClick={handlePrimaryCTAClick} />
        </Modal>
    );
}
