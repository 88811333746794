import React from 'react';
import {
    BorderStyle,
    Button,
    Card,
    CheckBox,
    ColumnLayout,
    Icon,
    IconButton,
    IconName,
    IconPhosphor,
    StackLayout,
    StatusType,
    Text,
} from '@embroker/ui-toolkit/v2';

export enum RecommendationType {
    none = 'none',
    essential = 'essential',
    popular = 'popular',
}

interface RecommendationTypeStyle {
    borderStyle: BorderStyle;
    statusLabelText: string;
    statusLabelType: StatusType;
}

const RECOMMENDATION_TYPE_STYLES: Record<RecommendationType, RecommendationTypeStyle | null> = {
    none: null,
    essential: {
        borderStyle: 'ui-500',
        statusLabelText: 'MUST HAVE FOR LAWYERS',
        statusLabelType: 'gray',
    },
    popular: {
        borderStyle: 'primary-500',
        statusLabelText: 'POPULAR',
        statusLabelType: 'purple-dark',
    },
};

interface CoverageRecommendationCardProps {
    recommendationType: RecommendationType;
    iconName: IconName;
    title: string;
    children: JSX.Element;
    className?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    disabledText?: string;
    isSelected?: boolean;
    showToggle?: boolean;
    onTitleTooltipClick?: () => void;
    onCoverageSelectionChange?: () => void;
    toggleAppearance?: 'default' | 'button';
}

export function CoverageRecommendationCard({
    recommendationType,
    iconName,
    title,
    children,
    isDisabled = false,
    disabledText,
    isSelected,
    isRequired,
    showToggle = true,
    className,
    onTitleTooltipClick,
    onCoverageSelectionChange,
    toggleAppearance = 'default',
}: CoverageRecommendationCardProps) {
    const recommendationTypeStyle = RECOMMENDATION_TYPE_STYLES[recommendationType];
    const canToggle = showToggle && !isDisabled && !isRequired;
    const titleWithoutLastWord = title.split(' ').slice(0, -1).join(' ');
    const titleLastWord = title.split(' ').slice(-1)[0];
    return (
        <Card
            appearance="box"
            borderRadius="16"
            borderStyle={
                toggleAppearance === 'button' ? (isSelected ? 'primary-500' : 'none') : 'none'
            }
            statusLabelPosition="center"
            statusLabelType={recommendationTypeStyle?.statusLabelType}
            className={className}
        >
            <StackLayout gap="24">
                <StackLayout gap="16">
                    <StackLayout gap="8">
                        <ColumnLayout
                            top
                            split={canToggle || (isDisabled && disabledText) ? '-1' : undefined}
                        >
                            <Icon
                                size="large"
                                color={isDisabled ? 'ui-300' : 'primary-500'}
                                name={iconName}
                            />
                            {isDisabled && disabledText && (
                                <Text color="ui-400" style="body 1">
                                    {disabledText}
                                </Text>
                            )}
                            {canToggle ? (
                                toggleAppearance === 'default' ? (
                                    <CheckBox
                                        checked={isSelected}
                                        appearance="toggle"
                                        onChange={onCoverageSelectionChange}
                                        data-e2e="coverage-toggle"
                                        inline
                                    />
                                ) : (
                                    <Button
                                        onClick={onCoverageSelectionChange}
                                        data-e2e="coverage-toggle"
                                        appearance="secondary"
                                        active={isSelected}
                                        size="small"
                                    >
                                        <ColumnLayout gap="8">
                                            <span>{isSelected ? 'Selected' : 'Select'}</span>
                                            <IconPhosphor
                                                name={isSelected ? 'check' : 'plus'}
                                                size="xsmall"
                                                color="inherit"
                                            />
                                        </ColumnLayout>
                                    </Button>
                                )
                            ) : undefined}
                        </ColumnLayout>
                        <Text color={isDisabled ? 'ui-400' : 'ui-500'} style="heading 5">
                            {titleWithoutLastWord + ' '}
                            {!isDisabled && !!onTitleTooltipClick ? (
                                <Text as="span" style="heading 5" nowrap>
                                    {titleLastWord}&nbsp;
                                    <IconButton
                                        name="info"
                                        size="small"
                                        color="primary-500"
                                        onClick={onTitleTooltipClick}
                                    />
                                </Text>
                            ) : (
                                titleLastWord
                            )}
                        </Text>
                    </StackLayout>
                    {children}
                </StackLayout>
            </StackLayout>
        </Card>
    );
}
