import { injectable, inject } from '@embroker/shotwell/core/di';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { OrganizationRepository } from '../../userOrg/repositories/OrganizationRepository';
import {
    BaseExperimentationService,
    HeapSessionId,
    HeapUserId,
    GAUserId,
} from './BaseExperimentationService';
import { ExperimentationService } from '.';
import { getEnvVar } from '../../env';
import { AppContext } from '../../view/AppContext';
import { ExperimentationTestNames, ExperimentationServicePlatforms } from '../types/enums';
import { ExperimentationTestName } from '../types/ExperimentationTestName';
import { hasRole } from '../../userOrg/entities/Session';
import {
    ExperimentationEventNames,
    ExperimentationImpressionEvent,
    FeatureUsedEvent,
} from '../types/ExperimentationEvents';
import { GrowthBook, Experiment, Result, FeatureResult } from '@growthbook/growthbook';
import cookiejs from 'cookiejs';

export type ResultPayload = {
    success: boolean;
    reason?: string;
};

export type DecisionPayload = {
    type: string;
    userId: UUID;
    decisionInfo: {
        flagKey: ExperimentationTestNames;
        enabled: boolean;
    };
};

export interface GrowthBookExperimentationService extends ExperimentationService {
    growthbookClient: GrowthBook<Record<string, any>>;
    onReady(): Promise<boolean>;
}

interface UserContextAttributes {
    url: string;
    REFERRER_origin: string;
    SESSION_isAuthenticated: boolean;
    SESSION_isBroker: boolean;
    SESSION_isTestAccount: boolean;
    SESSION_isAdmin: boolean;
}

interface IdAttributes {
    userId: string | undefined;
    deviceId: string | undefined;
    sessionId: string | undefined;
    HEAP_userId: HeapUserId;
    HEAP_sessionId: HeapSessionId;
    GA_userId: GAUserId;
    localStorage_deviceId: string | undefined;
    cookie_deviceId: string | undefined;
}

@injectable()
export class GrowthBookExperimentationService
    extends BaseExperimentationService
    implements GrowthBookExperimentationService
{
    private growthbookClientReady = false;
    private readyTimeout = 3000;

    constructor(
        @inject(DomainEventBus) private eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
    ) {
        super();

        this.platform = ExperimentationServicePlatforms.GrowthBook;
        this.platformName = 'GrowthBook';

        const attributes = {
            ...this.getIds(),
            url: this.getCurrentLocation().href,
            SESSION_isAdmin: false,
            REFERRER_origin: globalThis.document.referrer,
        } as UserContextAttributes & IdAttributes;

        this.growthbookClient = new GrowthBook({
            apiHost: 'https://cdn.growthbook.io',
            clientKey: getEnvVar('GROWTHBOOK_SDK_KEY'),
            enableDevMode: true,
            attributes,
            onFeatureUsage: (featureKey, result) => {
                this.handleOnGrowthBookFeatureUsed(featureKey, result);
            },
            trackingCallback: (experiment, result) => {
                this.handleOnGrowthBookExperimentationViewed(experiment, result);
            },
        });

        this.growthbookClient.debug = cookiejs.get('_emb_growthbook_debug') === 'true';

        this.onReady().then((success) => {
            if (success) {
                this.growthbookClientOnReady();
            }
        });

        // Handle application URL change
        this.subscribeToLocationChange((url) => {
            this.setContextAttribute('url', url.href);
        });

        //Handle application context change
        this.subscribeToAppContextChange((context) => {
            this.handleAppContextChange(context);
        });
    }

    private growthbookClientOnReady() {
        this.growthbookClientReady = true;
    }

    private handleOnGrowthBookFeatureUsed(featureKey: string, result: FeatureResult<any>): void {
        this.eventBus.publish<FeatureUsedEvent>({
            ...this.getIds(),
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Experimentation',
            name: ExperimentationEventNames.FeatureUsed,
            featureName: featureKey as unknown as ExperimentationTestNames,
            value: result.value,
            raw: result,
        });
    }

    private handleOnGrowthBookExperimentationViewed(
        experiment: Experiment<any>,
        result: Result<any>,
    ): void {
        this.eventBus.publish<ExperimentationImpressionEvent>({
            ...this.getIds(),
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Experimentation',
            name: ExperimentationEventNames.Impression,
            experimentationName: experiment.key as unknown as ExperimentationTestNames,
            assignment: Number.parseInt(result.key),
            raw: result,
        });
    }

    private async handleAppContextChange(appContext: AppContext) {
        const activeSession = appContext.activeSession;

        this.setContextAttribute('SESSION_isAuthenticated', activeSession.isAuthenticated ?? false);
        this.setContextAttribute('SESSION_isBroker', hasRole(activeSession, 'broker') ?? false);
        this.setContextAttribute('SESSION_isAdmin', hasRole(activeSession, 'admin') ?? false);

        if (activeSession.organizationId) {
            const organizationResult = await this.organizationRepository.getOrganization(
                activeSession.organizationId,
            );

            if (isOK(organizationResult)) {
                this.setContextAttribute(
                    'SESSION_isTestAccount',
                    organizationResult.value.isTestOrganization ?? false,
                );
            }
        }
    }

    private setContextAttribute(
        attributeName: keyof UserContextAttributes,
        attributeValue: string | number | boolean,
    ): void {
        this.growthbookClient.setAttributes({
            ...this.growthbookClient.getAttributes(),
            [attributeName]: attributeValue,
        });
    }

    private getIds(): IdAttributes {
        return {
            userId: this.getUserId(),
            deviceId: this.getDeviceId(),
            sessionId: this.getSessionId(),
            HEAP_userId: this.getHeapUserId(),
            HEAP_sessionId: this.getHeapSesssionId(),
            GA_userId: this.getGaCookieId(),
            localStorage_deviceId: this.getLocalStorageDeviceId(),
            cookie_deviceId: this.getCookieDeviceId(),
        };
    }

    private getDeviceId(): string | undefined {
        return this.getCookieDeviceId();
    }

    private getUserId(): string | undefined {
        return this.getCookieDeviceId();
    }

    private getSessionId(): string | undefined {
        return this.getSessionStorageDeviceId();
    }

    public onReady(): Promise<boolean> {
        return this.growthbookClient
            .loadFeatures({ timeout: this.readyTimeout })
            .then(() => true)
            .catch((error) => {
                this.printError(error);
                return false;
            });
    }

    public isReady(): boolean {
        return !!this.growthbookClientReady;
    }

    public isOn(experimentationTestName: ExperimentationTestName) {
        if (!this.isReady()) {
            return false;
        }

        return this.growthbookClient.isOn(experimentationTestName);
    }

    public getFeatureValue(experimentationTestName: ExperimentationTestName, fallback: any) {
        if (!this.isReady()) {
            return null;
        }

        return this.growthbookClient.getFeatureValue(experimentationTestName, fallback);
    }

    public printUserIds() {
        const attributes = this.growthbookClient.getAttributes();

        console.log(
            '%cBelow is a listing of all user attributes potentially used by our Growthbook experimentation service.',
            'color: blue',
        );
        console.log(
            '%cSee https://embroker.atlassian.net/wiki/x/AoCB_wI for more information.',
            'color: blue',
        );

        console.table(attributes);

        console.log(
            "%cCopy the above logs and share with the team. Don't share a screenshot.",
            'color: blue',
        );
    }
}
