import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';

const TechVerticalNaicsCodes = [
    '334413',
    '541715',
    '541519',
    '454110',
    '517919',
    '519190',
    '541490',
    '518210',
    '541714',
    '334418',
    '517312',
    '334220',
    '541513',
    '334310',
    '334111',
    '334419',
    '517410',
    '541713',
    '519130',
    '511210',
    '541512',
    '541690',
    '541511',
] as const;
type TechVerticalValidNaicsCodeKeys = (typeof TechVerticalNaicsCodes)[number];

const ITConsultantsNaicsCodes = ['541512', '541690'] satisfies TechVerticalValidNaicsCodeKeys[];

export const TechVerticalValidNaicsCodes = {
    ...defineValidator<TechVerticalValidNaicsCodeKeys[]>(Joi.array()),
    create(TechVerticalValidNaicsCodeKeys: TechVerticalValidNaicsCodeKeys[]) {
        return TechVerticalValidNaicsCodes.validate(TechVerticalValidNaicsCodeKeys);
    },
    isNaicCodeValid(naicsCode: string | null): boolean {
        return Boolean(
            naicsCode &&
                TechVerticalNaicsCodes.includes(naicsCode as TechVerticalValidNaicsCodeKeys),
        );
    },
    isITConsultant(naicsCode: string | null): boolean {
        return Boolean(naicsCode && ITConsultantsNaicsCodes.includes(naicsCode));
    },
};
