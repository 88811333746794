import React from 'react';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { ModalLayout } from '@app/view/components/ModalLayout.view';

type ESPFiduciaryWarningModalLayoutProps = {
    handlePrimaryCTAClick: () => void;
};

export function ESPFiduciaryWarningModalLayout({
    handlePrimaryCTAClick,
}: ESPFiduciaryWarningModalLayoutProps) {
    return (
        <ModalLayout
            title="Looks like you’re trying to get Fiduciary Liability on its own"
            primaryAction={{
                label: 'Add Another Coverage',
                onClick: () => handlePrimaryCTAClick(),
            }}
        >
            <StackLayout gap="12">
                <Text style="body 1">
                    Unfortunately, you can only get this coverage online if it's packaged with other
                    products.
                </Text>
                <Text style="body 1">
                    To get Fiduciary Liability insurance digitally, simply select at least one other
                    coverage.
                </Text>
            </StackLayout>
        </ModalLayout>
    );
}
