import {
    Button,
    ButtonBar,
    FormLayout,
    SidebarLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { SelfServingCertificateCoverageList } from '../../../types/SelfServingCertificateCoverage';
import { IssueCertificateStepGuidelines } from './IssueCertificateStepGuidelines';

interface IssueCertificateStepProps {
    onIssueCertificate(): void;
    onBack(): void;
    onClick(): void;
    onPreviewCertificate(): void;
    selectedCoverageList: SelfServingCertificateCoverageList;
}

export function IssueCertificateStep({
    onIssueCertificate,
    onBack,
    onClick,
    onPreviewCertificate,
}: IssueCertificateStepProps) {
    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <IssueCertificateStepGuidelines></IssueCertificateStepGuidelines>
            <StackLayout split="-1">
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="12">
                            <Text style={'heading 5'}>You’re almost there!</Text>
                            <StackLayout>
                                <Text>
                                    You can preview your certificate by clicking the button below.
                                    If you're happy with it, you can go ahead and get the
                                    certificate issued.
                                </Text>
                            </StackLayout>
                        </StackLayout>
                        <ButtonBar
                            split={'-2'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack}>Back</TextButton>
                            <Button onClick={onPreviewCertificate} appearance="secondary">
                                Preview the Certificate
                            </Button>
                            <Button onClick={onIssueCertificate}>Issue the Certificate</Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <FormLayout appearance="wide">
                    <SidebarLayout.MobileFooter split="-1">
                        <Text>What does this mean?</Text>
                        <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                            Let us explain
                        </SidebarLayout.Link>
                    </SidebarLayout.MobileFooter>
                    <StatusMessage status="helptext">
                        Not what you had in mind? Please{' '}
                        <TextButton onClick={onClick}>get in touch</TextButton> with our Customer
                        Success Team.
                    </StatusMessage>
                </FormLayout>
            </StackLayout>
        </SidebarLayout>
    );
}
