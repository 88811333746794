import React from 'react';
import {
    StackLayout,
    Text,
    List,
    StatusMessage,
    TextButton,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { CoverageRecommendationCard, RecommendationType } from './CoverageRecommendationCard.view';
import { CoverageIneligibilityReasonTypes } from '../../../types/CoverageEligibility';
import { OnboardingPrefillQuestionnaireData } from '../../../../userOrg/types/OnboardingPrefillQuestionnaireData';

interface CoverageCardProps {
    isSelected: boolean;
    ineligibilityReasons?: CoverageIneligibilityReasonTypes[];
    onboardingQuestionnaire?: OnboardingPrefillQuestionnaireData;
    handleTooltipClick: () => void;
    onCoverageSelectionChange: () => void;
}

export function CyberCoverageRecommendationCard(props: CoverageCardProps) {
    const { isSelected, handleTooltipClick, onCoverageSelectionChange } = props;
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    return (
        <CoverageRecommendationCard
            recommendationType={RecommendationType.none}
            iconName="cyber"
            title="Cyber Insurance"
            className={isMobile ? 'u-1' : 'u-1/4'}
            isSelected={isSelected}
            onCoverageSelectionChange={onCoverageSelectionChange}
            toggleAppearance="button"
        >
            <StackLayout gap="16">
                <StackLayout gap="20">
                    <Text style="body 1">
                        Check Point reports that cyber attacks on law firms increased 13% in 2023.
                        Don’t be a statistic. Cyber insurance covers financial and legal costs
                        following a cybercrime.{' '}
                        <TextButton onClick={handleTooltipClick}>Learn more</TextButton>
                    </Text>
                    <Text style="body 1">Commonly includes coverage for claims of:</Text>
                </StackLayout>
                <StackLayout gap="24">
                    <List>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Phishing scams
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Network/data breaches and privacy liability claims
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Extortion and ransomware events
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Loss of business income
                            </Text>
                        </List.Item>
                    </List>
                    <StatusMessage status="success">
                        Includes complimentary information security training.
                    </StatusMessage>
                </StackLayout>
            </StackLayout>
        </CoverageRecommendationCard>
    );
}

export function LPLCoverageRecommendationCard(props: CoverageCardProps) {
    const { isSelected, handleTooltipClick, onCoverageSelectionChange } = props;
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    return (
        <CoverageRecommendationCard
            recommendationType={RecommendationType.essential}
            iconName="lpl"
            title="Lawyers Professional Liability"
            className={isMobile ? 'u-1' : 'u-1/4'}
            isSelected={isSelected}
            onCoverageSelectionChange={onCoverageSelectionChange}
            toggleAppearance="button"
        >
            <StackLayout gap="16">
                <StackLayout gap="20">
                    <Text style="body 1">
                        Even the most experienced lawyers need to cover their assets. Protect your
                        firm while providing your professional services — anywhere in the world you
                        do business.{' '}
                        <TextButton onClick={handleTooltipClick}>Learn more</TextButton>
                    </Text>
                    <Text style="body 1">Commonly includes coverage for claims of:</Text>
                </StackLayout>
                <StackLayout gap="24">
                    <List>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Negligence or errors
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Actual or alleged misrepresentation
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Conflicts of interest or breach of duty
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Rendering or failing to render professional services
                            </Text>
                        </List.Item>
                    </List>
                    <StackLayout gap="16">
                        <Text style="body 1">This doesn’t include coverage for:</Text>
                        <List>
                            <List.Item icon="close" iconColor="negative-500">
                                <Text style="body 1" as="div">
                                    Phishing scams
                                </Text>
                            </List.Item>
                        </List>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </CoverageRecommendationCard>
    );
}

export function BOPCoverageRecommendationCard(props: CoverageCardProps) {
    const {
        isSelected,
        ineligibilityReasons,
        onboardingQuestionnaire,
        handleTooltipClick,
        onCoverageSelectionChange,
    } = props;
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    if (onboardingQuestionnaire?.working_space_type === 'office_owned') {
        return null;
    }
    const isDisabled = Boolean(ineligibilityReasons && ineligibilityReasons.length);
    const notAvailableInState =
        ineligibilityReasons && ineligibilityReasons.includes('not-available-in-state');

    const cardBody = notAvailableInState
        ? coverageRecommendationCardNotAvailableInStateBody
        : coverageRecommendationCardBOPBody(handleTooltipClick);

    return (
        <CoverageRecommendationCard
            recommendationType={RecommendationType.essential}
            iconName="bop"
            title="Liability + Property Insurance"
            isSelected={isSelected}
            isDisabled={isDisabled}
            onCoverageSelectionChange={onCoverageSelectionChange}
            toggleAppearance="button"
            className={isMobile ? 'u-1' : 'u-1/4'}
        >
            {cardBody}
        </CoverageRecommendationCard>
    );
}

export function WCCoverageRecommendationCard(props: CoverageCardProps) {
    const {
        isSelected,
        onboardingQuestionnaire,
        ineligibilityReasons = [],
        handleTooltipClick,
        onCoverageSelectionChange,
    } = props;
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    const hasEmployees = onboardingQuestionnaire?.has_employees;
    const isMonoState = ineligibilityReasons.includes('monopolistic-state');
    const isNotAvailableInState = ineligibilityReasons.includes('not-available-in-state');
    const confirmedNoEmployees = onboardingQuestionnaire?.has_employees === false; // questionnaire data may be undefined, in this scenario we do not want to prevent a user from toggling the coverage on/off
    const isDisabled = Boolean(ineligibilityReasons.length) || confirmedNoEmployees;

    const noEmployees = onboardingQuestionnaire && onboardingQuestionnaire.has_employees === false;

    let cardBody = coverageRecommendationWCCardBody(handleTooltipClick);
    if (isDisabled) {
        if (noEmployees) {
            cardBody = coverageRecommendationCardWCNoStaffBody;
        }
        if (isNotAvailableInState) {
            cardBody = coverageRecommendationCardNotAvailableInStateBody;
        }
        if (isMonoState) {
            cardBody = coverageRecommendationCardWCMonopolisticStateBody;
        }
    } else if (hasEmployees) {
        if (onboardingQuestionnaire.working_space_type !== 'office_owned') {
            cardBody = coverageRecommendationCardWCOfficeNotOwned(handleTooltipClick);
        }
    }

    const isEssential =
        !isDisabled && onboardingQuestionnaire && onboardingQuestionnaire.has_employees;
    const recommendationType = isEssential ? RecommendationType.essential : RecommendationType.none;

    return (
        <CoverageRecommendationCard
            recommendationType={recommendationType}
            iconName="workers-comp"
            title="Workers Compensation"
            isSelected={isSelected}
            isDisabled={isDisabled}
            onCoverageSelectionChange={onCoverageSelectionChange}
            toggleAppearance="button"
            className={isMobile ? 'u-1' : 'u-1/4'}
        >
            {cardBody}
        </CoverageRecommendationCard>
    );
}

const coverageRecommendationCardBOPBody = (onTitleTooltipClick: () => void) => (
    <StackLayout gap="16">
        <StackLayout gap="20">
            <Text style="body 1">
                Peace of mind for your business. One policy, multiple coverages to protect your
                business, property, and even employees driving vehicles for work.{' '}
                <TextButton onClick={onTitleTooltipClick}>Learn more</TextButton>
            </Text>
            <Text style="body 1">Commonly includes coverage for claims of:</Text>
        </StackLayout>
        <StackLayout gap="32">
            <List>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Legal defense, settlements, medical and other expenses resulting from
                        third-party bodily injury cases
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Theft or damage of your property by a third-party
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Financial losses in case of business interruption
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Hired/Non-Owned Auto Coverage
                    </Text>
                </List.Item>
            </List>
        </StackLayout>
    </StackLayout>
);

const coverageRecommendationCardWCOfficeNotOwned = (onTitleTooltipClick: () => void) => (
    <StackLayout gap="16">
        <StackLayout gap="20">
            <Text style="body 1">
                You’ve got employees — we’ve got your legal back. Subject to state law, workers
                compensation protects your staff and firm after a workplace injury or illness.{' '}
                <TextButton onClick={onTitleTooltipClick}>Learn more</TextButton>
            </Text>
            <Text style="body 1">Commonly includes coverage for claims of:</Text>
        </StackLayout>
        <StackLayout gap="32">
            <List>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Hospital bills and medication
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Physical therapy
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Lost wages
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Legal expenses
                    </Text>
                </List.Item>
            </List>
            <StackLayout gap="16">
                <Text style="body 1">Plus:</Text>
                <List>
                    <List.Item icon="check">
                        <Text style="body 1" as="div">
                            Employers Liability covers claims of employer negligence.
                        </Text>
                    </List.Item>
                </List>
            </StackLayout>
        </StackLayout>
    </StackLayout>
);

const coverageRecommendationCardWCNoStaffBody = (
    <StackLayout gap="12">
        <span />
        <StatusMessage status="helptext">
            Since you don’t have staff, you can skip this for now. As you grow your business, you
            will likely require this coverage.
        </StatusMessage>
    </StackLayout>
);

const coverageRecommendationCardWCMonopolisticStateBody = (
    <StackLayout gap="12">
        <span />
        <StatusMessage status="helptext">
            In your state, you can only get Workers Compensation through the state-administered
            fund.
            <TextButton
                href="https://www.nfib.com/content/legal-compliance/legal/workers-compensation-laws-state-by-state-comparison-57181/"
                target="_blank"
                textWrap
            >
                View State Requirements
            </TextButton>
        </StatusMessage>
    </StackLayout>
);

const coverageRecommendationCardNotAvailableInStateBody = (
    <StackLayout gap="12">
        <span />
        <StatusMessage status="helptext">
            This coverage is not available online in your state.
        </StatusMessage>
    </StackLayout>
);

const coverageRecommendationWCCardBody = (onTitleTooltipClick: () => void) => (
    <StackLayout gap="16">
        <StackLayout gap="20">
            <Text style="body 1">
                You’ve got employees — we’ve got your legal back. Subject to state law, it protects
                your staff and firm after a workplace injury or illness, and claims of employer
                negligence. <TextButton onClick={onTitleTooltipClick}>Learn more</TextButton>
            </Text>
            <Text style="body 1">Commonly includes coverage for claims of:</Text>
        </StackLayout>
        <StackLayout gap="24">
            <List>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Hospital bills and medication
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Physical therapy
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Lost wages
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Death benefits
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Negligence claims by injured employees
                    </Text>
                </List.Item>
            </List>
        </StackLayout>
    </StackLayout>
);
