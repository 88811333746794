import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable, StatusMessage } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { PCoMLQuote, PCoMLQuoteDetails } from '../../entities/PCoMLQuote';
import { CrimeQuoteDetails } from '../../../crime/types/CrimeQuoteDetails';

export interface QuoteBreakdownProps {
    quote: PCoMLQuote;
    activePageName: string;
}

function buildWarningMessage(quote: PCoMLQuote, activePage: string, isBroker: boolean) {
    if (!isBroker) {
        return null;
    }
    let warningText = ``;
    if (quote.applicationInfo?.existingCoverageReferral && activePage === 'signature') {
        warningText = `Thank you for your submission. We’re reviewing the uploaded loss runs to ensure the client meets Embroker’s underwriting criteria.`;
    } else if (quote.status === 'referred') {
        warningText = `For limits higher than $3,000,000, Embroker must review the clients application prior to releasing the quote. Please click 'Request higher limit' below or choose a lower limit to enable binding the policy now.`;
    }
    return warningText && <StatusMessage status="warning">{warningText}</StatusMessage>;
}

export function getStateFromPCoMLQuote(quote: PCoMLQuote) {
    return quote.applicationInfo?.userInfo.usaState;
}

export function sum(values: Money[]): Money {
    const sumResult = Money.sum(values);
    if (isOK(sumResult)) {
        return sumResult.value;
    }
    container
        .get<Logger>(Log)
        .error('Unexpected error while calculating the sum', sumResult.errors);

    return USD(Number.NaN);
}

export function getFees(details: PCoMLQuoteDetails | CrimeQuoteDetails) {
    const mwuaFee = details.mwuaFee;
    if (!mwuaFee) {
        return { fees: details.fees, mwuaFee: USD(0) };
    }
    const fees = sum([details.fees, USD(-mwuaFee.amount)]);
    return { fees: fees, mwuaFee: mwuaFee };
}

export function QuoteBreakdown({ quote, activePageName }: QuoteBreakdownProps) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const state = getStateFromPCoMLQuote(quote);
    const isKentucky = state === 'KY';
    const isMississippi = state === 'MS';
    const dnoCoveragePart = quote.details.dno.premium;
    const eplCoveragePart = quote.details.epl.premium;
    const totalCoverages = sum([dnoCoveragePart, eplCoveragePart]);
    const showAnnualTechFee = !isBroker && (quote.annualTechnologyFee?.amount ?? 0) > 0;
    const sociusEndorsementPremium = quote.details.sociusEndorsementPremium || USD(0);
    const sociusEndorsementPremiumVisible = sociusEndorsementPremium.amount > 0;
    const showTaxesAndFees = !isBroker;
    const { fees, mwuaFee } = getFees(quote.details);
    const subtotal = sum([totalCoverages, sociusEndorsementPremium]);

    return (
        <InvoiceTable>
            {buildWarningMessage(quote, activePageName, isBroker)}
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                {quote.options.dno.isSelected ? (
                    <InvoiceTable.Item
                        title={AppTypeLabelMap['AppTypeCodeListManualDirectorsAndOfficers']}
                    >
                        <MoneyDisplay value={dnoCoveragePart} />
                    </InvoiceTable.Item>
                ) : null}
                {quote.options.epl.isSelected ? (
                    <InvoiceTable.Item
                        title={AppTypeLabelMap['AppTypeCodeListManualEmploymentPractices']}
                    >
                        <MoneyDisplay value={eplCoveragePart} />
                    </InvoiceTable.Item>
                ) : null}
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Total coverages">
                    <MoneyDisplay value={totalCoverages} />
                </InvoiceTable.Subtotal>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Subtotal">
                    <MoneyDisplay value={subtotal} />
                </InvoiceTable.Subtotal>
                {showTaxesAndFees && (
                    <InvoiceTable.Item
                        title={isKentucky ? 'Surplus Lines Tax' : 'Taxes'}
                        tooltip={
                            !isKentucky &&
                            'Surplus Line Tax is required on all surplus line insurance transactions and vary by state.'
                        }
                    >
                        <MoneyDisplay value={quote.details.taxes} />
                    </InvoiceTable.Item>
                )}
                {!isBroker && isMississippi ? (
                    <InvoiceTable.Item title="MWUA Fee">
                        <MoneyDisplay value={mwuaFee} />
                    </InvoiceTable.Item>
                ) : null}
                {showTaxesAndFees && (
                    <InvoiceTable.Item
                        title={
                            isKentucky
                                ? 'Stamping Fee'
                                : 'Fees' +
                                  '(excluding transaction fees based on your choice of payment)'
                        }
                        tooltip={
                            !isKentucky &&
                            'Often referred to as “Stamping Fees”, Surplus Lines Fees are only required by certain states. In the applicable states Surplus Line Fees are charged on all insurance transactions. Embroker does not charge our clients any fees.'
                        }
                    >
                        <MoneyDisplay value={fees} />
                    </InvoiceTable.Item>
                )}
                {isKentucky && (
                    <InvoiceTable.Item title="Kentucky-Specific taxes and fees">
                        See note
                    </InvoiceTable.Item>
                )}
                {isBroker && (
                    <InvoiceTable.Item title="Embroker Access Fee" data-e2e="access-fee">
                        <MoneyDisplay value={quote.details.policyAdministrationFee ?? USD(0)} />
                    </InvoiceTable.Item>
                )}
                {showTaxesAndFees && showAnnualTechFee && (
                    <InvoiceTable.Item
                        title="Annual technology fee"
                        tooltip="The Annual technology fee is applied once a year with the first policy purchase of the year."
                    >
                        <MoneyDisplay value={quote.annualTechnologyFee ?? USD(0)} />
                    </InvoiceTable.Item>
                )}
                {showTaxesAndFees || isKentucky ? (
                    <InvoiceTable.Total
                        title={isKentucky ? 'Estimated Total: ' : 'Total: '}
                        data-e2e="total-amount"
                    >
                        {isKentucky ? 'Forthcoming' : <MoneyDisplay value={quote.totalPayable} />}
                    </InvoiceTable.Total>
                ) : null}
            </InvoiceTable.Section>
            {sociusEndorsementPremiumVisible ? (
                <InvoiceTable.Section>
                    <StatusMessage status="helptext">
                        <React.Fragment>
                            Additional premium of{' '}
                            <MoneyDisplay value={sociusEndorsementPremium} fractionDigits={0} /> was
                            applied for inclusion of the Socius Amendatory Endorsement.
                        </React.Fragment>
                    </StatusMessage>
                </InvoiceTable.Section>
            ) : undefined}
            {isKentucky && (
                <StatusMessage status="helptext">
                    Various municipalities (towns, counties, etc.) in the state of Kentucky are
                    permitted to charge taxes on insurance premium per state law. These tax amounts
                    are typically between 2-16% of premium and are in addition to any other
                    applicable taxes, fees or surcharges. Your policy and invoice will have these
                    taxes included.
                </StatusMessage>
            )}
        </InvoiceTable>
    );
}
