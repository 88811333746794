import React, { useEffect } from 'react';
import { OnboardingPageProps, Page } from './OnboardingFlowWrapper';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { LawVerticalRefinmentQuestion } from './LawVerticalRefinmentQuestion';
import { StaffDetailsPage } from './StaffDetailsPage';

const lawVerticalRefinmentPage: Page<OnboardingPageProps> = {
    name: 'Law Vertical Onboarding',
    component: LawVerticalRefinmentQuestion,
    questionsKeys: [],
};

const staffDetailsPage: Page<OnboardingPageProps> = {
    name: 'Staff Details Onboarding',
    component: StaffDetailsPage,
    questionsKeys: [],
};

export const industryRefinmentComponentMap: {
    [key: string]: Page<OnboardingPageProps>;
} = {
    LawFirm: lawVerticalRefinmentPage,
    TechCompanies: staffDetailsPage,
} as const;

export const IndustryRefinementPage = (props: OnboardingPageProps) => {
    const { questionnaireData, onCompleteOnboardingStep } = props;

    const vertical = NAICS_CODE_TO_VERTICAL[questionnaireData?.onb_naics_code || ''];
    const Component = industryRefinmentComponentMap[vertical].component;

    useEffect(() => {
        if (!Component) {
            onCompleteOnboardingStep();
        }
    }, [Component, onCompleteOnboardingStep]);

    return <Component {...props} />;
};
