import React from 'react';
import {
    ColumnLayout,
    Icon,
    IconColor,
    IconName,
    StackLayout,
    Text,
    useResponsive,
} from '@embroker/ui-toolkit/v2';

interface InformationWithIconProps {
    iconColor: IconColor;
    iconName: IconName;
    title: string | React.ReactNode;
    description: string;
}

export const InformationWithIcon = ({
    iconColor,
    title,
    description,
    iconName,
}: InformationWithIconProps) => {
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    return (
        <ColumnLayout gap={isMobile ? '4' : '16'} center className="u-1/1">
            <Icon name={iconName} color={iconColor} size="large" />
            <StackLayout gap="4">
                {typeof title === 'string' ? <Text style="heading 4">{title}</Text> : title}
                <Text nowrap>{description}</Text>
            </StackLayout>
        </ColumnLayout>
    );
};
