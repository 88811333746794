import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

type PolicyCardClickedEventName =
    | 'CardPayNowClicked'
    | 'CardViewPolicyClicked'
    | 'CardModifyPolicyClicked';

export interface CardPolicyClicked extends DomainEvent {
    readonly origin: 'Policy';
    readonly name: PolicyCardClickedEventName;
    readonly policyNumber: string;
    readonly isRenewal: boolean;
}

export interface PolicyCardClickedEvent extends DomainEvent {
    name: 'PolicyCardClickedEvent';
    eventName: PolicyCardClickedEventName;
    policyData: any;
}

interface ArgsProps {
    eventName: PolicyCardClickedEventName;
    policyData: any;
}

export interface PublishPolicyCardClickedEvent extends UseCase {
    execute(request: ArgsProps): AsyncResult<void>;
}

@injectable()
class PublishPolicyCardClickedEventUseCase
    extends UseCase
    implements PublishPolicyCardClickedEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('shopping/PublishPolicyCardClickedEventUseCase');

    /**
     * Constructor for PublishPolicyCardClickedEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishPolicyCardClickedEvent use case.
     */
    public async execute({ eventName, policyData }: ArgsProps): AsyncResult<void> {
        const cardPolicyClickedEvent: CardPolicyClicked = {
            origin: 'Policy',
            name: eventName,
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            policyNumber: policyData.policyNumber,
            isRenewal: policyData.bookingType === 'PolicyBookingTypeRenewal',
        };

        await this.eventBus.publish(cardPolicyClickedEvent);

        return Success();
    }
}

export const PublishPolicyCardClickedEvent: UseCaseClass<PublishPolicyCardClickedEvent> =
    PublishPolicyCardClickedEventUseCase;
