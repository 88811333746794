import { ValidationTypeProps } from '@app/view/components/DataDrivenForm/types/fieldValidationFactory';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { AnswerType, QuestionerQuestion, QuestionType } from './QuestionerQuestionType';

export type IntraValidationTypeKeys = keyof IntraValidation;
export interface IntraValidation {
    required?: boolean;
    min_length?: number;
    max_length?: number;
    min_value?: number;
    max_value?: number;
    regex?: string;
    message?: string;
}
export const IntraValidationSchema = Joi.object({
    required: Joi.boolean().optional(),
    min_length: Joi.number().integer().optional(),
    max_length: Joi.number().integer().optional(),
    min_value: Joi.number().integer().optional(),
    max_value: Joi.number().integer().optional(),
    regex: Joi.string().optional(),
    message: Joi.string().optional().allow(''),
});

export type ValidationTypeKeys = Exclude<keyof ValidationTypeProps, 'conditional'>;
const ANSWER_TYPE_FORM_DATA_MAP: Record<AnswerType, ValidationTypeKeys | 'object'> = {
    TEXT: 'string',
    NUMBER: 'number',
    DATE: 'number',
    BOOLEAN: 'boolean',
    INTEGER: 'number',
    ADDRESS: 'object',
    FUNDING_ROUND: 'object',
    REVENUE: 'object',
    CURRENCY: 'currency',
    DECIMAL: 'number',
};

export function getIntraValidations(
    question: QuestionerQuestion,
): ValidationTypeProps[] | undefined {
    const questionIntraValidations = questionTypeIntraValidations[question.type] || {};
    const intraValidation = { ...questionIntraValidations, ...question.intra_validation };
    const formDataType = ANSWER_TYPE_FORM_DATA_MAP[question.answer_type];

    switch (formDataType) {
        case 'string':
            return getStringIntraValidation(intraValidation);
        case 'number':
            return getNumberIntraValidation(intraValidation);
        case 'currency':
            return getCurrencyIntraValidation(intraValidation);
    }
    return;
}

export function getStringIntraValidation(
    intraValidation: IntraValidation,
): ValidationTypeProps[] | undefined {
    const stringValidations: ValidationTypeProps[] = [];
    const { min_length, max_length, regex, message } = intraValidation;
    if (min_length !== undefined) {
        stringValidations.push({ string: { hasLengthGreaterThan: min_length - 1 } });
    }
    if (max_length !== undefined) {
        stringValidations.push({ string: { hasLengthLessThan: max_length + 1 } });
    }
    if (regex && isValidRegexString(regex)) {
        stringValidations.push({
            string: { regex: { pattern: regex, message } },
        });
    }
    if (stringValidations.length) {
        return stringValidations;
    }
}

export function getNumberIntraValidation(
    intraValidation: IntraValidation,
): ValidationTypeProps[] | undefined {
    const numberValidations: ValidationTypeProps[] = [];

    const { max_value, min_value } = intraValidation;
    if (min_value !== undefined) {
        numberValidations.push({ number: { greaterThan: min_value } });
    }
    if (max_value !== undefined) {
        numberValidations.push({ number: { lessThan: max_value + 1 } });
    }
    if (numberValidations.length) {
        return numberValidations;
    }
}

export function isValidRegexString(pattern: string): boolean {
    try {
        new RegExp(pattern);
        return true;
    } catch (error) {
        return false;
    }
}

export function getCurrencyIntraValidation(
    intraValidation: IntraValidation,
): ValidationTypeProps[] | undefined {
    const currencyValidations: ValidationTypeProps[] = [];

    const { max_value, min_value } = intraValidation;
    if (min_value !== undefined) {
        currencyValidations.push({ currency: { greaterThan: min_value } });
    }
    if (max_value !== undefined) {
        currencyValidations.push({ currency: { lessThan: max_value + 1 } });
    }
    if (currencyValidations.length) {
        return currencyValidations;
    }
}

export const questionTypeIntraValidations: Partial<Record<QuestionType, IntraValidation>> = {
    TELEPHONE_NUMBER: {
        regex: '^[2-9][0-9]{9}$',
        message: 'Must be a 10 digit phone number',
    },
    WEBSITE: {
        regex: '^\\S*\\.\\S{2,}$', // make sure there is a dot and at least 2 characters after the dot and no spaces
        message: 'Must be a valid URL',
    },
    EMAIL: {
        regex: "^(?!\\.)(?!.*\\.\\.)([A-Za-z0-9'_+.-]+)@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
        message: 'Must be a valid email',
    },
};
