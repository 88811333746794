import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingDetails } from '../types/UserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { UserOnboardingRepository } from '../repositories/UserOnboardingRepository';

export interface GetUnauthenticatedUserOnboardingDetails extends UseCase {
    execute(): AsyncResult<Nullable<UserOnboardingDetails>, never>;
}

@injectable()
export class GetUnauthenticatedUserOnboardingDetailsUseCase
    extends UseCase
    implements GetUnauthenticatedUserOnboardingDetails
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetUnauthenticatedUserOnboardingDetails');
    /**
     * Constructor for GetUnauthenticatedUserOnboardingDetails use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is session repository used to update UserOnboardingDetails
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetUnauthenticatedUserOnboardingDetails use case
     * @returns Nothing if execution was successful
     */
    public async execute(): AsyncResult<Nullable<UserOnboardingDetails>, never> {
        const result = await this.userOnboardingRepository.getUnauthorizedOnboardingSignUpData();

        if (isOK(result)) {
            return Success(result.value);
        }

        return Success(null);
    }
}

export const GetUnauthenticatedUserOnboardingDetails: UseCaseClass<GetUnauthenticatedUserOnboardingDetails> =
    GetUnauthenticatedUserOnboardingDetailsUseCase;
