import React from 'react';
import { ColumnLayout, Text, Image } from '@embroker/ui-toolkit/v2';
import { Banner } from '@embroker/ui-toolkit/deprecated';

interface TechVerticalProductSelectionPageBannerProps {
    className?: string;
    hideSecurityScorecardText?: boolean;
}

export function TechVerticalProductSelectionPageBanner({
    className,
    hideSecurityScorecardText,
}: TechVerticalProductSelectionPageBannerProps) {
    return (
        <Banner className={className}>
            <ColumnLayout gap="24" responsive={{ screenWidth: { smallerThan: 'large-tablet' } }}>
                <Text style="body 1">
                    {!hideSecurityScorecardText && (
                        <>
                            Unlock <strong>up to 10% off Tech E&O</strong> through our partnership
                            with SecurityScorecard.{' '}
                        </>
                    )}
                    Submit your application to get your cybersecurity score — plus a{' '}
                    <strong>free cyber risk report</strong> with tips to reduce your business
                    risks.*
                </Text>
                <div>
                    <Image width={134} height={35} name="security-scoreboard" />
                </div>
            </ColumnLayout>
        </Banner>
    );
}
