import { CompanyDimension, CoverageRisk } from '@app/public/types/enums';
import { RiskProfile } from '@app/public/types/RiskProfile';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

type RiskProfileDisplay = Record<CoverageRisk, boolean>;

export const RiskProfileDisplay = {
    ...defineValidator<RiskProfileDisplay>({
        'technology-services': Joi.boolean(),
        cybersecurity: Joi.boolean(),
        'directors-and-officers-liability': Joi.boolean(),
        'employment-practices': Joi.boolean(),
        'employee-benefits-and-fiduciary-liability': Joi.boolean(),
        'general-liability': Joi.boolean(),
        'general-liability-property': Joi.boolean(),
        'property-damage': Joi.boolean(),
        'business-interruption': Joi.boolean(),
        'workers-compensation': Joi.boolean(),
        'product-liability': Joi.boolean(),
        'international-liability-and-travel': Joi.boolean(),
    }),
    create(riskProfileDisplay: RiskProfileDisplay) {
        return RiskProfileDisplay.validate(riskProfileDisplay);
    },
    getRiskDisplayFromRiskProfile(riskProfile: RiskProfile): RiskProfileDisplay {
        const validationCheck = RiskProfile.validate(riskProfile);

        if (isErr(validationCheck)) {
            return {
                'technology-services': false,
                cybersecurity: false,
                'directors-and-officers-liability': false,
                'general-liability': false,
                'general-liability-property': false,
                'property-damage': false,
                'business-interruption': false,
                'employment-practices': false,
                'workers-compensation': false,
                'product-liability': false,
                'international-liability-and-travel': false,
                'employee-benefits-and-fiduciary-liability': false,
            };
        }

        const isNaicsCodeValid = RiskProfile.isValidTechnologyNaics(riskProfile.naics);

        return {
            'technology-services': isNaicsCodeValid && riskProfile.technologyServicesOrProducts,
            cybersecurity: isNaicsCodeValid && riskProfile.technologyServicesOrProducts,
            'directors-and-officers-liability': RiskProfile.isFunded(riskProfile),
            'general-liability':
                riskProfile.companyDimensions.includes(CompanyDimension.AllEmployeesRemoteWork) &&
                !riskProfile.companyDimensions.includes(CompanyDimension.OfficeLocationRentOrOwn),
            'general-liability-property': riskProfile.companyDimensions.includes(
                CompanyDimension.OfficeLocationRentOrOwn,
            ),
            'property-damage': riskProfile.companyDimensions.includes(
                CompanyDimension.OfficeLocationRentOrOwn,
            ),
            'business-interruption': riskProfile.companyDimensions.includes(
                CompanyDimension.OfficeLocationRentOrOwn,
            ),
            'employment-practices': riskProfile.numberOfEmployees >= 1,
            'workers-compensation': riskProfile.numberOfEmployees >= 1,
            'product-liability': riskProfile.companyDimensions.includes(
                CompanyDimension.PhysicalProducts,
            ),
            'international-liability-and-travel': riskProfile.companyDimensions.includes(
                CompanyDimension.InternationalOperations,
            ),
            'employee-benefits-and-fiduciary-liability': riskProfile.companyDimensions.includes(
                CompanyDimension.EmployeeBenefitPlans,
            ),
        };
    },
};
