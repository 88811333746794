import { injectable, inject } from '@embroker/shotwell/core/di';
import { Nullable } from '@embroker/shotwell/core/types';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { BaseExperimentationService } from './BaseExperimentationService';
import { ExperimentationService } from '.';
import { getEnvVar } from '../../env';
import { AppContext } from '../../view/AppContext';
import { ExperimentationTestNames, ExperimentationServicePlatforms } from '../types/enums';
import { ExperimentationTest } from '../types/ExperimentationTest';
import { ExperimentationTestName } from '../types/ExperimentationTestName';
import { hasRole } from '../../userOrg/entities/Session';
import {
    ExperimentationEventNames,
    ExperimentationImpressionEvent,
    FeatureUsedEvent,
} from '../types/ExperimentationEvents';
import { GrowthBook, Experiment, Result, FeatureResult } from '@growthbook/growthbook';
import cookiejs from 'cookiejs';

export type ResultPayload = {
    success: boolean;
    reason?: string;
};

export type DecisionPayload = {
    type: string;
    userId: UUID;
    decisionInfo: {
        flagKey: ExperimentationTestNames;
        enabled: boolean;
    };
};

export interface LegacyGrowthBookExperimentationService extends ExperimentationService {
    growthbookClient: GrowthBook<Record<string, any>>;
    onReady(): Promise<boolean>;
}

export interface UserContextAttributes {
    REFERRER_origin: string;
    REFERRER_marketingReferrerPath: string;
    SESSION_isAuthenticated: boolean;
    SESSION_isBroker: boolean;
}

@injectable()
export class LegacyGrowthBookExperimentationService
    extends BaseExperimentationService
    implements LegacyGrowthBookExperimentationService
{
    public growthbookClient: GrowthBook<Record<string, any>>;
    private growthbookClientReady = false;
    private readyTimeout = 3000;

    constructor(@inject(DomainEventBus) private eventBus: DomainEventBus) {
        super();

        this.platform = ExperimentationServicePlatforms.GrowthBook;
        this.platformName = 'GrowthBook';

        this.growthbookClient = new GrowthBook({
            apiHost: 'https://cdn.growthbook.io',
            clientKey: getEnvVar('GROWTHBOOK_SDK_KEY'),
            enableDevMode: false,
            attributes: {
                id: this.getUserId(),
                deviceId: this.getUserId(),
                url: this.getCurrentLocation().href,
                REFERRER_origin: globalThis.document.referrer,
                REFERRER_marketingReferrerPath: decodeURIComponent(
                    this.getCurrentLocation().searchParams.get('ph') ?? '',
                ),
            },
            onFeatureUsage: (featureKey, result) => {
                this.handleOnGrowthBookFeatureUsed(featureKey, result);
            },
            trackingCallback: (experiment, result) => {
                this.handleOnGrowthBookExperimentationViewed(experiment, result);
            },
        });

        this.growthbookClient.debug = cookiejs.get('_emb_growthbook_debug') === 'true';

        this.onReady().then((success) => {
            if (success) {
                this.growthbookClientOnReady();
            }
        });

        // Handle application URL change
        this.subscribeToLocationChange((url) => {
            this.setContextAttribute('url', url.href);
        });

        //Handle application context change
        this.subscribeToAppContextChange((context) => {
            this.handleAppContextChange(context);
        });
    }

    private growthbookClientOnReady() {
        this.growthbookClientReady = true;
    }

    private handleOnGrowthBookFeatureUsed(featureKey: string, result: FeatureResult<any>): void {
        const { deviceId } = this.growthbookClient.getAttributes();

        this.eventBus.publish<FeatureUsedEvent>({
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Experimentation',
            name: ExperimentationEventNames.FeatureUsed,
            deviceId: deviceId,
            featureName: featureKey as unknown as ExperimentationTestNames,
            value: result.value,
            raw: result,
            // Legacy compatibility backfill
            sessionId: '',
            userId: '',
            HEAP_sessionId: undefined,
            HEAP_userId: undefined,
            GA_userId: undefined,
            localStorage_deviceId: undefined,
            cookie_deviceId: undefined,
        });
    }

    private handleOnGrowthBookExperimentationViewed(
        experiment: Experiment<any>,
        result: Result<any>,
    ): void {
        const { deviceId } = this.growthbookClient.getAttributes();

        this.eventBus.publish<ExperimentationImpressionEvent>({
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Experimentation',
            name: ExperimentationEventNames.Impression,
            deviceId: deviceId,
            experimentationName: experiment.key as unknown as ExperimentationTestNames,
            assignment: Number.parseInt(result.key),
            // Legacy compatibility backfill
            sessionId: '',
            userId: '',
            HEAP_sessionId: undefined,
            HEAP_userId: undefined,
            GA_userId: undefined,
            localStorage_deviceId: undefined,
            cookie_deviceId: undefined,
        });
    }

    private handleAppContextChange(appContext: AppContext) {
        const activeSession = appContext.activeSession;

        this.setContextAttribute('SESSION_isAuthenticated', activeSession.isAuthenticated ?? false);
        this.setContextAttribute('SESSION_isBroker', hasRole(activeSession, 'broker') ?? false);
    }

    private setContextAttribute(
        attributeName: string,
        attributeValue: string | number | boolean,
    ): void {
        this.growthbookClient.setAttributes({
            ...this.growthbookClient.getAttributes(),
            [attributeName]: attributeValue,
        });
    }

    private getUserId(): string | undefined {
        return this.getGaCookieId() || this.getLocalStorageDeviceId();
    }

    public onReady(): Promise<boolean> {
        return this.growthbookClient
            .loadFeatures({ timeout: this.readyTimeout })
            .then(() => true)
            .catch((error) => {
                this.printError(error);
                return false;
            });
    }

    public isReady(): boolean {
        return !!this.growthbookClientReady;
    }

    public getExperimentationTest(
        experimentationTestName: ExperimentationTestName,
    ): Nullable<ExperimentationTest> {
        if (!this.isReady()) {
            return null;
        }

        const decision = this.growthbookClient.isOn(experimentationTestName);

        if (decision === undefined) return null;

        const experimentationTestResult = ExperimentationTest.create({
            name: experimentationTestName,
            assignment: decision ? 1 : 0,
            organizationId: this.getUserId() || '',
        });

        if (isErr(experimentationTestResult)) {
            return null;
        }

        const growthbookExperimentationTest = experimentationTestResult.value;

        return growthbookExperimentationTest;
    }

    public getFeatureValue(experimentationTestName: ExperimentationTestName, fallback: any) {
        return this.growthbookClient.getFeatureValue(experimentationTestName, fallback);
    }
}
