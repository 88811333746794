// **Register active experiment tests here.**
// Note: Be sure to activate test on the abtests service prior to FE deployment,
// 		 or else all accounts will be be auto-assigned with a default test assignment of 0.
export const ExperimentationTestNames = [
    'document-ingestion',
    'nb-rv-no-app-in-progress-or-quote',
    'nb-rv-single-expired-quote',
    'nb-rv-single-app-in-progress',
    'nb-rv-expired-app-expired-quote',
    'quote-comparison',
    'tech-vertical-one-by-embroker',
    'law-vertical-product-page-2-vs-4-products',
    'local-storage-check',
    'tech-vertical-product-page-unselect',
    'unauthenticated-signup-onboarding',
    'security-scorecard-credit-tech-vertical',
] as const;

export type ExperimentationTestNames = typeof ExperimentationTestNames;

export enum ExperimentationServicePlatforms {
    GrowthBook = 'growthbook',
}
