import { BundlePayment, Payment, SinglePayment } from '@app/payments/types/Payment';
import {
    CardLayout,
    CheckBoxCard,
    IconName,
    MutuallyExclusiveProps,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { InvoiceListItemBundle } from './InvoiceListItemBundle.view';
import { InvoiceListItemSingle } from './InvoiceListItemSingle.view';

export const coverageIconMap = new Map<string, IconName>([
    ['BOP', 'user-shield'],
    ['Business Owners Policy', 'user-shield'],
    ['Commercial Auto', 'car'],
    ['Crime', 'cage'],
    ['Cyber Liability and Data Breach', 'thief'],
    ['Cyber', 'thief'],
    ['Directors and Officers', 'meeting'],
    ['Employment Practices Liability', 'suitcase'],
    ['ESP', 'rocket'],
    ['Excess Tech E&O/Cyber', 'excess'],
    ['Fiduciary', 'handshake'],
    ['General Liability', 'shield-in-circle'],
    ['Homeowners Association', 'houses'],
    ['International Package', 'plane'],
    ['Law Bundle', 'law'],
    ['Law Firm Program', 'law'],
    ['Law Firm Package', 'law'],
    ['Lawyers Professional', 'law'],
    ['Management Liability', 'suitcase'],
    ['Product Liability', 'pen'],
    ['Professional Liability / Errors and Omissions', 'pen'],
    ['Professional Liability', 'pen'],
    ['PCoML', 'pen'],
    ['Property', 'building'],
    ['Tax Preparers Package', 'tax-preparers'],
    ['Tech Professional/Cyber Package', 'thief'],
    ['Umbrella', 'umbrella'],
    ['Venture Capital Asset Protection', 'chart'],
    ['Workers Compensation', 'worker'],
]);

interface InvoiceListItem {
    invoice: Payment;
    active: boolean;
    readonly: boolean;
    cardType: 'check-box-card' | 'card-layout';
    dataE2e?: string;
    onToggleActive(): void;
    onViewDetailsClicked(): void;
}

function parsePayment(
    invoice: Payment,
): MutuallyExclusiveProps<{ bundlePayment: BundlePayment; singlePayment: SinglePayment }> {
    if ('invoiceList' in invoice) {
        if (invoice.invoiceList.length > 1) {
            return { bundlePayment: invoice as BundlePayment };
        }
        // If payment is a bundle with only one invoice, treat it as a single payment
        return { singlePayment: invoice.invoiceList[0] as SinglePayment };
    }
    return { singlePayment: invoice as SinglePayment };
}

export function InvoiceListItem(props: InvoiceListItem) {
    const { invoice, readonly, active, cardType = 'check-box-card', onToggleActive } = props;

    let cardBody: JSX.Element;
    const { bundlePayment, singlePayment } = parsePayment(invoice);

    if (bundlePayment) {
        cardBody = <InvoiceListItemBundle {...props} invoice={bundlePayment} />;
    } else {
        cardBody = <InvoiceListItemSingle {...props} invoice={singlePayment} />;
    }

    return cardType === 'card-layout' ? (
        <CardLayout>
            <CardLayout.Body>{cardBody}</CardLayout.Body>
        </CardLayout>
    ) : (
        <CheckBoxCard readOnly={readonly} checked={active} onChange={onToggleActive}>
            {cardBody}
        </CheckBoxCard>
    );
}
