import React, { useEffect, useState } from 'react';
import { NaicsConfirmation } from './NaicsConfirmation';
import { PrimaryAddress } from './PrimaryAddress';
import { NaicsCodeRefinement } from './NaicsCodeRefinement';
import {
    Header,
    Immutable,
    ReactProps,
    Image,
    usePageLayout,
    PageLayout,
} from '@embroker/ui-toolkit/v2';
import { OnboardingPrefillQuestionnaireData } from '../../../types/OnboardingPrefillQuestionnaireData';
import { useUserOnboardingNavigation } from '../../hooks/useUserOnboardingNavigation';
import { UserOnboardingStepType } from '../../../types/UserOnboardingDetails';
import { IndustryRefinementPage } from './IndustryRefinementPage';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Organization } from '@app/userOrg/entities/Organization';
import { onboardingStepUrlMap, USER_ONBOARDING_BASE_ROUTE } from '../../routes/onboardingRoutes';
import { VettingRefinement } from './VettingRefinement';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetOnboardingQuestionnaireDataFromOPTIMAL } from '@app/userOrg/useCases/GetOnboardingQuestionnaireDataFromOPTIMAL';

type DisplayPrefilledBanner = '' | 'crunchbase' | 'prefilled';

export interface OnboardingPageProps extends ReactProps<'div'> {
    onCompleteOnboardingStep: (questionnaireData?: OnboardingPrefillQuestionnaireData) => void;
    onBackClicked?: () => void;
    questionnaireData?: OnboardingPrefillQuestionnaireData;
    displayPrefilledBanner: DisplayPrefilledBanner;
    crunchbaseLink?: string;
}

const primaryAddressPage: Page<OnboardingPageProps> = {
    name: 'Primary Address',
    component: PrimaryAddress,
    questionsKeys: ['main_business_location'],
};

const naicsConfirmationPage: Page<OnboardingPageProps> = {
    name: 'Naics Confirmation',
    component: NaicsConfirmation,
    questionsKeys: ['onb_naics_code', 'onb_area_of_focus'],
};

const naicsCodeRefinementPage: Page<OnboardingPageProps> = {
    name: 'Naics Code Refinement',
    component: NaicsCodeRefinement,
    questionsKeys: ['onb_naics_code'],
};
const industryRefinementPage: Page<OnboardingPageProps> = {
    name: 'Industry Refinement Page',
    component: IndustryRefinementPage,
    questionsKeys: ['onb_naics_code'],
};
const vettingRefinementPage: Page<OnboardingPageProps> = {
    name: 'Vetting Refinement Page',
    component: VettingRefinement,
    questionsKeys: [],
};

export interface Page<OnboardingPageProps> {
    name: string;
    component: React.FC<OnboardingPageProps>;
    questionsKeys: (keyof OnboardingPrefillQuestionnaireData)[];
}

export const onboardingStepComponentMap: {
    [key in NonNullable<UserOnboardingStepType>]: Page<OnboardingPageProps>;
} = {
    businessLocation: primaryAddressPage,
    naicsConfirmation: naicsConfirmationPage,
    naicsRefinement: naicsCodeRefinementPage,
    industryRefinement: industryRefinementPage,
    vettingRefinement: vettingRefinementPage,
} as const;

export interface OnboardingFlowWrapperProps {
    questionnaireData?: OnboardingPrefillQuestionnaireData;
    organization?: Immutable<EntityProps<Organization>>;
    userOnboardingStepType: NonNullable<UserOnboardingStepType>;
}
export function OnboardingFlowWrapper(props: OnboardingFlowWrapperProps) {
    // We want to keep users in the onboarding flow, so making the header non-naviagable
    const [isFirstPrefilledStep, setIsFirstPrefilledStep] = useState(false);
    const [isFirstFromCrunchbase, setIsFirstFromCrunchbase] = useState(false);
    const [displayPrefilledBanner, setDisplayPrefilledBanner] = useState<{
        step: UserOnboardingStepType;
        value: DisplayPrefilledBanner;
    }>({ step: null, value: '' });
    const { result: onboardingQuestionnaireFromOptimalResult } = useUseCase(
        GetOnboardingQuestionnaireDataFromOPTIMAL,
    );
    const [crunchbaseLink, setCrunchbaseLink] = useState('');

    const pageLayout = usePageLayout({
        header: (
            <Header>
                <Image name="logotype" height={20} />
            </Header>
        ),
    });

    const { onCompleteOnboardingStep, onGoToPreviousStep, currentOnboardingStep } =
        useUserOnboardingNavigation(props);

    useEffect(() => {
        const currentUrl = `${USER_ONBOARDING_BASE_ROUTE}${
            onboardingStepUrlMap[currentOnboardingStep.stepType]
        }`;
        // This is not an ideal patern, but we do want to update the url to reflect the current step
        // We cannot use the typical usNavigation hook here as the form data state is managed withing userOnboardingNavigation, using navigate() will cause to us loose the form data state
        history.pushState(null, '', currentUrl);
    }, [currentOnboardingStep]);

    const Component = onboardingStepComponentMap[currentOnboardingStep.stepType].component;

    useEffect(() => {
        if (
            onboardingQuestionnaireFromOptimalResult &&
            isOK(onboardingQuestionnaireFromOptimalResult)
        ) {
            const questionnaireDataList = onboardingQuestionnaireFromOptimalResult.value;
            const keys = onboardingStepComponentMap[currentOnboardingStep.stepType].questionsKeys;
            const { hasCrunchbase, hasAnyPrefillSource } = keys.reduce(
                ({ hasCrunchbase, hasAnyPrefillSource }, key) => {
                    const item = questionnaireDataList.find((listItem) => listItem.key === key);
                    const hasCrunchbaseSource =
                        item !== undefined &&
                        item.value !== undefined &&
                        item.source === 'CRUNCHBASE';

                    if (hasCrunchbaseSource) {
                        const link = item.id !== undefined ? item.id.split('|')[1] : '';
                        setCrunchbaseLink(link);
                    }

                    return {
                        hasCrunchbase: hasCrunchbase || hasCrunchbaseSource,
                        hasAnyPrefillSource:
                            hasAnyPrefillSource ||
                            (item !== undefined &&
                                item.value !== undefined &&
                                item.source !== undefined &&
                                ['CRUNCHBASE', 'DNB'].includes(item.source)),
                    };
                },
                { hasCrunchbase: false, hasAnyPrefillSource: false },
            );
            if (
                hasCrunchbase &&
                hasAnyPrefillSource &&
                !isFirstPrefilledStep &&
                !isFirstFromCrunchbase
            ) {
                setIsFirstFromCrunchbase(true);
                setIsFirstPrefilledStep(true);
                setDisplayPrefilledBanner({
                    value: 'crunchbase',
                    step: currentOnboardingStep.stepType,
                });
            } else if (hasAnyPrefillSource && !isFirstPrefilledStep) {
                setIsFirstPrefilledStep(true);
                setDisplayPrefilledBanner({
                    value: 'prefilled',
                    step: currentOnboardingStep.stepType,
                });
            } else {
                if (displayPrefilledBanner.step !== currentOnboardingStep.stepType) {
                    setDisplayPrefilledBanner((currentValue) =>
                        currentValue.value === '' ? currentValue : { value: '', step: null },
                    );
                }
            }
        } else {
            setDisplayPrefilledBanner((currentValue) =>
                currentValue.value === '' ? currentValue : { value: '', step: null },
            );
        }
    }, [
        currentOnboardingStep,
        displayPrefilledBanner,
        isFirstFromCrunchbase,
        isFirstPrefilledStep,
        onboardingQuestionnaireFromOptimalResult,
    ]);

    return (
        <PageLayout {...pageLayout}>
            <Component
                onCompleteOnboardingStep={onCompleteOnboardingStep}
                onBackClicked={onGoToPreviousStep}
                questionnaireData={currentOnboardingStep.questionnaireData}
                displayPrefilledBanner={displayPrefilledBanner.value}
                crunchbaseLink={crunchbaseLink}
            />
        </PageLayout>
    );
}
