import { map, mount, redirect, route } from 'navi';
import { RouteDefinitions, group } from '../../../view/routes/Route';
import { AppContext } from '../../../view/AppContext';
import React from 'react';
import { RiskProfileQuestionnaire } from '../components/RiskProfileQuestionnaire';
import { RiskProfileInterstitial } from '../components/RiskProfileInterstitial';
import { RiskProfileReport } from '../components/RiskProfileReport';
import { RiskProfile, RiskProfileSerialized } from '@app/public/types/RiskProfile';
import { URI } from '@embroker/shotwell/core/types/URI';

export const routes: RouteDefinitions<AppContext> = {
    '/explore': group(
        {
            auth: 'any',
            isOrganizationSpecific: false,
        },
        {
            '/risk-profile': mount({
                '/': map((request) => {
                    return redirect(
                        URI.build('/explore/risk-profile/questionnaire', request.params),
                    );
                }),
                questionnaire: map((request) => {
                    const initialPageIndex = !isNaN(Number(request.params.p))
                        ? Number(request.params.p)
                        : undefined;

                    return route({
                        title: 'Risk Profile',
                        view: (
                            <RiskProfileQuestionnaire
                                initialValues={RiskProfile.deserialize(
                                    request.params as unknown as RiskProfileSerialized,
                                )}
                                initialPageIndex={initialPageIndex}
                            />
                        ),
                    });
                }),
                analyzing: route({
                    title: 'Analyzing Your Risk Profile',
                    view: <RiskProfileInterstitial />,
                }),
                report: map((request) => {
                    const riskProfile = RiskProfile.deserialize(
                        request.params as unknown as RiskProfileSerialized,
                    );

                    return route({
                        title: 'Risk Profile Report',
                        view: <RiskProfileReport riskProfile={riskProfile} />,
                    });
                }),
            }),
        },
    ),
};
