import type { BorStatus, ForceNotEligible } from '@embroker/shotwell-api/app';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Failure, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    DisplayPolicyStatus,
    DisplayPolicyStatusActive,
    DisplayPolicyStatusCancelled,
    DisplayPolicyStatusCancelPending,
    DisplayPolicyStatusExpired,
    DisplayPolicyStatusNonRenewed,
    QuotingEngine,
    QuotingEngineCNABOP,
    QuotingEngineCrime,
    QuotingEngineCyber,
    QuotingEngineESP,
    QuotingEngineLPLEverest,
    QuotingEnginePCoML,
    QuotingEngineWCGA,
} from '../../shopping/types/enums';
import { isFuture, isPast } from 'date-fns';
import { Policy } from '../entities/Policy';
import { PolicyRepository } from '../repositories/PolicyRepository';
import { BorStatusIsBor, BorStatusNotBor, BorStatusPendingBor } from '../types/BorStatus';
import { DisplayPolicy } from '../types/DisplayPolicy';
import { PolicyFilter } from '../types/PolicyFilter';
import { AppContextStore } from '../../view/AppContext';
import { OrganizationRepository } from '../../userOrg/repositories/OrganizationRepository';
import { GetPendingInvoices } from '../../payments/useCases/GetPendingInvoices';
import { BundlePayment, Payment } from '../../payments/types/Payment';
import { Immutable } from '@embroker/ui-toolkit/v2';

export interface GetPoliciesRequest {
    filter: PolicyFilter;
}

export const GetPoliciesRequest = {
    ...defineValidator<GetPoliciesRequest>({
        filter: PolicyFilter.schema,
    }),
    create(getPoliciesRequest: GetPoliciesRequest) {
        return GetPoliciesRequest.validate(getPoliciesRequest);
    },
};

export interface GetPoliciesResponse {
    policyList: DisplayPolicy[];
    hasAnyPolicy: boolean;
}

export const GetPoliciesResponse = {
    ...defineValidator<GetPoliciesResponse>({
        policyList: Joi.array().items(DisplayPolicy.schema),
        hasAnyPolicy: Joi.boolean(),
    }),
    create(getPoliciesResponse: GetPoliciesResponse) {
        return GetPoliciesResponse.validate(getPoliciesResponse);
    },
};

export interface GetPolicies extends UseCase {
    execute(
        request: GetPoliciesRequest,
    ): AsyncResult<GetPoliciesResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

function compareByName(a: Nullable<string>, b: Nullable<string>): number {
    if (a == b) {
        return 0;
    }
    if (a == null) {
        return 1;
    }
    if (b == null) {
        return -1;
    }

    if (a < b) {
        return -1;
    }
    return 1;
}

function compareByExpirationDate(a: Date, b: Date): number {
    if (a == b) {
        return 0;
    }

    if (a < b) {
        return -1;
    }
    return 1;
}

function compareByStartDate(a: Date, b: Date): number {
    if (a == b) {
        return 0;
    }

    if (a < b) {
        return 1;
    }

    return -1;
}

function compareByPremiumAmount(a: Nullable<Money>, b: Nullable<Money>): number {
    if (a == null && b == null) {
        return 0;
    }

    if (a == null) {
        return 1;
    }
    if (b == null) {
        return -1;
    }

    if (a.amount === b.amount) {
        return 0;
    }

    if (a.amount < b.amount) {
        return 1;
    }
    return -1;
}

function compareByTransferStatus(a: BorStatus, b: BorStatus): number {
    if (a == b) {
        return 0;
    }

    if (a === BorStatusIsBor) {
        return -1;
    }

    if (a === BorStatusPendingBor) {
        if (b === BorStatusNotBor) {
            return -1;
        } else {
            return 1;
        }
    }

    return 1;
}

function listToDisplayPolicy(
    policiesToBeShown: PolicyToBeShown[],
    forceNotEligible?: Immutable<ForceNotEligible>,
    invoicesDue?: Payment[],
): DisplayPolicy[] {
    return policiesToBeShown.map((policyToBeShown) => {
        return toDisplayPolicy(policyToBeShown, forceNotEligible, invoicesDue);
    });
}

function toDisplayPolicy(
    policyToBeShown: PolicyToBeShown,
    forceNotEligible?: Immutable<ForceNotEligible>,
    invoicesDue?: Payment[],
): DisplayPolicy {
    let invoiceDue = invoicesDue?.find((invoice) => invoice.policyId === policyToBeShown.policy.id);

    // if invoice not found, it might be a bundle payment so we have to check inside each invoice to see if it contains an invoiceList
    if (!invoiceDue) {
        invoicesDue?.forEach((invoice: Payment) => {
            const invoiceList = (invoice as BundlePayment).invoiceList;
            if (invoiceList) {
                invoiceDue = invoiceList.find(
                    (invoice: Payment) => invoice.policyId === policyToBeShown.policy.id,
                );
            }
        });
    }

    const displayPolicy = {
        borStatus: policyToBeShown.policy.borStatus,
        cancellationDate: policyToBeShown.policy.cancellationDate,
        displayName: policyToBeShown.policy.name,
        endDate: policyToBeShown.policy.endDate,
        hasMultiplePolicies: policyToBeShown.hasMultiplePolicies,
        hasRenewal: policyToBeShown.hasRenewal,
        id: policyToBeShown.policy.id,
        insurerId: policyToBeShown.policy.insurerId,
        bundleId: policyToBeShown.policy.bundleId,
        insurerName: policyToBeShown.policy.insurerName,
        policyNumber: policyToBeShown.policy.policyNumber,
        policyNumbersInSeries: policyToBeShown.policyNumbersInSeries,
        premiumPerYear: policyToBeShown.policy.premiumPerYear,
        basePremiumWithEmbrokerAccessFee: policyToBeShown.policy.basePremiumWithEmbrokerAccessFee,
        solartisPolicyNumber: policyToBeShown.policy.solartisPolicyNumber,
        startDate: policyToBeShown.policy.startDate,
        viewMode: policyToBeShown.policy.viewMode,
        lineOfBusiness: policyToBeShown.policy.lineOfBusiness,
        policyHistoryDate: policyToBeShown.policyHistoryDate,
        subLineOfBusiness: policyToBeShown.policy.subLineOfBusiness,
        quotingEngine: policyToBeShown.policy.quotingEngine,
        isReferred: policyToBeShown.policy.isReferred,
        isDirectBill: policyToBeShown.policy.isDirectBill,
        policyStatus: determineDisplayPolicyStatus(policyToBeShown.policy, forceNotEligible),
        bookingType: policyToBeShown.policy.bookingType,
        inRunoff: policyToBeShown.policy.inRunoff,
        insuranceApplication: policyToBeShown.policy.insuranceApplicationId,
        manifestId: policyToBeShown.policy.manifestId,
        coverageSectionList: policyToBeShown.policy.coverageSectionList,
        insuredPartiesList: policyToBeShown.policy.insuredPartiesList,
    } as DisplayPolicy;

    if (invoiceDue) {
        displayPolicy.invoiceDue = invoiceDue;
    }

    return displayPolicy;
}

interface PolicyToBeShown {
    policy: Policy;
    hasRenewal: boolean;
    hasMultiplePolicies: boolean;
    policyHistoryDate: Nullable<Date>;
    policyNumbersInSeries: string[];
}

function determineDisplayPolicyStatus(
    policy: Policy,
    forceNotEligible?: Immutable<ForceNotEligible>,
): DisplayPolicyStatus {
    if (policy.cancellationDate && !isFuture(policy.cancellationDate)) {
        return DisplayPolicyStatusCancelled;
    }
    if (policy.cancellationDate && isFuture(policy.cancellationDate)) {
        return DisplayPolicyStatusCancelPending;
    }
    if (determineIfPolicyIsNonRenewed(policy.quotingEngine, forceNotEligible)) {
        return DisplayPolicyStatusNonRenewed;
    }
    if (!isFuture(policy.endDate)) {
        return DisplayPolicyStatusExpired;
    }
    return DisplayPolicyStatusActive;
}

function determineIfPolicyIsNonRenewed(
    quotingEngine?: QuotingEngine,
    forceNotEligible?: Immutable<ForceNotEligible>,
) {
    return (
        (quotingEngine == QuotingEngineESP && forceNotEligible?.esp) ||
        (quotingEngine == QuotingEngineCyber && forceNotEligible?.cyber) ||
        (quotingEngine == QuotingEngineCrime && forceNotEligible?.crime) ||
        (quotingEngine == QuotingEngineCNABOP && forceNotEligible?.cna_bop) ||
        (quotingEngine == QuotingEngineLPLEverest && forceNotEligible?.lpl) ||
        (quotingEngine == QuotingEnginePCoML && forceNotEligible?.pcoml) ||
        (quotingEngine == QuotingEngineWCGA && forceNotEligible?.wcga)
    );
}

function getPolicyToBeShownFromPolicySeries(policySeries: Policy[]): Nullable<PolicyToBeShown> {
    if (policySeries.length === 1) {
        if (
            isFuture(policySeries[0].startDate) &&
            policySeries[0].viewMode === 'PolicyViewStatusCodeListDraft'
        ) {
            return null;
        }
        return {
            policy: policySeries[0],
            hasMultiplePolicies: false,
            hasRenewal: false,
            policyHistoryDate: null,
            policyNumbersInSeries: [policySeries[0].policyNumber],
        } as PolicyToBeShown;
    }

    //filter out future/renewal polices in Draft mode, since they should never be shown to users
    policySeries = policySeries.filter((policy) => {
        return !(isFuture(policy.startDate) && policy.viewMode === 'PolicyViewStatusCodeListDraft');
    });
    const descSortedExpiredPolicies = policySeries
        .filter((policy) => {
            return isPast(policy.endDate);
        })
        .sort((a, b) => (a.endDate > b.endDate ? -1 : 1));
    const futurePolicies = policySeries.filter((policy) => {
        return isFuture(policy.startDate);
    });
    const currentlyActivePolicies = policySeries
        .filter((policy) => {
            return isPast(policy.startDate) && isFuture(policy.endDate);
        })
        .sort((policy1, policy2) => {
            return policy2.startDate > policy1.startDate ? 1 : -1;
        });

    const descSortedExpiredPoliciesWithoutDraft = descSortedExpiredPolicies.filter(
        (policy) => policy.viewMode !== 'PolicyViewStatusCodeListDraft',
    );

    const policyHistoryDate =
        descSortedExpiredPoliciesWithoutDraft.length == 0
            ? null
            : descSortedExpiredPoliciesWithoutDraft[
                  descSortedExpiredPoliciesWithoutDraft.length - 1
              ].startDate;

    const policyNumbersInSeries = policySeries.map((policy) => policy.policyNumber);

    if (currentlyActivePolicies.length > 0) {
        const result: PolicyToBeShown = {
            hasMultiplePolicies: false,
            hasRenewal: false,
            policy: currentlyActivePolicies[0],
            policyHistoryDate,
            policyNumbersInSeries,
        };
        //special case when policy series contains more than one currently active policy, can be achieved only during testing
        if (currentlyActivePolicies.length > 1) {
            result.hasMultiplePolicies = true;
        }
        if (descSortedExpiredPolicies.length > 0) {
            result.hasMultiplePolicies = true;
        }
        if (futurePolicies.length > 0) {
            result.hasRenewal = true;
            result.hasMultiplePolicies = true;
        }
        return result;
    }

    if (descSortedExpiredPolicies.length > 0) {
        const result: PolicyToBeShown = {
            hasMultiplePolicies: false,
            hasRenewal: false,
            policy: descSortedExpiredPolicies[0],
            policyHistoryDate: null,
            policyNumbersInSeries,
        };
        if (descSortedExpiredPolicies.length > 1) {
            result.policyHistoryDate = policyHistoryDate;
            result.hasMultiplePolicies = true;
        }
        if (futurePolicies.length > 0) {
            result.policyHistoryDate = policyHistoryDate;
            result.hasRenewal = true;
            result.hasMultiplePolicies = true;
        }
        return result;
    }

    //special case when policy series has only future/renewal policies, can be achieved only during testing
    if (futurePolicies.length > 0) {
        const result: PolicyToBeShown = {
            hasMultiplePolicies: false,
            hasRenewal: false,
            policy: futurePolicies.sort((a, b) => (a.endDate < b.endDate ? -1 : 1))[0],
            policyHistoryDate: null,
            policyNumbersInSeries,
        };
        if (futurePolicies.length > 1) {
            result.hasMultiplePolicies = true;
            result.hasRenewal = true;
        }
        return result;
    }

    return null;
}

interface SortCriteria<T> {
    propertyName: keyof T;
    compareFunction(a: any, b: any): number;
}

@injectable()
export class GetPoliciesUseCase extends UseCase {
    public static type = Symbol('Policy/GetPolicies');
    public static sortByToPropMap: Map<string, SortCriteria<DisplayPolicy>> = new Map([
        ['A to Z', { propertyName: 'displayName', compareFunction: compareByName }],
        ['Expiration Date', { propertyName: 'endDate', compareFunction: compareByExpirationDate }],
        [
            'Premium amount',
            { propertyName: 'premiumPerYear', compareFunction: compareByPremiumAmount },
        ],
        [
            'Transfer Status',
            { propertyName: 'borStatus', compareFunction: compareByTransferStatus },
        ],
        ['Start Date', { propertyName: 'startDate', compareFunction: compareByStartDate }],
    ]);

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
        @inject(GetPendingInvoices.type) private getPendingInvoices: GetPendingInvoices,
    ) {
        super(eventBus);
    }

    async execute(
        request: GetPoliciesRequest,
    ): AsyncResult<GetPoliciesResponse, UnknownEntity | InvalidArgument | OperationFailed> {
        if (!GetPoliciesUseCase.sortByToPropMap.has(request.filter.sortBy)) {
            return Failure(
                InvalidArgument({ argument: 'request.sortBy', value: request.filter.sortBy }),
            );
        }

        const organizationId = AppContextStore.context.activeSession.organizationId;
        if (!organizationId) {
            return Failure(
                OperationFailed({
                    message: 'No organization in current context',
                    errors: [],
                }),
            );
        }

        const getPoliciesPromise = this.policyRepository.getPolicies();
        const getOrganizationPromise = this.organizationRepository.getOrganization(organizationId);
        const getPendingInvoicesPromise = this.getPendingInvoices.execute({ organizationId });
        const getPoliciesResult = await getPoliciesPromise;
        const getOrganizationResult = await getOrganizationPromise;
        const getPendingInvoicesList = await getPendingInvoicesPromise;

        if (isErr(getPoliciesResult)) {
            return getPoliciesResult;
        }
        if (isErr(getOrganizationResult)) {
            return getOrganizationResult;
        }

        let invoicesDue: Payment[] = [];
        if (isOK(getPendingInvoicesList)) {
            invoicesDue = getPendingInvoicesList.value.invoiceDueList as Payment[];
        }

        const checkedSeries: UUID[] = [];
        const policiesToBeShown: PolicyToBeShown[] = [];
        for (const policy of getPoliciesResult.value) {
            if (!checkedSeries.includes(policy.renewalSeriesId)) {
                checkedSeries.push(policy.renewalSeriesId);
                const policySeries = getPoliciesResult.value.filter((policyInTheSeries) => {
                    return policyInTheSeries.renewalSeriesId === policy.renewalSeriesId;
                });
                const policyToBeShown = getPolicyToBeShownFromPolicySeries(
                    policySeries as Policy[],
                );
                if (policyToBeShown) {
                    policiesToBeShown.push(policyToBeShown);
                }
            }
        }

        const sortCriteria = GetPoliciesUseCase.sortByToPropMap.get(
            request.filter.sortBy,
        ) as SortCriteria<DisplayPolicy>;
        const filteredPolicies = policiesToBeShown.filter((policy) =>
            policy.policy.matchFilter(request.filter),
        );

        const sortedDisplayPolicyList = this.sortByProp(
            listToDisplayPolicy(
                filteredPolicies,
                getOrganizationResult.value.forceNotEligible,
                invoicesDue,
            ),
            sortCriteria,
        );
        const policies = GetPoliciesResponse.create({
            policyList: sortedDisplayPolicyList,
            hasAnyPolicy: policiesToBeShown.length > 0,
        });
        let finalResult: GetPoliciesResponse = {} as GetPoliciesResponse;
        if (isOK(policies)) {
            finalResult = {
                hasAnyPolicy: policies.value.hasAnyPolicy,
                policyList: policies.value.policyList as DisplayPolicy[],
            };
        } else {
            return Failure(OperationFailed({ errors: policies.errors }));
        }
        return Success(finalResult);
    }
    sortByProp<T>(array: T[], sortCriteria: SortCriteria<T>): T[] {
        return array.sort((firstElement: T, secondElement: T) => {
            const a = firstElement[sortCriteria.propertyName];
            const b = secondElement[sortCriteria.propertyName];

            return sortCriteria.compareFunction(a, b);
        });
    }
}

export const GetPolicies: UseCaseClass<GetPolicies> = GetPoliciesUseCase;
