import { useEffect } from 'react';
import { API } from '@embroker/shotwell-api/app';

function isTrustedOrigin(eventOrigin: string) {
    return eventOrigin.includes('.embroker.com') || eventOrigin === 'http://localhost:5000';
}

export function useSetupAdminMessageSubscriptions() {
    useEffect(() => {
        const handleAdminMessage = (event: MessageEvent) => {
            if (!isTrustedOrigin(event.origin) || event.origin == window.origin) {
                return;
            }

            const { refreshToken, accessToken } = event.data || {};
            if (!refreshToken || !accessToken) {
                return;
            }

            if (event?.source?.postMessage) {
                event.source.postMessage('ack', event.origin as WindowPostMessageOptions);
            }

            API.setAuthorization({
                accessToken,
                refreshToken,
            });
        };
        window.addEventListener('message', handleAdminMessage);
        return () => window.removeEventListener('message', handleAdminMessage);
    }, []);
}
