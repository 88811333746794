import { Joi, SchemaType } from '@embroker/shotwell/core/validation/schema';
import { FormQuestionDefinition } from '../hooks/useDataDrivenForm';
import {
    locationFieldFormatValidationError,
    locationFieldValidator,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { InputType } from '@embroker/ui-toolkit/v2';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { ErrorMsgObject } from './validationObject';
import {
    AddressFieldFormFieldView,
    assertMailingAddressType,
} from '../components/complexFormFieldViews/AddressFieldFormFieldView.view';
import { StaticFieldView } from '../components/complexFormFieldViews/StaticFieldView.view';
import {
    assertInputValueAsArray,
    MultiSelectFieldView,
} from '../components/complexFormFieldViews/MultiSelectFieldView.view';
import {
    assertInputValueAsString,
    NaicsCodeFieldView,
} from '../components/complexFormFieldViews/NaicsCodeField';
import {
    FormFieldViewProps,
    InputFieldProps,
    QuestionProps,
} from '../components/FormFieldView.view';
import {
    FundraisingRoundFormFieldView,
    assertFundraisingRoundType,
    fundraisingRoundFormatValidationError,
} from '../components/complexFormFieldViews/FundraisingRoundFormFieldView.view';
import { PreviousYearRevenueView } from '../components/complexFormFieldViews/PreviousYearRevenueView.view';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import {
    assertInputValueAsBoolean,
    BooleanCheckboxFieldView,
} from '../components/complexFormFieldViews/BooleanCheckboxFieldView.view';
import { FileUploadField } from '../components/complexFormFieldViews/FileUploadField';
import { YearFieldView } from '../components/complexFormFieldViews/YearFieldView.view';
import { ProjectedYearRevenueView } from '../components/complexFormFieldViews/ProjectedYearRevenueView.view';
import {
    assertRevenueType,
    revenueFieldFormatValidationError,
} from '../components/complexFormFieldViews/RevenueFieldView.view';
import { Year } from '@embroker/shotwell/core/types/Year';

interface ComplexFormFieldQuestionProps extends QuestionProps {
    questionType: ComplexFormFieldType;
}
export interface ComplexFormFieldViewProps extends FormFieldViewProps {
    questionProps: ComplexFormFieldQuestionProps;
}

export const getFormFieldProps = (questionProps: QuestionProps) => ({
    title: questionProps.title,
    label: questionProps.label,
    tooltip: questionProps.tooltip,
});

export const getInputProps = (questionProps: QuestionProps, inputFieldProps: InputFieldProps) => ({
    ...inputFieldProps.inputProps,
    placeholder: inputFieldProps.inputProps.placeholder,
    items: questionProps.selectOptions,
});

export const ComplexFormFieldTypes = [
    'fundraisingRound',
    'addressField',
    'static',
    'multiSelect',
    'naicsCode',
    'previousYearRevenue',
    'projectedYearRevenue',
    'booleanCheckbox',
    'fileUpload',
    'yearField',
] as const;
export type ComplexFormFieldType = (typeof ComplexFormFieldTypes)[number];

export function isComplexQuestionType(
    value: FormQuestionDefinition['questionType'],
): value is ComplexFormFieldType {
    return ComplexFormFieldTypes.includes(value as ComplexFormFieldType);
}

const defaultFormatValidationError = (error: InvalidArgument) =>
    ErrorMsgObject.getValidationMessage(error);

function fallbackTypeAssertion<T>(input: unknown): input is T {
    return true;
}

export const fundraisingRoundFormatValidator = Joi.object({
    fundraiseDate: Joi.date().format('MM/YYYY').required(),
    moneyRaised: Joi.string().required(),
    leadInvestor: Joi.string().required(),
});

export interface ComplexFormFieldDefinition {
    type: InputType;
    validator: SchemaType<unknown>;
    renderingComponent: React.FC<ComplexFormFieldViewProps>;
    formatValidationError: (error: any) => string;
    typeAssertion: (input: unknown) => boolean;
}

export const isEmptyObject = (obj: unknown): boolean => {
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }

    const keys = Object.keys(obj);
    if (keys.length === 0) {
        return true;
    }

    for (const key of keys) {
        const value = (obj as { [key: string]: unknown })[key];
        const emptyInputValues: unknown[] = [undefined, ''];
        if (!emptyInputValues.includes(value)) {
            return false;
        }
    }

    return true;
};

export const ComplexFormFieldDefinitionMap: Record<
    ComplexFormFieldType,
    ComplexFormFieldDefinition
> = {
    fundraisingRound: {
        type: 'text',
        validator: fundraisingRoundFormatValidator,
        formatValidationError: fundraisingRoundFormatValidationError,
        renderingComponent: FundraisingRoundFormFieldView,
        typeAssertion: assertFundraisingRoundType,
    },
    addressField: {
        type: 'text',
        validator: locationFieldValidator,
        formatValidationError: locationFieldFormatValidationError,
        renderingComponent: AddressFieldFormFieldView,
        typeAssertion: assertMailingAddressType,
    },
    static: {
        type: 'text',
        validator: Joi.any().optional(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: StaticFieldView,
        typeAssertion: fallbackTypeAssertion,
    },
    multiSelect: {
        type: 'radioGroup',
        validator: Joi.any().optional(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: MultiSelectFieldView,
        typeAssertion: assertInputValueAsArray,
    },
    naicsCode: {
        type: 'text',
        validator: Joi.string(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: NaicsCodeFieldView,
        typeAssertion: assertInputValueAsString,
    },
    previousYearRevenue: {
        type: 'currency',
        validator: Revenue.schema,
        formatValidationError: revenueFieldFormatValidationError,
        renderingComponent: PreviousYearRevenueView,
        typeAssertion: assertRevenueType,
    },
    projectedYearRevenue: {
        type: 'currency',
        validator: Revenue.schema,
        formatValidationError: revenueFieldFormatValidationError,
        renderingComponent: ProjectedYearRevenueView,
        typeAssertion: assertRevenueType,
    },
    booleanCheckbox: {
        type: 'checkbox',
        validator: Joi.boolean(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: BooleanCheckboxFieldView,
        typeAssertion: assertInputValueAsBoolean,
    },
    fileUpload: {
        type: 'text',
        validator: Joi.array().items(Joi.string().allow('')),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: FileUploadField,
        typeAssertion: fallbackTypeAssertion,
    },
    yearField: {
        type: 'year',
        validator: Year.schema.required(),
        formatValidationError: defaultFormatValidationError,
        renderingComponent: YearFieldView,
        typeAssertion: fallbackTypeAssertion,
    },
};
