import React, { useMemo } from 'react';
import { StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { Link } from '@app/view/components';

interface PrefilledBannerProps {
    displayMessageStatus: OnboardingPageProps['displayPrefilledBanner'];
    crunchbaseLink?: string;
}

export const PrefilledBanner = ({ displayMessageStatus, crunchbaseLink }: PrefilledBannerProps) => {
    const displayMessage = useMemo(() => {
        switch (displayMessageStatus) {
            case 'crunchbase':
                return (
                    <Text>
                        We're making this easy by pre-filling your application with publicly
                        available info. Please review for accuracy and update if needed. Powered by{' '}
                        <Link href={crunchbaseLink || '/'}>Crunchbase</Link>.
                    </Text>
                );
            case 'prefilled':
                return "We're making this easy by pre-filling your application with publicly available info. Please review for accuracy and update if needed.";
            default:
                return '';
        }
    }, [displayMessageStatus, crunchbaseLink]);
    return <StatusMessage status="helptext">{displayMessage}</StatusMessage>;
};
