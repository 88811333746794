import React from 'react';
import ReactToPrint from 'react-to-print';

// NOTE A note on current restrictions
// The copy styles feature is not working, so it is turned off for now. The
// reason is that the <base href="/app/"/> element is not copied as well, so
// the linked stylesheets cannot be loaded.

interface PrintElement {
    children: React.ReactElement;
    elementToPrint: React.Ref<HTMLDivElement> | any;
}

function PrintElement({ children, elementToPrint }: PrintElement) {
    return (
        <ReactToPrint
            copyStyles={false}
            pageStyle={'@media print {.hide-in-print {display:none}}}'}
            trigger={() => children}
            content={() => elementToPrint?.current}
        />
    );
}

function HideInPrint({ children }: any) {
    return <span className="hide-in-print">{children}</span>;
}

export { PrintElement, HideInPrint };
