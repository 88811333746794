import { Immutable } from '@embroker/shotwell/core/types';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, Icon, Label, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { BundlePayment } from '../../types/Payment';
import { coverageIconMap } from './InvoiceListItem.view';

interface BundleInvoiceListItem {
    invoice: Immutable<BundlePayment>;
    dataE2e?: string;
    onViewDetailsClicked(): void;
}

export function InvoiceListItemBundle(props: BundleInvoiceListItem) {
    const { invoice, dataE2e, onViewDetailsClicked } = props;

    let iconName = coverageIconMap.get(invoice.lineOfBusiness);
    if (iconName == undefined) {
        iconName = 'product-default';
    }

    const bundlePolicyListData = invoice.invoiceList.map((invoice) => {
        return (
            <Label style="body 2" color="ui-500" key={invoice.id}>
                {invoice.lineOfBusiness} <br /> Invoice no. {invoice.invoiceNumber}
            </Label>
        );
    });

    return (
        <ColumnLayout top data-e2e={dataE2e}>
            <Icon name={iconName} />
            <ColumnLayout gap="16" split="3" responsive={{ containerWidth: { smallerThan: 400 } }}>
                <StackLayout gap="8">
                    <Label style="heading 5">{invoice.lineOfBusiness}</Label>
                    {bundlePolicyListData}
                    <br />
                    <ColumnLayout gap="8">
                        <Text style="heading 5">
                            <MoneyDisplay value={invoice.balance} />
                        </Text>
                        <ColumnLayout split="-1">
                            <TextButton color="primary-500" onClick={onViewDetailsClicked}>
                                View Details
                            </TextButton>
                        </ColumnLayout>
                    </ColumnLayout>
                </StackLayout>
            </ColumnLayout>
        </ColumnLayout>
    );
}
